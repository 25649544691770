import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import CreateReport from "../Components/CreateReport/CreateReport";
import {useMutation, useLazyQuery} from "@apollo/client";
import {GET_CONTACTS} from "../Services/Graph/Contacts/Queries";
import {AppStateContext} from "../Context/AppReducer";
import {authApolloClient} from "../Services/Graph/apolloConfig";
import {ADD_CONTACT} from "../Services/Graph/Contacts/Mutations";
import {getCrmUrl} from "../Helpers/getEnvVar";

export const CreateReportIframeView = ({ match, googleScriptLoaded, location }) => {
  const username = match?.params?.user;
  const query = new URLSearchParams(location?.search);
  const globalState = useContext(AppStateContext);
  const [contactData, setContactData] = useState(null);

  const [getContact, {loading: contactLoading, error: contactError, data: contactResults}] = useLazyQuery(GET_CONTACTS, {
    variables: {
      parentPersonId: globalState?.user?.personId,
      searchTerm: query.get('contact_email'),
      first: 1,
      offset: 0,
    },
    client: authApolloClient,
    onCompleted(data) {
      setContactData(data.contacts.contacts[0]);
    }
  });

  const [addContact] = useMutation(ADD_CONTACT, {
    client: authApolloClient,
    onCompleted(data) {
      setContactData(data.contact);
    },
    onError(error) {
      console.log(error);
    }
  });

  useEffect(() => {
    getContact();
  }, []);

  useEffect(() =>{
    if (!contactLoading && contactResults?.contacts?.contacts?.length === 0) {
      addContact({
        variables: {
          parentPersonId: globalState?.user?.personId,
          firstName: query.get('first_name'),
          lastName: query.get('last_name'),
          email: query.get('contact_email'),
        },
      });
    }
  }, [contactLoading, contactResults]);

  const postMessage = () => {
    window.parent.postMessage("create-report-done", getCrmUrl());
  }

  return (
    <div>
      {googleScriptLoaded && !contactLoading && !contactError &&
      <CreateReport
        user={username}
        contactData={{contact: contactData }}
        createReportOpen={{open: true, editing:false, iframe: true, editPayload: query.get('report-id') || null}}
        googleScriptLoaded={true}
        setSavedReport={() => console.log('saved')}
        setSubscribe={() => console.log('subscribed')}
        setContactReportWasMade={() => postMessage()}
      />}
    </div>
  )
}

CreateReportIframeView.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  googleScriptLoaded: PropTypes.bool,
};
