import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { createStyles, makeStyles } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    headerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "30px",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    areYouSureSubText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    deleteModalTextContainer: {
      display: "flex",
      justifyContent: "center",
    },
    modalActions: {
      display: "flex",
      justifyContent: "center",
      marginTop: "40px",
      marginBottom: "35px",
    },
    modalClose: {
      position: "absolute",
      right: "30px",
    },
    closeModalButton: {
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      "&:hover": {},
    },
    closeModalButtonIcon: {
      color: "#282828",
      fontSize: "30px",
    },
    cancelButton: {
      background: "transparent",
      minWidth: "0px",
      padding: "16px 80px",
      borderRadius: "10px",
      color: "#8D8989",
      "&:hover": {
        background: "transparent",
      },
      marginRight: "10px",
    },
    cancelButtonLabel: {
      fontFamily: theme.typography.secondary,
      color: "#8D8989",
      fontSize: "16px",
      fontWeight: "700",
      margin: 0,
    },
    deleteButton: {
      background: theme.palette.areaPulseError,
      minWidth: "0px",
      padding: "16px 80px",
      borderRadius: "10px",
      color: "#fff",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseError,
      },
      marginLeft: "10px",
    },
    deleteButtonLabel: {
      fontFamily: theme.typography.secondary,
      color: "#fff",
      fontSize: "16px",
      fontWeight: "700",
      margin: 0,
    },
  })
);

const DeleteModal = ({ open, title, description, handleDelete, handleClose }) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: "800px",
          },
        }}
        onClose={handleClose}
        aria-labelledby="delete-modal"
      >
        <div className={classes.headerContainer}>
          <h1 className={classes.paperLabel}>{title}</h1>
          <div className={classes.modalClose}>
            <Button className={classes.closeModalButton} onClick={handleClose}>
              <CloseRounded color="inherit" fontSize="inherit" className={classes.closeModalButtonIcon} />
            </Button>
          </div>
        </div>
        <DialogContent>
          <div className={classes.deleteModalTextContainer}>
            <h1 className={classes.areYouSureSubText}>{description}</h1>
          </div>
        </DialogContent>
        <div className={classes.modalActions}>
          <Button onClick={handleClose} className={classes.cancelButton}>
            <h1 className={classes.cancelButtonLabel}>Cancel</h1>
          </Button>
          <Button onClick={handleDelete} className={classes.deleteButton}>
            <h1 className={classes.deleteButtonLabel}>Delete</h1>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
DeleteModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  handleDelete: PropTypes.func,
  handleClose: PropTypes.func,
};

export default DeleteModal;
