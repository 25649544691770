import { gql } from "@apollo/client";

export const GET_BILLING_PLANS = gql`
  query plans($project: String!) {
    plans(project: $project) {
      plans {
        id
        show
        order
        project
        sku
        name
        description
        config
        price
      }
    }
  }
`;

export const GET_USER_BILLING_PLANS = gql`
  query user($id: Int!) {
    user(id: $id) {
      subscriptions {
        id
        name
        itemId
      }
    }
  }
`;

export const GET_USER_BILLING_PLANS_USAGE = gql`
  query userSubscriptionsUsage($userId: Int!) {
    userSubscriptionsUsage(userId: $userId) {
      id
      emails
      emailsLimit
      leads
      leadsLimit
      reports
      reportsLimit
      users
      usersLimit
    }
  }
`;
