import React from "react";
import HomeStylesheet from "../HomeStylesheet";
import Parallax from "react-rellax";
import { Element } from "react-scroll";
import { Grid, Hidden } from "@material-ui/core";

const Included = () => {
  const classes = HomeStylesheet();

  return (
    <Element name="included" className={classes.includedSection}>
      <Grid container className={classes.includeSectionContentContainer}>
        <Grid item xs={12} className={""}>
          <img src="/assets/wave.png" className={classes.topWaveInclude} />
          <h2 className={`wow ${classes.fadeInRight} ${classes.includeHeader}`}>Realtime and Relevant</h2>
          <p className={`wow ${classes.fadeInRight} ${classes.includeDescriptions}`}>
            AreaPulse makes it easy to send your clients reports that are relevant to them. Monthly updates on their <br />
            current neighborhood or the neighborhood of their dreams provides real time data that gets their <br />
            attention.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.includePhotoContainer}>
          <img className={`wow fadeIn ${classes.taptopPic}`} src="/assets/new-areapulse-realtime-mockup.png" alt="areapulse content 6" />
        </Grid>
        <Grid className={classes.includeBoxes} container>
          <Grid item sm={12} md={4} className={classes.aboutPhotoContainer}>
            <div className={`${classes.localBoxes} wow fadeIn ${classes.delay0}`}>
              <img className={classes.includeImage} src="/assets/Group-1.png" />
              <div className={classes.includeBoxTitle}>Hyper-Local</div>
              <div className={classes.includeBoxSubtitle}>
                Get detailed stats based on address,
                <br /> neighborhood, city, zip, or by drawing
                <br /> the area.
              </div>
            </div>
          </Grid>
          <Grid item sm={12} md={4} className={classes.aboutPhotoContainer}>
            <div className={`${classes.localBoxes} wow fadeIn ${classes.delay1}`}>
              <img className={classes.includeImage} src="/assets/Group-2.png" />
              <div className={classes.includeBoxTitle}>Real-Time</div>
              <div className={classes.includeBoxSubtitle}>
                Updates sent weekly, bi-weekly, or
                <br /> monthly.
              </div>
            </div>
          </Grid>
          <Grid item sm={12} md={4} className={classes.aboutPhotoContainer}>
            <div className={`${classes.localBoxes} wow fadeIn ${classes.delay2}`}>
              <img className={classes.includeImage} src="/assets/Group-9.png" />
              <div className={classes.includeBoxTitle}>Relevant</div>
              <div className={classes.includeBoxSubtitle}>
                Only get the data you care about
                <br /> most.
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Element>
  );
};

Included.propTypes = {};

export default Included;
