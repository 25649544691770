import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { AppStateContext } from "../../Context/AppReducer";
import { makeStyles, createStyles, Grid } from "@material-ui/core";
import SaveReport from "./SaveReport";
import ShareOptions from "./ShareOptions";
import ReportPreview from "./ReportPreview";
import ShareModals from "./ShareModals";

const useStyles = makeStyles((theme) =>
  createStyles({
    createReportShareContainer: {
      height: "calc(100% - 85px)",
    },
    createReportSharePreviewContainer: {
      position: "relative",
      height: "100%",
      width: "100%",
      background: "#FAFAFA",
      padding: "25px 25px 25px 100px",
    },
    createReportSharePreviewContainerLandingPage: {
      position: "relative",
      height: "88%",
      width: "100%",
      background: "#FAFAFA",
      padding: "25px 25px 25px 100px",
    },
    createReportShareContentContainer: {
      width: "100%",
      height: "100%",
      padding: "30px",
      overflowY: "scroll",
      overflowX: "inherit",
    },
    createReportShareContentContainerLandingPage: {
      width: "100%",
      height: "12%",
      padding: "0px 30px",
      overflowY: "scroll",
      overflowX: "inherit",
      background: "#FAFAFA",
    },
    "@media (max-width: 960px)": {
      createReportShareContainer: {
        overflowX: "scroll",
        padding: "5px",
      },
    },
    "@media (max-width: 600px)": {
      createReportSharePreviewContainerLandingPage: {
        padding: "25px 0px 25px 0px",
      },
    },
  })
);

const CreateReportShare = ({
  contactFrequency,
  setSubscribe,
  contactData,
  reportSaved,
  user,
  subscribeContactData,
  thumbnail,
  reportData,
  createReportOpen,
  setReportSaved,
  setReportSubmitted,
  setMarketReportSubmiting,
  iFrameLoading,
  setiFrameLoading,
  landingPageView,
  setGetUserData,
  setContactReportWasMade,
}) => {
  const classes = useStyles();
  const globalState = useContext(AppStateContext);
  const reportLink = createReportOpen.editing ? reportData?.updateMarketReport?.url : reportData?.createMarketReport?.url;
  const reportId = createReportOpen.editing ? reportData?.updateMarketReport?.id : landingPageView ? subscribeContactData?.subscribeContactToReport.id : reportData?.createMarketReport?.id;
  const reportHeadline = createReportOpen.editing ? reportData?.updateMarketReport?.headline : landingPageView ? "Market Report For" : reportData?.createMarketReport?.headline;
  const userName = landingPageView ? user : globalState.user.username;
  const userId = globalState.user.userId;
  const landingPageContact = globalState?.landingPageContact;
  const [shareType, setShareType] = useState("none");
  const [shareOpen, setShareOpen] = useState(false);
  const [editedReport, setEditedReport] = useState(false);
  const [changedTitles, setChangedTitles] = useState(false);
  const createTitle = () => {
    if (reportData?.createReportRequest.parameters.address) {
      return reportData?.createReportRequest.parameters.address;
    }
    if (reportData?.createReportRequest.parameters.city) {
      return reportData?.createReportRequest.parameters.city;
    }
    if (reportData?.createReportRequest.parameters.zipCode) {
      return reportData?.createReportRequest.parameters.zipCode;
    }
  };

  const reportTitle = createReportOpen.editing ? reportData?.updateMarketReport?.title : landingPageView ? createTitle() : reportData?.createMarketReport?.title;

  return (
    <Grid container className={classes.createReportShareContainer}>
      <Grid
        item
        sm={12}
        md={landingPageView ? 12 : 6}
        lg={landingPageView ? 12 : 4}
        className={landingPageView ? classes.createReportShareContentContainerLandingPage : classes.createReportShareContentContainer}
      >
        <SaveReport
          contactFrequency={contactFrequency}
          setGetUserData={setGetUserData}
          setChangedTitles={setChangedTitles}
          changedTitles={changedTitles}
          reportSaved={reportSaved}
          setSubscribe={setSubscribe}
          contactData={contactData}
          title={reportTitle}
          headline={reportHeadline}
          id={reportId}
          thumbnail={thumbnail}
          user={user}
          subscribeContactData={subscribeContactData}
          iFrameLoading={iFrameLoading}
          editing={createReportOpen.editing}
          setReportSaved={(status) => setReportSaved(status)}
          setReportSubmitted={(status) => setReportSubmitted(status)}
          setMarketReportSubmiting={(status) => setMarketReportSubmiting(status)}
          setEditedReport={(status) => setEditedReport(status)}
          landingPageView={landingPageView}
          setContactReportWasMade={setContactReportWasMade}
          contactFrequency={contactFrequency}
        />
        <ShareOptions
          userName={userName}
          reportId={reportId}
          setShareOpen={setShareOpen}
          contactData={contactData}
          reportSaved={reportSaved}
          setShareType={(type) => setShareType(type)}
          landingPageView={landingPageView}
          landingPageContact={landingPageContact}
        />
      </Grid>

      <Grid
        item
        sm={12}
        md={landingPageView ? 12 : 6}
        lg={landingPageView ? 12 : 8}
        className={landingPageView ? classes.createReportSharePreviewContainerLandingPage : classes.createReportSharePreviewContainer}
      >
        <ReportPreview
          setChangedTitles={setChangedTitles}
          changedTitles={changedTitles}
          userName={userName}
          reportId={reportId}
          editedReport={editedReport}
          setEditedReport={(status) => setEditedReport(status)}
          iFrameLoading={iFrameLoading}
          setiFrameLoading={(status) => setiFrameLoading(status)}
        />
        <ShareModals
          userId={userId}
          setShareOpen={setShareOpen}
          shareOpen={shareOpen}
          user={user}
          reportId={reportId}
          reportLink={reportLink}
          shareType={shareType}
          setShareType={(type) => setShareType(type)}
        />
      </Grid>
    </Grid>
  );
};

CreateReportShare.propTypes = {
  setSubscribe: PropTypes.func,
  contactData: PropTypes.object,
  reportSaved: PropTypes.bool,
  user: PropTypes.string,
  thumbnail: PropTypes.string,
  reportData: PropTypes.object,
  setReportSaved: PropTypes.func,
  setReportSubmitted: PropTypes.func,
  setMarketReportSubmiting: PropTypes.func,
  setGetUserData: PropTypes.func,
  landingPageView: PropTypes.bool,
  setContactReportWasMade: PropTypes.func,
  contactFrequency: PropTypes.string,
};

export default CreateReportShare;
