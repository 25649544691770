import { gql } from "@apollo/client";

export const CREATE_MARKET_REPORT = gql`
  mutation createMarketReport(
    $forContactID: Int
    $geoJSON: geoJSONType
    $minBaths: Int
    $maxPrice: Int
    $lat: Float
    $state: String
    $propertyType: [String]
    $minBeds: Int
    $minPrice: Int
    $radius: Float
    $address: String
    $city: String
    $neighborhood: String
    $polygon: [[Float]]
    $lng: Float
    $mlsId: [Int]
    $zip: String
    $maxBeds: Int
    $maxBaths: Int
    $thumbnail: String
    $headline: String
  ) {
    createMarketReport(
      forContactID: $forContactID
      geoJSON: $geoJSON
      minBaths: $minBaths
      maxPrice: $maxPrice
      lat: $lat
      state: $state
      propertyType: $propertyType
      minBeds: $minBeds
      minPrice: $minPrice
      radius: $radius
      address: $address
      city: $city
      neighborhood: $neighborhood
      polygon: $polygon
      lng: $lng
      mlsId: $mlsId
      zip: $zip
      maxBeds: $maxBeds
      maxBaths: $maxBaths
      thumbnail: $thumbnail
      headline: $headline
    ) {
      dateCreated
      id
      url
      title
      thumbnailURL
      headline
    }
  }
`;

export const CREATE_MARKET_REPORT_OPEN = gql`
  mutation createMarketReport(
    $geoJSON: geoJSONType
    $minBaths: Int
    $maxPrice: Int
    $lat: Float
    $state: String
    $propertyType: [String]
    $minBeds: Int
    $minPrice: Int
    $radius: Float
    $address: String
    $city: String
    $neighborhood: String
    $polygon: [[Float]]
    $lng: Float
    $mlsId: [Int]
    $zip: String
    $maxBeds: Int
    $maxBaths: Int
  ) {
    createReportRequest(
      geoJSON: $geoJSON
      minBaths: $minBaths
      maxPrice: $maxPrice
      lat: $lat
      state: $state
      propertyType: $propertyType
      minBeds: $minBeds
      minPrice: $minPrice
      radius: $radius
      address: $address
      city: $city
      neighborhood: $neighborhood
      polygon: $polygon
      lng: $lng
      mlsId: $mlsId
      zip: $zip
      maxBeds: $maxBeds
      maxBaths: $maxBaths
    ) {
      dateCreated
      id
      parameters {
        address
        bathsMax
        bathsMin
        bedsMax
        bedsMin
        city
        geoJSON {
          coordinates
        }
        lat
        lng
        mlsIDs
        priceMax
        priceMin
        propertyType
        radius
        sqFtMax
        sqFtMin
        state
        zipCode
      }
    }
  }
`;

export const EDIT_MARKET_REPORT = gql`
  mutation EditMarketReport(
    $id: Int!
    $title: String
    $minBaths: Int
    $maxPrice: Int
    $lat: Float
    $state: String
    $propertyType: [String]
    $minBeds: Int
    $minPrice: Int
    $radius: Float
    $address: String
    $city: String
    $neighborhood: String
    $polygon: [[Float]]
    $lng: Float
    $mlsId: [Int]
    $zip: String
    $maxBeds: Int
    $maxBaths: Int
    $thumbnail: String
    $headline: String
  ) {
    updateMarketReport(
      id: $id
      title: $title
      minBaths: $minBaths
      maxPrice: $maxPrice
      lat: $lat
      state: $state
      propertyType: $propertyType
      minBeds: $minBeds
      minPrice: $minPrice
      radius: $radius
      address: $address
      city: $city
      neighborhood: $neighborhood
      polygon: $polygon
      lng: $lng
      mlsId: $mlsId
      zip: $zip
      maxBeds: $maxBeds
      maxBaths: $maxBaths
      thumbnail: $thumbnail
      headline: $headline
    ) {
      dateCreated
      id
      url
      title
      thumbnailURL
      headline
    }
  }
`;

export const DELETE_MARKET_REPORT = gql`
  mutation DeleteMarketReport($id: Int!) {
    deleteMarketReport(id: $id)
  }
`;

export const SEND_REPORT = gql`
  mutation sendReport($contactEmail: String!, $reportID: Int!, $contactFirstName: String, $contactLastName: String, $contactPhone: String, $frequency: reportFrequency) {
    emailContactReport(contactEmail: $contactEmail, reportID: $reportID, frequency: $frequency, contactPhone: $contactPhone, contactFirstName: $contactFirstName, contactLastName: $contactLastName) {
      newContact {
        id
        reportEmailStats {
          lastReportSent
        }
        email {
          email
        }
        firstName
      }
      subscribed
    }
  }
`;

export const SEND_REPORT_OPEN = gql`
  mutation SendReportOpen($reportID: Int!, $contactFirstName: String, $contactLastName: String, $contactPhone: String, $frequency: reportFrequency, $username: String!, $contactEmail: String!) {
    emailContactReport(
      reportID: $reportID
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      contactPhone: $contactPhone
      frequency: $frequency
      username: $username
      contactEmail: $contactEmail
    ) {
      newContact {
        email {
          email
        }
        firstName
      }
      subscribed
    }
  }
`;

export const SUBSCRIBE_CONTACT_TO_REPORT_FROM_LANDING_PAGE = gql`
  mutation SubscribeContactToReport($frequency: reportFrequency!, $title: String, $headline: String, $contactID: Int!, $reportRequestID: Int!, $thumbnail: String) {
    subscribeContactToReport(frequency: $frequency, title: $title, headline: $headline, contactID: $contactID, reportRequestID: $reportRequestID, thumbnail: $thumbnail) {
      createdAt
      frequency
      lastQueuedAt
      lastSentAt
      id
      title
      opened
      personId
      receiveEmail
      reportRequestId
      saved
      sent
    }
  }
`;

export const UPDATE_CONTACT_SUBSCRIPTION = gql`
  mutation UpdateContactSubscription($contactID: Int!, $subscriptionID: Int!, $frequency: reportFrequency, $title: String, $headline: String) {
    updateSubscription(contactID: $contactID, subscriptionID: $subscriptionID, frequency: $frequency, title: $title, headline: $headline) {
      dateCreated
      dateRun
      headline
      id
      requestID
      subscriberCount
      thumbnailURL
      title
      url
    }
  }
`;
