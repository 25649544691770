import React, { useState, useEffect, useContext } from "react";
import { Clear } from "@material-ui/icons";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Grid, Button, withStyles } from "@material-ui/core";
import { AppStateContext, AppDispatchContext } from "../../../Context/AppReducer";
import CreateContactLandingPage from "./CreateContactLandingPage";

const useStyles = makeStyles((theme) =>
  createStyles({
    createReportContentContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
      maxWidth: "1600px",
      maxHeight: "930px",
      zIndex: "10",
      background: "#fff",
      borderRadius: "20px",
      overflow: "hidden",
    },
    headerBar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "25px 35px",
    },
    headerBarTitle: {
      color: "black",
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      margin: "0px",
      width: "100%",
      textAlign: "center",
      marginLeft: "32px",
    },
    headerBarIcon: {
      color: "#282828",
      fontSize: "32px",
      cursor: "pointer",
    },
    topGrid: {
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    subTitle: {
      fontWeight: "700",
      fontSize: "16px",
      fontFamily: " nunito",
      color: "#A3A3A3",
      maxWidth: "337px",
      textAlign: "center",
    },
    properties: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      color: "#009EEB",
      marginTop: "56px",
      marginBottom: "50px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "40px 0px",
    },
    image: {
      width: "100%",
      maxWidth: "245px",
    },
    numberHolder: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    svg: {
      marginLeft: "10px",
      marginTop: "3px",
    },
    arrowParent: {
      filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
    },
    arrowBox: {
      display: "flex",
      top: "-42px",
      left: "22px",
      position: "absolute",
      width: "218px",
      background: "#FFFFFF",
      borderRadius: "10px",
      fontFamily: "nunito",
      fontWeight: "600",
      fontSize: "13px",
      "&::after": {
        content: `''`,
        position: "absolute",
        top: "62%",
        right: "100%",
        width: "0",
        height: "0",
        border: "solid transparent",
        borderColor: "rgba(136, 183, 213, 0)",
        borderRightColor: "#FFFFFF",
        marginTop: "-30px",
        borderWidth: "15px",
      },
    },
    arrowText: {
      color: "#A3A3A3",
      padding: "15px 0px 13px 18px",
    },
    tootTipClose: {
      padding: "9px 9px 0px 0px",
      color: "#AAAAAA",
      fontSize: "27px",
      cursor: "pointer",
    },
    noSvg: {
      display: "none",
    },
    "@media (max-width: 950px)": {
      arrowBox: {
        width: "162px",
        top: "-50px",
      },
    },
    "@media (max-width: 600px)": {
      headerBarTitle: {
        fontSize: "18px",
      },
      headerBarIcon: {
        fontSize: "24px",
      },
    },
  })
);

const EditButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    background: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "11px 25.5px",
    borderRadius: "10px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const ContinueButton = withStyles((theme) => ({
  root: {
    color: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 10px",
    borderRadius: "7px",
    transition: "0.2s",
    textTransform: "lowercase",
    marginLeft: "10px",
  },
}))(Button);

const CreateReportTooLittle = ({
  user,
  setPreSubmit,
  preReportData,
  setResultsOpen,
  landingPageView,
  setCreateContactOpen,
  createContactOpenView,
  setCreateContactOpenView,
  setContactFrequency,
  contactFrequency,
}) => {
  const classes = useStyles();
  const globalDispatch = useContext(AppDispatchContext);
  const globalState = useContext(AppStateContext);
  const contactWasMade = globalState?.contactWasMade;

  const [svgClick, setSvgClick] = useState(false);

  const handleAddContactCheck = () => {
    if (landingPageView) {
      setCreateContactOpenView(true);
    } else {
      setResultsOpen(false);
      setPreSubmit(true);
    }
  };

  useEffect(() => {
    if (contactWasMade && landingPageView) {
      setResultsOpen(false);
      setPreSubmit(true);
      globalDispatch({ type: "setContactWasMade", payload: false });
    }
  }, [contactWasMade, landingPageView]);

  return (
    <div className={classes.createReportContentContainer}>
      <div className={classes.headerBar}>
        <h1 className={classes.headerBarTitle}>{!createContactOpenView ? "hmm...Let’s improve your report!" : "Almost there! Just one more thing."}</h1>
        <Clear
          fontSize="inherit"
          color="inherit"
          onClick={() => {
            setResultsOpen(false);
          }}
          className={classes.headerBarIcon}
        />
      </div>
      {!createContactOpenView ? (
        <Grid className={classes.topGrid} container>
          <div className={classes.subTitle}>With the information you provided, your report is showing</div>
          <div className={classes.numberHolder}>
            <div className={classes.properties}>{preReportData?.listingCount} properties</div>
            <svg
              onPointerEnter={() => {
                setSvgClick(true);
              }}
              className={classes.svg}
              width="24"
              height="21"
              viewBox="0 0 24 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.99938 20.6004C2.49938 20.9004 2.99938 21.0004 3.49938 21.0004H20.4994C21.2994 21.0004 21.9994 20.7004 22.4994 20.1004C23.0994 19.6004 23.3994 18.8004 23.3994 18.0004C23.3994 17.5004 23.2994 17.0004 22.9994 16.5004L14.5994 2.40039C14.0994 1.70039 13.4994 1.20039 12.6994 1.00039C11.8994 0.800391 11.0994 0.900391 10.3994 1.30039C9.99938 1.50039 9.59938 1.90039 9.39938 2.30039L0.899377 16.5004C0.0993767 17.9004 0.599377 19.8004 1.99938 20.6004ZM2.69938 17.5004L11.0994 3.40039C11.1994 3.20039 11.2994 3.10039 11.4994 3.00039C11.9994 2.80039 12.5994 2.90039 12.8994 3.40039L21.2994 17.5004C21.3994 17.7004 21.3994 17.8004 21.3994 18.0004C21.3994 18.3004 21.2994 18.5004 21.0994 18.7004C20.8994 18.9004 20.6994 19.0004 20.3994 19.0004H3.49938C3.39938 19.0004 3.19938 19.0004 3.09938 18.9004C2.59938 18.6004 2.39938 18.0004 2.69938 17.5004ZM12.9994 12.0004V8.00039C12.9994 7.40039 12.5994 7.00039 11.9994 7.00039C11.3994 7.00039 10.9994 7.40039 10.9994 8.00039V12.0004C10.9994 12.6004 11.3994 13.0004 11.9994 13.0004C12.5994 13.0004 12.9994 12.6004 12.9994 12.0004ZM12.9994 16.0004C12.9994 16.3004 12.8994 16.5004 12.6994 16.7004C12.4994 16.9004 12.2994 17.0004 11.8994 17.0004C11.5994 17.0004 11.3994 16.9004 11.1994 16.7004C10.9994 16.5004 10.8994 16.3004 10.8994 16.0004C10.8994 15.8832 10.9337 15.8004 10.9621 15.7318C10.9822 15.6832 10.9994 15.6418 10.9994 15.6004C10.9994 15.5004 11.0994 15.4004 11.1994 15.3004C11.2994 15.2004 11.3994 15.1004 11.4994 15.1004C11.6994 15.0004 11.8994 15.0004 12.0994 15.0004C12.1569 15.0579 12.1813 15.0823 12.2107 15.0927C12.2324 15.1004 12.2569 15.1004 12.2994 15.1004C12.3494 15.1004 12.3744 15.1254 12.3994 15.1504C12.4244 15.1754 12.4494 15.2004 12.4994 15.2004C12.5994 15.2004 12.6994 15.3004 12.6994 15.3004C12.7994 15.4004 12.8994 15.5004 12.8994 15.6004C12.9994 15.7004 12.9994 15.9004 12.9994 16.0004Z"
                fill="#EB5757"
              />
            </svg>
            <div className={`${!svgClick ? classes.noSvg : null} ${classes.arrowParent}`}>
              <div className={classes.arrowBox}>
                <div className={classes.arrowText}>We recommend expanding your filters to showcase MORE properties for more accurate information on your area. </div>
                <Clear
                  fontSize="inherit"
                  color="inherit"
                  onClick={() => {
                    setSvgClick(false);
                  }}
                  className={classes.tootTipClose}
                />
              </div>
            </div>
          </div>
          <img alt="report results" className={classes.image} src="/assets/report-results-2.png" />
          <div className={classes.buttonContainer}>
            <EditButton
              onClick={() => {
                setResultsOpen(false);
              }}
            >
              Edit Report
            </EditButton>
            <ContinueButton
              onClick={() => {
                handleAddContactCheck();
              }}
            >
              Continue
            </ContinueButton>
          </div>
        </Grid>
      ) : null}
      {createContactOpenView ? <CreateContactLandingPage setContactFrequency={setContactFrequency} contactFrequency={contactFrequency} user={user}></CreateContactLandingPage> : null}
    </div>
  );
};

CreateReportTooLittle.propTypes = {
  user: PropTypes.string,
  resultsOpen: PropTypes.bool,
  setResultsOpen: PropTypes.func,
  setPreSubmit: PropTypes.func,
  preReportData: PropTypes.object,
  createContactOpen: PropTypes.object,
  setCreateContactOpen: PropTypes.func,
  createContactOpenView: PropTypes.bool,
  setCreateContactOpenView: PropTypes.func,
  setContactFrequency: PropTypes.func,
  contactFrequency: PropTypes.string,
};

export default CreateReportTooLittle;
