import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import MarketReportHeader from "./MarketReportHeader/MarketReportHeader";
import RecentReportsCardComps from "../MarketReports/MarketReportsCardComps/MarketReportCardComps";

const useStyles = makeStyles((theme) =>
  createStyles({
    marketReportContainer: {
      width: "100%",
      height: "100%",
      padding: "60px 80px 60px 180px",
    },
  })
);

const MarketReportsComponent = ({ userId, user, setCreateReportOpen, setSavedReport, savedReport }) => {
  const classes = useStyles();

  return (
    <div className={classes.marketReportContainer}>
      <MarketReportHeader setSavedReport={(status) => setSavedReport(status)} setCreateReportOpen={(status) => setCreateReportOpen(status)} />
      <RecentReportsCardComps userId={userId} user={user} savedReport={savedReport} setSavedReport={setSavedReport} setCreateReportOpen={(status) => setCreateReportOpen(status)} />
    </div>
  );
};

MarketReportsComponent.propTypes = {
  user: PropTypes.string,
  userId: PropTypes.number,
  setCreateReportOpen: PropTypes.func,
  savedReport: PropTypes.bool,
  setSavedReport: PropTypes.func,
};

export default MarketReportsComponent;
