import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import { AppDispatchContext } from "../../../../../Context/AppReducer";
import { withRouter } from "react-router-dom";
import HeaderStylesheet from "../HeaderStylesheet";
import { Grid, Fade, IconButton, withStyles, TextField, CircularProgress, makeStyles, createStyles, Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { saveState } from "../../../../../Helpers/localStorage";
import useLogin from "../../../../../Services/Rest/loginHook";
import useSignup from "../../../../../Services/Rest/signupHook";
import GoogleLoginButton from "../../../../Common/GoogleLogin/GoogleLogin";
import FacebookLoginButton from "../../../../Common/FacebookLogin/FacebookLogin";

const ReportContactInput = withStyles({
  root: {
    width: "100%",
    overflow: "hidden",
    maxWidth: "471px",
    height: "43px",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    "& label": {
      marginTop: "-3px",
      color: "#CDCDCD",
      paddingLeft: "14px",
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "14px",
    },
    "& label.Mui-focused": {
      color: "#777777",
      fontSize: "14px",
      paddingTop: "0px",
    },
    "& .MuiInputLabel-shrink": {
      paddingTop: "0px",
      fontSize: "14px",
    },
    "& .MuiFilledInput-root": {
      width: "100%",
      padding: "0px",
      fontSize: "17px",
      fontWeight: "600",
      fontFamily: "nunito",
      height: "45px",
      color: "#000",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
      "&.MuiFilledInput-underline:after": {
        display: "none",
      },
    },
  },
})(TextField);

const SampleButton = withStyles((theme) => ({
  root: {
    color: "#FFF",
    minWidth: "107px",
    top: "0",
    right: "0",
    padding: "15px 29px",
    background: "linear-gradient(180deg, #FF507A 0%, #E14864 100%)",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Nunito",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "700",
    "&:hover": {
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) =>
  createStyles({
    loginParent: {
      top: "0",
      position: "absolute",
      padding: "195px 0px 0px 152px",
    },
    loginTitle: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "24px",
      lineHeight: "32px",
      marginTop: "22px",
      marginBottom: "34px",
    },
    backButton: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#D33141",
      cursor: "pointer",
    },
    goBack: {
      display: "flex",
      marginTop: "35px",
      alignItems: "center",
      justifyContent: "space-between",
      maxWidth: "471px",
    },
    loginParentLeft: {
      display: "flex",
    },
    alreadyHasAccount: {
      textDecoration: "underline",
      cursor: "pointer",
      color: "#D33141",
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "16px",
      marginRight: "16px",
    },
    forgetPassword: {
      textDecoration: "underline",
      cursor: "pointer",
      color: "##777D7Få",
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "16px",
    },
    topText: {
      margin: "23px 0px 0px",
    },
    bottomText: {
      marginTop: "26px",
    },
    viewContainerSmall: {
      width: "100%",
    },
    loginError: {
      color: "red",
      marginLeft: "30px",
    },
    progress: {
      height: "19px !important",
      color: "#FFF",
      width: "19px !important",
    },
    orDiv: {
      color: "#7E7E7E",
      fontFamily: "nunito",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "19px",
      margin: "0px 15px",
    },
    borderDiv: {
      width: "100%",
      border: "1px solid #7E7E7E",
      height: "0px",
    },
    dividerDiv: {
      display: "flex",
      maxWidth: "471px",
      alignItems: "center",
    },
    googleButton: {
      marginBottom: "19px",
    },
    facebookButton: {
      marginBottom: "23px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    emailAlreadyInUseError: {
      color: "red",
      maxWidth: "471px",
      padding: "0px 10px 10px",
    },
    "@media (max-width: 800px)": {
      loginParent: {
        top: "0",
        position: "absolute",
        padding: "195px 60px 0px 60px",
      },
    },
  })
);

const Login = ({ activeView, setActiveView, history, setForgotOpen, setForgotValue }) => {
  const classes = useStyles();
  const { handleSubmit, register, errors } = useForm();
  const globalDispatch = useContext(AppDispatchContext);
  const [user, loginUser] = useLogin();
  const [userSignup, signupUser] = useSignup();
  const [token, setToken] = useState("");
  const [emailValue, setEmailValue] = useState("");

  useEffect(() => {
    if (user.isSuccessful) {
      if (!token) {
        saveState("areaPulseUserToken", user.result.user.token);
        setToken(user.result.user.token);
      } else {
        globalDispatch({ type: "login", payload: { ...user.result.user, mlsLinks: user.result.mlsLinks } });
        history.push(`/${user.result.user.username}/dashboard`);
      }
    } else if (userSignup.isSuccessful) {
      if (!token) {
        saveState("areaPulseUserToken", userSignup.result.token);
        setToken(userSignup.result.token);
      } else {
        globalDispatch({ type: "login", payload: { ...userSignup.result, mlsLinks: userSignup.result.mlsLinks } });
        history.push(`/${userSignup.result.username}/dashboard`);
      }
    }
  }, [user, userSignup, token, globalDispatch, history]);

  const facebookButtonClicked = () => {
    console.log("Facebook Button Clicked");
  };
  const responseFacebook = (res) => {
    const firstName = res.name.split(" ")[0];
    const lastName = res.name.split(" ")[1];
    const username = `${firstName.toLowerCase()}${lastName.toLowerCase()}`;
    const facebookPayload = {
      username,
      email: res.email,
      firstName,
      lastName,
      password: "facebook",
      rePassword: "facebook",
      type: "facebook",
      socialToken: res.accessToken,
      socialUserID: res.userID,
    };
    signupUser(facebookPayload);
  };

  const googleButtonClicked = () => {
    console.log("Google Button Clicked");
  };
  const responseGoogle = (res) => {
    const firstName = res.profileObj.givenName;
    const lastName = res.profileObj.familyName;
    const username = `${firstName.toLowerCase()}${lastName.toLowerCase()}`;
    const googlePayload = {
      username,
      email: res.profileObj.email,
      firstName,
      lastName,
      password: "google",
      rePassword: "google",
      type: "google",
      socialToken: res.accessToken,
      socialUserID: res.profileObj.googleId,
    };
    signupUser(googlePayload);
  };

  const onSubmit = (values) => {
    const emailPayload = {
      username: values.email,
      password: values.password,
    };
    loginUser(emailPayload);
  };
  return (
    <Fade in={activeView === 3} timeout={600} className={classes.loginParent}>
      <Grid container className={classes.onBoardingContainer}>
        <div className={classes.viewContainerSmall}>
          <div
            onClick={() => {
              setActiveView(2);
            }}
            className={classes.backButton}
          >
            <ArrowBack /> Back
          </div>
          <div className={classes.loginTitle}>Login</div>
          <div className={classes.googleButton}>
            {/* <GoogleLoginButton loading={userSignup?.isFetching} size="large" clicked={() => googleButtonClicked()} response={(res) => responseGoogle(res)} /> */}
          </div>
          <div className={classes.facebookButton}>
            <FacebookLoginButton loading={userSignup?.isFetching} size="large" clicked={() => facebookButtonClicked()} response={(res) => responseFacebook(res)} />
          </div>
          {userSignup.errorMessage === "Email is taken by another account" && (
            <div className={classes.emailAlreadyInUseError}>The email associated with your social media account is already being used. Please login with your email.</div>
          )}

          <div className={classes.dividerDiv}>
            <div className={classes.borderDiv}></div>
            <div className={classes.orDiv}>OR</div>
            <div className={classes.borderDiv}></div>
          </div>
          <form className={classes.form} onSubmit={handleSubmit((data) => onSubmit(data))} id="sign-in-form">
            <ReportContactInput
              className={classes.topText}
              inputProps={{
                style: {
                  padding: "22px 26px 9px",
                },
                name: "email",
              }}
              onChange={(e) => {
                setEmailValue(e.target.value);
              }}
              variant="filled"
              label="Email Address"
              error={Boolean(errors.email)}
              type="email"
              inputRef={register({
                validate: (value) => value !== "" || "Please enter an email address",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Please Enter A Valid Email Address",
                },
              })}
            />
            {errors.email ? <div className={classes.loginError}>{errors.email?.message}</div> : null}
            <ReportContactInput
              inputProps={{
                style: {
                  padding: "22px 26px 9px",
                },
                name: "password",
              }}
              variant="filled"
              label="Password"
              error={Boolean(errors.password)}
              type="password"
              inputRef={register({
                validate: (value) => value !== "" || "Please enter a password",
              })}
              className={classes.bottomText}
            />
            {errors.password ? <div className={classes.loginError}>{errors.password?.message}</div> : null}
            {!user?.isSuccessful ? <div className={classes.loginError}>{user?.errorMessage}</div> : null}
          </form>
          <div className={classes.goBack}>
            <div className={classes.loginParentLeft}>
              <div className={classes.alreadyHasAccount} onClick={() => setActiveView(2)}>
                Create An Account
              </div>
              <div
                className={classes.forgetPassword}
                onClick={() => {
                  setForgotOpen(true);
                  setForgotValue(emailValue);
                }}
              >
                Forgot Password
              </div>
            </div>
            <div className={classes.loginParentRight}>
              <SampleButton className={`${classes.nextSlideButton} ${classes.allInfoIsGood}`} type="submit" form="sign-in-form">
                {user?.isFetching ? <CircularProgress className={classes.progress} /> : "LOG IN"}
              </SampleButton>
            </div>
          </div>
        </div>
      </Grid>
    </Fade>
  );
};

Login.propTypes = {
  setForgotValue: PropTypes.func,
  setForgotOpen: PropTypes.func,
  activeView: PropTypes.number,
  setActiveView: PropTypes.func,
};

export default withRouter(Login);
