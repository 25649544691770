import { useReducer } from "react";
import axios from "axios";
import { getBasePath } from "../../Helpers/getEnvVar";
import { loadState } from "../../Helpers/localStorage";

const uploadLogoHookInitialState = {
  isFetching: false,
  isSuccessful: false,
  errorMessage: "",
  result: {},
};

const uploadLogoHookReducer = (state, action) => {
  switch (action.type) {
    case "fetching":
      return {
        ...state,
        isFetching: true,
        isSuccessful: false,
        errorMessage: "",
        result: {},
      };
    case "success":
      return {
        ...state,
        isFetching: false,
        isSuccessful: true,
        result: { ...action.payload },
      };
    case "error":
      return {
        ...state,
        isFetching: false,
        isSuccessful: false,
        errorMessage: action.payload,
      };
    default:
      throw new Error("Unsupported Action");
  }
};

const useUploadLogo = () => {
  const [logo, dispatch] = useReducer(uploadLogoHookReducer, uploadLogoHookInitialState);
  const basePath = getBasePath();
  const token = loadState("areaPulseUserToken");

  const setUploadLogo = ({ userId, image }) => {
    let formData = new FormData();
    formData.append("image", image)
    axios({
      url: `${basePath}/user/${userId}/company-logo`,
      method: "POST",
      data: formData,
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      },
    })
      .then((data) => {
        const logoData = data?.data?.data;
        dispatch({
          type: "success",
          payload: logoData,
        });
      })
      .catch((err) => {
        dispatch({ type: "error", payload: err?.response?.data?.error });
      });
  };

  const uploadLogo = (payload) => {
    dispatch({ type: "fetching" });
    setUploadLogo(payload);
  };

  return [logo, uploadLogo];
};

export default useUploadLogo;
