import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { AppDispatchContext } from "../../../Context/AppReducer";
import {
  createStyles,
  makeStyles,
  withStyles,
  Button,
  Grid,
  TextField,
  Slide,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import { ADD_CONTACT_OPEN } from "../../../Services/Graph/Contacts/Mutations";
import { openApolloClient } from "../../../Services/Graph/apolloConfig";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) =>
  createStyles({
    topGrid: {
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    subTitle: {
      fontWeight: "700",
      fontSize: "16px",
      fontFamily: " nunito",
      color: "#A3A3A3",
      maxWidth: "337px",
      textAlign: "center",
    },
    inputContainer: {
      padding: "60px 30px 0px",
    },
    contactInput: {
      fontFamily: theme.typography.secondary,
      color: "#676767",
      fontSize: "18px",
      fontWeight: "600",
      borderRadius: "10px",
      border: "none",
    },
    buttonContainer: {
      flexDirection: "column",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "25px 0px 40px",
    },
    image: {
      width: "100%",
      maxWidth: "500px",
    },
    svg: {
      marginLeft: "10px",
      marginTop: "52px",
    },
    circle: {
      width: "20px !important",
      height: "20px !important",
      color: "#fff",
      margin: "4px 12px",
    },
    error: {
      color: "red",
      marginBottom: "20px",
    },
    roundSelect: {
      width: "100%",
      height: "55px",
    },
    selectControl: {
      width: "100%",
      height: "55px",
      backgroundColor: "#fff",
      borderRadius: "10px",
      borderRadius: "4px",
      border: "1px solid #c4c4c4",
      "&:hover": {
        border: "1px solid #000",
      },
    },
    "@media (max-width: 600px)": {
      inputContainer: {
        padding: "30px 30px",
      },
    },
  })
);

const EditButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    background: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "11px 25.5px",
    borderRadius: "10px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const RoundSelect = withStyles((theme) => ({
  root: {
    paddingTop: "7px",
    paddingLeft: "12px",
    backgroundColor: "transparent !important",
    "&.MuiFilledInput-underline:before": {
      display: "none",
    },
  },
  icon: {
    marginTop: "-2px",
    marginRight: "22px",
  },
}))(Select);

const CreateContactLandingPage = ({ user, setContactFrequency, contactFrequency }) => {
  const classes = useStyles();
  const globalDispatch = useContext(AppDispatchContext);
  const [formState, setFormState] = useState({});
  const [addContact, { data: contactData, error: contactError, loading: contactLoading }] = useMutation(
    ADD_CONTACT_OPEN,
    {
      variables: {
        username: user,
        firstName: formState.firstname,
        lastName: formState.lastname,
        email: formState.email,
        phone: formState.phone ?? "",
        streetAddress: formState.streetAddress ?? "",
        city: formState.city ?? "",
        state: formState.state ?? "",
        zip: formState.zip ?? "",
      },
      client: openApolloClient,
      update: (updateCache, mutationResult) => {
        globalDispatch({ type: "setLandingPageContact", payload: { ...mutationResult?.data?.createContact } });
      },
      onCompleted() {
        globalDispatch({ type: "setContactWasMade", payload: true });
      },
    }
  );

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      streetAddress: "",
      zip: "",
      city: "",
    },
  });

  const submit = (data) => {
    setFormState({ ...data });
    addContact();
  };

  return (
    <Slide direction={"left"} in={true} mountOnEnter unmountOnExit>
      <Grid className={classes.topGrid} container>
        <div className={classes.subTitle}>To see your report, you must register.</div>
        <form id="add-contact-form" onSubmit={handleSubmit(submit)}>
          <div className={classes.inputContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  className={classes.contactInput}
                  id="contact-first-name"
                  placeholder="First Name"
                  fullWidth
                  error={Boolean(errors.firstname)}
                  helperText={errors.firstname?.message}
                  InputProps={{
                    name: "firstname",
                    className: classes.input,
                  }}
                  inputRef={register({
                    validate: (value) => value !== "" || "Please enter your first name",
                  })}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  className={classes.contactInput}
                  id="contact-last-name"
                  placeholder="Last Name"
                  fullWidth
                  error={Boolean(errors.lastname)}
                  helperText={errors.lastname?.message}
                  InputProps={{
                    name: "lastname",
                    className: classes.input,
                  }}
                  inputRef={register({
                    validate: (value) => value !== "" || "Please enter your last name",
                  })}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  className={classes.contactInput}
                  id="contact-phone-number"
                  placeholder="Phone Number"
                  fullWidth
                  error={Boolean(errors.phone)}
                  helperText={errors.phone?.message}
                  InputProps={{
                    name: "phone",
                    className: classes.input,
                  }}
                  inputRef={register({
                    pattern: {
                      value: /^\d{10}$/i,
                      message: "Please Enter A Valid 10 digit phone number",
                    },
                  })}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  className={classes.contactInput}
                  id="contact-email"
                  placeholder="Email"
                  fullWidth
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  InputProps={{
                    name: "email",
                    className: classes.input,
                  }}
                  inputRef={register({
                    validate: (value) => value !== "" || "Please enter your email address",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please Enter A Valid Email Address",
                    },
                  })}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.selectControl}>
                  <RoundSelect
                    value={contactFrequency}
                    onChange={(e) => {
                      setContactFrequency(e.target.value);
                    }}
                    label="Frequency"
                    className={classes.roundSelect}
                    disableUnderline
                  >
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="biweekly">Bi-Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                  </RoundSelect>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.buttonContainer}>
            <div className={classes.error}>{contactError ? "There was a problem adding this contact" : null}</div>
            <EditButton disabled={contactLoading} type="submit" form="add-contact-form">
              {contactLoading ? <CircularProgress className={classes.circle} /> : "Save"}
            </EditButton>
          </div>
        </form>
      </Grid>
    </Slide>
  );
};

CreateContactLandingPage.propTypes = {
  user: PropTypes.string,
  setContactFrequency: PropTypes.func,
  contactFrequency: PropTypes.string,
};

export default CreateContactLandingPage;
