import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import Header from "./OptionThreeSections/Header";
import SearchBar from "./OptionThreeSections/SearchBar";
import LandingFooter from "./LandingFooter";

const useStyles = makeStyles((theme) =>
  createStyles({
    optionThreeContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    },
    contentContainer: {
      width: "100vw",
    },
    backgroundImage: {
      minHeight: "100%",
      width: "100%",
      height: "auto",
      position: "fixed",
      top: "0",
      left: "0",
      objectFit: "cover",
    },
    logoImage: {
      width: "auto",
      maxHeight: "75px",
      maxWidth: "160px",
      position: "absolute",
      top: "50px",
      left: "50px",
    },
    "@media (max-width: 960px)": {
      logoImage: {
        left: "initial",
      },
    },
  })
);

const OptionThree = ({ canCreate, setNoReport, userData, setCreateReportOpen }) => {
  const [backgroundImage, setBackgroundImage] = useState("/assets/areapulse-landing-t2-bg.jpg");
  const [logoImage, setLogoImage] = useState("/assets/w-logo.png");
  const classes = useStyles({ backgroundImage });

  useEffect(() => {
    if (userData?.user?.landingPageSettings?.backgroundImage !== "") {
      setBackgroundImage(userData?.user?.landingPageSettings?.backgroundImage);
    }

    if (userData?.user?.logoURL !== "") {
      setLogoImage(userData?.user?.logoURL);
    }
  }, [userData]);

  return (
    <div>
      <Grid container className={classes.optionThreeContainer}>
        <img className={classes.backgroundImage} src={backgroundImage}></img>
        <img className={classes.logoImage} src={logoImage}></img>
        <div className={classes.contentContainer}>
          <Grid item xs={12}>
            <Header userData={userData} />
          </Grid>
          <Grid item xs={12}>
            <SearchBar canCreate={canCreate} setNoReport={setNoReport} userData={userData} setCreateReportOpen={setCreateReportOpen} />
          </Grid>
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <LandingFooter userData={userData} />
        </Grid>
      </Grid>
    </div>
  );
};
OptionThree.propTypes = {
  userData: PropTypes.object,
  setCreateReportOpen: PropTypes.func,
  setNoReport: PropTypes.func,
  canCreate: PropTypes.bool,
};

export default OptionThree;
