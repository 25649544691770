import React, { useContext, useEffect, useState } from "react";
import { createStyles, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, CircularProgress, Fade } from "@material-ui/core";
import { GET_USER_BILLING_HISTORY } from "../../../../Services/Graph/UserSettings/Queries";
import { authApolloClient } from "../../../../Services/Graph/apolloConfig";
import { useLazyQuery } from "@apollo/client";
import UserSettingsBillingHistoryTableHeaders from "./UserSettingsBillingHistoryTableHeaders";
import { AppStateContext, AppDispatchContext } from "../../../../Context/AppReducer";
import { ErrorOutlineOutlined } from "@material-ui/icons";
import Moment from "react-moment";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundColor: "#F5F5F5",
      padding: "35px 53px",
      borderRadius: "20px",
    },
    tableContainer: {
      backgroundColor: "#F5F5F5",
      maxHeight: 440,
      overflow: "scroll",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    labelCenter: {
      textAlign: "center",
    },
    columnData: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "400",
      color: "#000",
      padding: "16px 6px",
      minWidth: "40px",
    },
    columnTitleData: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "400",
      color: "#818181",
      padding: "16px 6px",
      backgroundColor: "#F5F5F5",
      textTransform: "uppercase",
    },
    nameCell: {
      padding: "16px 16px 16px 0",
    },
    createdDate: {
      fontFamily: theme.typography.secondary,
      paddingTop: "30px",
      margin: "0",
      fontSize: "18px",
      fontWeight: "600",
      color: "#8D8989",
    },
    noInfoLabel: {
      fontFamily: theme.typography.secondary,
      fontSize: "22px",
      fontWeight: "700",
      color: "#000",
      textTransform: "capitalize",
    },
  })
);

const UserSettingsBillingHistoryTable = () => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const { user } = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);

  // QUERIES
  const [fetchUserBillingHistory, { data: billingHistoryData, loading: billingHistoryLoading, error: billingHistoryError }] = useLazyQuery(GET_USER_BILLING_HISTORY, {
    variables: {
      userId: user?.userId ?? 0,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const errorMessage = billingHistoryError?.networkError?.result?.error ?? "";
    if (billingHistoryError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [billingHistoryError, globalDispatch]);

  const rows = billingHistoryData?.billingHistory ?? [];

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    fetchUserBillingHistory();
  }, [fetchUserBillingHistory]);

  const sortHeaderCells = stableSort(rows, getComparator(order, orderBy));

  return (
    <div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table} aria-label="enhanced table">
          <UserSettingsBillingHistoryTableHeaders order={order} orderBy={orderBy} handleRequestSort={handleRequestSort} />
          <TableBody>
            {billingHistoryLoading && (
              <TableRow>
                <TableCell>
                  <CircularProgress
                    size={60}
                    thickness={3}
                    color="inherit"
                    className={classes.progress}
                    classes={{
                      circle: classes.circle,
                    }}
                  />
                </TableCell>
              </TableRow>
            )}
            {rows.length === 0 && rows !== undefined && billingHistoryLoading === false ? (
              <TableRow>
                <TableCell>
                  <div>
                    <Fade in={true} timeout={500}>
                      <p className={classes.noInfoLabel}>no billing history</p>
                    </Fade>
                  </div>
                </TableCell>
              </TableRow>
            ) : null}
            {billingHistoryError && (
              <TableRow>
                <TableCell>
                  <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.error} />
                </TableCell>
              </TableRow>
            )}
            {sortHeaderCells.map((row, index) => {
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" className={classes.columnData} scope="row" padding="none">
                    <Moment className={classes.createdDate} format="MM/DD/YYYY">
                      {row.createdAt}
                    </Moment>
                  </TableCell>
                  <TableCell className={classes.columnData}>${row.amount}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

UserSettingsBillingHistoryTable.propTypes = {};

export default UserSettingsBillingHistoryTable;
