import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Grid, Button, TextField, withStyles, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { openApolloClient, authApolloClient } from "../../Services/Graph/apolloConfig";
import { Skeleton } from "@material-ui/lab";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { useQuery, useMutation } from "@apollo/client";
import { GET_USER_DATA, GET_REPORT_DATA } from "../../Services/Graph/MarketReportPage/MarketReportPageQueries";
import { EDIT_MARKET_REPORT } from "../../Services/Graph/MarketReportPage/MarketReportPageMutations";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import getRoundAbreviation from "../../Helpers/getRoundAbreviation";

const useStyles = makeStyles((theme) =>
  createStyles({
    parentDiv: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "56px 63px 0px 50px",
    },
    twitterIcon: {
      color: "white",
    },
    buttonParent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      marginBottom: "40px",
    },
    hiddenBtn: {
      overflow: "hidden",
      height: "0px",
    },
    createReportButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    error: {
      textAlign: "center",
      marginTop: "10px",
      color: "red",
      fontFamily: "nunito",
      fontWeight: "700",
    },
    preview: {
      fontSize: "16px",
      fontWeight: " 700",
      fontFamily: " nunito",
      marginBottom: "16px",
    },
    cardImage: {
      height: "355px",
      width: "500px",
      marginBottom: "80px",
      boxShadow: "0px 15px 5px rgba(0, 0, 0, 0.0008), 0px 0px 13px rgba(0, 0, 0, 0.11)",
      borderRadius: "10px",
      padding: "0px 0px 37px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    colorsTop: {
      marginTop: "48px",
      marginLeft: "0px !important",
      height: "20px",
    },
    colorsBottom: {
      margin: "16px 0px 41px",
      height: "20px",
    },
    accent: {
      width: "301px",
      border: "1px solid #DADADA",
      marginBottom: "45px",
      marginLeft: "0px",
      height: "0px",
    },
    leftGrid: {
      paddingRight: "30px",
    },
    rightGrid: {
      paddingLeft: "65px",
    },
    createReportContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "12px 0px 12px 0px",
      paddingRight: "42px",
    },
    buttonHolder: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "100px",
    },
    createReportButtonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "11px 10px 11px 17px",
      background: "#FAFAFA",
      borderRadius: "10px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      cursor: "pointer",
      width: "221px",
    },
    createReportButtonLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "16px",
      color: "#333",
    },
    createReportTwitterButton: {
      background: "#009EEB",
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: "$009EEB",
      },
    },
    createReportButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    cardTitle: {
      fontFamily: "roboto",
      fontWeight: "500",
      fontSize: "12px",
      color: "#8D8D8D",
      textTransform: "uppercase",
    },
    middleCards: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "flex-end",
      margin: "97px 0px 33px",
    },
    arrowParent: {
      filter: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.1))",
    },
    smallCards: {
      display: "flex",
      justifyContent: "Center",
      flexDirection: "column",
      alignItems: "center",
      padding: "11px 17px 6.5px",
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      fontFamily: "roboto",
      fontWeight: "700",
      fontSize: "18px",
      color: "#282828",
      height: "56.5px",
      width: "103px",
      "&::after": {
        content: `''`,
        position: "absolute",
        bottom: "100%",
        width: "0",
        height: "0",
        border: "solid transparent",
        borderColor: "rgba(136, 183, 213, 0)",
        borderBottomColor: "#FFFFFF",
        borderWidth: "8px",
      },
    },
    bigCard: {
      display: "flex",
      justifyContent: "Center",
      flexDirection: "column",
      alignItems: "center",
      fontFamily: "roboto",
      fontWeight: "700",
      fontSize: "24px",
      color: "#282828",
      padding: "11px 10px 11.5px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "5px",
      margin: "0px 33px 5px",
      height: "73px",
      width: "163px",
      "&::after": {
        content: `''`,
        position: "absolute",
        bottom: "49.5%",
        width: "0",
        height: "0",
        border: "solid transparent",
        borderColor: "rgba(136, 183, 213, 0)",
        borderBottomColor: "#FFFFFF",
        borderWidth: "8px",
      },
    },
    cardSubTitles: {
      fontFamily: "roboto",
      fontWeight: "500",
      fontSize: "12px",
      color: "#8D8D8D",
    },
    bottomCard: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
    },
    bottomNumber: {
      fontFamily: "roboto",
      fontWeight: "700",
      fontSize: "30px",
      color: "#282828",
    },
    bottomSubTitles: {
      fontFamily: "roboto",
      fontWeight: "400",
      fontSize: "14px",
      color: "#8D8D8D",
      width: "61px",
    },
    lineDiv: {
      height: "42px",
      backgroundColor: "black",
      width: "1px",
      margin: "0px 8px",
    },
    numberParents: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    numberParentsMiddle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      margin: "0px 40px",
    },
    reportTitle: {
      maxWidth: "405px",
      fontFamily: "roboto",
      fontWeight: "700",
      fontSize: "24px",
      color: "#282828",
      marginTop: "7px",
      zIndex: "100",
      height: "34px",
    },
    imgCard: {
      position: "absolute",
      zIndex: "1",
    },
    zCard: {
      zIndex: "10",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "absolute",
      marginTop: "26px",
    },
    whiteLine: {
      backgroundColor: "#FFFFFF",
      width: "1px",
      height: "81px",
      position: "absolute",
      marginBottom: "123px",
    },
    firstBall: {
      width: "12px",
      height: "12px",
      backgroundColor: "#FFFFFF",
      boxShadow: " 0px 2px 6px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "150px",
      position: "absolute",
    },
    firstBall2: {
      width: "6px",
      height: "6px",
      backgroundColor: "#2D9CDB",
      borderRadius: "10px",
    },
    firstBall3: {
      width: "12px",
      height: "12px",
      backgroundColor: "#FFFFFF",
      boxShadow: " 0px 2px 6px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "123px",
      position: "absolute",
    },
    whiteLine2: {
      backgroundColor: "#FFFFFF",
      width: "1px",
      height: "137px",
      position: "absolute",
      marginBottom: "201px",
    },
    secondBall: {
      width: "12px",
      height: "12px",
      backgroundColor: "#FFFFFF",
      boxShadow: " 0px 2px 6px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "235px",
      position: "absolute",
    },
    secondBall2: {
      width: "6px",
      height: "6px",
      backgroundColor: "#2D9CDB",
      borderRadius: "10px",
    },
    skeleton: {
      borderRadius: "10px",
      marginBottom: "80px",
    },
    whiteColor: {
      color: "#FFFFFF",
    },
    whiteBack: {
      backgroundColor: "#FFFFFF",
    },
    circleOutline: {
      padding: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "18px",
      width: "18px",
      border: "2px solid",
      borderColor: theme.palette.areaPulseBlue,
      borderRadius: "100px",
      backgroundColor: "#fff",
    },
    filled: {
      height: "12px",
      width: "12px",
      backgroundColor: theme.palette.areaPulseBlue,
      borderRadius: "100px",
    },
    requireParent: {
      display: "flex",
      alignItems: "center",
      marginLeft: "23px",
      marginTop: "10px",
    },
    require: {
      fontFamily: "nunito",
      fontWeight: "600",
      fontSize: "16px",
      color: "#000",
      marginLeft: "16px",
    },
    circle: {
      color: theme.palette.areaPulseBlue,
      height: "18px !important",
      width: "18px !important",
    },
    "@media (max-width: 1405px)": {
      parentDiv: {
        padding: "56px 63px 0px 10px",
      },
    },
    "@media (max-width: 1280px)": {
      rightGrid: {
        paddingLeft: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      },
      leftGrid: {
        paddingRight: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        width: "100%",
      },
      createReportContainer: {
        padding: "0px 20px",
      },
      cardImage: {
        marginBottom: "25px",
      },
      skeleton: {
        marginBottom: "20px",
      },
      parentDiv: {
        padding: "15px 63px 0px 50px",
      },
      accent: {
        display: "none",
      },
    },
  })
);

const ReportContactInput = withStyles({
  root: {
    marginTop: "125px",
    width: "292px",
    minHeight: "134px",
    maxHeight: "134px",
    backgroundColor: "#FAFAFA",
    boxShadow: "inset 0px 0px 7px rgba(0, 0, 0, 0.08)",
    borderRadius: "10px",
    "& label": {
      color: "#777777",
      marginLeft: "11px",
    },
    "& label.Mui-focused": {
      color: "#777777",
    },
    "& .MuiFilledInput-root": {
      color: "#000",
      marginLeft: "11px",
      backgroundColor: "transparent",
      borderRadius: "10px",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
      "&.MuiFilledInput-underline:after": {
        display: "none",
      },
    },
    "@media (max-width: 1280px)": {
      marginTop: "0px",
      marginBottom: "5px",
    },
  },
})(TextField);

const ShareSocially = ({ user, reportId, whichReportURL }) => {
  const classes = useStyles();
  const [messageValue, setMessageValue] = useState(`Check out`);
  const [required, setRequired] = useState(false);
  const [buttonPushed, setButtonPushed] = useState(false);
  const [themeColors, setThemeColors] = useState("1");

  const simulateFbClick = () => {
    document.querySelector("#st-first").click();
  };
  const simulateTwClick = () => {
    document.querySelector("#st-last").click();
  };
  const handleMessage = (e) => {
    setMessageValue(e.target.value);
  };

  const { data: reportData, loading: reportLoading } = useQuery(GET_REPORT_DATA, {
    variables: {
      id: reportId,
    },
    client: openApolloClient,
    fetchPolicy: "network-only",
  });

  // const [updateRequired, { loading: updateLoading }] = useMutation(EDIT_MARKET_REPORT, {
  //   variables: {
  //     id: reportId,
  //     requireRegistration: required,
  //   },
  //   client: authApolloClient,
  // });

  const { data: userData } = useQuery(GET_USER_DATA, {
    variables: {
      username: user,
    },
    client: openApolloClient,
    fetchPolicy: "network-only",
  });

  // useEffect(() => {
  //   if (buttonPushed) {
  //     updateRequired();
  //     setButtonPushed(false);
  //   }
  // }, [required]);

  useEffect(() => {
    if (reportData && !reportLoading) {
      setMessageValue(`Check out ${reportData?.report?.title}`);
      // setRequired(reportData?.report?.requireRegistration);
    }
  }, [reportData, reportLoading]);

  return (
    <div className={classes.parentDiv}>
      <div className={classes.hiddenBtn}>
        <FacebookShareButton id="st-first" quote={messageValue} url={whichReportURL}></FacebookShareButton>
        <TwitterShareButton id="st-last" url={whichReportURL} title={messageValue}></TwitterShareButton>
      </div>
      <Grid container>
        <Grid className={classes.leftGrid} lg={4} md={12} item>
          <ReportContactInput
            onChange={(e) => {
              handleMessage(e);
            }}
            value={messageValue}
            rowsMax={5}
            multiline
            variant="filled"
            label="Your Message Here"
          />
          {/* <div className={classes.requireParent}>
            {updateLoading || reportLoading ? (
              <CircularProgress className={classes.circle} />
            ) : (
              <button
                disabled={updateLoading}
                onClick={() => {
                  setRequired(!required);
                  setButtonPushed(true);
                }}
                className={classes.circleOutline}
              >
                {required ? <div className={classes.filled} /> : <div />}
              </button>
            )}
            <div className={classes.require}>Force Registration</div>
          </div> */}
        </Grid>
        <Grid className={classes.rightGrid} lg={8} md={12} item>
          <div className={classes.preview}>Preview</div>
          {reportData !== undefined && !reportLoading ? (
            <div
              style={{
                background:
                  themeColors === "2"
                    ? userData?.user?.reportSettings?.themeColorGradient
                    : "linear-gradient(180deg, #E0E0E0 8.36%, rgba(255, 255, 255, 0) 52.95%)",
              }}
              className={`${classes.cardImage}`}
            >
              <img alt="line shape" src="/assets/line-shape.png" />
              <div className={classes.zCard}>
                <div className={classes.cardTitle}>areapulse Market Report for: </div>
                <div className={classes.reportTitle}>{reportData?.report?.title}</div>
                <div className={classes.middleCards}>
                  <div className={classes.arrowParent}>
                    <div className={classes.smallCards}>
                      <div className={classes.whiteLine}></div>
                      <div className={classes.firstBall}>
                        <div className={classes.firstBall2}></div>
                      </div>
                      <div>{getRoundAbreviation(reportData?.report?.stats?.byStatus[1]?.minPrice)}</div>
                      <div className={classes.cardSubTitles}>LOW</div>
                    </div>
                  </div>
                  <div className={classes.bigCard}>
                    <div className={classes.whiteLine}></div>
                    <div className={classes.firstBall3}>
                      <div className={classes.firstBall2}></div>
                    </div>
                    <div>{getRoundAbreviation(reportData?.report?.stats?.byStatus[1]?.avgPrice)}</div>
                    <div className={classes.cardSubTitles}>AVERAGE HOME PRICE</div>
                  </div>
                  <div className={classes.arrowParent}>
                    <div className={classes.smallCards}>
                      <div className={classes.whiteLine2}></div>
                      <div className={classes.secondBall}>
                        <div className={classes.secondBall2}></div>
                      </div>
                      <div>{getRoundAbreviation(reportData?.report?.stats?.byStatus[1]?.maxPrice)}</div>
                      <div className={classes.cardSubTitles}>HIGH</div>
                    </div>
                  </div>
                </div>
                <div className={classes.bottomCard}>
                  <div className={classes.numberParents}>
                    <div className={`${classes.bottomNumber} ${themeColors === "2" ? classes.whiteColor : null}`}>
                      {reportData?.report?.stats?.byStatus[0]?.count}
                    </div>
                    <div className={`${classes.lineDiv} ${themeColors === "2" ? classes.whiteBack : null}`}></div>
                    <div className={`${classes.bottomSubTitles} ${themeColors === "2" ? classes.whiteColor : null}`}>
                      ACTIVE HOMES
                    </div>
                  </div>
                  <div className={classes.numberParentsMiddle}>
                    <div className={`${classes.bottomNumber} ${themeColors === "2" ? classes.whiteColor : null}`}>
                      {reportData?.report?.stats?.byStatus[1]?.count}
                    </div>
                    <div className={`${classes.lineDiv} ${themeColors === "2" ? classes.whiteBack : null}`}></div>
                    <div className={`${classes.bottomSubTitles} ${themeColors === "2" ? classes.whiteColor : null}`}>
                      PENDING HOMES
                    </div>
                  </div>
                  <div className={classes.numberParents}>
                    <div className={`${classes.bottomNumber} ${themeColors === "2" ? classes.whiteColor : null}`}>
                      {reportData?.report?.stats?.byStatus[2]?.count}
                    </div>
                    <div className={`${classes.lineDiv} ${themeColors === "2" ? classes.whiteBack : null}`}></div>
                    <div className={`${classes.bottomSubTitles} ${themeColors === "2" ? classes.whiteColor : null}`}>
                      SOLD HOMES
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Skeleton className={classes.skeleton} variant="rect" animation="wave" height={355} width={500} />
          )}
        </Grid>
      </Grid>
      <div className={classes.buttonParent}>
        <div className={classes.createReportContainer}>
          <div
            onClick={() => {
              simulateFbClick();
            }}
            className={classes.createReportButtonContainer}
          >
            <h1 className={classes.createReportButtonLabel}>Share to Facebook</h1>
            <Button className={classes.createReportButton}>
              <FacebookIcon className={classes.twitterIcon} />
            </Button>
          </div>
        </div>
        <div className={classes.createReportContainer}>
          <div
            onClick={() => {
              simulateTwClick();
            }}
            className={classes.createReportButtonContainer}
          >
            <h1 className={classes.createReportButtonLabel}>Share to Twitter</h1>
            <Button className={classes.createReportTwitterButton}>
              <TwitterIcon className={classes.twitterIcon} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ShareSocially.propTypes = {
  whichReportURL: PropTypes.string,
  reportId: PropTypes.number,
  user: PropTypes.string,
};

export default ShareSocially;
