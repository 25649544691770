import React, { useState, useEffect, useContext } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { CloseRounded } from "@material-ui/icons";
import BillingPlanList from "./BillingPlanList";
import BillingPaymentSelect from "./BillingPaymentSelect";
import { GET_BILLING_PLANS } from "../../../Services/Graph/Billing/Queries";
import { UPDATE_USER_BILLING_PLAN } from "../../../Services/Graph/Billing/Mutations";
import { CREATE_USER_BILLING_PLAN } from "../../../Services/Graph/Billing/Mutations";
import { GET_USER_BILLING_INFO } from "../../../Services/Graph/UserSettings/Queries";
import { AppStateContext, AppDispatchContext } from "../../../Context/AppReducer";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getStripePublishableKey } from "../../../Helpers/getEnvVar";
import UserSettingsBillingPaymentModal from "../../UserSettings/UserSettingsTabs/UserSettingsBillingTab/UserSettingsBillingPaymentModal";
import CicularProgressStyled from "../Loaders/CircularProgressStyled";

import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "30px",
  },
  paperLabel: {
    fontFamily: theme.typography.secondary,
    margin: 0,
    fontSize: "24px",
    fontWeight: "700",
    color: "#000",
    textTransform: "uppercase",
  },
  modalClose: {
    position: "absolute",
    right: "30px",
  },
  closeModalButton: {
    minWidth: "0px",
    padding: "8px",
    borderRadius: "10px",
    "&:hover": {},
  },
  closeModalButtonIcon: {
    color: "#282828",
    fontSize: "30px",
  },
  modalActions: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
    marginBottom: "35px",
  },
  cancelButton: {
    background: "transparent",
    minWidth: "0px",
    padding: "16px 80px",
    borderRadius: "10px",
    color: "#8D8989",
    "&:hover": {
      background: "transparent",
    },
    marginRight: "10px",
  },
  cancelButtonLabel: {
    fontFamily: theme.typography.secondary,
    color: "#8D8989",
    fontSize: "16px",
    fontWeight: "700",
    margin: 0,
  },
  saveButton: {
    background: theme.palette.areaPulseBlue,
    minWidth: "0px",
    padding: "16px 80px",
    borderRadius: "10px",
    color: "#fff",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
    },
    marginLeft: "10px",
  },
  saveButtonLabel: {
    fontFamily: theme.typography.secondary,
    color: "#fff",
    fontSize: "16px",
    fontWeight: "700",
    margin: 0,
  },
  infoContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fullCentered: {
    width: "100%",
    textAlign: "center",
  },
}));

const stripePromise = loadStripe(`${getStripePublishableKey()}`);

const BillingUpgrade = ({ isOpen, subscribedPlans, subscribedPlanToUpdate, handleClose }) => {
  const classes = useStyles();
  const { user } = useContext(AppStateContext);
  const [isPlansShowing, setIsPlansShowing] = useState(true);
  const [isNewPaymentModalOpen, setIsNewPaymentModalOpen] = useState(false);
  const [isShowSubscribing, setIsShowSubscribing] = useState(false);
  const [title, setTitle] = useState("Choose a Plan");
  const [processing, setProcessing] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [newPlanID, setNewPlanID] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [error, setError] = useState("");
  const [planList, setPlanList] = useState([]);
  const globalDispatch = useContext(AppDispatchContext);

  const [fetchPlansInfo, { loading: plansLoading, error: plansError }] = useLazyQuery(GET_BILLING_PLANS, {
    variables: {
      project: "areapulse",
    },
    onCompleted({ plans }) {
      if (plans && plans.plans && plans.plans.length > 0) {
        setShowNextButton(true);
        const tempPlans = plans.plans.filter((plan) => plan.show);
        setPlanList(tempPlans);
      }
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    fetchPlansInfo();
  }, [fetchPlansInfo]);

  useEffect(() => {
    const errorMessage = plansError?.networkError?.result?.error ?? "";
    if (plansError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [plansError, globalDispatch]);

  const [fetchUserBillingInfo, { data: userBillingInfoData, loading: userBillingInfoLoading, error: userBillingInfoError }] = useLazyQuery(GET_USER_BILLING_INFO, {
    variables: {
      userId: user?.userId,
    },
    onCompleted(data) {
      if (data?.paymentMethods?.paymentMethods && data?.paymentMethods?.paymentMethods.length > 0) {
        setSelectedPayment(data?.paymentMethods?.paymentMethods[0].id);
      }
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    fetchUserBillingInfo();
  }, [fetchUserBillingInfo]);

  useEffect(() => {
    const errorMessage = userBillingInfoError?.networkError?.result?.error ?? "";
    if (userBillingInfoError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [userBillingInfoError, globalDispatch]);

  const [updateSubscription] = useMutation(UPDATE_USER_BILLING_PLAN, {
    onCompleted() {
      setProcessing(false);
      handleLocalClose(true);
    },
    client: authApolloClient,
  });

  const [createSubscription] = useMutation(CREATE_USER_BILLING_PLAN, {
    onCompleted() {
      setProcessing(false);
      setIsShowSubscribing(false);
      handleLocalClose(true);
    },
    onError(error) {
      setProcessing(false);
      setIsShowSubscribing(false);
      setError(`Error creating subscription, ${error}`);
    },
    client: authApolloClient,
  });

  const handleNewPlanSelection = (newPlanId) => {
    setNewPlanID(parseInt(newPlanId));
  };

  const handleLocalClose = (shouldReload) => {
    handleClose(shouldReload);
    if (!isPlansShowing) {
      setIsPlansShowing(true);
    }
    setIsNewPaymentModalOpen(false);
    setError("");
  };

  const handleNext = () => {
    setTitle("Choose payment method");
    setIsPlansShowing(false);
    if (userBillingInfoData?.paymentMethods?.paymentMethods && userBillingInfoData?.paymentMethods?.paymentMethods.length === 0) {
      setIsNewPaymentModalOpen(true);
    }
  };

  const handlePaymentAdd = (paymentMethod) => {
    setProcessing(true);
    setIsShowSubscribing(true);
    setIsNewPaymentModalOpen(false);
    const variables = {
      userId: user?.userId,
      planId: newPlanID,
      paymentMethodId: paymentMethod.id,
    };

    createSubscription({
      variables: variables,
    });
  };

  const handlePlanSubscribe = () => {
    setProcessing(true);
    const variables = {
      userId: user?.userId,
      subscriptionId: subscribedPlanToUpdate,
      planId: newPlanID,
      paymentMethodId: selectedPayment,
    };

    updateSubscription({
      variables: variables,
    });
  };

  const handlePaymentChange = (selectedPaymentId) => {
    setSelectedPayment(selectedPaymentId);
  };

  if (isNewPaymentModalOpen) {
    return (
      <Elements stripe={stripePromise}>
        <UserSettingsBillingPaymentModal
          userId={user?.userId}
          open={isNewPaymentModalOpen}
          fullname={user?.fullName}
          email={user?.email}
          phone={user?.phone}
          handleClose={() => {
            setIsNewPaymentModalOpen(false);
            handleLocalClose();
          }}
          handlePaymentAdd={handlePaymentAdd}
        />
      </Elements>
    );
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: "20px",
          width: "800px",
        },
      }}
      onClose={() => {
        handleLocalClose(false);
      }}
      aria-labelledby="upgrade-plan-modal"
    >
      <div className={classes.headerContainer}>
        <h1 className={classes.paperLabel}>{title}</h1>
        <div className={classes.modalClose}>
          <Button
            className={classes.closeModalButton}
            onClick={() => {
              handleLocalClose(false);
            }}
          >
            <CloseRounded color="inherit" fontSize="inherit" className={classes.closeModalButtonIcon} />
          </Button>
        </div>
      </div>
      <DialogContent>
        <div className={classes.infoContainer}>
          {isPlansShowing ? (
            <BillingPlanList planList={planList} subscribedPlans={subscribedPlans} handleNewSelection={handleNewPlanSelection} isFetching={plansLoading} />
          ) : (
            <>
              {isShowSubscribing ? (
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item xs={12}>
                    <div className={classes.fullCentered}>
                      <CicularProgressStyled />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.fullCentered}>
                      <p>Subscribing...</p>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <>
                  {error !== "" ? (
                    <p>{error}</p>
                  ) : (
                    <BillingPaymentSelect
                      paymentMethodsList={userBillingInfoData?.paymentMethods?.paymentMethods ?? []}
                      selectedPayment={selectedPayment}
                      isPaymentListFetching={userBillingInfoLoading}
                      handlePaymentChange={handlePaymentChange}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </DialogContent>
      <div className={classes.modalActions}>
        <Button
          onClick={() => {
            handleLocalClose(false);
          }}
          className={classes.cancelButton}
        >
          <h1 className={classes.cancelButtonLabel}>Cancel</h1>
        </Button>
        {showNextButton ? (
          <Button className={classes.saveButton} disabled={processing || newPlanID === 0} onClick={isPlansShowing ? handleNext : handlePlanSubscribe}>
            <h1 className={classes.saveButtonLabel}>{isPlansShowing ? "Next" : processing ? "Processing..." : "Save"}</h1>
          </Button>
        ) : null}
      </div>
    </Dialog>
  );
};

BillingUpgrade.defaultProps = {
  isOpen: false,
  subscribedPlans: {},
  subscribedPlanToUpdate: 0,
};

BillingUpgrade.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  subscribedPlans: PropTypes.object.isRequired,
  subscribedPlanToUpdate: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default BillingUpgrade;
