import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    parentDiv: {
      width: "100%",
      height: "109px",
      fontFamily: "nunito",
      position: "absolute",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0px 40px",
    },
    image: {
      height: "35px",
      width: "150px",
    },
    link: {
      fontWeight: "500",
      fontSize: "18px",
      color: "#C4C4C4",
      marginLeft: "39px",
      textDecoration: "none",
    },
    loginLink: {
      fontWeight: "500",
      fontSize: "18px",
      color: "#C4C4C4",
      marginLeft: "39px",
      textDecoration: "none",
    },
    "@media (max-width: 900px)": {
      loginLink: {
        display: "none",
      },
      parentDiv: {
        padding: "0px 25px",
      },
      link: {
        marginLeft: "25px",
      },
    },
  })
);
const UnSubscribeHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.parentDiv}>
      <img alt="check" className={`${classes.image}`} src="/assets/ap-color.png" />
      <div>
        <a href={`/home`} className={classes.link}>
          Home
        </a>
        <a href={`/home`} className={classes.loginLink}>
          Login
        </a>
      </div>
    </div>
  );
};

UnSubscribeHeader.propTypes = {
  googleScriptLoaded: PropTypes.bool,
};

export default UnSubscribeHeader;
