import React from "react";
import { makeStyles, createStyles, Paper } from "@material-ui/core";
import UserSettingsBillingHistoryTable from "./UserSettingsBillingHistoryTable";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: "20px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    accountSettingsHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "30px",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    accountSettingsInfoText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    editUserButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    editUserButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
  })
);

const UserSettingsBillingHistory = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div className={classes.accountSettingsHeaderContainer}>
        <h1 className={classes.paperLabel}>Billing History</h1>
      </div>
      <div>
        <UserSettingsBillingHistoryTable />
      </div>
    </Paper>
  );
};

UserSettingsBillingHistory.propTypes = {};

export default UserSettingsBillingHistory;
