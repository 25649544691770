import React, { useState, useContext, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Button } from "@material-ui/core";
import { AppStateContext } from "../../../Context/AppReducer";
import ColorPicker from "./ColorPicker";
import GradientColorPicker from "./GradientColorPicker";

const useStyles = makeStyles((theme) =>
  createStyles({
    designPreferencesContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
      width: "100%",
      position: "relative",
    },
    resetButton: {
      marginTop: "10px",
      marginBottom: "20px",
      background: theme.palette.areaPulseBlue,
      color: "#fff",
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      padding: "10px 14px",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    labelText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "bold",
      fontSize: "16px",
      color: "#000",
      margin: "15px 0px 15px 0px",
    },
    colorSetContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      marginBottom: "30px",
    },
    colorTypeContainer: {
      position: "relative",
      background: "transparent",
      padding: "8px 0px",
      border: "1px solid #E9E9E9",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      maxWidth: "82px",
      width: "100%",
      marginRight: "20px",
      cursor: "pointer",
    },
    color: {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      background: "#BDBDBD",
      transition: "0.2s",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    colorLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "13px",
      color: "#000",
      margin: "6px 0px 0px 0px",
    },
    noMargin: {
      marginTop: "0px",
    },
    accentColor: {
      background: (props) => props.accentColor,
    },
    backgroundColor: {
      background: (props) => props.backgroundColor,
    },
    newColor: {
      background: (props) => props.newColor,
    },
    activeColor: {
      background: (props) => props.activeColor,
    },
    pendingColor: {
      background: (props) => props.pendingColor,
    },
    soldColor: {
      background: (props) => props.soldColor,
    },
  })
);

const DesignPreferences = ({ savingUserPrefs, designPrefs, setDesignPrefs, reportSettings }) => {
  const globalState = useContext(AppStateContext);
  const userColors = globalState?.user?.ReportSettings;
  const [accentColor, setAccentColor] = useState(userColors?.themeColorSolid);
  const [backgroundColor, setBackgroundColor] = useState(userColors?.themeColorGradient);
  const [newColor, setNewColor] = useState(userColors?.categoryColorNew);
  const [activeColor, setActiveColor] = useState(userColors?.categoryColorActive);
  const [pendingColor, setPendingColor] = useState(userColors?.categoryColorPending);
  const [soldColor, setSoldColor] = useState(userColors?.categoryColorSold);
  const [origionalGradient, setOrigionalGradient] = useState(userColors?.themeColorGradient);
  const [colorPickerState, setColorPickerState] = useState("none");
  const [closePicker, setClosePicker] = useState(false);
  const classes = useStyles({ accentColor, backgroundColor, newColor, activeColor, pendingColor, soldColor });
  const collectInformation = useCallback(() => {
    const colorsObject = {
      themeColorSolid: accentColor,
      themeColorGradient: backgroundColor,
      categoryColorNew: newColor,
      categoryColorActive: activeColor,
      categoryColorPending: pendingColor,
      categoryColorSold: soldColor,
    };
    setDesignPrefs(colorsObject);
  }, [accentColor, backgroundColor, newColor, activeColor, pendingColor, soldColor, setDesignPrefs]);

  const resetDefaults = () => {
    setAccentColor("#008DD1");
    setBackgroundColor("linear-gradient(-5deg, #000000 50%, #797A7A 90%)");
    setNewColor("#71E2A3");
    setActiveColor("#FF865C");
    setPendingColor("#66C5F3");
    setSoldColor("#FFC82C");
    setOrigionalGradient("linear-gradient(-5deg, #98D1FF 50%, #4BB3E6 90%)");
  };

  useEffect(() => {
    if (reportSettings) {
      setAccentColor(reportSettings?.themeColorSolid);
      setBackgroundColor(reportSettings?.themeColorGradient);
      setNewColor(reportSettings?.categoryColorNew);
      setActiveColor(reportSettings?.categoryColorActive);
      setPendingColor(reportSettings?.categoryColorPending);
      setSoldColor(reportSettings?.categoryColorSold);
    }
  }, [reportSettings]);

  useEffect(() => {
    if (savingUserPrefs && !designPrefs) {
      collectInformation();
    }
  }, [savingUserPrefs, designPrefs, collectInformation]);

  useEffect(() => {
    setColorPickerState("none");
  }, [accentColor, backgroundColor, newColor, activeColor, pendingColor, soldColor]);

  useEffect(() => {
    if (closePicker) {
      setColorPickerState("none");
      setClosePicker(false);
    }
  }, [closePicker]);

  return (
    <div className={classes.designPreferencesContainer}>
      <h1 className={classes.labelText}>Theme Colors</h1>
      <div className={classes.colorSetContainer}>
        <div className={classes.colorTypeContainer} onClick={() => setColorPickerState("accent")}>
          <div className={`${classes.color} ${classes.accentColor}`} />
          <h1 className={classes.colorLabel}>Accent</h1>
          {colorPickerState === "accent" && (
            <ColorPicker setColor={(color) => setAccentColor(color)} origionalColor={reportSettings?.themeColorSolid} setClosePicker={() => setClosePicker(true)} color={accentColor} />
          )}
        </div>
        <div className={classes.colorTypeContainer} onClick={() => setColorPickerState("background")}>
          <div className={`${classes.color} ${classes.backgroundColor}`} />
          <h1 className={classes.colorLabel}>Background</h1>
          <GradientColorPicker
            open={colorPickerState === "background"}
            setBackgroundColor={(color) => setBackgroundColor(color)}
            origionalGradient={origionalGradient}
            setClosePicker={() => setClosePicker(true)}
          />
        </div>
      </div>
      <h1 className={`${classes.labelText} ${classes.noMargin}`}>Category Colors</h1>
      <div className={classes.colorSetContainer}>
        <div className={classes.colorTypeContainer} onClick={() => setColorPickerState("new")}>
          <div className={`${classes.color} ${classes.newColor}`} />
          <h1 className={classes.colorLabel}>New</h1>
          {colorPickerState === "new" && (
            <ColorPicker setColor={(color) => setNewColor(color)} origionalColor={reportSettings?.categoryColorNew} setClosePicker={() => setClosePicker(true)} color={newColor} />
          )}
        </div>
        <div className={classes.colorTypeContainer} onClick={() => setColorPickerState("active")}>
          <div className={`${classes.color} ${classes.activeColor}`} />
          <h1 className={classes.colorLabel}>Active</h1>
          {colorPickerState === "active" && (
            <ColorPicker setColor={(color) => setActiveColor(color)} origionalColor={reportSettings?.categoryColorActive} setClosePicker={() => setClosePicker(true)} color={activeColor} />
          )}
        </div>
        <div className={classes.colorTypeContainer} onClick={() => setColorPickerState("pending")}>
          <div className={`${classes.color} ${classes.pendingColor}`} />
          <h1 className={classes.colorLabel}>Pending</h1>
          {colorPickerState === "pending" && (
            <ColorPicker setColor={(color) => setPendingColor(color)} origionalColor={reportSettings?.categoryColorPending} setClosePicker={() => setClosePicker(true)} color={pendingColor} />
          )}
        </div>
        <div className={classes.colorTypeContainer} onClick={() => setColorPickerState("sold")}>
          <div className={`${classes.color} ${classes.soldColor}`} />
          <h1 className={classes.colorLabel}>Sold</h1>
          {colorPickerState === "sold" && (
            <ColorPicker setColor={(color) => setSoldColor(color)} origionalColor={reportSettings?.categoryColorSold} setClosePicker={() => setClosePicker(true)} color={soldColor} />
          )}
        </div>
      </div>
      <Button className={classes.resetButton} onClick={() => resetDefaults()}>
        Reset defaults
      </Button>
    </div>
  );
};

DesignPreferences.propTypes = {
  savingUserPrefs: PropTypes.bool,
  setDesignPrefs: PropTypes.func,
  reportSettings: PropTypes.object,
};

export default DesignPreferences;
