import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Backdrop, Button, withStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    createReportBackdrop: {
      height: "100%",
      width: "100%",
      zIndex: "8",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "35px",
    },
    sentDiv: {
      marginTop: "60px",
      marginBottom: "50px",
      fontSize: "30px",
      fontFamily: "nunito",
      fontWeight: "400",
    },
    createReportContentContainer: {
      position: "relative",
      width: "600px",
      height: "250px",
      maxWidth: "1600px",
      maxHeight: "930px",
      zIndex: "10",
      background: "#fff",
      borderRadius: "20px",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  })
);
const CloseButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    background: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "15px 50px",
    borderRadius: "7px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);
const MarketReportCardSentConfirm = ({ setConfirmUp, confirmUp }) => {
  const classes = useStyles();

  const backdropAnimations = {
    appear: 500,
    enter: 500,
    exit: 500,
  };

  return (
    <Backdrop open={confirmUp} transitionDuration={backdropAnimations} className={classes.createReportBackdrop}>
      <div className={classes.createReportContentContainer}>
        <div className={classes.sentDiv}>Your Report was sent!</div>
        <CloseButton
          onClick={() => {
            setConfirmUp(false);
          }}
        >
          Okay
        </CloseButton>
      </div>
    </Backdrop>
  );
};

MarketReportCardSentConfirm.propTypes = {
  confirmUp: PropTypes.bool,
  setConfirmUp: PropTypes.func,
};

export default MarketReportCardSentConfirm;
