import React, { useState, useEffect } from "react";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { createStyles, makeStyles, withStyles, Button, MenuItem, TextField, Container } from "@material-ui/core";
import { Add, CloseRounded } from "@material-ui/icons";
import { CREATE_NOTE } from "../../../Services/Graph/Contact/Mutations";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      margin: "15px",
      padding: "30px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
    },
    contactHeaderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "30px",
    },
    closeModalButton: {
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
    },
    closeModalButtonIcon: {
      color: "#282828",
      fontSize: "30px",
    },
    saveButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "16px 80px",
      borderRadius: "10px",
      color: "#fff",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    saveButtonLabel: {
      fontFamily: theme.typography.secondary,
      color: "#fff",
      fontSize: "16px",
      fontWeight: "700",
      margin: 0,
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    contactInfoText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    modalClose: {
      position: "absolute",
      right: "30px",
    },
    modalContainer: {
      borderRadius: "10px",
    },
    modalActions: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "35px",
    },
    menuItem: {
      padding: "14px 20px",
    },
    menuItemIcon: {
      marginRight: "12px",
    },
    contactInput: {
      fontFamily: theme.typography.secondary,
      fontSize: "16px",
    },
  })
);

const Dialog = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialog);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "0 70px 45px",
  },
}))(MuiDialogContent);

const AddNoteModal = ({ contactId, contactNoteWasMade, setContactNoteWasMade, setCancelAddNote, openModal, setOpenModal }) => {
  const classes = useStyles();
  const [noteFormState, setNoteFormState] = useState({});

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      note: "",
    },
  });

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setCancelAddNote(true);
  };

  const [addContactNote] = useMutation(CREATE_NOTE, {
    variables: {
      id: contactId ?? 0,
      note: noteFormState.note,
    },
    client: authApolloClient,
  });

  const submit = (data) => {
    setNoteFormState({ ...data });
    addContactNote();
    setContactNoteWasMade(true);
    handleClose();
  };

  useEffect(() => {
    if (contactNoteWasMade) {
      setOpenModal(false);
    }
  }, [contactNoteWasMade, setOpenModal]);

  return (
    <div>
      <MenuItem onClick={handleClickOpen} className={classes.menuItem}>
        <Add className={classes.menuItemIcon} />
        Add Note
      </MenuItem>
      <Dialog
        maxWidth="md"
        disableEnforceFocus
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: "800px",
          },
        }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <form id="contact-notes-form" onSubmit={handleSubmit(submit)}>
          <div className={classes.contactHeaderContainer}>
            <h1 className={classes.paperLabel}>Create New Note</h1>
            <div className={classes.modalClose}>
              <Button className={classes.closeModalButton} onClick={handleClose}>
                <CloseRounded color="inherit" fontSize="inherit" className={classes.closeModalButtonIcon} />
              </Button>
            </div>
          </div>
          <Container>
            <DialogContent>
              <TextField
                variant="outlined"
                className={classes.contactInput}
                id="contact-note"
                multiline
                rows={10}
                fullWidth
                required
                type="text"
                error={Boolean(errors.note)}
                helperText={errors.note?.message}
                InputProps={{
                  name: "note",
                }}
                inputRef={register({
                  validate: (value) => {
                    if (value === "") {
                      return "Please enter a note";
                    } else if (value !== "" && !value.includes("<script>") && !value.includes("</script>")) {
                      return true;
                    } else if (value !== "" && (value.includes("<script>") || value.includes("</script>"))) {
                      return "You can't write custom code here you filthy hacker";
                    }
                  },
                })}
              />
            </DialogContent>
            <div className={classes.modalActions}>
              <Button className={classes.saveButton} type="submit" form="contact-notes-form">
                <h1 className={classes.saveButtonLabel}>Save</h1>
              </Button>
            </div>
          </Container>
        </form>
      </Dialog>
    </div>
  );
};

AddNoteModal.propTypes = {
  contactId: PropTypes.string,
  contactNoteWasMade: PropTypes.bool,
  setContactNoteWasMade: PropTypes.func,
  setCancelAddNote: PropTypes.func,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};

export default AddNoteModal;
