import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { AppStateContext, AppDispatchContext } from "../../Context/AppReducer";
import { makeStyles, createStyles, withStyles, Button, Grid } from "@material-ui/core";
import { useQuery, useLazyQuery } from "@apollo/client";
import { authApolloClient, openApolloClient } from "../../Services/Graph/apolloConfig";
import {
  GET_ELASTIC_SEARCH_DATA,
  GET_ELASTIC_SEARCH_DATA_OPEN,
  GET_MLS_LINKS,
  GET_MLS_LINKS_OPEN,
  GET_POLYGON,
  GET_POLYGON_OPEN,
  PRE_MARKET_REPORT,
  PRE_MARKET_REPORT_OPEN,
  GET_USER_AMOUNT,
} from "../../Services/Graph/CreateReport/Queries";
import { GET_USER_BILLING_PLANS } from "../../Services/Graph/Billing/Queries";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import CreateReportSearch from "../Common/Inputs/CreateReportSearch";
import getUsersMlsIds from "../../Helpers/getUsersMlsIds";
import getFormattedTerm from "../../Helpers/getFormattedTerm";
import AutoCompletOptions from "./AutoCompleteOptions";
import SetRadius from "./SetRadius";
import SelectPropertyType from "./SelectPropertyType";
import SelectPrice from "./SelectPrice";
import SelectBeds from "./SelectBeds";
import SelectBaths from "./SelectBaths";
import CreateReportMap from "./CreateReportMap";
import CreateReportResultsBack from "./CreateReportReportResults/CreateReportReportResultsBack";
import BillingUpgrade from "../Common/Billing/BillingUpgrade";

const useStyles = makeStyles((theme) =>
  createStyles({
    createToolsContainer: {
      height: "100%",
    },
    createReportFiltersContainer: {
      flexDirection: "column",
      width: "100%",
      height: "calc(100% - 84px)",
      padding: "30px",
      overflowY: "scroll",
      overflowX: "inherit",
    },
    locationHeader: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "16px",
      color: "#414141",
      margin: "0px 0px 10px 0px",
    },
    autoCompleteInputContainer: {
      position: "relative",
      marginBottom: "20px",
    },
    createReportButtonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "40px",
    },
    errorMessage: {
      color: "red",
      fontFamily: "nunito",
      fontWeight: "600",
      marginBottom: "20px",
    },
    "@media (max-width: 960px)": {
      createToolsContainer: {
        overflowX: "scroll",
      },
    },
  })
);

const SubmitReportButton = withStyles((theme) => ({
  root: {
    background: theme.palette.areaPulseBlue,
    borderRadius: "10px",
    padding: "10px 20px",
    color: "#fff",
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    "&:hover": {
      background: `${theme.palette.areaPulseBlue}90`,
    },
  },
}))(Button);

const CreateReportTools = ({
  user,
  collectMarketReportInfo,
  marketReportSubmiting,
  setMarketReportSubmiting,
  createReportOpen,
  editPayload,
  landingPageView,
  createContactOpen,
  setCreateContactOpen,
  setContactFrequency,
  contactFrequency,
}) => {
  const classes = useStyles();
  const globalState = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);
  const [resultsOpen, setResultsOpen] = useState(false);
  const [imageOfMap, setImageOfMap] = useState("");
  const [mlsList, setMlsList] = useState("");
  const [drawnPolygon, setDrawnPolygon] = useState(false);
  const [geoJSON, setGeoJson] = useState(false);
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [radius, setRadius] = useState(0);
  const [propertyTypes, setPropertyTypes] = useState(["Residential"]);
  const [renderedElasticData, setRenderedElasticData] = useState([]);
  const [selectedAnAddress, setSelectedAnAddress] = useState(false);
  const [selectedNonAddress, setSelectedNonAddress] = useState(false);
  const [loadMap, setLoadMap] = useState(false);
  const [gotStat, setGotStat] = useState(false);
  const [price, setPrice] = useState([0, 3000000]);
  const [pullPrice, setPullPrice] = useState(false);
  const [paidAccount, setPaidAccount] = useState(false);
  const [freeAccountNoReports, setFreeAccountNoReports] = useState(false);
  const [beds, setBeds] = useState([0, 10]);
  const [baths, setBaths] = useState([0, 10]);
  const [inputErrors, setInputErrors] = useState({
    error: false,
    message: "Please enter an Address, City or Zip",
  });
  const [preReportCollectedData, setPreReportCollectedData] = useState(false);
  const [preSubmit, setPreSubmit] = useState(false);
  const [upgradeOpen, setUpgradeOpen] = useState(false);
  const [userPlansMap, setUserPlansMap] = useState({});
  const [userPlanToUpdate, setUserPlanToUpdate] = useState(0);
  const [formattedTerm, setFormattedTerm] = useState({
    city: "",
    state: "",
    county: "",
    zip: "",
    neighborhood: "",
  });

  const [fetchStats, { data: statsData, loading: statsLoading }] = useLazyQuery(GET_USER_AMOUNT, {
    variables: {
      id: globalState?.user?.userId,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });
  const {
    ready,
    value,
    suggestions: { data: placesData },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
    },
    debounce: 300,
  });
  const [fetchUserPlans, { loading: userPlansLoading, error: userPlansError }] = useLazyQuery(GET_USER_BILLING_PLANS, {
    variables: {
      id: globalState.user.userId,
    },
    onCompleted({ user }) {
      if (user && user.subscriptions) {
        let tempMap = {};
        let firstSubscriptionAdded = false;
        user.subscriptions.forEach((subscription) => {
          if (!firstSubscriptionAdded && subscription.name.toLowerCase().indexOf("free") !== -1) {
            setUserPlanToUpdate(subscription.id);
          }
          tempMap[subscription.itemId] = subscription.id;
        });
        setUserPlansMap(tempMap);
      }
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    fetchUserPlans();
  }, [fetchUserPlans]);

  const { data: polygonData } = useQuery(landingPageView ? GET_POLYGON_OPEN : GET_POLYGON, {
    variables: formattedTerm,
    client: landingPageView ? openApolloClient : authApolloClient,
    fetchPolicy: "no-cache",
  });

  const { data: mlsLinkData, loading: mlsLinkLoading } = useQuery(
    landingPageView ? GET_MLS_LINKS_OPEN : GET_MLS_LINKS,
    {
      variables: landingPageView
        ? { username: user }
        : {
            id: globalState.user.userId,
          },
      client: landingPageView ? openApolloClient : authApolloClient,
      fetchPolicy: "no-cache",
    }
  );

  const mlsIds = getUsersMlsIds(mlsLinkData?.user);
  const [getElasticSearchData, { data: elasticData, error: elasticError }] = useLazyQuery(
    landingPageView ? GET_ELASTIC_SEARCH_DATA_OPEN : GET_ELASTIC_SEARCH_DATA,
    {
      variables: {
        term: value,
        mlsIDs: mlsIds,
      },
      client: landingPageView ? openApolloClient : authApolloClient,
    }
  );

  const [preMarketReport, { data: preReportData, loading: preReportLoading }] = useLazyQuery(
    landingPageView ? PRE_MARKET_REPORT_OPEN : PRE_MARKET_REPORT,
    {
      variables: {
        ...preReportCollectedData,
      },
      client: landingPageView ? openApolloClient : authApolloClient,
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    const states = [];
    if (mlsLinkData) {
      for (let i = 0; i < mlsLinkData.user?.mlsLinks.length; i++) {
        states.push(mlsLinkData.user?.mlsLinks[i]?.state?.abbr);
      }
    }
    const list = states.join(",");
    setMlsList(list);
  }, [mlsLinkData]);

  useEffect(() => {
    if (statsData) {
      let array = [];
      array = statsData?.user?.subscriptions;
      setGotStat(true);
      array.forEach((element) => {
        if (element.itemId !== 29) {
          setPaidAccount(true);
        }
      });
    }

    if (!statsData && !landingPageView) {
      fetchStats();
    } else if (
      statsData &&
      statsData?.user?.totalSubscriptionUsage?.reportsRemaining <= 0 &&
      !landingPageView &&
      !paidAccount &&
      gotStat
    ) {
      setFreeAccountNoReports(true);
    } else {
      setFreeAccountNoReports(false);
    }
  }, [statsData, gotStat]);

  useEffect(() => {
    if (landingPageView === false) {
      const errorMessage = elasticError?.networkError?.result?.error ?? "";
      if (
        elasticError &&
        (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")
      ) {
        globalDispatch({ type: "setExpiredSessionModal", payload: true });
      }
    }
  }, [elasticError, globalDispatch, landingPageView]);

  useEffect(() => {
    if (createReportOpen.editing && editPayload) {
      const isAddress = editPayload.parameters.address !== undefined && editPayload.parameters.address !== "";
      const isZipCode = editPayload.parameters.zipCode !== undefined && editPayload.parameters.zipCode !== "";
      const isCity = editPayload.parameters.city !== undefined && editPayload.parameters.city !== "" && !isAddress;
      const isPolygon = editPayload.parameters.polygon !== undefined && !isCity && !isAddress;
      const latAndLng = { lat: editPayload.parameters.lat, lng: editPayload.parameters.lng };
      if (isAddress) {
        const address = `${editPayload.parameters.address}, ${editPayload.parameters.city}, ${editPayload.parameters.state}`;
        setValue(address, false);
        setFormattedTerm(getFormattedTerm(address));
        setSelectedAnAddress(true);
        setSelectedNonAddress(false);
        setLatLng(latAndLng);
        setRadius(editPayload.parameters.radius);
        setPropertyTypes(editPayload.parameters.propertyType);
        setPrice([editPayload.parameters.priceMin, editPayload.parameters.priceMax]);
        setPullPrice(true);
        setBeds([editPayload.parameters.bedsMin, editPayload.parameters.bedsMax]);
        setBaths([editPayload.parameters.bathsMin, editPayload.parameters.bathsMax]);
      } else if (isCity) {
        const cityFormat = {
          cat: "City",
          value: `${editPayload.parameters.city}, ${editPayload.parameters.state}`,
        };
        const city = `${editPayload.parameters.city}, ${editPayload.parameters.state}`;
        setFormattedTerm(getFormattedTerm(cityFormat));
        setValue(city, false);
        setSelectedAnAddress(false);
        setSelectedNonAddress(true);
        setLatLng(latAndLng);
        setPropertyTypes(editPayload.parameters.propertyType);
        setPrice([editPayload.parameters.priceMin, editPayload.parameters.priceMax]);
        setPullPrice(true);
        setBeds([editPayload.parameters.bedsMin, editPayload.parameters.bedsMax]);
        setBaths([editPayload.parameters.bathsMin, editPayload.parameters.bathsMax]);
      } else if (isZipCode) {
        const zipCode = `${editPayload.parameters.zipCode}`;
        const zipFormat = {
          cat: "Zip Code",
          value: zipCode,
        };
        setFormattedTerm(getFormattedTerm(zipFormat));
        setValue(zipCode, false);
        setSelectedAnAddress(false);
        setSelectedNonAddress(true);
        setLatLng(latAndLng);
        setPropertyTypes(editPayload.parameters.propertyType);
        setPrice([editPayload.parameters.priceMin, editPayload.parameters.priceMax]);
        setPullPrice(true);
        setBeds([editPayload.parameters.bedsMin, editPayload.parameters.bedsMax]);
        setBaths([editPayload.parameters.bathsMin, editPayload.parameters.bathsMax]);
      } else if (isPolygon) {
        const poly = editPayload.parameters.polygon;
        var formatJSON = {
          coordinates: editPayload?.parameters?.geoJSON?.coordinates,
          type: editPayload?.parameters?.geoJSON?.type,
        };
        var polyLatAndLng = {
          lat: editPayload?.parameters?.polygon[0][0],
          lng: editPayload?.parameters?.polygon[0][1],
        };
        setGeoJson(formatJSON);
        setDrawnPolygon(poly);
        setSelectedAnAddress(false);
        setSelectedNonAddress(false);
        setLatLng(polyLatAndLng);
        setPropertyTypes(editPayload.parameters.propertyType);
        setPrice([editPayload.parameters.priceMin, editPayload.parameters.priceMax]);
        setPullPrice(true);
        setBeds([editPayload.parameters.bedsMin, editPayload.parameters.bedsMax]);
        setBaths([editPayload.parameters.bathsMin, editPayload.parameters.bathsMax]);
        setLoadMap(true);
      }
    }
  }, [createReportOpen, editPayload]);

  useEffect(() => {
    if (landingPageView && createReportOpen.termInfo.value && mlsList) {
      // Get latitude and longitude via utility functions
      getGeocode({
        address: createReportOpen.termInfo.value,
      })
        .then((results) => {
          return getLatLng(results[0]);
        })
        .then(({ lat, lng }) => {
          setLatLng({ lat, lng });
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
      setValue(createReportOpen.termInfo.value);
      if (createReportOpen.termInfo.cat === "Address") {
        setFormattedTerm(getFormattedTerm(createReportOpen.termInfo));
        setSelectedAnAddress(true);
      } else if (createReportOpen.termInfo.cat === "Zip Code") {
        setFormattedTerm(getFormattedTerm(createReportOpen.termInfo, mlsList));
        setSelectedNonAddress(true);
        setSelectedAnAddress(false);
      } else {
        setSelectedNonAddress(true);
        setSelectedAnAddress(false);
        setFormattedTerm(getFormattedTerm(createReportOpen.termInfo));
      }
    }
  }, [createReportOpen.termInfo, mlsList]);

  const ref = useOnclickOutside(() => {
    if (value.length !== 0 && !selectedAnAddress) {
      clearSuggestions();
      setRenderedElasticData([]);
    }
  });

  const handleSelect = (term, isAddress) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(term.value, false);
    clearSuggestions();
    setRenderedElasticData([]);
    setDrawnPolygon(false);
    if (isAddress) {
      setSelectedAnAddress(true);
      setSelectedNonAddress(false);
      setFormattedTerm(getFormattedTerm(term));
    } else if (term.cat === "Zip Code") {
      setFormattedTerm(getFormattedTerm(term, mlsList));
      setSelectedNonAddress(true);
      setSelectedAnAddress(false);
    } else {
      setSelectedNonAddress(true);
      setSelectedAnAddress(false);
      setFormattedTerm(getFormattedTerm(term));
    }

    // Get latitude and longitude via utility functions
    getGeocode({
      address: term.value,
    })
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        setLatLng({ lat, lng });
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const handleChange = (value) => {
    setValue(value);
    getElasticSearchData();
    setSelectedAnAddress(false);
    setSelectedNonAddress(false);
    setFormattedTerm({
      city: "",
      state: "",
      county: "",
      zip: "",
      neighborhood: "",
    });
    setInputErrors({ error: false, message: inputErrors.message });
  };

  const handlePolygonChange = (poly) => {
    setDrawnPolygon(poly);
    setValue("", false);
    setSelectedAnAddress(false);
    setSelectedNonAddress(false);
    setFormattedTerm({
      city: "",
      state: "",
      county: "",
      zip: "",
      neighborhood: "",
    });
  };

  const submitMarketReport = () => {
    if (value !== "" && !drawnPolygon && landingPageView) {
      const payload = {
        ...formattedTerm,
        thumbnail: imageOfMap.split(",")[1],
        lat: latLng.lat,
        lng: latLng.lng,
        propertyType: propertyTypes,
        mlsId: mlsIds,
        minPrice: price[0],
        maxPrice: price[1],
        minBeds: beds[0],
        maxBeds: beds[1],
        minBaths: baths[0],
        maxBaths: baths[1],
        radius: radius,
      };
      collectMarketReportInfo(payload);
    } else if (value !== "" && !drawnPolygon) {
      const payload = {
        ...formattedTerm,
        thumbnail: imageOfMap.split(",")[1],
        lat: latLng.lat,
        lng: latLng.lng,
        propertyType: propertyTypes,
        radius: radius,
        mlsId: mlsIds,
        minPrice: price[0],
        maxPrice: price[1],
        minBeds: beds[0],
        maxBeds: beds[1],
        minBaths: baths[0],
        maxBaths: baths[1],
        headline: "Market Report For",
      };
      collectMarketReportInfo(payload);
    } else if (value === "" && drawnPolygon) {
      const payload = {
        geoJSON: geoJSON,
        thumbnail: imageOfMap.split(",")[1],
        lat: latLng.lat,
        lng: latLng.lng,
        propertyType: propertyTypes,
        mlsId: mlsIds,
        minPrice: price[0],
        maxPrice: price[1],
        minBeds: beds[0],
        maxBeds: beds[1],
        minBaths: baths[0],
        maxBaths: baths[1],
        headline: "Market Report For",
      };
      collectMarketReportInfo(payload);
    } else {
      setInputErrors({ error: true, message: inputErrors.message });
    }
  };
  const preSubmitMarketReport = () => {
    if (value !== "" && !drawnPolygon) {
      const prePayload = {
        ...formattedTerm,
        lat: latLng.lat,
        lng: latLng.lng,
        propertyType: propertyTypes,
        radius: radius,
        mlsId: mlsIds,
        minPrice: price[0],
        maxPrice: price[1],
        minBeds: beds[0],
        maxBeds: beds[1],
        minBaths: baths[0],
        maxBaths: baths[1],
      };
      setPreReportCollectedData(prePayload);
      preMarketReport();
      setResultsOpen(true);
    } else if (value === "" && drawnPolygon) {
      const prePayload = {
        geoJSON: geoJSON,
        thumbnail: imageOfMap.split(",")[1],
        lat: latLng.lat,
        lng: latLng.lng,
        propertyType: propertyTypes,
        mlsId: mlsIds,
        minPrice: price[0],
        maxPrice: price[1],
        minBeds: beds[0],
        maxBeds: beds[1],
        minBaths: baths[0],
        maxBaths: baths[1],
      };
      setPreReportCollectedData(prePayload);
      preMarketReport();
      setResultsOpen(true);
    } else {
      setInputErrors({ error: true, message: inputErrors.message });
    }
  };

  useEffect(() => {
    setRenderedElasticData(elasticData?.suggestions ?? []);
  }, [elasticData]);

  useEffect(() => {
    if (marketReportSubmiting && imageOfMap !== "") {
      preSubmitMarketReport();
      setMarketReportSubmiting(false);
    }
    if (preSubmit && imageOfMap !== "") {
      setPreSubmit(false);
      submitMarketReport();
    }
  }, [marketReportSubmiting, imageOfMap, preSubmit]);

  return (
    <Grid container className={classes.createToolsContainer}>
      <Grid item sm={12} md={6} lg={4} className={classes.createReportFiltersContainer}>
        <Grid className={classes.autoCompleteContainer}>
          <div>
            <div className={classes.autoCompleteInputContainer} ref={ref}>
              <CreateReportSearch
                variant="filled"
                type="text"
                name="location"
                autoComplete="off"
                fullWidth
                value={value}
                disabled={!ready}
                onChange={(e) => handleChange(e.target.value)}
                label="Search an Address, City, Zip"
                error={inputErrors.error}
                helperText={inputErrors.error && inputErrors.message}
                className={classes.contactInputSpacing}
                InputProps={{
                  name: "location",
                }}
              />
              <AutoCompletOptions
                elasticData={renderedElasticData}
                placesData={placesData}
                handleSelect={(term, isAddress) => handleSelect(term, isAddress)}
                inputValue={value}
              />
            </div>
            {mlsLinkData && !mlsLinkLoading && mlsIds.length === 0 ? (
              <div className={classes.errorMessage}>
                You have no MLS set, please add an MLS to continue creating reports. You can add an MLS in the user
                settings tab.
              </div>
            ) : null}
            <SetRadius
              radius={radius}
              setRadius={(radius) => setRadius(radius)}
              selectedAnAddress={selectedAnAddress}
            />
            <SelectPropertyType
              propertyTypes={propertyTypes}
              setPropertyTypes={(type) => setPropertyTypes(type)}
              mlsIds={mlsIds}
              editing={createReportOpen.editing}
              landingPageView={landingPageView}
            />
            <SelectPrice
              setPullPrice={setPullPrice}
              pullPrice={pullPrice}
              price={price}
              setPrice={(newPrice) => setPrice(newPrice)}
            />
            <SelectBeds beds={beds} setBeds={(newBeds) => setBeds(newBeds)} />
            <SelectBaths baths={baths} setBaths={(newBaths) => setBaths(newBaths)} />
            <div className={classes.createReportButtonContainer}>
              <SubmitReportButton
                disabled={statsLoading}
                type="submit"
                form="create-report-form"
                onClick={() => {
                  if (freeAccountNoReports) {
                    setResultsOpen(true);
                  } else {
                    setMarketReportSubmiting(true);
                  }
                }}
              >
                {createReportOpen.editing ? "Edit Report" : "Create Report"}
              </SubmitReportButton>
            </div>
          </div>
        </Grid>
      </Grid>
      <CreateReportResultsBack
        user={user}
        freeAccountNoReports={freeAccountNoReports}
        preSubmit={preSubmit}
        setPreSubmit={setPreSubmit}
        preReportData={preReportData}
        preReportLoading={preReportLoading}
        resultsOpen={resultsOpen}
        setResultsOpen={setResultsOpen}
        setMarketReportSubmiting={setMarketReportSubmiting}
        submitMarketReport={submitMarketReport}
        landingPageView={landingPageView}
        createContactOpen={createContactOpen}
        setCreateContactOpen={setCreateContactOpen}
        setUpgradeOpen={setUpgradeOpen}
        setContactFrequency={setContactFrequency}
        contactFrequency={contactFrequency}
      />
      {!landingPageView ? (
        <BillingUpgrade
          isOpen={upgradeOpen}
          subscribedPlans={userPlansMap ?? {}}
          subscribedPlanToUpdate={userPlanToUpdate}
          handleClose={(reloadStats) => {
            setUpgradeOpen(false);
            if (reloadStats) {
              fetchStats();
            }
          }}
        />
      ) : null}

      <CreateReportMap
        setValue={setValue}
        setSelectedAnAddress={setSelectedAnAddress}
        setSelectedNonAddress={setSelectedNonAddress}
        setFormattedTerm={setFormattedTerm}
        setGeoJson={setGeoJson}
        latLng={latLng}
        radius={radius}
        editing={createReportOpen.editing}
        selectedAnAddress={selectedAnAddress}
        selectedNonAddress={selectedNonAddress}
        drawnPolygon={drawnPolygon}
        polygonData={polygonData}
        editPayload={editPayload}
        marketReportSubmiting={marketReportSubmiting}
        setRadius={(newRadius) => setRadius(newRadius)}
        handlePolygonChange={(poly) => handlePolygonChange(poly)}
        setImageOfMap={(img) => setImageOfMap(img)}
        setLatLng={(newLngLat) => setLatLng(newLngLat)}
        setLoadMap={setLoadMap}
        loadMap={loadMap}
        landingPageView={landingPageView}
      />
    </Grid>
  );
};

CreateReportTools.propTypes = {
  collectMarketReportInfo: PropTypes.func,
  marketReportSubmiting: PropTypes.bool,
  setMarketReportSubmiting: PropTypes.func,
  createReportOpen: PropTypes.object,
  editPayload: PropTypes.any,
  landingPageView: PropTypes.bool,
  createContactOpen: PropTypes.object,
  setCreateContactOpen: PropTypes.func,
  setContactFrequency: PropTypes.func,
  contactFrequency: PropTypes.string,
};

export default CreateReportTools;
