import React, { useEffect, useState, useContext } from "react";
import { makeStyles, createStyles, Grid, Button, TextField, withStyles, Select, MenuItem, FormControl, InputLabel, Input, CircularProgress } from "@material-ui/core";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import { SEND_REPORT } from "../../Services/Graph/CreateReport/Mutations";
import { useMutation, useLazyQuery } from "@apollo/client";
import { authApolloClient } from "../../Services/Graph/apolloConfig";
import { GET_CONTACTS } from "../../Services/Graph/MarketReportPage/MarketReportPageQueries";
import { AppStateContext } from "../../Context/AppReducer";
import { CREATE_NOTE } from "../../Services/Graph/Contact/Mutations";

const useStyles = makeStyles((theme) =>
  createStyles({
    TextFields: {
      borderRadius: "20px",
    },
    selectRound: {
      borderRadius: "10px",
      width: "258px",
      height: "48px",
    },
    circle: {
      marginLeft: "10px",
      height: "25px !important",
      width: "25px !important",
      color: "#FFFFFF",
    },
    select: {
      "&:before": {
        borderColor: "transparent",
      },
      "&:after": {
        borderColor: "transparent",
      },
      "& .MuiSelect-icon": {
        height: "30px",
        width: "30px",
        marginRight: "20px",
      },
    },
    inputProps: {
      backgroundColor: "red",
      border: "0px",
      borderColor: "transparent",
    },
    formControl: {
      width: "258px",
      marginRight: "10px",
      height: "48px",
      boxShadow: "inset 0px 0px 7px rgba(0, 0, 0, 0.08)",
      background: "#FAFAFA",
      borderRadius: "10px",
      fontSize: "15px",
      display: "flex",
    },
    selectLabel: {
      color: "#BDBDBD",
      fontWeight: "700",
      fontFamily: "nunito",
      fontSize: "15px",
      paddingLeft: "16px",
      paddingBottom: "20px",
    },
    titleDiv: {
      color: "#000000",
      fontWeight: "bold",
      fontSize: "24px",
      fontFamily: "nunito",
      marginTop: "13px",
      marginBottom: "52px",
    },
    parentDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    leftGrid: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "column",
    },
    RightGrid: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    rightText: {
      marginLeft: "10px",
    },
    leftText: {
      marginRight: "10px",
    },
    topGrid: {
      marginBottom: "20px",
    },
    sendButton: {
      backgroundColor: "#0079B4",
      width: "190px",
      height: "50px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      borderRadius: "10px",
      fontFamily: "nunito",
      fontWeight: "700",
      color: "#FFFFFF",
      fontSize: "16px",
      marginTop: "50px",
    },
    errorDivs: {
      width: "258px",
      color: "red",
      fontSize: "14px",
      marginTop: "10px",
      marginBottom: "10px",
      fontFamily: "nunito",
      fontWeight: "700",
    },
    errorDivsLeft: {
      textAlign: "left",
      marginLeft: "15px",
    },
    errorDivsCenter: {
      textAlign: "center",
    },
    errorDivsRight: {
      textAlign: "left",
      marginLeft: "15px",
    },
    root: {
      background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      borderRadius: 3,
      border: 0,
      color: "white",
      height: 48,
      padding: "0 30px",
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    },
    topMargin: {
      marginBottom: "40px",
    },
    sendDiv: {
      marginLeft: "35px",
    },
    textMaskForm: {
      marginRight: "10px",
      color: "#000",
      background: "#FAFAFA",
      borderRadius: "10px",
      fontSize: "15px",
      maxWidth: "430px",
      width: "258px",
      height: "48px",
      boxShadow: "inset 0px 0px 7px rgba(0, 0, 0, 0.08)",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
    },
    sentReport: {
      borderRadius: "10px",
      justifyContent: "center",
      display: "flex",
      color: "#FFFFFF",
      alignItems: "center",
      fontSize: "14px",
      marginTop: "10px",
      height: "40px",
      width: "200px",
      fontFamily: "nunito",
      fontWeight: "700",
      backgroundColor: "black",
    },
    textMaskLabel: {
      marginLeft: "16px",
    },
    bottomGrid: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      flexDirection: "column",
    },
    textMaskInput: {
      marginLeft: "16px",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
    },
    autoCompleteContainer: {
      background: "#FAFAFA",
      borderRadius: "10px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
      padding: "8px 15px",
      position: "absolute",
      zIndex: "3",
      top: "85px",
      maxWidth: "500px",
      width: "258px",
      marginRight: "11px",
      marginTop: "93px",
      maxHeight: "136px",
      overflow: "scroll",
    },
    autoCompleteOption: {
      fontFamily: theme.typography.secondary,
      fontSize: "18px",
      color: "#333",
      fontWeight: "500",
      margin: "0px",
      transition: "0.2s",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.areaPulseBlue,
        transition: "0.2s",
      },
    },
    "@media (max-width: 960px)": {
      sendButton: {
        marginTop: "20px",
      },
      errorDivs: {
        marginBottom: "0px",
      },
      textMaskForm: {
        marginLeft: "0px",
      },
      titleDiv: {
        marginTop: "25px",
        marginBottom: "25px",
      },
      leftGrid: {
        alignItems: "center",
        marginBottom: "20px",
      },
      RightGrid: {
        alignItems: "center",
        marginBottom: "20px",
      },
      leftText: {
        marginRight: "0px",
      },
      rightText: {
        marginLeft: "0px",
      },
      topGrid: {
        marginBottom: "0px",
      },
      formControl: {
        marginLeft: "0px",
        marginRight: "0px",
      },
      bottomGrid: {
        justifyContent: "center",
        alignItems: "center",
      },
    },
  })
);

const RoundTextField = withStyles((theme) => ({
  root: {
    "& label": {
      color: "#BDBDBD",
      fontWeight: "700",
      fontFamily: "nunito",
    },
    "& label.Mui-focused": {
      color: theme.palette.areaPulseBlue,
      fontWeight: "700",
      fontFamily: "nunito",
    },
    "& .MuiFilledInput-root": {
      color: "#000",
      background: "#FAFAFA",
      borderRadius: "10px",
      fontSize: "15px",
      maxWidth: "430px",
      width: "258px",
      height: "48px",
      boxShadow: "inset 0px 0px 7px rgba(0, 0, 0, 0.08)",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
      "&.MuiFilledInput-underline:after": {
        display: "none",
      },
    },
  },
}))(TextField);

const RoundSelect = withStyles((theme) => ({
  root: {
    paddingLeft: "12px",
    backgroundColor: "transparent !important",
    "&.MuiFilledInput-underline:before": {
      display: "none",
    },
  },
  icon: { marginTop: "-10px" },
}))(Select);

const RoundButton = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#0079B4",
    },
  },
}))(Button);

const RoundInputLabel = withStyles((theme) => ({
  shrink: {
    paddingTop: "10px",
    paddingBottom: "0px",
    transform: "translate(0, 1.5px) scale(0.75) !important",
  },
  focused: {
    color: `${theme.palette.areaPulseBlue} !important`,
  },
  formControl: {
    transform: "translate(0, 22px) scale(1)",
  },
}))(InputLabel);

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}
TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const ShareWithContact = ({ updateStats, setUpdateStats, setShareOpen, reportId, setSendReportOpen, setConfirmUp }) => {
  const [selectValue, setSelectValue] = useState("once");
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState({
    textmask: "(   )    -    ",
  });
  const [sendError, setSendError] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [lastNameValid, setLastNameValid] = useState(false);
  const [sentReport, setSentReport] = useState(false);
  const [autocomplete, setAutocomplete] = useState(false);
  const { user } = useContext(AppStateContext);
  const [sendReport, { data: sendData, loading: sendLoading }] = useMutation(SEND_REPORT, {
    variables: {
      contactEmail: emailValue,
      reportID: reportId,
      contactFirstName: firstNameValue,
      contactLastName: lastNameValue,
      contactPhone: phoneValue,
      frequency: selectValue,
    },
    client: authApolloClient,
    onCompleted() {
      if (sendData?.emailContactReport?.newContact?.id) {
        addNote();
      }
    },
  });

  const [fetchContacts, { data: searchData }] = useLazyQuery(GET_CONTACTS, {
    variables: {
      parentPersonId: user?.personId,
      searchTerm: firstNameValue?.toLowerCase(),
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  const [addNote, { data: noteData, loading: noteLoading, error: noteError }] = useMutation(CREATE_NOTE, {
    variables: {
      id: sendData?.emailContactReport?.newContact?.id,
      note: `Sent Report on ${sendData?.emailContactReport?.newContact?.reportEmailStats?.lastReportSent}`,
    },
    client: authApolloClient,
  });
  useEffect(() => {
    if (sendData && !sentReport) {
      setEmailValue("");
      setFirstNameValue("");
      setLastNameValue("");
      setPhoneValue({
        ...phoneValue,
        textmask: "(   )    -    ",
      });
      if (setSendReportOpen) {
        setSendReportOpen(false);
      }
      if (setConfirmUp) {
        if (setUpdateStats && !updateStats) {
          setUpdateStats(true);
        }
        setConfirmUp(true);
      }
    }
  }, [sendData]);

  useEffect(() => {
    if (firstNameValue !== "") {
      setAutocomplete(true);
      fetchContacts();
    } else if (firstNameValue === "") {
      fetchContacts(false);
    }
  }, [firstNameValue]);

  useEffect(() => {
    if (sentReport) {
      setShareOpen(false);
    }
  }, [sentReport]);

  useEffect(() => {
    if (!sentReport && sendData && firstNameValue === "" && !setSendReportOpen) {
      setSentReport(true);
    }

    if (sentReport) {
      setSentReport(false);
    }

    if (sendError) {
      if (firstNameValue !== "") {
        setNameInvalid(false);
      } else {
        setNameInvalid(true);
      }
      if (lastNameValue !== "") {
        setLastNameValid(false);
      } else {
        setLastNameValid(true);
      }
      if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emailValue)) {
        setEmailInvalid(false);
      } else {
        setEmailInvalid(true);
      }
    }
  }, [phoneValue, firstNameValue, emailValue, lastNameValue]);

  const handleChange = (event) => {
    setPhoneValue({
      ...phoneValue,
      [event.target.name]: event.target.value,
    });
  };

  const classes = useStyles();

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastNameValue(event.target.value);
  };
  const handleFirstNameChange = (event) => {
    setFirstNameValue(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
  };
  const sendToContact = () => {
    if (
      firstNameValue !== "" &&
      lastNameValue !== "" &&
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emailValue) &&
      selectValue !== ""
    ) {
      sendReport();
      setSendError(false);
    } else {
      setSendError(true);
    }

    if (firstNameValue !== "") {
      setNameInvalid(false);
    } else {
      setNameInvalid(true);
    }

    if (lastNameValue !== "") {
      setLastNameValid(false);
    } else {
      setLastNameValid(true);
    }

    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emailValue)) {
      setEmailInvalid(false);
    } else {
      setEmailInvalid(true);
    }
  };

  return (
    <div className={classes.parentDiv}>
      <div className={classes.titleDiv}>Send to Contact</div>
      <Grid container>
        <Grid className={`${classes.leftGrid} `} item md={6} xs={12}>
          <RoundTextField
            onBlur={() => {
              setTimeout(() => {
                setAutocomplete(false);
              }, 250);
            }}
            autoComplete="nope"
            value={firstNameValue}
            onChange={(e) => {
              handleFirstNameChange(e);
            }}
            className={`${classes.leftText} ${nameInvalid ? null : classes.topGrid} ${!nameInvalid && lastNameValid ? classes.topMargin : null}`}
            variant="filled"
            type="text"
            name="name"
            label="First Name"
          />
          {autocomplete && searchData?.contacts?.contacts.length > 0 && (
            <div className={classes.autoCompleteContainer}>
              {searchData?.contacts?.contacts.map((term, i) => {
                return (
                  <h1
                    onClick={() => {
                      setAutocomplete(false);
                      setFirstNameValue(term?.firstName);
                      setLastNameValue(term?.lastName);
                      setEmailValue(term?.email?.email);
                      setPhoneValue({
                        textmask: term?.phone?.number,
                      });
                    }}
                    key={i}
                    className={classes.autoCompleteOption}
                  >
                    {term?.firstName} {term?.lastName}
                  </h1>
                );
              })}
            </div>
          )}
          {sendError && nameInvalid ? <div className={`${classes.errorDivs} ${classes.errorDivsLeft}`}>Enter a First Name</div> : null}
        </Grid>
        <Grid className={`${classes.RightGrid} `} item md={6} xs={12}>
          <RoundTextField
            autoComplete="off"
            value={lastNameValue}
            onChange={(e) => {
              handleLastNameChange(e);
            }}
            className={`${lastNameValid ? null : classes.topGrid} ${nameInvalid && !lastNameValid ? classes.topMargin : null}`}
            variant="filled"
            type="text"
            name="location"
            label="Last Name"
          />
          {sendError && lastNameValid ? <div className={`${classes.errorDivs} ${classes.errorDivsLeft}`}>Enter a Last Name</div> : null}
        </Grid>
        <Grid className={` ${classes.leftGrid}`} item md={6} xs={12}>
          <FormControl className={`${classes.topGrid} ${emailInvalid ? classes.topMargin : null} ${classes.textMaskForm}`}>
            <RoundInputLabel className={classes.textMaskLabel} htmlFor="formatted-text-mask-input">
              Phone Number
            </RoundInputLabel>
            <Input
              autoComplete="nope"
              disableUnderline
              className={classes.textMaskInput}
              value={phoneValue.textmask}
              onChange={handleChange}
              name="textmask"
              id="formatted-text-mask-input"
              inputComponent={TextMaskCustom}
            />
          </FormControl>
        </Grid>
        <Grid className={classes.RightGrid} item md={6} xs={12}>
          <RoundTextField
            autoComplete="off"
            value={emailValue}
            onChange={(e) => {
              handleEmailChange(e);
            }}
            variant="filled"
            type="text"
            name="location"
            label="Email Address"
          />
          {sendError && emailInvalid ? <div className={`${classes.errorDivs} ${classes.errorDivsLeft}`}>Enter Enter a Email</div> : null}
        </Grid>
        <Grid className={classes.bottomGrid} item md={6} xs={12}>
          <FormControl className={classes.formControl}>
            <RoundInputLabel className={classes.selectLabel}>Frequency</RoundInputLabel>
            <RoundSelect
              onChange={(e) => {
                handleSelectChange(e);
              }}
              value={selectValue}
              disableUnderline
              className={classes.select}
              labelId="demo-simple-select-label"
            >
              <MenuItem value={"once"}>Once</MenuItem>
              <MenuItem value={"weekly"}>Weekly</MenuItem>
              <MenuItem value={"biweekly"}>Bi-Weekly</MenuItem>
              <MenuItem value={"monthly"}>Monthly</MenuItem>
            </RoundSelect>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}></Grid>
      </Grid>
      <RoundButton disabled={sendLoading} onClick={sendToContact} className={classes.sendButton}>
        <div className={sendLoading ? classes.sendDiv : null}>SEND</div>
        {sendLoading ? <CircularProgress className={classes.circle} /> : null}
      </RoundButton>
    </div>
  );
};

ShareWithContact.propTypes = {
  setConfirmUp: PropTypes.func,
  setShareOpen: PropTypes.func,
  setSendReportOpen: PropTypes.func,
  shareType: PropTypes.string,
  reportLink: PropTypes.string,
  reportId: PropTypes.number,
  setUpdateStats: PropTypes.func,
  updateStats: PropTypes.bool,
};

export default ShareWithContact;
