import { withStyles, Avatar } from "@material-ui/core";

const ImageUploadPreview = withStyles((theme) => ({
  root: {
    "& .MuiAvatar-img": {
      objectFit: "contain",
      minHeight: "200px",
      maxHeight: "200px",
    },
  },
}))(Avatar);

export default ImageUploadPreview;
