import React, { useContext, useEffect, useState, useRef } from "react";
import { AppStateContext, AppDispatchContext } from "../../../Context/AppReducer";
import PropTypes from "prop-types";
import {
  createStyles,
  makeStyles,
  withStyles,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Collapse,
  Fade,
} from "@material-ui/core";
import { ErrorOutlineOutlined, SearchOutlined } from "@material-ui/icons";
import { GET_CONTACTS, SEARCH_CONTACTS } from "../../../Services/Graph/Contacts/Queries";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { useLazyQuery } from "@apollo/client";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import ContactsTableHeaders from "./ContactsTableHeaders";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) =>
  createStyles({
    addContactSuccess: {
      position: "absolute",
      top: "40px",
      width: "50%",
    },
    searchInput: {},
    inputText: {
      color: "#676767",
    },
    "@media (max-width: 960px)": {
      searchInput: {
        width: "100%",
      },
    },
    contactsTableContainer: {
      position: "relative",
    },
    paper: {
      backgroundColor: "#F5F5F5",
      padding: "35px 53px",
      borderRadius: "20px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    },
    tableContainer: {
      backgroundColor: "#F5F5F5",
      maxHeight: "50vh",
      overflow: "scroll",
    },
    tableHeader: {
      margin: "0px",
      fontFamily: theme.typography.secondary,
      fontSize: "25px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      marginLeft: "10px",
    },
    error: {
      fontSize: "24px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    labelCenter: {
      textAlign: "center",
    },
    nameCell: {
      padding: "16px 16px 16px 0",
    },
    columnData: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "400",
      color: "#000",
      padding: "16px 10px",
    },
    columnDataCenter: {
      textAlign: "center",
    },
    hoverRow: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    headerContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: "40px",
    },
    autoCompleteContainer: {
      background: "#FAFAFA",
      borderRadius: "10px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
      padding: "8px 15px",
      position: "absolute",
      zIndex: "3",
      top: "85px",
      maxWidth: "500px",
      width: "100%",
    },
    autoCompleteOption: {
      fontFamily: theme.typography.secondary,
      fontSize: "18px",
      color: "#333",
      fontWeight: "500",
      margin: "0px",
      transition: "0.2s",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.areaPulseBlue,
        transition: "0.2s",
      },
    },
  })
);

const ContactsSearchInput = withStyles((theme) => ({
  root: {
    background: "#FAFAFA",
    borderRadius: "10px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
    border: "none",
    outline: "none",
    marginTop: "20px",
    marginBottom: "20px",
    width: "100%",
    maxWidth: "500px",
    "& label.Mui-focused": {
      color: theme.palette.areaPulseBlue,
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: theme.typography.secondary,
      color: "#676767",
      fontSize: "18px",
      fontWeight: "600",
      "& fieldset": {
        display: "none",
        borderColor: "transparent",
      },
    },
  },
}))(TextField);

const ContactsTable = ({ history }) => {
  const classes = useStyles();
  const { user } = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);
  const globalState = useContext(AppStateContext);
  const tableRef = useRef(null);
  const tableBodyRef = useRef(null);
  const [order, setOrder] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [openDeleted, setOpenDeleted] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [contacts, setContacts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [autocomplete, setAutocomplete] = useState(false);
  const first = 20;

  const contactWasDeleted = globalState?.contactWasDeleted;
  const contactWasMade = globalState?.contactWasMade;

  const [submitSearchValue, setSubmitSearchValue] = useState("");

  const [fetchContacts, { data: contactsData, loading: contactsLoading, error: contactsError }] = useLazyQuery(GET_CONTACTS, {
    variables: {
      parentPersonId: user?.personId,
      searchTerm: submitSearchValue?.toLowerCase(),
      first: first,
      offset: offset,
      sortField: orderBy,
      sortDirection: order,
    },
    onCompleted(data) {
      setSubmitted(false);
      setContacts([...contacts, ...(data?.contacts?.contacts ?? [])]);
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });
  const [searchContacts, { data: searchData, loading: searchLoading, error: searchError }] = useLazyQuery(SEARCH_CONTACTS, {
    variables: {
      parentPersonId: user?.personId,
      term: searchValue,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitSearchValue(searchValue);
    setOrder(undefined);
    setOrderBy(undefined);
    setOffset(0);
    setContacts([]);
    setSubmitted(true);
    setAutocomplete(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value === "") {
      setSubmitSearchValue("");
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setOffset(0);
    setContacts([]);
  };

  const handleScroll = (e) => {
    const tableScrollHeight = tableRef.current.scrollHeight;
    const tableScrollTop = tableRef.current.scrollTop;
    const tableClientHeight = tableRef.current.clientHeight;
    if (tableScrollHeight === tableScrollTop + tableClientHeight && contacts.length >= (offset === 0 ? first : offset)) {
      setOffset(offset + 20);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [order, orderBy, offset, fetchContacts, searchContacts]);

  useEffect(() => {
    if (searchValue !== "") {
      setAutocomplete(true);
      searchContacts();
    } else if (searchValue === "") {
      setAutocomplete(false);
    }
  }, [searchValue, searchContacts]);

  useEffect(() => {
    if (submitSearchValue !== "" && submitted) {
      fetchContacts();
    }
  }, [submitSearchValue, fetchContacts, submitted]);

  useEffect(() => {
    const errorMessage = contactsError?.networkError?.result?.error ?? "";
    if (contactsError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [contactsError, globalDispatch]);

  useEffect(() => {
    let mounted = true;

    if (contactWasMade && Object.keys(contactsData ?? {}).length !== 0) {
      fetchContacts();
      setContacts([]);
      setOffset(0);
      globalDispatch({ type: "setContactWasMade", payload: false });
      setOpen(true);
    }
    const closeAddContactAlert = setTimeout(() => {
      if (mounted) setOpen(false);
    }, 3000);

    return () => {
      clearTimeout(closeAddContactAlert);
      mounted = false;
    };
  }, [contactWasMade, contactsData, fetchContacts]);

  useEffect(() => {
    let mounted = true;

    if (contactWasDeleted && Object.keys(contactsData ?? {}).length !== 0 && contactsLoading === false) {
      fetchContacts();
      setContacts([]);
      setOffset(0);
      globalDispatch({ type: "setContactWasDeleted", payload: false });
      setOpenDeleted(true);
    }
    const closeDeleteContactAlert = setTimeout(() => {
      if (mounted) setOpenDeleted(false);
    }, 3000);

    return () => {
      clearTimeout(closeDeleteContactAlert);
      mounted = false;
    };
  }, [contactWasDeleted, contactsData, fetchContacts, globalDispatch]);

  return (
    <div className={classes.contactsTableContainer}>
      <form onSubmit={handleSubmit}>
        <ContactsSearchInput
          className={classes.searchInput}
          variant="outlined"
          type="text"
          name="search"
          autoComplete="off"
          value={searchValue}
          onChange={handleChange}
          placeholder="Search Contact By Name or Email"
          InputProps={{
            startAdornment: (
              <InputAdornment className={classes.inputText} position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      </form>
      {autocomplete && searchData?.searchContacts?.rows.length > 0 && (
        <div className={classes.autoCompleteContainer}>
          {searchData?.searchContacts?.rows.map((term, i) => {
            return (
              <h1 key={i} className={classes.autoCompleteOption} onClick={() => history.push(`/${user?.username}/contacts/${term.id}`)}>
                {term.label}
              </h1>
            );
          })}
        </div>
      )}
      <Paper className={classes.paper}>
        <div className={classes.headerContainer}>
          <h1 className={classes.tableHeader}>All Contacts</h1>
          {(contactsLoading || searchLoading) && (
            <CircularProgress
              size={25}
              thickness={4}
              color="inherit"
              className={classes.progress}
              classes={{
                circle: classes.circle,
              }}
            />
          )}
          {((contactsError && !contactsLoading) || (searchError && !searchLoading)) && <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.error} />}
        </div>
        <TableContainer className={classes.tableContainer} ref={tableRef} onScroll={handleScroll}>
          <Table stickyHeader className={classes.table} ref={tableBodyRef}>
            <ContactsTableHeaders order={order} orderBy={orderBy} handleRequestSort={(event, property) => handleRequestSort(event, property)} />
            <TableBody>
              {contacts.map((row, i) => {
                const neverSentReport = row.reportEmailStats === null;
                return (
                  <Fade key={row.id} in={true} timeout={500}>
                    <TableRow key={i} className={classes.hoverRow} hover onClick={() => history.push(`/${user?.username}/contacts/${row.id}`)}>
                      <TableCell className={`${classes.nameCell} ${classes.columnData}`}>
                        <div>
                          {row.firstName} {row.lastName}
                        </div>
                      </TableCell>
                      <TableCell className={classes.columnData}>{row.email.email}</TableCell>
                      <TableCell className={classes.columnData}>{neverSentReport ? "never" : <Moment format="ddd, MMM DD, YYYY">{row.reportEmailStats.lastReportSent}</Moment>}</TableCell>
                      <TableCell className={`${classes.columnData} ${classes.columnDataCenter}`}>{row.reportEmailStats === null ? "0" : row.reportEmailStats.sent}</TableCell>
                      <TableCell className={`${classes.columnData} ${classes.columnDataCenter}`}>{row.reportEmailStats === null ? "0" : row.reportEmailStats.opened}</TableCell>
                    </TableRow>
                  </Fade>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div className={classes.addContactSuccess}>
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Contact Was Added!
          </Alert>
        </Collapse>
        <Collapse in={openDeleted}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenDeleted(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Contact Was Deleted!
          </Alert>
        </Collapse>
      </div>
    </div>
  );
};

ContactsTable.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ContactsTable);
