import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import AdminComponent from "../Components/AdminPage/AdminComponent";
import CreateReport from "../Components/CreateReport/CreateReport";

const useStyles = makeStyles((theme) =>
  createStyles({
    dashboardViewContainer: {
      position: "absolute",
      left: "0",
      right: "0",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  })
);

const AdminView = ({ createReportOpen, setCreateReportOpen, savedReport, setSavedReport, match, googleScriptLoaded }) => {
  const classes = useStyles();
  const user = match?.params?.user;
  return (
    <div className={classes.dashboardViewContainer}>
      {createReportOpen.open && (
        <CreateReport
          user={user}
          googleScriptLoaded={googleScriptLoaded}
          createReportOpen={createReportOpen}
          setCreateReportOpen={(status) => setCreateReportOpen(status)}
          setSavedReport={(status) => setSavedReport(status)}
        />
      )}
      <AdminComponent user={user} setCreateReportOpen={(status) => setCreateReportOpen(status)} setSavedReport={(status) => setSavedReport(status)} savedReport={savedReport} />
    </div>
  );
};

AdminView.propTypes = {
  savedReport: PropTypes.bool,
  setSavedReport: PropTypes.func,
  createReportOpen: PropTypes.object,
  setCreateReportOpen: PropTypes.func,
  match: PropTypes.object,
  googleScriptLoaded: PropTypes.bool,
};

export default AdminView;
