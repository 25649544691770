import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, withStyles, Backdrop, Button, CircularProgress } from "@material-ui/core";
import { Close, Publish } from "@material-ui/icons";
import getBase64 from "../../../Helpers/getBase64";
import useUploadProfileImage from "../../../Services/Rest/uploadProfileImageHook";

const useStyles = makeStyles((theme) =>
  createStyles({
    imageUploadBackdrop: {
      zIndex: "10",
    },
    imageUploadContainer: {
      maxWidth: "767px",
      width: "100%",
      background: "#fff",
      borderRadius: "20px",
      padding: "30px",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    exitButton: {
      position: "absolute",
      right: "30px",
      top: "30px",
      fontSize: "30px",
      color: "#333",
      transition: "0.2s",
      cursor: "pointer",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    saveLoading: {
      marginRight: "10px",
      marginLeft: "38px",
    },
    imageUploadHeader: {
      fontFamily: theme.typography.secondary,
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000",
      margin: "0px 0px 45px 0px",
    },

    imagesContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    imagesLabelContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: "65px",
    },
    imageContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "195px",
      width: "195px",
      borderRadius: "50%",
      overflow: "hidden",
      cursor: "pointer",
      margin: "0px 40px 0px 40px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0008), 0px 4px 8px rgba(0, 0, 0, 0.12)",
      border: "1px solid #CCCCCC",
    },
    img: {
      width: "100%",
    },
    fallbackContainer: {
      height: "100%",
      width: "100%",
      background: "#BDBDBD",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    fallbackText: {
      fontFamily: theme.typography.default,
      margin: "0px",
      fontSize: "40px",
      color: "#fff",
      fontWeight: "600",
    },
    hoverIconContainer: {
      height: "0%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#00000050",
      opacity: "0",
      transition: "opacity 0.2s",
      position: "absolute",
    },
    active: {
      height: "100%",
      opacity: "1",
      transition: "opacity 0.2s",
    },
    hoverIcon: {
      fontSize: "75px",
      color: "#fff",
    },
    errorDiv: {
      marginTop: "10px",
      color: "red",
    },
  })
);

const SaveButton = withStyles((theme) => ({
  root: {
    height: "58px",
    width: "193px",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: theme.typography.secondary,
    fontWeight: "400",
    fontSize: "16px",
    background: theme.palette.areaPulseBlue,
    borderRadius: "10px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    transition: "0.2s",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      opacity: "0.8",
      transition: "0.2s",
    },
  },
}))(Button);

const ImageUpload = ({ setUseNewImage, userInfo, firstInitial, imageUpload, setImageUpload, profileImage, setProfileImage }) => {
  const classes = useStyles();
  const profileImageInput = useRef(null);
  const [profImgHov, onProfImgHov] = useState(false);
  const [files, setFiles] = useState(undefined);
  const [{ isFetching: profileImageIsFetching, isSuccessful: profileImageIsSuccessful, errorMessage: profileError }, uploadProfileImage] = useUploadProfileImage();
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [newUploadImage, setNewUploadImage] = useState("");
  const handleProfileUpload = () => {
    profileImageInput.current.click();
  };
  const handleInputSave = () => {
    if (files) {
      setUseNewImage(URL.createObjectURL(files));
      uploadProfileImage({
        userId: userInfo?.id,
        image: files,
      });
      setSaveButtonClicked(true);
    }
  };
  const handleProfileSubmit = (e) => {
    setFiles(e.target.files[0]);
    let file = e.target.files[0];
    if (file !== undefined) {
      getBase64(file).then((data) => {
        setNewUploadImage(URL.createObjectURL(file));
        setProfileImage({
          preview: URL.createObjectURL(file),
          base64: data,
        });
      });
    }
  };

  const handleUploadClose = () => {
    setImageUpload(false);
  };

  useEffect(() => {
    if (!profileImageIsFetching && saveButtonClicked && profileImageIsSuccessful) {
      setSaveButtonClicked(false);
    }
  }, [files, profileImageIsFetching, profileImageIsSuccessful]);
  return (
    <Backdrop open={imageUpload} className={classes.imageUploadBackdrop} onClick={profileImageIsFetching ? null : () => handleUploadClose()}>
      <div className={classes.imageUploadContainer} onClick={(e) => e.stopPropagation()}>
        <Close disabled={profileImageIsFetching} fontSize="inherit" color="inherit" className={classes.exitButton} onClick={profileImageIsFetching ? null : () => handleUploadClose()} />
        <h1 className={classes.imageUploadHeader}>Upload Profile</h1>
        <div className={classes.imagesContainer}>
          <div className={classes.imagesLabelContainer}>
            <div className={classes.imageContainer} onClick={() => handleProfileUpload()} onMouseEnter={() => onProfImgHov(true)} onMouseLeave={() => onProfImgHov(false)}>
              {profileImage?.preview !== "" ? (
                <Fragment>
                  <img alt={userInfo?.user?.fullName ?? ""} src={newUploadImage ? newUploadImage : profileImage?.preview} className={classes.img} />
                  <div className={`${classes.hoverIconContainer} ${profImgHov && classes.active}`}>
                    <Publish fontSize="inherit" color="inherit" className={classes.hoverIcon} />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className={classes.fallbackContainer}>
                    <h1 className={classes.fallbackText}>{firstInitial}</h1>
                  </div>
                  <div className={`${classes.hoverIconContainer} ${profImgHov && classes.active}`}>
                    <Publish fontSize="inherit" color="inherit" className={classes.hoverIcon} />
                  </div>
                </Fragment>
              )}
              <input
                type="file"
                ref={profileImageInput}
                onChange={(e) => {
                  handleProfileSubmit(e);
                  e.target.value = null;
                }}
                style={{ display: "none" }}
                accept="image/png, image/jpeg"
              />
            </div>
          </div>
        </div>
        <SaveButton
          disabled={profileImageIsFetching}
          onClick={() => {
            handleInputSave();
          }}
        >
          <div className={profileImageIsFetching ? classes.saveLoading : null}>SAVE</div>
          {profileImageIsFetching ? <CircularProgress style={{ height: "28px", width: "28px", color: "white" }} /> : null}
        </SaveButton>
        <div className={classes.errorDiv}>{profileError ? "There was a problem uploading your image." : ""}</div>
      </div>
    </Backdrop>
  );
};

ImageUpload.propTypes = {
  imageUpload: PropTypes.bool,
  setImageUpload: PropTypes.func,
  setUseNewImage: PropTypes.func,
  profileImage: PropTypes.any,
  setProfileImage: PropTypes.func,
};

export default ImageUpload;
