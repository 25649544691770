import React, { lazy, Suspense, useEffect, useState } from "react";
import { useImmerReducer } from "use-immer";
import { Route, Switch, Redirect } from "react-router-dom";
import { appReducer, initialState, AppStateContext, AppDispatchContext } from "../../Context/AppReducer";
import { CssBaseline, ThemeProvider, Grid } from "@material-ui/core";
import theme from "./Theme";
import AppStylesheet from "./AppStylesheet";
import { getGoogleApiKey, getElasticActive, getElasticServerUrl, getElasticTransactionSampleRate } from "../../Helpers/getEnvVar";
import loadGoogleApiScript from "../../Helpers/loadGoogleApiScript";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./App.css";
import UnSubscribe from "../UnSubscribe/UnSubscribe";
import SSOLogin from "../SSOLogin/SSOLogin";
import FacebookDelete from "../FacebookDelete/FacebookDelete.js";
import NavigationParent from "../../Components/Navigation/NavigationParent";

//Non-lazy loaded components.
import AdminView from "../../Views/AdminView";
import DashboardView from "../../Views/DashboardView";
import MarketReportsView from "../../Views/MarketReportsView";
import ContactsView from "../../Views/ContactsView";
import ContactView from "../../Views/ContactView";
import UserSettingsView from "../../Views/UserSettingsView";
import UserPreferencesView from "../../Views/UserPreferencesView";
import LandingPagePreferencesView from "../../Views/LandingPagePreferencesView";
import LandingPageView from "../../Views/LandingPageView";
import Home from "../Home/HomeComponent";
import Loader from "../Common/Loaders/Loader";
import SessionExpiredModal from "./SessionExpiredModal";

import { init as initApm } from "@elastic/apm-rum";
import { CreateReportIframeView } from "../../Views/CreateReportIframeView";

const elasticActive = getElasticActive();
const elasticServerUrl = getElasticServerUrl();
const elasticTransactionSampleRate = getElasticTransactionSampleRate();

initApm({
  active: elasticActive,
  breakdownMetrics: true,
  flushInterval: 1000,
  logLevel: "error",
  serviceName: "areapulse-app-rum",
  serverUrl: elasticServerUrl,
  transactionSampleRate: elasticTransactionSampleRate
});

//Lazy loaded components.
const NotFoundPage = lazy(() => import("../NotFound/NotFoundComponent"));
const PasswordReset = lazy(() => import("../ResetPassword/ResetPassword"));
const Privacy = lazy(() => import("../PrivacyPolicy/PrivacyComponent"));
const Report = lazy(() => import("../Report/Report"));

const App = () => {
  const classes = AppStylesheet();
  const [state, dispatch] = useImmerReducer(appReducer, initialState);
  const [createReportOpen, setCreateReportOpen] = useState({
    open: false,
    editing: false,
    editPayload: {},
    termInfo: {},
    isAddress: false,
  });
  const [createContactOpen, setCreateContactOpen] = useState({ open: false, editing: false, editPayload: {} });
  const [savedReport, setSavedReport] = useState(false);
  const [subscribe, setSubscribe] = useState(false);
  const user = state?.user?.username;
  const userId = state?.user?.userId;
  const userRole = state?.user?.Roles;
  const googleApiKey = getGoogleApiKey();
  const [googleScriptLoaded, setGoogleScriptLoaded] = useState(false);
  useEffect(() => {
    const url = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
    loadGoogleApiScript(url, () => setGoogleScriptLoaded(true));
  }, [googleApiKey]);
  return (
    <AppDispatchContext.Provider value={dispatch}>
      <AppStateContext.Provider value={state}>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <Grid container justify="center" alignItems="center" className={classes.mainContainer}>
              <Grid item xs={12} className={classes.mainContainer}>
                <SessionExpiredModal />
                <Suspense fallback={<Loader />}>
                  <NavigationParent
                    userRole={userRole}
                    user={user}
                    state={state}
                    setSavedReport={(status) => setSavedReport(status)}
                    savedReport={savedReport}
                    createReportOpen={createReportOpen}
                    setCreateReportOpen={(status) => setCreateReportOpen(status)}
                  />
                  <Route
                    render={({ location }) => (
                      <TransitionGroup>
                        <CSSTransition classNames="fade" key={location.key} timeout={300}>
                          <Switch location={location}>
                            <Route exact path="/">
                              {state?.loggedIn ? <Redirect to={`/${state.user?.username}/dashboard`} /> : <Home />}
                            </Route>
                            <Route exact path="/home">
                              <Home googleScriptLoaded={googleScriptLoaded} />
                            </Route>
                            <Route exact path="/reset-password" component={PasswordReset} />
                            <Route exact path="/privacy-policy" component={Privacy} />
                            <Route exact path="/:user/market-reports/:marketReportId" component={Report} />
                            <Route exact path="/:user/market-reports/signup/:marketReportId" component={Report} />
                            <Route exact path="/unsubscribe/:token/:reportID" component={UnSubscribe} />
                            <Route exact path="/sso/logmein/:token" component={SSOLogin} />
                            <Route exact path="/facebook-delete" component={FacebookDelete} />
                            <Route
                              exact
                              path="/:user/create-report/"
                              render={(props) => (
                                <CreateReportIframeView
                                  loggedIn={state.loggedIn}
                                  googleScriptLoaded={googleScriptLoaded}
                                  {...props}
                                />
                              )}
                            />

                            <Route
                              path="/markspain"
                              component={() => {
                                window.location.href = "https://markspain.com/getguaranteedoffer/";
                                return null;
                              }}
                            />
                            <Route
                              exact
                              path="/:user/dashboard"
                              render={(props) =>
                                state.loggedIn ? (
                                  <DashboardView
                                    savedReport={savedReport}
                                    setSavedReport={(status) => setSavedReport(status)}
                                    createReportOpen={createReportOpen}
                                    setCreateReportOpen={(status) => setCreateReportOpen(status)}
                                    googleScriptLoaded={googleScriptLoaded}
                                    userId={userId}
                                    {...props}
                                  />
                                ) : (
                                  <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/:user/admin"
                              render={(props) =>
                                state.loggedIn ? (
                                  <AdminView
                                    savedReport={savedReport}
                                    setSavedReport={(status) => setSavedReport(status)}
                                    createReportOpen={createReportOpen}
                                    setCreateReportOpen={(status) => setCreateReportOpen(status)}
                                    googleScriptLoaded={googleScriptLoaded}
                                    {...props}
                                  />
                                ) : (
                                  <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/:user"
                              render={(props) => (
                                <LandingPageView
                                  subscribe={subscribe}
                                  setSubscribe={setSubscribe}
                                  savedReport={savedReport}
                                  setSavedReport={(status) => setSavedReport(status)}
                                  createReportOpen={createReportOpen}
                                  setCreateReportOpen={(status) => setCreateReportOpen(status)}
                                  createContactOpen={createContactOpen}
                                  setCreateContactOpen={(status) => setCreateContactOpen(status)}
                                  googleScriptLoaded={googleScriptLoaded}
                                  {...props}
                                />
                              )}
                            />
                            <Route
                              exact
                              path="/:user/market-reports"
                              render={(props) =>
                                state.loggedIn ? (
                                  <MarketReportsView
                                    userId={userId}
                                    savedReport={savedReport}
                                    setSavedReport={(status) => setSavedReport(status)}
                                    createReportOpen={createReportOpen}
                                    setCreateReportOpen={(status) => setCreateReportOpen(status)}
                                    googleScriptLoaded={googleScriptLoaded}
                                    {...props}
                                  />
                                ) : (
                                  <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/:user/user-preferences"
                              render={(props) =>
                                state.loggedIn ? (
                                  <UserPreferencesView
                                    createReportOpen={createReportOpen}
                                    setCreateReportOpen={(status) => setCreateReportOpen(status)}
                                    googleScriptLoaded={googleScriptLoaded}
                                    {...props}
                                  />
                                ) : (
                                  <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/:user/landing-page-preferences"
                              render={(props) =>
                                state.loggedIn ? (
                                  <LandingPagePreferencesView
                                    createReportOpen={createReportOpen}
                                    setCreateReportOpen={(status) => setCreateReportOpen(status)}
                                    googleScriptLoaded={googleScriptLoaded}
                                    {...props}
                                  />
                                ) : (
                                  <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/:user/contacts"
                              render={(props) =>
                                state.loggedIn ? (
                                  <ContactsView
                                    createReportOpen={createReportOpen}
                                    setCreateReportOpen={(status) => setCreateReportOpen(status)}
                                    googleScriptLoaded={googleScriptLoaded}
                                    createContactOpen={createContactOpen}
                                    setCreateContactOpen={(status) => setCreateContactOpen(status)}
                                    {...props}
                                  />
                                ) : (
                                  <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/:user/contacts/:contactId"
                              render={(props) =>
                                state.loggedIn ? (
                                  <ContactView
                                    userId={userId}
                                    subscribe={subscribe}
                                    setSubscribe={setSubscribe}
                                    createReportOpen={createReportOpen}
                                    setCreateReportOpen={(status) => setCreateReportOpen(status)}
                                    googleScriptLoaded={googleScriptLoaded}
                                    {...props}
                                  />
                                ) : (
                                  <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/:user/user-settings"
                              render={(props) =>
                                state.loggedIn ? (
                                  <UserSettingsView
                                    createReportOpen={createReportOpen}
                                    setCreateReportOpen={(status) => setCreateReportOpen(status)}
                                    googleScriptLoaded={googleScriptLoaded}
                                    {...props}
                                  />
                                ) : (
                                  <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
                                )
                              }
                            />
                            {/* <PrivateRoute exact path="/:user/widgets" component={Widgets} /> */}
                            {/* <PrivateRoute exact path="/:user/tutorials" component={Tutorials} /> */}
                            {/* <PrivateRoute exact path="/:user/users" component={Users} /> */}
                            {/* <PrivateRoute exact path="/:user/clients" component={Clients} /> */}
                            <Route exact path="/404" component={NotFoundPage} />
                            <Route path="*">
                              <Redirect to="/404" />
                            </Route>
                          </Switch>
                        </CSSTransition>
                      </TransitionGroup>
                    )}
                  />
                </Suspense>
              </Grid>
            </Grid>
          </CssBaseline>
        </ThemeProvider>
      </AppStateContext.Provider>
    </AppDispatchContext.Provider>
  );
};

export default App;
