const getFormattedTerm = (term, mls) => {
  switch (term.cat) {
    case "Google Address": {
      const splitValue = term.value.split(",");
      return {
        address: splitValue[0]?.trim() ?? "",
        city: "",
        state: splitValue[2]?.trim() ?? "",
        county: "",
        zip: "",
        neighborhood: "",
      };
    }
    case "Address": {
      const splitValue = term.value.split(",");
      return {
        address: splitValue[0]?.trim() ?? "",
        city: "",
        state: splitValue[2]?.trim() ?? "",
        county: "",
        zip: splitValue[3]?.trim() ?? "",
        neighborhood: "",
      };
    }
    case "City": {
      const splitValue = term.value.split(",");
      return {
        address: "",
        city: splitValue[0]?.trim() ?? "",
        state: splitValue[1]?.trim() ?? "",
        county: "",
        zip: "",
        neighborhood: "",
      };
    }
    case "County": {
      const splitValue = term.value.split(",");
      return {
        address: "",
        city: "",
        state: splitValue[1]?.trim() ?? "",
        county: splitValue[0]?.trim() ?? "",
        zip: "",
        neighborhood: "",
      };
    }
    case "Zip Code": {
      return {
        address: "",
        city: "",
        state: mls,
        county: "",
        zip: term.value ?? "",
        neighborhood: "",
      };
    }
    case "Neighborhood": {
      const splitValue = term.value.split(",");
      return {
        address: "",
        city: splitValue[1]?.trim() ?? "",
        state: splitValue[2]?.trim() ?? "",
        county: "",
        zip: "",
        neighborhood: splitValue[0]?.trim() ?? "",
      };
    }
  }
};

export default getFormattedTerm;
