import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GET_USER_DATA } from "../../../../Services/Graph/UserSettings/Queries";
import { AppStateContext, AppDispatchContext } from "../../../../Context/AppReducer";
import { authApolloClient } from "../../../../Services/Graph/apolloConfig";
import { useLazyQuery, useMutation } from "@apollo/client";
import { createStyles, makeStyles, Paper, CircularProgress, Button, Fade } from "@material-ui/core";
import { ErrorOutlineOutlined } from "@material-ui/icons";
import UserSettingsAccountLoginDetailsInputs from "./UserSettingsAccountLoginDetailsInputs";
import { Check, EditOutlined, CloseRounded } from "@material-ui/icons";
import { UPDATE_USER } from "../../../../Services/Graph/UserSettings/Mutations";
import usePasswordReset from "../../../../Services/Rest/passwordResetHook";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: "20px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
    },
    accountSettingsHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "30px",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    accountSettingsInfoText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    editContactButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    editContactButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    cancelButton: {
      color: theme.palette.grey[500],
      marginRight: "10px",
    },
    infoContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    infoText: {
      width: "100%",
    },
    inputActionButtons: {
      width: "130px",
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "center",
      margin: "60px 0px",
    },
    forgotPasswordLink: {
      fontFamily: theme.typography.secondary,
      fontSize: "12px",
      fontWeight: "600",
      color: "#0079B4",
      cursor: "pointer",
      margin: "0px",
    },
    sendButtonLabel: {
      color: "#fff",
    },
    sentConfirmation: {
      textAlign: "center",
      marginTop: "60px",
    },
    sentConfirmationLabel: {
      marginBottom: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    sentConfirmationAgainLabel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    sentConfirmationLabelText: {
      color: theme.palette.areaPulseBlue,
      fontFamily: theme.typography.secondary,
      fontSize: "20px",
      margin: 0,
    },
    sentConfirmationLabelCheck: {
      marginLeft: "10px",
      fontSize: "20px",
      color: theme.palette.areaPulseBlue,
    },
    sentConfirmationContent: {
      fontFamily: theme.typography.secondary,
    },
    closeModalButton: {
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      "&:hover": {},
    },
    closeModalButtonIcon: {
      color: "#282828",
      fontSize: "20px",
    },
    didNotReceive: {
      fontFamily: theme.typography.secondary,
      textDecoration: "underline",
      fontSize: "14px",
      color: "#646464",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

const UserSettingsAccountLoginDetails = ({ setOpenUpdateUserLoginSuccess, openUpdateUserLoginSuccess }) => {
  const classes = useStyles();
  const globalState = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);
  const [openUpdatingLoginInputs, setOpenUpdatingLoginInputs] = useState(false);
  const [updatedUserLoginInfo, setUpdatedUserLoginInfo] = useState({});
  const [updateWasMade, setUpdateWasMade] = useState(false);
  const [openPasswordReset, setOpenPasswordReset] = useState(false);
  const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false);
  const [getNewUserData, setGetNewUserData] = useState(false);
  const [tryingToUpdateUsername, setTryingToUpdateUsername] = useState(false);

  const [email, validateEmail] = usePasswordReset();
  const [sendResetAgainConfirmation, setSendResetAgainConfirmation] = useState(false);

  // QUERIES
  const [fetchUserLoginInfo, { data: userLoginInfoData, loading: userLoginInfoLoading, error: userLoginInfoError }] = useLazyQuery(GET_USER_DATA, {
    variables: {
      id: globalState?.user?.userId ?? 0,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });
  const userLoginInfo = userLoginInfoData?.user;

  const [updateUserUsername, { data: userData, loading: userLoading, error: userError }] = useMutation(UPDATE_USER, {
    variables: {
      title: userLoginInfo?.title,
      licenseId: userLoginInfo?.licenseId,
      email: userLoginInfo?.email,
      phone: userLoginInfo?.phone,
      firstName: userLoginInfo?.firstName,
      lastName: userLoginInfo?.lastName,
      userId: userLoginInfo?.id,
      username: updatedUserLoginInfo.username,
    },
    client: authApolloClient,
  });

  useEffect(() => {
    fetchUserLoginInfo();
  }, [fetchUserLoginInfo]);

  useEffect(() => {
    if (getNewUserData && !userLoading && !userError) {
      setOpenUpdateUserLoginSuccess(true);
      fetchUserLoginInfo();
      setGetNewUserData(false);
      setOpenUpdatingLoginInputs(false);
    }
  }, [userData, getNewUserData]);

  useEffect(() => {
    if (Object.keys(updatedUserLoginInfo ?? {}).length !== 0 && updateWasMade === true && Object.keys(userLoginInfoData ?? {}).length !== 0) {
      updateUserUsername();
    }
  }, [updatedUserLoginInfo, updateWasMade, userLoginInfoData]);

  useEffect(() => {
    if ((userData && !userError, tryingToUpdateUsername)) {
      globalDispatch({ type: "setUserName", payload: updatedUserLoginInfo?.username });
      setGetNewUserData(true);
      setUpdateWasMade(false);
    }
  }, [userData]);

  useEffect(() => {
    if (sendResetAgainConfirmation) {
      const closeUpdateUserAlert = setTimeout(() => {
        setSendResetAgainConfirmation(false);
      }, 3000);
      return () => clearTimeout(closeUpdateUserAlert);
    }
  }, [sendResetAgainConfirmation]);

  const checkForFade = () => {
    if (userLoginInfo) {
      return true;
    } else {
      return false;
    }
  };

  const handleOpen = () => {
    setOpenPasswordReset(true);
  };

  const handleClose = () => {
    setOpenPasswordReset(false);
    setPasswordResetEmailSent(false);
  };

  const handlePasswordReset = () => {
    validateEmail(userLoginInfo?.email);
    setPasswordResetEmailSent(true);
  };

  const handlePasswordResetAgain = () => {
    validateEmail(userLoginInfo?.email);
    setSendResetAgainConfirmation(true);
    setPasswordResetEmailSent(true);
  };

  return (
    <div>
      <Paper className={classes.paper}>
        {openPasswordReset ? (
          <div>
            <div className={classes.accountSettingsHeaderContainer}>
              <Fade in={true} timeout={500}>
                <h1 className={classes.paperLabel}>Resetting Password</h1>
              </Fade>

              <div className={classes.modalClose}>
                <Fade in={true} timeout={500}>
                  <Button className={classes.closeModalButton} onClick={handleClose}>
                    <CloseRounded color="inherit" fontSize="inherit" className={classes.closeModalButtonIcon} />
                  </Button>
                </Fade>
              </div>
            </div>
            <div className={classes.infoContainer}>
              {userLoginInfoLoading && (
                <CircularProgress
                  size={60}
                  thickness={3}
                  color="inherit"
                  className={classes.progress}
                  classes={{
                    circle: classes.circle,
                  }}
                />
              )}
              {userLoginInfoError && <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.error} />}
              {openUpdatingLoginInputs ? (
                <UserSettingsAccountLoginDetailsInputs
                  userLoginInfo={userLoginInfo}
                  setOpenUpdatingLoginInputs={setOpenUpdatingLoginInputs}
                  openUpdatingLoginInputs={openUpdatingLoginInputs}
                  setUpdatedUserLoginInfo={setUpdatedUserLoginInfo}
                  setUpdateWasMade={setUpdateWasMade}
                />
              ) : null}
              {Object.keys(userLoginInfo).length !== 0 && userLoginInfoData !== undefined && openUpdatingLoginInputs === false ? (
                <div className={classes.infoText}>
                  <Fade in={true} timeout={500}>
                    <div>
                      <h2 className={classes.accountSettingsInfoText}>Send password reset link to email on file:</h2>
                      <h2 className={classes.accountSettingsInfoText}>{userLoginInfo?.email}</h2>
                    </div>
                  </Fade>
                  {passwordResetEmailSent ? (
                    <div className={classes.sentConfirmation}>
                      <Fade in={true} timeout={500}>
                        <div className={classes.sentConfirmationLabel}>
                          <h2 className={classes.sentConfirmationLabelText}>Sent! </h2>
                          <Check color="inherit" fontSize="inherit" className={classes.sentConfirmationLabelCheck} />
                        </div>
                      </Fade>
                      <Fade in={true} timeout={500}>
                        <h3 className={classes.sentConfirmationContent}>Please check your inbox and follow instructions to reset your password.</h3>
                      </Fade>
                      <Fade in={true} timeout={500}>
                        <div className={classes.sentConfirmationAgainLabel}>
                          <h3 className={classes.didNotReceive} onClick={handlePasswordResetAgain}>
                            Oh geez. I did not receive an email. Please send again.{" "}
                          </h3>
                          <Fade in={sendResetAgainConfirmation} timeout={500}>
                            <Check color="inherit" fontSize="inherit" className={classes.sentConfirmationLabelCheck} />
                          </Fade>
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    <Fade in={true} timeout={500}>
                      <div className={classes.buttonGroup}>
                        <Button
                          aria-label="cancel"
                          className={classes.cancelButton}
                          onClick={() => {
                            setOpenPasswordReset(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button className={classes.editContactButton} onClick={handlePasswordReset}>
                          <span className={classes.sendButtonLabel}>Send</span>
                        </Button>
                      </div>
                    </Fade>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div>
            <div className={classes.accountSettingsHeaderContainer}>
              <h1 className={classes.paperLabel}>Login Details</h1>
              <div>
                {openUpdatingLoginInputs ? (
                  <Fade in={openUpdatingLoginInputs} timeout={500}>
                    <div>
                      <Button
                        aria-label="cancel"
                        className={classes.cancelButton}
                        onClick={() => {
                          setOpenUpdatingLoginInputs(false);
                          setUpdatedUserLoginInfo({ username: userLoginInfo?.username });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button className={classes.editContactButton} type="submit" form="user-account-login-form">
                        <Check color="inherit" fontSize="inherit" className={classes.editContactButtonIcon} />
                      </Button>
                    </div>
                  </Fade>
                ) : (
                  <Button
                    className={classes.editContactButton}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenUpdatingLoginInputs(true);
                    }}
                  >
                    <EditOutlined color="inherit" fontSize="inherit" className={classes.editContactButtonIcon} />
                  </Button>
                )}
              </div>
            </div>
            <div className={classes.infoContainer}>
              {userLoginInfoLoading && (
                <CircularProgress
                  size={60}
                  thickness={3}
                  color="inherit"
                  className={classes.progress}
                  classes={{
                    circle: classes.circle,
                  }}
                />
              )}
              {userLoginInfoError && <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.error} />}
              {openUpdatingLoginInputs ? (
                <UserSettingsAccountLoginDetailsInputs
                  setTryingToUpdateUsername={setTryingToUpdateUsername}
                  userError={userError}
                  userLoginInfo={userLoginInfo}
                  setOpenUpdateUserLoginSuccess={setOpenUpdateUserLoginSuccess}
                  setOpenUpdatingLoginInputs={setOpenUpdatingLoginInputs}
                  openUpdatingLoginInputs={openUpdatingLoginInputs}
                  setUpdatedUserLoginInfo={setUpdatedUserLoginInfo}
                  setUpdateWasMade={setUpdateWasMade}
                />
              ) : null}
              {Object.keys(userLoginInfo ?? {}).length !== 0 && userLoginInfoData !== undefined && openUpdatingLoginInputs === false ? (
                <Fade in={checkForFade()} timeout={500}>
                  <div className={classes.infoText}>
                    <h2 className={classes.accountSettingsInfoText}>username: {userLoginInfo?.username}</h2>
                    <h2 className={classes.accountSettingsInfoText}>password: ********</h2>
                    <h3 className={classes.forgotPasswordLink} onClick={handleOpen}>
                      forgot password?
                    </h3>
                  </div>
                </Fade>
              ) : null}
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

UserSettingsAccountLoginDetails.propTypes = {
  openUpdateUserLoginSuccess: PropTypes.bool,
  setOpenUpdateUserLoginSuccess: PropTypes.func,
};

export default UserSettingsAccountLoginDetails;
