import React, { useContext, useState, useEffect } from "react";
import { makeStyles, createStyles, Paper, Button, Fade } from "@material-ui/core";
import { Add, EditOutlined } from "@material-ui/icons";
import { GET_USER_BILLING_INFO } from "../../../../Services/Graph/UserSettings/Queries";
import { DELETE_PAYMENT_METHOD } from "../../../../Services/Graph/UserSettings/Mutations";
import { authApolloClient } from "../../../../Services/Graph/apolloConfig";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AppStateContext, AppDispatchContext } from "../../../../Context/AppReducer";
import { ErrorOutlineOutlined } from "@material-ui/icons";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Grid from "@material-ui/core/Grid";
import DeleteModal from "../../../Common/Modals/DeleteModal";
import UserSettingsBillingPaymentModal from "./UserSettingsBillingPaymentModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getStripePublishableKey } from "../../../../Helpers/getEnvVar";
import CircularProgressStyled from "../../../Common/Loaders/CircularProgressStyled";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: "20px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    paymentMethodGrid: {
      borderBottom: "1px solid #E0E0E0",
      marginBottom: "15px",
    },
    accountSettingsHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "30px",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    accountSettingsInfoText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    accountSettingsInfoCardNum: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      marginLeft: "45px",
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    editUserButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    editUserButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    infoContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
    },
    infoText: {
      width: "100%",
    },
    inputActionButtons: {
      width: "112px",
    },
    deletePaymentButton: {
      minWidth: "0px",
      padding: "9px 20px",
      borderRadius: "10px",
      "&:hover": {},
    },
    deletePaymentButtonIcon: {
      color: theme.palette.areaPulseRed,
      fontSize: "24px",
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginRight: "12px",
    },
  })
);
const stripePromise = loadStripe(`${getStripePublishableKey()}`);

const UserSettingsBillingBilling = () => {
  const classes = useStyles();
  const { user } = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [paymentToDelete, setPaymentToDelete] = useState("");
  const [payments, setPayments] = useState([]);

  const [fetchUserBillingInfo, { data: userBillingInfoData, loading: userBillingInfoLoading, error: userBillingInfoError }] = useLazyQuery(GET_USER_BILLING_INFO, {
    variables: {
      userId: user?.userId,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  const [deletePayment] = useMutation(DELETE_PAYMENT_METHOD, {
    onCompleted(data) {
      let tempPayments = payments;
      tempPayments = tempPayments.filter(function (payment) {
        return payment.id !== data?.detachPaymentMethod?.id;
      });
      setPayments(tempPayments);
    },
    variables: {
      userId: user?.userId,
      paymentMethodId: paymentToDelete,
    },
    client: authApolloClient,
  });

  useEffect(() => {
    setPayments(userBillingInfoData?.paymentMethods?.paymentMethods ?? []);
  }, [userBillingInfoData]);

  useEffect(() => {
    const errorMessage = userBillingInfoError?.networkError?.result?.error ?? "";
    if (userBillingInfoError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [userBillingInfoError, globalDispatch]);

  useEffect(() => {
    fetchUserBillingInfo();
  }, [fetchUserBillingInfo]);

  const handlePaymentDelete = () => {
    deletePayment();
    setIsDeleteModalOpen(false);
  };

  const handlePaymentAdd = (payment) => {
    let tempPayments = payments;
    if (payment.id) {
      tempPayments.push({
        id: payment.id,
        last4: payment.card.last4,
        brand: payment.card.brand,
        name: payment.billing_details.name,
      });
    }
    setPayments(tempPayments);
    setIsSaveModalOpen(false);
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.accountSettingsHeaderContainer}>
        <h1 className={classes.paperLabel}>Billing</h1>
        <div>
          {isEditing ? (
            <Fade in={true} timeout={500}>
              <div className={classes.inputActionButtons}>
                <Button
                  aria-label="cancel"
                  className={classes.cancelButton}
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.editUserButton}
                  onClick={() => {
                    setIsSaveModalOpen(true);
                  }}
                >
                  <Add color="inherit" fontSize="inherit" className={classes.editUserButtonIcon} />
                </Button>
              </div>
            </Fade>
          ) : (
            <Button
              className={classes.editUserButton}
              onClick={(e) => {
                e.preventDefault();
                setIsEditing(true);
              }}
            >
              <EditOutlined color="inherit" fontSize="inherit" className={classes.editUserButtonIcon} />
            </Button>
          )}
        </div>
      </div>
      <div className={classes.infoContainer}>
        {userBillingInfoLoading && <CircularProgressStyled />}
        {userBillingInfoError && <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.error} />}
        {payments !== [] && userBillingInfoData !== undefined ? (
          <Fade in={true} timeout={500}>
            <div className={classes.infoText}>
              {payments.map((payment, index) => {
                return (
                  <div key={payment.id} className={classes.singlePaymentMethod}>
                    <Grid key={payment.id} className={classes.paymentMethodGrid} container spacing={1} alignItems="center">
                      <Grid item xs={12}>
                        <h2 className={classes.accountSettingsInfoText}>{payment.name}</h2>
                      </Grid>
                      <Grid item xs={2}>
                        <h2 className={classes.accountSettingsInfoText}>{payment.brand}</h2>
                      </Grid>
                      <Grid item xs={2}>
                        <h2 className={classes.accountSettingsInfoCardNum}>{payment.last4}</h2>
                      </Grid>
                      <Grid item xs={8}>
                        <div className={classes.buttonContainer}>
                          {payments.length > 1 && isEditing ? (
                            <Fade in={true} timeout={500}>
                              <Button
                                className={classes.deletePaymentButton}
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setPaymentToDelete(payment.id);
                                }}
                              >
                                <DeleteOutlineOutlinedIcon color="inherit" fontSize="inherit" className={classes.deletePaymentButtonIcon} />
                              </Button>
                            </Fade>
                          ) : null}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </div>
          </Fade>
        ) : null}
        <DeleteModal
          open={isDeleteModalOpen}
          title="DELETE PAYMENT"
          description="Are you sure you want to delete the payment method?"
          handleDelete={() => handlePaymentDelete(paymentToDelete)}
          handleClose={() => {
            setIsDeleteModalOpen(false);
          }}
        />
        <Elements stripe={stripePromise}>
          <UserSettingsBillingPaymentModal
            userId={user?.userId}
            open={isSaveModalOpen}
            fullname={user?.fullName}
            email={user?.email}
            phone={user?.phone}
            handleClose={() => {
              setIsSaveModalOpen(false);
            }}
            handlePaymentAdd={handlePaymentAdd}
          />
        </Elements>
      </div>
    </Paper>
  );
};

UserSettingsBillingBilling.propTypes = {};

export default UserSettingsBillingBilling;
