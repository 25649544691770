import React, { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { AppDispatchContext } from "../../../../../Context/AppReducer";
import PropTypes from "prop-types";
import { Grid, IconButton, Fade, CircularProgress, withStyles, TextField, makeStyles, createStyles, Button } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import useSignup from "../../../../../Services/Rest/signupHook";
import useValidateEmail from "../../../../../Services/Rest/validateEmailHook";
import { saveState } from "../../../../../Helpers/localStorage";
import { withRouter } from "react-router-dom";

const ReportContactInput = withStyles({
  root: {
    overflow: "hidden",
    width: "100%",
    height: "48px",
    maxWidth: "552px",
    backgroundColor: "#FFFFFF",
    boxShadow: "3px 6px 6px 1px rgb(0 0 0 / 9%)",
    borderRadius: "12px",
    "& label": {
      width: "1000px",
      color: "#CDCDCD",
      paddingLeft: "10px",
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "14px",
    },
    "& label.Mui-focused": {
      color: "#777777",
      fontSize: "18px",
      paddingTop: "0px",
    },
    "& .MuiInputLabel-shrink": {
      paddingTop: "0px",
      fontSize: "18px",
      display: "none",
    },
    "& .MuiFilledInput-root": {
      marginTop: "-14px",
      padding: "0px 0px 0px 16px",
      fontSize: "22px",
      fontWeight: "700",
      fontFamily: "nunito",
      height: "100px",
      color: "#000",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
      "&.MuiFilledInput-underline:after": {
        display: "none",
      },
    },
  },
})(TextField);
const SampleButton = withStyles((theme) => ({
  root: {
    color: "#FFF",
    top: "0",
    right: "0",
    padding: "15px 29px",
    position: "absolute",
    background: "linear-gradient(180deg, #FF507A 0%, #E14864 100%)",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Nunito",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "700",
    "&:hover": {
      transition: "0.2s",
      opacity: "0.8",
    },
  },
  "@media (max-width: 450px)": {
    root: {
      marginTop: "5px",
      position: "relative",
      left: "75px",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) =>
  createStyles({
    captureParent: {
      padding: "235px 0px 0px 120px",
    },
    emailHeader: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "24px",
      lineHeight: "32px",
      margin: "5px 0px",
    },
    emailHeaderBig: {
      fontFamily: "nunito",
      fontWeight: "800",
      fontSize: "48px",
      lineHeight: "65px",
      marginBottom: "35px",
    },
    emailHeaderSmall: {
      fontFamily: "nunito",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "22px",
      marginBottom: "18px",
    },
    widthParent: {
      width: "100%",
    },
    textButtton: {
      position: "relative",
      maxWidth: "552px",
    },
    alreadyHasAccount: {
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      marginTop: "77px",
      textDecoration: "underline",
      cursor: "pointer",
      color: "#D33141",
    },
    "@media (max-width: 960px)": {
      captureParent: {
        padding: "235px 50px 0px 50px",
      },
    },
  })
);

const CaptureEmail = ({ setEmailValue, activeView, setActiveView, history }) => {
  const classes = useStyles();
  const dispatch = useContext(AppDispatchContext);
  const { handleSubmit, register, errors, watch } = useForm();
  const [email, validateEmail] = useValidateEmail();

  const onSubmit = (values) => {
    setEmailValue(values.email);
    setActiveView(10);
  };

  return (
    <Fade in={activeView === 2} timeout={600} className={classes.captureParent}>
      <Grid container className={""}>
        <div className={classes.widthParent}>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M34.6736 12.0153C34.6892 11.9041 34.6892 11.7913 34.6736 11.6801C34.6592 11.5863 34.6315 11.495 34.5911 11.4087C34.5475 11.3307 34.4979 11.256 34.4426 11.1852C34.3799 11.0842 34.302 10.9928 34.2117 10.9138L34.0137 10.8021C33.9185 10.7334 33.8127 10.6795 33.7002 10.6424H33.3703C33.2697 10.5482 33.1523 10.4725 33.0238 10.4189H24.7749C24.3374 10.4189 23.9177 10.5871 23.6083 10.8865C23.2989 11.1859 23.1251 11.5919 23.1251 12.0153C23.1251 12.4387 23.2989 12.8447 23.6083 13.1441C23.9177 13.4434 24.3374 13.6116 24.7749 13.6116H29.4438L22.8447 21.1304L15.7176 17.0278C15.38 16.8335 14.9819 16.7622 14.5948 16.8265C14.2077 16.8909 13.857 17.0868 13.6059 17.379L5.35696 26.957C5.21805 27.1184 5.11339 27.3046 5.04897 27.5051C4.98456 27.7056 4.96165 27.9164 4.98158 28.1255C5.0015 28.3346 5.06386 28.5378 5.16507 28.7236C5.26629 28.9093 5.40438 29.0739 5.57144 29.2079C5.86825 29.4459 6.24182 29.5758 6.6273 29.575C6.86967 29.5754 7.10915 29.5241 7.32871 29.4248C7.54827 29.3255 7.74251 29.1806 7.89763 29.0004L15.2392 20.4759L22.2837 24.5625C22.6179 24.7543 23.0112 24.8258 23.3945 24.7645C23.7778 24.7032 24.1264 24.5129 24.379 24.2273L31.374 16.3254V19.997C31.374 20.4204 31.5479 20.8264 31.8572 21.1258C32.1666 21.4252 32.5863 21.5933 33.0238 21.5933C33.4614 21.5933 33.881 21.4252 34.1904 21.1258C34.4998 20.8264 34.6736 20.4204 34.6736 19.997V12.0153Z"
              fill="#0079B4"
            />
          </svg>

          <div className={`${classes.emailHeader}`}>Market Reports That</div>
          <div className={`${classes.emailHeaderBig}`}>DRIVE RESULTS</div>
          <div className={`${classes.emailHeaderSmall} `}>Set up your free account in less than 5 minutes.</div>
          <form onSubmit={handleSubmit((data) => onSubmit(data))} id="sign-email-form">
            <div className={classes.textButtton}>
              <ReportContactInput
                type="email"
                variant="filled"
                label="Enter Email Address"
                className={classes.inputSpacing}
                error={Boolean(errors.email) || email?.errorMessage !== ""}
                helperText={errors.email?.message !== "" ? errors.email?.message : email?.errorMessage}
                InputProps={{
                  name: "email",
                }}
                inputRef={register({
                  validate: (value) => {
                    if (value === "") {
                      return "Please enter an email address";
                    } else {
                      validateEmail(value);
                    }
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please enter a valid email address",
                  },
                })}
              ></ReportContactInput>
              <SampleButton type="submit" form="sign-email-form">
                GET STARTED
              </SampleButton>
            </div>
          </form>
          <div className={classes.emailParent}>
            <div>
              <div className={classes.alreadyHasAccount} onClick={() => setActiveView(3)}>
                Already Have An Account?
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Fade>
  );
};

CaptureEmail.propTypes = {
  activeView: PropTypes.number,
  setActiveView: PropTypes.func,
};

export default withRouter(CaptureEmail);
