import { gql } from "@apollo/client";

export const DELETE_USER = gql`
  mutation deleteUser($userId: Int!) {
    deleteUser(userId: $userId) {
      id
      email
    }
  }
`;
