import React, { useState } from "react";
import { Element } from "react-scroll";
import { makeStyles, createStyles, Grid } from "@material-ui/core";
import SearchBarInputs from "./SearchBarInputs";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    searchBarSection: {
      position: "relative",
      padding: "60px  0px 120px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    sectionContentContainer: {
      position: "relative",
      width: "80%",
      maxWidth: "1600px",
      zIndex: "7",
    },
    "@media (max-width: 1280px)": {
      sectionContentContainer: {
        width: "95%",
      },
    },
    "@media (max-width: 960px)": {
      sectionContentContainer: {
        width: "85%",
      },
    },
    "@media (max-width: 768px)": {
      searchBarSection: {
        padding: "45px 0px",
      },
    },
    "@media (max-width: 468px)": {
      sectionContentContainer: {
        width: "95%",
      },
    },
  })
);

const SearchBar = ({ canCreate, setNoReport, userData, setCreateReportOpen }) => {
  const classes = useStyles();

  const [personalInfo, setPersonalInfo] = useState(false);

  const sendCollectedInfo = (info) => {
    setPersonalInfo({
      ...info,
    });
  };

  return (
    <Element name="searchbar" className={classes.searchBarSection}>
      <Grid container className={classes.sectionContentContainer}>
        <Grid item xs={12}>
          <SearchBarInputs
            canCreate={canCreate}
            setNoReport={setNoReport}
            sendCollectedInfo={(info) => sendCollectedInfo(info)}
            setPersonalInfo={(info) => setPersonalInfo(info)}
            userData={userData}
            setCreateReportOpen={setCreateReportOpen}
          />
        </Grid>
      </Grid>
    </Element>
  );
};

SearchBar.propTypes = {
  userData: PropTypes.object,
  setCreateReportOpen: PropTypes.func,
  canCreate: PropTypes.bool,
};

export default SearchBar;
