import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import ContactsComponent from "../Components/Contacts/ContactsComponent";
import CreateReport from "../Components/CreateReport/CreateReport";
import AddContactModal from "../Components/Contacts/ContactsHeader/AddContactModal";

const useStyles = makeStyles((theme) =>
  createStyles({
    contactsViewContainer: {
      position: "absolute",
      left: "0",
      right: "0",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  })
);

const ContactsView = ({ createReportOpen, setCreateReportOpen, match, googleScriptLoaded, createContactOpen, setCreateContactOpen }) => {
  const classes = useStyles();
  const user = match.params.user;
  return (
    <div className={classes.contactsViewContainer}>
      {createReportOpen.open && <CreateReport googleScriptLoaded={googleScriptLoaded} createReportOpen={createReportOpen} setCreateReportOpen={(status) => setCreateReportOpen(status)} />}
      {createContactOpen.open && <AddContactModal createContactOpen={createContactOpen} setCreateContactOpen={(status) => setCreateContactOpen(status)} />}
      <ContactsComponent user={user} createContactOpen={createContactOpen} setCreateContactOpen={(status) => setCreateContactOpen(status)} />
    </div>
  );
};

ContactsView.propTypes = {
  createReportOpen: PropTypes.object,
  setCreateReportOpen: PropTypes.func,
  match: PropTypes.object,
  googleScriptLoaded: PropTypes.bool,
  createContactOpen: PropTypes.object,
  setCreateContactOpen: PropTypes.func,
};

export default ContactsView;
