import React, { useState, useEffect, useContext } from "react";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { createStyles, makeStyles, withStyles, Button, MenuItem, TextField, Container, CircularProgress } from "@material-ui/core";
import { Add, CloseRounded } from "@material-ui/icons";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { useLazyQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AppStateContext } from "../../../Context/AppReducer";
import { SEARCH_REPORTS_AUTOCOMPLETE } from "../../../Services/Graph/Contact/Queries";
import { SEND_REPORT } from "../../../Services/Graph/MarketReportPage/MarketReportPageMutations";
import { useForm } from "react-hook-form";
import { CREATE_NOTE } from "../../../Services/Graph/Contact/Mutations";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundColor: "#fff",
      fontFamily: theme.typography.secondary,
    },
    contactHeaderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "30px",
    },
    closeModalButton: {
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      "&:hover": {},
    },
    circle: {
      color: "#fff",
      width: "28px !important",
      height: "28px !important",
      marginLeft: "10px",
    },
    loading: {
      marginLeft: "38px",
    },
    closeModalButtonIcon: {
      color: "#282828",
      fontSize: "30px",
    },
    saveButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "16px 80px",
      borderRadius: "10px",
      color: "#fff",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    saveButtonLabel: {
      fontFamily: theme.typography.secondary,
      color: "#fff",
      fontSize: "16px",
      fontWeight: "700",
      margin: 0,
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    modalClose: {
      position: "absolute",
      right: "30px",
    },
    modalActions: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "35px",
    },
    menuItem: {
      padding: "14px 20px",
    },
    menuItemIcon: {
      marginRight: "12px",
    },
    inputRoot: {
      background: "#F5F5F5",
      marginBottom: "20px",
      fontFamily: theme.typography.secondary,
      "&.MuiAutocomplete-input": {
        borderRadius: "10px",
      },
      "&.MuiAutocomplete-paper": {
        background: "#fff",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.areaPulseBlue,
      },
    },
  })
);

const Dialog = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialog);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "0 70px 45px",
  },
}))(MuiDialogContent);

const ContactInfoSelect = withStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "20px",
    fontFamily: theme.typography.secondary,
    backgroundColor: "#f5f5f5",
    "& label": {
      // color: "#BDBDBD",
      fontWeight: "500",
      fontFamily: "nunito",
    },
    "& label.Mui-focused": {
      color: theme.palette.areaPulseBlue,
      fontWeight: "500",
      fontFamily: "nunito",
      // transform: "translate(14px, 5px) scale(0.75)",
    },
    "& .MuiFilledInput-root": {
      color: "#000",
      backgroundColor: "#F5F5F5",
      fontWeight: "400",
      fontSize: "1rem",
      border: "#bcbcbc 1px solid",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
      "&.MuiFilledInput-underline:after": {
        display: "none",
      },
      "& .MuiSelect-filled": {
        padding: "17.5px 14px",
      },
    },
  },
}))(TextField);

const SubscribeToReportModal = ({ paidAccount, setRegrabReportsSent, setNoReports, reportsRemaining, contactDetails, contactId, setSubscriptionWasMade, setSubscriptionModalWasClosed }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { user } = useContext(AppStateContext);
  const [inputValue, setInputValue] = useState("");
  const [inputValueError, setInputValueError] = useState({
    status: false,
    message: "",
  });
  const [frequencySelect, setFrequencySelect] = useState("");
  const [frequencySelectError, setFrequencySelectError] = useState({
    status: false,
    message: "",
  });
  const [reportValue, setReportValue] = useState("");
  const [formState, setFormState] = useState({});
  const [addSubscription, { data: sendData, loading: sendLoading, error: sendError }] = useMutation(SEND_REPORT, {
    variables: {
      contactEmail: contactDetails?.contact?.email?.email,
      reportID: formState.reportRequestId,
      contactFirstName: contactDetails?.contact?.firstName,
      contactLastName: contactDetails?.contact?.lastName,
      frequency: formState.frequency,
    },
    client: authApolloClient,
  });

  const { handleSubmit } = useForm();

  const submit = () => {
    if (frequencySelect === "") {
      setFrequencySelectError({
        status: true,
        message: "Please enter frequency",
      });
    } else {
      setFrequencySelectError({ status: false, message: "" });
    }
    if (inputValue === "") {
      setInputValueError({
        status: true,
        message: "Please enter to search reports",
      });
    } else {
      setInputValueError({ status: false, message: "" });
    }
    if (frequencySelect !== "" && inputValue !== "") {
      setFrequencySelectError({
        status: false,
        message: "",
      });
      setInputValueError({
        status: false,
        message: "",
      });
      setFormState({
        personId: contactId ?? 0,
        reportRequestId: reportValue.reportID,
        receiveEmail: true,
        frequency: frequencySelect,
        title: reportValue.label,
      });
      addSubscription();
      if (sendData !== "" && !sendLoading && !sendError) {
        addNote();
      }
      setInputValue("");
      setFrequencySelect("");
      setReportValue("");
    }
  };

  useEffect(() => {
    if (sendData && !sendLoading) {
      setSubscriptionWasMade(true);
      setRegrabReportsSent(true);
      setOpen(false);
    }
  }, [sendData]);

  const [fetchSearchedReports, { data: searchedReportsData }] = useLazyQuery(SEARCH_REPORTS_AUTOCOMPLETE, {
    variables: {
      userId: user?.userId ?? 0,
      term: inputValue,
    },
    client: authApolloClient,
  });
  const handleClickOpen = (e, reportsRemaining) => {
    if (reportsRemaining > 0 || reportsRemaining === -1 || !paidAccount) {
      setOpen(true);
    } else {
      setNoReports(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSubscriptionModalWasClosed(true);
  };

  const searchResults = searchedReportsData?.searchReports?.rows ?? [];

  const [addNote, { data: noteData, loading: noteLoading, error: noteError }] = useMutation(CREATE_NOTE, {
    variables: {
      id: contactDetails?.contact?.id,
      note: `Subscribed To Report ${reportValue?.label}`,
    },
    client: authApolloClient,
  });

  useEffect(() => {
    if (inputValue !== "") {
      fetchSearchedReports();
    }
  }, [fetchSearchedReports, inputValue]);

  useEffect(() => {
    if (frequencySelect !== "" || inputValue !== "") {
      setFormState({
        personId: contactId,
        reportRequestId: reportValue?.reportID,
        receiveEmail: true,
        frequency: frequencySelect,
        title: reportValue?.label,
      });
    }
  }, [setFormState, frequencySelect, inputValue]);

  return (
    <div>
      <MenuItem className={classes.menuItem} onClick={(e) => handleClickOpen(e, reportsRemaining)}>
        <Add className={classes.menuItemIcon} />
        Subscribe to Report
      </MenuItem>
      <Dialog
        maxWidth="md"
        disableEnforceFocus
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: "800px",
          },
        }}
        onClose={handleClose}
        aria-labelledby="subscription-input"
        open={open}
      >
        <div className={classes.contactHeaderContainer}>
          <h1 className={classes.paperLabel}>Subscribe to Report</h1>
          <div className={classes.modalClose}>
            <Button className={classes.closeModalButton} onClick={handleClose}>
              <CloseRounded color="inherit" fontSize="inherit" className={classes.closeModalButtonIcon} />
            </Button>
          </div>
        </div>
        <Container>
          <form id="contact-subscription-form" onSubmit={handleSubmit(submit)}>
            <DialogContent>
              <Autocomplete
                id="search-reports"
                options={searchResults}
                getOptionLabel={(searchResult) => (searchResult?.label ? searchResult.label : "")}
                getOptionSelected={(searchResult) => (searchResult.reportID ? searchResult.reportID : "")}
                className={classes.inputRoot}
                fullWidth
                value={reportValue}
                onChange={(event, newValue) => {
                  setReportValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField error={inputValueError.status} helperText={inputValueError.message} {...params} label="Search Market Reports" variant="outlined" classes={classes.searchInput} />
                )}
              />
              <ContactInfoSelect
                select
                label="Frequency"
                variant="outlined"
                className={classes.frequencySelect}
                error={frequencySelectError.status}
                helperText={frequencySelectError.message}
                value={frequencySelect}
                onChange={(e) => setFrequencySelect(e.target.value)}
              >
                <MenuItem value={"weekly"}>Weekly</MenuItem>
                <MenuItem value={"biweekly"}>Bi-Weekly</MenuItem>
                <MenuItem value={"monthly"}>Monthly</MenuItem>
              </ContactInfoSelect>
            </DialogContent>
            <div className={classes.modalActions}>
              <Button disabled={sendLoading} className={classes.saveButton} type="submit" form="contact-subscription-form">
                <h1 className={`${classes.saveButtonLabel} ${sendLoading ? classes.loading : null}`}>Subscribe</h1>
                {sendLoading ? <CircularProgress className={classes.circle} /> : null}
              </Button>
            </div>
          </form>
        </Container>
      </Dialog>
    </div>
  );
};

SubscribeToReportModal.propTypes = {
  setAnchorEl: PropTypes.any,
  setRegrabReportsSent: PropTypes.func,
  setSubscriptionWasMade: PropTypes.func,
  setNoReports: PropTypes.func,
  reportsRemaining: PropTypes.number,
  contactId: PropTypes.string,
  setSubscriptionModalWasClosed: PropTypes.func,
  contactDetails: PropTypes.object,
  paidAccount: PropTypes.bool,
};

export default SubscribeToReportModal;
