import { gql } from "@apollo/client";

export const GET_PERSONAL_INFO = gql`
  query GetUser($id: Int!) {
    user(id: $id) {
      id
      logoURL
      photoURL
      firstName
      lastName
      title
      email
      phone
      licenseId
      reportSettings {
        title
        licenseId
        firstName
        lastName
        email {
          email
        }
        phone {
          number
        }
        address {
          city
          state
          zip
          street
        }
        themeColorSolid
        themeColorGradient
        categoryColorNew
        categoryColorActive
        categoryColorPending
        categoryColorSold
      }
      links {
        icon
        id
        isDefault
        isHidden
        title
        url
      }
    }
  }
`;

export const GET_REPORTS = gql`
  query GetReports($pageSize: Int, $page: Int, $sortField: MarketReportSortFields, $sortDirection: SortDirection) {
    reports(pageSize: $pageSize, page: $page, sortField: $sortField, sortDirection: $sortDirection) {
      id
    }
  }
`;

export const GET_MlS = gql`
  query GetMLS($state: String) {
    mlses(state: $state) {
      mlsID
      name
    }
  }
`;

export const GET_MLS_LINKS = gql`
  query GetUser($id: Int!) {
    user(id: $id) {
      id
      mlsLinks {
        id
        mlsId
        stateId
        mlsName
        mlsOfficeId
        mlsAgentId
        state {
          name
          abbr
          id
        }
      }
    }
  }
`;

export const GET_STATE = gql`
  query GetState($country: String!) {
    states(country: $country) {
      id
      name
      abbr
    }
  }
`;

export const GET_USER_STEP = gql`
  query getUserSteo($userId: Int!) {
    userOnboardingStep(userId: $userId) {
      step
      activity
    }
  }
`;

export const GET_ONE_REPORT = gql`
  query GetReports($page: Int, $pageSize: Int, $sortField: MarketReportSortFields, $sortDirection: SortDirection) {
    reports(pageSize: $pageSize, page: $page, sortField: $sortField, sortDirection: $sortDirection) {
      id
    }
  }
`;
