import { gql } from '@apollo/client';

export const GET_CONTACTS = gql`
  query GetContacts($parentPersonId: Int!, $searchTerm: String, $first: Int, $offset: Int, $sortField: String, $sortDirection: String) {
    contacts(parentPersonId: $parentPersonId, searchTerm: $searchTerm, first: $first, offset: $offset, sortField: $sortField, sortDirection: $sortDirection) {
      contacts {
        id
        firstName
        lastName
        email {
          email
        }
        reportEmailStats {
          lastReportSent
          opened
          sent
        }
      }
    }
  }
`;

export const SEARCH_CONTACTS = gql`
  query SearchContacts($parentPersonId: Int!, $term: String!) {
    searchContacts(parentPersonId: $parentPersonId, term: $term) {
      rows {
        id
        label
      }
    }
  }
`;