import React from "react";
import { makeStyles, createStyles, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    loadingContainer: {
      height: "calc(100% - 85px)",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "30px",
    },
    loadingContentContainer: {
      maxWidth: "767px",
      padding: "30px",
      background: "#fff",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingContentText: {
      margin: "0px",
      fontFamily: theme.typography.secondary,
      fontSize: "20px",
      fontWeight: "600",
      color: theme.palette.areaPulseBlue,
    },
    progressInner: {
      position: "absolute",
      color: theme.palette.areaPulseBlue,
      animationDuration: "1500ms",
    },
    circle: {
      strokeLinecap: "round",
    },
  })
);

const CreateReportLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingContainer}>
      <div className={classes.loadingContentContainer}>
        <CircularProgress
          size={130}
          thickness={2}
          color="inherit"
          className={classes.progressInner}
          classes={{
            circle: classes.circle,
          }}
        />
        <h1 className={classes.loadingContentText}>Loading</h1>
      </div>
    </div>
  );
};

export default CreateReportLoading;
