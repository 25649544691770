import React, { useState } from "react";
import { Clear } from "@material-ui/icons";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Backdrop, Grid, Button } from "@material-ui/core";
import { Send, Share, Link } from "@material-ui/icons";
import ReportPreview from "../../CreateReport/ReportPreview";
import ShareModals from "../../CreateReport/ShareModals";

const useStyles = makeStyles((theme) =>
  createStyles({
    createReportBackdrop: {
      height: "100%",
      width: "100%",
      zIndex: "8",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "35px",
    },
    createReportSharePreviewContainer: {
      position: "relative",
      height: "100%",
      width: "100%",
      background: "#FAFAFA",
      padding: "25px 25px 25px 100px",
    },
    createReportContentContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
      maxWidth: "1600px",
      maxHeight: "930px",
      zIndex: "10",
      background: "#fff",
      borderRadius: "20px",
      overflow: "hidden",
    },
    headerBar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: theme.palette.areaPulseBlue,
      padding: "25px 35px",
    },
    headerBarTitle: {
      color: "#fff",
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      margin: "0px",
    },
    headerBarIcon: {
      color: "#fff",
      fontSize: "32px",
      cursor: "pointer",
    },
    leftDiv: {
      backgroundColor: "#F1F1F1",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    topGrid: {
      height: "calc(100% - 85px)",
      display: "flex",
      flexDirection: "row",
    },
    createReportContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "12px 0px 12px 0px",
      paddingLeft: "53px",
      paddingRight: "53px",
    },
    buttonHolder: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "100px",
    },
    createReportButtonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "11px 20px",
      background: "#FAFAFA",
      borderRadius: "10px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      cursor: "pointer",
      width: "216px",
    },
    createReportButtonLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "16px",
      color: "#333",
      margin: "0px 16px 0px 0px",
    },
    createReportButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    iframe: {
      height: "100%",
    },
    rightDiv: {
      width: "100%",
    },
    createReportButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    "@media (max-width: 1400px)": {
      leftDiv: {
        width: "250px",
      },
      createReportContentContainer: {
        overflow: "scroll",
      },
    },
    "@media (max-width: 960px)": {
      topGrid: {
        height: "815px",
        flexDirection: "column",
      },
      buttonHolder: {
        flexDirection: "row",
        marginBottom: "0px",
      },
      createReportButtonContainer: {
        width: "150px",
      },
      createReportContainer: {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
      leftDiv: {
        width: "unset",
        height: "90px",
      },
      rightDiv: {
        height: "100%",
      },
    },
  })
);

const RecentReportsCardButtonsPopUp = ({ userData, userLoading, userId, setUpdateStats, updateStats, user, reportId, sendReportOpen, setSendReportOpen, whichReportURL, setConfirmUp }) => {
  const classes = useStyles();
  const [whichMarketReportCardOption, setWhichMarketReportCardOption] = useState("none");
  const [shareOpen, setShareOpen] = useState(false);

  const backdropAnimations = {
    appear: 500,
    enter: 500,
    exit: 500,
  };

  const closeMarketReport = () => {
    setSendReportOpen(false);
  };
  const handleShareType = (e, type) => {
    e.stopPropagation();
    setWhichMarketReportCardOption(type);
    setShareOpen(true);
  };
  return (
    <Backdrop open={sendReportOpen} transitionDuration={backdropAnimations} className={classes.createReportBackdrop}>
      <div className={classes.createReportContentContainer}>
        <div className={classes.headerBar}>
          <h1 className={classes.headerBarTitle}>Share Report</h1>
          <Clear
            fontSize="inherit"
            color="inherit"
            onClick={() => {
              closeMarketReport();
            }}
            className={classes.headerBarIcon}
          />
        </div>
        <div className={classes.topGrid}>
          <div className={classes.leftDiv}>
            <Grid className={classes.buttonHolder}>
              <div className={classes.createReportContainer}>
                <div
                  onClick={(e) => {
                    handleShareType(e, "contact");
                  }}
                  className={classes.createReportButtonContainer}
                >
                  <h1 className={classes.createReportButtonLabel}>Send To Contact</h1>
                  <Button className={classes.createReportButton}>
                    <Send color="inherit" fontSize="inherit" className={classes.createReportButtonIcon} />
                  </Button>
                </div>
              </div>
              <div className={classes.createReportContainer}>
                <div
                  onClick={(e) => {
                    handleShareType(e, "social");
                  }}
                  className={classes.createReportButtonContainer}
                >
                  <h1 className={classes.createReportButtonLabel}>Share Socially</h1>
                  <Button className={classes.createReportButton}>
                    <Share color="inherit" fontSize="inherit" className={classes.createReportButtonIcon} />
                  </Button>
                </div>
              </div>
              <div className={classes.createReportContainer}>
                <div
                  onClick={(e) => {
                    handleShareType(e, "link");
                  }}
                  className={classes.createReportButtonContainer}
                >
                  <h1 className={classes.createReportButtonLabel}>Copy a Link</h1>
                  <Button className={classes.createReportButton}>
                    <Link color="inherit" fontSize="inherit" className={classes.createReportButtonIcon} />
                  </Button>
                </div>
              </div>
            </Grid>
          </div>
          <div className={classes.rightDiv}>
            <Grid className={classes.iframe} container>
              <div className={classes.createReportSharePreviewContainer}>
                <ReportPreview userName={user} reportId={reportId} />
                <ShareModals
                  userData={userData}
                  userLoading={userLoading}
                  userId={userId}
                  setUpdateStats={setUpdateStats}
                  updateStats={updateStats}
                  setConfirmUp={setConfirmUp}
                  setSendReportOpen={setSendReportOpen}
                  user={user}
                  reportLink={whichReportURL}
                  reportId={reportId}
                  shareType={whichMarketReportCardOption}
                  setShareType={setWhichMarketReportCardOption}
                  setShareOpen={setShareOpen}
                  shareOpen={shareOpen}
                />
              </div>
            </Grid>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

RecentReportsCardButtonsPopUp.propTypes = {
  setUpdateStats: PropTypes.func,
  userData: PropTypes.object,
  userLoading: PropTypes.bool,
  updateStats: PropTypes.bool,
  setConfirmUp: PropTypes.func,
  sendReportOpen: PropTypes.bool,
  setSendReportOpen: PropTypes.func,
  whichReportURL: PropTypes.string,
  reportId: PropTypes.number,
  userId: PropTypes.number,
  user: PropTypes.string,
};

export default RecentReportsCardButtonsPopUp;
