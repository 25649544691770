import React from "react";
import HomeStylesheet from "../HomeStylesheet";
import Parallax from "react-rellax";
import { Element } from "react-scroll";
import { Grid, Hidden, useMediaQuery } from "@material-ui/core";

const SlidingReports = () => {
  const classes = HomeStylesheet();
  const isMobile = useMediaQuery("(max-width: 860px)");

  return (
    <Element name="included" className={classes.slideSection}>
      <Grid container className={classes.includeSectionContentContainer}>
        <Grid item xs={12} className={classes.includePhotoContainer2}>
          {!isMobile ? (
            <div>
              <div className={classes.makeParent}>
                <div className={classes.includeMake}>
                  Make it <strong>Yours</strong>
                </div>
                <div className={classes.includeMakeSub}>
                  Fully customize your market report to
                  <br /> match your brand.
                </div>
              </div>
              <img className={` ${classes.cutomizedTopWave} `} src="/assets/Intersect.png" alt="areapulse content 3" />
              <div className={classes.includeImageParent}>
                <Parallax speed={-6} percentage={0}>
                  <img className={` ${classes.cutomizedInclude1} ${classes.image}`} src="/assets/new-areapulse-bluereport.png" alt="areapulse content 3" />
                </Parallax>
                <Parallax speed={6}>
                  <img className={` ${classes.customizedInclude2} ${classes.image}`} src="/assets/new-areapulse-blackreport.png" alt="areapulse content 4" />
                </Parallax>
                <img className={` ${classes.cutomizedBottomWave} `} src="/assets/BottomWave.png" alt="areapulse content 3" />
              </div>
            </div>
          ) : (
            <div>
              <img className={` ${classes.cutomizedTopWave} `} src="/assets/intersect.png" alt="areapulse content 3" />
              <Parallax speed={6} percentage={0}>
                <div className={classes.mobileSlide}>
                  <img className={` ${classes.cutomizedInclude1} ${classes.image}`} src="/assets/new-areapulse-bluereport.png" alt="areapulse content 3" />
                  <div className={classes.makeParent}>
                    <div className={classes.includeMake}>
                      Make it <strong>Yours</strong>
                    </div>
                    <div className={classes.includeMakeSub}>
                      Fully customize your market report to
                      <br /> match your brand.
                    </div>
                  </div>
                  <img className={` ${classes.customizedInclude2} ${classes.image}`} src="/assets/new-areapulse-blackreport.png" alt="areapulse content 4" />
                </div>
              </Parallax>
              <img className={` ${classes.cutomizedBottomWave} `} src="/assets/BottomWave.png" alt="areapulse content 3" />
            </div>
          )}
        </Grid>
      </Grid>
    </Element>
  );
};

SlidingReports.propTypes = {};

export default SlidingReports;
