import { gql } from "@apollo/client";

export const DELETE_REQUEST = gql`
  query deleteUser($requestID: String!) {
    deleteRequest(requestID: $requestID) {
      provider
      status
      message
    }
  }
`;
