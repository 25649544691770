import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, withStyles, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Fade } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useLazyQuery } from "@apollo/client";
import { ErrorOutlineOutlined, RadioButtonUncheckedOutlined, CheckRounded } from "@material-ui/icons";
import Moment from "react-moment";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { GET_CONTACT } from "../../../Services/Graph/Contact/Queries";
import ContactActivityDetails from "./ContactActivityDetails";

const useStyles = makeStyles((theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    accordionContainer: {
      height: "100%",
    },
    singleActivity: {
      borderBottom: "1px #DADADA solid",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    activityContainer: {
      padding: "0px",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
    },
    marketReportInfo: {
      display: "flex",
      alignItems: "center",
    },
    activityText: {
      fontFamily: theme.typography.secondary,
      margin: "0",
      padding: "0",
      fontSize: "18px",
      fontWeight: "600",
      color: "#000000",
      width: "180px",
    },
    activityDateText: {
      fontFamily: theme.typography.secondary,
      margin: "0",
      padding: "0",
      fontSize: "18px",
      fontWeight: "600",
      color: "#8D8989",
    },
    iconContainer: {
      marginRight: "20px",
    },
    openedIcon: {
      color: "#fff",
      backgroundColor: "#6FCF97",
      borderRadius: "50%",
      fontSize: "20px",
    },
    unopenedIcon: {
      color: "#D8D8D8",
    },
    dateOpenedContainer: {
      display: "flex",
      alignItems: "center",
    },
    openedText: {
      color: "#6FCF97",
      fontFamily: theme.typography.secondary,
      margin: "0 0 0 4px",
      padding: "0",
      fontSize: "14px",
      fontWeight: "900",
      textTransform: "uppercase",
    },
    unopenedText: {
      color: "#D8D8D8",
      fontFamily: theme.typography.secondary,
      margin: "0 0 0 4px",
      padding: "0",
      fontSize: "14px",
      fontWeight: "900",
      textTransform: "uppercase",
    },
    openRateText: {
      color: "#8D8989",
      fontFamily: theme.typography.secondary,
      margin: "0",
      padding: "0",
      fontSize: "14px",
      fontWeight: "600",
      textTransform: "capitalize",
      textAlign: "right",
    },
    noInfoLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "22px",
      fontWeight: "700",
      color: "#000",
      textTransform: "capitalize",
    },
    infoContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    infoContainerData: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
    },
    scrollingContainer: {
      maxHeight: "270px",
      overflow: "scroll",
      width: "100%",
    },
    "@media (min-width: 1500px)": {
      activityText: {
        width: "inherit",
      },
    },
  })
);

const CustomAccordion = withStyles((theme) => ({
  root: {
    width: "100%",
    border: "0px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    background: "transparent",
    "&$expanded": {
      margin: "0px 0 !important",
    },
  },
  expanded: {},
}))(Accordion);

const CustomAccordionSummary = withStyles((theme) => ({
  root: {
    padding: "0px",
    // borderBottom: "1px solid rgba(0, 0, 0, .125)",
    "&$expanded": {
      margin: "10px 0 !important",
    },
  },
  expanded: {
    margin: "0px 0 !important",
  },
}))(AccordionSummary);

const CustomAccordionDetails = withStyles((theme) => ({
  root: {
    border: "0px solid rgba(0, 0, 0, .125)",
    padding: "0px",
  },
}))(AccordionDetails);

const ContactActivityAccordion = ({ contactId, contactReportWasMade, reportSent }) => {
  const classes = useStyles();
  const [activePanel, setActivPanel] = useState("none");

  const [fetchRecentSubcriptions, { data: recentSubscriptionActivity, loading: recentSubscriptionLoading, error: recentSubscriptionError }] = useLazyQuery(GET_CONTACT, {
    variables: {
      id: contactId ?? 0,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  const rows = recentSubscriptionActivity?.contact?.reportSubscriptions ?? [];

  useEffect(() => {
    fetchRecentSubcriptions();
  }, [fetchRecentSubcriptions]);

  useEffect(() => {
    if (contactReportWasMade || reportSent) {
      fetchRecentSubcriptions();
    }
  }, [contactReportWasMade, reportSent]);

  const handleChange = (panel) => (event, newExpanded) => {
    setActivPanel(newExpanded ? panel : false);
  };

  const checkForFade = () => {
    if (recentSubscriptionActivity) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className={`${rows.length === 0 && recentSubscriptionLoading === true ? classes.infoContainer : ""}${rows.length !== 0 && recentSubscriptionLoading === false ? classes.infoContainerData : ""}${
        rows.length === 0 && recentSubscriptionActivity !== undefined ? classes.infoContainer : ""
      }`}
    >
      {recentSubscriptionLoading && (
        <CircularProgress
          size={60}
          thickness={3}
          color="inherit"
          className={classes.progress}
          classes={{
            circle: classes.circle,
          }}
        />
      )}
      {recentSubscriptionError && <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.error} />}
      {rows.length === 0 && recentSubscriptionActivity !== undefined ? (
        <div>
          <Fade in={true} timeout={500}>
            <p className={classes.noInfoLabel}>no activity</p>
          </Fade>
        </div>
      ) : null}
      {rows.length !== 0 && recentSubscriptionActivity !== undefined ? (
        <Fade in={checkForFade()} timeout={500}>
          <div className={classes.scrollingContainer}>
            {rows.map((row, index) => {
              return (
                <div key={row.id} className={classes.singleActivity}>
                  <CustomAccordion square expanded={activePanel === row.id} onChange={handleChange(row.id)}>
                    <CustomAccordionSummary expandIcon={<ExpandMore />}>
                      <div className={classes.activityContainer}>
                        <div className={classes.marketReportInfo}>
                          {row.opened > 0 ? (
                            <div className={classes.iconContainer}>
                              <CheckRounded className={classes.openedIcon}></CheckRounded>
                            </div>
                          ) : (
                            <div className={classes.iconContainer}>
                              <RadioButtonUncheckedOutlined className={classes.unopenedIcon}></RadioButtonUncheckedOutlined>
                            </div>
                          )}
                          <div>
                            <div className={classes.dateOpenedContainer}>
                              <div>
                                <p className={classes.activityDateText}>{row.lastSentAt === "0001-01-01T00:00:00Z" ? `NOT SENT` : <Moment format="MM/DD/YYYY">{row.lastSentAt}</Moment>}</p>
                              </div>
                              <div>{row.opened > 0 ? <p className={classes.openedText}> opened</p> : <p className={classes.unopenedText}> unopened</p>}</div>
                            </div>
                            <div>
                              <p className={classes.activityText}>{row.title}</p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <p className={classes.openRateText}>open rate {row.sent === 0 ? 0 : Math.trunc((row.opened / row.sent) * 100)}%</p>
                        </div>
                      </div>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                      <ContactActivityDetails subscriptionId={row.id}></ContactActivityDetails>
                    </CustomAccordionDetails>
                  </CustomAccordion>
                </div>
              );
            })}
          </div>
        </Fade>
      ) : null}
    </div>
  );
};

ContactActivityAccordion.propTypes = {
  contactId: PropTypes.string,
  contactReportWasMade: PropTypes.bool,
  reportSent: PropTypes.bool,
};

export default ContactActivityAccordion;
