import React from "react";
import { Grid, Fade } from "@material-ui/core";
import PropTypes from "prop-types";
import UserSettingsBillingBasic from "./UserSettingsBillingBasic";
import UserSettingsBillingBilling from "./UserSettingsBillingBilling";
import UserSettingsBillingHistory from "./UserSettingsBillingHistory";

const UserSettingsBillingTab = ({ selectedTab }) => {
  return (
    <Fade in={selectedTab === 1} timeout={500}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <UserSettingsBillingBasic />
        </Grid>
        {/* <Grid item sm={12} md={6}>
        <UserSettingsBillingSubscriptions />
      </Grid> */}
        <Grid item sm={12} md={6}>
          <UserSettingsBillingBilling />
        </Grid>
        <Grid item sm={12} md={12}>
          <UserSettingsBillingHistory />
        </Grid>
      </Grid>
    </Fade>
  );
};

UserSettingsBillingTab.propTypes = {
  selectedTab: PropTypes.number,
};

export default UserSettingsBillingTab;
