import { useReducer } from "react";
import axios from "axios";
import { getBasePath } from "../../Helpers/getEnvVar";

const signupHookInitialState = {
  isFetching: false,
  isSuccessful: false,
  errorMessage: "",
  result: {},
};

const signupHookReducer = (state, action) => {
  switch (action.type) {
    case "fetching":
      return {
        ...state,
        isFetching: true,
        isSuccessful: false,
        errorMessage: "",
        result: {},
      };
    case "success":
      return {
        ...state,
        isFetching: false,
        isSuccessful: true,
        result: { ...action.payload },
      };
    case "error":
      return {
        ...state,
        isFetching: false,
        isSuccessful: false,
        errorMessage: action.payload,
      };
    default:
      throw new Error("Unsupported Action");
  }
};

const useSignup = () => {
  const [user, dispatch] = useReducer(signupHookReducer, signupHookInitialState);
  const basePath = getBasePath();

  const setUser = ({ email, firstName, lastName, password, rePassword, type, socialToken, socialUserID }) => {
    const hasSocialToken = socialToken !== undefined ? `&socialToken=${socialToken}` : "";
    const hasSocialUserId = socialUserID !== undefined ? `&socialUserID=${socialUserID}` : "";
    axios({
      url: `${basePath}/signup?&email=${email}&firstName=${firstName}&lastName=${lastName}&password=${password}&rePassword=${rePassword}&type=${type}${hasSocialToken}${hasSocialUserId}`,
      method: "POST",
    })
      .then((data) => {
        dispatch({
          type: "success",
          payload: { ...data?.data?.data },
        });
      })
      .catch((err) => {
        dispatch({ type: "error", payload: err?.response?.data?.error });
      });
  };

  const signupUser = (payload) => {
    dispatch({ type: "fetching" });
    setUser(payload);
  };

  return [user, signupUser];
};

export default useSignup;
