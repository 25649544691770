import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const SaveTitleInput = withStyles(theme => ({
  root: {
    marginBottom: "20px",
    '& label': {
      color: '#BDBDBD',
      fontWeight: "700",
      fontFamily: "nunito"
    },
    '& label.Mui-focused': {
      color: theme.palette.areaPulseBlue,
      fontWeight: "700",
      fontFamily: "nunito"
    },
    '& .MuiFilledInput-root': {
      color: "#000",
      background: "#FAFAFA",
      borderRadius: "10px",
      fontSize: "15px",
      width: "350px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
      '&.MuiFilledInput-underline:before': {
        display: "none"
      },
      '&.MuiFilledInput-underline:after': {
        display: "none"
      }
    },
  },
}))(TextField);

export default SaveTitleInput;