import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles, Grid, Collapse, IconButton } from "@material-ui/core";
import ContactHeader from "./ContactHeader/ContactHeader";
import ContactPersonalDetails from "./ContactPersonalDetails/ContactPersonalDetails";
import ContactSubscribed from "./ContactSubscribed/ContactSubscribed";
import ContactNotes from "./ContactNotes/ContactNotes";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import ContactActivity from "./ContactActivity/ContactActivity";
import MarketReportCardSentConfirm from "../MarketReports/MarketReportCardButtons/MarketReportCardSentConfirm";
import { GET_USER_AMOUNT } from "../../Services/Graph/CreateReport/Queries";
import { authApolloClient } from "../../Services/Graph/apolloConfig";
import { useLazyQuery } from "@apollo/client";
import ContactNoReports from "./ContactNotes/ContactNoReport";

const useStyles = makeStyles((theme) =>
  createStyles({
    contactsContainer: {
      width: "100%",
      height: "100%",
      padding: "60px 80px 60px 180px",
      position: "relative",
    },
    alert: {
      position: "fixed",
      top: "40px",
      width: "50%",
    },
    root: {
      marginTop: "20px",
    },
  })
);

const ContactComponent = ({ userId, setSubscribe, subscribe, contactId, user, setCreateReportOpen, contactReportWasMade, setContactReportWasMade }) => {
  const classes = useStyles();
  const [openUpdateContactSuccess, setOpenUpdateContactSuccess] = useState(false);
  const [subscriptionWasMade, setSubscriptionWasMade] = useState(false);
  const [subscriptionWasDeleted, setSubscriptionWasDeleted] = useState(false);
  const [openNoteSuccess, setOpenNoteSuccess] = useState(false);
  const [openNoteDelete, setOpenNoteDelete] = useState(false);
  const [contactNoteWasMade, setContactNoteWasMade] = useState(false);
  const [contactNoteWasDeleted, setContactNoteWasDeleted] = useState(false);
  const [noReports, setNoReports] = useState(false);
  const [reportSent, setReportSent] = useState(false);
  const [paidAccount, setPaidAccount] = useState(false);
  const [regrabReportsSent, setRegrabReportsSent] = useState(true);

  const [contactDetails, setContactDetails] = useState({});
  const [fetchStats, { data: statsData, loading: statsLoading }] = useLazyQuery(GET_USER_AMOUNT, {
    variables: {
      id: userId,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (statsData) {
      let array = [];
      array = statsData?.user?.subscriptions;
      array.forEach((element) => {
        if (element.itemId !== 29) {
          setPaidAccount(true);
        }
      });
    }
  }, [statsData]);

  useEffect(() => {
    if (openUpdateContactSuccess) {
      const closeUpdateContactAlert = setTimeout(() => {
        setOpenUpdateContactSuccess(false);
      }, 3000);
      return () => clearTimeout(closeUpdateContactAlert);
    }
  }, [openUpdateContactSuccess]);

  useEffect(() => {
    if (regrabReportsSent) {
      setRegrabReportsSent(false);
      fetchStats();
    }
  }, [regrabReportsSent]);

  useEffect(() => {
    let mounted = true;

    if (subscriptionWasMade) {
      setSubscriptionWasMade(true);
    }
    const closeAddContactAlert = setTimeout(() => {
      if (mounted) setSubscriptionWasMade(false);
    }, 3000);

    return () => {
      clearTimeout(closeAddContactAlert);
      mounted = false;
    };
  }, [subscriptionWasMade]);

  useEffect(() => {
    let mounted = true;

    if (subscriptionWasDeleted) {
      setSubscriptionWasDeleted(true);
    }
    const closeAddContactAlert = setTimeout(() => {
      if (mounted) setSubscriptionWasDeleted(false);
    }, 3000);

    return () => {
      clearTimeout(closeAddContactAlert);
      mounted = false;
    };
  }, [subscriptionWasDeleted]);

  useEffect(() => {
    let mounted = true;

    const closeAddContactAlert = setTimeout(() => {
      if (mounted) {
        setReportSent(false);
        setOpenNoteSuccess(false);
      }
    }, 3000);

    return () => {
      clearTimeout(closeAddContactAlert);
      mounted = false;
    };
  }, [reportSent]);

  useEffect(() => {
    let mounted = true;

    if (contactNoteWasMade) {
      setOpenNoteSuccess(true);
    }
    const closeAddContactNotesAlert = setTimeout(() => {
      if (mounted) setOpenNoteSuccess(false);
    }, 3000);

    return () => {
      clearTimeout(closeAddContactNotesAlert);
      mounted = false;
    };
  }, [contactNoteWasMade]);

  useEffect(() => {
    let mounted = true;

    if (contactNoteWasDeleted) {
      setOpenNoteDelete(true);
    }
    const closeDeleteContactNotesAlert = setTimeout(() => {
      if (mounted) setOpenNoteDelete(false);
    }, 3000);

    return () => {
      clearTimeout(closeDeleteContactNotesAlert);
      mounted = false;
    };
  }, [contactNoteWasDeleted]);

  useEffect(() => {
    let mounted = true;

    const closeAddContactNotesAlert = setTimeout(() => {
      if (mounted) setContactReportWasMade(false);
    }, 3000);

    return () => {
      clearTimeout(closeAddContactNotesAlert);
      mounted = false;
    };
  }, [contactReportWasMade]);

  return (
    <div className={classes.contactsContainer}>
      <ContactHeader
        paidAccount={paidAccount}
        setRegrabReportsSent={setRegrabReportsSent}
        setNoReports={setNoReports}
        reportsRemaining={statsData?.user?.totalSubscriptionUsage?.reportsRemaining}
        contactDetails={contactDetails}
        contactId={contactId}
        user={user}
        setCreateReportOpen={(status) => setCreateReportOpen(status)}
        subscriptionWasMade={subscriptionWasMade}
        setSubscriptionWasMade={setSubscriptionWasMade}
      />
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <ContactPersonalDetails
              setContactDetails={setContactDetails}
              contactId={contactId}
              openUpdateContactSuccess={openUpdateContactSuccess}
              setOpenUpdateContactSuccess={setOpenUpdateContactSuccess}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <ContactNotes
              setSubscribe={setSubscribe}
              subscribe={subscribe}
              contactId={contactId}
              openNoteSuccess={openNoteSuccess}
              setOpenNoteSuccess={setOpenNoteSuccess}
              openNoteDelete={openNoteDelete}
              setOpenNoteDelete={setOpenNoteDelete}
              contactNoteWasMade={contactNoteWasMade}
              setContactNoteWasMade={setContactNoteWasMade}
              contactNoteWasDeleted={contactNoteWasDeleted}
              setContactNoteWasDeleted={setContactNoteWasDeleted}
              reportSent={reportSent}
              subscriptionWasMade={subscriptionWasMade}
              contactReportWasMade={contactReportWasMade}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <ContactActivity contactId={contactId} contactReportWasMade={contactReportWasMade} reportSent={reportSent} />
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <ContactSubscribed
              paidAccount={paidAccount}
              setRegrabReportsSent={setRegrabReportsSent}
              setNoReports={setNoReports}
              reportsRemaining={statsData?.user?.totalSubscriptionUsage?.reportsRemaining}
              reportSent={reportSent}
              setReportSent={setReportSent}
              contactDetails={contactDetails}
              setSubscribe={setSubscribe}
              subscribe={subscribe}
              contactId={contactId}
              subscriptionWasMade={subscriptionWasMade}
              setSubscriptionWasDeleted={setSubscriptionWasDeleted}
              setCreateReportOpen={(status) => setCreateReportOpen(status)}
              contactReportWasMade={contactReportWasMade}
            />
          </Grid>
          <div className={classes.alert}>
            <Collapse in={openUpdateContactSuccess} timeout={1000}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenUpdateContactSuccess(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Contact Info Was Updated!
              </Alert>
            </Collapse>
          </div>
          <div className={classes.alert}>
            <Collapse in={subscriptionWasMade}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSubscriptionWasMade(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Subscription Was Added and Report Has Been Sent!
              </Alert>
            </Collapse>
            <Collapse in={subscriptionWasDeleted}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSubscriptionWasDeleted(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Subscription Was Deleted!
              </Alert>
            </Collapse>
          </div>
          <div className={classes.alert}>
            <Collapse in={openNoteSuccess} timeout={1000}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenNoteSuccess(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Note Was Made!
              </Alert>
            </Collapse>
          </div>
          <div className={classes.alert}>
            <Collapse in={openNoteDelete} timeout={1000}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenNoteDelete(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Note Was Deleted!
              </Alert>
            </Collapse>
          </div>
          <div className={classes.alert}>
            <Collapse in={contactReportWasMade} timeout={1000}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setContactReportWasMade(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Market Report Was Created!
              </Alert>
            </Collapse>
          </div>
        </Grid>
      </div>
      <MarketReportCardSentConfirm setConfirmUp={setReportSent} confirmUp={reportSent} />
      <ContactNoReports noReports={noReports} setNoReports={setNoReports} />
    </div>
  );
};

ContactComponent.propTypes = {
  setSubscribe: PropTypes.func,
  subscribe: PropTypes.bool,
  contactId: PropTypes.string,
  user: PropTypes.string,
  setCreateReportOpen: PropTypes.func,
  userId: PropTypes.number,
  contactReportWasMade: PropTypes.bool,
  setContactReportWasMade: PropTypes.func,
};

export default ContactComponent;
