import { makeStyles, createStyles } from "@material-ui/core";

const GoogleLoginStylesheet = makeStyles((theme) =>
  createStyles({
    socialButtons: {
      minWidth: "440px",
      justifyContent: "flex-start",
      padding: "15px 25px 15px 25px",
      borderRadius: "5px",
      opacity: "1",
      transition: "0.2s",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    socialButtonsSmall: {
      justifyContent: "center",
      padding: "10px 10px",
      borderRadius: "2px",
      opacity: "1",
      transition: "0.2s",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    google: {
      color: "#000",
      background: "#fff",
      transition: "0.2s",
      boxShadow: "0px 2px 2px rgba(0,0,0,0.1)",
      "&:hover": {
        background: "#fff",
        transition: "0.2s",
      },
    },
    socialTextGoogle: {
      fontSize: "18px",
      fontFamily: "Nunito",
      fontWeight: "800",
      paddingLeft: "30px",
    },
    socialIcons: {
      color: "#fff",
      fontSize: "24px !important",
      width: "24px",
      height: "24px",
    },
    socialIconsSmall: {
      width: "40px",
      height: "40px",
    },
    progress: {
      height: "31px !important",
      width: "31px !important",
      marginLeft: "20px",
    },
  })
);

export default GoogleLoginStylesheet;
