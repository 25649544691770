import React, { useState } from "react";
import { Element } from "react-scroll";
import { makeStyles, createStyles, Grid } from "@material-ui/core";
import SearchBarInputs from "./SearchBarInputs";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    searchBarSection: {
      position: "relative",
      padding: "0px  0px 120px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#ffffff",
    },
    sectionContentContainer: {
      position: "relative",
      width: "80%",
      maxWidth: "900px",
      zIndex: "7",
    },
    "@media (max-width: 1280px)": {
      sectionContentContainer: {
        width: "95%",
      },
    },
    "@media (max-width: 960px)": {
      sectionContentContainer: {
        width: "85%",
      },
    },
    "@media (max-width: 768px)": {
      searchBarSection: {
        padding: "45px 0px",
      },
    },
    "@media (max-width: 468px)": {
      sectionContentContainer: {
        width: "95%",
      },
    },
  })
);

const SearchBar = ({ setNoReport, canCreate, userData, setCreateReportOpen }) => {
  const classes = useStyles();

  const [personalInfo, setPersonalInfo] = useState(false);

  const sendCollectedInfo = (info) => {
    setPersonalInfo({
      ...info,
    });
  };

  return (
    <Element name="searchbar" className={classes.searchBarSection}>
      <Grid container className={classes.sectionContentContainer}>
        <Grid item xs={12}>
          <SearchBarInputs setNoReport={setNoReport} canCreate={canCreate} userData={userData} setCreateReportOpen={setCreateReportOpen} />
        </Grid>
      </Grid>
    </Element>
  );
};

SearchBar.propTypes = {
  userData: PropTypes.object,
  setNoReport: PropTypes.func,
  setCreateReportOpen: PropTypes.func,
  canCreate: PropTypes.bool,
};

export default SearchBar;
