import { gql } from "@apollo/client";

export const GET_REPORTS = gql`
  query GetReports($page: Int, $pageSize: Int, $sortField: MarketReportSortFields, $sortDirection: SortDirection) {
    reports(pageSize: $pageSize, page: $page, sortField: $sortField, sortDirection: $sortDirection) {
      dateCreated
      dateRun
      title
      id
      subscriberCount
      thumbnailURL
      url
      socialShareURL
    }
  }
`;

export const GET_STATS = gql`
  query getEmailStats($startDate: DateTime, $endDate: DateTime) {
    userActivityStats(startDate: $startDate, endDate: $endDate) {
      opened
      sent
      leads
      totalLeads
      totalReports
    }
  }
`;
