import React, { useEffect, useState } from "react";
import { GET_CONTACT } from "../../../Services/Graph/Contact/Queries";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { useLazyQuery } from "@apollo/client";
import { createStyles, makeStyles, Paper, CircularProgress, Fade } from "@material-ui/core";
import ContactNotesActions from "./ContactNotesActions";
import { ErrorOutlineOutlined } from "@material-ui/icons";
import DeleteNoteModal from "./DeleteNoteModal";
import Moment from "react-moment";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: "20px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contactHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    noInfoLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "22px",
      fontWeight: "700",
      color: "#000",
      textTransform: "capitalize",
    },
    createdDate: {
      fontFamily: theme.typography.secondary,
      paddingTop: "30px",
      margin: "0",
      fontSize: "18px",
      fontWeight: "600",
      color: "#8D8989",
    },
    singleNote: {
      borderBottom: "1px #DADADA solid",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    noteContainer: {
      paddingTop: "30px",
    },
    noteText: {
      fontFamily: theme.typography.secondary,
      paddingBottom: "30px",
      margin: "0",
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    infoContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    infoContainerData: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
    },
    loadingContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    scrollingContainer: {
      maxHeight: "270px",
      overflow: "scroll",
      width: "100%",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    alert: {
      position: "fixed",
      top: "40px",
      width: "50%",
    },
  })
);

const ContactNotes = ({
  setSubscribe,
  subscribe,
  contactId,
  setOpenNoteSuccess,
  setOpenNoteDelete,
  contactNoteWasMade,
  setContactNoteWasMade,
  contactNoteWasDeleted,
  setContactNoteWasDeleted,
  reportSent,
  subscriptionWasMade,
  contactReportWasMade,
}) => {
  const classes = useStyles();
  const [contactNoteDeleteButton, setContactDeleteButton] = useState(false);

  const [fetchContactNotes, { data: contactData, loading: contactLoading, error: contactError }] = useLazyQuery(GET_CONTACT, {
    variables: {
      id: contactId ?? 0,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  const contactNotes = contactData?.contact?.notes ?? [];

  useEffect(() => {
    fetchContactNotes();
  }, [fetchContactNotes]);

  useEffect(() => {
    if (subscriptionWasMade || contactReportWasMade) {
      fetchContactNotes();
    }
  }, [subscriptionWasMade]);

  useEffect(() => {
    if (subscribe) {
      fetchContactNotes();
      setSubscribe(false);
    }
  }, [subscribe, fetchContactNotes, setSubscribe]);

  useEffect(() => {
    if (contactNoteWasMade && Object.keys(contactData ?? {}).length !== 0) {
      fetchContactNotes();
      setContactNoteWasMade(false);
      setOpenNoteSuccess(true);
    }
    if (reportSent && Object.keys(contactData ?? {}).length !== 0) {
      fetchContactNotes();
      setOpenNoteSuccess(true);
    }
  }, [contactNoteWasMade, contactData, fetchContactNotes, setContactNoteWasMade, setOpenNoteSuccess, reportSent]);

  useEffect(() => {
    if (contactNoteWasDeleted && contactData) {
      fetchContactNotes();
      setContactNoteWasDeleted(false);
      setOpenNoteDelete(true);
      setContactDeleteButton(false);
    }
  }, [contactNoteWasDeleted, contactData, fetchContactNotes, setContactNoteWasDeleted, setOpenNoteDelete, setContactDeleteButton]);

  const createMarkup = (html) => {
    return { __html: html };
  };

  const checkForFade = () => {
    if (contactData) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.contactHeaderContainer}>
        <h1 className={classes.paperLabel}>Notes</h1>
        <div>
          <ContactNotesActions
            contactNoteWasMade={contactNoteWasMade}
            setContactNoteWasMade={(status) => setContactNoteWasMade(status)}
            contactId={contactId}
            contactNoteDeleteButton={contactNoteDeleteButton}
            setContactDeleteButton={(status) => setContactDeleteButton(status)}
          />
        </div>
      </div>
      <div className={contactNotes.length === 0 && contactData !== undefined ? classes.infoContainer : classes.infoContainerData}>
        {contactLoading && (
          <div className={classes.loadingContainer}>
            <CircularProgress
              size={60}
              thickness={3}
              color="inherit"
              className={classes.progress}
              classes={{
                circle: classes.circle,
              }}
            />
          </div>
        )}
        {contactError && (
          <div>
            <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.error} />
          </div>
        )}
        {contactNotes.length === 0 && contactData !== undefined ? (
          <div>
            <Fade in={true} timeout={500}>
              <p className={classes.noInfoLabel}>no notes</p>
            </Fade>
          </div>
        ) : null}
        {contactNotes.length !== 0 ? (
          <Fade in={checkForFade()} timeout={500}>
            <div className={classes.scrollingContainer}>
              {contactNotes
                .slice(0)
                .reverse()
                .map((note, index) => {
                  return (
                    <div key={note.id} className={classes.singleNote}>
                      <div className={classes.noteContainer}>
                        <Moment className={classes.createdDate} format="MM/DD/YYYY">
                          {note.createdAt}
                        </Moment>
                        <div className={classes.noteText} dangerouslySetInnerHTML={createMarkup(note.note)} />
                      </div>
                      <div>
                        {contactNoteDeleteButton ? (
                          <DeleteNoteModal noteId={note.id} contactNoteWasDeleted={contactNoteWasDeleted} setContactNoteWasDeleted={(status) => setContactNoteWasDeleted(status)} />
                        ) : null}
                      </div>
                    </div>
                  );
                })}
            </div>
          </Fade>
        ) : null}
      </div>
    </Paper>
  );
};

ContactNotes.propTypes = {
  setSubscribe: PropTypes.func,
  subscribe: PropTypes.bool,
  contactId: PropTypes.string,
  setOpenNoteSuccess: PropTypes.func,
  setOpenNoteDelete: PropTypes.func,
  contactNoteWasMade: PropTypes.bool,
  setContactNoteWasMade: PropTypes.func,
  contactNoteWasDeleted: PropTypes.bool,
  setContactNoteWasDeleted: PropTypes.func,
  subscriptionWasMade: PropTypes.bool,
  contactReportWasMade: PropTypes.bool,
};

export default ContactNotes;
