import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, CircularProgress } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import { ErrorOutlineOutlined, CheckRounded } from "@material-ui/icons";
import Moment from "react-moment";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { GET_SUBSCRIPTION_ACTIVITY } from "../../../Services/Graph/Contact/Queries";

const useStyles = makeStyles((theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    alert: {
      position: "fixed",
      top: "40px",
      width: "50%",
    },
    summaryText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "20px",
      color: "#000",
      margin: "0px",
    },
    accordionContainer: {
      margin: "10px 20px",
      width: "100%",
    },
    singleActivity: {
      display: "flex",
      alignItems: "center",
    },
    activityContainer: {
      padding: "15px",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
    },
    marketReportInfo: {
      display: "flex",
      alignItems: "center",
    },
    openedIcon: {
      color: "#fff",
      backgroundColor: "#6FCF97",
    },
    unopenedIcon: {
      color: "#D8D8D8",
    },
    dateOpenedContainer: {
      display: "flex",
    },
    openedCheckMark: {
      color: "#6FCF97",
      fontSize: "22px",
      marginRight: "6px",
    },
    unopenedCheckMark: {
      color: "#D8D8D8",
      fontSize: "22px",
      marginRight: "6px",
    },
    openedText: {
      color: "#6FCF97",
      fontFamily: theme.typography.secondary,
      margin: "0",
      padding: "0",
      fontSize: "14px",
      fontWeight: "900",
      textTransform: "uppercase",
    },
    unopenedText: {
      color: "#D8D8D8",
      fontFamily: theme.typography.secondary,
      margin: "0",
      padding: "0",
      fontSize: "14px",
      fontWeight: "900",
      textTransform: "uppercase",
    },
    activityDateText: {
      fontFamily: theme.typography.secondary,
      margin: "0",
      padding: "0",
      fontSize: "14px",
      fontWeight: "600",
      color: "#8D8989",
      paddingRight: "6px",
    },
    activityText: {
      fontFamily: theme.typography.secondary,
      margin: "0",
      padding: "0",
      fontSize: "14px",
      fontWeight: "600",
      color: "#000000",
      paddingRight: "6px",
    },
    iconContainer: {
      marginRight: "20px",
      position: "relative",
    },
    dottedLine: {
      position: "absolute",
      borderRight: "1px dashed #c4c4c4",
      height: "14px",
      top: "20px",
      right: "18px",
      "& dottedLine:last-child": {
        display: "none",
      },
    },
  })
);

const ContactActivityDetails = ({ subscriptionId }) => {
  const classes = useStyles();

  const [fetchSubscriptionActivity, { data: activityData, loading: activityLoading, error: activityError }] = useLazyQuery(GET_SUBSCRIPTION_ACTIVITY, {
    variables: {
      subscriptionId: subscriptionId ?? 0,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  const activities = activityData?.subscriptionActivity ?? [];

  useEffect(() => {
    fetchSubscriptionActivity();
  }, [fetchSubscriptionActivity]);

  return (
    <div className={classes.accordionContainer}>
      {activityLoading && (
        <CircularProgress
          size={60}
          thickness={3}
          color="inherit"
          className={classes.progress}
          classes={{
            circle: classes.circle,
          }}
        />
      )}
      {activityError && <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.error} />}
      {activities
        .slice(0)
        .reverse()
        .map((activity, index) => {
          return (
            <div key={activity.id} className={classes.singleActivity}>
              {activity.openedAt === "0001-01-01T00:00:00Z" ? (
                <div className={classes.iconContainer}>
                  <CheckRounded className={classes.unopenedCheckMark}></CheckRounded>
                  {index === activities.length - 1 ? null : <div className={classes.dottedLine}></div>}
                </div>
              ) : (
                <div className={classes.iconContainer}>
                  <CheckRounded className={classes.openedCheckMark}></CheckRounded>
                  {index === activities.length - 1 ? null : <div className={classes.dottedLine}></div>}
                </div>
              )}
              <p className={classes.activityDateText}>
                <Moment format="MM/DD/YYYY">{activity.createdAt}</Moment>
              </p>
              <p className={classes.activityText}>Report Sent</p>
              {activity.openedAt === "0001-01-01T00:00:00Z" ? <p className={classes.unopenedText}> unopened</p> : <p className={classes.openedText}> opened</p>}
            </div>
          );
        })}
    </div>
  );
};

ContactActivityDetails.propTypes = {
  subscriptionId: PropTypes.number,
};

export default ContactActivityDetails;
