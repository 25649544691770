import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core";
import DashboardHeader from "./DashboardHeader/DashboardHeader";
import Stats from "./Stats/stats";
import RecentReports from "./DashboardRecentReport/DashboardRecentReports";
import { GET_USER_AMOUNT } from "../../Services/Graph/CreateReport/Queries";
import { authApolloClient } from "../../Services/Graph/apolloConfig";
import { useLazyQuery } from "@apollo/client";
import DashboardReportPopUp from "./DashboardPopUps/DashboardReportPopUp";
import { AppStateContext, AppDispatchContext } from "../../Context/AppReducer";

const useStyles = makeStyles((theme) =>
  createStyles({
    dashboardContainer: {
      width: "100%",
      height: "100%",
      padding: "60px 80px 60px 180px",
    },
    statsMargin: {
      margin: "60px 0px 55px 0px",
    },
  })
);

const DashboardComponent = ({ createReportOpen, user, setCreateReportOpen, setSavedReport, savedReport, userId }) => {
  const classes = useStyles();

  const [updateStats, setUpdateStats] = useState(false);
  const [reportPopup, setReportPopup] = useState(false);
  const [upgradeOpen, setUpgradeOpen] = useState(false);

  const globalState = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);

  const [fetchStats, { data: userData, loading: userLoading }] = useLazyQuery(GET_USER_AMOUNT, {
    variables: {
      id: userId,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  const remainingReports = userData?.user?.totalSubscriptionUsage?.reportsRemaining;
  const usedReports = userData?.user?.totalSubscriptionUsage?.reportsUsed;
  const whichPlan = () => {
    let howManyReports = 0;
    if (remainingReports + usedReports > 5) {
      howManyReports = 20;
    } else {
      howManyReports = 2;
    }
    return howManyReports;
  };
  useEffect(() => {
    if (!userData && userId) {
      fetchStats();
    }
  }, [userData]);

  useEffect(() => {
    if (updateStats) {
      fetchStats();
    }
  }, [updateStats]);

  useEffect(() => {
    if (remainingReports + usedReports !== 500 && remainingReports < whichPlan() && globalState?.loginInfoErrors && !createReportOpen.open && remainingReports !== -1) {
      globalDispatch({ type: "loginInfoErrors", payload: false });
      setReportPopup(true);
    }
  }, [userData]);

  return (
    <div className={classes.dashboardContainer}>
      <DashboardHeader setCreateReportOpen={(status) => setCreateReportOpen(status)} setSavedReport={(status) => setSavedReport(status)} />
      <div className={classes.statsMargin}>
        <Stats upgradeOpen={upgradeOpen} setUpgradeOpen={setUpgradeOpen} userLoading={userLoading} userData={userData} updateStats={updateStats} setUpdateStats={setUpdateStats} />
      </div>
      <RecentReports
        userData={userData}
        userLoading={userLoading}
        userId={userId}
        updateStats={updateStats}
        setUpdateStats={setUpdateStats}
        setCreateReportOpen={setCreateReportOpen}
        savedReport={savedReport}
        setSavedReport={setSavedReport}
        user={user}
      />
      <DashboardReportPopUp
        userLoading={userLoading}
        setUpgradeOpen={setUpgradeOpen}
        totalSubscriptionUsage={userData?.user?.totalSubscriptionUsage}
        setReportPopup={setReportPopup}
        reportPopup={reportPopup}
      />
    </div>
  );
};

DashboardComponent.propTypes = {
  user: PropTypes.string,
  userId: PropTypes.number,
  setCreateReportOpen: PropTypes.func,
  setSavedReport: PropTypes.func,
  savedReport: PropTypes.bool,
  createReportOpen: PropTypes.object,
};

export default DashboardComponent;
