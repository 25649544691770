import { useReducer } from "react";
import axios from "axios";
import { getBasePath } from "../../Helpers/getEnvVar";

const passwordResetHookInitialState = {
  isFetching: false,
  isSuccessful: false,
  errorMessage: "",
  result: {},
};

const passwordResetHookReducer = (state, action) => {
  switch (action.type) {
    case "fetching":
      return {
        ...state,
        isFetching: true,
        isSuccessful: false,
        errorMessage: "",
        result: {},
      };
    case "success":
      return {
        ...state,
        isFetching: false,
        isSuccessful: true,
        result: { ...action.payload },
      };
    case "error":
      return {
        ...state,
        isFetching: false,
        isSuccessful: false,
        errorMessage: action.payload,
      };
    default:
      throw new Error("Unsupported Action");
  }
};

const usePasswordReset = () => {
  const [email, dispatch] = useReducer(passwordResetHookReducer, passwordResetHookInitialState);
  const basePath = getBasePath();

  const validatePasswordEmail = (payload) => {
    axios({
      url: `${basePath}/forgot-password?email=${payload}`,
      method: "POST",
    })
      .then((data) => {
        dispatch({
          type: "success",
          payload: { ...data?.data?.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: "error",
          payload: err?.response?.data?.error,
        });
      });
  };

  const validateEmail = (payload) => {
    dispatch({
      type: "fetching",
    });
    validatePasswordEmail(payload);
  };

  return [email, validateEmail];
};

export default usePasswordReset;
