import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Fade, Collapse, CircularProgress } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import ShareWithContact from "./ShareWithContact";
import ShareSocially from "./ShareSocially";
import ShareWithLink from "./ShareWithLink";
import CannotShare from "./CannotShare";
import { GET_USER_AMOUNT } from "../../Services/Graph/CreateReport/Queries";
import { authApolloClient } from "../../Services/Graph/apolloConfig";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles((theme) =>
  createStyles({
    modalContainer: {
      top: "0px",
      left: "0px",
      position: "absolute",
      background: "rgba(0, 0, 0, 0.7)",
      height: "101%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      transition: "0.4s",
      padding: "50px",
    },
    modalContentContainer: {
      position: "relative",
      borderRadius: "20px",
      padding: "30px",
      background: "#FFFFFF",
      maxWidth: "1000px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    circle: {
      height: "100px !important",
      color: theme.palette.areaPulseBlue,
      width: "100px !important",
      margin: "100px 0px",
    },
    close: {
      position: "absolute",
      top: "30px",
      right: "30px",
      fontSize: "35px",
      color: "#333",
      cursor: "pointer",
      transition: "0.2s",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
  })
);

const ShareModals = ({ userData, userLoading, setUpdateStats, updateStats, shareOpen, setShareOpen, user, reportId, shareType, reportLink, setSendReportOpen, setConfirmUp, userId }) => {
  const classes = useStyles();
  const isShareContact = shareType === "contact";
  const isShareSocial = shareType === "social";
  const isShareLink = shareType === "link";
  const [canSendReport, setCanSendReport] = useState(false);
  const [freeAccount, setFreeAccount] = useState(true);
  const handleClose = (e) => {
    e.stopPropagation();
    setShareOpen(false);
  };
  const [fetchStats, { data: statsData, loading: statsLoading }] = useLazyQuery(GET_USER_AMOUNT, {
    variables: {
      id: userId,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!statsData && userId && !setUpdateStats) {
      fetchStats();
    }

    if (statsData) {
      if (statsData) {
        let array = [];
        array = statsData?.user?.subscriptions;
        array.forEach((element) => {
          if (element.itemId !== 29) {
            setFreeAccount(false);
          }
        });
      }
      if (statsData?.user?.totalSubscriptionUsage?.reportsRemaining > 0) {
        setCanSendReport(true);
      }
      if (statsData?.user?.totalSubscriptionUsage?.reportsRemaining === -1) {
        setCanSendReport(true);
      }
    }
  }, [statsData]);

  useEffect(() => {
    if (userData) {
      if (userData) {
        let array = [];
        array = userData?.user?.subscriptions;
        array.forEach((element) => {
          if (element.itemId !== 29) {
            setFreeAccount(false);
          }
        });
      }
      if (userData?.user?.totalSubscriptionUsage?.reportsRemaining > 0) {
        setCanSendReport(true);
      }
      if (userData?.user?.totalSubscriptionUsage?.reportsRemaining === -1) {
        setCanSendReport(true);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (updateStats && !setUpdateStats) {
      fetchStats();
    }
  }, [updateStats]);
  return (
    <Fade in={shareOpen} timeout={400}>
      <div className={`${classes.modalContainer}`} onClick={(e) => handleClose(e)}>
        <div className={classes.modalContentContainer} onClick={(e) => e.stopPropagation()}>
          <Collapse in={isShareContact} timeout={400}>
            {statsLoading || userLoading ? (
              <CircularProgress className={classes.circle} />
            ) : isShareContact ? (
              canSendReport || freeAccount ? (
                <ShareWithContact
                  updateStats={updateStats}
                  setUpdateStats={setUpdateStats}
                  setShareOpen={setShareOpen}
                  setConfirmUp={setConfirmUp}
                  setSendReportOpen={setSendReportOpen}
                  reportId={reportId}
                />
              ) : (
                <CannotShare setShareOpen={setShareOpen} />
              )
            ) : null}
          </Collapse>
          <Collapse in={isShareSocial} timeout={400}>
            {statsLoading || userLoading ? (
              <CircularProgress className={classes.circle} />
            ) : isShareSocial ? (
              canSendReport || freeAccount ? (
                <ShareSocially user={user} reportId={reportId} whichReportURL={reportLink} />
              ) : (
                <CannotShare setShareOpen={setShareOpen} />
              )
            ) : null}
          </Collapse>
          <Collapse in={isShareLink} timeout={400}>
            {isShareLink && <ShareWithLink reportId={reportId} reportLink={reportLink} />}
          </Collapse>
          <Clear fontSize="inherit" color="inherit" className={classes.close} onClick={(e) => handleClose(e)} />
        </div>
      </div>
    </Fade>
  );
};

ShareModals.propTypes = {
  setConfirmUp: PropTypes.func,
  setShareOpen: PropTypes.func,
  shareOpen: PropTypes.bool,
  userData: PropTypes.object,
  userLoading: PropTypes.bool,
  setSendReportOpen: PropTypes.func,
  user: PropTypes.string,
  shareType: PropTypes.string,
  reportLink: PropTypes.string,
  userId: PropTypes.number,
  reportId: PropTypes.number,
  setUpdateStats: PropTypes.func,
  updateStats: PropTypes.bool,
};

export default ShareModals;
