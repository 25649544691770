import React, { useState, useContext, useEffect } from "react";
import { makeStyles, createStyles, Avatar, IconButton } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import { GET_USER_DATA } from "../../../Services/Graph/UserSettings/Queries";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { useLazyQuery } from "@apollo/client";
import { AppStateContext, AppDispatchContext } from "../../../Context/AppReducer";
import UserSettingsImageUpload from "./UserSettingsImageUpload";

const useStyles = makeStyles((theme) =>
  createStyles({
    userSettingsHeaderContainer: {
      display: "flex",
      alignItems: "center",
    },
    userSettingsHeader: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "36px",
      color: "#4E4E4E",
      margin: "0px",
    },
    userImageContainer: {
      width: "180px",
      height: "180px",
      borderRadius: "50%",
      position: "absolute",
      bottom: "-90px",
      left: "260px",
    },
    editImageButtonContainer: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    editImageButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "50%",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    editImageButtonLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "21px",
      color: "#333",
      margin: "0px 16px 0px 0px",
    },
    editImageButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    large: {
      width: "180px",
      height: "180px",
    },
    "@media (max-width: 1280px)": {
      userSettingsHeaderContainer: {
        justifyContent: "center",
        flexDirection: "column",
      },
      userImageContainer: {
        width: "180px",
        height: "180px",
        borderRadius: "50%",
        position: "relative",
        bottom: "initial",
        left: "initial",
      },
    },
  })
);

const UserSettingsHeader = () => {
  const classes = useStyles();
  const globalState = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);
  const [imageUpload, setImageUpload] = useState(false);
  const [useNewImage, setUseNewImage] = useState("");

  const [getUser, { data: userInfoData, error: userInfoError }] = useLazyQuery(GET_USER_DATA, {
    variables: {
      id: globalState?.user?.userId ?? 0,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });
  const userInfo = userInfoData?.user;

  useEffect(() => {
    const errorMessage = userInfoError?.networkError?.result?.error ?? "";
    if (userInfoError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [userInfoError, globalDispatch]);

  const [profileImage, setProfileImage] = useState({
    preview: userInfo?.user?.photoURL,
    base64: "",
  });

  const firstInitial = userInfo?.user?.firstName?.split("")[0];

  const activateImageUpload = (e) => {
    e.stopPropagation();
    setImageUpload(true);
  };

  useEffect(() => {
    if (userInfoData === undefined) {
      getUser();
    }
    if (userInfo) {
      setProfileImage({
        ...profileImage,
        preview: userInfoData?.user?.photoURL,
        base64: "",
      });
    }
  }, [userInfo]);

  return (
    <div className={classes.userSettingsHeaderContainer}>
      <div className={classes.userImageContainer}>
        <Avatar alt={globalState?.user?.fullName ?? ""} src={useNewImage ? useNewImage : userInfo?.photoURL} className={classes.large} />
        <div className={classes.editImageButtonContainer}>
          <IconButton className={classes.editImageButton} onClick={(e) => activateImageUpload(e)}>
            <EditOutlined color="inherit" className={classes.editImageButtonIcon} />
          </IconButton>
        </div>
      </div>
      <h1 className={classes.userSettingsHeader}>{globalState?.user?.fullName ?? ""}</h1>
      <UserSettingsImageUpload
        setUseNewImage={setUseNewImage}
        userInfo={userInfo}
        firstInitial={firstInitial}
        imageUpload={imageUpload}
        setImageUpload={(status) => setImageUpload(status)}
        profileImage={profileImage}
        setProfileImage={(image) => setProfileImage(image)}
      />
    </div>
  );
};

UserSettingsHeader.propTypes = {};

export default UserSettingsHeader;
