import React from "react";
import { createStyles, makeStyles, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import ContactActivityAccordion from "./ContactActivityAccordion";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: "20px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contactHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
  })
);

const ContactActivity = ({ contactId, contactReportWasMade, reportSent }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div className={classes.contactHeaderContainer}>
        <h1 className={classes.paperLabel}>Activity</h1>
      </div>
      <ContactActivityAccordion contactId={contactId} contactReportWasMade={contactReportWasMade} reportSent={reportSent}></ContactActivityAccordion>
    </Paper>
  );
};

ContactActivity.propTypes = {
  contactId: PropTypes.string,
  contactReportWasMade: PropTypes.bool,
  reportSent: PropTypes.bool,
};

export default ContactActivity;
