import { gql } from "@apollo/client";

export const UPDATE_USER_BILLING_PLAN = gql`
  mutation UpdatePlanSubscripton($userId: Int!, $subscriptionId: Int!, $planId: Int!, $paymentMethodId: String!) {
    updatePlanSubscription(userId: $userId, subscriptionId: $subscriptionId, planId: $planId, paymentMethodId: $paymentMethodId) {
      id
      name
    }
  }
`;

export const CREATE_USER_BILLING_PLAN = gql`
  mutation createPlanSubscription($userId: Int!, $planId: Int!, $paymentMethodId: String!) {
    createPlanSubscription(userId: $userId, planId: $planId, paymentMethodId: $paymentMethodId) {
      id
      name
    }
  }
`;
