import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#fff",
        },
      },
    },
  },
  palette: {
    areaPulseBlue: "#0079B4",
    areaPulseGreen: "#0F9F4F",
    areaPulseError: "#d10000",
    areaPulseWarning: "#debd00",
    areaPulseRed: "#E23A00",
    areaPulseErrorLight: "#F00",
  },
  typography: {
    default: "Roboto",
    secondary: "Nunito",
  },
});

export default theme;
