import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import HomeStylesheet from "../HomeStylesheet";
import contactSubmit from "../../../Services/Rest/contactSubmit";
import ReCAPTCHA from "react-google-recaptcha";
import { Element } from "react-scroll";
import { Grid, Button, FormControl, CircularProgress, Fade, withStyles, TextField } from "@material-ui/core";
import { Done, Clear } from "@material-ui/icons";

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#CD1041",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "transparent",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#CD1041",
    },
    "& .MuiFilledInput-root": {
      background: "#FFFFFF",
      borderRadius: "12px",
      boxShadow: "5px 2px 6px 3px rgba(0,0,0,0.04)",
    },

    "& label": {
      color: "rgba(0, 0, 0, 0.4)",
      fontWeight: "400",
      fontFamily: "nunito",
      fontSize: "16px",
    },
  },
})(TextField);

const CustomTextFieldRight = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#CD1041",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "transparent",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#CD1041",
    },
    "& .MuiFilledInput-root": {
      background: "#FFFFFF",
      borderRadius: "12px",
    },
    "& .MuiFilledInput-root": {
      background: "#FFFFFF",
      borderRadius: "12px",
      boxShadow: "5px 2px 6px 3px rgba(0,0,0,0.04)",
    },
    "& label": {
      color: "rgba(0, 0, 0, 0.4)",
      fontWeight: "400",
      fontFamily: "nunito",
      fontSize: "16px",
      marginLeft: "12px",
      "@media (max-width: 960px)": {
        marginLeft: "0px",
      },
    },
  },
})(TextField);
const Contact = ({}) => {
  const classes = HomeStylesheet();
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [needMoreInfo, setNeedMoreInfo] = useState(false);
  const fullName = firstName + " " + lastName;
  const captchaRef = useRef(null);

  const handleSubmit = () => {
    setIsSubmitting(true);
    if (fullName !== "" && email !== "" && message !== "") {
      setNeedMoreInfo(false);
      const payload = {
        name: fullName,
        email,
        subject,
        msg: message,
      };
      contactSubmit(payload)
        .then((data) => {
          if (data) {
            setIsSubmitting(false);
            setHasSubmitted(true);
            setTimeout(() => {
              setHasSubmitted(false);
            }, 3000);
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          setHasFailed(true);
          setTimeout(() => {
            setHasFailed(false);
          }, 3000);
        });
    } else {
      setIsSubmitting(false);
      setNeedMoreInfo(true);
      setHasFailed(true);
      setTimeout(() => {
        setHasFailed(false);
      }, 3000);
    }
  };

  return (
    <Element name="contact" className={classes.contactSection}>
      <img src="/assets/new-areapulse-bluebg.png" className={classes.contactWave} />
      <Grid container className={classes.pricingSectionContentContainer}>
        <Grid item xs={12} className={classes.contactHeaderContainer}>
          <div className={`wow ${classes.fadeInUp}  ${classes.pricingHeaderContainer}`}>Have Any Questions?</div>
          <p className={`wow ${classes.fadeInUp} ${classes.pricingDescriptions}`}>Get in touch and see why we think AreaPulse can be a super useful tool for your day-today.</p>
          <FormControl className={classes.contactForm}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className={`wow ${classes.fadeInUp} ${classes.delay0} ${classes.contactInputContainer}`}>
                  <CustomTextField
                    id="full-name"
                    error={needMoreInfo && fullName === "" ? true : false}
                    helperText={needMoreInfo && fullName === "" ? "Please Enter Your First Name" : ""}
                    required
                    label="First Name"
                    variant="filled"
                    className={`${classes.contactInput} ${classes.contactLeft}`}
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={`wow ${classes.fadeInUp} ${classes.delay0} ${classes.contactInputContainer}`}>
                  <CustomTextFieldRight
                    id="full-name"
                    error={needMoreInfo && fullName === "" ? true : false}
                    helperText={needMoreInfo && fullName === "" ? "Please Enter Your Last Name" : ""}
                    required
                    label="Last Name"
                    variant="filled"
                    className={`${classes.contactInput} ${classes.contactRight}`}
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={`wow ${classes.fadeInUp} ${classes.delay1} ${classes.contactInputContainer}`}>
                  <CustomTextField
                    id="email"
                    error={needMoreInfo && email === "" ? true : false}
                    helperText={needMoreInfo && email === "" ? "Please Enter Your Email" : ""}
                    required
                    label="Email"
                    variant="filled"
                    className={`${classes.contactInput} ${classes.contactLeft}`}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={`wow ${classes.fadeInUp} ${classes.delay2} ${classes.contactInputContainer}`}>
                  <CustomTextFieldRight
                    id="subject"
                    label="Phone Number"
                    variant="filled"
                    className={`${classes.contactInput} ${classes.contactRight}`}
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <div className={`wow ${classes.fadeInUp} ${classes.delay3} ${classes.contactInputContainer}`}>
              <CustomTextField
                id="message"
                error={needMoreInfo && message === "" ? true : false}
                helperText={needMoreInfo && message === "" ? "Please Include A Message" : ""}
                required
                label="Your Message"
                variant="filled"
                rows={4}
                multiline
                className={classes.contactInput}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </div>
          </FormControl>
          <ReCAPTCHA ref={captchaRef} size="invisible" sitekey="6LfZxbAZAAAAALN69GWluvGyk0zZJktL5YnehViP" />
          <Button onClick={() => handleSubmit()} className={`wow ${classes.fadeInUp} ${classes.delay4} ${classes.contactButton}`}>
            {isSubmitting && !hasSubmitted && !hasFailed ? (
              <div className={classes.contactButtonIconContainer}>
                <Fade in={isSubmitting}>
                  <CircularProgress size={25} thickness={4} color="inherit" className={classes.contactIcon} />
                </Fade>
              </div>
            ) : (
              <div>
                {!isSubmitting && hasSubmitted && !hasFailed ? (
                  <div className={classes.contactButtonIconContainer}>
                    <Fade in={hasSubmitted}>
                      <Done className={classes.contactIcon} />
                    </Fade>
                  </div>
                ) : (
                  <div>
                    {!isSubmitting && !hasSubmitted && hasFailed ? (
                      <div className={classes.contactButtonIconContainer}>
                        <Fade in={hasFailed}>
                          <Clear className={classes.contactIcon} />
                        </Fade>
                      </div>
                    ) : (
                      <Fade in={!isSubmitting && !hasSubmitted && !hasFailed}>
                        <span>Send Message</span>
                      </Fade>
                    )}
                  </div>
                )}
              </div>
            )}
          </Button>
        </Grid>
      </Grid>
    </Element>
  );
};

Contact.propTypes = {
  CustomTextField: PropTypes.object,
};

export default Contact;
