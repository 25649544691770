import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, withStyles, Slider } from "@material-ui/core";
import CreateReportFilterInputSmall from "../Common/Inputs/CreateReportFilterInputSmall";

const useStyles = makeStyles((theme) =>
  createStyles({
    selectBathsContainer: {
      marginBottom: "15px",
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerText: {
      fontFamily: theme.typography.secondary,
      color: "#414141",
      fontWeight: "600",
      fontSize: "16px",
      margin: "0px",
    },
    rangesContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    hyphen: {
      margin: "0px 5px",
    },
    sliderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "15px",
    },
  })
);

const CustomSlider = withStyles((theme) => ({
  root: {
    margin: "0 30px",
  },
  thumb: {
    background: theme.palette.areaPulseBlue,
    height: "20px",
    width: "20px",
    marginTop: "-8px",
  },
  track: {
    borderRadius: "5px",
    background: "#EAEAEA",
    height: "4px",
  },
  rail: {
    borderRadius: "5px",
    background: "#EAEAEA",
    height: "4px",
  },
}))(Slider);

const SelectBaths = ({ baths, setBaths }) => {
  const classes = useStyles();
  const lowBaths = baths[0];
  const highBaths = baths[1];

  const lowBathsChange = (e) => {
    setBaths([parseInt(e.target.value.replace(/[^\d.-]/g, "") || 0), highBaths]);
  };

  const highBathsChange = (e) => {
    setBaths([lowBaths, parseInt(e.target.value.replace(/[^\d.-]/g, "") || 0)]);
  };

  return (
    <div className={classes.selectBathsContainer}>
      <div className={classes.headerContainer}>
        <h1 className={classes.headerText}>Bath</h1>
        <div className={classes.rangesContainer}>
          <CreateReportFilterInputSmall type="text" size="small" label="min" variant="filled" value={lowBaths} onChange={lowBathsChange} />
          <div className={classes.hyphen}>{"—"}</div>
          <CreateReportFilterInputSmall type="text" size="small" label="max" variant="filled" value={highBaths} onChange={highBathsChange} />
        </div>
      </div>
      <div className={classes.sliderContainer}>
        <CustomSlider value={baths} step={1} min={0} max={10} onChange={(e, value) => setBaths(value)} />
      </div>
    </div>
  );
};

SelectBaths.propTypes = {
  baths: PropTypes.array,
  setBaths: PropTypes.func,
};

export default SelectBaths;
