import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, TextField, Grid } from "@material-ui/core";
import PersonalInfoInput from "../../Common/Inputs/PersonalInfoInput";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) =>
  createStyles({
    headlineInput: {
      backgroundColor: "#FAFAFA",
      fontFamily: theme.typography.secondary,
      color: "#676767",
      fontSize: "18px",
      fontWeight: "600",
      borderRadius: "10px",
      border: "none",
      boxShadow: "inset 0px 0px 7px rgba(0, 0, 0, 0.08);",
    },
    paddingRight: {
      paddingRight: "10px",
    },
    designPreferencesContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
      width: "100%",
      position: "relative",
    },
    labelText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "bold",
      fontSize: "16px",
      color: "#000",
      margin: "15px 0px 15px 0px",
    },
    formContainer: {
      width: "100%",
    },
  })
);

const HeadlineInputs = ({ userData, headline, setHeadline, subheadline, setSubheadline, savingLandingPageSettings, setSavingLandingPageSettings }) => {
  const classes = useStyles();

  useEffect(() => {
    if (userData?.user) {
      if (userData?.user?.landingPageSettings) {
        setHeadline(userData?.user?.landingPageSettings?.headline);
      }
      if (userData?.user?.landingPageSettings) {
        setSubheadline(userData?.user?.landingPageSettings?.subheadline);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (savingLandingPageSettings) {
      setSavingLandingPageSettings(false);
    }
  }, [savingLandingPageSettings, userData]);

  return (
    <div className={classes.designPreferencesContainer}>
      <form id="landing-page-headline-form" className={classes.formContainer}>
        <h1 className={classes.labelText}>Headline</h1>
        <Grid container>
          <Grid item xs={12} className={classes.paddingRight}>
            <TextField
              variant="outlined"
              className={classes.headlineInput}
              id="landing-page-headline"
              placeholder="Headline"
              fullWidth
              required
              type="text"
              value={headline}
              onChange={(e) => {
                setHeadline(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <h1 className={classes.labelText}>Subheadline</h1>
        <Grid container>
          <Grid item xs={12} className={classes.paddingRight}>
            <TextField
              variant="outlined"
              className={classes.headlineInput}
              id="landing-page-subheadline"
              placeholder="Subheadline"
              fullWidth
              required
              type="text"
              value={subheadline}
              onChange={(e) => {
                setSubheadline(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

HeadlineInputs.propTypes = {
  userData: PropTypes.object,
  headline: PropTypes.string,
  setHeadline: PropTypes.func,
  subheadline: PropTypes.string,
  setSubheadline: PropTypes.func,
  savingLandingPageSettings: PropTypes.bool,
  setSavingLandingPageSettings: PropTypes.func,
};

export default HeadlineInputs;
