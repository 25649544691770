import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Grid, Radio } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    designPreferencesContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
      width: "100%",
      position: "relative",
    },
    labelText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "bold",
      fontSize: "16px",
      color: "#000",
      margin: "15px 0px 15px 0px",
    },
    templateOptionContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
    },
    templatesContainer: {
      width: "100%",
    },
    templateImg: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  })
);

const PageTemplate = ({ userData, templateOptionChosen, setTemplateOptionChosen, savingLandingPageSettings, setSavingLandingPageSettings }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    setTemplateOptionChosen(event.target.value);
    setSavingLandingPageSettings(true);
  };

  useEffect(() => {
    if (userData) {
      if (userData?.user?.landingPageSettings?.templateChosen !== "") {
        setTemplateOptionChosen(userData?.user?.landingPageSettings?.templateChosen.toString());
      }
    }
  }, [userData]);

  return (
    <div className={classes.designPreferencesContainer}>
      <h1 className={classes.labelText}>Choose Template:</h1>
      <div className={classes.templatesContainer}>
        <Grid container>
          <Grid item md={12} lg={4} className={classes.templateOptionContainer}>
            <div className={classes.templateImageContainer}>
              <img className={classes.templateImg} alt="Option 1 Template" src="/assets/landing_opt_1.png" />
            </div>
            <Radio color="primary" checked={templateOptionChosen === "1"} onChange={handleChange} value="1" name="radio-button-demo" inputProps={{ "aria-label": "1" }} />
          </Grid>
          <Grid item md={12} lg={4} className={classes.templateOptionContainer}>
            <div className={classes.templateImageContainer}>
              <img className={classes.templateImg} alt="Option 2 Template" src="/assets/landing_opt_2.png" />
            </div>
            <Radio color="primary" checked={templateOptionChosen === "2"} onChange={handleChange} value="2" name="radio-button-demo" inputProps={{ "aria-label": "2" }} />
          </Grid>
          <Grid item md={12} lg={4} className={classes.templateOptionContainer}>
            <div className={classes.templateImageContainer}>
              <img className={classes.templateImg} alt="Option 3 Template" src="/assets/landing_opt_3.png" />
            </div>
            <Radio color="primary" checked={templateOptionChosen === "3"} onChange={handleChange} value="3" name="radio-button-demo" inputProps={{ "aria-label": "3" }} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

PageTemplate.propTypes = {
  userData: PropTypes.object,
  templateOptionChosen: PropTypes.string,
  setTemplateOptionChosen: PropTypes.func,
};

export default PageTemplate;
