import { useReducer } from "react";
import axios from "axios";
import { getBasePath } from "../../Helpers/getEnvVar";

const validateEmailHookInitialState = {
  isFetching: false,
  isSuccessful: false,
  errorMessage: "",
  result: {},
};

const validateEmailHookReducer = (state, action) => {
  switch (action.type) {
    case "fetching":
      return {
        ...state,
        isFetching: true,
        isSuccessful: false,
        errorMessage: "",
        result: {},
      };
    case "success":
      return {
        ...state,
        isFetching: false,
        isSuccessful: true,
        result: { ...action.payload },
      };
    case "error":
      return {
        ...state,
        isFetching: false,
        isSuccessful: false,
        errorMessage: action.payload,
      };
    default:
      throw new Error("Unsupported Action");
  }
};

const useValidateEmail = () => {
  const [email, dispatch] = useReducer(validateEmailHookReducer, validateEmailHookInitialState);
  const basePath = getBasePath();

  const validateGivenEmail = (payload) => {
    axios({
      url: `${basePath}/valid-email?email=${payload}`,
      method: "POST",
    })
      .then((data) => {
        dispatch({
          type: "success",
          payload: { ...data?.data?.data },
        });
      })
      .catch((err) => {
        dispatch({ type: "error", payload: err?.response?.data?.error });
      });
  };

  const validateEmail = (payload) => {
    dispatch({ type: "fetching" });
    validateGivenEmail(payload);
  };

  return [email, validateEmail];
};

export default useValidateEmail;
