import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Button } from "@material-ui/core";
import { SendOutlined, ShareOutlined, LinkOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    shareOptionsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
    shareOptionsHeaderText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      color: "#000",
      margin: "30px 0px 30px 0px",
    },
    shareOptionsButtonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "9px 12px",
      background: "#F5F5F5",
      borderRadius: "10px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      cursor: "pointer",
      marginBottom: "25px",
      maxWidth: "220px",
      width: "100%",
    },
    shareOptionsButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      transition: "0.2s",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    shareOptionsButtonLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "16px",
      color: "#333",
      margin: "0px 16px 0px 0px",
    },
    shareOptionsButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    shareError: {
      color: "red",
      font: "nunito",
      fontWeight: "500",
      fontSize: "14px",
    },
    viewReportsButtons: {
      textDecoration: "none",
      marginTop: "30px",
      marginLeft: "75px",
    },
    "@media (max-width: 600px)": {
      viewReportsButtons: {
        marginLeft: "0px",
      },
    },
  })
);

const ShareOptions = ({ userName, reportId, landingPageView, setShareOpen, contactData, reportSaved, setShareType }) => {
  const classes = useStyles();

  const [shareError, setShareError] = useState(false);

  const handleShareType = (e, type, reportSaved) => {
    e.stopPropagation();
    if (reportSaved) {
      setShareType(type);
      setShareOpen(true);
    } else {
      setShareError(true);
    }
  };
  const baseURL = window.location.host;

  useEffect(() => {
    if (reportSaved) {
      setShareError(false);
    }
  }, [reportSaved]);
  return (
    <div className={classes.shareOptionsContainer}>
      {!contactData && !landingPageView ? <h1 className={classes.shareOptionsHeaderText}>Share Your Report</h1> : null}
      {!contactData && !landingPageView ? (
        <div className={classes.shareOptionsButtonContainer} onClick={(e) => handleShareType(e, "contact", reportSaved)}>
          <h1 className={classes.shareOptionsButtonLabel}>Send to Contact</h1>
          <Button className={classes.shareOptionsButton} onClick={(e) => handleShareType(e, "contact", reportSaved)}>
            <SendOutlined color="inherit" fontSize="inherit" className={classes.shareOptionsButtonIcon} />
          </Button>
        </div>
      ) : null}
      {!contactData && !landingPageView ? (
        <div className={classes.shareOptionsButtonContainer} onClick={(e) => handleShareType(e, "social", reportSaved)}>
          <h1 className={classes.shareOptionsButtonLabel}>Share Socially</h1>
          <Button className={classes.shareOptionsButton} onClick={(e) => handleShareType(e, "social", reportSaved)}>
            <ShareOutlined color="inherit" fontSize="inherit" className={classes.shareOptionsButtonIcon} />
          </Button>
        </div>
      ) : null}
      {!contactData && !landingPageView ? (
        <div className={classes.shareOptionsButtonContainer} onClick={(e) => handleShareType(e, "link", reportSaved)}>
          <h1 className={classes.shareOptionsButtonLabel}>Copy a Link</h1>
          <Button className={classes.shareOptionsButton} onClick={(e) => handleShareType(e, "link", reportSaved)}>
            <LinkOutlined color="inherit" fontSize="inherit" className={classes.shareOptionsButtonIcon} />
          </Button>
        </div>
      ) : null}
      {landingPageView ? (
        <a target="_blank" rel="noopener noreferrer" class={classes.viewReportsButtons} href={reportId ? `https://${baseURL}/${userName}/market-reports/${reportId}` : null}>
          <div className={classes.shareOptionsButtonContainer}>
            <h1 className={classes.shareOptionsButtonLabel}>View Report</h1>
            <Button className={classes.shareOptionsButton}>
              <SendOutlined color="inherit" fontSize="inherit" className={classes.shareOptionsButtonIcon} />
            </Button>
          </div>
        </a>
      ) : null}

      {shareError ? <div className={classes.shareError}>You must save your report to share it</div> : null}
    </div>
  );
};

ShareOptions.propTypes = {
  contactData: PropTypes.object,
  setShareType: PropTypes.func,
  setShareOpen: PropTypes.func,
  reportSaved: PropTypes.bool,
  landingPageView: PropTypes.bool,
  userName: PropTypes.string,
  reportId: PropTypes.number,
};

export default ShareOptions;
