import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { AppDispatchContext, AppStateContext } from "../../../Context/AppReducer";
import { makeStyles, createStyles, withStyles, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { GET_PERSONAL_INFO } from "../../../Services/Graph/UserPreferences/Queries";
import { UPDATE_REPORT_SETTINGS, CREATE_REPORT_SETTINGS, UPDATE_USER_LINKS } from "../../../Services/Graph/UserPreferences/Mutations";
import useUploadLogo from "../../../Services/Rest/uploadLogoHook";
import useUploadProfileImage from "../../../Services/Rest/uploadProfileImageHook";
import PersonalInfo from "../PersonalInfo/PersonalInfo";
import DesignPreferences from "../DesignPreferences/DesignPreferences";
import CustomLinks from "../CustomLinks/CustomLinks";

const useStyles = makeStyles((theme) =>
  createStyles({
    summaryText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "20px",
      color: "#000",
      margin: "0px",
    },
    accordionContainer: {
      marginTop: "20px",
      marginRight: "20px",
    },
  })
);

const CustomAccordion = withStyles((theme) => ({
  root: {
    border: "0px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    background: "transparent",
    "&$expanded": {
      margin: "0px 0 !important",
    },
  },
  expanded: {},
}))(Accordion);

const CustomAccordionSummary = withStyles((theme) => ({
  root: {
    padding: "0px",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    "&$expanded": {
      margin: "0px 0 !important",
    },
  },
  expanded: {},
}))(AccordionSummary);

const CustomAccordionDetails = withStyles((theme) => ({
  root: {
    border: "0px solid rgba(0, 0, 0, .125)",
    padding: "0px",
  },
}))(AccordionDetails);

const PreferencesAccordion = ({ savingUserPrefs, setSavingUserPrefs }) => {
  const classes = useStyles();
  const globalDispatch = useContext(AppDispatchContext);
  const globalState = useContext(AppStateContext);
  const [activePanel, setActivPanel] = useState("none");
  const [personalInfo, setPersonalInfo] = useState(false);
  const [designPrefs, setDesignPrefs] = useState(false);
  const [customLinks, setCustomlinks] = useState(false);
  const [{ isFetching: logoIsFetching }, uploadLogo] = useUploadLogo();
  const [{ isFetching: profileImageIsFetching }, uploadProfileImage] = useUploadProfileImage();
  const [saveLinks, setSaveLinks] = useState(false);
  const [hasUpdateUserData, setHasUpdateUserData] = useState(false);
  const [fetchUpdateUserData, setFetchUpdatedUser] = useState(false);
  const [newData, setNewData] = useState(false);

  const [fetchPersonalInfo, { data, error, loading: dataLoading }] = useLazyQuery(GET_PERSONAL_INFO, {
    variables: {
      id: globalState?.user?.userId,
    },
    client: authApolloClient,
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    const errorMessage = error?.networkError?.result?.error ?? "";
    if (error && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [error, globalDispatch]);

  const [updateUserLinks, { data: linksData, loading: linksLoading, error: linksError }] = useMutation(UPDATE_USER_LINKS, {
    variables: {
      userId: globalState?.user?.userId,
      links: customLinks,
    },
    client: authApolloClient,
    fetchPolicy: "no-cache",
  });

  const whichQuery = () => {
    const reportSettingsIsUndefined = data?.user?.reportSettings === undefined;
    const firstNameIsEmpty = data?.user?.reportSettings?.firstName === "";
    const lastNameIsEmpty = data?.user?.reportSettings?.lastName === "";
    const emailIsEmpty = data?.user?.reportSettings?.email?.email === "";
    const phoneIsEmpty = data?.user?.reportSettings?.phone?.number === "";
    const addressIsEmpty =
      data?.user?.reportSettings?.address?.city === "" &&
      data?.user?.reportSettings?.address?.state === "" &&
      data?.user?.reportSettings?.address?.street === "" &&
      data?.user?.reportSettings?.address?.zip === "";
    const designColorsAreEmpty =
      data?.user?.reportSettings?.themeColorSolid === "" &&
      data?.user?.reportSettings?.themeColorGradient === "" &&
      data?.user?.reportSettings?.categoryColorNew === "" &&
      data?.user?.reportSettings?.categoryColorActive === "" &&
      data?.user?.reportSettings?.categoryColorPending === "" &&
      data?.user?.reportSettings?.categoryColorSold === "";
    if (reportSettingsIsUndefined || (firstNameIsEmpty && lastNameIsEmpty && emailIsEmpty && phoneIsEmpty && designColorsAreEmpty && addressIsEmpty)) {
      return CREATE_REPORT_SETTINGS;
    } else {
      return UPDATE_REPORT_SETTINGS;
    }
  };

  const [updateReportSettings, { data: settingsData, loading: settingsLoading, error: settingsError }] = useMutation(whichQuery(), {
    variables: {
      userId: globalState?.user?.userId,
      firstName: personalInfo?.firstname ?? "",
      lastName: personalInfo?.lastname ?? "",
      title: personalInfo?.title ?? "",
      licenseId: personalInfo?.license ?? "",
      phone: personalInfo?.phone ?? "",
      email: personalInfo?.email ?? "",
      city: personalInfo?.city ?? "",
      state: personalInfo?.state ?? "",
      street: personalInfo?.street ?? "",
      zip: personalInfo?.zip ?? "",
      categoryColorNew: designPrefs?.categoryColorNew ?? "",
      categoryColorActive: designPrefs?.categoryColorActive ?? "",
      categoryColorPending: designPrefs?.categoryColorPending ?? "",
      categoryColorSold: designPrefs?.categoryColorSold ?? "",
      themeColorSolid: designPrefs?.themeColorSolid ?? "",
      themeColorGradient: designPrefs?.themeColorGradient ?? "",
    },
    client: authApolloClient,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    fetchPersonalInfo();
  }, [fetchPersonalInfo]);

  useEffect(() => {
    if (fetchUpdateUserData && settingsData !== undefined && !settingsLoading && !settingsError && !linksLoading && !linksError && !logoIsFetching && !profileImageIsFetching && !dataLoading) {
      setFetchUpdatedUser(false);
      setSavingUserPrefs(false);
      setPersonalInfo(false);
      fetchPersonalInfo();
    }
  }, [settingsData, linksData, settingsLoading, settingsError, linksLoading, linksError, logoIsFetching, profileImageIsFetching, customLinks, savingUserPrefs, fetchUpdateUserData]);

  useEffect(() => {
    if (hasUpdateUserData && newData) {
      setNewData(false);
      if (personalInfo && designPrefs) {
        setCustomlinks(false);
        setHasUpdateUserData(false);
        setDesignPrefs(false);
        updateReportSettings();
        setFetchUpdatedUser(true);

        if (personalInfo?.logoURL !== "") {
          uploadLogo({
            userId: globalState?.user?.userId,
            image: personalInfo?.logoURL,
          });
        }
        if (personalInfo?.photoURL !== "") {
          uploadProfileImage({
            userId: globalState?.user?.userId,
            image: personalInfo?.photoURL,
          });
        }
      }
    }
  }, [savingUserPrefs, personalInfo, designPrefs, customLinks, hasUpdateUserData]);

  useEffect(() => {
    if (savingUserPrefs) {
      setSaveLinks(true);
      setHasUpdateUserData(true);
    }
    if (saveLinks) {
      if (customLinks && customLinks.length > 0) {
        updateUserLinks();
        setCustomlinks(false);
        setSaveLinks(false);
      }
    }
  }, [savingUserPrefs, customLinks, saveLinks]);

  const handleChange = (panel) => (event, newExpanded) => {
    setActivPanel(newExpanded ? panel : false);
  };

  return (
    <div className={classes.accordionContainer}>
      <CustomAccordion square expanded={activePanel === "panel1"} onChange={handleChange("panel1")}>
        <CustomAccordionSummary expandIcon={<ExpandMore />}>
          <h1 className={classes.summaryText}>Personal Info</h1>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <PersonalInfo
            savingUserPrefs={savingUserPrefs}
            personalInfo={personalInfo}
            setSavingUserPrefs={(status) => setSavingUserPrefs(status)}
            setPersonalInfo={(info) => setPersonalInfo(info)}
            data={data}
            setNewData={setNewData}
          />
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion square expanded={activePanel === "panel2"} onChange={handleChange("panel2")}>
        <CustomAccordionSummary expandIcon={<ExpandMore />}>
          <h1 className={classes.summaryText}>Design Preferences</h1>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <DesignPreferences savingUserPrefs={savingUserPrefs} designPrefs={designPrefs} setDesignPrefs={(info) => setDesignPrefs(info)} reportSettings={data?.user?.reportSettings} />
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion square expanded={activePanel === "panel3"} onChange={handleChange("panel3")}>
        <CustomAccordionSummary expandIcon={<ExpandMore />}>
          <h1 className={classes.summaryText}>Custom Links</h1>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomLinks savingUserPrefs={savingUserPrefs} customLinks={customLinks} setCustomlinks={(info) => setCustomlinks(info)} data={data} />
        </CustomAccordionDetails>
      </CustomAccordion>
    </div>
  );
};

PreferencesAccordion.propTypes = {
  savingUserPrefs: PropTypes.bool,
  setPersonalInfo: PropTypes.func,
  setDesignPrefs: PropTypes.func,
  data: PropTypes.object,
};

export default PreferencesAccordion;
