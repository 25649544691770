import { gql } from "@apollo/client";

export const UPDATE_REPORT_SETTINGS = gql`
  mutation UpdateReportSettings(
    $userId: Int!
    $firstName: String!
    $lastName: String!
    $title: String!
    $licenseId: String!
    $phone: String!
    $email: String!
    $city: String!
    $state: String!
    $street: String!
    $zip: String!
    $categoryColorNew: String!
    $categoryColorActive: String!
    $categoryColorPending: String!
    $categoryColorSold: String!
    $themeColorSolid: String!
    $themeColorGradient: String!
  ) {
    updateReportSettings(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      title: $title
      licenseId: $licenseId
      phone: $phone
      email: $email
      city: $city
      state: $state
      street: $street
      zip: $zip
      categoryColorNew: $categoryColorNew
      categoryColorActive: $categoryColorActive
      categoryColorPending: $categoryColorPending
      categoryColorSold: $categoryColorSold
      themeColorSolid: $themeColorSolid
      themeColorGradient: $themeColorGradient
    ) {
      id
    }
  }
`;

export const CREATE_REPORT_SETTINGS = gql`
  mutation CreateReportSettings(
    $userId: Int!
    $firstName: String!
    $lastName: String!
    $title: String!
    $licenseId: String!
    $phone: String!
    $email: String!
    $city: String!
    $state: String!
    $street: String!
    $zip: String!
    $categoryColorNew: String!
    $categoryColorActive: String!
    $categoryColorPending: String!
    $categoryColorSold: String!
    $themeColorSolid: String!
    $themeColorGradient: String!
  ) {
    createReportSettings(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      title: $title
      licenseId: $licenseId
      phone: $phone
      email: $email
      city: $city
      state: $state
      street: $street
      zip: $zip
      categoryColorNew: $categoryColorNew
      categoryColorActive: $categoryColorActive
      categoryColorPending: $categoryColorPending
      categoryColorSold: $categoryColorSold
      themeColorSolid: $themeColorSolid
      themeColorGradient: $themeColorGradient
    ) {
      id
    }
  }
`;

export const UPDATE_USER_LINKS = gql`
  mutation BatchUserLinks($links: [linkInput], $userId: Int!) {
    batchUserLink(links: $links, userId: $userId) {
      id
    }
  }
`;

export const CREATE_MLS_LINK = gql`
  mutation AddMLS($mlsId: Int!, $stateId: Int!, $userId: Int!, $mlsOfficeId: [String], $mlsAgentId: [String]) {
    createMlsLink(userId: $userId, mlsOfficeId: $mlsOfficeId, stateId: $stateId, mlsId: $mlsId, mlsAgentId: $mlsAgentId) {
      id
      mlsId
      stateId
      mlsAgentId
    }
  }
`;

export const DELETE_MLS_LINK = gql`
  mutation deleteMLS($id: Int!, $userId: Int!) {
    deleteMlsLink(id: $id, userId: $userId) {
      id
    }
  }
`;

export const UPDATE_USER_STEP = gql`
  mutation updateUserStep($userId: Int!, $step: Int!) {
    updateUserOnboardingStep(userId: $userId, step: $step) {
      step
    }
  }
`;
