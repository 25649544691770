import React, { useState, useEffect, useContext } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Grid, Collapse, IconButton, Input, FormControl } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, TextField } from "@material-ui/core";
import "./stripe.css";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { CloseRounded } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { ATTACH_PAYMENT_METHOD } from "../../../../Services/Graph/UserSettings/Mutations";
import { authApolloClient } from "../../../../Services/Graph/apolloConfig";
import { useMutation } from "@apollo/client";
import { AppDispatchContext } from "../../../../Context/AppReducer";
import { useForm } from "react-hook-form";
import MaskedInput from "react-text-mask";

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      animation: "fade 200ms ease-out",
    },
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    alert: {
      position: "fixed",
      top: "40px",
      width: "50%",
      left: "25%",
    },
    errorLabel: {
      color: "red",
    },
    formRow: {
      display: "flex",
      msFlexAlign: "center",
      alignItems: "center",
      backgroundColor: "#F5F5F5",
      border: "solid 1px #BCBCBC",
      padding: "7px 10px",
      borderRadius: "10px",
      "&:hover": { border: "solid 1px #202020" },
    },
    headerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "30px",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    modalActions: {
      display: "flex",
      justifyContent: "center",
      marginTop: "40px",
      marginBottom: "35px",
    },
    modalClose: {
      position: "absolute",
      right: "30px",
    },
    closeModalButton: {
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      "&:hover": {},
    },
    closeModalButtonIcon: {
      color: "#282828",
      fontSize: "30px",
    },
    cancelButton: {
      background: "transparent",
      minWidth: "0px",
      padding: "16px 80px",
      borderRadius: "10px",
      color: "#8D8989",
      "&:hover": {
        background: "transparent",
      },
      marginRight: "10px",
    },
    cancelButtonLabel: {
      fontFamily: theme.typography.secondary,
      color: "#8D8989",
      fontSize: "16px",
      fontWeight: "700",
      margin: 0,
    },
    saveButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "16px 80px",
      borderRadius: "10px",
      color: "#fff",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
      marginLeft: "10px",
    },
    formControl: {
      height: "56px",
      backgroundColor: "#F5F5F5",
      width: "100%",
      borderRadius: "10px",
      border: "solid 1px rgba(0, 0, 0, 0.23)",
      "&:hover": {
        border: "solid 1px black",
      },
    },
    formControlError: {
      border: "solid 1px red",
    },
    saveButtonLabel: {
      fontFamily: theme.typography.secondary,
      color: "#fff",
      fontSize: "16px",
      fontWeight: "700",
      margin: 0,
    },
    contactInput: {
      fontFamily: theme.typography.secondary,
      color: "#676767",
      fontSize: "18px",
      fontWeight: "600",
      borderRadius: "10px",
      border: "none",
    },
    contactInputPhone: {
      fontFamily: theme.typography.secondary,
      color: "#676767",
      fontSize: "18px",
      fontWeight: "600",
      borderRadius: "10px",
      border: "none",
      height: "56px",
      paddingLeft: "14px",
    },
    input: {
      backgroundColor: "#F5F5F5",
      borderRadius: "10px",
    },
    error: {
      color: "red",
      marginLeft: "14px",
      fontSize: "0.75rem",
    },
  })
);

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#818181",
      color: "#000",
      fontWeight: 1000,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#000",
      },
      "::placeholder": {
        color: "#AFAFAF",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

const CardField = ({ onChange, classes }) => (
  <div className={classes.formRow}>
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}
TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const UserSettingsBillingPaymentModal = ({ open, userId, email, fullname, phone, handleClose, handlePaymentAdd }) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const globalDispatch = useContext(AppDispatchContext);
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [attachPaymentSuccess, setAttachPaymentSuccess] = useState(false);
  const [attachPaymentError, setAttachPaymentError] = useState(false);

  const [attachPayment] = useMutation(ATTACH_PAYMENT_METHOD, {
    onCompleted() {
      setProcessing(false);
      setAttachPaymentSuccess(true);
      handlePaymentAdd(paymentMethod);
    },
    onError() {
      setAttachPaymentError(true);
      setProcessing(false);
      setError("Error attaching payment!");
    },
    variables: {
      userId: userId,
      paymentMethodId: paymentMethod?.id,
    },
    client: authApolloClient,
  });

  useEffect(() => {
    const errorMessage = attachPaymentError?.networkError?.result?.error ?? "";
    if (attachPaymentError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [attachPaymentError, globalDispatch]);

  useEffect(() => {
    if (attachPaymentSuccess) {
      const closeAlert = setTimeout(() => {
        setAttachPaymentSuccess(false);
      }, 3000);
      return () => clearTimeout(closeAlert);
    }
  }, [attachPaymentSuccess, globalDispatch]);

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      name: fullname,
      email: email,
      phone: phone,
    },
  });

  const submit = async (data) => {
    data.phone = data.phone.replace(/[&\/\\()-]/g, "");
    data.phone = data.phone.replace(" ", "");

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    setError(null);
    setAttachPaymentError(false);

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: data,
    });

    if (payload.error) {
      setError(payload.error.message);
      setAttachPaymentError(true);
      setProcessing(false);
    } else {
      setPaymentMethod(payload.paymentMethod);
      attachPayment();
    }
  };

  return (
    <div>
      <div className={classes.alert}>
        <Collapse in={attachPaymentSuccess} timeout={1000}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAttachPaymentSuccess(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Payment was added!
          </Alert>
        </Collapse>
      </div>

      <Dialog
        open={open}
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: "800px",
          },
        }}
        onClose={handleClose}
        aria-labelledby="add-payment-modal"
      >
        <div className={classes.headerContainer}>
          <h1 className={classes.paperLabel}>Add Payment Method</h1>
          <div className={classes.modalClose}>
            <Button className={classes.closeModalButton} onClick={handleClose}>
              <CloseRounded color="inherit" fontSize="inherit" className={classes.closeModalButtonIcon} />
            </Button>
          </div>
        </div>
        <form className={classes.form} onSubmit={handleSubmit(submit)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <TextField
                  variant="outlined"
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  className={classes.contactInput}
                  id="add-payment-name"
                  placeholder="John Doe"
                  fullWidth
                  InputProps={{
                    name: "name",
                    className: classes.input,
                  }}
                  inputRef={register({
                    validate: (value) => value !== "" || "Please enter your name",
                  })}
                  type="text"
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  variant="outlined"
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  className={classes.contactInput}
                  id="add-payment-email"
                  placeholder="johndoe@email.com"
                  fullWidth
                  InputProps={{
                    name: "email",
                    className: classes.input,
                  }}
                  inputRef={register({
                    validate: (value) => value !== "" || "Please enter your email address",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please Enter A Valid Email Address",
                    },
                  })}
                  type="text"
                />
              </Grid>
              <Grid item md={12}>
                <FormControl variant="outlined" className={`${classes.formControl} ${errors.phone ? classes.formControlError : null}`}>
                  <Input
                    disableUnderline={true}
                    name="phone"
                    variant="outlined"
                    error={Boolean(errors.phone)}
                    className={classes.contactInputPhone}
                    id="add-payment-phone"
                    placeholder="(941) 555-0123"
                    fullWidth
                    inputComponent={TextMaskCustom}
                    inputRef={register({
                      validate: (value) => value !== "" || "Please enter your phone number",
                      pattern: {
                        value: /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/i,
                        message: "Please Enter A Valid 10 digit phone number",
                      },
                    })}
                    type="tel"
                  />
                </FormControl>
                <div className={classes.error}>{errors?.phone?.message}</div>
              </Grid>
              <Grid item xs={12}>
                <CardField
                  classes={classes}
                  onChange={(e) => {
                    setError(e.error);
                    setCardComplete(e.complete);
                  }}
                />
              </Grid>
              {attachPaymentError ? (
                <Grid item xs={12} alignItems="center">
                  <span className={classes.errorLabel}>{error}</span>
                </Grid>
              ) : null}
            </Grid>
          </DialogContent>
          <div className={classes.modalActions}>
            <Button onClick={handleClose} className={classes.cancelButton}>
              <h1 className={classes.cancelButtonLabel}>Cancel</h1>
            </Button>
            <Button className={classes.saveButton} type="submit" disabled={processing || !stripe}>
              <h1 className={classes.saveButtonLabel}>{processing ? "Processing..." : "Save"}</h1>
            </Button>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

UserSettingsBillingPaymentModal.defaultProps = {
  email: "",
  fullname: "",
  phone: "",
};

UserSettingsBillingPaymentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePaymentAdd: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  fullname: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
};

export default UserSettingsBillingPaymentModal;
