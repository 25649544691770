import React, { useContext, useState, useEffect, Fragment } from "react";
import HomeStylesheet from "../HomeStylesheet";
import { AppStateContext } from "../../../Context/AppReducer";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import Desknav from "./DeskNav";
import MobileNav from "./MobileNav";
import PropTypes from "prop-types";

const Navigation = ({ setActiveView }) => {
  const classes = HomeStylesheet();
  const state = useContext(AppStateContext);
  const [navItemActive, setNavItemActive] = useState("home");
  const [anchor, setAnchor] = useState(null);
  const [needsToBeLighter, setNeedsToBeLighter] = useState(false);
  const [needsToBeSolid, setNeedsToBeSolid] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const isTablet = useMediaQuery("(max-width: 968px)");

  useEffect(() => {
    let mounted = true;
    const onScroll = (e) => {
      if (mounted) setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", (e) => onScroll(e));

    scrollTop >= 130 ? setNeedsToBeLighter(true) : setNeedsToBeLighter(false);
    scrollTop >= 130 ? setNeedsToBeSolid(true) : setNeedsToBeSolid(false);

    return () => {
      window.removeEventListener("scroll", (e) => onScroll(e));
      mounted = false;
    };
  }, [scrollTop]);

  /**
   * This function handles the open/ closed state of the menu.
   * @param {String} action
   * @param {Event} e
   */
  const handleMenu = (action, e) => {
    setAnchor(action === "open" ? e.currentTarget : null);
  };

  return (
    <Fragment>
      <div className={`${classes.navContainer} ${needsToBeSolid ? classes.navContainerSolid : ""}`}>
        <div className={classes.logoContainer}>
          <Link to={state.loggedIn ? "/home" : "/"}>
            <img className={`${classes.navLogoColor}`} src="/assets/ap-color.png" alt="AreaPulse" />
            {/* <img className={`${classes.navLogoWhite} ${needsToBeLighter ? classes.logoHidden : ""}`} src="/assets/w-logo.png" alt="AreaPulse" /> */}
          </Link>
        </div>
        {isTablet ? (
          <MobileNav
            anchor={anchor}
            handleMenu={(action, e) => handleMenu(action, e)}
            navItemActive={navItemActive}
            setNavItemActive={(item) => setNavItemActive(item)}
            needsToBeLighter={needsToBeLighter}
          />
        ) : (
          <Desknav setActiveView={setActiveView} navItemActive={navItemActive} setNavItemActive={(item) => setNavItemActive(item)} needsToBeLighter={needsToBeLighter} />
        )}
      </div>
    </Fragment>
  );
};

Navigation.propTypes = {
  setActiveView: PropTypes.func,
};

export default Navigation;
