import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Button, withStyles, CircularProgress, TextField } from "@material-ui/core";
import { Element } from "react-scroll";
import { DELETE_REQUEST } from "../../Services/Graph/FacebookDelete/queries";
import { useLazyQuery } from "@apollo/client";
import { openApolloClient } from "../../Services/Graph/apolloConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    overParent: {
      position: "absolute",
      backgroundColor: "#fff",
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    buttonParent: {
      justifyContent: "flex-end",
      display: "flex",
    },
    overDiv: {
      padding: "0px 180px",
      width: "100%",
      maxWidth: "906px",
      height: "100%",
      zIndex: "1",
    },
    parentDiv: {
      width: "100%",
      maxWidth: "1560px",
      fontFamily: "nunito",
    },
    titleDiv: {
      textAlign: "center",
      marginTop: "109px",
      fontWeight: "600",
      fontSize: "36px",
      marginBottom: "31px",
    },
    line: {
      height: "1px",
      width: "100%",
      backgroundColor: "#DEDEDE",
      marginBottom: "34px",
    },
    contactWave: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      bottom: "0px",
      height: "100%",
    },
    whiteBox: {
      backgroundColor: "#fff",
      borderRadius: "10px",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    circle: {
      height: "16px !important",
      width: "16px !important",
      color: "#000000",
    },
    error: {
      textAlign: "center",
      color: "red",
      marginTop: "10px",
    },
    success: {
      textAlign: "center",
      marginTop: "20px",
    },
    "@media (max-width: 900px)": {
      overDiv: {
        padding: "0px 25px",
      },
      titleDiv: {
        fontSize: "25px",
      },
    },
  })
);
const CloseButton = withStyles((theme) => ({
  root: {
    margin: "32px auto 0px",
    width: "190px",
    height: "50px",
    color: "#fff",
    background: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 30px",
    borderRadius: "10px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const ReportContactInput = withStyles({
  root: {
    margin: "5px 0px 15px",
    width: "100%",
    maxWidth: "380px",
    height: "50px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    "& label": {
      paddingTop: "18px",
      color: "#BDBDBD",
      paddingLeft: "40px",
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "24px",
    },
    "& label.Mui-focused": {
      color: "#777777",
      paddingLeft: "40px",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: "nunito",
      color: "#000",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",

      "&.MuiOutlinedInput-underline:before": {
        display: "none",
      },
      "&.MuiOutlinedInput-underline:after": {
        display: "none",
      },
    },
    "& .MuiOutlinedInput-input ": {
      padding: "18px 12px 13px",
    },
  },
})(TextField);

const FacebookDelete = () => {
  const classes = useStyles();
  const [requestID, setRequestID] = useState("");
  const [value, setValue] = useState("");
  const [emptyValue, setEmptyValue] = useState(false);

  const [getStatus, { data: statusData, loading: statusLoading, error: statusError }] = useLazyQuery(DELETE_REQUEST, {
    variables: {
      requestID: requestID,
    },
    client: openApolloClient,
    fetchPolicy: "network-only",
  });

  return (
    <Element className={classes.overParent} name="unsubscribe">
      <div className={classes.overDiv}>
        <div className={classes.parentDiv}>
          <div className={classes.titleDiv}>Delete Account Information</div>
          <div className={classes.line}></div>
          <div className={classes.whiteBox}>
            <div>Enter Facebook ID</div>
            <ReportContactInput
              onChange={(e) => {
                if (emptyValue) {
                  setEmptyValue(false);
                }
                setValue(e.target.value);
              }}
              variant="outlined"
              underline="none"
            />
            <CloseButton
              onClick={() => {
                if (value === "") {
                  setEmptyValue(true);
                } else {
                  setRequestID(value);
                  getStatus();
                }
              }}
              disabled={statusLoading}
            >
              {statusLoading ? <CircularProgress className={classes.circle} /> : "Send"}
            </CloseButton>
            {emptyValue ? <div className={classes.error}>Please Enter Your ID.</div> : null}
            {statusError && !emptyValue ? <div className={classes.error}>There was a problem retrieving the data.</div> : null}
            {statusData?.deleteRequest?.status === "Success" ? <div className={classes.success}>Your information was deleted successfully </div> : null}
            {statusData?.deleteRequest?.status === "failed" ? <div className={classes.error}>{statusData?.deleteRequest?.message} </div> : null}
          </div>
        </div>
      </div>
      <img src="/assets/new-areapulse-bluebg.png" className={classes.contactWave} />
    </Element>
  );
};

FacebookDelete.propTypes = {};

export default FacebookDelete;
