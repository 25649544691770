import { createContext } from "react";
import { loadState, saveState } from "../Helpers/localStorage";
import { authApolloClient } from "../Services/Graph/apolloConfig";

export const AppStateContext = createContext();
export const AppDispatchContext = createContext();

export const initialState =
  loadState("areaPulseAppState") !== undefined
    ? loadState("areaPulseAppState")
    : {
        loggedIn: false,
        user: {},
        logInAsUser: {},
        privacyNotice: true,
        activeView: "SignUpSocial",
        expanded: false,
        createMarketReport: false,
        reportWasSaved: false,
        contactWasMade: false,
        contactWasDeleted: false,
        sessionExpiredModal: false,
        ssoLoginError: false,
        loginInfoErrors: false,
        landingPageContact: {},
      };

/**
 * This function is responsible for mutating our apps context.
 * @param {Object} state
 * @param {String} action
 * @returns {Object}
 */
export const appReducer = (state, action) => {
  switch (action.type) {
    case "incremeant":
      state.count = state.count + 1;
      break;
    case "decremeant":
      state.count = state.count - 1;
      break;
    case "login":
      state.loginInfoErrors = true;
      state.loggedIn = true;
      state.user = action.payload;
      break;
    case "loginAsUser":
      state.logInAsUser = action.payload;
      break;
    case "logout":
      state.loggedIn = false;
      state.activeView = "Login";
      state.sessionExpiredModal = false;
      state.user = {};
      state.logInAsUser = {};
      state.landingPageContact = {};
      saveState("areaPulseAppState", {
        logInAsUser: {},
        loggedIn: false,
        user: {},
        privacyNotice: true,
        activeView: "Login",
        expanded: false,
        createMarketReport: false,
        reportWasSaved: false,
        contactWasMade: false,
        contactWasDeleted: false,
        sessionExpiredModal: false,
        landingPageContact: {},
      });
      saveState("areaPulseUserToken", null);
      authApolloClient.resetStore();
      if (action.payload?.history) {
        action.payload.history.push("/");
      }
      break;
    case "setExpiredSessionModal":
      state.sessionExpiredModal = action.payload;
      break;
    case "setAuthToken":
      state.userAuthToken = action.payload;
      break;
    case "setPrivacyNotice":
      state.privacyNotice = action.payload;
      break;
    case "setActiveView":
      state.activeView = action.payload;
      break;
    case "expandNav":
      state.expanded = action.payload;
      break;
    case "setCreateMarketReport":
      state.createMarketReport = action.payload;
      break;
    case "setReportWasSaved":
      state.reportWasSaved = action.payload;
      break;
    case "setContactWasMade":
      state.contactWasMade = action.payload;
      break;
    case "setContactWasDeleted":
      state.contactWasDeleted = action.payload;
      break;
    case "setUserName":
      state.user.username = action.payload;
      break;
    case "setFullName":
      state.user.fullName = action.payload;
      break;
    case "setLandingPageContact":
      state.landingPageContact = action.payload;
      break;
    case "setSSOLoginError":
      state.ssoLoginError = action.payload;
      break;
    case "loginInfoErrors":
      state.loginInfoErrors = action.payload;
      break;

    default:
      throw new Error("Invalid Action");
  }
  saveState("areaPulseAppState", state);
  return state;
};
