import { withStyles, TextField } from "@material-ui/core";

const CreateReportFilterInputSmall = withStyles(theme => ({
  root: {
    maxWidth: "55px",
    '& label': {
      color: '#BDBDBD',
      fontWeight: "500",
      fontFamily: "nunito"
    },
    '& label.Mui-focused': {
      color: theme.palette.areaPulseBlue,
      fontWeight: "500",
      fontFamily: "nunito"
    },
    '& .MuiFilledInput-root': {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "16px",
      color: "#4F4F4F",
      background: "#FAFAFA",
      borderRadius: "10px",
      borderColor: "rgba(0, 0, 0, 0.08)",
      boxShadow: "inset 0px 0px 7px rgba(0, 0, 0, 0.08)",
      '&.MuiFilledInput-underline:before': {
        display: "none"
      },
      '&.MuiFilledInput-underline:after': {
        display: "none"
      }
    },
  }
}))(TextField);

export default CreateReportFilterInputSmall;