import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Slider, withStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    setRadiusContainer: {
      marginBottom: "20px",
    },
    radiusHeader: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "16px",
      color: "#414141",
      margin: "0px",
    },
    radiusSubHeader: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "12px",
      color: "#7D7D7D",
      margin: "0px",
    },
    radiusSliderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "5px",
    },
    radiusValueText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "12px",
      color: "#7D7D7D",
      margin: "0px 0px 0px 20px",
      minWidth: "40px",
      textAlign: "right",
    },
  })
);

const CustomSlider = withStyles((theme) => ({
  root: {},
  thumb: {
    background: theme.palette.areaPulseBlue,
    height: "20px",
    width: "20px",
    marginTop: "-8px",
  },
  track: {
    borderRadius: "5px",
    background: "#EAEAEA",
    height: "4px",
  },
  rail: {
    borderRadius: "5px",
    background: "#EAEAEA",
    height: "4px",
  },
}))(Slider);

const SetRadius = ({ radius, setRadius, selectedAnAddress }) => {
  const classes = useStyles();

  useEffect(() => {
    if (selectedAnAddress) {
      setRadius(3);
    }
  }, [selectedAnAddress]);

  if (!selectedAnAddress) return null;
  return (
    <div className={classes.setRadiusContainer}>
      <h1 className={classes.radiusHeader}>Custom Radius</h1>
      <h1 className={classes.radiusSubHeader}>Only show listings within a specific distance</h1>
      <div className={classes.radiusSliderContainer}>
        <CustomSlider value={radius} min={1} max={50} onChange={(e, value) => setRadius(value)} />
        <h1 className={classes.radiusValueText}>{radius} mi</h1>
      </div>
    </div>
  );
};

SetRadius.propTypes = {
  radius: PropTypes.number,
  setRadius: PropTypes.func,
  selectedAnAddress: PropTypes.bool,
};

export default SetRadius;
