const getRadiusPolygon = (center, radiusInMi, points) => {
  if (!points) points = 64;

  const coords = {
    latitude: center[1],
    longitude: center[0],
  };

  const mi = radiusInMi * 1.61;
  const ret = [];
  const distanceX = mi / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = mi / 110.574;
  let theta;
  let x;
  let y;
  for (var i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [ret],
          },
        },
      ],
    },
  };
};

export default getRadiusPolygon;
