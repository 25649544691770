import React, { useState } from "react";
import { createStyles, makeStyles, withStyles, MenuItem, Grid, TextField, Fade } from "@material-ui/core";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    contactInput: {
      backgroundColor: "#fff",
      fontFamily: theme.typography.secondary,
      color: "#676767",
      fontSize: "18px",
      fontWeight: "600",
      borderRadius: "10px",
      border: "none",
    },
  })
);

const ContactInfoSelect = withStyles((theme) => ({
  root: {
    width: "100%",
    "& label": {
      color: "#BDBDBD",
      fontWeight: "500",
      fontFamily: "nunito",
    },
    "& label.Mui-focused": {
      color: theme.palette.areaPulseBlue,
      fontWeight: "500",
      fontFamily: "nunito",
    },
    "& .MuiFilledInput-root": {
      color: "#000",
      background: "#fff",
      borderRadius: "4px",
      fontWeight: "400",
      fontSize: "1rem",
      border: "#bcbcbc 1px solid",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
      "&.MuiFilledInput-underline:after": {
        display: "none",
      },
      "& .MuiSelect-filled": {
        padding: "17.5px 14px",
      },
    },
  },
}))(TextField);

const ContactPersonalDetailsInput = ({ contactInfo, setUpdatedContactInfo, setOpenUpdateContactSuccess, openUpdatingInputs, setOpenUpdatingInputs }) => {
  const classes = useStyles();
  const [stateSelect, setStateSelect] = useState(contactInfo?.address?.state.toUpperCase() ?? "");

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      firstname: contactInfo?.firstName ?? "",
      lastname: contactInfo?.lastName ?? "",
      phone: contactInfo?.phone?.number ?? "",
      email: contactInfo?.email?.email ?? "",
      street: contactInfo?.address?.street ?? "",
      zip: contactInfo?.address?.zip ?? "",
      city: contactInfo?.address?.city ?? "",
    },
  });

  const submit = (data) => {
    setUpdatedContactInfo({ ...data, state: stateSelect });
    setOpenUpdateContactSuccess(true);
    setOpenUpdatingInputs(false);
  };

  return (
    <Fade in={openUpdatingInputs} timeout={500}>
      <form id="contact-person-details-form" onSubmit={handleSubmit(submit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <TextField
              variant="outlined"
              className={classes.contactInput}
              id="contact-first-name"
              placeholder="First Name"
              fullWidth
              required
              type="text"
              error={Boolean(errors.firstname)}
              helperText={errors.firstname?.message}
              InputProps={{
                name: "firstname",
              }}
              inputRef={register({
                validate: (value) => value !== "" || "Please enter your first name",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <TextField
              variant="outlined"
              className={classes.contactInput}
              id="contact-last-name"
              placeholder="Last Name"
              fullWidth
              required
              type="text"
              error={Boolean(errors.lastname)}
              helperText={errors.lastname?.message}
              InputProps={{
                name: "lastname",
              }}
              inputRef={register({
                validate: (value) => value !== "" || "Please enter your last name",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              variant="outlined"
              className={classes.contactInput}
              id="contact-email"
              placeholder="Email"
              fullWidth
              required
              type="text"
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              InputProps={{
                name: "email",
              }}
              inputRef={register({
                validate: (value) => value !== "" || "Please enter your email address",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Please Enter A Valid Email Address",
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              variant="outlined"
              className={classes.contactInput}
              id="contact-street-address"
              placeholder="Home Address"
              fullWidth
              type="text"
              error={Boolean(errors.street)}
              helperText={errors.street?.message}
              InputProps={{
                name: "street",
              }}
              inputRef={register()}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              variant="outlined"
              className={classes.contactInput}
              id="contact-city"
              placeholder="City"
              fullWidth
              type="text"
              error={Boolean(errors.city)}
              helperText={errors.city?.message}
              InputProps={{
                name: "city",
              }}
              inputRef={register()}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ContactInfoSelect select variant="filled" className={classes.stateSelect} value={stateSelect} onChange={(e) => setStateSelect(e.target.value)}>
              <MenuItem value={"AL"}>Alabama</MenuItem>
              <MenuItem value={"AK"}>Alaska</MenuItem>
              <MenuItem value={"AZ"}>Arizona</MenuItem>
              <MenuItem value={"AR"}>Arkansas</MenuItem>
              <MenuItem value={"CA"}>California</MenuItem>
              <MenuItem value={"CO"}>Colorado</MenuItem>
              <MenuItem value={"CT"}>Connecticut</MenuItem>
              <MenuItem value={"DE"}>Delaware</MenuItem>
              <MenuItem value={"FL"}>Florida</MenuItem>
              <MenuItem value={"GA"}>Georgia</MenuItem>
              <MenuItem value={"HI"}>Hawaii</MenuItem>
              <MenuItem value={"ID"}>Idaho</MenuItem>
              <MenuItem value={"IL"}>Illinois</MenuItem>
              <MenuItem value={"IN"}>Indiana</MenuItem>
              <MenuItem value={"IA"}>Iowa</MenuItem>
              <MenuItem value={"KS"}>Kansas</MenuItem>
              <MenuItem value={"KY"}>Kentucky</MenuItem>
              <MenuItem value={"LA"}>Louisiana</MenuItem>
              <MenuItem value={"ME"}>Maine</MenuItem>
              <MenuItem value={"MD"}>Maryland</MenuItem>
              <MenuItem value={"MA"}>Massachusetts</MenuItem>
              <MenuItem value={"MI"}>Michigan</MenuItem>
              <MenuItem value={"MN"}>Minnesota</MenuItem>
              <MenuItem value={"MS"}>Mississippi</MenuItem>
              <MenuItem value={"MO"}>Missouri</MenuItem>
              <MenuItem value={"MT"}>Montana</MenuItem>
              <MenuItem value={"NE"}>Nebraska</MenuItem>
              <MenuItem value={"NV"}>Nevada</MenuItem>
              <MenuItem value={"NH"}>New Hampshire</MenuItem>
              <MenuItem value={"NJ"}>New Jersey</MenuItem>
              <MenuItem value={"NM"}>New Mexico</MenuItem>
              <MenuItem value={"NY"}>New York</MenuItem>
              <MenuItem value={"NC"}>North Carolina</MenuItem>
              <MenuItem value={"ND"}>North Dakota</MenuItem>
              <MenuItem value={"OH"}>Ohio</MenuItem>
              <MenuItem value={"OK"}>Oklahoma</MenuItem>
              <MenuItem value={"OR"}>Oregon</MenuItem>
              <MenuItem value={"PA"}>Pennsylvania</MenuItem>
              <MenuItem value={"RI"}>Rhode Island</MenuItem>
              <MenuItem value={"SC"}>South Carolina</MenuItem>
              <MenuItem value={"SD"}>South Dakota</MenuItem>
              <MenuItem value={"TN"}>Tennessee</MenuItem>
              <MenuItem value={"TX"}>Texas</MenuItem>
              <MenuItem value={"UT"}>Utah</MenuItem>
              <MenuItem value={"VT"}>Vermont</MenuItem>
              <MenuItem value={"VA"}>Virginia</MenuItem>
              <MenuItem value={"WA"}>Washington</MenuItem>
              <MenuItem value={"WV"}>West Virginia</MenuItem>
              <MenuItem value={"WI"}>Wisconson</MenuItem>
              <MenuItem value={"WY"}>Wyoming</MenuItem>
            </ContactInfoSelect>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              variant="outlined"
              className={classes.contactInput}
              id="contact-zip"
              placeholder="Zip"
              fullWidth
              type="text"
              error={Boolean(errors.zip)}
              helperText={errors.zip?.message}
              InputProps={{
                name: "zip",
              }}
              inputRef={register()}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              className={classes.contactInput}
              id="contact-phone-number"
              placeholder="Phone Number"
              fullWidth
              type="text"
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
              InputProps={{
                name: "phone",
              }}
              inputRef={register({
                pattern: {
                  value: /^\d{10}$/i,
                  message: "Please Enter A Valid 10 digit phone number",
                },
              })}
            />
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};

ContactPersonalDetailsInput.propTypes = {
  contactInfo: PropTypes.object,
  setUpdatedContactInfo: PropTypes.func,
  setOpenUpdateContactSuccess: PropTypes.func,
  setOpenUpdatingInputs: PropTypes.func,
  openUpdatingInputs: PropTypes.bool,
};

export default ContactPersonalDetailsInput;
