import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, withStyles, Button } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { authApolloClient } from "../../Services/Graph/apolloConfig";
import { DELETE_MARKET_REPORT } from "../../Services/Graph/CreateReport/Mutations";

const useStyles = makeStyles((theme) =>
  createStyles({
    areYouSureModalContainer: {
      position: "absolute",
      height: "0%",
      width: "100%",
      top: "0px",
      left: "0px",
      background: "rgba(0, 0, 0, 0.7)",
      zIndex: "10",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    },
    modalOpen: {
      height: "100vh",
    },
    areYouSureModalContentContainer: {
      borderRadius: "20px",
      background: "#FFFFFF",
      padding: "30px",
      maxWidth: "767px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    areYouSureText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      color: "#000",
      textTransform: "uppercase",
      letterSpacing: "0.195em",
    },
    areYouSureSubText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "18px",
      color: "#000",
      margin: "20px 0px",
      textAlign: "center",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "40px",
    },
  })
);

const CloseButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    background: theme.palette.areaPulseErrorLight,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 30px",
    borderRadius: "7px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseErrorLight,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const CancelButton = withStyles((theme) => ({
  root: {
    color: "#C6C6C6",
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 10px",
    borderRadius: "7px",
    transition: "0.2s",
    textTransform: "lowercase",
    marginRight: "5px",
  },
}))(Button);

const AreYouSure = ({ tryingToCloseWithoutSaving, setTryingToCloseWithoutSaving, setSavedReport, setCreateReportOpen, id, landingPageView }) => {
  const classes = useStyles();

  const [deleteReport] = useMutation(DELETE_MARKET_REPORT, {
    variables: {
      id: id,
    },
    client: authApolloClient,
  });

  const handleClose = () => {
    if (setSavedReport) {
      setSavedReport(false);
    }
    setCreateReportOpen({ open: false, editing: false, editPayload: {} });
    setTryingToCloseWithoutSaving(false);
    if (!landingPageView) {
      deleteReport();
    }
    document.body.style.overflowY = "visible";
  };

  const handleCancel = () => {
    setTryingToCloseWithoutSaving(false);
  };

  return (
    <div className={`${classes.areYouSureModalContainer} ${tryingToCloseWithoutSaving && classes.modalOpen}`} onClick={() => handleCancel()}>
      <div className={classes.areYouSureModalContentContainer} onClick={(e) => e.stopPropagation()}>
        <h1 className={classes.areYouSureText}>Are You Sure?</h1>
        <h1 className={classes.areYouSureSubText}>
          Make sure you have saved your report and shared it! Otherwise, closing the
          <br />
          popup will delete the report you worked so hard on creating!
        </h1>
        <div className={classes.buttonContainer}>
          <CancelButton onClick={() => handleCancel()}>cancel</CancelButton>
          <CloseButton onClick={() => handleClose()}>Close</CloseButton>
        </div>
      </div>
    </div>
  );
};

AreYouSure.propTypes = {
  tryingToCloseWithoutSaving: PropTypes.bool,
  setTryingToCloseWithoutSaving: PropTypes.func,
  setReportSaved: PropTypes.func,
  landingPageView: PropTypes.bool,
};

export default AreYouSure;
