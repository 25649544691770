import React, { useEffect, useContext, useState } from "react";
import { Clear } from "@material-ui/icons";
import PropTypes from "prop-types";
import { AppStateContext, AppDispatchContext } from "../../../Context/AppReducer";
import { makeStyles, createStyles, Grid, Button, withStyles, Slide } from "@material-ui/core";
import CreateContactLandingPage from "./CreateContactLandingPage";

const useStyles = makeStyles((theme) =>
  createStyles({
    createReportContentContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
      maxWidth: "1600px",
      maxHeight: "930px",
      zIndex: "10",
      background: "#fff",
      borderRadius: "20px",
      overflow: "hidden",
    },
    headerBar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "25px 35px",
    },
    headerBarTitle: {
      color: "black",
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      margin: "0px",
      width: "100%",
      textAlign: "center",
      marginLeft: "32px",
    },
    headerBarIcon: {
      color: "#282828",
      fontSize: "32px",
      cursor: "pointer",
    },
    topGrid: {
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    subTitle: {
      fontWeight: "700",
      fontSize: "16px",
      fontFamily: " nunito",
      color: "#A3A3A3",
      maxWidth: "337px",
      textAlign: "center",
    },
    properties: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      color: "#009EEB",
      marginTop: "56px",
      marginBottom: "0px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "40px 0",
    },
    image: {
      width: "100%",
      maxWidth: "500px",
    },
    numberHolder: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    svg: {
      marginLeft: "10px",
      marginTop: "52px",
    },
    arrowParent: {
      filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
    },
    arrowBox: {
      display: "flex",
      top: "-19px",
      left: "22px",
      position: "absolute",
      width: "218px",
      background: "#FFFFFF",
      borderRadius: "10px",
      fontFamily: "nunito",
      fontWeight: "600",
      fontSize: "13px",
      "&::after": {
        content: `''`,
        position: "absolute",
        top: "62%",
        right: "100%",
        width: "0",
        height: "0",
        border: "solid transparent",
        borderColor: "rgba(136, 183, 213, 0)",
        borderRightColor: "#FFFFFF",
        marginTop: "-30px",
        borderWidth: "15px",
      },
    },
    arrowText: {
      color: "#A3A3A3",
      padding: "15px 0px 13px 18px",
    },
    tootTipClose: {
      padding: "9px 9px 0px 0px",
      color: "#AAAAAA",
      fontSize: "27px",
      cursor: "pointer",
    },
    noSvg: {
      display: "none",
    },
    "@media (max-width: 600px)": {
      headerBarTitle: {
        fontSize: "18px",
      },
      headerBarIcon: {
        fontSize: "24px",
      },
    },
  })
);

const EditButton = withStyles((theme) => ({
  root: {
    marginLeft: "10px",
    color: "#fff",
    background: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "11px 25.5px",
    borderRadius: "10px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const ContinueButton = withStyles((theme) => ({
  root: {
    color: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 10px",
    borderRadius: "7px",
    transition: "0.2s",
  },
}))(Button);

const CreateReportGoodReport = ({
  user,
  setPreSubmit,
  preReportData,
  setResultsOpen,
  landingPageView,
  setCreateContactOpen,
  createContactOpenView,
  setCreateContactOpenView,
  setContactFrequency,
  contactFrequency,
}) => {
  const classes = useStyles();
  const globalDispatch = useContext(AppDispatchContext);
  const globalState = useContext(AppStateContext);
  const contactWasMade = globalState?.contactWasMade;

  const handleAddContactCheck = () => {
    if (landingPageView) {
      setCreateContactOpenView(true);
    } else {
      setResultsOpen(false);
      setPreSubmit(true);
    }
  };

  useEffect(() => {
    if (contactWasMade && landingPageView) {
      setResultsOpen(false);
      setPreSubmit(true);
      globalDispatch({ type: "setContactWasMade", payload: false });
    }
  }, [contactWasMade, landingPageView]);

  return (
    <div className={classes.createReportContentContainer}>
      <div className={classes.headerBar}>
        <h1 className={classes.headerBarTitle}>{!createContactOpenView ? "Wow! Data lookin’ Good!" : "Almost there! Just one more thing."}</h1>
        <Clear
          fontSize="inherit"
          color="inherit"
          onClick={() => {
            setResultsOpen(false);
          }}
          className={classes.headerBarIcon}
        />
      </div>
      {!createContactOpenView ? (
        <Grid className={classes.topGrid} container>
          <div className={classes.subTitle}>With the information you provided, your report is showing</div>
          <div className={classes.numberHolder}>
            <div className={classes.properties}>{preReportData !== undefined ? preReportData.listingCount : null} properties</div>
          </div>
          <img alt="report results" className={classes.image} src="/assets/report-results-1.png" />
          <div className={classes.buttonContainer}>
            <ContinueButton
              onClick={() => {
                setResultsOpen(false);
              }}
            >
              Edit Report
            </ContinueButton>
            <EditButton
              onClick={() => {
                handleAddContactCheck();
              }}
            >
              Continue
            </EditButton>
          </div>
        </Grid>
      ) : null}
      {createContactOpenView ? <CreateContactLandingPage setContactFrequency={setContactFrequency} contactFrequency={contactFrequency} user={user}></CreateContactLandingPage> : null}
    </div>
  );
};

CreateReportGoodReport.propTypes = {
  user: PropTypes.string,
  setResultsOpen: PropTypes.func,
  setPreSubmit: PropTypes.func,
  preReportData: PropTypes.object,
  setCreateContactOpen: PropTypes.func,
  createContactOpenView: PropTypes.bool,
  setCreateContactOpenView: PropTypes.func,
  setContactFrequency: PropTypes.func,
  contactFrequency: PropTypes.string,
};

export default CreateReportGoodReport;
