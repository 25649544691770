import { gql } from "@apollo/client";

export const GET_ALL_REPORTS = gql`
  query GetReports($page: Int, $pageSize: Int, $sortField: MarketReportSortFields, $sortDirection: SortDirection) {
    reports(pageSize: $pageSize, page: $page, sortField: $sortField, sortDirection: $sortDirection) {
      dateCreated
      id
    }
  }
`;

export const GET_USER_DATA = gql`
  query User($username: String!) {
    user(username: $username) {
      reportSettings {
        themeColorGradient
      }
    }
  }
`;

export const GET_REPORT_DATA = gql`
  query GetData($id: Int!) {
    report(id: $id) {
      title
      id
      requireRegistration
      stats {
        byStatus {
          count
          avgPrice
          status
          maxPrice
          minPrice
        }
      }
    }
  }
`;

export const GET_CONTACTS = gql`
  query GetContacts($parentPersonId: Int!, $searchTerm: String, $first: Int, $offset: Int, $sortField: String, $sortDirection: String) {
    contacts(parentPersonId: $parentPersonId, searchTerm: $searchTerm, first: $first, offset: $offset, sortField: $sortField, sortDirection: $sortDirection) {
      contacts {
        firstName
        lastName
        email {
          email
        }
        phone {
          number
        }
      }
    }
  }
`;
