import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import ContactComponent from "../Components/Contact/ContactComponent";
import CreateReport from "../Components/CreateReport/CreateReport";
import { GET_CONTACT } from "../Services/Graph/Contact/Queries";
import { authApolloClient } from "../Services/Graph/apolloConfig";
import { useQuery } from "@apollo/client";

const useStyles = makeStyles((theme) =>
  createStyles({
    contactsViewContainer: {
      position: "absolute",
      left: "0",
      right: "0",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  })
);

const ContactView = ({ userId, createReportOpen, setCreateReportOpen, match, googleScriptLoaded, subscribe, setSubscribe }) => {
  const classes = useStyles();
  const user = match.params.user;
  const contactId = match.params.contactId;
  const [contactReportWasMade, setContactReportWasMade] = useState(false);

  const { data: contactData } = useQuery(GET_CONTACT, {
    variables: {
      id: contactId ?? 0,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });
  return (
    <div className={classes.contactsViewContainer}>
      {createReportOpen.open && (
        <CreateReport
          user={user}
          contactData={contactData}
          setSubscribe={setSubscribe}
          googleScriptLoaded={googleScriptLoaded}
          createReportOpen={createReportOpen}
          setCreateReportOpen={(status) => setCreateReportOpen(status)}
          setContactReportWasMade={setContactReportWasMade}
        />
      )}
      <ContactComponent
        userId={userId}
        setSubscribe={setSubscribe}
        subscribe={subscribe}
        contactId={contactId}
        user={user}
        setCreateReportOpen={(status) => setCreateReportOpen(status)}
        contactReportWasMade={contactReportWasMade}
        setContactReportWasMade={setContactReportWasMade}
      />
    </div>
  );
};

ContactView.propTypes = {
  setSubscribe: PropTypes.func,
  subscribe: PropTypes.bool,
  createReportOpen: PropTypes.object,
  setCreateReportOpen: PropTypes.func,
  match: PropTypes.object,
  userId: PropTypes.number,
};

export default ContactView;
