import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, withStyles, Backdrop, Grid, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ColorPicker from "./ColorPicker";

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: "10",
      cursor: "default",
    },
    gradientPickerContainer: {
      padding: "30px",
      background: "#fff",
      position: "relative",
      borderRadius: "10px",
      maxWidth: "767px",
      width: "100%",
    },
    exitButton: {
      position: "absolute",
      right: "3px",
      top: "3px",
      fontSize: "30px",
      color: "#333",
      transition: "0.2s",
      cursor: "pointer",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    gradientPreviewContainer: {
      height: "200px",
      width: "100%",
      borderRadius: "10px",
      background: (props) => `linear-gradient(-5deg, ${props?.color1} 50%, ${props?.color2} 90%) repeat scroll rgba(0, 0, 0, 0)`,
    },
    colorPickersContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "140px",
    },
    colorTypeContainer: {
      position: "relative",
      background: "transparent",
      padding: "8px 0px",
      border: "1px solid #E9E9E9",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      maxWidth: "82px",
      width: "100%",
      margin: "0px 10px 0px 10px",
      cursor: "pointer",
    },
    color: {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      background: "#BDBDBD",
      transition: "0.2s",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    colorLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "13px",
      color: "#000",
      margin: "6px 0px 0px 0px",
    },
    color1: {
      background: (props) => props.color1,
    },
    color2: {
      background: (props) => props.color2,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

const SaveButton = withStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: "-35px",
    color: "#fff",
    fontFamily: theme.typography.secondary,
    fontWeight: "400",
    fontSize: "16px",
    background: theme.palette.areaPulseBlue,
    padding: "10px 70px",
    borderRadius: "10px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    transition: "0.2s",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      opacity: "0.8",
      transition: "0.2s",
    },
  },
}))(Button);

const GradientColorPicker = ({ open, setClosePicker, setBackgroundColor, origionalGradient }) => {
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [colorPicker2State, setColorPicker2State] = useState("none");
  const [closePicker2, setClosePicker2] = useState(false);
  const classes = useStyles({ color1, color2 });
  const defaultColor1 = origionalGradient?.split("-5deg, ").pop().split(" 50%")[0];
  const defaultColor2 = origionalGradient?.split("50%, ").pop().split(" 90%")[0];

  const handleSave = () => {
    const newGradient = `linear-gradient(-5deg, ${color1} 50%, ${color2} 90%) repeat scroll rgba(0, 0, 0, 0)`;
    setBackgroundColor(newGradient);
    setClosePicker();
  };

  const handleCancel = () => {
    setBackgroundColor(origionalGradient);
    setClosePicker();
    setColor1(defaultColor1);
    setColor2(defaultColor2);
  };

  useEffect(() => {
    setColor1(defaultColor1);
    setColor2(defaultColor2);
  }, [origionalGradient, defaultColor1, defaultColor2]);

  useEffect(() => {
    if (closePicker2) {
      setColorPicker2State("none");
      setClosePicker2(false);
    }
  }, [closePicker2]);

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <div className={classes.gradientPickerContainer} onClick={(e) => e.stopPropagation()}>
        <Close fontSize="inherit" color="inherit" className={classes.exitButton} onClick={() => handleCancel()} />
        <Grid container>
          <Grid item xs={6}>
            <div className={classes.colorPickersContainer}>
              <div className={classes.colorTypeContainer} onClick={() => setColorPicker2State("color1")}>
                <div className={`${classes.color} ${classes.color2}`} />
                <h1 className={classes.colorLabel}>Color 1</h1>
                {colorPicker2State === "color1" && <ColorPicker setColor={(color) => setColor2(color)} origionalColor={defaultColor2} setClosePicker={() => setClosePicker2(true)} color={color2} />}
              </div>
              <div className={classes.colorTypeContainer} onClick={() => setColorPicker2State("color2")}>
                <div className={`${classes.color} ${classes.color1}`} />
                <h1 className={classes.colorLabel}>Color 2</h1>
                {colorPicker2State === "color2" && <ColorPicker setColor={(color) => setColor1(color)} origionalColor={defaultColor1} setClosePicker={() => setClosePicker2(true)} color={color1} />}
              </div>
              <SaveButton onClick={() => handleSave()}>SAVE</SaveButton>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.gradientPreviewContainer} />
          </Grid>
        </Grid>
      </div>
    </Backdrop>
  );
};

GradientColorPicker.propTypes = {
  open: PropTypes.bool,
  setClosePicker: PropTypes.func,
  setBackgroundColor: PropTypes.func,
  origionalGradient: PropTypes.string,
};

export default GradientColorPicker;
