import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Grid, Avatar, IconButton } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import PersonalInfoInputs from "./PersonalInfoInputs";
import ImageUpload from "./ImageUpload";

const useStyles = makeStyles((theme) =>
  createStyles({
    imagesContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      marginTop: "20px",
      marginBottom: "30px",
    },
    imageContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "fit-content",
      cursor: "pointer",
    },
    margin: {
      marginRight: "60px",
    },
    avatar: {
      height: "103px",
      width: "103px",
      background: "#BDBDBD",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0008), 0px 4px 8px rgba(0, 0, 0, 0.12)",
    },
    fallbackText: {
      margin: "0px",
      fontSize: "30px",
    },
    imagePreviewLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "14px",
      color: "#000",
      margin: "15px 0px 0px 0px",
    },
    editButton: {
      background: theme.palette.areaPulseBlue,
      padding: "5px",
      position: "absolute",
      right: "0px",
      bottom: "35px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0008), 0px 4px 8px rgba(0, 0, 0, 0.12)",
      transition: "0.2s",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
        transition: "0.2s",
        opacity: "0.8",
      },
    },
    editButtonIcon: {
      color: "#fff",
    },
  })
);

const PersonalInfo = ({ setNewData, savingUserPrefs, personalInfo, setSavingUserPrefs, setPersonalInfo, data }) => {
  const classes = useStyles();
  const [imageUpload, setImageUpload] = useState(false);

  const [profileImage, setProfileImage] = useState({
    preview: data?.user?.photoURL,
    file: "",
  });
  const [logoImage, setLogoImage] = useState({
    preview: data?.user?.logoURL,
    file: "",
  });

  const firstInitial = data?.user?.firstName?.split("")[0];

  const sendCollectedInfo = (info) => {
    setPersonalInfo({
      ...info,
      photoURL: profileImage.file,
      logoURL: logoImage.file,
    });
  };

  const activateImageUpload = (e) => {
    e.stopPropagation();
    setImageUpload(true);
  };

  useEffect(() => {
    if (data) {
      setProfileImage({
        preview: data?.user?.photoURL,
        file: "",
      });
      setLogoImage({
        preview: data?.user?.logoURL,
        file: "",
      });
    }
  }, [data]);

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} className={classes.imagesContainer}>
          <div className={`${classes.imageContainer} ${classes.margin}`} onClick={(e) => activateImageUpload(e)}>
            {profileImage.preview === "" ? (
              <Avatar className={classes.avatar}>
                <h1 className={classes.fallbackText}>{firstInitial}</h1>
              </Avatar>
            ) : (
              <Avatar alt={firstInitial} src={profileImage.preview} className={classes.avatar} />
            )}
            <IconButton className={classes.editButton} onClick={(e) => activateImageUpload(e)}>
              <EditOutlined color="inherit" className={classes.editButtonIcon} />
            </IconButton>
            <h1 className={classes.imagePreviewLabel}>Profile Image</h1>
          </div>
          <div className={classes.imageContainer} onClick={(e) => activateImageUpload(e)}>
            {logoImage.preview === "" ? (
              <Avatar className={classes.avatar}>
                <h1 className={classes.fallbackText}>{firstInitial}</h1>
              </Avatar>
            ) : (
              <Avatar alt={firstInitial} src={logoImage.preview} className={classes.avatar} />
            )}
            <IconButton className={classes.editButton} onClick={(e) => activateImageUpload(e)}>
              <EditOutlined color="inherit" className={classes.editButtonIcon} />
            </IconButton>
            <h1 className={classes.imagePreviewLabel}>Logo Image</h1>
          </div>
        </Grid>
        <Grid item xs={12}>
          {data && (
            <PersonalInfoInputs
              setNewData={setNewData}
              data={data}
              personalInfo={personalInfo}
              sendCollectedInfo={(info) => sendCollectedInfo(info)}
              savingUserPrefs={savingUserPrefs}
              setSavingUserPrefs={(status) => setSavingUserPrefs(status)}
            />
          )}
        </Grid>
      </Grid>
      <ImageUpload
        firstInitial={firstInitial}
        imageUpload={imageUpload}
        setImageUpload={(status) => setImageUpload(status)}
        profileImage={profileImage}
        setProfileImage={(image) => setProfileImage(image)}
        logoImage={logoImage}
        setLogoImage={(image) => setLogoImage(image)}
        origionalProfileImage={data?.user?.photoURL}
        origionalLogoImage={data?.user?.logoURL}
      />
    </Fragment>
  );
};

PersonalInfo.propTypes = {
  savingUserPrefs: PropTypes.bool,
  setPersonalInfo: PropTypes.func,
  setNewData: PropTypes.func,
};

export default PersonalInfo;
