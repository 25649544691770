import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import { makeStyles, createStyles, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import getFormattedPhoneNumber from "../../Helpers/getFormattedPhoneNumber";

const useStyles = makeStyles((theme) =>
  createStyles({
    section: {
      position: "relative",
      padding: "100px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "2",
      backgroundColor: (props) => props.secondaryColor,
    },
    sectionContentContainer: {
      position: "relative",
      width: "80%",
      maxWidth: "1600px",
      zIndex: "9",
    },
    logoContainer: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      width: "100%",
      height: "100%",
      padding: "40px",
    },
    logoImage: {
      width: "auto",
      maxHeight: "200px",
      maxWidth: "300px",
    },
    userInfoContainer: {
      padding: "40px",
    },
    fadeInUp: {
      animationName: "$fadeInUp",
    },
    "@keyframes fadeInUp": {
      from: {
        opacity: "0",
        transform: "translate3d(0, 100%, 0)",
      },
      to: {
        opacity: "1",
        transform: "translate3d(0, 0, 0)",
      },
    },
    textPosition: {
      textAlign: "left",
    },
    footerName: {
      fontSize: "24px",
      fontFamily: "Nunito",
      margin: "5px 0px",
      fontWeight: "700",
      color: "#ffffff",
    },
    footerInfo: {
      fontSize: "24px",
      fontFamily: "Nunito",
      margin: "5px 0px",
      fontWeight: "100",
      color: "#ffffff",
    },
    "@media (max-width: 1280px)": {
      sectionContentContainer: {
        width: "95%",
      },
      userInfoContainer: {
        padding: "40px",
      },
    },
    "@media (max-width: 960px)": {
      section: {
        padding: "80px 0px",
      },
      sectionContentContainer: {
        width: "85%",
      },
      logoContainer: {
        justifyContent: "center",
        padding: "0px",
      },
      textPosition: {
        textAlign: "center",
      },
      userInfoContainer: {
        padding: "40px 40px 0",
      },
    },
    "@media (max-width: 468px)": {
      sectionContentContainer: {
        width: "95%",
      },
    },
    primaryColor: {
      fill: (props) => props.primaryColor,
    },
  })
);

const LandingFooter = ({ userData }) => {
  const [primaryColor, setPrimaryColor] = useState(userData?.user?.landingPageSettings?.themeColorPrimary);
  const [secondaryColor, setSecondaryColor] = useState(userData?.user?.landingPageSettings?.themeColorSecondary);

  const [logoImage, setLogoImage] = useState("/assets/w-logo.png");
  const classes = useStyles({ primaryColor, secondaryColor });
  const userDisplayInfo = userData.user.reportSettings;

  useEffect(() => {
    if (userData?.user?.landingPageSettings?.themeColorPrimary === "") {
      setPrimaryColor("#008DD1");
    }

    if (userData?.user?.landingPageSettings?.themeColorSecondary === "") {
      setSecondaryColor("#008DD1");
    }

    if (userData?.user?.logoURL !== "") {
      setLogoImage(userData?.user?.logoURL);
    }
  }, [userData]);

  return (
    <Element name="landingfooter" className={classes.section}>
      <Grid container className={classes.sectionContentContainer}>
        <Grid item xs={12} sm={12} md={6}>
          <div className={classes.logoContainer}>
            <img className={classes.logoImage} src={logoImage} alt="company logo"></img>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className={`wow ${classes.fadeInUp} ${classes.userInfoContainer}`}>
            {userDisplayInfo.firstName && userDisplayInfo.lastName ? (
              <h2 className={`${classes.footerName} ${classes.textPosition}`}>
                {userDisplayInfo.firstName} {userDisplayInfo.lastName}
              </h2>
            ) : null}
            {userDisplayInfo.title ? (
              <h2 className={`${classes.footerInfo} ${classes.textPosition}`}>{userDisplayInfo.title}</h2>
            ) : null}
            {userDisplayInfo.phone?.number ? (
              <h2 className={`${classes.footerInfo} ${classes.textPosition}`}>
                {getFormattedPhoneNumber(userDisplayInfo.phone?.number)}
              </h2>
            ) : null}
            {userDisplayInfo.licenseId ? (
              <h2 className={`${classes.footerInfo} ${classes.textPosition}`}>
                License ID: {userDisplayInfo.licenseId}
              </h2>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Element>
  );
};

LandingFooter.propTypes = {
  userData: PropTypes.object,
};

export default LandingFooter;
