/**
 * Function for getting the current users MLS IDS
 * @param {Object} user
 * @returns {Array} Returns Array of Ints
 */
const getUsersMlsIds = user => {
  const mlsLinks = user?.mlsLinks ?? [];
  const ids = mlsLinks.map(link => {
    return link.mlsId;
  });
  return ids;
};

export default getUsersMlsIds;