import React, { useContext, useEffect, useState } from "react";
import { makeStyles, createStyles, Grid, CircularProgress } from "@material-ui/core";
import { AppStateContext } from "../../Context/AppReducer";
import { openApolloClient } from "../../Services/Graph/apolloConfig";
import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import OptionOne from "./OptionOne";
import OptionTwo from "./OptionTwo";
import OptionThree from "./OptionThree";
import { GET_USER_DATA, GET_REPORT_DATA } from "../../Services/Graph/ReportQueries";
import LandingPageNoReports from "./LandingPageNoReports";
import NotFoundPage from "../NotFound/NotFoundComponent";

const useStyles = makeStyles((theme) =>
  createStyles({
    landingPageContainer: {
      background: "#fff",
      height: "100%",
    },
    circleContatiner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
    },
    circle: {
      height: "100px !important",
      width: "100px !important",
      color: "#0079B4",
      strokeLinecap: "round",
    },
    loading: {
      marginLeft: "38px",
    },
  })
);

const getFaviconEl = () => {
  return document.getElementById("favicon");
};

const LandingPageComponent = ({ setGetUserData, getUserData, user, setCreateReportOpen }) => {
  const classes = useStyles();
  const [canCreate, setCanCreate] = useState(false);
  const [noReport, setNoReport] = useState(false);
  const [is404, setIs404] = useState(false);

  const [getUser, { data: userData, error: userDataError }] = useLazyQuery(GET_USER_DATA, {
    variables: {
      username: user,
    },
    client: openApolloClient,
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (userDataError) {
      const noUser = userDataError.toString().slice(0, 21);
      if (noUser === "Error: user not found") {
        setIs404(true);
      }
    }
  }, [userDataError]);
  useEffect(() => {
    if (userData === undefined) {
      getUser();
    }

    if (getUserData) {
      getUser();
      setGetUserData(false);
    }

    if (userData && userData?.user?.totalSubscriptionUsage?.reportsRemaining) {
      if (userData?.user?.totalSubscriptionUsage?.reportsRemaining > 0 || userData?.user?.totalSubscriptionUsage?.reportsRemaining === -1) {
        setCanCreate(true);
      } else if (userData?.user?.totalSubscriptionUsage?.reportsRemaining < 0 || userData?.user?.totalSubscriptionUsage?.reportsRemaining === 0) {
        setCanCreate(false);
      }
    }
  }, [userData, getUserData]);

  useEffect(() => {
    if (userData) {
      handleFavicon();
    }
  }, [userData]);

  const getOption = (option) => {
    switch (option) {
      case 1:
        return <OptionOne canCreate={canCreate} setNoReport={setNoReport} canCreate={canCreate} userData={userData} setCreateReportOpen={(status) => setCreateReportOpen(status)} />;
      case 2:
        return <OptionTwo canCreate={canCreate} setNoReport={setNoReport} canCreate={canCreate} userData={userData} setCreateReportOpen={(status) => setCreateReportOpen(status)} />;
      case 3:
        return <OptionThree canCreate={canCreate} setNoReport={setNoReport} canCreate={canCreate} userData={userData} setCreateReportOpen={(status) => setCreateReportOpen(status)} />;
      default:
        return <OptionOne canCreate={canCreate} setNoReport={setNoReport} canCreate={canCreate} userData={userData} setCreateReportOpen={(status) => setCreateReportOpen(status)} />;
    }
  };

  const handleFavicon = () => {
    const favicon = getFaviconEl();
    favicon.href = document.getElementById("landing-page-ready") ? userData?.user?.landingPageSettings?.faviconUrl : "/assets/ap-logo-new.png";
  };

  return (
    <Grid container id="landing-page-ready" className={classes.landingPageContainer}>
      {!is404 ? (
        userData ? (
          <div>{getOption(userData?.user?.landingPageSettings.templateChosen)}</div>
        ) : (
          <div className={classes.circleContatiner}>
            <CircularProgress className={classes.circle} thickness={3} />
          </div>
        )
      ) : (
        <NotFoundPage />
      )}
      <LandingPageNoReports email={userData?.user?.email} noReport={noReport} setNoReport={setNoReport} />
    </Grid>
  );
};

LandingPageComponent.propTypes = {
  user: PropTypes.string,
  setCreateReportOpen: PropTypes.func,
  setGetUserData: PropTypes.func,
  getUserData: PropTypes.bool,
};

export default LandingPageComponent;
