import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, withStyles, Button, Menu, MenuItem, Fade } from "@material-ui/core";
import PropTypes from "prop-types";
import MoreHorzOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddNoteModal from "./AddNoteModal";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      padding: "14px 20px",
    },
    menuItemIcon: {
      marginRight: "12px",
    },
    notesContactButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    notesContactButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    cancelButton: {
      color: theme.palette.grey[500],
      marginRight: "10px",
    },
  })
);

const StyledMenu = withStyles({
  paper: {
    backgroundColor: "#f1f1f1",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "center",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "center",
      horizontal: "left",
    }}
    {...props}
  />
));

const ContactNotesActions = ({ contactId, contactNoteWasMade, setContactNoteWasMade, contactNoteDeleteButton, setContactDeleteButton }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [cancelAddNote, setCancelAddNote] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteStatus = (event) => {
    setContactDeleteButton(true);
    setAnchorEl(null);
  };

  const handleCancelDeletion = () => {
    setContactDeleteButton(false);
  };

  useEffect(() => {
    if (openModal) {
      setAnchorEl(null);
    }

    if (cancelAddNote) {
      setAnchorEl(null);
      setCancelAddNote(false);
    }
  }, [contactNoteWasMade, cancelAddNote, openModal]);

  return (
    <div>
      {contactNoteDeleteButton ? (
        <Fade in={contactNoteDeleteButton} timeout={500}>
          <Button aria-label="cancel" className={classes.cancelButton} onClick={handleCancelDeletion}>
            Cancel
          </Button>
        </Fade>
      ) : null}
      <Button className={classes.notesContactButton} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreHorzOutlinedIcon className={classes.notesContactButtonIcon} />
      </Button>
      <StyledMenu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className={classes.dropDown}>
        <div>
          <AddNoteModal
            contactId={contactId}
            contactNoteWasMade={contactNoteWasMade}
            setContactNoteWasMade={(status) => setContactNoteWasMade(status)}
            setCancelAddNote={(status) => setCancelAddNote(status)}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </div>
        <MenuItem onClick={handleDeleteStatus} className={classes.menuItem}>
          <DeleteOutlineOutlinedIcon className={classes.menuItemIcon} />
          Delete Note
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

ContactNotesActions.propTypes = {
  contactId: PropTypes.string,
  contactNoteWasMade: PropTypes.bool,
  setContactNoteWasMade: PropTypes.func,
  contactNoteDeleteButton: PropTypes.bool,
  setContactDeleteButton: PropTypes.func,
};

export default ContactNotesActions;
