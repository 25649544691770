import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, withStyles, Slider } from "@material-ui/core";
import CreateReportFilterInput from "../Common/Inputs/CreateReportFilterInput";

const useStyles = makeStyles((theme) =>
  createStyles({
    selectPriceContainer: {
      marginBottom: "15px",
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerText: {
      fontFamily: theme.typography.secondary,
      color: "#414141",
      fontWeight: "600",
      fontSize: "16px",
      margin: "0px",
    },
    rangesContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    hyphen: {
      margin: "0px 5px",
    },
    sliderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "15px",
    },
  })
);

const CustomSlider = withStyles((theme) => ({
  root: {
    margin: "0 30px",
  },
  thumb: {
    background: theme.palette.areaPulseBlue,
    height: "20px",
    width: "20px",
    marginTop: "-8px",
  },
  track: {
    borderRadius: "5px",
    background: "#EAEAEA",
    height: "4px",
  },
  rail: {
    borderRadius: "5px",
    background: "#EAEAEA",
    height: "4px",
  },
}))(Slider);

const SelectPrice = ({ pullPrice, setPullPrice, price, setPrice }) => {
  const classes = useStyles();
  const lowPrice = `$${price[0].toLocaleString()}`;
  const highPrice = `$${price[1].toLocaleString()}`;
  const [newLowPrice, setNewLowPrice] = useState("$0");
  const [newHighPrice, setNewHighPrice] = useState("$3,000,000");
  const [sliderFocus, setSliderFous] = useState(false);
  const isHighPriceGreaterThanFiveMil = highPrice > 3000000;

  const lowPriceChange = (e) => {
    setPrice([parseInt(e.target.value.replace(/,/g, "").replace(/[^\d.-]/g, "") || 0), parseInt(highPrice.replace(/,/g, "").replace(/[^\d.-]/g, "") || 0)]);
  };

  const highPriceChange = (e) => {
    setPrice([parseInt(lowPrice.replace(/,/g, "").replace(/[^\d.-]/g, "") || 0), parseInt(e.target.value.replace(/,/g, "").replace(/[^\d.-]/g, "") || 0)]);
  };
  useEffect(() => {
    if (pullPrice) {
      setPullPrice(false);
      setNewLowPrice(`$${price[0].toLocaleString()}`);
      setNewHighPrice(`$${price[1].toLocaleString()}`);
    }
    if (sliderFocus) {
      setNewLowPrice(`$${price[0].toLocaleString()}`);
      setNewHighPrice(`$${price[1].toLocaleString()}`);
    }
  }, [price]);

  const handlePriceChange = (e, price) => setPrice(price);

  return (
    <div className={classes.selectPriceContainer}>
      <div className={classes.headerContainer}>
        <h1 className={classes.headerText}>Price</h1>
        <div className={classes.rangesContainer}>
          <CreateReportFilterInput
            type="text"
            size="small"
            label="min"
            variant="filled"
            value={newLowPrice}
            onChange={(e) => {
              lowPriceChange(e);
              setNewLowPrice(e.target.value);
            }}
            onBlur={() => {
              setNewLowPrice(lowPrice);
            }}
          />
          <div className={classes.hyphen}>{"—"}</div>
          <CreateReportFilterInput
            type="text"
            size="small"
            label="max"
            variant="filled"
            value={newHighPrice}
            onBlur={() => {
              setNewHighPrice(highPrice);
            }}
            onChange={(e) => {
              highPriceChange(e);
              setNewHighPrice(e.target.value);
            }}
          />
        </div>
      </div>
      <div className={classes.sliderContainer}>
        <CustomSlider
          onFocus={() => {
            setSliderFous(true);
          }}
          onBlur={() => {
            setSliderFous(false);
          }}
          value={price}
          step={isHighPriceGreaterThanFiveMil ? 100000 : 10000}
          min={0}
          max={price[1] > 3000000 ? price[1] : 3000000}
          onChange={handlePriceChange}
        />
      </div>
    </div>
  );
};

SelectPrice.propTypes = {
  price: PropTypes.array,
  setPrice: PropTypes.func,
  setPullPrice: PropTypes.func,
  pullPrice: PropTypes.bool,
};

export default SelectPrice;
