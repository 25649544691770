const contactSubmit = payload => {
  // const key = "1234";
  // return axios({
  //   method: "POST",
  //   url: "/contactus",
  //   headers: {
  //     Authorization: `Bearer ${key}`
  //   },
  //   data: payload
  // });
};

export default contactSubmit;