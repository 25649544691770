import React, { useState, Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Icon, IconButton } from "@material-ui/core";
import { ExpandLess, ExpandMore, Delete, Visibility, VisibilityOff } from "@material-ui/icons";
import PersonalInfoInput from "../../Common/Inputs/PersonalInfoInput";

const useStyles = makeStyles((theme) =>
  createStyles({
    linkContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "18px",
      width: "100%",
    },
    iconPreviewContainer: {
      background: "#FAFAFA",
      borderRadius: "10px",
      boxShadow: "inset 0px 0px 7px rgba(0, 0, 0, 0.08)",
      padding: "11px 15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      marginRight: "20px",
    },
    previewIcon: {
      fontSize: "24px",
      color: "#333333",
      marginRight: "5px",
    },
    expandIcons: {
      fontSize: "24px",
      color: "#333333",
    },
    iconPickerContainer: {
      position: "absolute",
      background: "#FAFAFA",
      borderRadius: "10px",
      padding: "10px",
      height: "300px",
      width: "252px",
      overflow: "scroll",
      top: "55px",
      left: "0px",
      zIndex: "2",
    },
    icon: {
      fontSize: "24px",
      color: "#333333",
      transition: "0.2s",
      margin: "2px",
    },
    iconContainer: {
      cursor: "pointer",
      transition: "0.2s",
      "&:hover": {
        transition: "0.2s",
        "& $icon": {
          color: theme.palette.areaPulseBlue,
          transition: "0.2s",
        },
      },
    },
    inputs: {
      marginRight: "20px",
    },
    iconButtons: {
      marginRight: "10px",
      background: "#FAFAFA",
    },
    isHidden: {
      opacity: "0.5",
    },
    disabled: {
      cursor: "not-allowed",
    },
    placeholder: {
      opacity: "0.5",
    },
  })
);

const Links = ({ index, icons, icon, action, original, isDefault, isHidden, title, url, links, setLinks }) => {
  const classes = useStyles();
  const [iconPicker, setIconPicker] = useState(false);

  const handleHideLink = () => {
    let newLinks = JSON.parse(JSON.stringify(links));
    if (isDefault) {
      newLinks[index].isHidden = !newLinks[index].isHidden;
      if (newLinks[index].id !== -1) {
        newLinks[index].action = "update";
      } else {
        newLinks[index].action = "create";
      }
      setLinks(newLinks);
    } else if (original) {
      newLinks[index].isHidden = !newLinks[index].isHidden;
      newLinks[index].action = "update";
      setLinks(newLinks);
    } else {
      newLinks[index].isHidden = !newLinks[index].isHidden;
      setLinks(newLinks);
    }
  };

  const handleDeleteLink = () => {
    let newLinks = JSON.parse(JSON.stringify(links));
    if (original) {
      newLinks[index].action = "delete";
      setLinks(newLinks);
    } else {
      const filteredLinks = newLinks.filter((link, i) => {
        if (i !== index) {
          return link;
        } else {
          return null;
        }
      });
      setLinks(filteredLinks);
    }
  };

  const handleIconSelect = (icon, event) => {
    event.stopPropagation();
    let newLinks = JSON.parse(JSON.stringify(links));
    if (isDefault) {
      newLinks[index].icon = icon;
      if (newLinks[index].id !== -1) {
        newLinks[index].action = "update";
      } else {
        newLinks[index].action = "create";
      }
      setLinks(newLinks);
      setIconPicker(false);
    } else if (original) {
      newLinks[index].icon = icon;
      newLinks[index].action = "update";
      setLinks(newLinks);
      setIconPicker(false);
    } else {
      newLinks[index].icon = icon;
      setLinks(newLinks);
      setIconPicker(false);
    }
  };

  let iconMenu = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (iconPicker && iconMenu.current && !iconMenu.current.contains(event.target)) {
        setIconPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [iconPicker]);

  const handleTitleChange = (value) => {
    let newLinks = JSON.parse(JSON.stringify(links));
    if (original) {
      newLinks[index].title = value;
      newLinks[index].action = "update";
      setLinks(newLinks);
    } else {
      newLinks[index].title = value;
      setLinks(newLinks);
    }
  };

  const handleUrlChange = (value) => {
    let newLinks = JSON.parse(JSON.stringify(links));
    if (original) {
      newLinks[index].url = value;
      newLinks[index].action = "update";
      setLinks(newLinks);
    } else {
      newLinks[index].url = value;
      setLinks(newLinks);
    }
  };

  return (
    <Fragment>
      {action !== "delete" && (
        <div className={`${classes.linkContainer} ${isHidden && classes.isHidden}`}>
          <div className={classes.iconPreviewContainer} onClick={() => setIconPicker(!iconPicker)}>
            <Icon color="inherit" fontSize="inherit" className={`${classes.previewIcon} ${icon === "" && classes.placeholder}`}>
              {icon !== "" ? icon : "language"}
            </Icon>
            {iconPicker ? <ExpandLess color="inherit" fontSize="inherit" className={classes.expandIcons} /> : <ExpandMore color="inherit" fontSize="inherit" className={classes.expandIcons} />}
          </div>
          {iconPicker && (
            <div ref={iconMenu} className={classes.iconPickerContainer}>
              {icons.map((icon, i) => {
                return (
                  <span key={i} className={classes.iconContainer} onClick={(event) => handleIconSelect(icon.props.children, event)}>
                    <Icon color="inherit" fontSize="inherit" className={classes.icon}>
                      {icon}
                    </Icon>
                  </span>
                );
              })}
            </div>
          )}
          <PersonalInfoInput
            type="text"
            variant="filled"
            label="Link Title"
            className={`${classes.inputs} ${isDefault && classes.disabled}`}
            disabled={isDefault}
            size="small"
            value={title}
            onChange={(e) => handleTitleChange(e.target.value)}
          />
          {isDefault ? null : (
            <PersonalInfoInput
              type="text"
              variant="filled"
              label="Link Url"
              disabled={isDefault}
              className={`${classes.inputs} ${isDefault && classes.disabled}`}
              size="small"
              value={url}
              onChange={(e) => handleUrlChange(e.target.value)}
            />
          )}

          <IconButton className={classes.iconButtons} onClick={() => handleHideLink()}>
            {isHidden ? <VisibilityOff color="inherit" fontSize="inherit" className={classes.icon} /> : <Visibility color="inherit" fontSize="inherit" className={classes.icon} />}
          </IconButton>
          {!isDefault && (
            <IconButton className={classes.iconButtons} onClick={() => handleDeleteLink()}>
              <Delete color="inherit" fontSize="inherit" className={classes.icon} />
            </IconButton>
          )}
        </div>
      )}
    </Fragment>
  );
};

Links.propTypes = {
  index: PropTypes.number,
  icons: PropTypes.array,
  icon: PropTypes.string,
  action: PropTypes.string,
  original: PropTypes.bool,
  isDefault: PropTypes.bool,
  isHidden: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  links: PropTypes.array,
  setLinks: PropTypes.func,
};

export default Links;
