import { gql } from "@apollo/client";

export const GET_SUBSCRIBED_REPORTS = gql`
  query unsubscribeContact($unsubscribeToken: String!) {
    unsubscribeContact(unsubscribeToken: $unsubscribeToken) {
      reports {
        id
        title
        thumbnailURL
      }
    }
  }
`;
