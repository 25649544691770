import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Radio, CircularProgress, RadioGroup } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) =>
  createStyles({
    topList: {
      transition: "0.5s",
      height: "75px",
      border: "solid",
      borderWidth: "3px 3px 1.5px 3px",
      borderColor: "#DDDDDD",
      borderRadius: "10px 10px 0px 0px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#F7F9FB",
    },

    middleList: {
      height: "75px",
      border: "solid",
      borderColor: "#DDDDDD",
      borderWidth: "1.5px 3px 1.5px 3px",
      transition: "0.5s",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
    },
    bottomList: {
      transition: "0.5s",
      height: "75px",
      border: "solid",
      borderWidth: "1.5px 3px 3px 3px",
      borderColor: "#DDDDDD",
      borderRadius: "0px 0px 10px 10px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
    },
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      margin: "0px 44px 0px 26px",
    },
    icon: {
      borderRadius: "50%",
      width: 30,
      height: 30,
      boxShadow: "inset 0 0 0 3px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: "#f5f8fa",
      backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },

      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: "#137cbd",
      backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 30,
        height: 30,
        backgroundImage: "radial-gradient(#fff,#fff 20%,transparent 32%)",
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#106ba3",
      },
    },
    planType: {
      fontWeight: "800",
      fontFamily: "nunito",
      fontSize: "18px",
      paddingRight: "89px",
    },
    sponsor: {
      fontFamily: "nunito",
      fontSize: "18px",
      fontWeight: "400",
      paddingRight: "118px",
    },
    reports: {
      fontFamily: "nunito",
      fontSize: "18px",
      fontWeight: "400",
      paddingRight: "90px",
    },
    howManyReports: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      paddingRight: "10px",
    },
    numberReports: {
      fontFamily: "nunito",
      fontSize: "16px",
      fontWeight: "400",
    },
    perMonth: {
      fontFamily: "nunito",
      fontSize: "12px",
      fontWeight: "400",
    },
    chosenPlan: {
      color: "#3988F0",
      borderColor: "#3988F0 !important",
      borderWidth: "3px !important",
    },
    current: {
      textAlign: "center",
      width: "100px",
      backgroundColor: "#f2994b",
      transform: "rotate(-46deg)",
      fontWeight: "700",
      color: "#FFFFFF",
      fontSize: "12px",
      height: "26px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0px 45px 26px -29px",
    },
    overflow: {
      overflow: "hidden",
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
  })
);

const BillingPlanList = ({ planList, subscribedPlans, handleNewSelection, isFetching }) => {
  const classes = useStyles();
  const [whichPlan, setWhichPlan] = useState("");
  const handleOnPlanChange = (e) => {
    handleNewSelection(e.target.value);
  };

  if (!isFetching && planList.length === 0) {
    return (
      <div>
        <p>No subscription plans available!!</p>
      </div>
    );
  }

  if (isFetching) {
    return (
      <CircularProgress
        size={60}
        thickness={3}
        color="inherit"
        className={classes.progress}
        classes={{
          circle: classes.circle,
        }}
      />
    );
  }

  const items = planList.map((plan, index) => {
    let rowClass = classes.topList;
    if (index > 0) {
      if (index < planList.length - 1) {
        rowClass = classes.middleList;
      } else {
        rowClass = classes.bottomList;
      }
    }

    const config = JSON.parse(plan.config);

    return (
      <div key={plan.id} className={classes.overflow}>
        <div className={`${whichPlan === plan.id ? classes.chosenPlan : null} ${rowClass}`}>
          {subscribedPlans[plan.id] !== undefined ? (
            <div className={classes.current}>Current</div>
          ) : (
            <Radio
              color="primary"
              value={plan.id}
              onClick={() => {
                setWhichPlan(plan.id);
              }}
              disableRipple
              className={classes.root}
              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
              icon={<span className={classes.icon} />}
            />
          )}
          <div className={classes.planType}>{plan.name}</div>
          {/* <div className={classes.sponsor}></div> */}
          <div className={classes.reports}>{config.market_reports} reports</div>
          <div className={classes.howManyReports}>
            <div className={classes.numberReports}>${plan.price}</div>
            <div className={classes.perMonth}>per month</div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      <RadioGroup onChange={handleOnPlanChange} value={whichPlan}>
        {items}
      </RadioGroup>
    </div>
  );
};

BillingPlanList.defaultProps = {
  planList: [],
  subscribedPlans: {},
  isFetching: false,
};

BillingPlanList.propTypes = {
  planList: PropTypes.array.isRequired,
  subscribedPlans: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleNewSelection: PropTypes.func.isRequired,
};
export default BillingPlanList;
