import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, withStyles, Button, Menu, MenuItem, CircularProgress } from "@material-ui/core";
import MoreHorzOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import SendIcon from "@material-ui/icons/Send";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import DeleteSubscriptionModal from "./DeleteSubscriptionModal";
import { SEND_REPORT } from "../../../Services/Graph/CreateReport/Mutations";
import { useMutation } from "@apollo/client";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { CREATE_NOTE } from "../../../Services/Graph/Contact/Mutations";

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      padding: "14px 20px",
    },
    menuItemIcon: {
      marginRight: "12px",
    },
    circle: {
      color: "black",
      height: "24px !important",
      width: "24px !important",
      marginRight: "12px",
    },
  })
);

const StyledMenu = withStyles({
  paper: {
    backgroundColor: "#f1f1f1",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "center",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "center",
      horizontal: "left",
    }}
    {...props}
  />
));

const ContactSubscribedActions = ({
  setRegrabReportsSent,
  setNoReports,
  reportsRemaining,
  setReportSent,
  reportId,
  contactDetails,
  subscriptionId,
  reportRequestId,
  reportTitle,
  setSubscriptionWasDeleted,
  setCreateReportOpen,
  paidAccount,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalWasClosed, setModalWasClosed] = useState(false);
  const [sendReport, { data: sendData, loading: sendLoading }] = useMutation(SEND_REPORT, {
    variables: {
      contactEmail: contactDetails?.contact?.email?.email,
      reportID: reportId,
      contactFirstName: contactDetails?.contact?.firstName,
      contactLastName: contactDetails?.contact?.lastName,
      contactPhone: contactDetails?.contact?.phone?.number,
      frequency: "once",
    },
    client: authApolloClient,
    onCompleted() {
      addNote();
    },
  });

  const [addNote, { data: noteData, loading: noteLoading, error: noteError }] = useMutation(CREATE_NOTE, {
    variables: {
      id: contactDetails?.contact?.id,
      note: `Sent Report: ${reportTitle}`,
    },
    client: authApolloClient,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditReport = (e, id) => {
    e.preventDefault();
    setCreateReportOpen({ open: true, editing: true, editPayload: id });
    setAnchorEl(null);
  };

  useEffect(() => {
    if (modalWasClosed) {
      handleClose();
      setModalWasClosed(false);
    }
  }, [modalWasClosed]);

  useEffect(() => {
    if (sendData) {
      setRegrabReportsSent(true);
      setReportSent(true);
      handleClose();
    }
  }, [sendData]);

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreHorzOutlinedIcon />
      </Button>
      <StyledMenu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className={classes.dropDown}>
        <MenuItem onClick={(e) => handleEditReport(e, reportId)} className={classes.menuItem}>
          <EditIcon className={classes.menuItemIcon} />
          Edit Report
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            if (reportsRemaining > 0 || reportsRemaining === -1 || !paidAccount) {
              if (!sendLoading) {
                sendReport();
              }
            } else {
              setNoReports(true);
            }
          }}
        >
          {sendLoading ? <CircularProgress className={classes.circle} /> : <SendIcon className={classes.menuItemIcon} />}
          Send Report
        </MenuItem>
        <DeleteSubscriptionModal subscriptionId={subscriptionId} setModalWasClosed={setModalWasClosed} setSubscriptionWasDeleted={setSubscriptionWasDeleted} />
      </StyledMenu>
    </div>
  );
};

ContactSubscribedActions.propTypes = {
  setNoReports: PropTypes.func,
  setReportSent: PropTypes.func,
  reportsRemaining: PropTypes.number,
  reportId: PropTypes.number,
  contactDetails: PropTypes.object,
  reportRequestId: PropTypes.number,
  reportTitle: PropTypes.string,
  subscriptionId: PropTypes.number,
  setSubscriptionWasDeleted: PropTypes.func,
  setCreateReportOpen: PropTypes.func,
  setRegrabReportsSent: PropTypes.func,
  paidAccount: PropTypes.bool,
};

export default ContactSubscribedActions;
