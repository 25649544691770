import { gql } from "@apollo/client";

export const GET_CONTACT = gql`
  query GetContact($id: Int!) {
    contact(id: $id) {
      id
      address {
        city
        country
        state
        street
        zip
      }
      email {
        email
      }
      firstName
      lastName
      notes {
        createdAt
        id
        note
      }
      phone {
        primary
        number
      }
      reportEmailStats {
        lastReportSent
        opened
        sent
      }
      reportSubscriptions {
        createdAt
        frequency
        id
        lastQueuedAt
        lastSentAt
        opened
        personId
        receiveEmail
        reportRequestId
        saved
        sent
        title
      }
    }
  }
`;

export const GET_MARKET_REPORTS = gql`
  query GetReports($page: Int, $pageSize: Int, $sortField: MarketReportSortFields, $sortDirection: SortDirection) {
    reports(pageSize: $pageSize, page: $page, sortField: $sortField, sortDirection: $sortDirection) {
      dateCreated
      dateRun
      title
      id
      subscriberCount
      thumbnailURL
      url
    }
  }
`;

export const SEARCH_REPORTS_AUTOCOMPLETE = gql`
  query SearchReports($userId: Int!, $term: String!, $limit: Int) {
    searchReports(userId: $userId, term: $term, limit: $limit) {
      rows {
        id
        label
        reportID
      }
    }
  }
`;

export const GET_SUBSCRIPTION_ACTIVITY = gql`
  query GetSubscriptionActivity($subscriptionId: Int!) {
    subscriptionActivity(subscriptionId: $subscriptionId) {
      createdAt
      fromPersonId
      id
      message
      openedAt
      subject
      subscriptionId
      toPersonId
    }
  }
`;
