import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, withStyles, Button, CircularProgress } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { openApolloClient } from "../../Services/Graph/apolloConfig";
import { Clear } from "@material-ui/icons";
import { UNSUBSCRIBE_FROM_REPORTS } from "../../Services/Graph/UnsubscribePage/Mutations";

const useStyles = makeStyles((theme) =>
  createStyles({
    areYouSureModalContainer: {
      position: "fixed",
      height: "0%",
      width: "100%",
      top: "0px",
      left: "0px",
      background: "rgba(0, 0, 0, 0.7)",
      zIndex: "10",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    },
    modalOpen: {
      height: "100%",
    },
    areYouSureModalContentContainer: {
      borderRadius: "20px",
      background: "#FFFFFF",
      padding: "0px 30px 30px 30px",
      maxWidth: "767px",
      height: "439px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    areYouSureText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "800",
      fontSize: "24px",
      color: "#000",
      textTransform: "uppercase",
      margin: "41px 0px 12px",
    },
    areYouSureSubText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "400",
      fontSize: "20px",
      color: "#000",
      margin: "0px 0px 77px",
      textAlign: "center",
      maxWidth: "363px",
    },
    subText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "18px",
      color: "#000",
      margin: "10px 0px 0px",
      textAlign: "center",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    loader: {
      width: "22px !important",
      height: "22px !important",
      color: " #FFFFFF",
      marginLeft: "10px",
    },
    clearDiv: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
    },
    clear: {
      marginTop: "35px",
      marginRight: "3px",
      cursor: "pointer",
    },
    loaderButton: {
      padding: "5px 15px !important",
    },
  })
);

const CloseButton = withStyles((theme) => ({
  root: {
    width: "190px",
    height: "50px",
    color: "#fff",
    marginBottom: "6px",
    background: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 30px",
    borderRadius: "10px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      opacity: "0.8",
    },
  },
}))(Button);

const CancelButton = withStyles((theme) => ({
  root: {
    color: "#000000",
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 10px",
    borderRadius: "7px",
    transition: "0.2s",
  },
}))(Button);

const UnsubscribeConfirm = ({ token, setRegrabReports, sendIDs, deleteLoading, reportUp, setReportUp }) => {
  const classes = useStyles();

  const handleClose = () => {
    unsubscribe();
  };

  const handleCancel = () => {
    setReportUp(false);
  };

  const [unsubscribe, { data: reportData, loading: reportLoading }] = useMutation(UNSUBSCRIBE_FROM_REPORTS, {
    variables: {
      unsubscribeToken: token,
      reportIDs: sendIDs,
    },
    client: openApolloClient,
  });

  useEffect(() => {
    if (reportData) {
      setReportUp(false);
      setRegrabReports(true);
    }
  }, [reportData]);

  return (
    <div className={`${classes.areYouSureModalContainer} ${reportUp && classes.modalOpen}`} onClick={() => handleCancel()}>
      <div className={classes.areYouSureModalContentContainer} onClick={(e) => e.stopPropagation()}>
        <div className={classes.clearDiv}>
          <Clear
            className={classes.clear}
            onClick={() => {
              setReportUp(false);
            }}
          />
        </div>
        <div className={classes.areYouSureText}>Are You Sure?</div>
        <div className={classes.areYouSureSubText}>You will no longer receive updates from this market report.</div>
        <div className={classes.buttonContainer}>
          <CloseButton className={reportLoading ? classes.loaderButton : null} disabled={deleteLoading} onClick={() => handleClose()}>
            UNSUBSCRIBE
            {reportLoading ? <CircularProgress className={classes.loader} /> : null}
          </CloseButton>
          <CancelButton onClick={() => handleCancel()}>NEVERMIND</CancelButton>
        </div>
      </div>
    </div>
  );
};

UnsubscribeConfirm.propTypes = {
  setReportUp: PropTypes.func,
  setRegrabReports: PropTypes.func,
  reportUp: PropTypes.bool,
  sendIDs: PropTypes.array,
  token: PropTypes.string,
};

export default UnsubscribeConfirm;
