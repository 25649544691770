import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import UserSettingsComponent from "../Components/UserSettings/UserSettingsComponent";
import CreateReport from "../Components/CreateReport/CreateReport";

const useStyles = makeStyles((theme) =>
  createStyles({
    userSettingsViewContainer: {
      position: "absolute",
      left: "0",
      right: "0",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      backgroundColor: "#fff",
    },
  })
);

const UserSettingsView = ({ createReportOpen, setCreateReportOpen, match, googleScriptLoaded }) => {
  const classes = useStyles();

  return (
    <div className={classes.userSettingsViewContainer}>
      {createReportOpen.open && <CreateReport googleScriptLoaded={googleScriptLoaded} createReportOpen={createReportOpen} setCreateReportOpen={(status) => setCreateReportOpen(status)} />}
      <UserSettingsComponent />
    </div>
  );
};

UserSettingsView.propTypes = {
  createReportOpen: PropTypes.object,
  setCreateReportOpen: PropTypes.func,
  match: PropTypes.object,
  googleScriptLoaded: PropTypes.bool,
};

export default UserSettingsView;
