import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import PersonalInfoInput from "../../Common/Inputs/PersonalInfoInput";
import PersonalInfoSelect from "../../Common/Inputs/PersonalInfoSelect";
import { createStyles, makeStyles, Grid, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    inputs: {
      marginBottom: "20px",
    },
    paddingLeft: {
      paddingLeft: "10px",
    },
    paddingRight: {
      paddingRight: "10px",
    },
  })
);

const PersonalInfoInputs = ({ setNewData, data, personalInfo, sendCollectedInfo, savingUserPrefs, setSavingUserPrefs }) => {
  const classes = useStyles();
  const formSubmitInputRef = useRef(null);
  const [stateSelect, setStateSelect] = useState(data?.user?.reportSettings?.address?.state);
  const [stateSelectError, setStateSelectError] = useState({ status: false, message: "" });

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      firstname: data?.user?.reportSettings?.firstName,
      lastname: data?.user?.reportSettings?.lastName,
      title: data?.user?.reportSettings?.title ?? "",
      phone: data?.user?.reportSettings?.phone?.number ?? "",
      email: data?.user?.reportSettings?.email?.email ?? "",
      license: data?.user?.reportSettings?.licenseId ?? "",
      street: data?.user?.reportSettings?.address?.street ?? "",
      zip: data?.user?.reportSettings?.address?.zip ?? "",
      city: data?.user?.reportSettings?.address?.city ?? "",
    },
  });

  const submit = (data) => {
    setNewData(true);
    sendCollectedInfo({ ...data, state: stateSelect });
  };

  useEffect(() => {
    if (savingUserPrefs) {
      formSubmitInputRef.current.click();
      setStateSelectError({ status: false, message: "" });
      setSavingUserPrefs(false);
    }
  }, [savingUserPrefs, personalInfo]);

  useEffect(() => {
    if (Object.keys(errors).length > 0 && savingUserPrefs) {
      setSavingUserPrefs(false);
    }
  }, [errors, setSavingUserPrefs, savingUserPrefs]);

  return (
    <form id="personal-info-form" onSubmit={handleSubmit(submit)}>
      <Grid container>
        <Grid item xs={4} className={classes.paddingRight}>
          <PersonalInfoInput
            type="text"
            variant="filled"
            label="First Name"
            className={classes.inputs}
            error={Boolean(errors.firstname)}
            helperText={errors.firstname?.message}
            InputProps={{
              name: "firstname",
            }}
            inputRef={register()}
          />
        </Grid>
        <Grid item xs={4} className={`${classes.paddingLeft} ${classes.paddingRight}`}>
          <PersonalInfoInput
            type="text"
            variant="filled"
            label="Last Name"
            className={classes.inputs}
            error={Boolean(errors.lastname)}
            helperText={errors.lastname?.message}
            InputProps={{
              name: "lastname",
            }}
            inputRef={register()}
          />
        </Grid>
        <Grid item xs={4} className={classes.paddingLeft}>
          <PersonalInfoInput
            type="text"
            variant="filled"
            label="License ID"
            className={classes.inputs}
            error={Boolean(errors.license)}
            helperText={errors.license?.message}
            InputProps={{
              name: "license",
            }}
            inputRef={register()}
          />
        </Grid>
        <Grid item xs={6} className={classes.paddingRight}>
          <PersonalInfoInput
            type="text"
            variant="filled"
            label="Title"
            className={classes.inputs}
            helperText={errors.title?.message}
            InputProps={{
              name: "title",
            }}
            inputRef={register()}
          />
        </Grid>
        <Grid item xs={6} className={classes.paddingLeft}>
          <PersonalInfoInput
            type="text"
            variant="filled"
            label="Email"
            className={classes.inputs}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            InputProps={{
              name: "email",
            }}
            inputRef={register({
              validate: (value) => value !== "" || "Please enter your email address",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Please Enter A Valid Email Address",
              },
            })}
          />
        </Grid>
        <Grid item xs={6} className={classes.paddingRight}>
          <PersonalInfoInput
            type="text"
            variant="filled"
            label="Phone"
            className={classes.inputs}
            helperText={errors.phone?.message}
            InputProps={{
              name: "phone",
            }}
            inputRef={register()}
          />
        </Grid>
        <Grid item xs={6} className={classes.paddingLeft}>
          <PersonalInfoInput
            type="text"
            variant="filled"
            label="Street"
            className={classes.inputs}
            helperText={errors.street?.message}
            InputProps={{
              name: "street",
            }}
            inputRef={register()}
          />
        </Grid>
        <Grid item xs={4} className={classes.paddingRight}>
          <PersonalInfoInput
            type="text"
            variant="filled"
            label="City"
            className={classes.inputs}
            helperText={errors.city?.message}
            InputProps={{
              name: "city",
            }}
            inputRef={register()}
          />
        </Grid>
        <Grid item xs={4} className={`${classes.paddingLeft} ${classes.paddingRight}`}>
          <PersonalInfoSelect
            select
            label="State"
            variant="filled"
            className={classes.inputs}
            helperText={stateSelectError.message}
            value={stateSelect}
            onChange={(e) => setStateSelect(e.target.value)}
          >
            <MenuItem value={"AL"}>Alabama</MenuItem>
            <MenuItem value={"AK"}>Alaska</MenuItem>
            <MenuItem value={"AZ"}>Arizona</MenuItem>
            <MenuItem value={"AR"}>Arkansas</MenuItem>
            <MenuItem value={"CA"}>California</MenuItem>
            <MenuItem value={"CO"}>Colorado</MenuItem>
            <MenuItem value={"CT"}>Connecticut</MenuItem>
            <MenuItem value={"DE"}>Delaware</MenuItem>
            <MenuItem value={"FL"}>Florida</MenuItem>
            <MenuItem value={"GA"}>Georgia</MenuItem>
            <MenuItem value={"HI"}>Hawaii</MenuItem>
            <MenuItem value={"ID"}>Idaho</MenuItem>
            <MenuItem value={"IL"}>Illinois</MenuItem>
            <MenuItem value={"IN"}>Indiana</MenuItem>
            <MenuItem value={"IA"}>Iowa</MenuItem>
            <MenuItem value={"KS"}>Kansas</MenuItem>
            <MenuItem value={"KY"}>Kentucky</MenuItem>
            <MenuItem value={"LA"}>Louisiana</MenuItem>
            <MenuItem value={"ME"}>Maine</MenuItem>
            <MenuItem value={"MD"}>Maryland</MenuItem>
            <MenuItem value={"MA"}>Massachusetts</MenuItem>
            <MenuItem value={"MI"}>Michigan</MenuItem>
            <MenuItem value={"MN"}>Minnesota</MenuItem>
            <MenuItem value={"MS"}>Mississippi</MenuItem>
            <MenuItem value={"MO"}>Missouri</MenuItem>
            <MenuItem value={"MT"}>Montana</MenuItem>
            <MenuItem value={"NE"}>Nebraska</MenuItem>
            <MenuItem value={"NV"}>Nevada</MenuItem>
            <MenuItem value={"NH"}>New Hampshire</MenuItem>
            <MenuItem value={"NJ"}>New Jersey</MenuItem>
            <MenuItem value={"NM"}>New Mexico</MenuItem>
            <MenuItem value={"NY"}>New York</MenuItem>
            <MenuItem value={"NC"}>North Carolina</MenuItem>
            <MenuItem value={"ND"}>North Dakota</MenuItem>
            <MenuItem value={"OH"}>Ohio</MenuItem>
            <MenuItem value={"OK"}>Oklahoma</MenuItem>
            <MenuItem value={"OR"}>Oregon</MenuItem>
            <MenuItem value={"PA"}>Pennsylvania</MenuItem>
            <MenuItem value={"RI"}>Rhode Island</MenuItem>
            <MenuItem value={"SC"}>South Carolina</MenuItem>
            <MenuItem value={"SD"}>South Dakota</MenuItem>
            <MenuItem value={"TN"}>Tennessee</MenuItem>
            <MenuItem value={"TX"}>Texas</MenuItem>
            <MenuItem value={"UT"}>Utah</MenuItem>
            <MenuItem value={"VT"}>Vermont</MenuItem>
            <MenuItem value={"VA"}>Virginia</MenuItem>
            <MenuItem value={"WA"}>Washington</MenuItem>
            <MenuItem value={"WV"}>West Virginia</MenuItem>
            <MenuItem value={"WI"}>Wisconson</MenuItem>
            <MenuItem value={"WY"}>Wyoming</MenuItem>
          </PersonalInfoSelect>
        </Grid>
        <Grid item xs={4} className={`${classes.paddingLeft}`}>
          <PersonalInfoInput
            type="text"
            variant="filled"
            label="Zip"
            className={classes.inputs}
            helperText={errors.zip?.message}
            InputProps={{
              name: "zip",
            }}
            inputRef={register()}
          />
        </Grid>
      </Grid>
      <input ref={formSubmitInputRef} type="submit" style={{ display: "none" }} />
    </form>
  );
};

PersonalInfoInputs.propTypes = {
  data: PropTypes.object,
  savingUserPrefs: PropTypes.bool,
  sendCollectedInfo: PropTypes.func,
  setNewData: PropTypes.func,
};

export default PersonalInfoInputs;
