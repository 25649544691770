import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles, withStyles, MenuItem, Grid, TextField, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    topList: {
      transition: "0.5s",
      height: "75px",
      border: "solid",
      borderWidth: "3px 3px 1.5px 3px",
      borderColor: "#DDDDDD",
      borderRadius: "10px 10px 0px 0px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#F7F9FB",
    },

    middleList: {
      height: "75px",
      border: "solid",
      borderColor: "#DDDDDD",
      borderWidth: "1.5px 3px 1.5px 3px",
      transition: "0.5s",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
    },
    bottomList: {
      transition: "0.5s",
      height: "75px",
      border: "solid",
      borderWidth: "1.5px 3px 3px 3px",
      borderColor: "#DDDDDD",
      borderRadius: "0px 0px 10px 10px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
    },
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      margin: "0px 44px 0px 26px",
    },
    icon: {
      borderRadius: "50%",
      width: 30,
      height: 30,
      boxShadow: "inset 0 0 0 3px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: "#f5f8fa",
      backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },

      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: "#137cbd",
      backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 30,
        height: 30,
        backgroundImage: "radial-gradient(#fff,#fff 20%,transparent 32%)",
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#106ba3",
      },
    },
    planType: {
      fontWeight: "800",
      fontFamily: "nunito",
      fontSize: "18px",
      paddingRight: "89px",
    },
    sponsor: {
      fontFamily: "nunito",
      fontSize: "18px",
      fontWeight: "400",
      paddingRight: "118px",
    },
    reports: {
      fontFamily: "nunito",
      fontSize: "18px",
      fontWeight: "400",
      paddingRight: "90px",
    },
    howManyReports: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      paddingRight: "10px",
    },
    numberReports: {
      fontFamily: "nunito",
      fontSize: "16px",
      fontWeight: "400",
    },
    perMonth: {
      fontFamily: "nunito",
      fontSize: "12px",
      fontWeight: "400",
    },
    chosenPlan: {
      color: "#3988F0",
      borderColor: "#3988F0 !important",
      borderWidth: "3px !important",
    },
    current: {
      textAlign: "center",
      width: "100px",
      backgroundColor: "#f2994b",
      transform: "rotate(-46deg)",
      fontWeight: "700",
      color: "#FFFFFF",
      fontSize: "12px",
      height: "26px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0px 45px 26px -29px",
    },
    overflow: {
      overflow: "hidden",
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
  })
);

const PaymentSelect = withStyles((theme) => ({
  root: {
    width: "100%",
    "& label": {
      color: "#BDBDBD",
      fontWeight: "500",
      fontFamily: "nunito",
    },
    "& label.Mui-focused": {
      color: theme.palette.areaPulseBlue,
      fontWeight: "500",
      fontFamily: "nunito",
    },
    "& .MuiFilledInput-root": {
      color: "#000",
      background: "#fff",
      borderRadius: "4px",
      fontWeight: "400",
      fontSize: "1rem",
      border: "#bcbcbc 1px solid",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
      "&.MuiFilledInput-underline:after": {
        display: "none",
      },
      "& .MuiSelect-filled": {
        padding: "17.5px 14px",
      },
    },
  },
}))(TextField);

const BillingPaymentSelect = ({ paymentMethodsList, selectedPayment, isPaymentListFetching, handlePaymentChange }) => {
  const classes = useStyles();
  const [payments, setPayments] = useState(null);

  useEffect(() => {
    const tempPayments = paymentMethodsList.map((payment) => {
      return <MenuItem key={payment.id} value={payment.id}>{`${payment.brand} ${payment.last4}`}</MenuItem>;
    });
    setPayments(tempPayments);
  }, [paymentMethodsList]);

  if (!isPaymentListFetching && paymentMethodsList.length === 0) {
    return (
      <div>
        <p>Show add payment form here</p>
      </div>
    );
  }

  if (isPaymentListFetching) {
    return (
      <CircularProgress
        size={60}
        thickness={3}
        color="inherit"
        className={classes.progress}
        classes={{
          circle: classes.circle,
        }}
      />
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12}>
        {payments ? (
          <PaymentSelect select variant="filled" className={classes.stateSelect} required value={selectedPayment} onChange={(e) => handlePaymentChange(e.target.value)}>
            {payments}
          </PaymentSelect>
        ) : null}
      </Grid>
    </Grid>
  );
};

BillingPaymentSelect.defaultProps = {
  paymentMethodsList: [],
  selectedPayment: "",
  isPaymentListFetching: false,
};

BillingPaymentSelect.propTypes = {
  paymentMethodsList: PropTypes.array.isRequired,
  selectedPayment: PropTypes.string.isRequired,
  isPaymentListFetching: PropTypes.bool.isRequired,
  handlePaymentChange: PropTypes.func.isRequired,
};
export default BillingPaymentSelect;
