import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    columnHeader: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#818181",
      backgroundColor: "#F5F5F5",
      padding: "16px 10px",
    },
    columnHeaderCenter: {
      textAlign: "center",
    },
  })
);

const ContactsTableHeaders = ({ order, orderBy, handleRequestSort }) => {
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const headCells = [
    { id: "firstName", label: "Name" },
    { id: "email", label: "Email" },
    { id: "reportEmailStats.lastReportSent", label: "Last Report Sent" },
    { id: "reportEmailStats.sent", label: "# Reports Sent" },
    { id: "reportEmailStats.opened", label: "# Reports Open" },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          const labelIsLastReportSent = headCell.label === "Last Report Sent";
          const labelIsReportsSent = headCell.label === "# Reports Sent";
          const labelIsReportsOpened = headCell.label === "# Reports Open";
          return (
            <TableCell key={i} className={`${classes.columnHeader} ${(labelIsReportsSent || labelIsReportsOpened) && classes.columnHeaderCenter}`}>
              {!labelIsLastReportSent && !labelIsReportsSent && !labelIsReportsOpened ? (
                <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                </TableSortLabel>
              ) : (
                <Fragment>{headCell.label}</Fragment>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

ContactsTableHeaders.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  handleRequestSort: PropTypes.func,
};

export default ContactsTableHeaders;
