import React from "react";
import { makeStyles, createStyles, Button, withStyles, Backdrop } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    createReportBackdrop: {
      height: "100%",
      width: "100%",
      padding: "117px 100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "1000",
    },
    createReportContentContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      width: "500px",
      height: "100%",
      maxWidth: "1600px",
      maxHeight: "250px",
      zIndex: "10",
      background: "#fff",
      borderRadius: "20px",
      overflow: "hidden",
      padding: "30px",
    },
    reset: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "25px",
      marginBottom: "26px",
    },
    subtitle: {
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "18px",
    },
    error: {
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "16px",
      color: "red",
    },
    buttons: {
      marginTop: "15px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    circle: {
      width: "26px !important",
      height: "26px !important",
      color: "white",
      marginLeft: "8px",
    },
    padding: {
      padding: "5px 10px 5px 23px !important",
    },
    content: {
      fontFamily: "nunito",
      marginTop: "30px",
      fontSize: "18px",
      fontWeight: "700",
      textAlign: "center",
      marginBottom: "60px",
    },
    sent: {
      marginTop: "20px",
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "18px",
    },
    please: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "16px",
      marginTop: "26px",
      width: "370px",
      textAlign: "center",
    },
    success: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    emailErrors: {
      margin: "5px 0px 8px !important",
    },
  })
);

const CloseButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.areaPulseBlue,
    color: "#ffffff",
    fontSize: "20px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "8px 25px",
    borderRadius: "7px",
    transition: "0.2s",
    textTransform: "lowercase",
    marginRight: "5px",
  },
}))(Button);

const LoginErrorModal = ({ loginErrorModal, setLoginErrorModal }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.createReportBackdrop} open={loginErrorModal}>
      <div className={classes.createReportContentContainer}>
        <div className={classes.content}>
          There was a problem logging you in. <br /> Please try again
        </div>
        <CloseButton
          onClick={() => {
            setLoginErrorModal(false);
          }}
        >
          Close
        </CloseButton>
      </div>
    </Backdrop>
  );
};
LoginErrorModal.propTypes = {
  setLoginErrorModal: PropTypes.func,
  loginErrorModal: PropTypes.bool,
};
export default LoginErrorModal;
