import React, { useState } from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles } from "@material-ui/core";
import ContactsHeader from "./ContactsHeader/ContactsHeader";
import ContactsTable from "./ContactsTable/ContactsTable";

const useStyles = makeStyles((theme) =>
  createStyles({
    contactsContainer: {
      width: "100%",
      height: "100%",
      padding: "60px 80px 60px 180px",
    },
  })
);

const ContactsComponent = ({ contactId, user, createContactOpen, setCreateContactOpen }) => {
  const classes = useStyles();

  return (
    <div className={classes.contactsContainer}>
      <ContactsHeader createContactOpen={createContactOpen} setCreateContactOpen={(status) => setCreateContactOpen(status)} />
      <ContactsTable user={user} contactId={contactId} />
    </div>
  );
};

ContactsComponent.propTypes = {
  contactId: PropTypes.number,
  user: PropTypes.string,
  createContactOpen: PropTypes.object,
  setCreateContactOpen: PropTypes.func,
};

export default ContactsComponent;
