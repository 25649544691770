import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles, createStyles, makeStyles, Grid, Button, Fade } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { AppStateContext } from "../../../../../Context/AppReducer";

const useStyles = makeStyles((theme) =>
  createStyles({
    onBoarding: {
      top: "0",
      padding: "235px 0px 0px 120px",

      position: "absolute",
    },
    onBoardingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
    },
    viewContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    viewHeaders: {
      fontFamily: "Nunito",
      fontWeight: "400",
      fontSize: "21px",
      color: "#000000",
    },
    goto: {
      marginBottom: "50px",
      alignItems: "center",
      fontFamily: "Nunito",
      fontWeight: "700",
      fontSize: "18px",
      color: "#A3A3A3",
      marginTop: "60px",
      display: "flex",
      flexDirection: "row",
    },
    arrow: {
      color: "#FFFFFF",
      height: "30px",
      width: "30px",
    },
    arrowCircle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#A3A3A3",
      height: "39px",
      width: "39px",
      marginLeft: "10px",
      borderRadius: "50px",
    },
    atag: {
      textDecoration: "none",
      marginBottom: "90px",
      marginTop: "120px",
    },
    "@media (max-width: 960px)": {
      onBoarding: {
        padding: "235px 40px 0px 50px",
      },
      atag: {
        marginBottom: "40px",
      },
    },
  })
);

const CloseButton = withStyles((theme) => ({
  root: {
    textTransform: "none",
    maxWidth: "433px",
    width: "100%",
    minHeight: "80px",
    color: "#FFFFFF",
    background: "#2D9CDB",
    fontSize: "28px",
    fontFamily: "nunito",
    fontWeight: "700",
    padding: "17px 30px",
    borderRadius: "10px",
    transition: "0.2s",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      background: "#2D9CDB",
      transition: "0.2s",
      opacity: "0.8",
    },
    "@media (max-width: 960px)": {
      fontSize: "20px",
      padding: "8px 16px",
    },
  },
}))(Button);
const GoButton = withStyles((theme) => ({
  root: {
    minWidth: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#A3A3A3",
    height: "39px",
    width: "39px",
    marginLeft: "10px",
    borderRadius: "50px",
    "&:hover": {
      background: "#A3A3A3",
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const FinishOnBoarding = ({ activeView, reportId, email }) => {
  const classes = useStyles();
  const baseURL = window.location.host;
  const globalState = useContext(AppStateContext);
  return (
    <Fade in={activeView === 6} timeout={1500} className={classes.onBoarding}>
      <Grid container className={classes.onBoardingContainer}>
        <div className={classes.viewContainer}>
          <a className={classes.atag} href={`https://${baseURL}/${globalState?.user?.username}/user-preferences`}>
            <CloseButton>View Your Market Report</CloseButton>
          </a>
          <div className={classes.viewHeaders}>
            Check your Email ({email ?? "email@email.com"})
            <br /> to see what your clients receive
          </div>
          <a style={{ textDecoration: "none" }} href={`https://${baseURL}/${globalState?.user?.username}/dashboard`}>
            <div className={classes.goto}>
              Skip to Dashboard
              <GoButton>
                <ArrowForwardIcon className={classes.arrow} />
              </GoButton>
            </div>
          </a>
        </div>
      </Grid>
    </Fade>
  );
};

FinishOnBoarding.propTypes = {
  email: PropTypes.string,
  reportId: PropTypes.number,
  activeView: PropTypes.number,
};

export default FinishOnBoarding;
