import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Button, withStyles, Backdrop, TextField, CircularProgress } from "@material-ui/core";
import usePasswordReset from "../../../../Services/Rest/passwordResetHook";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    createReportBackdrop: {
      height: "100%",
      width: "100%",
      padding: "117px 100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "1000",
    },
    createReportContentContainer: {
      position: "relative",
      width: "593px",
      height: "100%",
      maxWidth: "1600px",
      maxHeight: "290px",
      zIndex: "10",
      background: "#fff",
      borderRadius: "20px",
      overflow: "hidden",
      padding: "30px",
    },
    reset: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "25px",
      marginBottom: "26px",
    },
    subtitle: {
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "18px",
    },
    error: {
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "16px",
      color: "red",
    },
    buttons: {
      marginTop: "15px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    circle: {
      width: "32px !important",
      height: "32px !important",
      margin: "0 8px",
      color: "#fff",
    },
    padding: {
      padding: "5px 10px 5px 23px !important",
    },
    sent: {
      marginTop: "20px",
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "18px",
    },
    please: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "16px",
      marginTop: "26px",
      width: "370px",
      textAlign: "center",
    },
    success: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    emails: {
      margin: "5px 0px 30px !important",
    },
    emailErrors: {
      margin: "5px 0px 8px !important",
    },
  })
);

const SubmitButton = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    background: theme.palette.areaPulseBlue,
    fontSize: "18px",
    fontFamily: theme.typography.secondary,
    fontWeight: "800",
    padding: "5px 23px",
    borderRadius: "9px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const CloseButton = withStyles((theme) => ({
  root: {
    color: "#C6C6C6",
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 10px",
    borderRadius: "7px",
    transition: "0.2s",
    textTransform: "lowercase",
    marginRight: "5px",
  },
}))(Button);

const SecondCloseButton = withStyles((theme) => ({
  root: {
    background: theme.palette.areaPulseBlue,
    color: "#fff",
    fontSize: "20px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "10px 50px",
    borderRadius: "7px",
    marginTop: "50px",
    transition: "0.2s",
    textTransform: "none",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const ReportContactInput = withStyles({
  root: {
    margin: "5px 0px 15px",
    width: "300px",
    height: "50px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    "& label": {
      paddingTop: "18px",
      color: "#BDBDBD",
      paddingLeft: "40px",
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "24px",
    },
    "& label.Mui-focused": {
      color: "#777777",
      paddingLeft: "40px",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: "nunito",
      color: "#000",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",

      "&.MuiOutlinedInput-underline:before": {
        display: "none",
      },
      "&.MuiOutlinedInput-underline:after": {
        display: "none",
      },
    },
    "& .MuiOutlinedInput-input ": {
      padding: "18px 12px 13px",
    },
  },
})(TextField);

const ForgotPassword = ({ forgotOpen, setForgotOpen, forgotValue }) => {
  const classes = useStyles();
  const [emailValue, setEmailValue] = useState("");
  const [emailChanged, setEmailChanged] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [email, validateEmail] = usePasswordReset();
  const [successReset, setSuccessReset] = useState(false);

  useEffect(() => {
    if (forgotValue !== "" && !emailChanged) {
      setEmailValue(forgotValue);
      setEmailChanged(true);
    }
  });

  useEffect(() => {
    if (submitAttempted) {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailValue)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
  }, [emailValue]);

  useEffect(() => {
    if (email?.isSuccessful) {
      setSuccessReset(true);
    }
  }, [email?.isSuccessful]);

  const submitEmail = () => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailValue)) {
      setEmailError(false);
      validateEmail(emailValue.toLowerCase());
    } else {
      setEmailError(true);
    }
  };

  const errorFunc = () => {
    let x = false;
    if (email?.errorMessage) {
      x = true;
    }
    return x;
  };

  return (
    <Backdrop className={classes.createReportBackdrop} open={forgotOpen}>
      <div className={classes.createReportContentContainer}>
        {!successReset ? (
          <div>
            <div className={classes.reset}>RESET PASSWORD</div>
            <div className={classes.subtitle}>Send password reset link to email:</div>
            <ReportContactInput
              className={`${emailError || email?.errorMessage ? classes.emailErrors : classes.emails}`}
              onChange={(e) => {
                setEmailValue(e.target.value);
              }}
              value={emailValue}
              variant="outlined"
              underline="none"
              error={emailError || errorFunc()}
            />
            {emailError ? <div className={classes.error}>Please enter a valid email</div> : null}
            {email?.errorMessage ? (
              <div className={classes.error}>{email?.errorMessage === "Email does not exist in database" ? "Email does not exist" : "There was a problem, please try again"}</div>
            ) : null}

            <div className={classes.buttons}>
              <CloseButton
                onClick={() => {
                  setForgotOpen(false);
                  setSubmitAttempted(false);
                }}
              >
                Cancel
              </CloseButton>
              <SubmitButton
                className={email?.isFetching ? classes.padding : null}
                disabled={email.isFetching}
                onClick={() => {
                  submitEmail();
                  setSubmitAttempted(true);
                }}
              >
                {email?.isFetching ? <CircularProgress className={classes.circle} /> : "Send"}
              </SubmitButton>
            </div>
          </div>
        ) : (
          <div className={classes.success}>
            <div className={classes.sent}>Your Password Reset Was Sent!</div>
            <div className={classes.please}>Please check your inbox and follow instructions to reset your password.</div>
            <SecondCloseButton
              onClick={() => {
                setForgotOpen(false);
                setSubmitAttempted(false);
                setTimeout(() => {
                  setSuccessReset(false);
                }, 1000);
              }}
            >
              Close
            </SecondCloseButton>
          </div>
        )}
      </div>
    </Backdrop>
  );
};
ForgotPassword.propTypes = {
  forgotValue: PropTypes.string,
  setForgotOpen: PropTypes.func,
  forgotOpen: PropTypes.bool,
};
export default ForgotPassword;
