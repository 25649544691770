import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    dashboardHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "0.5px solid #DEDEDE",
      padding: "0px 0px 5px 0px",
    },
    dashboardHeaderName: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "36px",
      color: "#4E4E4E",
      margin: "0px",
    },
  })
);

const DashboardHeader = ({}) => {
  const classes = useStyles();

  return (
    <div className={classes.dashboardHeaderContainer}>
      <div className={classes.dashboardHeaderName}>Clients</div>
    </div>
  );
};

DashboardHeader.propTypes = {};

export default DashboardHeader;
