import React from "react";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { createStyles, makeStyles, withStyles, Button, Grid } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "5px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    paper: {
      margin: "15px",
      padding: "30px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
    },
    contactHeaderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "30px",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    areYouSureSubText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    deleteContactModalTextContainer: {
      display: "flex",
      justifyContent: "center",
    },
    modalActions: {
      display: "flex",
      justifyContent: "center",
      marginTop: "40px",
      marginBottom: "35px",
    },
    personalDetailsForm: {
      width: "100%",
    },
    menuItem: {
      padding: "14px 20px",
    },
    menuItemIcon: {
      marginRight: "12px",
    },
    modalClose: {
      position: "absolute",
      right: "30px",
    },
    closeModalButton: {
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      "&:hover": {},
    },
    closeModalButtonIcon: {
      color: "#282828",
      fontSize: "30px",
    },
    deleteButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "16px 80px",
      borderRadius: "10px",
      color: "#fff",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
      marginLeft: "10px",
    },
    deleteButtonLabel: {
      fontFamily: theme.typography.secondary,
      color: "#fff",
      fontSize: "16px",
      fontWeight: "700",
      margin: 0,
    },
  })
);

const Dialog = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialog);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "0 30px 30px 30px",
  },
}))(MuiDialogContent);

const ContactNoReports = ({ noReports, setNoReports }) => {
  const classes = useStyles();
  const handleClose = () => {
    setNoReports(false);
  };

  return (
    <Grid container justify="center">
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: "800px",
          },
        }}
        onClose={handleClose}
        aria-labelledby="add-contact-modal"
        open={noReports}
      >
        <div className={classes.contactHeaderContainer}>
          <h1 className={classes.paperLabel}>No Report Sends Remaining</h1>
          <div className={classes.modalClose}>
            <Button className={classes.closeModalButton} onClick={handleClose}>
              <CloseRounded color="inherit" fontSize="inherit" className={classes.closeModalButtonIcon} />
            </Button>
          </div>
        </div>

        <DialogContent>
          <div className={classes.deleteContactModalTextContainer}>
            <h1 className={classes.areYouSureSubText}>Please upgrade your account to continue sending reports.</h1>
          </div>
        </DialogContent>
        <div className={classes.modalActions}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
            className={classes.deleteButton}
          >
            <h1 className={classes.deleteButtonLabel}>Close</h1>
          </Button>
        </div>
      </Dialog>
    </Grid>
  );
};

ContactNoReports.propTypes = {
  noReports: PropTypes.bool,
  setNoReports: PropTypes.func,
};

export default ContactNoReports;
