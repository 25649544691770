import { useReducer } from "react";
import axios from "axios";
import { getBasePath } from "../../Helpers/getEnvVar";

const loginHookInitialState = {
  isFetching: false,
  isSuccessful: false,
  errorMessage: "",
  result: {},
};

const loginHookReducer = (state, action) => {
  switch (action.type) {
    case "fetching":
      return {
        ...state,
        isFetching: true,
        isSuccessful: false,
        errorMessage: "",
        result: {},
      };
    case "success":
      return {
        ...state,
        isFetching: false,
        isSuccessful: true,
        result: { ...action.payload },
      };
    case "error":
      return {
        ...state,
        isFetching: false,
        isSuccessful: false,
        errorMessage: action.payload,
      };
    default:
      throw new Error("Unsupported Action");
  }
};

const useSSOLogin = () => {
  const [user, dispatch] = useReducer(loginHookReducer, loginHookInitialState);
  const basePath = getBasePath();

  const setUser = (payload) => {
    axios({
      url: `${basePath}/sso-login/${payload}`,
      method: "POST",
    })
      .then((data) => {
        const userData = data?.data?.data;
        dispatch({
          type: "success",
          payload: userData,
        });
      })
      .catch((err) => {
        dispatch({ type: "error", payload: err?.response?.data?.error });
      });
  };

  const loginUser = (payload) => {
    dispatch({ type: "fetching" });
    setUser(payload);
  };

  return [user, loginUser];
};

export default useSSOLogin;
