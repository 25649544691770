import React from "react";
import PropTypes from "prop-types";
import {
  createStyles,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    columnHeader: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#818181",
      backgroundColor: "#F5F5F5",
    },
  })
);

const ContactsTableSubscribedHeaders = ({
  order,
  orderBy,
  handleRequestSort,
}) => {
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const headCells = [
    { id: "title", label: "Title" },
    { id: "createdAt", label: "Date Created" },
    { id: "lastSentAt", label: "Last Sent" },
    { id: "numSent", label: "# Sent" },
    { id: "numOpen", label: "# Opened" },
    { id: "frequency", label: "Frequency" },
    { id: "actions", label: "Actions" },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} className={classes.columnHeader}>
            <TableSortLabel
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(`${headCell.id}`)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ContactsTableSubscribedHeaders.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  handleRequestSort: PropTypes.func,
};

export default ContactsTableSubscribedHeaders;
