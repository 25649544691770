import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) =>
  createStyles({
    imageLoaderContainer: {
      height: "100%",
      width: "100%",
    },
    listingPhoto: {
      overflow: "hidden",
      width: "100%",
      height: "205px",
      objectFit: "cover",
      borderRadius: "10px",
    },
    noListingPhoto: {
      height: "unset !important",
      width: "100% !important",
      objectFit: "cover",
      transform: "scale(1.0) !important",
    },
    imageDimensions: {
      maxHeight: "205px",
      overflow: "hidden",
    },
  })
);

const RecentReportsPhoto = ({ photo }) => {
  const classes = useStyles();
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const skeletonStyle = {
    borderRadius: "10px",
  };

  const photoReturn = () => {
    let x = photo;
    if (photo === "" || photo === undefined) {
      x = "/assets/no-image.png";
    }
    return x;
  };
  return (
    <div className={classes.imageDimensions}>
      {!imageIsLoaded ? (
        <div className={classes.imageLoaderContainer}>
          <Skeleton style={skeletonStyle} variant="rect" animation="wave" height={205} />
        </div>
      ) : null}
      <div style={{ height: "205px" }}>
        <img
          alt="listing"
          onLoad={() => {
            setImageIsLoaded(true);
          }}
          src={photoReturn()}
          className={`${classes.listingPhoto}`}
        />
      </div>
    </div>
  );
};
RecentReportsPhoto.propTypes = {
  photo: PropTypes.string,
};

export default RecentReportsPhoto;
