import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, IconButton } from "@material-ui/core";
import { Done, Close, SettingsBackupRestore } from "@material-ui/icons";
import { ChromePicker } from "react-color-hook";

const useStyles = makeStyles((theme) =>
  createStyles({
    colorPickerContainer: {
      position: "absolute",
      bottom: "-250px",
      zIndex: "5",
    },
    iconButtonCheck: {
      position: "absolute",
      top: "0px",
      right: "-60px",
      zIndex: "5",
      background: "#fff",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.4)",
      "&:hover": {
        background: "#fff",
      },
    },
    iconButtonClose: {
      position: "absolute",
      top: "120px",
      right: "-60px",
      zIndex: "5",
      background: "#fff",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.4)",
      "&:hover": {
        background: "#fff",
      },
    },
    iconButtonRestore: {
      position: "absolute",
      top: "60px",
      right: "-60px",
      zIndex: "5",
      background: "#fff",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.4)",
      "&:hover": {
        background: "#fff",
      },
    },
    icons: {
      color: "#333",
      fontSize: "24px",
    },
  })
);

const ColorPicker = ({ setColor, origionalColor, setClosePicker, color }) => {
  const classes = useStyles();
  const [colorValue, setColorValue] = useState("");

  const handleConfrim = () => {
    setColor(colorValue !== "" ? colorValue : color);
    setClosePicker();
  };

  const handleRestore = () => {
    setColor(origionalColor);
    setClosePicker();
  };

  const handleCancel = () => {
    setClosePicker();
  };

  return (
    <div className={classes.colorPickerContainer}>
      <IconButton className={classes.iconButtonCheck} onClick={() => handleConfrim()}>
        <Done fontSize="inherit" color="inherit" className={classes.icons} />
      </IconButton>
      <IconButton className={classes.iconButtonRestore} onClick={() => handleRestore()}>
        <SettingsBackupRestore fontSize="inherit" color="inherit" className={classes.icons} />
      </IconButton>
      <IconButton className={classes.iconButtonClose} onClick={() => handleCancel()}>
        <Close fontSize="inherit" color="inherit" className={classes.icons} />
      </IconButton>
      <ChromePicker color={color !== "" ? color : origionalColor} onChangeComplete={(c, e) => setColorValue(c.hex)} />
    </div>
  );
};

ColorPicker.propTypes = {
  setColor: PropTypes.func,
  origionalColor: PropTypes.string,
  setClosePicker: PropTypes.func
};

export default ColorPicker;
