import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { AppStateContext } from "../../Context/AppReducer";
import NotFoundStylesheet from "./NotFoundStylesheet";

const NotFound = () => {
  const classes = NotFoundStylesheet();
  const state = useContext(AppStateContext);
  return (
    <Grid style={{ position: "absolute" }} container justify="center" alignItems="center" direction="column">
      <h1 className={classes.headerStyles}>404 Not Found</h1>
      {state.loggedIn ? <Link to={`/${state?.user?.username}/dashboard`}>Lets get you back on track :{")"}</Link> : <Link to="/home">Lets get you back on track :{")"}</Link>}
    </Grid>
  );
};

export default NotFound;
