import { gql } from "@apollo/client";

export const DELETE_CONTACT = gql`
  mutation deleteContact($id: Int!) {
    deleteContact(id: $id) {
      id
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation addContactNote($id: Int!, $note: String!) {
    createNote(id: $id, note: $note) {
      id
      note
    }
  }
`;

export const CREATE_NOTE_OPEN = gql`
  mutation addContactNote($contactID: Int!, $note: String!, $username: String!) {
    createNote(contactID: $contactID, note: $note, username: $username) {
      id
      note
    }
  }
`;

export const DELETE_CONTACT_NOTE = gql`
  mutation deleteContactNote($id: Int!) {
    deleteNote(id: $id) {
      id
    }
  }
`;

export const UPDATE_CONTACT_INFO = gql`
  mutation updateContact($firstName: String!, $lastName: String!, $city: String, $state: String, $zip: String, $id: Int!, $email: String!, $phone: String, $website: String, $streetAddress: String) {
    updateContact(firstName: $firstName, lastName: $lastName, city: $city, state: $state, zip: $zip, id: $id, email: $email, phone: $phone, website: $website, streetAddress: $streetAddress) {
      firstName
      lastName
      email {
        email
      }
      phone {
        number
      }
      address {
        street
        city
        state
        zip
      }
    }
  }
`;

export const SUBSCRIBE_TO_REPORT = gql`
  mutation SubscrbeToReport($personId: Int!, $reportRequestId: Int!, $receiveEmail: Boolean!, $frequency: String!, $title: String!) {
    createReportSubscription(personId: $personId, reportRequestId: $reportRequestId, receiveEmail: $receiveEmail, frequency: $frequency, title: $title) {
      createdAt
      frequency
      id
      lastQueuedAt
      lastSentAt
      opened
      personId
      receiveEmail
      reportRequestId
      saved
      sent
      title
    }
  }
`;

export const DELETE_SUBSCRIPTION = gql`
  mutation DeleteContactSubscription($id: Int!) {
    deleteReportSubscription(id: $id) {
      createdAt
      frequency
      id
      lastSentAt
      lastQueuedAt
      opened
      personId
      receiveEmail
      reportRequestId
      saved
      sent
      title
    }
  }
`;
