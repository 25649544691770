import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Button, Grid } from "@material-ui/core";
import PersonalInfoInput from "../../Common/Inputs/PersonalInfoInput";
import AutoCompletOptions from "../../CreateReport/AutoCompleteOptions";
import { AppStateContext } from "../../../Context/AppReducer";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { useLazyQuery, useQuery } from "@apollo/client";
import { authApolloClient, openApolloClient } from "../../../Services/Graph/apolloConfig";
import getUsersMlsIds from "../../../Helpers/getUsersMlsIds";
import { GET_ELASTIC_SEARCH_DATA_OPEN, GET_MLS_LINKS_OPEN } from "../../../Services/Graph/CreateReport/Queries";
import useOnclickOutside from "react-cool-onclickoutside";

const useStyles = makeStyles((theme) =>
  createStyles({
    inputs: {
      marginBottom: "20px",
    },
    designPreferencesContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      position: "relative",
      backgroundColor: "#ffffff",
    },
    labelText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "bold",
      fontSize: "16px",
      color: "#000",
      margin: "15px 0px 15px 0px",
    },
    formContainer: {
      width: "100%",
    },
    resetButton: {
      width: "128px",
      color: "#fff",
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "1",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    fadeInUp: {
      animationName: "$fadeInUp",
    },
    "@keyframes fadeInUp": {
      from: {
        opacity: "0",
        transform: "translate3d(0, 100%, 0)",
      },
      to: {
        opacity: "1",
        transform: "translate3d(0, 0, 0)",
      },
    },
    "@media (max-width: 1024px)": {
      formContainer: {
        width: "100%",
      },
    },
    primaryColor: {
      background: (props) => props.primaryColor,
    },
  })
);

const SearchBarInputs = ({ setNoReport, canCreate, userData, setCreateReportOpen }) => {
  const [primaryColor, setPrimaryColor] = useState(userData?.user?.landingPageSettings?.themeColorPrimary);
  const classes = useStyles({ primaryColor });
  const globalState = useContext(AppStateContext);
  const [landingPageInputInfo, setLandingPageInputInfo] = useState({ termInfo: {}, isAddress: false });
  const [renderedElasticData, setRenderedElasticData] = useState([]);
  const [selectedAnAddress, setSelectedAnAddress] = useState(false);
  const [selectedNonAddress, setSelectedNonAddress] = useState(false);
  const [optionWasSelected, setOptionWasSelected] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    error: false,
    message: "Please enter an Address, City or Zip",
  });

  const {
    ready,
    value,
    suggestions: { data: placesData },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
    },
    debounce: 300,
  });

  const { data: mlsLinkData } = useQuery(GET_MLS_LINKS_OPEN, {
    variables: {
      username: userData?.user?.username,
    },
    client: openApolloClient,
    fetchPolicy: "no-cache",
  });

  const mlsIds = getUsersMlsIds(mlsLinkData?.user);
  const [getElasticSearchData, { data: elasticData }] = useLazyQuery(GET_ELASTIC_SEARCH_DATA_OPEN, {
    variables: {
      term: value,
      mlsIDs: mlsIds,
    },
    client: openApolloClient,
  });

  const ref = useOnclickOutside(() => {
    if (value.length !== 0 && !selectedAnAddress) {
      clearSuggestions();
      setRenderedElasticData([]);
    }
  });

  const handleSelect = (term, isAddress) => () => {
    setValue(term.value, false);
    clearSuggestions();
    setRenderedElasticData([]);

    if (isAddress) {
      setSelectedAnAddress(true);
      setSelectedNonAddress(false);
    } else {
      setSelectedNonAddress(true);
      setSelectedAnAddress(false);
    }
    setLandingPageInputInfo({ termInfo: term, isAddress: isAddress });
    setOptionWasSelected(true);
  };

  const handleChange = (value) => {
    setValue(value);
    getElasticSearchData();
    setSelectedAnAddress(false);
    setSelectedNonAddress(false);
    setInputErrors({ error: false, message: inputErrors.message });
  };

  const handleCreateReport = (e) => {
    e.stopPropagation();
    if (value && landingPageInputInfo.termInfo.cat && optionWasSelected) {
      setCreateReportOpen({ open: true, editing: false, editPayload: {}, termInfo: landingPageInputInfo.termInfo, isAddress: landingPageInputInfo.isAddress });
      setValue("");
      setOptionWasSelected(false);
    } else {
      setInputErrors({ error: true, message: inputErrors.message });
    }
  };

  useEffect(() => {
    setRenderedElasticData(elasticData?.suggestions ?? []);
  }, [elasticData]);

  useEffect(() => {
    if (userData?.user?.landingPageSettings?.themeColorPrimary === "") {
      setPrimaryColor("#008DD1");
    }
  }, [userData]);

  return (
    <div className={classes.designPreferencesContainer}>
      <form id="landing-page-address-form" className={classes.formContainer}>
        <Grid container>
          <Grid item xs={12} className={`wow ${classes.fadeInUp}`}>
            <div className={classes.autoCompleteInputContainer} ref={ref}>
              <PersonalInfoInput
                variant="filled"
                type="text"
                name="location"
                autoComplete="off"
                fullWidth
                value={value}
                onClick={() => {
                  if (!canCreate) {
                    setNoReport(true);
                  }
                }}
                disabled={!ready || !canCreate}
                onChange={(e) => handleChange(e.target.value)}
                label="Search an Address, City, Zip"
                error={inputErrors.error}
                helperText={inputErrors.error && inputErrors.message}
                className={classes.landingPageInput}
                InputProps={{
                  name: "location",
                  endAdornment: (
                    <Button onClick={(e) => handleCreateReport(e)} className={`${classes.resetButton} ${classes.primaryColor}`}>
                      Find Out
                    </Button>
                  ),
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
              <div className={classes.autodiv}>
                <AutoCompletOptions elasticData={renderedElasticData} placesData={placesData} handleSelect={(term, isAddress) => handleSelect(term, isAddress)} inputValue={value} />
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

SearchBarInputs.propTypes = {
  userData: PropTypes.object,
  setCreateReportOpen: PropTypes.func,
  setNoReport: PropTypes.func,
  canCreate: PropTypes.bool,
};

export default SearchBarInputs;
