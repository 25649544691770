import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, CircularProgress } from "@material-ui/core";
import { Element } from "react-scroll";
import useSSOLogin from "../../Services/Rest/SSOloginHook";
import { AppDispatchContext } from "../../Context/AppReducer";
import { saveState } from "../../Helpers/localStorage";

const useStyles = makeStyles((theme) =>
  createStyles({
    overParent: {
      position: "absolute",
      backgroundColor: "#fff",
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    overDiv: {
      padding: "0px 180px",
      width: "100%",
      height: "100%",
    },
    parentDiv: {
      fontSize: "24px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      filter: "drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25))",
      top: "30%",
      borderRadius: "25px",
      backgroundColor: "#F3F3F3",
      height: "300px",
      margin: "auto",
      position: "absolute",
      right: "0",
      left: "0",
      width: "100%",
      maxWidth: "660px",
      fontFamily: "nunito",
    },
    circle: {
      height: "75px !important",
      width: "75px !important",
      color: theme.palette.areaPulseBlue,
    },
    "@media (max-width: 900px)": {
      overDiv: {
        padding: "0px 25px",
      },
    },
  })
);

function buildRedirectURL(query, user) {
  const type = query.get('type');
  query.delete('type');
  const queryString = query.toString();
  switch (type) {
    case 'contact':
      return `/${user.username}/create-report?${queryString}`;
    case 'edit':
      return `/${user.username}/create-report?${queryString}`;
    case 'preview':
      return `/${user.username}/market-reports/${query.get('report-id')}`;
    default:
      return `/${user.username}/dashboard`;
  }
}

const SSOLogin = ({ match, history, location }) => {
  const query = new URLSearchParams(location?.search);
  const classes = useStyles();
  const [user, loginUser] = useSSOLogin();
  const token = match?.params?.token;
  const globalDispatch = useContext(AppDispatchContext);

  useEffect(() => {
    loginUser(token);
  }, []);

  useEffect(() => {
    if (user.isSuccessful) {
      globalDispatch({ type: "login", payload: { ...user.result.user, mlsLinks: user.result.mlsLinks } });
      saveState("areaPulseUserToken", user.result.user.token);
      history.push(buildRedirectURL(query, user.result.user));
    } else if (user.errorMessage !== "") {
      globalDispatch({ type: "setSSOLoginError", payload: true });
      history.push(`/home`);
    }
  }, [user]);

  return (
    <Element className={classes.overParent} name="SSOLogin">
      <div className={classes.overDiv}>
        <div className={classes.parentDiv}>{user?.errorMessage ? "There was a problem logging in" : <CircularProgress className={classes.circle} />}</div>
      </div>
    </Element>
  );
};

SSOLogin.propTypes = {
  googleScriptLoaded: PropTypes.bool,
};

export default SSOLogin;
