import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    preferencesHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "0.5px solid #DEDEDE",
      padding: "0px 0px 20px 0px",
    },
    preferencesHeaderText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "36px",
      color: "#4E4E4E",
      margin: "0px",
    },
    saveButtonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "9px 20px",
      background: "#F5F5F5",
      borderRadius: "10px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      cursor: "pointer",
    },
    saveButtonLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "16px",
      color: "#333",
      margin: "0px 25px 0px 0px",
    },
    saveButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    saveButtonDisabled: {
      cursor: "not-allowed",
      opacity: "0.5",
    },
    saveButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    circle: {
      strokeLinecap: "round",
    },
    progressOutter: {
      color: "#fff",
      animationDuration: "1700ms",
    },
  })
);

const PreferencesHeader = ({ setSavingUserPrefs, iFrameLoading, savingUserPrefs }) => {
  const classes = useStyles();
  // const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);

  // useEffect(() => {
  //   if (iFrameLoading) {
  //     setCloseButtonDisabled(true);
  //   } else if (!iFrameLoading) {
  //     setCloseButtonDisabled(false);
  //   }
  // }, [iFrameLoading]);

  // const handleSave = (e) => {
  //   if (!closeButtonDisabled) {
  //     e.stopPropagation();
  //     setSavingUserPrefs(true);
  //   }
  // };

  return (
    <div className={classes.preferencesHeaderContainer}>
      <h1 className={classes.preferencesHeaderText}>Reports Preferences</h1>
      {/* <div className={classes.saveButtonContainer} onClick={(e) => handleSave(e)}>
        {!savingUserPrefs && (
          <h1 className={classes.saveButtonLabel}>Save Preferences</h1>
        )}
        {savingUserPrefs && (
          <h1 className={classes.saveButtonLabel}>Saving Preferences</h1>
        )}
        <Button className={`${classes.saveButton} ${closeButtonDisabled && classes.saveButtonDisabled}`} onClick={(e) => handleSave(e)}>
          {!savingUserPrefs && (
            <Done color="inherit" fontSize="inherit" className={classes.saveButtonIcon} />
          )}
          {savingUserPrefs && (
            <CircularProgress
              size={24}
              thickness={4}
              color="inherit"
              classes={{
                circle: classes.circle
              }}
              className={classes.progressOutter}
            />
          )}
        </Button>
      </div> */}
    </div>
  );
};

PreferencesHeader.propTypes = {
  setSavingUserPrefs: PropTypes.func,
  iFrameLoading: PropTypes.bool,
  savingUserPrefs: PropTypes.bool,
};

export default PreferencesHeader;
