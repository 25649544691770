import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, withStyles, Button, MenuItem, Menu } from "@material-ui/core";
import { ChevronLeftRounded, EditOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MoreHorzOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import SubscribeToReportModal from "./SubscribeToReportModal";

const useStyles = makeStyles((theme) =>
  createStyles({
    backButton: {
      display: "flex",
      alignItems: "center",
      marginBottom: "30px",
      textDecoration: "none",
    },
    backIcon: {
      fontSize: "24px",
      color: "#282828",
    },
    backLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "18px",
      color: "#000",
      margin: "0",
      textDecoration: "none",
    },
    contactHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "0.5px solid #DEDEDE",
      padding: "0px 0px 5px 0px",
    },
    contactHeader: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "36px",
      color: "#4E4E4E",
      margin: "0px",
      textTransform: "capitalize",
    },
    createReportButtonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "9px 20px",
      background: "#F5F5F5",
      borderRadius: "10px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      cursor: "pointer",
      marginBottom: "25px",
    },
    createReportButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    createReportButtonLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "16px",
      color: "#333",
      margin: "0px 16px 0px 0px",
    },
    createReportButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    menuItem: {
      padding: "14px 20px",
    },
    menuItemIcon: {
      marginRight: "12px",
    },
    addSuccess: {
      position: "fixed",
      top: "40px",
      width: "50%",
    },
  })
);

const StyledMenuReports = withStyles({
  paper: {
    backgroundColor: "#f1f1f1",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "center",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "center",
      horizontal: "left",
    }}
    {...props}
  />
));

const ContactHeader = ({ paidAccount, setRegrabReportsSent, setNoReports, reportsRemaining, contactDetails, contactId, user, setCreateReportOpen, subscriptionWasMade, setSubscriptionWasMade }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [subscriptionModalWasClosed, setSubscriptionModalWasClosed] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReportMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleCreateReport = (e) => {
    e.stopPropagation();
    setCreateReportOpen({ open: true, editing: false, editPayload: {} });
    setAnchorEl(null);
  };

  useEffect(() => {
    if (subscriptionWasMade) {
      setAnchorEl(null);
    }

    if (subscriptionModalWasClosed) {
      setAnchorEl(null);
      setSubscriptionModalWasClosed(false);
    }
  }, [subscriptionWasMade, subscriptionModalWasClosed]);

  return (
    <div>
      <div>
        <Link className={classes.backButton} to={`/${user}/contacts`}>
          <ChevronLeftRounded color="inherit" fontSize="inherit" className={classes.backIcon} />
          <span className={classes.backLabel}>Go Back</span>
        </Link>
      </div>
      <div className={classes.contactHeaderContainer}>
        <h1 className={classes.contactHeader}>Contact</h1>
        <div className={classes.createReportButtonContainer} aria-controls="report-dropdown" aria-haspopup="true" onClick={(event) => handleClick(event)}>
          <h1 className={classes.createReportButtonLabel}>Report</h1>
          <Button className={classes.createReportButton}>
            <MoreHorzOutlinedIcon className={classes.createReportButtonIcon} />
          </Button>
          <StyledMenuReports id="report-dropdown" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={(event) => handleReportMenuClose(event)} className={classes.dropDown}>
            <MenuItem className={classes.menuItem} onClick={(e) => handleCreateReport(e)}>
              <EditOutlined className={classes.menuItemIcon} />
              Create New Report
            </MenuItem>
            <div>
              <SubscribeToReportModal
                paidAccount={paidAccount}
                setRegrabReportsSent={setRegrabReportsSent}
                setNoReports={setNoReports}
                reportsRemaining={reportsRemaining}
                contactDetails={contactDetails}
                contactId={contactId}
                setSubscriptionWasMade={setSubscriptionWasMade}
                setSubscriptionModalWasClosed={setSubscriptionModalWasClosed}
              />
            </div>
          </StyledMenuReports>
        </div>
      </div>
    </div>
  );
};

ContactHeader.propTypes = {
  reportsRemaining: PropTypes.number,
  paidAccount: PropTypes.bool,
  contactDetails: PropTypes.object,
  setCreateReportOpen: PropTypes.func,
  setRegrabReportsSent: PropTypes.func,
  setNoReports: PropTypes.func,
  user: PropTypes.string,
  contactId: PropTypes.string,
};

export default ContactHeader;
