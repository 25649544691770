import React from "react";
import { makeStyles, createStyles, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    reportLoaderContainer: {
      position: "fixed",
      height: "100%",
      width: "100%",
      background: (props) => props?.themeColorGradient,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      zIndex: "100",
    },
    loadingCardContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
      background: "#fff",
      borderRadius: "10px",
      padding: "25px",
      margin: "15px",
      maxWidth: "767px",
      width: "calc(100% - 30px)",
    },
    loadingCardRowContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "10px 0px",
    },
    circle: {
      strokeLinecap: "round",
    },
    loader: {
      margin: "0px",
    },
    loadingText: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: "500",
      color: "#000",
      marginLeft: "10px",
    },
  })
);

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.reportLoaderContainer}>
      <div className={classes.loadingCardContainer}>
        <div className={classes.loadingCardRowContainer}>
          <CircularProgress
            size={35}
            thickness={4}
            color="inherit"
            className={classes.loader}
            classes={{
              circle: classes.circle,
            }}
          />
          <h1 className={classes.loadingText}>Fetching User Data...</h1>
        </div>
      </div>
    </div>
  );
};

export default Loader;
