import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Icon, IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { allIcons } from "../../../Helpers/getAllIcons";
import Link from "./Links";

const useStyles = makeStyles((theme) =>
  createStyles({
    createLinkButtonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    customLinksContainer: {
      marginTop: "20px",
      width: "100%",
    },
    addIconButton: {
      background: "#FAFAFA",
      marginBottom: "18px",
      marginRight: "12px",
    },
    addIcon: {
      fontSize: "24px",
      color: "#333333",
    },
  })
);

const CustomLinks = ({ savingUserPrefs, customLinks, setCustomlinks, data }) => {
  const classes = useStyles();
  const [links, setLinks] = useState();
  const preStateLinks = data?.user?.links;
  const collectData = useCallback(() => {
    let filterUnwanted = [];
    links.map((link) => {
      if (link.action !== undefined) {
        if (link.action === "delete" || link.action === "update") {
          const newLink = {
            id: link.id,
            action: link.action,
            icon: link.icon,
            isDefault: link.isDefault,
            isHidden: link.isHidden,
            title: link.title,
            url: link.url,
          };
          filterUnwanted.push(newLink);
        } else if (link.action === "create") {
          const newLink = {
            id: -1,
            action: link.action,
            icon: link.icon === "" ? "accessibility" : link.icon,
            isDefault: link.isDefault,
            isHidden: link.isHidden,
            title: link.title,
            url: link.url,
          };
          filterUnwanted.push(newLink);
        } else {
          const newLink = {
            id: link.id,
            action: link.action,
            icon: link.icon,
            isDefault: link.isDefault,
            isHidden: link.isHidden,
            title: link.title,
            url: link.url,
          };
          filterUnwanted.push(newLink);
        }
      }
      return null;
    });
    setCustomlinks(filterUnwanted);
    return null;
  }, [setCustomlinks, links]);

  useEffect(() => {
    if (savingUserPrefs && !customLinks) {
      collectData();
    }
  }, [savingUserPrefs, customLinks, collectData]);

  useEffect(() => {
    if (preStateLinks !== undefined) {
      setLinks(data.user.links);
    }
  }, [preStateLinks]);

  const handleCreateLink = () => {
    const hiddenDefaults = links.filter((link, i) => {
      if ((link.isDefault && link.isHidden) || link.isHidden) {
        return true;
      }
      return false;
    });
    const howManyLink = links.length - hiddenDefaults.length;
    if (howManyLink < 6) {
      let newLinks = JSON.parse(JSON.stringify(links));
      const newObject = {
        icon: "",
        isDefault: false,
        isHidden: false,
        title: "",
        url: "",
        action: "create",
        original: false,
      };
      setLinks([newObject, ...newLinks]);
    }
    return null;
  };

  const formattedIcons = () => {
    return allIcons.map((icon) => {
      return <Icon>{icon}</Icon>;
    });
  };

  const icons = formattedIcons();

  return (
    <div className={classes.customLinksContainer}>
      <div className={classes.createLinkButtonContainer}>
        <IconButton className={classes.addIconButton} onClick={() => handleCreateLink()}>
          <Add color="inherit" fontSize="inherit" className={classes.addIcon} />
        </IconButton>
      </div>
      {links?.map((link, i) => {
        const icon = link.icon;
        const isDefault = link.isDefault;
        const isHidden = link.isHidden;
        const title = link.title;
        const url = link.url;
        const original = link.original === undefined ? true : false;
        const action = link.action;
        return (
          <Link
            key={i}
            index={i}
            icons={icons}
            icon={icon}
            action={action}
            original={original}
            isDefault={isDefault}
            isHidden={isHidden}
            title={title}
            url={url}
            links={links}
            setLinks={(links) => setLinks(links)}
          />
        );
      })}
    </div>
  );
};

CustomLinks.propTypes = {
  savingUserPrefs: PropTypes.bool,
  setCustomlinks: PropTypes.func,
  data: PropTypes.object,
};

export default CustomLinks;
