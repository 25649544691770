import { gql } from "@apollo/client";

export const GET_USER_DATA = gql`
  query User($username: String!) {
    user(username: $username) {
      email
      username
      firstName
      lastName
      bio
      photoURL
      logoURL
      phone
      title
      id
      totalSubscriptionUsage {
        reportsRemaining
      }
      mlsLinks {
        mlsOfficeId
        mlsAgentId
      }
      links {
        icon
        isDefault
        isHidden
        title
        url
      }
      reportSettings {
        title
        licenseId
        firstName
        lastName
        email {
          email
        }
        phone {
          number
        }
        address {
          city
          state
          zip
          street
        }
        themeColorSolid
        themeColorGradient
        categoryColorNew
        categoryColorActive
        categoryColorPending
        categoryColorSold
      }
      landingPageSettings {
        backgroundImage
        faviconUrl
        headline
        id
        subheadline
        templateChosen
        themeColorPrimary
        themeColorSecondary
        logoURL
      }
    }
  }
`;

export const GET_REPORT_DATA = gql`
  query GetMarketReport($id: Int!) {
    report(id: $id) {
      requireRegistration
      id
      title
      headline
      parameters {
        geoJSON {
          coordinates
          type
        }
        bathsMax
        bathsMin
        bedsMax
        bedsMin
        city
        state
        address
        zipCode
        priceMax
        priceMin
        propertyType
        lat
        lng
        radius
        polygon
      }
      listings(page: 0, pageSize: 1000, sortDirection: asc) {
        statusCode
        listDate
        price
        remarks
        beds
        totalBaths
        sqFt
        acres
        year
        office {
          id
        }
        agents {
          id
        }
        photos {
          url
        }
        location {
          lat
          lng
        }
        address {
          area
          city
          neighborhood
          state
          street
          zip
        }
      }
      stats {
        byMonth {
          avgDOM
          avgPrice
          month
        }
        byStatus {
          avgBedrooms
          avgBathrooms
          avgPrice
          avgSqFt
          status
          count
          countsByField {
            fieldName
            buckets {
              count
              name
            }
          }
          maxPrice
          minPrice
        }
      }
    }
  }
`;

export const CONTACT_USER = gql`
  query ContactUser($userId: Int!, $firstName: String!, $lastName: String!, $email: String!, $phone: String, $message: String!) {
    contactUser(userId: $userId, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, message: $message) {
      status
    }
  }
`;
