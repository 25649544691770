import React, { useState, useContext, useEffect, useRef } from "react";
import { makeStyles, createStyles, Grid, IconButton, Collapse } from "@material-ui/core";
import { AppStateContext, AppDispatchContext } from "../../Context/AppReducer";
import { useLazyQuery, useMutation } from "@apollo/client";
import { authApolloClient } from "../../Services/Graph/apolloConfig";
import PreferencesHeader from "./PreferencesHeader/PreferencesHeader";
import DesignPreferences from "./PreferencesContent/DesignPreferences";
import UsernameEdit from "./PreferencesContent/UsernameEdit";
import SaveLandingPageSettings from "./SaveLandingPageSettings";
import ReportPreview from "./ReportPreview/ReportPreview";
import PropTypes from "prop-types";
import PageTemplate from "./PreferencesContent/PageTemplate";
import HeadlineInputs from "./PreferencesContent/HeadlineInputs";
import { GET_USER_DATA } from "../../Services/Graph/UserSettings/Queries";
import { UPDATE_USER_LANDING_PAGE_SETTINGS, REVERT_LANDING_PAGE_SETTINGS } from "../../Services/Graph/LandingPageSettings/Mutations";
import { UPDATE_USER } from "../../Services/Graph/UserSettings/Mutations";
import { EditOutlined, DeleteForever } from "@material-ui/icons";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import ImageUploadPreview from "../Common/ImageUpload/ImageUploadPreview";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import useUploadFavicon from "../../Services/Rest/uploadFaviconHook";
import useUploadLandingBackgroundImage from "../../Services/Rest/uploadLandingBackgroundImageHook";

const useStyles = makeStyles((theme) =>
  createStyles({
    landingPageSettingsContainer: {
      paddingRight: "50px",
    },
    imageContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      cursor: "pointer",
      marginTop: "20px",
      marginBottom: "30px",
      padding: "10px",
    },
    avatarLogo: {
      height: "100%",
      width: "100%",
      padding: "10px",
      borderRadius: "0%",
      background: "#BDBDBD",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0008), 0px 4px 8px rgba(0, 0, 0, 0.12)",
      objectFit: "contain",
    },
    avatarFavicon: {
      height: "100%",
      width: "100%",
      padding: "10px",
      borderRadius: "0%",
      background: "#BDBDBD",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0008), 0px 4px 8px rgba(0, 0, 0, 0.12)",
      objectFit: "contain",
    },
    uploadIcon: {
      fontSize: "40px",
      minHeight: "200px",
    },
    fallbackText: {
      margin: "0px",
      fontSize: "30px",
    },
    imagePreviewLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "14px",
      color: "#000",
      margin: "15px 0px 0px 0px",
    },
    editButton: {
      background: theme.palette.areaPulseBlue,
      padding: "5px",
      position: "absolute",
      right: "5px",
      bottom: "20px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0008), 0px 4px 8px rgba(0, 0, 0, 0.12)",
      transition: "0.2s",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
        transition: "0.2s",
        opacity: "0.8",
      },
    },
    editButtonIcon: {
      color: "#fff",
    },
    deleteButton: {
      background: theme.palette.areaPulseBlue,
      padding: "5px",
      position: "absolute",
      left: "5px",
      bottom: "20px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0008), 0px 4px 8px rgba(0, 0, 0, 0.12)",
      transition: "0.2s",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
        transition: "0.2s",
        opacity: "0.8",
      },
    },
    userPreferencesContainer: {
      width: "100%",
      minHeight: "100vh",
      padding: "60px 80px 60px 180px",
      background: "#fff",
    },
    disableDiv: {
      zIndex: "10",
      position: "absolute",
      left: "0",
      top: "0",
      backgroundColor: "transparent",
      width: "200px",
      height: "100%",
    },
    widerDisable: {
      width: "200px",
    },
    alert: {
      position: "fixed",
      top: "40px",
      width: "50%",
    },
    error: {
      color: "red",
      marginTop: "-30px",
      fontFamily: "nunito",
    },
  })
);

const LandingPagePreferencesComponent = (user) => {
  const classes = useStyles();
  const globalState = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);
  const [savingLandingPageSettings, setSavingLandingPageSettings] = useState(false);
  const [iFrameLoading, setiFrameLoading] = useState(false);
  const [updatedUsername, setUpdatedUsername] = useState("");
  const [usernameError, setUsernameError] = useState({ status: false, message: "" });
  const [primaryColor, setPrimaryColor] = useState("#0079B4");
  const [secondaryColor, setSecondaryColor] = useState("#333333");
  const [templateOptionChosen, setTemplateOptionChosen] = useState("1");
  const [headline, setHeadline] = useState("Be the first to know.");
  const [subheadline, setSubheadline] = useState("Up-to-Date Home Value Report Sent to You Every Week");
  const [preferencesWereSaved, setPreferencesWereSaved] = useState(false);
  const [imageHasUpdated, setImageHasUpdate] = useState(false);
  const [{ isFetching: faviconIsFetching }, uploadFavicon] = useUploadFavicon();
  const [{ isFetching: backgroundImageIsFetching }, uploadLandingBackgroundImage] = useUploadLandingBackgroundImage();
  const faviconImageInput = useRef(null);
  const logoImageInput = useRef(null);
  const backgroundImageInput = useRef(null);

  const [getUser, { data: userData, error: userDataError }] = useLazyQuery(GET_USER_DATA, {
    variables: {
      id: globalState?.user?.userId ?? 0,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  const [faviconImage, setFaviconImage] = useState({
    preview: userData?.user?.landingPageSettings?.faviconUrl,
    file: "",
  });

  const [logoImage, setLogoImage] = useState({
    preview: userData?.user?.landingPageSettings?.logoURL,
    file: "",
  });

  const [backgroundImage, setBackgroundImage] = useState({
    preview: userData?.user?.landingPageSettings?.backgroundImage,
    file: "",
  });

  const firstInitial = userData?.user?.firstName?.split("")[0];

  const [updateUserUsername] = useMutation(UPDATE_USER, {
    variables: {
      title: userData?.user?.title,
      licenseId: userData?.user?.licenseId,
      email: userData?.user?.email,
      phone: userData?.user?.phone,
      firstName: userData?.user?.firstName,
      lastName: userData?.user?.lastName,
      userId: userData?.user?.id,
      username: updatedUsername,
    },
    client: authApolloClient,
    onCompleted() {
      setPreferencesWereSaved(true);
    },
  });

  const [revertLandingPageSettings, { loading: revertLoading, error: revertError }] = useMutation(REVERT_LANDING_PAGE_SETTINGS, {
    variables: {
      userId: userData?.user?.id,
      fields: ["backgroundImage"],
    },
    client: authApolloClient,
    onCompleted() {
      setPreferencesWereSaved(true);
      getUser();
    },
  });

  const [UpdateUserLandingPagePreferences] = useMutation(UPDATE_USER_LANDING_PAGE_SETTINGS, {
    variables: imageHasUpdated
      ? {
          themeColorPrimary: primaryColor,
          themeColorSecondary: secondaryColor,
          templateChosen: parseInt(templateOptionChosen),
          headline: headline,
          subheadline: subheadline,
          userId: userData?.user?.id,
          logo: logoImage?.preview?.split(",").pop(),
        }
      : {
          themeColorPrimary: primaryColor,
          themeColorSecondary: secondaryColor,
          templateChosen: parseInt(templateOptionChosen),
          headline: headline,
          subheadline: subheadline,
          userId: userData?.user?.id,
        },
    client: authApolloClient,
    onCompleted() {
      setPreferencesWereSaved(true);
      setImageHasUpdate(false);
    },
  });

  // const handleLogoUpload = (e) => {
  //   e.stopPropagation();
  //   logoImageInput.current.click();
  // };
  // const handleLogoSubmit = (e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.addEventListener(
  //     "load",
  //     function () {
  //       // convert image file to base64 string
  //       setLogoImage({
  //         preview: reader.result,
  //         file: file,
  //       });
  //       setImageHasUpdate(true);
  //     },
  //     false
  //   );

  //   if (file) {
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleProfileUpload = (e) => {
    e.stopPropagation();
    faviconImageInput.current.click();
  };
  const handleProfileSubmit = (e) => {
    const file = e.target.files[0];
    if (file !== undefined) {
      setFaviconImage({
        preview: URL.createObjectURL(file),
        file: file,
      });
    }
  };

  const handleBackgroundRevert = (e) => {
    e.stopPropagation();
    revertLandingPageSettings();
    setBackgroundImage({
      preview: "",
      file: "",
    });
  };

  const handleBackgroundUpload = (e) => {
    e.stopPropagation();
    backgroundImageInput.current.click();
  };
  const handleBackgroundSubmit = (e) => {
    const file = e.target.files[0];
    if (file !== undefined) {
      setBackgroundImage({
        preview: URL.createObjectURL(file),
        file: file,
      });
    }
  };

  useEffect(() => {
    const errorMessage = userDataError?.networkError?.result?.error ?? "";
    if (userDataError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [userDataError, globalDispatch]);

  useEffect(() => {
    if (userData === undefined) {
      getUser();
    }
    if (userData?.user?.landingPageSettings?.backgroundImage !== "") {
      let uploadedBackgroundImage = {
        preview: userData?.user?.landingPageSettings?.backgroundImage,
        file: backgroundImage.file,
      };
      setBackgroundImage(uploadedBackgroundImage);
    }
    // if (userData?.user?.landingPageSettings?.logoURL) {
    //   setLogoImage({
    //     preview: userData?.user?.landingPageSettings?.logoURL,
    //   });
    // }
    if (userData?.user?.landingPageSettings?.faviconUrl !== "") {
      let uploadFaviconImage = {
        preview: userData?.user?.landingPageSettings?.faviconUrl,
        file: faviconImage.file,
      };
      setFaviconImage(uploadFaviconImage);
    }
  }, [userData]);

  useEffect(() => {
    if (
      savingLandingPageSettings &&
      updatedUsername !== "" &&
      updatedUsername !== "home" &&
      updatedUsername !== "404" &&
      updatedUsername !== "reset-password" &&
      updatedUsername !== "privacy-policy" &&
      updatedUsername !== "reset-password"
    ) {
      updateUserUsername();

      if (faviconImage !== "" && faviconImage.file !== "") {
        uploadFavicon({
          userId: userData?.user?.id,
          image: faviconImage.file,
        });
      }
      if (backgroundImage !== "" && backgroundImage.file !== "") {
        uploadLandingBackgroundImage({
          userId: userData?.user?.id,
          image: backgroundImage.file,
        });
      }
      UpdateUserLandingPagePreferences();
      globalDispatch({ type: "setUserName", payload: updatedUsername });
    }
    if (
      (userData && updatedUsername === "") ||
      updatedUsername === "home" ||
      updatedUsername === "404" ||
      updatedUsername === "reset-password" ||
      updatedUsername === "privacy-policy" ||
      updatedUsername === "reset-password"
    ) {
      setUsernameError({ status: true, message: "Now" });
    } else {
      setUsernameError({ status: false, message: "" });
    }
  }, [savingLandingPageSettings, updatedUsername]);

  useEffect(() => {
    let mounted = true;

    if (preferencesWereSaved) {
      setPreferencesWereSaved(true);
    }
    const closeSavePreferencesAlert = setTimeout(() => {
      if (mounted) setPreferencesWereSaved(false);
    }, 3000);

    return () => {
      clearTimeout(closeSavePreferencesAlert);
      mounted = false;
    };
  }, [preferencesWereSaved]);

  return (
    <div className={classes.userPreferencesContainer}>
      <PreferencesHeader />
      <Grid container>
        <Grid item xs={5} className={classes.landingPageSettingsContainer}>
          <Grid container>
            <Grid item md={12} lg={6}>
              <UsernameEdit
                userData={userData}
                updatedUsername={updatedUsername}
                setUpdatedUsername={setUpdatedUsername}
                usernameError={usernameError}
                savingLandingPageSettings={savingLandingPageSettings}
                setSavingLandingPageSettings={(status) => setSavingLandingPageSettings(status)}
              />
            </Grid>
            <Grid item md={12} lg={6}>
              <DesignPreferences
                savingLandingPageSettings={savingLandingPageSettings}
                landingPagePreferences={userData?.user?.landingPageSettings}
                primaryColor={primaryColor}
                setPrimaryColor={setPrimaryColor}
                secondaryColor={secondaryColor}
                setSecondaryColor={setSecondaryColor}
              />
            </Grid>
          </Grid>
          <PageTemplate
            userData={userData}
            templateOptionChosen={templateOptionChosen}
            setTemplateOptionChosen={setTemplateOptionChosen}
            savingLandingPageSettings={savingLandingPageSettings}
            setSavingLandingPageSettings={(status) => setSavingLandingPageSettings(status)}
          />
          <Grid container className={classes.imagesContainer}>
            <Grid item md={12} lg={8} xl={4}>
              <div className={classes.imageContainer} onClick={(e) => handleBackgroundUpload(e)}>
                {backgroundImage.preview === "" ? (
                  <ImageUploadPreview className={classes.avatarFavicon}>
                    <PublishRoundedIcon className={classes.uploadIcon} />
                  </ImageUploadPreview>
                ) : (
                  <ImageUploadPreview alt={firstInitial} src={backgroundImage.preview} className={classes.avatarFavicon}>
                    <PublishRoundedIcon className={classes.uploadIcon} />
                  </ImageUploadPreview>
                )}
                <IconButton className={classes.editButton} onClick={(e) => handleBackgroundUpload(e)}>
                  <EditOutlined color="inherit" className={classes.editButtonIcon} />
                </IconButton>
                <IconButton disabled={revertLoading} className={classes.deleteButton} onClick={(e) => handleBackgroundRevert(e)}>
                  <DeleteForever color="inherit" className={classes.editButtonIcon} />
                </IconButton>
                <input
                  type="file"
                  ref={backgroundImageInput}
                  onChange={(e) => {
                    handleBackgroundSubmit(e);
                    e.target.value = null;
                  }}
                  style={{ display: "none" }}
                  accept="image/png, image/jpeg"
                />
                <h1 className={classes.imagePreviewLabel}>Background Image</h1>
              </div>
              {revertError ? <div className={classes.error}>There was a problem deleting your image</div> : null}
            </Grid>
            <Grid item md={12} lg={4} xl={4}>
              <div className={classes.imageContainer} onClick={(e) => handleProfileUpload(e)}>
                {faviconImage.preview === "" ? (
                  <ImageUploadPreview className={classes.avatarFavicon}>
                    <PublishRoundedIcon className={classes.uploadIcon} />
                  </ImageUploadPreview>
                ) : (
                  <ImageUploadPreview alt={firstInitial} src={faviconImage.preview} className={classes.avatarFavicon}>
                    <PublishRoundedIcon className={classes.uploadIcon} />
                  </ImageUploadPreview>
                )}
                <IconButton className={classes.editButton} onClick={(e) => handleProfileUpload(e)}>
                  <EditOutlined color="inherit" className={classes.editButtonIcon} />
                </IconButton>
                <input
                  type="file"
                  ref={faviconImageInput}
                  onChange={(e) => {
                    handleProfileSubmit(e);
                    e.target.value = null;
                  }}
                  style={{ display: "none" }}
                  accept="image/png, image/jpeg"
                />
                <h1 className={classes.imagePreviewLabel}>Favicon Image</h1>
              </div>
            </Grid>
            {/* <Grid item md={12} lg={8} xl={4}>
              <div className={classes.imageContainer} onClick={(e) => handleLogoUpload(e)}>
                {logoImage.preview === "" ? (
                  <ImageUploadPreview className={classes.avatarLogo}>
                    <PublishRoundedIcon className={classes.uploadIcon} />
                  </ImageUploadPreview>
                ) : (
                  <ImageUploadPreview alt={firstInitial} src={logoImage.preview} className={classes.avatarLogo}>
                    <PublishRoundedIcon className={classes.uploadIcon} />
                  </ImageUploadPreview>
                )}
                <IconButton className={classes.editButton} onClick={(e) => handleLogoUpload(e)}>
                  <EditOutlined color="inherit" className={classes.editButtonIcon} />
                </IconButton>
                <input
                  type="file"
                  ref={logoImageInput}
                  onChange={(e) => {
                    handleLogoSubmit(e);
                    e.target.value = null;
                  }}
                  style={{ display: "none" }}
                  accept="image/png, image/jpeg"
                />
                <h1 className={classes.imagePreviewLabel}>Page Logo</h1>
              </div>
            </Grid> */}
          </Grid>
          <HeadlineInputs
            userData={userData}
            headline={headline}
            subheadline={subheadline}
            setHeadline={setHeadline}
            setSubheadline={setSubheadline}
            savingLandingPageSettings={savingLandingPageSettings}
            setSavingLandingPageSettings={(status) => setSavingLandingPageSettings(status)}
          />
          <SaveLandingPageSettings
            userData={userData}
            usernameError={usernameError}
            savingLandingPageSettings={savingLandingPageSettings}
            iFrameLoading={iFrameLoading}
            setSavingLandingPageSettings={(status) => setSavingLandingPageSettings(status)}
          />
        </Grid>
        <Grid item xs={7}>
          <ReportPreview
            userName={globalState.user.username}
            savingLandingPageSettings={savingLandingPageSettings}
            iFrameLoading={iFrameLoading}
            setiFrameLoading={(status) => setiFrameLoading(status)}
          />
        </Grid>
      </Grid>
      {/* {iFrameLoading ? (
        <div
          onMouseLeave={() => {
            setWideDisable(false);
          }}
          onMouseEnter={() => {
            setWideDisable(true);
          }}
          className={`${classes.disableDiv} ${wideDisable ? classes.widerDisable : null}`}
        ></div>
      ) : null} */}
      <div className={classes.alert}>
        <Collapse in={preferencesWereSaved} timeout={1000}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPreferencesWereSaved(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Preferences Were Saved!
          </Alert>
        </Collapse>
      </div>
    </div>
  );
};
LandingPagePreferencesComponent.propTypes = {
  user: PropTypes.object,
};
export default LandingPagePreferencesComponent;
