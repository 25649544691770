import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query users($first: Int, $offset: Int, $email: String, $name: String, $username: String, $sortField: UserSortFieldEnum, $sortDirection: SortDirection) {
    users(first: $first, offset: $offset, email: $email, name: $name, username: $username, sortField: $sortField, sortDirection: $sortDirection) {
      results {
        total
        returned
      }
      users {
        lastLogin
        id
        username
        email
        firstName
        lastName
        subscriptions {
          name
        }
        roles {
          role {
            name
          }
        }
      }
    }
  }
`;
