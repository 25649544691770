import React from "react";
import FooterStylesheet from "./FooterStylesheet";

const Footer = () => {
  const classes = FooterStylesheet();

  return (
    <div className={classes.footerContainer}>
      <img className={classes.footerImage} src="/assets/ap-color.png" alt="AreaPulse" />
      <p className={classes.rightsReserved}>
        © {new Date().getFullYear()} <strong>AreaPulse</strong>. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
