import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppStateContext, AppDispatchContext } from "../../Context/AppReducer";
import PrivacyStylesheet from "./PrivacyStylesheet";
import { Fade, Button } from "@material-ui/core";
import { Clear } from "@material-ui/icons";

const PrivacyPopup = () => {
  const classes = PrivacyStylesheet();
  const state = useContext(AppStateContext);
  const dispatch = useContext(AppDispatchContext);

  return (
    <Fade in={state.privacyNotice} timeout={1000}>
      <div className={classes.privacyPopupContainer}>
        <h1 className={classes.privacyPolicyText}>
          We use cookies to provide and improve our services. By using our site, you consent to cookies.
        </h1>
        <Link to="/privacy-policy" className={classes.textDecoration}>
          <Button className={classes.learnMoreButton}>Learn more</Button>
        </Link>
        <Clear onClick={() => dispatch({ type: "setPrivacyNotice", payload: false })} className={classes.closeIcon} />
      </div>
    </Fade>
  );
};

export default PrivacyPopup;
