import { makeStyles, createStyles } from "@material-ui/core";

const HomeStylesheet = makeStyles(() =>
  createStyles({
    navContainer: {
      position: "fixed",
      top: "0px",
      left: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100px",
      width: "100%",
      paddingLeft: "25px",
      paddingRight: "25px",
      zIndex: "10",
      background: "transparent",
      transition: "ease-out 1s",
    },
    navContainerSolid: {
      background: "#fff",
      boxShadow: "0 -20px 50px rgba(0,0,0,0.2)",
      transition: "ease-in 1s",
    },
    logoContainer: {
      width: "220px",
      height: "50px",
      position: "relative",
    },
    navLogoWhite: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      opacity: "1",
      transition: "1s",
    },
    navLogoColor: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      opacity: "1",
      transition: "1s",
    },
    logoHidden: {
      opacity: "0 !important",
      transition: "1s !important",
    },
    navLinksContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    navLinksStyles: {
      fontFamily: "Nunito",
      color: "#333333",
      fontSize: "18px",
      padding: "15px 25px",
      cursor: "pointer",
      opacity: "0.6",
      transition: "0.3s",
      textDecoration: "none",
      textTransform: "none",
      "&:hover": {
        transition: "0.3s",
        opacity: "1",
      },
    },
    deskTopPadding: {
      padding: "15px",
    },
    navLinkOverrides: {
      textDecoration: "none",
    },
    navLinkActive: {
      opacity: "1 !important",
    },
    imgOverlay: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: "60px",
      left: "0px",
      backgroundRepeat: "no-repeat !important",
      backgroundPosition: "center center !important",
      backgroundSize: "cover !important",
      opacity: "0.15",
    },
    imgOverlay2: {
      height: "100%",
      width: "calc(120%)",
      position: "absolute",
      top: "60px",
      left: "0px",
      backgroundRepeat: "no-repeat !important",
      backgroundPosition: "center center !important",
    },
    mobileNavContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    mobileIconStyles: {
      color: "#fff",
      transition: "1s",
    },
    mobileIconStylesLighter: {
      color: "#333333",
      transition: "1s",
    },
    mobileNavColor: {
      color: "#cd1041 !important",
      width: "100%",
      height: "100%",
    },
    aboutSection: {
      position: "relative",
      padding: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    includedSection: {
      position: "relative",
      padding: "190px 0px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "10px",
    },
    slideSection: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    customizedSection: {
      paddingTop: "200px",
    },
    circlePulse: {
      zIndex: "1000",
      position: "absolute",
      filter: "drop-shadow(0px 3px 12px rgba(9, 85, 128, 0.12))",
      transition: "0.7s",
    },
    circlePulsePosition1: {
      top: "50px",
      left: "50px",
    },
    circlePulsePosition2: {
      top: "360px",
      left: "30px",
    },
    circlePulsePosition3: {
      top: "100px",
      left: "20px",
    },
    circlePulsePosition4: {
      top: "125px",
      left: "140px",
    },
    circlePulsePosition5: {
      top: "60px",
      left: "50px",
    },
    circlePulsePosition6: {
      top: "230px",
      left: "70px",
    },
    phoneContainer: {
      position: "relative",
    },
    whiteOuter: {
      backgroundColor: "#fff",
      width: "34px",
      height: "34px",
      borderRadius: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    slideImages: {
      margin: "0px 84px",
    },
    para1: {
      left: "-310px",
      marginBottom: "16px",
      position: "absolute",
      top: "-238px",
    },
    para2: {
      left: "-310px",
      top: "20px",
      marginBottom: "16px",
      position: "absolute",
    },
    pricingSection: {
      background: "#fafafa",
      position: "relative",
      paddingTop: "50px",
      paddingBottom: "150px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      overflow: "hidden",
    },
    contactSection: {
      background: "#fff",
      position: "relative",
      padding: "150px 0px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    stitch: {
      background: "#f9f9f9",
      marginTop: "-10px",
    },
    aboutSectionContentContainer: {
      position: "relative",
      width: "80%",
      zIndex: "9",
      maxWidth: "1085px",
    },
    includeBoxes: {
      maxWidth: "1180px",
      paddingBottom: "100px",
    },
    makeParent: {
      position: "absolute",
      marginLeft: "auto",
      marginRight: "auto",
      left: "0",
      right: "0",
      textAlign: "center",
      top: "350px",
    },
    includeMake: {
      fontSize: "40px",
      fontWeight: "400",
      fontFamily: "nunito",
      marginBottom: "18px",
    },
    includeMakeSub: {
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "400",
      color: "#606060",
      fontFamily: "nunito",
    },
    topWaveInclude: {
      position: "absolute",
      top: "-160px",
      left: "0px",
      width: "100%",
      zIndex: "-1",
    },
    contactWave: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      bottom: "0px",
      height: "100%",
    },
    includeSectionContentContainer: {
      backgroundImage: "linear-gradient(#f9f9f9, #fefefe)",
      position: "relative",
      width: "100%",
      zIndex: "9",
      justifyContent: "center",
    },
    pricingSectionContentContainer: {
      position: "relative",
      width: "50%",
      maxWidth: "726px",
      zIndex: "9",
    },
    section2And3Styles: {
      opacity: "1 !important",
      filter: "drop-shadow(0px 0px 30px rgba(0,0,0,0.06))",
    },
    section2Background: {
      background: "url(/assets/curve-bg.png)",
    },
    section3Background: {
      background: "url(/assets/wave.png)",
    },
    delay: {
      animationDelay: "1s",
    },
    fadeInLeft: {
      animationName: "$fadeInLeft",
    },
    fadeInRight: {
      animationName: "$fadeInRight",
    },
    fadeInUp: {
      animationName: "$fadeInUp",
    },
    fadeInDown: {
      animationName: "$fadeInDown",
    },
    aboutHeader: {
      textAlign: "center",
      fontSize: "48px",
      fontFamily: "Nunito",
      margin: "107px 0px 64px 0px",
      lineHeight: "1",
      fontWeight: "700",
    },
    includeHeader: {
      textAlign: "center",
      fontSize: "48px",
      fontFamily: "Nunito",
      margin: "0px 0px 37px 0px",
      lineHeight: "1",
      fontWeight: "700",
    },
    localBoxes: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      height: "221px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 30px 50px rgba(0,0,0,0.08)",
      borderRadius: "16px",
      margin: "0px 23px",
    },
    includeImage: {
      margin: "32px 0px 16px",
    },
    includeBoxTitle: {
      fontSize: "24px",
      fontFamily: "Nunito",
      margin: "0px 0px 12px 0px",
      lineHeight: "1",
      fontWeight: "700",
      color: "#6B6B6B",
    },
    includeBoxSubtitle: {
      fontSize: "16px",
      fontFamily: "Nunito",
      margin: "0px 0px 16px 0px",
      lineHeight: "22px",
      fontWeight: "400",
      color: "#6B6B6B",
      textAlign: "center",
    },
    taptopPic: {
      width: "687px",
      height: "392px",
    },
    aboutBackground: {
      position: "absolute",
      top: "0",
      height: "100px",
      width: "100%",
      backgroundColor: "#e7f5fd",
    },
    aboutBackgroundCurve: {
      borderRadius: "60px 60px 0px 0px",
      zIndex: "1",
      position: "absolute",
      top: "0",
      height: "100px",
      width: "100%",
      backgroundColor: "#fafafa",
      boxShadow: "0px -10px 15px 0px rgba(0,0,0,0.04)",
    },
    pricingHeader: {
      fontSize: "48px",
      fontFamily: "Nunito",
      margin: "20px 0px 15px 0px",
      lineHeight: "1",
      fontWeight: "700",
    },
    aboutDescriptions: {
      textAlign: "center",
      color: "#6B6B6B",
      fontSize: "20px",
      fontFamily: "Nunito",
      fontWeight: "400",
      lineHeight: " 27px",
    },
    includeDescriptions: {
      textAlign: "center",
      color: "#6B6B6B",
      fontSize: "16px",
      fontFamily: "Nunito",
      fontWeight: "400",
      lineHeight: " 21px",
    },
    aboutList: {
      paddingLeft: "0px",
      listStyle: "none",
      margin: "17px 20px",
    },
    aboutListItem: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontFamily: "Nunito",
      fontSize: "24px",
      fontWeight: "400",
    },
    outerPhone: {
      position: "absolute",
      width: "352px",
      height: "730px",
      zIndex: "10",
    },
    innerPhone: {
      width: "315px",
      position: "relative",
      top: "25px",
    },
    redTop: {
      position: "absolute",
      width: "315px",
      height: "40px",
      top: "0px",
      backgroundColor: "#c8ac69",
      zIndex: "1",
    },
    listIcons: {
      width: "42px",
      height: "42px",
      margin: "27.5px 46px 22.5px 35px",
    },
    reportParent: {
      height: "698px",
      left: "20px",
      position: "absolute",
      top: "15px",
      overflow: "hidden",
      zIndex: "1",
    },
    changeParent: {
      transition: "0.7s",
      position: "relative",
    },
    aboutListIcons: {
      marginRight: "5px",
      marginTop: "-3px",
      fontSize: "30px",
      color: "#CD1041",
    },
    aboutButtonLink: {
      textDecoration: "none",
    },
    aboutButton: {
      background: "linear-gradient(180deg, #FF507A 0%, #E14864 100%)",
      fontFamily: "Nunito",
      color: "#fff",
      textTransform: "uppercase",
      boxShadow: "0 8px 14px rgba(0,0,0,0.2)",
    },
    contactButton: {
      marginTop: "25px",
      marginBottom: "85px",
      borderRadius: "12px",
      background: " linear-gradient(180deg, #FF507A 0%, #E14864 100%)",
      fontSize: "14px",
      fontFamily: "Nunito",
      fontWeight: "bold",
      color: "#fff",
      textTransform: "uppercase",
      padding: "14px 27px",
      minWidth: "175px",
      minHeight: "52px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0 8px 14px rgba(0,0,0,0.2)",
      "&:hover": {
        opacity: "0.8",
      },
    },
    contactButtonIconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    contactIcon: {
      fontSize: "25px",
      color: "#fff",
    },
    image: {
      boxShadow: "0 30px 50px rgba(0,0,0,0.08)",
    },
    bold: {
      fontWeight: "700 !important",
    },
    place1: {
      position: "absolute",
      top: "129px",
    },
    place2: {
      position: "absolute",
      right: "30px",
      top: "50px",
    },
    includedPlace1: {
      maxWidth: "580px",
      position: "absolute",
      top: "400px",
      right: "30px",
    },
    includedPlace2: {
      maxWidth: "500px",
      position: "absolute",
      top: "400px",
      right: "190px",
    },
    cutomizedPlace1: {
      maxWidth: "580px",
      position: "absolute",
      top: "480px",
      right: "0px",
    },
    customizedPlace2: {
      position: "absolute",
      right: "390px",
      maxWidth: "500px",
      top: "350px",
    },
    cutomizedInclude1: {
      maxWidth: "580px",
      position: "absolute",
      top: "-890px",
      right: "0px",
    },
    customizedInclude2: {
      position: "absolute",
      left: "0px",
      maxWidth: "500px",
      top: "2000px",
    },
    cutomizedTopWave: {
      position: "absolute",
      left: "0px",
      bottom: "580px",
      zIndex: "1",
      width: "100%",
    },
    includeImageParent: {
      padding: "0px 60px",
      maxWidth: "1435px",
      margin: "auto",
    },
    cutomizedBottomWave: {
      position: "absolute",
      left: "0px",
      top: "630px",
      zIndex: "1",
      width: "100%",
    },
    aboutTextPadding: {
      paddingRight: "45px",
      position: "relative",
      height: "730px",
    },
    textPadding1: {
      paddingLeft: "45px",
    },
    aboutPhotoContainer: {
      position: "relative !important",
      width: "100%",
      zIndex: "8",
    },
    includePhotoContainer2: {
      overflow: "hidden",
      position: "relative !important",
      width: "100%",
      zIndex: "8",
      backgroundColor: "#f7f7f7",
      height: "800px",
    },
    includePhotoContainer: {
      position: "relative !important",
      width: "100%",
      zIndex: "8",
      display: "flex",
      justifyContent: "center",
      margin: "40px 0px 88px",
    },
    aboutButtonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    pricingContainer: {
      alignItems: "flex-start",
      maxWidth: "1600px",
      width: "80%",
    },
    pricingHeaderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      fontFamily: "nunito",
      fontSize: "48px",
      fontWeight: "700",
      lineHeight: "65px",
      padding: "0px 15px 0px 15px",
    },
    priceInfo: {
      position: "relative",
      background: "#535f6f",
      padding: "50px 40px 60px 40px",
      borderRadius: "10px",
      margin: "0px 15px 20px 15px",
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    subHeader: {
      textAlign: "center",
      marginBottom: "60px",
    },
    pricesubHeader: {
      fontFamily: "nunito",
      fontSize: "16px",
      color: "#6B6B6B",
      fontWeight: "400",
      marginBottom: "675px",
    },
    pricesubSubHeader: {
      fontFamily: "nunito",
      fontSize: "16px",
      color: "#6B6B6B",
      fontWeight: "400",
    },
    delay0: {
      animationDelay: "0.3s",
    },
    delay1: {
      animationDelay: "0.5s",
    },
    delay2: {
      animationDelay: "0.7s",
    },
    delay3: {
      animationDelay: "0.9s",
    },
    delay4: {
      animationDelay: "1.3s",
    },
    plan: {
      color: "#fff",
      opacity: "0.5",
      fontWeight: "700",
      marginBottom: "0",
      fontFamily: "Nunito",
      fontSize: "20px",
    },
    users: {
      fontSize: "36px",
      fontWeight: "500",
      fontFamily: "Nunito",
      margin: "0px",
      color: "#fff",
    },
    price: {
      fontSize: "70px",
      margin: "30px 0px",
      lineHeight: "35px",
      color: "#fff",
      fontWeight: "500",
      fontFamily: "Nunito",
      display: "flex",
      alignItems: "center",
    },
    sub: {
      fontSize: "24px",
      marginTop: "27px",
      marginRight: "15px",
    },
    period: {
      fontWeight: "700",
      color: "#fff",
      opacity: "0.3",
      fontSize: "18px",
      fontFamily: "Nunito",
    },
    buttonMargin: {
      marginTop: "40px",
    },
    em: {
      color: "#999",
    },
    contactHeaderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    contactSubHeaderSpace: {
      marginBottom: "40px",
      textAlign: "center",
    },
    contactForm: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    contactInputContainer: {
      width: "100%",
    },
    contactInput: {
      width: "100%",
      marginBottom: "32px",
    },
    loginModalContainer: {
      padding: "10px",
      background: "#fff",
      borderRadius: "4px",
      width: "25%",
      visibility: "unset !important",
    },
    loginLogoContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#f3f3f3",
      width: "100%",
      padding: "20px",
    },
    loginLogo: {
      height: "45px",
    },
    modalBody: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "30px",
    },
    loginHeader: {
      fontSize: "36px",
      fontWeight: "400",
      color: "#777",
      fontFamily: "Nunito",
      margin: "10px 0px 40px 0px",
    },
    forgotPassword: {
      textTransform: "uppercase",
      textAlign: "center",
      fontSize: "13px",
      fontFamily: "Nunito",
      color: "#CD1041",
      letterSpacing: "2px",
      fontWeight: "700",
      marginTop: "25px",
      opacity: "1",
      transition: "0.3s",
      "&:hover": {
        opacity: "0.5",
      },
    },
    wantsToBeRememberedContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    rememberMe: {
      color: "#777",
      fontSize: "13px",
      fontFamily: "Nunito",
      fontWeight: "500",
    },
    mobileLoginButton: {
      width: "100%",
      height: "100%",
      padding: "15px 0px",
    },
    mobileItemPadding: {
      padding: "6px 0px",
    },
    pricingDescriptions: {
      fontWeight: "400",
      fontFamily: "nunito",
      lineHeight: "21px",
      fontSize: "16px",
      color: "#6B6B6B",
      marginBottom: "55px",
      textAlign: "center",
    },
    contactLeft: {
      paddingRight: "12px",
    },
    contactRight: {
      paddingLeft: "12px",
    },
    "@keyframes gradientShift": {
      "0%": {
        backgroundPosition: "0% 75%",
      },
      "50%": {
        backgroundPosition: "100% 26%",
      },
      "100%": {
        backgroundPosition: "0% 75%",
      },
    },
    "@keyframes fadeInLeft": {
      from: {
        opacity: "0px",
        transform: "translate3d(-50px, 0, 0)",
      },
      to: {
        opacity: "1",
        transform: "translate3d(0, 0, 0)",
      },
    },
    "@keyframes fadeInRight": {
      from: {
        opacity: "0",
        transform: "translate3d(50px, 0, 0)",
      },
      to: {
        opacity: "1",
        transform: "translate3d(0, 0, 0)",
      },
    },
    "@keyframes fadeInUp": {
      from: {
        opacity: "0",
        transform: "translate3d(0, 100%, 0)",
      },
      to: {
        opacity: "1",
        transform: "translate3d(0, 0, 0)",
      },
    },
    "@keyframes fadeInDown": {
      from: {
        opacity: "0",
        transform: "translate3d(0, -100%, 0)",
      },
      to: {
        opacity: "1",
        transform: "translate3d(0, 100%, 0)",
      },
    },
    mobileSlide: {
      position: "absolute",
      top: "160px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "@media (max-width: 1480px)": {
      cutomizedInclude1: {
        maxWidth: "400px",
      },
      customizedInclude2: {
        maxWidth: "400px",
      },
      place1: {
        maxWidth: "550px",
        top: "265px",
      },
      place2: {
        maxWidth: "480px",
        top: "155px",
      },
      includedPlace1: {
        top: "400px",
        right: "0px",
      },
      includedPlace2: {
        top: "400px",
        right: "100px",
      },
      cutomizedPlace1: {
        maxWidth: "520px",
      },
      customizedPlace2: {
        right: "280px",
        maxWidth: "460px",
      },
      pricingHeaderContainer: {
        textAlign: "center",
      },
      loginModalContainer: {
        width: "35%",
      },
    },
    "@media (max-width: 1280px)": {
      cutomizedInclude1: {
        maxWidth: "300px",
      },
      customizedInclude2: {
        maxWidth: "300px",
      },
      place1: {
        maxWidth: "70%",
      },
      place2: {
        maxWidth: "70%",
      },
      includedPlace1: {
        maxWidth: "80%",
        top: "400px",
        right: "0px",
      },
      includedPlace2: {
        maxWidth: "80%",
        top: "400px",
        right: "60px",
      },
      cutomizedPlace1: {
        maxWidth: "480px",
      },
      customizedPlace2: {
        right: "240px",
        maxWidth: "450px",
      },
      waveMobile: {
        height: "280px",
      },
      aboutSectionContentContainer: {
        width: "95%",
      },
    },
    "@media (max-width: 1055px)": {
      cutomizedInclude1: {
        top: "-7px",
        maxWidth: "200px",
      },
      customizedInclude2: {
        top: "2121px",
        maxWidth: "200px",
      },
      pricingHeader: {
        fontSize: "34px",
      },
      aboutDescriptions: {
        fontSize: "18px",
      },
      aboutButton: {
        fontSize: "13px",
      },
      contactButton: {
        fontSize: "13px",
      },
      cutomizedPlace1: {
        maxWidth: "460px",
      },
      customizedPlace2: {
        right: "210px",
        maxWidth: "440px",
      },
      includeBoxSubtitle: {
        fontSize: "13px",
      },
    },
    "@media (max-width: 960px)": {
      aboutPhotoContainer: {
        paddingBottom: "20px",
        left: "160px",
      },
      includeBoxes: {
        maxWidth: "440px",
      },
      includeBoxSubtitle: {
        fontSize: "16px",
      },
      contactLeft: {
        paddingRight: "0px",
      },
      contactRight: {
        paddingLeft: "0px",
      },

      customizedSection: {
        paddingBottom: "350px",
      },
      place1: {
        maxWidth: "50%",
        top: "330px",
      },
      place2: {
        maxWidth: "50%",
        top: "215px",
      },

      includedPlace1: {
        top: "560px",
      },
      includedPlace2: {
        right: "120px",
      },
      cutomizedPlace1: {
        maxWidth: "50%",
        top: "740px",
        right: "90px",
      },
      customizedPlace2: {
        maxWidth: "50%",
        right: "260px",
        top: "480px",
      },
      loginModalContainer: {
        width: "45%",
      },
      aboutTextPadding: {
        display: "flex",
        justifyContent: "center",
        right: "130px",
      },
    },
    "@media (max-width: 905px)": {
      aboutPhotoContainer: {
        left: "0px",
      },
      aboutListItem: {
        justifyContent: "center",
      },
      listIcons: {
        margin: "27px 21px 22px 11px",
      },
      includeDescriptions: {
        maxWidth: "80%",
        margin: "0 auto",
      },
      taptopPic: {
        height: "unset",
        width: "407px",
      },
    },
    "@media (max-width: 860px)": {
      makeParent: {
        position: "unset",
        padding: "10px 0px 20px",
      },
      cutomizedInclude1: {
        position: "relative",
        top: "-7px",
        maxWidth: "305px",
      },
      customizedInclude2: {
        position: "relative",
        top: "0px",
        maxWidth: "305px",
      },
      cutomizedTopWave: {
        width: "unset",
        left: "-450px",
      },
      cutomizedBottomWave: {
        width: "unset",
        left: "-450px",
      },
    },
    "@media (max-width: 768px)": {
      waveMobile: {
        height: "350px",
      },
      typeWriteStyles: {
        fontSize: "55px",
      },
      videoContainer: {
        width: "90%",
        height: "60%",
      },
      aboutSection: {
        padding: "45px 25px",
      },
      customizedSection: {
        paddingBottom: "200px",
      },
      includedPlace1: {
        top: "480px",
      },
      aboutTextPadding: {
        paddingRight: "0px",
      },
      textPadding1: {
        paddingLeft: "0px",
      },
      pricingSection: {
        paddingTop: "250px",
        paddingBottom: "50px",
      },
      contactSection: {
        paddingTop: "50px",
      },
      pricingSectionContentContainer: {
        position: "relative",
        width: "70%",
        maxWidth: "950px",
        zIndex: "9",
      },
      loginModalContainer: {
        width: "55%",
      },
      aboutTextPadding: {
        right: "160px",
      },
    },
    "@media (max-width: 737px)": {
      aboutList: {
        margin: "17px 0px",
      },
    },
    "@media (max-width: 660px)": {
      includedPlace2: {
        right: "60px",
      },
      customizedSection: {
        paddingTop: "100px",
        paddingBottom: "250px",
      },
      cutomizedPlace1: {
        maxWidth: "70%",
        top: "700px",
        right: "0px",
      },
      customizedPlace2: {
        maxWidth: "70%",
        right: "90px",
        top: "480px",
      },
      pricingContainer: {
        width: "95%",
      },
      priceInfo: {
        margin: "0px 10px 15px 10px",
        padding: "40px 20px",
      },
      loginModalContainer: {
        width: "65%",
      },
    },
    "@media (max-width: 468px)": {
      aboutButtonContainer: {
        justifyContent: "center",
      },
      place1: {
        top: "315px",
      },
      place2: {
        top: "200px",
      },
      aboutSectionContentContainer: {
        width: "95%",
      },
      includedPlace1: {
        top: "545px",
      },
      includedPlace2: {
        top: "375px",
      },
      customizedSection: {
        paddingTop: "0px",
        paddingBottom: "500px",
      },
      cutomizedPlace1: {
        maxWidth: "70%",
        top: "650px",
        right: "0px",
      },
      customizedPlace2: {
        maxWidth: "70%",
        left: "15px",
        top: "400px",
      },
      pricingSection: {
        paddingTop: "0px",
        marginTop: "0px !important",
      },

      plan: {
        fontSize: "18px",
      },
      users: {
        fontSize: "26px",
      },
      price: {
        fontSize: "34px",
        margin: "10px 0px",
        lineHeight: "35px",
      },
      sub: {
        fontSize: "16px",
        marginTop: "10px",
        marginRight: "10px",
      },
      buttonMargin: {
        marginTop: "20px",
      },
      pricingHeader: {
        fontSize: "28px",
      },
      loginModalContainer: {
        width: "90%",
      },
    },
  })
);

export default HomeStylesheet;
