import React, { useContext } from "react";
import PropTypes from "prop-types";
import HomeStylesheet from "../HomeStylesheet";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { AppStateContext, AppDispatchContext } from "../../../Context/AppReducer";
import { Button } from "@material-ui/core";

const DeskNav = ({ navItemActive, setNavItemActive, setActiveView }) => {
  const classes = HomeStylesheet();
  const globalDispatch = useContext(AppDispatchContext);
  const globalState = useContext(AppStateContext);

  return (
    <div className={classes.navLinksContainer}>
      <ScrollLink to="home" smooth={true} duration={500} title="Go to the home section" className={classes.navLinkOverrides}>
        <Button size="medium" onClick={() => setNavItemActive("home")} className={`${classes.navLinksStyles} ${classes.deskTopPadding} ${navItemActive === "home" ? classes.navLinkActive : ""}`}>
          Home
        </Button>
      </ScrollLink>
      <ScrollLink to="about" smooth={true} offset={-70} duration={1000} title="Go to the about section" className={classes.navLinkOverrides}>
        <Button size="medium" onClick={() => setNavItemActive("about")} className={`${classes.navLinksStyles} ${classes.deskTopPadding} ${navItemActive === "about" ? classes.navLinkActive : ""}`}>
          About
        </Button>
      </ScrollLink>
      <ScrollLink to="included" smooth={true} offset={-110} duration={1000} title="Go to the included section" className={classes.navLinkOverrides}>
        <Button onClick={() => setNavItemActive("included")} className={`${classes.navLinksStyles} ${classes.deskTopPadding} ${navItemActive === "included" ? classes.navLinkActive : ""}`}>
          Included
        </Button>
      </ScrollLink>
      {/* <ScrollLink
        to="customized"
        smooth={true}
        duration={1000}
        title="Go to the customized section"
        className={classes.navLinkOverrides}
      >
        <Button onClick={() => setNavItemActive("customized")} className={`${classes.navLinksStyles} ${classes.deskTopPadding} ${navItemActive === "customized" ? classes.navLinkActive : ""}`}>
          Customized
        </Button>
      </ScrollLink> */}
      <ScrollLink to="contact" smooth={true} offset={20} duration={1000} title="Go to the contact section" className={classes.navLinkOverrides}>
        <Button onClick={() => setNavItemActive("contact")} className={`${classes.navLinksStyles} ${classes.deskTopPadding} ${navItemActive === "contact" ? classes.navLinkActive : ""}`}>
          Contact
        </Button>
      </ScrollLink>
      {globalState.loggedIn ? (
        <Link to={`/${globalState.user.username}/dashboard`} className={`${classes.navLinksStyles} ${classes.deskTopPadding}`}>
          <span onClick={() => setNavItemActive("dashboard")} className={navItemActive === "dashboard" ? classes.navLinkActive : ""}>
            Dashboard
          </span>
        </Link>
      ) : (
        <ScrollLink to="home" smooth={true} offset={20} duration={1000} title="Go to the home section" className={classes.navLinkOverrides}>
          <Button
            onClick={() => {
              setActiveView(3);
              setNavItemActive("login");
              globalDispatch({ type: "setActiveView", payload: "Login" });
            }}
            className={`${classes.navLinksStyles} ${classes.deskTopPadding} ${navItemActive === "login" ? classes.navLinkActive : ""}`}
          >
            Login
          </Button>
        </ScrollLink>
      )}
    </div>
  );
};

DeskNav.propTypes = {
  navItemActive: PropTypes.string,
  setNavItemActive: PropTypes.func,
  state: PropTypes.object,
  needsToBeLighter: PropTypes.bool,
  handleLoginModal: PropTypes.func,
  setActiveView: PropTypes.func,
};

export default DeskNav;
