import { makeStyles, createStyles } from "@material-ui/core";

const HeaderStylesheet = makeStyles((theme) =>
  createStyles({
    header1: {
      position: "relative",
      height: "950px",
    },
    step1: {
      background: "linear-gradient(to right, #2D9CDB 25.92%, rgba(19, 206, 102, 0.282) 87.04%)",
    },
    svgs: {
      top: "-15px",
      position: "absolute",
      transition: ".5s",
    },
    svgtransition: {
      transition: "1s",
    },
    hidden: {
      opacity: "0",
      visibility: "hidden",
    },
    svgImage: {
      transition: "1s",
      top: "-15px",
      position: "absolute",
      right: "-90px",
    },
    headerContent: {
      position: "absolute",
      top: "0px",
      left: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      zIndex: "9",
      paddingLeft: "170px",
      paddingRight: "175px",
    },
    headerContainer: {
      textAlign: "left",
    },
    SocialemailAlreadyInUseError: {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Nunito",
      color: "#f44336",
      margin: "15px 0px 0px",
    },
    progress: {
      width: "24px !important",
      height: "24px !important",
      color: "#fff",
    },
    header: {
      color: "#fff",
      fontSize: "36px",
      fontWeight: "700",
      fontFamily: "Nunito",
      marginBottom: "10px",
    },
    typeWriteStyles: {
      color: "#fff",
      fontSize: "65px",
      fontWeight: "700",
      fontFamily: "Nunito",
      lineHeight: "1",
      cursor: "default",
      maxWidth: "440px",
    },
    headerPhoneContainer: {
      height: "420px",
      width: "440px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerPhone: {
      height: "640px",
      width: "660px",
    },
    waveContainer: {
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    wave: {
      background: "url(/assets/wave-overlay-vertical.png)",
      height: "100%",
      width: "200px",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      position: "absolute",
      top: "0px",
      right: "-5px",
      zIndex: "8",
    },
    waveMobile: {
      background: "url(/assets/wave-overlay.png)",
      height: "400px",
      backgroundPosition: "center top",
      backgroundSize: "cover",
      backgroundRepeat: "none",
      position: "absolute",
      bottom: "-5px",
      left: "0px",
      right: "0px",
      zIndex: "8",
    },
    allViewsContainer: {
      height: "950px",
      transition: "1.5s",
      position: "relative",
    },
    onBoarding: {
      position: "absolute",
      zIndex: "10",
    },
    noActive: {
      display: "none",
    },
    onBoardingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
    },
    viewContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "60px 120px 60px 60px",
      width: "100%",
    },
    viewContainerSocial: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "100px 0px 60px 0px",
      width: "444px",
      marginRight: "320px",
    },
    viewContainerTop: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "120px 120px 60px 120px",
      width: "100%",
    },
    socialCenter: {
      display: "flex",
      justifyContent: "flex-end",
    },
    viewContainerSmall: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "120px 120px 60px 160px",
      width: "100%",
    },
    viewHeaders: {
      fontFamily: "Nunito",
      fontWeight: "bold",
      fontSize: "48px",
      color: "#000000",
      margin: "10px 0px 10px 0px",
    },
    logInHr: {
      margin: "0",
      width: "440px",
      marginBottom: "38px",
      border: "0.5px solid #E0E0E0",
      marginTop: "15px",
    },
    socialButtonsContainer: {
      width: "85%",
      marginTop: "40px",
      marginBottom: "40px",
    },
    viewHeaderSmall: {
      fontFamily: "Nunito",
      fontWeight: "700",
      fontSize: "32px",
      color: "#000000",
    },
    viewHeaderSmallMargin: {
      marginBottom: "15px",
    },
    socialButtonsSmallContainer: {
      width: "85%",
      marginTop: "10px",
      marginBottom: "40px",
      display: "flex",
      flexDirection: "row",
    },
    socialButtons: {
      minWidth: "400px",
      justifyContent: "flex-start",
      padding: "15px 25px 15px 25px",
      borderRadius: "5px",
      opacity: "1",
      transition: "0.2s",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    socialButtonsSmall: {
      justifyContent: "center",
      padding: "10px 10px",
      borderRadius: "2px",
      opacity: "1",
      transition: "0.2s",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    faceBook: {
      color: "#fff",
      marginBottom: "25px",
      background: "#3B5998",
      transition: "0.2s",
      boxShadow: "0px 2px 2px rgba(0,0,0,0.1)",
      "&:hover": {
        background: "#3B5998",
        transition: "0.2s",
      },
    },
    faceBookSmall: {
      color: "#fff",
      marginRight: "25px",
      background: "#3B5998",
      transition: "0.2s",
      boxShadow: "0px 2px 2px rgba(0,0,0,0.1)",
      "&:hover": {
        background: "#3B5998",
        transition: "0.2s",
      },
    },
    google: {
      color: "#000",
      background: "#fff",
      transition: "0.2s",
      boxShadow: "0px 2px 2px rgba(0,0,0,0.1)",
      "&:hover": {
        background: "#fff",
        transition: "0.2s",
      },
    },
    socialText: {
      fontSize: "18px",
      fontFamily: "Nunito",
      paddingLeft: "30px",
    },
    socialTextGoogle: {
      fontSize: "18px",
      fontFamily: "Nunito",
      fontWeight: "800",
      paddingLeft: "30px",
    },
    socialIcons: {
      color: "#fff",
      fontSize: "24px !important",
    },
    socialIconsSmall: {
      color: "#fff",
      fontSize: "40px !important",
    },
    continueWithEmail: {
      marginTop: "15px",
      color: "#2D9CDB",
      fontSize: "14px",
      fontWeight: "800",
      fontFamily: "Nunito",
      textDecoration: "underline",
      marginBottom: "0px",
      cursor: "pointer",
    },
    alreadyHasAccount: {
      color: "#A3A3A3",
      fontSize: "14px",
      fontWeight: "800",
      fontFamily: "Nunito",
      textDecoration: "underline",
      marginTop: "15px",
      cursor: "pointer",
    },
    orContinueWith: {
      marginTop: "40px",
      marginBottom: "0px",
      fontSize: "18px",
    },
    inputSpacing: {
      margin: "10px 0px",
      width: "90%",
    },
    nextSlideContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    nextSlideButton: {
      height: "48px",
      background: "#BDBDBD",
      cursor: "default",
      "&:hover": {
        background: "#BDBDBD",
      },
    },
    loginError: {
      color: "red",
      marginLeft: "50px",
    },
    allInfoIsGood: {
      background: "#2D9CDB",
      cursor: "pointer",
      "&:hover": {
        background: "#2D9CDB",
      },
    },
    nextSlideButtonText: {
      color: "#FFFFFF",
    },
    emailAlreadyInUseError: {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Nunito",
      color: "#f44336",
      margin: "0px",
    },
    loginParent: {
      width: "440px",
      display: "flex",
      flexDirection: "row",
    },
    loginParentLeft: {
      width: "400px",
    },
    contactWave: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      bottom: "0px",
      height: "950px",
    },
    loginParentRight: {
      marginTop: "15px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    emailParent: {
      width: "400px",
      display: "flex",
      justifyContent: "space-between",
    },
    "@media (max-width: 1600px)": {
      viewContainerSmall: {
        padding: "120px 120px 60px 60px",
      },
    },
    "@media (max-width: 1560px)": {
      viewContainerSocial: {
        marginRight: "200px",
      },
    },
    "@media (max-width: 1500px)": {
      viewContainerSmall: {
        padding: "120px 120px 60px 60px",
      },
      viewContainerTop: {
        marginTop: "150px",
        padding: "120px 120px 60px 60px",
      },
      alreadyHasAccount: {
        marginBottom: "110px",
      },
    },
    "@media (max-width: 1400px)": {
      viewContainerSocial: {
        marginRight: "100px",
      },
      headerContent: {
        paddingLeft: "100px",
      },
    },
    "@media (max-width: 1300px)": {
      viewContainerTop: {
        padding: "120px 60px 60px 0px",
      },
      headerContent: {
        paddingLeft: "50px",
      },
    },
    "@media (max-width: 1050px)": {
      viewContainerTop: {
        padding: "120px 30px 60px 0px",
      },
      headerContent: {
        paddingLeft: "50px",
      },
    },
    "@media (max-width: 960px)": {
      viewContainerSmall: {
        width: "560px",
        padding: "90px 30px 60px 70px",
      },
      onBoarding: {
        justifyContent: "center",
      },
      viewContainerTop: {
        width: "560px",
      },
      viewContainerSocial: {
        marginRight: "10px",
      },
      continueWithEmail: {
        color: "#fff",
      },
      alreadyHasAccount: {
        color: "#fff",
      },
    },
  })
);

export default HeaderStylesheet;
