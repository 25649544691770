import { withStyles, TextField } from "@material-ui/core";

const PersonalInfoSelect = withStyles((theme) => ({
  root: {
    width: "100%",
    '& label': {
      color: '#BDBDBD',
      fontWeight: "500",
      fontFamily: "nunito"
    },
    '& label.Mui-focused': {
      color: theme.palette.areaPulseBlue,
      fontWeight: "500",
      fontFamily: "nunito"
    },
    '& .MuiFilledInput-root': {
      color: "#000",
      background: "#FAFAFA",
      borderRadius: "10px",
      fontWeight: "400",
      fontSize: "14px",
      borderColor: "rgba(0, 0, 0, 0.08)",
      boxShadow: "inset 0px 0px 7px rgba(0, 0, 0, 0.08)",
      '&.MuiFilledInput-underline:before': {
        display: "none"
      },
      '&.MuiFilledInput-underline:after': {
        display: "none"
      }
    },
  }
}))(TextField);

export default PersonalInfoSelect;