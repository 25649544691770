import React, { useEffect, useState } from "react";
import { Grid, withStyles, TextField, Checkbox } from "@material-ui/core";
import Typewriter from "typewriter-effect/dist/core";
import HomeStylesheet from "./HomeStylesheet";

import WOW from "wow.js";
import Navigation from "./Navigation/Navigation";
import Header from "./Sections/Header/Header";
import About from "./Sections/About";
import Included from "./Sections/Included";
import SlidingReports from "./Sections/SlidingReports";
import Customized from "./Sections/Customized";
import Pricing from "./Sections/Pricing";
import Contact from "./Sections/Contact";
import Footer from "../Common/Footer/Footer";
import PrivacyPopup from "../PrivacyPolicy/PrivacyPopup";
import PropTypes from "prop-types";

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#CD1041",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "transparent",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#CD1041",
    },
    "& .MuiFilledInput-root": {
      background: "#f7f7f7",
      borderRadius: "4px",
    },
  },
})(TextField);

const CustomCheckBox = withStyles({
  root: {
    color: "#CD1041",
  },
})((props) => <Checkbox color="default" {...props} />);

const Home = ({ googleScriptLoaded }) => {
  const classes = HomeStylesheet();

  const [activeView, setActiveView] = useState(2);

  useEffect(() => {
    //Constructor for WOW.
    const wow = new WOW();
    wow.init();
  }, []);

  return (
    <Grid container>
      <Navigation setActiveView={setActiveView} CustomTextField={CustomTextField} CustomCheckBox={CustomCheckBox} />
      <PrivacyPopup />
      <Grid item xs={12}>
        <Header setActiveView={setActiveView} activeView={activeView} googleScriptLoaded={googleScriptLoaded} />
      </Grid>
      <Grid item xs={12}>
        <About />
      </Grid>
      <Grid item xs={12}>
        <Included />
      </Grid>
      <Grid item xs={12}>
        <SlidingReports />
        {/* <Customized /> */}
      </Grid>
      <Grid item xs={12}>
        <Pricing />
      </Grid>
      <Grid item xs={12}>
        <Contact CustomTextField={CustomTextField} />
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

Home.propTypes = {
  googleScriptLoaded: PropTypes.bool,
};

export default Home;
