import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, CircularProgress, Fade } from "@material-ui/core";
import ContactSubscribedActions from "./ContactSubscribedActions";
import { GET_CONTACT } from "../../../Services/Graph/Contact/Queries";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { useLazyQuery } from "@apollo/client";
import { ErrorOutlineOutlined } from "@material-ui/icons";
import Moment from "react-moment";
import ContactsTableSubscribedHeaders from "./ContactSubscribedTableHeaders";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundColor: "#F5F5F5",
      padding: "35px 53px",
      borderRadius: "20px",
    },
    tableContainer: {
      backgroundColor: "#F5F5F5",
      maxHeight: 440,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    labelCenter: {
      textAlign: "center",
    },
    columnData: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "400",
      color: "#000",
      padding: "16px 6px",
      minWidth: "40px",
    },
    columnTitleData: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "400",
      color: "#000",
      padding: "16px 6px",
      minWidth: "120px",
      backgroundColor: "#F5F5F5",
    },
    nameCell: {
      padding: "16px 16px 16px 0",
    },
    noInfoLabel: {
      fontFamily: theme.typography.secondary,
      margin: "20px",
      fontSize: "22px",
      fontWeight: "700",
      color: "#000",
      textTransform: "capitalize",
      width: "100%",
    },
  })
);

const ContactSubscribedTable = ({
  reportsRemaining,
  reportSent,
  setReportSent,
  contactDetails,
  setSubscribe,
  subscribe,
  contactId,
  subscriptionWasMade,
  setSubscriptionWasDeleted,
  setCreateReportOpen,
  setNoReports,
  setRegrabReportsSent,
  paidAccount,
  contactReportWasMade,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("createdAt");

  const [fetchSubcriptions, { data: contactSubscriptionsData, loading: contactSubscriptionsLoading, error: contactSubscriptionsError }] = useLazyQuery(GET_CONTACT, {
    variables: {
      id: contactId ?? 0,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  const rows = contactSubscriptionsData?.contact?.reportSubscriptions ?? [];
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortHeaderCells = stableSort(rows, getComparator(order, orderBy));

  useEffect(() => {
    fetchSubcriptions();
  }, [fetchSubcriptions]);

  useEffect(() => {
    if (contactReportWasMade) {
      fetchSubcriptions();
    }
  }, [contactReportWasMade]);

  useEffect(() => {
    if (subscribe) {
      fetchSubcriptions();
      setSubscribe(false);
    }
  }, [subscribe, fetchSubcriptions, setSubscribe]);

  useEffect(() => {
    if (subscriptionWasMade === true && Object.keys(contactSubscriptionsData ?? {}).length !== 0) {
      fetchSubcriptions();
    }
  }, [subscriptionWasMade, fetchSubcriptions]);

  useEffect(() => {
    if (reportSent === true && Object.keys(contactSubscriptionsData ?? {}).length !== 0) {
      fetchSubcriptions();
    }
  }, [reportSent, contactSubscriptionsData, fetchSubcriptions]);

  return (
    <div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table} aria-label="enhanced table">
          <ContactsTableSubscribedHeaders order={order} orderBy={orderBy} handleRequestSort={handleRequestSort} />
          <TableBody>
            {contactSubscriptionsLoading && (
              <TableRow>
                <TableCell>
                  <CircularProgress
                    size={60}
                    thickness={3}
                    color="inherit"
                    className={classes.progress}
                    classes={{
                      circle: classes.circle,
                    }}
                  />
                </TableCell>
              </TableRow>
            )}
            {contactSubscriptionsError && (
              <TableRow>
                <TableCell>
                  <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.error} />
                </TableCell>
              </TableRow>
            )}
            {rows.length === 0 && contactSubscriptionsData !== undefined ? (
              <TableRow>
                <TableCell>
                  <div>
                    <Fade in={true} timeout={500}>
                      <p className={classes.noInfoLabel}>No Subscriptions</p>
                    </Fade>
                  </div>
                </TableCell>
              </TableRow>
            ) : null}
            {rows.length !== 0
              ? sortHeaderCells
                  .slice(0)
                  .reverse()
                  .map((row, index) => {
                    return (
                      <Fade key={row.id} in={true} timeout={500}>
                        <TableRow className={classes.hoverRow}>
                          <TableCell id={row.title} className={classes.columnTitleData}>
                            {row.title}
                          </TableCell>
                          <TableCell>
                            <Moment className={classes.columnData} format="MM/DD/YYYY">
                              {row.createdAt}
                            </Moment>
                          </TableCell>
                          <TableCell>
                            {row.lastSentAt === "0001-01-01T00:00:00Z" ? (
                              <p className={classes.columnData}>NOT SENT</p>
                            ) : (
                              <Moment className={classes.columnData} format="MM/DD/YYYY">
                                {row.lastSentAt}
                              </Moment>
                            )}
                          </TableCell>
                          <TableCell className={classes.columnData}>{row.sent}</TableCell>
                          <TableCell className={classes.columnData}>{row.opened}</TableCell>
                          <TableCell className={classes.columnData}>{row.frequency}</TableCell>
                          <TableCell className={classes.columnData}>
                            <ContactSubscribedActions
                              paidAccount={paidAccount}
                              setRegrabReportsSent={setRegrabReportsSent}
                              setNoReports={setNoReports}
                              reportsRemaining={reportsRemaining}
                              setReportSent={setReportSent}
                              reportId={row?.id}
                              contactDetails={contactDetails}
                              subscriptionId={row.id}
                              reportRequestId={row.reportRequestId}
                              reportTitle={row.title}
                              setSubscriptionWasDeleted={setSubscriptionWasDeleted}
                              setCreateReportOpen={(status) => setCreateReportOpen(status)}
                            />
                          </TableCell>
                        </TableRow>
                      </Fade>
                    );
                  })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

ContactSubscribedTable.propTypes = {
  contactDetails: PropTypes.object,
  reportsRemaining: PropTypes.number,
  setSubscribe: PropTypes.func,
  setNoReports: PropTypes.func,
  setRegrabReportsSent: PropTypes.func,
  paidAccount: PropTypes.bool,
  reportSent: PropTypes.bool,
  setReportSent: PropTypes.func,
  subscribe: PropTypes.bool,
  contactId: PropTypes.string,
  subscriptionWasMade: PropTypes.bool,
  setSubscriptionWasDeleted: PropTypes.func,
  setCreateReportOpen: PropTypes.func,
  contactReportWasMade: PropTypes.bool,
};

export default ContactSubscribedTable;
