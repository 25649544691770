import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Button, withStyles, TextField, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import { GET_REPORT_DATA } from "../../Services/Graph/MarketReportPage/MarketReportPageQueries";
import { EDIT_MARKET_REPORT } from "../../Services/Graph/MarketReportPage/MarketReportPageMutations";
import { openApolloClient, authApolloClient } from "../../Services/Graph/apolloConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    parentDiv: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    textField: {
      marginTop: "7px",
    },
    copy: {
      fontSize: "16px",
      fontFamily: "nunito",
      fontWeight: "600",
    },
    textParent: {
      width: "532px",
      display: "flex",
      justifyContent: "flex-start",
    },
    buttonDiv: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
    },
    buttonPad: {
      marginBottom: "55px !important",
    },
    requireParent: {
      display: "flex",
      alignItems: "center",
      marginRight: "23px",
      marginTop: "10px",
    },
    require: {
      fontFamily: "nunito",
      fontWeight: "600",
      fontSize: "16px",
      color: "#000",
      marginLeft: "16px",
    },
    circleOutline: {
      padding: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "18px",
      width: "18px",
      border: "2px solid",
      borderColor: theme.palette.areaPulseBlue,
      borderRadius: "100px",
      backgroundColor: "#fff",
    },
    filled: {
      height: "12px",
      width: "12px",
      backgroundColor: theme.palette.areaPulseBlue,
      borderRadius: "100px",
    },
    circle: {
      color: theme.palette.areaPulseBlue,
      height: "18px !important",
      width: "18px !important",
    },
  })
);

const ReportContactInput = withStyles({
  root: {
    width: "532px",
    height: "48px",
    backgroundColor: "#FAFAFA",
    boxShadow: "inset 0px 0px 7px rgba(0, 0, 0, 0.08)",
    borderRadius: "10px",
    "& label": {
      color: "#777777",
    },
    "& label.Mui-focused": {
      color: "#777777",
    },
    "& .MuiFilledInput-input": {
      padding: "0px 12px ",
      height: " 48px",
    },
    "& .MuiFilledInput-root": {
      color: "#000",

      backgroundColor: "#FAFAFA",
      boxShadow: "inset 0px 0px 7px rgba(0, 0, 0, 0.08)",
      borderRadius: "10px",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
      "&.MuiFilledInput-underline:after": {
        display: "none",
      },
    },
  },
})(TextField);

const CloseButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    background: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: "nunito",
    fontWeight: "700",
    padding: "11px 72px",
    borderRadius: "7px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const ShareWithLink = ({ reportLink, reportId }) => {
  const classes = useStyles();
  // const [required, setRequired] = useState(false);
  // const [buttonPushed, setButtonPushed] = useState(false);

  function copyLink() {
    var copyText = document.getElementById("marketReportsCopyALink");
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");
  }

  // const { data: reportData, loading: reportLoading } = useQuery(GET_REPORT_DATA, {
  //   variables: {
  //     id: reportId,
  //   },
  //   client: openApolloClient,
  //   fetchPolicy: "network-only",
  // });

  // const [updateRequired, { loading: updateLoading }] = useMutation(EDIT_MARKET_REPORT, {
  //   variables: {
  //     id: reportId,
  //     requireRegistration: required,
  //   },
  //   client: authApolloClient,
  // });

  // useEffect(() => {
  //   if (buttonPushed) {
  //     updateRequired();
  //     setButtonPushed(false);
  //   }
  // }, [required]);

  // useEffect(() => {
  //   if (reportData && !reportLoading) {
  //     setRequired(reportData?.report?.requireRegistration);
  //   }
  // }, [reportData, reportLoading]);

  return (
    <div className={classes.parentDiv}>
      <div className={classes.copyTitle}>Copy a Link</div>
      <div className={classes.textParent}>
        <div className={classes.copy}>Market Report URL</div>
      </div>
      <div className={classes.textField}>
        <ReportContactInput id="marketReportsCopyALink" value={reportLink} variant="filled" />
      </div>
      {/* <div className={classes.requireParent}>
        {updateLoading || reportLoading ? (
          <CircularProgress className={classes.circle} />
        ) : (
          <button
            disabled={updateLoading}
            onClick={() => {
              setRequired(!required);
              setButtonPushed(true);
            }}
            className={classes.circleOutline}
          >
            {required ? <div className={classes.filled} /> : <div />}
          </button>
        )}

        <div className={classes.require}>Force Registration</div>
      </div> */}
      <div className={classes.buttonDiv}>
        <CloseButton onClick={copyLink}>COPY</CloseButton>
      </div>
    </div>
  );
};

ShareWithLink.propTypes = {
  reportLink: PropTypes.string,
};

export default ShareWithLink;
