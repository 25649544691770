import React from "react";
import { makeStyles, createStyles, Grid, Fade } from "@material-ui/core";
import PropTypes from "prop-types";
import UserSettingsAccountDetails from "./UserSettingsAccountDetails";
import UserSettingsAccountLoginDetails from "./UserSettingsAccountLoginDetails";

const useStyles = makeStyles((theme) =>
  createStyles({
    UserSettingsAccountDetailsContainer: {
      // alignItems: "center",
    },
  })
);

const UserSettingsAccountTab = ({ setOpenUpdateUserSuccess, openUpdateUserLoginSuccess, setOpenUpdateUserLoginSuccess, openUpdateUserSuccess, selectedTab }) => {
  const classes = useStyles();

  return (
    <Fade in={selectedTab === 0} timeout={500}>
      <Grid container spacing={3} className={classes.UserSettingsAccountDetailsContainer}>
        <Grid item sm={12} md={6}>
          <UserSettingsAccountDetails openUpdateUserSuccess={openUpdateUserSuccess} setOpenUpdateUserSuccess={setOpenUpdateUserSuccess} />
        </Grid>
        <Grid item sm={12} md={6}>
          <UserSettingsAccountLoginDetails openUpdateUserLoginSuccess={openUpdateUserLoginSuccess} setOpenUpdateUserLoginSuccess={setOpenUpdateUserLoginSuccess} />
          {/* <UserSettingsAccountPersonalDetails 
          openUpdateUserPersonalInfoSuccess={openUpdateUserPersonalInfoSuccess}
          setOpenUpdateUserPersonalInfoSuccess={setOpenUpdateUserPersonalInfoSuccess}
        /> */}
        </Grid>
      </Grid>
    </Fade>
  );
};

UserSettingsAccountTab.propTypes = {
  openUpdateUserSuccess: PropTypes.bool,
  setOpenUpdateUserSuccess: PropTypes.func,
  openUpdateUserLoginSuccess: PropTypes.bool,
  setOpenUpdateUserLoginSuccess: PropTypes.func,
  selectedTab: PropTypes.number,
};

export default UserSettingsAccountTab;
