import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import HeaderStylesheet from "./HeaderStylesheet";
import { makeStyles, createStyles, useMediaQuery } from "@material-ui/core";
import SignUpSocial from "./OnBoarding/SignUpSocial";
import SignUpEmail from "./OnBoarding/SignUpEmail";
import Login from "./OnBoarding/Login";
import CaptureMls from "./OnBoarding/CaptureMls";
import CaptureEmail from "./OnBoarding/CaptureAddress";
import FinishOnBoarding from "./OnBoarding/FinishOnBoarding";
import { Element } from "react-scroll";
import { Grid } from "@material-ui/core";
import { AppStateContext } from "../../../../Context/AppReducer";
import ForgotPassword from "./ForgotPassword";
import LoginErrorModal from "./LoginErrorModal";
import CaptureAddress from "./OnBoarding/captureEmail";
import { useMutation } from "@apollo/client";
import { CREATE_MARKET_REPORT } from "../../../../Services/Graph/CreateReport/Mutations";
import { authApolloClient } from "../../../../Services/Graph/apolloConfig";
import { SEND_REPORT } from "../../../../Services/Graph/MarketReportPage/MarketReportPageMutations";
import { AppDispatchContext } from "../../../../Context/AppReducer";

const useStyles = makeStyles((theme) =>
  createStyles({
    noActive: {
      display: "none",
    },
    gone: {
      visibility: "hidden",
      opacity: "0",
    },
    leftTitleParent: {
      transition: "1s",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      position: "absolute",
      height: "100%",
      width: "100%",
      flexDirection: "column",
      paddingLeft: "110px",
      zIndex: "100",
    },
    leftTitle: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "64px",
      color: "#FFFFFF",
    },
    leftSubTitle: {
      marginBottom: "100px",
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "36px",
      color: "#FFFFFF",
      marginTop: "15px",
    },
    topSubtitle: {
      width: "380px",
    },
    secondTitle: {
      width: "500px",
    },
    stepper: {
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      display: "flex",
      flexDirection: "row",
      bottom: "70px",
    },
    stepLine: {
      transition: "1.5s",
      height: "2px",
      width: "118px",
    },
    stepColor1: {
      background: "linear-gradient(89.99deg, #2D9CDB 0%, #13CE66 99.99%)",
    },
    stepColor2: {
      background: "linear-gradient(89.98deg, #13CE66 -8.05%, #F2C94C 100%)",
    },
    stepColor3: {
      background: "linear-gradient(90.01deg, #F2C94C 0.01%, #F2994A 100%)",
    },
    noStepColor: {
      backgroundColor: "#E0E0E0",
    },
    grayBall: {
      backgroundColor: "#E0E0E0",
      height: "10px",
      width: "10px",
      borderRadius: "100px",
      margin: "20px",
    },
    check1: {
      margin: "12px",
      height: "24px",
      width: "24px",
      color: "#2D9CDB",
    },
    check2: {
      margin: "12px",
      height: "24px",
      width: "24px",
      color: "#13CE66",
    },
    check3: {
      margin: "12px",
      height: "24px",
      width: "24px",
      color: "#F2C94C",
    },
    check4: {
      margin: "12px",
      height: "24px",
      width: "24px",
      color: "#F2994A",
    },
    phoneImage: {
      position: "absolute",
      top: "199px",
      left: "-89px",
      zIndex: "1",
    },
    resultImage: {
      position: "absolute",
      top: "541px",
      zIndex: "2",
      left: "166px",
    },
    shadowImage: {
      position: "absolute",
      top: "713px",
      zIndex: "0",
      left: "-138px",
    },
    "@media (max-width: 1400px)": {
      stepper: {
        left: "-40px",
      },
    },
    "@media (max-width: 1200px)": {
      leftTitleParent: {
        paddingLeft: "50px",
      },
    },
  })
);
const Header = ({ googleScriptLoaded, activeView, setActiveView }) => {
  const classes = HeaderStylesheet();
  const newClasses = useStyles();

  const globalState = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);

  const [reportId, setReportId] = useState(Number);
  const names = globalState?.user?.fullName?.split(" ");
  const firstName = names?.[0];
  const lastName = names?.[1];
  const email = globalState?.user?.email;
  const [forgotOpen, setForgotOpen] = useState(false);
  const [forgotValue, setForgotValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [payload, setPayload] = useState({});
  const [selectedAnAddress, setSelectedAnAddress] = useState(false);
  const [selectedNonAddress, setSelectedNonAddress] = useState(false);
  const [loginErrorModal, setLoginErrorModal] = useState(false);

  const [createTheMarketReport, { data: reportData, loading: reportLoading }] = useMutation(CREATE_MARKET_REPORT, {
    variables: {
      ...payload,
    },
    client: authApolloClient,
  });
  const [sendReport] = useMutation(SEND_REPORT, {
    variables: {
      contactEmail: email,
      reportID: reportData?.createMarketReport?.id,
      contactFirstName: firstName,
      contactLastName: lastName,
      frequency: "once",
      contactPhone: "0000000000",
    },
    client: authApolloClient,
  });

  useEffect(() => {
    if (globalState?.ssoLoginError) {
      globalDispatch({ type: "setSSOLoginError", payload: false });
      setLoginErrorModal(true);
      setActiveView(3);
    }
  }, []);

  useEffect(() => {
    if (payload && (selectedNonAddress || selectedAnAddress)) {
      createTheMarketReport();
    }
  }, [payload]);
  useEffect(() => {
    if (reportData) {
      setActiveView(6);
      setReportId(reportData?.createMarketReport?.id);
      const sendTheReport = setTimeout(() => {
        sendReport();
      }, 3000);

      return () => {
        clearTimeout(sendTheReport);
      };
    }
  }, [selectedAnAddress, selectedNonAddress, reportData]);

  const isMobile = useMediaQuery("(max-width: 1060px)");

  return (
    <Element name="home">
      <Grid className={newClasses.gridParent} container>
        <img src="/assets/new-areapulse-bluebg.png" className={classes.contactWave} />
        <Grid item xs={12} sm={12} md={8} style={activeView === 3 ? { paddingRight: "25px" } : null} className={classes.allViewsContainer}>
          <SignUpSocial activeView={activeView} setActiveView={setActiveView} />
          {emailValue ? <SignUpEmail emailValue={emailValue} setEmailValue={setEmailValue} activeView={activeView} setActiveView={setActiveView} /> : null}
          <CaptureAddress setEmailValue={setEmailValue} activeView={activeView} setActiveView={setActiveView} />
          <Login setForgotValue={setForgotValue} setForgotOpen={setForgotOpen} activeView={activeView} setActiveView={setActiveView} />
          <CaptureMls activeView={activeView} setActiveView={setActiveView} />
          {googleScriptLoaded ? (
            <CaptureEmail
              setSelectedAnAddress={setSelectedAnAddress}
              setSelectedNonAddress={setSelectedNonAddress}
              selectedNonAddress={selectedNonAddress}
              selectedAnAddress={selectedAnAddress}
              setPayload={setPayload}
              lastName={lastName}
              firstName={firstName}
              email={email}
              setReportId={setReportId}
              activeView={activeView}
              setActiveView={setActiveView}
              reportData={reportData}
              reportLoading={reportLoading}
            />
          ) : null}
          <FinishOnBoarding email={email} reportId={reportId} activeView={activeView} setActiveView={setActiveView} />
        </Grid>

        {isMobile ? null : (
          <Grid item xs={12} sm={12} md={4}>
            <div className={`${classes.header1}`}>
              <img alt="wave" className={`${newClasses.phoneImage} `} src="/assets/Phone.png" />
              <img alt="wave" className={`${newClasses.resultImage} `} src="/assets/Group-39.png" />
              <img alt="wave" className={`${newClasses.shadowImage} `} src="/assets/Primary-Shadow.png" />
            </div>
          </Grid>
        )}
      </Grid>
      <ForgotPassword forgotValue={forgotValue} setForgotOpen={setForgotOpen} forgotOpen={forgotOpen} />
      <LoginErrorModal setLoginErrorModal={setLoginErrorModal} loginErrorModal={loginErrorModal} />
    </Element>
  );
};

Header.propTypes = {
  googleScriptLoaded: PropTypes.bool,
  setActiveView: PropTypes.func,
  activeView: PropTypes.number,
};

export default Header;
