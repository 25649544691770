import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import UserSettingsHeader from "./UserSettingsHeader/UserSettingsHeader";
import UserSettingsTabs from "./UserSettingsTabs/UserSettingsTabs";

const useStyles = makeStyles((theme) =>
  createStyles({
    userSettingsContainer: {
      width: "100%",
      height: "100%",
    },
    userSettingsHeaderContainer: {
      width: "100%",
      height: "100%",
      backgroundColor: "#EFEFEF",
      padding: "140px 80px 20px 480px",
      position: "relative",
    },
    userSettingsTabContainer: {
      width: "100%",
      minHeight: "79vh",
      padding: "60px 80px 60px 480px",
    },
    "@media (max-width: 1280px)": {
      userSettingsHeaderContainer: {
        padding: "60px 80px 60px 180px",
        position: "relative",
      },
      userSettingsTabContainer: {
        padding: "60px 80px 60px 180px",
      },
    },
    "@media (max-width: 960px)": {
      userSettingsHeaderContainer: {
        padding: "60px 60px 60px 180px",
      },
      userSettingsTabContainer: {
        padding: "60px 60px 60px 180px",
      },
    },
  })
);

const UserSettingsComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.userSettingsContainer}>
      <div className={classes.userSettingsHeaderContainer}>
        <UserSettingsHeader />
      </div>
      <div className={classes.userSettingsTabContainer}>
        <UserSettingsTabs />
      </div>
    </div>
  );
};

UserSettingsComponent.propTypes = {};

export default UserSettingsComponent;
