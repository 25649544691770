import { makeStyles, createStyles } from "@material-ui/core";

const FacebookLoginStylesheet = makeStyles((theme) =>
  createStyles({
    socialButtons: {
      minWidth: "440px",
      justifyContent: "flex-start",
      padding: "15px 25px 15px 25px",
      borderRadius: "5px",
      opacity: "1",
      transition: "0.2s",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    socialButtonsSmall: {
      justifyContent: "center",
      padding: "10px 10px",
      borderRadius: "2px",
      opacity: "1",
      transition: "0.2s",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    faceBook: {
      color: "#fff",
      marginBottom: "25px",
      background: "#3B5998",
      transition: "0.2s",
      boxShadow: "0px 2px 2px rgba(0,0,0,0.1)",
      "&:hover": {
        background: "#3B5998",
        transition: "0.2s",
      },
    },
    faceBookSmall: {
      color: "#fff",
      marginRight: "25px",
      background: "#3B5998",
      transition: "0.2s",
      boxShadow: "0px 2px 2px rgba(0,0,0,0.1)",
      "&:hover": {
        background: "#3B5998",
        transition: "0.2s",
      },
    },
    socialText: {
      fontSize: "18px",
      fontFamily: "Nunito",
      paddingLeft: "30px",
    },
    socialIcons: {
      color: "#fff",
      fontSize: "24px !important",
    },
    socialIconsSmall: {
      color: "#fff",
      fontSize: "40px !important",
    },
    progress: {
      height: "31px !important",
      width: "31px !important",
      marginLeft: "20px",
      color: "#fff",
    },
  })
);

export default FacebookLoginStylesheet;
