import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import LandingPagePreferencesComponent from "../Components/LandingPagePreferences/LandingPagePreferencesComponent";
import CreateReport from "../Components/CreateReport/CreateReport";

const useStyles = makeStyles((theme) =>
  createStyles({
    userSettingsViewContainer: {
      position: "absolute",
      left: "0",
      right: "0",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      backgroundColor: "#fff",
    },
  })
);

const LandingPagePreferencesView = ({ createReportOpen, setCreateReportOpen, match, googleScriptLoaded }) => {
  const classes = useStyles();
  const user = match.params.user;

  return (
    <div className={classes.userSettingsViewContainer}>
      {createReportOpen.open && <CreateReport googleScriptLoaded={googleScriptLoaded} createReportOpen={createReportOpen} setCreateReportOpen={(status) => setCreateReportOpen(status)} />}
      <LandingPagePreferencesComponent />
    </div>
  );
};

LandingPagePreferencesView.propTypes = {
  createReportOpen: PropTypes.object,
  setCreateReportOpen: PropTypes.func,
  match: PropTypes.object,
  googleScriptLoaded: PropTypes.bool,
};

export default LandingPagePreferencesView;
