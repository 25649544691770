import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    errorContainer: {
      height: "calc(100% - 85px)",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "30px",
    },
    errorContentContainer: {
      maxWidth: "767px",
      padding: "30px",
      background: "#F5F5F5",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    errorContentText: {
      margin: "0px",
      fontFamily: theme.typography.default,
      fontSize: "24px",
      fontWeight: "600",
      color: theme.palette.areaPulseErrorLight,
    },
  })
);

const CreateReportError = ({ error }) => {
  const classes = useStyles();

  return (
    <div className={classes.errorContainer}>
      <div className={classes.errorContentContainer}>
        <h1 className={classes.errorContentText}>
          Failed to create market report {">>"} <strong>{error?.message ?? "Unknown"}</strong>
        </h1>
      </div>
    </div>
  );
};

CreateReportError.propTypes = {
  error: PropTypes.object,
};

export default CreateReportError;
