import { gql } from "@apollo/client";

export const UPDATE_USER_LANDING_PAGE_SETTINGS = gql`
  mutation UpdateUserLandingPagePreferences($themeColorSecondary: String!, $templateChosen: Int!, $headline: String, $subheadline: String, $userId: Int!, $themeColorPrimary: String!, $logo: String) {
    updateLandingPageSettings(
      themeColorSecondary: $themeColorSecondary
      templateChosen: $templateChosen
      headline: $headline
      subheadline: $subheadline
      userId: $userId
      themeColorPrimary: $themeColorPrimary
      logo: $logo
    ) {
      themeColorPrimary
      themeColorSecondary
      templateChosen
      headline
      subheadline
      id
      backgroundImage
      faviconUrl
      logoURL
    }
  }
`;

export const REVERT_LANDING_PAGE_SETTINGS = gql`
  mutation revertLandingPageSettings($userId: Int!, $fields: [LandingPageFields]) {
    revertLandingPageSettings(userId: $userId, fields: $fields) {
      backgroundImage
    }
  }
`;
