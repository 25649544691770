import React, { useState, useEffect } from "react";
import { makeStyles, withStyles, createStyles, Tab, Tabs, Collapse, IconButton } from "@material-ui/core";
import UserSettingsAccountTab from "./UserSettingsAccountTab/UserSettingsAccountTab";
import UserSettingsBillingTab from "./UserSettingsBillingTab/UserSettingsBillingTab";
import UserSettingsMlsTab from "./UserSettingsMlsTab/UserSettingsMlsTab";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(3),
    },
    demo1: {
      backgroundColor: theme.palette.background.paper,
    },
    alert: {
      position: "fixed",
      top: "40px",
      width: "50%",
    },
  })
);

const AccountSettingsTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
    fontFamily: theme.typography.secondary,
    marginBottom: "35px",
  },
  indicator: {
    backgroundColor: theme.palette.areaPulseBlue,
  },
}))(Tabs);

const AccountSettingsTab = withStyles((theme) => ({
  root: {
    minWidth: 72,
    fontWeight: "700",
    fontSize: "24px",
    marginRight: "90px",
    fontFamily: theme.typography.secondary,
    textTransform: "uppercase",
    "&:hover": {
      color: theme.palette.areaPulseBlue,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.areaPulseBlue,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: theme.palette.areaPulseBlue,
    },
  },
  "@media (max-width: 960px)": {
    root: {
      marginRight: "30px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const UserSettingsTabs = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [openUpdateUserSuccess, setOpenUpdateUserSuccess] = useState(false);
  const [openUpdateUserLoginSuccess, setOpenUpdateUserLoginSuccess] = useState(false);
  const [openUpdateUserPersonalInfoSuccess, setOpenUpdateUserPersonalInfoSuccess] = useState(false);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (openUpdateUserSuccess) {
      const closeUpdateUserAlert = setTimeout(() => {
        setOpenUpdateUserSuccess(false);
      }, 3000);
      return () => clearTimeout(closeUpdateUserAlert);
    }

    if (openUpdateUserLoginSuccess) {
      const closeUpdateUserLoginAlert = setTimeout(() => {
        setOpenUpdateUserLoginSuccess(false);
      }, 3000);
      return () => clearTimeout(closeUpdateUserLoginAlert);
    }

    if (openUpdateUserPersonalInfoSuccess) {
      const closeUpdateUserPersonalInfoAlert = setTimeout(() => {
        setOpenUpdateUserPersonalInfoSuccess(false);
      }, 3000);
      return () => clearTimeout(closeUpdateUserPersonalInfoAlert);
    }
  }, [openUpdateUserSuccess, openUpdateUserLoginSuccess, openUpdateUserPersonalInfoSuccess]);

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AccountSettingsTabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons="off" aria-label="Account Settings Tab">
          <AccountSettingsTab label="Account" />
          <AccountSettingsTab label="Billing" />
          <AccountSettingsTab label="MLS" />
        </AccountSettingsTabs>
        {selectedTab === 0 && (
          <UserSettingsAccountTab
            setOpenUpdateUserSuccess={setOpenUpdateUserSuccess}
            openUpdateUserLoginSuccess={openUpdateUserLoginSuccess}
            setOpenUpdateUserLoginSuccess={setOpenUpdateUserLoginSuccess}
            openUpdateUserPersonalInfoSuccess={openUpdateUserPersonalInfoSuccess}
            setOpenUpdateUserPersonalInfoSuccess={setOpenUpdateUserPersonalInfoSuccess}
            openUpdateUserSuccess={openUpdateUserSuccess}
            selectedTab={selectedTab}
          />
        )}
        {selectedTab === 1 && <UserSettingsBillingTab selectedTab={selectedTab} />}
        {selectedTab === 2 && <UserSettingsMlsTab selectedTab={selectedTab} />}
      </div>
      <div className={classes.alert}>
        <Collapse in={openUpdateUserSuccess} timeout={1000}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenUpdateUserSuccess(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            User Account Details Was Updated!
          </Alert>
        </Collapse>
        <Collapse in={openUpdateUserLoginSuccess} timeout={1000}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenUpdateUserLoginSuccess(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            User Account Login Was Updated!
          </Alert>
        </Collapse>
        <Collapse in={openUpdateUserPersonalInfoSuccess} timeout={1000}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenUpdateUserPersonalInfoSuccess(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            User Personal Details Was Updated!
          </Alert>
        </Collapse>
      </div>
    </div>
  );
};

export default UserSettingsTabs;
