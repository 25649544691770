import { gql } from "@apollo/client";

export const DELETE_REPORT = gql`
  mutation DeleteReports($id: Int!) {
    deleteMarketReport(id: $id)
  }
`;
export const CREATE_CARD = gql`
  mutation makeCard($id: Int!) {
    createMarketReportCardOpen(id: $id) {
      url
    }
  }
`;

export const SEND_REPORT = gql`
  mutation sendReport($contactEmail: String!, $reportID: Int!, $contactFirstName: String, $contactLastName: String, $contactPhone: String, $frequency: reportFrequency) {
    emailContactReport(contactEmail: $contactEmail, reportID: $reportID, frequency: $frequency, contactPhone: $contactPhone, contactFirstName: $contactFirstName, contactLastName: $contactLastName) {
      newContact {
        email {
          email
        }
        firstName
      }
      subscribed
    }
  }
`;

export const EDIT_MARKET_REPORT = gql`
  mutation EditMarketReport($requireRegistration: Boolean, $id: Int!) {
    updateMarketReport(requireRegistration: $requireRegistration, id: $id) {
      requireRegistration
    }
  }
`;
