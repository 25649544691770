import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GET_ELASTIC_SEARCH_DATA } from "../../../../../Services/Graph/CreateReport/Queries";
import { authApolloClient } from "../../../../../Services/Graph/apolloConfig";
import { makeStyles, Fade, createStyles, Grid, TextField, withStyles, Button, CircularProgress } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { AppStateContext } from "../../../../../Context/AppReducer";
import getUsersMlsIds from "../../../../../Helpers/getUsersMlsIds";
import { useLazyQuery } from "@apollo/client";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import getFormattedTerm from "../../../../../Helpers/getFormattedTerm";
import { GET_USER_DATA } from "../../../../../Services/Graph/UserSettings/Queries";
import AutoCompletOptions from "../../../../CreateReport/AutoCompleteOptions";
import { PRE_MARKET_REPORT } from "../../../../../Services/Graph/CreateReport/Queries";

const useStyles = makeStyles((theme) =>
  createStyles({
    onBoarding: {
      top: "0",
      position: "absolute",
      padding: "235px 0px 0px 120px",
    },
    onBoardingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
    },
    viewContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    viewHeaders: {
      fontFamily: "Nunito",
      fontWeight: "bold",
      fontSize: "24px",
      color: "#000000",
      margin: "55px 0px 30px 0px",
    },
    skipDiv: {
      fontFamily: "Nunito",
      fontWeight: "700",
      fontSize: "18px",
      color: "#A3A3A3",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      textDecoration: "none",
    },
    arrow: {
      height: "28px",
      width: "28px",
      color: "#FFFFFF",
    },
    autodiv: {
      position: "relative",
      top: "-181px",
    },
    loadingParent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItem: "center",
      marginBottom: "70px",
    },
    circle: {
      color: theme.palette.areaPulseBlue,
      height: "30px !important",
      marginLeft: "20px",
      width: "30px !important",
    },
    circleTitle: {
      fontFamily: "Nunito",
      fontWeight: "bold",
      fontSize: "20px",
      color: "#000000",
    },
    bigMargin: {
      margin: "15px 0px 122px",
    },
    smallMargin: {
      margin: "15px 0px 22px",
    },
    fadeDiv: {
      opacity: "1",
      transition: "0.5s",
    },
    fadeDivFaded: {
      opacity: "0",
    },
    "@media (max-width: 960px)": {
      onBoarding: {
        padding: "235px 50px 0px 50px",
      },
    },
  })
);

const ReportContactInput = withStyles({
  root: {
    width: "100%",
    height: "48px",
    maxWidth: "552px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    "& label": {
      color: "#BDBDBD",
      paddingLeft: "40px",
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "14px",
    },
    "& label.Mui-focused": {
      color: "#777777",
      paddingLeft: "40px",
    },
    "& .MuiInputLabel-shrink": {
      display: "none",
    },
    "& .MuiFilledInput-root": {
      marginTop: "-5px",
      padding: "0px 0px 0px 15px",
      fontSize: "21px",
      fontWeight: "700",
      fontFamily: "nunito",
      width: "100%",
      height: "48px",
      maxWidth: "552px",
      color: "#000",
      backgroundColor: "transparent",
      borderRadius: "10px",

      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
      "&.MuiFilledInput-underline:after": {
        display: "none",
      },
    },
  },
})(TextField);

const GoButton = withStyles((theme) => ({
  root: {
    minWidth: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#A3A3A3",
    height: "39px",
    width: "39px",
    marginLeft: "10px",
    borderRadius: "50px",
    "&:hover": {
      background: "#A3A3A3",
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const CaptureAddress = ({ activeView, setPayload, setSelectedNonAddress, setSelectedAnAddress, selectedNonAddress, selectedAnAddress, reportData, reportLoading }) => {
  const classes = useStyles();
  const globalState = useContext(AppStateContext);
  const baseURL = window.location.host;
  const [renderedElasticData, setRenderedElasticData] = useState([]);

  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [reportRadius, setReportRadius] = useState(5);
  const [timesCounted, setTimeCounted] = useState(1);

  const [inputErrors, setInputErrors] = useState({
    error: false,
    message: "Please enter an Address, City or Zip",
  });
  const [formattedTerm, setFormattedTerm] = useState({
    city: "",
    state: "",
    county: "",
    zip: "",
    neighborhood: "",
  });
  const handleChange = (value) => {
    setValue(value);
    getElasticSearchData();
    setSelectedAnAddress(false);
    setSelectedNonAddress(false);
    setFormattedTerm({
      city: "",
      state: "",
      county: "",
      zip: "",
      neighborhood: "",
    });
    setInputErrors({ error: false, message: inputErrors.message });
  };
  const {
    ready,
    value,
    suggestions: { data: placesData },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
    },
    debounce: 300,
  });
  const [getUserMLS, { data: mlsLinkData }] = useLazyQuery(GET_USER_DATA, {
    variables: {
      id: globalState.user.userId,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });
  const mlsIds = getUsersMlsIds(mlsLinkData?.user);
  const [getElasticSearchData, { data: elasticData }] = useLazyQuery(GET_ELASTIC_SEARCH_DATA, {
    variables: {
      term: value,
      mlsIDs: mlsIds,
    },
    client: authApolloClient,
  });
  const [preMarketReport, { data: preReportData, loading: preReportLoading }] = useLazyQuery(PRE_MARKET_REPORT, {
    variables: {
      address: formattedTerm.address,
      neighborhood: formattedTerm.neighborhood,
      state: formattedTerm.state,
      county: formattedTerm.county,
      zip: formattedTerm.zip,
      city: formattedTerm.city,
      lat: latLng.lat,
      lng: latLng.lng,
      maxBaths: 4,
      maxBeds: 5,
      maxPrice: 500000,
      minBaths: 1,
      minBeds: 1,
      minPrice: 200000,
      mlsId: mlsIds,
      propertyType: ["Residential"],
      radius: formattedTerm.address ? reportRadius : 0,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (activeView === 5 && mlsLinkData === undefined) {
      getUserMLS();
    }
  }, [activeView]);

  useEffect(() => {
    let reportMultiplyer = 10 * timesCounted;
    if (preReportData) {
      if (timesCounted > 5) {
        setPayload({
          address: formattedTerm.address,
          neighborhood: formattedTerm.neighborhood,
          state: formattedTerm.state,
          county: formattedTerm.county,
          zip: formattedTerm.zip,
          city: formattedTerm.city,
          lat: latLng.lat,
          lng: latLng.lng,
          maxBaths: 4,
          maxBeds: 5,
          maxPrice: 500000,
          minBaths: 1,
          minBeds: 1,
          minPrice: 200000,
          mlsId: mlsIds,
          propertyType: ["Residential"],
          radius: formattedTerm.address ? reportRadius : 0,
        });
      } else if (preReportData?.listingCount < 50) {
        setReportRadius(reportMultiplyer);
        setTimeCounted(timesCounted + 1);
      } else if (preReportData?.listingCount > 800 && reportRadius > 1) {
        setReportRadius(reportRadius - 1);
        setTimeCounted(timesCounted + 1);
      } else if (!reportData && !reportLoading) {
        setPayload({
          address: formattedTerm.address,
          neighborhood: formattedTerm.neighborhood,
          state: formattedTerm.state,
          county: formattedTerm.county,
          zip: formattedTerm.zip,
          city: formattedTerm.city,
          lat: latLng.lat,
          lng: latLng.lng,
          maxBaths: 4,
          maxBeds: 5,
          maxPrice: 500000,
          minBaths: 1,
          minBeds: 1,
          minPrice: 200000,
          mlsId: mlsIds,
          propertyType: ["Residential"],
          radius: formattedTerm.address ? reportRadius : 0,
        });
      }
    }
  }, [preReportData]);

  useEffect(() => {
    if (selectedAnAddress) {
      if (!preReportLoading) {
        preMarketReport();
      }
    } else if (selectedNonAddress) {
      if (!reportLoading) {
        setPayload({
          address: formattedTerm.address,
          neighborhood: formattedTerm.neighborhood,
          state: formattedTerm.state,
          county: formattedTerm.county,
          zip: formattedTerm.zip,
          city: formattedTerm.city,
          lat: latLng.lat,
          lng: latLng.lng,
          maxBaths: 4,
          maxBeds: 5,
          maxPrice: 500000,
          minBaths: 1,
          minBeds: 1,
          minPrice: 200000,
          mlsId: mlsIds,
          propertyType: ["Residential"],
          radius: formattedTerm.address ? reportRadius : 0,
        });
      }
    }
  }, [latLng, reportRadius]);

  useEffect(() => {
    setRenderedElasticData(elasticData?.suggestions ?? []);
  }, [elasticData]);

  const handleSelect = (term, isAddress) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(term.value, false);
    clearSuggestions();
    setRenderedElasticData([]);

    if (isAddress) {
      setSelectedAnAddress(true);
      setSelectedNonAddress(false);
      setFormattedTerm(getFormattedTerm(term));
    } else {
      setSelectedNonAddress(true);
      setSelectedAnAddress(false);
      setFormattedTerm(getFormattedTerm(term));
    }

    // Get latitude and longitude via utility functions
    getGeocode({
      address: term.value,
    })
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        setLatLng({ lat, lng });
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };
  return (
    <Fade in={activeView === 5} timeout={600} className={classes.onBoarding}>
      <Grid container className={classes.onBoardingContainer}>
        <div className={classes.viewContainer}>
          <div className={classes.viewHeaders}>Take a test Drive and run your first report</div>
          <ReportContactInput
            autoComplete="none"
            inputProps={{
              style: {
                padding: "22px 26px 9px",
              },
            }}
            className={selectedAnAddress || selectedNonAddress ? classes.smallMargin : classes.bigMargin}
            value={value}
            variant="filled"
            label="Enter Your Home Address"
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          />
          <div className={classes.autodiv}>
            <AutoCompletOptions elasticData={renderedElasticData} placesData={placesData} handleSelect={(term, isAddress) => handleSelect(term, isAddress)} inputValue={value} />
          </div>
          <div className={`${classes.fadeDiv} ${selectedAnAddress || selectedNonAddress ? null : classes.fadeDivFaded}`}>
            {selectedAnAddress || selectedNonAddress ? (
              <div className={classes.loadingParent}>
                <div className={classes.circleTitle}>Creating Your Report...</div>
                <CircularProgress className={classes.circle} />
              </div>
            ) : null}
          </div>

          <a href={`https://${baseURL}/${globalState?.user?.username}/dashboard/`} className={classes.skipDiv}>
            skip to area pulse
            <GoButton>
              <ArrowForwardIcon className={classes.arrow} />
            </GoButton>
          </a>
        </div>
      </Grid>
    </Fade>
  );
};

CaptureAddress.propTypes = {
  activeView: PropTypes.number,
  setPayload: PropTypes.func,
  setSelectedNonAddress: PropTypes.func,
  setSelectedAnAddress: PropTypes.func,
  selectedNonAddress: PropTypes.bool,
  selectedAnAddress: PropTypes.bool,
};

export default CaptureAddress;
