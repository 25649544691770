import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getBasePath } from "../../Helpers/getEnvVar";
import { loadState } from "../../Helpers/localStorage";

const basePath = getBasePath();

const openHttpLink = createHttpLink({
  uri: `${basePath}/graphql-open`,
});

const authHttpLink = createHttpLink({
  uri: `${basePath}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = loadState("areaPulseUserToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const openApolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: openHttpLink,
});

export const authApolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(authHttpLink),
});
