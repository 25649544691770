import { gql } from "@apollo/client";

export const GET_MLS = gql`
  query GetMLS($state: String) {
    mlses(state: $state) {
      mlsID
      name
    }
  }
`;
