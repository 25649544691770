import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Backdrop, CircularProgress } from "@material-ui/core";
import CreateReportTooLittle from "./CreateReportTooLittle";
import CreateReportTooMany from "./CreateReportTooMany";
import CreateReportNoReports from "./CreateReportNoReports";
import CreateReportGoodReport from "./CreateReportGoodReport";
import CannotCreateReport from "./CannotCreateReport";

const useStyles = makeStyles((theme) =>
  createStyles({
    createReportBackdrop: {
      height: "100%",
      width: "100%",
      zIndex: "8",
      padding: "242px 475px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    createReportContentContainer: {
      position: "relative",
      width: "100%",
      minHeight: "450px",
      maxWidth: "1600px",
      zIndex: "10",
      background: "#fff",
      borderRadius: "20px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
    },
    headerBar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "25px 35px",
    },
    headerBarTitle: {
      color: "#fff",
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      margin: "0px",
    },
    headerBarIcon: {
      color: "#282828",
      fontSize: "32px",
      cursor: "pointer",
    },
    CircularProgress: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    circleClass: {
      strokeLinecap: "round",
    },
    circle: {
      height: "100px !important",
      width: "100px !important",
      color: "#0079B4",
    },
    "@media (max-width: 1600px)": {
      createReportBackdrop: {
        padding: "242px 250px",
      },
    },
    "@media (max-width: 1250px)": {
      createReportBackdrop: {
        padding: "242px 100px",
      },
    },
    "@media (max-width: 950px)": {
      createReportBackdrop: {
        padding: "242px 36px",
      },
    },
    "@media (max-width: 600px)": {
      createReportBackdrop: {
        padding: "242px 0px",
      },
      headerBarTitle: {
        fontSize: "18px",
      },
      headerBarIcon: {
        fontSize: "24px",
      },
    },
  })
);

const CreateReportResultsBack = ({
  setUpgradeOpen,
  user,
  freeAccountNoReports,
  setPreSubmit,
  preReportData,
  preReportLoading,
  setResultsOpen,
  resultsOpen,
  landingPageView,
  setCreateContactOpen,
  setContactFrequency,
  contactFrequency,
}) => {
  const classes = useStyles();
  const [createContactOpenView, setCreateContactOpenView] = useState(false);
  const backdropAnimations = {
    appear: 500,
    enter: 500,
    exit: 500,
  };

  const whichResult = (x) => {
    let which = "";
    if (x <= 10 && x !== 0) {
      which = (
        <CreateReportTooLittle
          user={user}
          setPreSubmit={setPreSubmit}
          preReportData={preReportData}
          setResultsOpen={setResultsOpen}
          landingPageView={landingPageView}
          setCreateContactOpen={setCreateContactOpen}
          createContactOpenView={createContactOpenView}
          setCreateContactOpenView={setCreateContactOpenView}
          contactFrequency={contactFrequency}
          setContactFrequency={setContactFrequency}
        />
      );
    } else if (x >= 1000) {
      which = (
        <CreateReportTooMany
          user={user}
          setPreSubmit={setPreSubmit}
          preReportData={preReportData}
          setResultsOpen={setResultsOpen}
          landingPageView={landingPageView}
          setCreateContactOpen={setCreateContactOpen}
          createContactOpenView={createContactOpenView}
          setCreateContactOpenView={setCreateContactOpenView}
          contactFrequency={contactFrequency}
          setContactFrequency={setContactFrequency}
        />
      );
    } else if (x === 0) {
      which = <CreateReportNoReports setResultsOpen={setResultsOpen} />;
    } else {
      which = (
        <CreateReportGoodReport
          user={user}
          setPreSubmit={setPreSubmit}
          preReportData={preReportData}
          setResultsOpen={setResultsOpen}
          landingPageView={landingPageView}
          setCreateContactOpen={setCreateContactOpen}
          createContactOpenView={createContactOpenView}
          setCreateContactOpenView={setCreateContactOpenView}
          setContactFrequency={setContactFrequency}
          contactFrequency={contactFrequency}
        />
      );
    }

    return which;
  };
  return (
    <Backdrop open={resultsOpen} transitionDuration={backdropAnimations} className={classes.createReportBackdrop}>
      <div className={classes.createReportContentContainer}>
        {preReportLoading ? (
          <div className={classes.CircularProgress}>
            <CircularProgress classes={{ circle: classes.circleClass }} thickness={3} className={classes.circle} />
          </div>
        ) : freeAccountNoReports ? (
          <CannotCreateReport setUpgradeOpen={setUpgradeOpen} setResultsOpen={setResultsOpen} />
        ) : (
          whichResult(preReportData?.listingCount)
        )}
      </div>
    </Backdrop>
  );
};

CreateReportResultsBack.propTypes = {
  user: PropTypes.string,
  resultsOpen: PropTypes.bool,
  freeAccountNoReports: PropTypes.bool,
  setResultsOpen: PropTypes.func,
  setPreSubmit: PropTypes.func,
  preReportData: PropTypes.object,
  preReportLoading: PropTypes.bool,
  setCreateContactOpen: PropTypes.func,
  setUpgradeOpen: PropTypes.func,
  setContactFrequency: PropTypes.func,
  contactFrequency: PropTypes.string,
};

export default CreateReportResultsBack;
