import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Button, CircularProgress } from "@material-ui/core";
import { DesktopWindowsOutlined, PhoneIphoneOutlined } from "@material-ui/icons";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const useStyles = makeStyles((theme) =>
  createStyles({
    createReportSharePreviewContainer: {
      position: "relative",
      height: "100%",
      width: "100%",
      background: "#FAFAFA",
      padding: "25px 25px 25px 100px",
    },
    sharePreviewContainer: {
      padding: "25px",
      height: "100%",
      width: "100%",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "linear-gradient(180deg, #E8E8E8 1.11%, #D8D8D8 100%)",
    },
    iframe: {
      height: "0%",
      zoom: "1",
      transform: "scale(1)",
      transformOrigin: "0 0",
    },
    iFrameLoaded: {
      height: "100%",
    },
    mobileContainer: {
      width: "375px",
      height: "100%",
      boxShadow: "-8px 11px 11px rgba(0, 0, 0, 0.5)",
      borderRadius: "10px",
      background: "#fff",
      overflow: "hidden",
    },
    mobile: {
      width: "375px",
    },
    desktopContainer: {
      maxWidth: "750px",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      background: "#fff",
      boxShadow: "-8px 11px 11px rgba(0, 0, 0, 0.5)",
      borderRadius: "10px",
    },
    desktop: {
      width: "2000px",
      height: "135%",
      zoom: "0.5",
      transform: "scale(0.75)",
      transformOrigin: "0 0",
    },
    buttonsContainer: {
      position: "absolute",
      top: "25px",
      left: "25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    shareOptionsButton: {
      background: "#fff",
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      transition: "0.2s",
      marginBottom: "20px",
      "&:hover": {
        background: "#fff",
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    shareOptionsButtonActive: {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    shareOptionsButtonIcon: {
      color: "#333",
      fontSize: "36px",
      transition: "0.2s",
    },
    shareOptionsButtonIconActive: {
      color: "#fff",
      transition: "0.2s",
    },
    progress: {
      position: "absolute",
      color: theme.palette.areaPulseBlue,
      animationDuration: "1500ms",
    },
    circle: {
      strokeLinecap: "round",
    },
    "@media (max-width: 600px)": {
      buttonsContainer: {
        display: "none",
      },
    },
  })
);

const ReportPreview = ({ changedTitles, setChangedTitles, userName, reportId, editedReport, setEditedReport, iFrameLoading, setiFrameLoading }) => {
  const classes = useStyles();
  const iframeRef = useRef(null);
  const [view, setView] = useState("mobile");
  const [iFrameLoaded, setIFrameLoaded] = useState(false);

  const isMobile = view === "mobile";
  const isDesk = view === "desktop";
  const baseURL = window.location.host;

  useEffect(() => {
    if (setiFrameLoading !== undefined && iFrameLoaded === false) {
      setiFrameLoading(true);
    }
  }, [setiFrameLoading]);

  useEffect(() => {
    if (iframeRef.current !== null && editedReport && changedTitles) {
      setChangedTitles(false);
      setIFrameLoaded(false);
      setiFrameLoading(true);
      const src = iframeRef.current.src;
      iframeRef.current.src = src;
    }
  }, [editedReport, setiFrameLoading]);

  useEffect(() => {
    let interval;
    if (iframeRef.current !== null) {
      interval = setInterval(() => {
        let iframeHasContent = iframeRef.current.contentDocument.getElementById("report-is-ready") !== null;
        if (iframeHasContent) {
          setiFrameLoading(false);
          clearInterval(interval);
        }
      }, 300);
    }
    return () => clearInterval(interval);
  }, [setiFrameLoading]);

  const iFrameHasLoaded = () => {
    setIFrameLoaded(true);
    if (editedReport) {
      setEditedReport(false);
    }
  };

  return (
    <Fragment>
      <div className={classes.buttonsContainer}>
        <Button className={`${classes.shareOptionsButton} ${isDesk && classes.shareOptionsButtonActive}`} onClick={() => setView("desktop")}>
          <DesktopWindowsOutlined color="inherit" fontSize="inherit" className={`${classes.shareOptionsButtonIcon} ${isDesk && classes.shareOptionsButtonIconActive}`} />
        </Button>
        <Button className={`${classes.shareOptionsButton} ${isMobile && classes.shareOptionsButtonActive}`} onClick={() => setView("mobile")}>
          <PhoneIphoneOutlined color="inherit" fontSize="inherit" className={`${classes.shareOptionsButtonIcon} ${isMobile && classes.shareOptionsButtonIconActive}`} />
        </Button>
        <a target="_blank" rel="noopener noreferrer" className={classes.marketLinks} href={`https://${baseURL}/${userName}/market-reports/${reportId}`}>
          <Button className={`${classes.shareOptionsButton}`} onClick={() => setView("mobile")}>
            <OpenInNewIcon color="inherit" fontSize="inherit" className={`${classes.shareOptionsButtonIcon}`} />
          </Button>
        </a>
      </div>
      {userName !== "" && reportId && (
        <div className={classes.sharePreviewContainer}>
          {!iFrameLoaded && (
            <CircularProgress
              size={80}
              thickness={2}
              color="inherit"
              className={classes.progress}
              classes={{
                circle: classes.circle,
              }}
            />
          )}
          <div className={`${isDesk && classes.desktopContainer} ${isMobile && classes.mobileContainer}`}>
            <iframe
              src={`https://${baseURL}/${userName}/market-reports/${reportId}`}
              className={`${classes.iframe} ${iFrameLoaded && classes.iFrameLoaded} ${isMobile && classes.mobile} ${isDesk && classes.desktop}`}
              frameBorder="none"
              title="report preview"
              ref={iframeRef}
              onLoad={() => iFrameHasLoaded()}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

ReportPreview.propTypes = {
  changedTitles: PropTypes.bool,
  setChangedTitles: PropTypes.func,
  userName: PropTypes.string,
  reportId: PropTypes.number,
  editedReport: PropTypes.bool,
  setEditedReport: PropTypes.func,
};

export default ReportPreview;
