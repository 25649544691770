import React, { useState, Fragment, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Grid, CircularProgress, Icon, Button } from "@material-ui/core";
import { ErrorOutlineOutlined, ExpandLess, ExpandMore } from "@material-ui/icons";
import { AppDispatchContext } from "../../Context/AppReducer";
import { useQuery } from "@apollo/client";
import { authApolloClient, openApolloClient } from "../../Services/Graph/apolloConfig";
import { GET_PROPERTY_TYPES, GET_PROPERTY_TYPES_OPEN } from "../../Services/Graph/CreateReport/Queries";
import getFormattedPropertyType from "../../Helpers/getFormattedPropertyType";

const useStyles = makeStyles((theme) =>
  createStyles({
    selectPropertyTypeContainer: {
      marginBottom: "20px",
    },
    selectedPropertyTypeHeader: {
      display: "flex",
      alignItems: "center",
    },
    selectPropertyTypeHeader: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "16px",
      color: "#414141",
      margin: "0px 0px 0px 0px",
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      marginLeft: "10px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginLeft: "10px",
    },
    propertyTypeContainerGrid: {
      width: "calc(100% + 20px)",
      marginLeft: "-10px",
    },
    propertyTypeContainer: {
      padding: "10px 10px",
      border: "1px solid #E9E9E9",
      borderRadius: "10px",
      background: "#fff",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      margin: "10px 10px 10px 10px",
      transition: "0.2s",
      "&:hover": {
        transition: "0.2s",
        "& $propertyTypeText": {
          color: theme.palette.areaPulseBlue,
          fontWeight: "600",
          transition: "0.2s",
        },
        "& $icon": {
          color: theme.palette.areaPulseBlue,
          transition: "0.2s",
        },
      },
    },
    propertyTypeContainerSelected: {
      borderWidth: "2px",
      borderColor: theme.palette.areaPulseBlue,
    },
    oddType: {
      marginRight: "0px",
      marginLeft: "0px",
    },
    propertyTypeText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "14px",
      color: "#818181",
      margin: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      lineHeight: "1",
      minHeight: "30px",
      transition: "0.2s",
    },
    propertyTypeTextSelected: {
      color: theme.palette.areaPulseBlue,
      fontWeight: "700",
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      color: "#818181",
      fontSize: "28px",
      marginBottom: "10px",
      transition: "0.2s",
    },
    iconSelected: {
      color: theme.palette.areaPulseBlue,
    },
    showMoreButtonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    moreButtonText: {
      fontFamily: theme.typography.secondary,
      color: theme.palette.areaPulseBlue,
      fontWeight: "600",
      fontSize: "14px",
      margin: "0px 0px 0px 5px",
      textTransform: "capitalize",
    },
    showMoreIcons: {
      color: theme.palette.areaPulseBlue,
      fontSize: "22px",
    },
  })
);

const SelectPropertyType = ({ propertyTypes, setPropertyTypes, mlsIds, editing, landingPageView }) => {
  const classes = useStyles();
  const globalDispatch = useContext(AppDispatchContext);
  const [selectedTypes, setSelectedTypes] = useState([0]);
  const [showMorePropertyTypes, setShowMorePropertyTypes] = useState(false);
  const [renderedPropertyTypes, setRenderedPropertyTypes] = useState([]);
  const fieldNames = ["propertyType"];

  const { data: propertyData, loading: propertyLoading, error: propertyError } = useQuery(landingPageView ? GET_PROPERTY_TYPES_OPEN : GET_PROPERTY_TYPES, {
    variables: {
      mlsIDs: mlsIds,
      fields: fieldNames,
    },
    client: landingPageView ? openApolloClient : authApolloClient,
  });

  useEffect(() => {
    if (landingPageView === false) {
      const errorMessage = propertyError?.networkError?.result?.error ?? "";
      if (propertyError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
        globalDispatch({ type: "setExpiredSessionModal", payload: true });
      }
    }
  }, [propertyError, globalDispatch, landingPageView]);

  useEffect(() => {
    let sortedValues = JSON.parse(JSON.stringify(propertyData?.mlsValues[0]?.values ?? []));
    if (sortedValues.indexOf("Manufactured Home") !== -1) {
      sortedValues.splice(sortedValues.indexOf("Manufactured Home"), 1);
      sortedValues.unshift("Manufactured Home");
    }
    if (sortedValues.indexOf("Farm/Ranch") !== -1) {
      sortedValues.splice(sortedValues.indexOf("Farm/Ranch"), 1);
      sortedValues.unshift("Farm/Ranch");
    }
    if (sortedValues.indexOf("Vacant Land") !== -1) {
      sortedValues.splice(sortedValues.indexOf("Vacant Land"), 1);
      sortedValues.unshift("Vacant Land");
    }
    if (sortedValues.indexOf("Multi-Family") !== -1) {
      sortedValues.splice(sortedValues.indexOf("Multi-Family"), 1);
      sortedValues.unshift("Multi-Family");
    }
    if (sortedValues.indexOf("Condo/Townhome") !== -1) {
      sortedValues.splice(sortedValues.indexOf("Condo/Townhome"), 1);
      sortedValues.unshift("Condo/Townhome");
    }
    if (sortedValues.indexOf("Residential") !== -1) {
      sortedValues.splice(sortedValues.indexOf("Residential"), 1);
      sortedValues.unshift("Residential");
    }

    if (editing) {
      const selectedValues = [];
      for (let x = 0; propertyTypes.length >= x; x++) {
        if (sortedValues.indexOf(propertyTypes[x]) !== -1) {
          selectedValues.push(sortedValues.indexOf(propertyTypes[x]));
        }
      }
      setSelectedTypes(selectedValues);
    }

    setRenderedPropertyTypes(sortedValues ?? []);
  }, [propertyData, editing, propertyTypes]);

  const handleSelect = (type, i) => {
    const isSelected = selectedTypes.indexOf(i) !== -1;
    if (isSelected) {
      const deleteFromSelected = selectedTypes.filter((t) => {
        if (t !== i) {
          return type;
        }
        return null;
      });
      setSelectedTypes(deleteFromSelected);

      const deleteFromPropertyTypes = propertyTypes.filter((t) => {
        if (t !== type) {
          return type;
        }
        return null;
      });
      setPropertyTypes(deleteFromPropertyTypes);
    } else {
      setSelectedTypes([...selectedTypes, i]);
      setPropertyTypes([...propertyTypes, type]);
    }
  };

  return (
    <div className={classes.selectPropertyTypeContainer}>
      <div className={classes.selectedPropertyTypeHeader}>
        <h1 className={classes.selectPropertyTypeHeader}>Property Type</h1>
        {(propertyLoading || (renderedPropertyTypes.length === 0 && !propertyError)) && (
          <CircularProgress
            size={15}
            thickness={6}
            color="inherit"
            className={classes.progress}
            classes={{
              circle: classes.circle,
            }}
          />
        )}
        {propertyError && <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.error} />}
      </div>
      <Grid container className={classes.propertyTypeContainerGrid}>
        {renderedPropertyTypes.map((type, i) => {
          const isSelected = selectedTypes.indexOf(i) !== -1;
          const showPropertyType = showMorePropertyTypes ? true : i < 6;
          const { name, icon, value } = getFormattedPropertyType(type);

          return (
            <Fragment key={i}>
              {showPropertyType && (
                <Grid item xs={4} sm={3} md={4} lg={4}>
                  <div onClick={() => handleSelect(value, i)} className={`${classes.propertyTypeContainer} ${isSelected && classes.propertyTypeContainerSelected}`}>
                    <div className={classes.iconContainer}>
                      <Icon fontSize="inherit" color="inherit" className={`${classes.icon} ${isSelected && classes.iconSelected}`}>
                        {icon}
                      </Icon>
                    </div>
                    <h1 className={`${classes.propertyTypeText} ${isSelected && classes.propertyTypeTextSelected}`}>{name}</h1>
                  </div>
                </Grid>
              )}
            </Fragment>
          );
        })}
      </Grid>
      {renderedPropertyTypes.length > 6 && (
        <div className={classes.showMoreButtonContainer}>
          <Button onClick={() => setShowMorePropertyTypes(!showMorePropertyTypes)}>
            <h1 className={classes.moreButtonText}>{showMorePropertyTypes ? "less" : "more"}</h1>
            {showMorePropertyTypes ? (
              <ExpandLess fontSize="inherit" color="inherit" className={classes.showMoreIcons} />
            ) : (
              <ExpandMore fontSize="inherit" color="inherit" className={classes.showMoreIcons} />
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

SelectPropertyType.propTypes = {
  propertyType: PropTypes.array,
  setPropertyType: PropTypes.func,
  editing: PropTypes.bool,
  landingPageView: PropTypes.bool,
};

export default SelectPropertyType;
