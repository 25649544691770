import React from "react";
import { createStyles, makeStyles, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
  })
);

const CicularProgressStyled = () => {
  const classes = useStyles();

  return (
    <CircularProgress
      size={60}
      thickness={3}
      color="inherit"
      className={classes.progress}
      classes={{
        circle: classes.circle,
      }}
    />
  );
};

export default CicularProgressStyled;
