import React, { useState, useContext, useEffect } from "react";
import { AppStateContext, AppDispatchContext } from "../../../../Context/AppReducer";
import { useLazyQuery } from "@apollo/client";
import { authApolloClient } from "../../../../Services/Graph/apolloConfig";
import { GET_USER_BILLING_PLANS } from "../../../../Services/Graph/Billing/Queries";
import { GET_USER_BILLING_PLANS_USAGE } from "../../../../Services/Graph/Billing/Queries";
import { createStyles, makeStyles, Paper, LinearProgress, withStyles, Button, Fade } from "@material-ui/core";
import BillingUpgrade from "../../../Common/Billing/BillingUpgrade";
import CicularProgressStyled from "../../../Common/Loaders/CircularProgressStyled";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: "20px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    infoContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
      padding: "60px",
    },
    accountSettingsHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "35px",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    accountSettingsInfoText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    titleContentReportSent: {
      marginBottom: "10px",
      marginTop: "44px",
      fontSize: "18px",
      fontFamily: "nunito",
      fontWeight: "400",
    },
    boldWords: {
      fontWeight: "700",
    },
    upgradePlanButton: {
      background: theme.palette.areaPulseBlue,
      padding: " 13px 34px",
      fontSize: "18px",
      fontWeight: "700",
      fontFamily: "nunito",
      color: "#FFFFFF",
      height: "50px",
      marginLeft: "15px",
      borderRadius: "9px",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
  })
);

const BorderLinearProgressReportsSent = withStyles((theme) => ({
  root: {
    height: "20px",
    borderRadius: "10px",
  },
  colorPrimary: {
    backgroundColor: "#E0E0E0",
  },
  bar: {
    backgroundColor: theme.palette.areaPulseBlue,
    borderRadius: "50px",
  },
}))(LinearProgress);

const UserSettingsBillingBasic = () => {
  const classes = useStyles();
  const { user } = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [userPlansMap, setUserPlansMap] = useState({});
  const [userPlansUsageMap, setUserPlansUsageMap] = useState({});
  const [userPlanSingle, setUserPlanSingle] = useState([]);
  const [userPlanToUpdate, setUserPlanToUpdate] = useState(0);

  const [fetchUserPlans, { loading: userPlansLoading, error: userPlansError }] = useLazyQuery(GET_USER_BILLING_PLANS, {
    variables: {
      id: user?.userId,
    },
    onCompleted({ user }) {
      if (user && user.subscriptions) {
        let tempMap = {};
        user.subscriptions.forEach((subscription, index) => {
          if (index === 0) {
            setUserPlanToUpdate(subscription.id);
            setUserPlanSingle([subscription]);
          }
          tempMap[subscription.itemId] = subscription.id;
        });
        setUserPlansMap(tempMap);
      }
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    fetchUserPlans();
  }, [fetchUserPlans]);

  useEffect(() => {
    const errorMessage = userPlansError?.networkError?.result?.error ?? "";
    if (userPlansError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [userPlansError, globalDispatch]);

  const [fetchUserPlansUsage, { loading: userPlansUsageLoading, error: userPlansUsageError }] = useLazyQuery(GET_USER_BILLING_PLANS_USAGE, {
    variables: {
      userId: user?.userId,
    },
    onCompleted({ userSubscriptionsUsage }) {
      if (userSubscriptionsUsage) {
        let tempMap = {};
        userSubscriptionsUsage.forEach((usage, index) => {
          tempMap[usage.id] = usage;
        });
        setUserPlansUsageMap(tempMap);
      }
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    fetchUserPlansUsage();
  }, [fetchUserPlansUsage]);

  useEffect(() => {
    const errorMessage = userPlansUsageError?.networkError?.result?.error ?? "";
    if (userPlansUsageError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [userPlansUsageError, globalDispatch]);

  const planRow = (planId, title, sponsor, usedReports, totalReports) => {
    return (
      <>
        <div className={classes.accountSettingsHeaderContainer}>
          <h1 className={classes.paperLabel}>{title}</h1>
          <div>
            <Button
              className={classes.upgradePlanButton}
              onClick={() => {
                setIsUpgradeModalOpen(true);
                setUserPlanToUpdate(planId);
              }}
            >
              <span>Upgrade</span>
            </Button>
          </div>
        </div>
        <div>
          <h2 className={classes.accountSettingsInfoText}>{sponsor ? `Your FREE account is currently being sponsored by ${sponsor}` : ""} </h2>
          <div className={classes.titleContentReportSent}>
            <span className={classes.boldWords}>{usedReports}</span> / {totalReports} Reports
          </div>
          <BorderLinearProgressReportsSent value={usedReports} variant="determinate" />
        </div>
      </>
    );
  };

  const notSubscribed = (title) => {
    return (
      <>
        <p>{title}</p>
        <Button
          className={classes.upgradePlanButton}
          onClick={() => {
            setIsUpgradeModalOpen(true);
            setUserPlanToUpdate(0);
          }}
        >
          <span>Subscribe</span>
        </Button>
      </>
    );
  };

  return (
    <Paper className={classes.paper}>
      {userPlansLoading || userPlansUsageLoading ? (
        <div className={classes.infoContainer}>
          <CicularProgressStyled />
        </div>
      ) : (
        <>
          <BillingUpgrade
            isOpen={isUpgradeModalOpen}
            subscribedPlans={userPlansMap ?? {}}
            subscribedPlanToUpdate={userPlanToUpdate}
            handleClose={(reloadUserPlans) => {
              setIsUpgradeModalOpen(false);
              if (reloadUserPlans) {
                fetchUserPlansUsage();
                fetchUserPlans();
              }
            }}
          />
          {userPlanSingle.length > 0 ? (
            <Fade in={true} timeout={500}>
              <div>{planRow(userPlanSingle[0]?.id, userPlanSingle[0]?.name, "", userPlansUsageMap[userPlanSingle[0]?.id]?.reports, userPlansUsageMap[userPlanSingle[0]?.id]?.reportsLimit)}</div>
            </Fade>
          ) : (
            <Fade in={true} timeout={500}>
              <div className={classes.infoContainer}>{notSubscribed("Not subscribed to any plans")}</div>
            </Fade>
          )}
        </>
      )}
    </Paper>
  );
};

export default UserSettingsBillingBasic;
