import React, { useState } from "react";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { createStyles, makeStyles, withStyles, Button, Grid, Fade } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "5px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    paper: {
      margin: "15px",
      padding: "30px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
    },
    contactHeaderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "30px",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    areYouSureSubText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    deleteContactModalTextContainer: {
      display: "flex",
      justifyContent: "center",
    },
    modalActions: {
      display: "flex",
      justifyContent: "center",
      marginTop: "40px",
      marginBottom: "35px",
    },
    personalDetailsForm: {
      width: "100%",
    },
    menuItem: {
      padding: "14px 20px",
    },
    menuItemIcon: {
      marginRight: "12px",
    },
    modalClose: {
      position: "absolute",
      right: "30px",
    },
    closeModalButton: {
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      "&:hover": {},
    },
    closeModalButtonIcon: {
      color: "#282828",
      fontSize: "30px",
    },
    cancelButton: {
      background: "transparent",
      minWidth: "0px",
      padding: "16px 80px",
      borderRadius: "10px",
      color: "#8D8989",
      "&:hover": {
        background: "transparent",
      },
      marginRight: "10px",
    },
    cancelButtonLabel: {
      fontFamily: theme.typography.secondary,
      color: "#8D8989",
      fontSize: "16px",
      fontWeight: "700",
      margin: 0,
    },
    deleteButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "12px 60px",
      borderRadius: "10px",
      color: "#fff",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
      marginLeft: "10px",
    },
    deleteButtonLabel: {
      fontFamily: theme.typography.secondary,
      color: "#fff",
      fontSize: "16px",
      fontWeight: "700",
      margin: 0,
    },
    deleteContactNoteButton: {
      background: theme.palette.areaPulseRed,
      minWidth: "0px",
      padding: "8px",
      marginRight: "10px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseRed,
      },
    },
    deleteContactNoteButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    "@media (max-width: 750px)": {
      paperLabel: {
        fontSize: "18px",
        textAlign: "center",
      },
      contactHeaderContainer: {},
      areYouSureSubText: {
        textAlign: "center",
      },
      modalClose: {
        position: "absolute",
        right: "10px",
      },
    },
  })
);

const Dialog = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialog);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "0 30px 30px 30px",
  },
}))(MuiDialogContent);

const LandingPageNoReports = ({ email, noReport, setNoReport }) => {
  const classes = useStyles();

  const handleClose = () => {
    setNoReport(false);
  };

  return (
    <Grid container justify="center">
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: "800px",
            margin: "0px",
          },
        }}
        onClose={handleClose}
        aria-labelledby="no-reports-modal"
        open={noReport}
      >
        <div className={classes.contactHeaderContainer}>
          <h1 className={classes.paperLabel}>This agent has run out of reports</h1>
          <div className={classes.modalClose}>
            <Button className={classes.closeModalButton} onClick={handleClose}>
              <CloseRounded color="inherit" fontSize="inherit" className={classes.closeModalButtonIcon} />
            </Button>
          </div>
        </div>

        <DialogContent>
          <div className={classes.deleteContactModalTextContainer}>
            <h1 className={classes.areYouSureSubText}>
              Please contact your agent at <a href={`mailto:${email}`}>{email}</a> to continue creating reports.
            </h1>
          </div>
        </DialogContent>
        <div className={classes.modalActions}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
            className={classes.deleteButton}
          >
            <h1 className={classes.deleteButtonLabel}>close</h1>
          </Button>
        </div>
      </Dialog>
    </Grid>
  );
};

LandingPageNoReports.propTypes = {
  noReport: PropTypes.bool,
  email: PropTypes.string,
  setNoReport: PropTypes.func,
};

export default LandingPageNoReports;
