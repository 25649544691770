import { makeStyles, createStyles } from '@material-ui/core';

const PrivacyStylesheet = makeStyles(() => createStyles({
  navContainer: {
    position: "fixed",
    top: "0px",
    left: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100px",
    width: "100%",
    paddingLeft: "25px",
    paddingRight: "25px",
    zIndex: "10",
    background: "#fff",
    borderBottom: "1px solid rgba(255,255,255,0.15)",
    transition: "ease-out 1s",
    boxShadow: "0 -20px 50px rgba(0,0,0,0.2)",
  },
  logoContainer: {
    width: "220px",
    height: "50px",
    position: "relative"
  },
  navLogoColor: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    opacity: "1",
    transition: "1s",
  },
  privacyPolicyContainer: {
    padding: "5%"
  },
  privacyHeader: {
    fontSize: "40px",
    fontWeight: "700",
    marginTop: "100px",
    marginBottom: "40px",
    fontFamily: "Lato",
    color: "#333333",
    textTransform: "capitalize"
  },
  privacyListHeader: {
    fontSize: "18px",
    marginBottom: "15px",
    fontFamily: "Nunito",
    color: "#555"
  },
  privacyListItem: {
    fontSize: "16px",
    marginTop: "5px",
    marginBottom: "5px",
    fontFamily: "Nunito",
    fontWeight: "400",
    color: "#555",
    marginLeft: "15px"
  },
  paragraphHeaders: {
    fontSize: "18px",
    fontWeight: "700",
    fontFamily: "Nunito",
    color: "#333333",
    textTransform: "capitalize",
    marginBottom: "0px",
  },
  paragraphText: {
    fontSize: "16px",
    marginTop: "0px",
    fontWeight: "400",
    fontFamily: "Nunito",
    color: "#555",
    marginBottom: "40px"
  },
  paragraphTextLast: {
    marginBottom: "0px"
  },
  privacyPopupContainer: {
    position: "fixed",
    bottom: "14px",
    left: "14px",
    padding: "10px",
    background: "#fff",
    boxShadow: "0 8px 14px rgba(0,0,0,0.2)",
    borderRadius: "5px",
    zIndex: "15",
    width: "250px"
  },
  privacyPolicyText: {
    fontSize: "14px",
    marginTop: "0px",
    marginBottom: "0px",
    fontWeight: "400",
    fontFamily: "Nunito",
    color: "#555",
  },
  learnMoreButton: {
    background: "#CD1041",
    fontSize: "12px",
    fontFamily: "Nunito",
    color: "#fff",
    textTransform: "uppercase",
    padding: "6px 12px",
    marginTop: "10px",
    boxShadow: "0 8px 14px rgba(0,0,0,0.2)",
    "&:hover": {
      background: "#b1174f"
    }
  },
  textDecoration: {
    textDecoration: "none"
  },
  closeIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
    fontSize: "25px",
    fontWeight: "900",
    color: "#CD1041",
    cursor: "pointer",
    "&:hover": {
      color: "#b1174f"
    }
  },
  "@media (max-width: 468px)": {
    navContainer: {
      justifyContent: "center"
    },
    privacyHeader: {
      marginTop: "130px",
    }
  }
}));

export default PrivacyStylesheet;