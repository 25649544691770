import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Slider, withStyles } from "@material-ui/core";
import CreateReportFilterInputSmall from "../Common/Inputs/CreateReportFilterInputSmall";

const useStyles = makeStyles((theme) =>
  createStyles({
    selectBedsContainer: {
      marginBottom: "15px",
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerText: {
      fontFamily: theme.typography.secondary,
      color: "#414141",
      fontWeight: "600",
      fontSize: "16px",
      margin: "0px",
    },
    rangesContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    hyphen: {
      margin: "0px 5px",
    },
    sliderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "15px",
    },
  })
);

const CustomSlider = withStyles((theme) => ({
  root: {
    margin: "0 30px",
  },
  thumb: {
    background: theme.palette.areaPulseBlue,
    height: "20px",
    width: "20px",
    marginTop: "-8px",
  },
  track: {
    borderRadius: "5px",
    background: "#EAEAEA",
    height: "4px",
  },
  rail: {
    borderRadius: "5px",
    background: "#EAEAEA",
    height: "4px",
  },
}))(Slider);

const SelectBeds = ({ beds, setBeds }) => {
  const classes = useStyles();
  const lowBeds = beds[0];
  const highBeds = beds[1];

  const lowBedsChange = (e) => {
    setBeds([parseInt(e.target.value.replace(/[^\d.-]/g, "") || 0), highBeds]);
  };

  const highBedsChange = (e) => {
    setBeds([lowBeds, parseInt(e.target.value.replace(/[^\d.-]/g, "") || 0)]);
  };

  return (
    <div className={classes.selectBedsContainer}>
      <div className={classes.headerContainer}>
        <h1 className={classes.headerText}>Bed</h1>
        <div className={classes.rangesContainer}>
          <CreateReportFilterInputSmall type="text" size="small" label="min" variant="filled" value={lowBeds} onChange={lowBedsChange} />
          <div className={classes.hyphen}>{"—"}</div>
          <CreateReportFilterInputSmall type="text" size="small" label="max" variant="filled" value={highBeds} onChange={highBedsChange} />
        </div>
      </div>
      <div className={classes.sliderContainer}>
        <CustomSlider value={beds} step={1} min={0} max={10} onChange={(e, value) => setBeds(value)} />
      </div>
    </div>
  );
};

SelectBeds.propTypes = {
  beds: PropTypes.array,
  setBeds: PropTypes.func,
};

export default SelectBeds;
