import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { AppDispatchContext } from "../../../../../Context/AppReducer";
import HeaderStylesheet from "../HeaderStylesheet";
import { Grid, Fade } from "@material-ui/core";
import GoogleLoginButton from "../../../../Common/GoogleLogin/GoogleLogin";
import FacebookLoginButton from "../../../../Common/FacebookLogin/FacebookLogin";
import useSignup from "../../../../../Services/Rest/signupHook";
import { saveState } from "../../../../../Helpers/localStorage";
import { withRouter } from "react-router-dom";

const SignUpSocial = ({ activeView, setActiveView, history }) => {
  const classes = HeaderStylesheet();
  const dispatch = useContext(AppDispatchContext);
  const [user, signupUser] = useSignup();
  useEffect(() => {
    if (user.isSuccessful) {
      saveState("areaPulseUserToken", user.result.token);
      dispatch({ type: "login", payload: { ...user.result, mlsLinks: user.result.mlsLinks } });
      if (!user?.result?.isNewAccount) {
        history.push(`/${user.result.username}/dashboard`);
      } else if (user?.result?.isNewAccount) {
        setActiveView(4);
      }
    }
  }, [user, dispatch]);
  const facebookButtonClicked = () => {
    console.log("Facebook Button Clicked");
  };
  const responseFacebook = (res) => {
    const firstName = res.name.split(" ")[0];
    const lastName = res.name.split(" ")[1];
    const username = `${firstName.toLowerCase()}${lastName.toLowerCase()}`;
    const facebookPayload = {
      username,
      email: res.email,
      firstName,
      lastName,
      password: "facebook",
      rePassword: "facebook",
      type: "facebook",
      socialToken: res.accessToken,
      socialUserID: res.userID,
    };
    signupUser(facebookPayload);
  };
  const googleButtonClicked = () => {
    console.log("Google Button Clicked");
  };
  const responseGoogle = (res) => {
    const firstName = res.profileObj.givenName;
    const lastName = res.profileObj.familyName;
    const username = `${firstName.toLowerCase()}${lastName.toLowerCase()}`;
    const googlePayload = {
      username,
      email: res.profileObj.email,
      firstName,
      lastName,
      password: "google",
      rePassword: "google",
      type: "google",
      socialToken: res.accessToken,
      socialUserID: res.profileObj.googleId,
    };
    signupUser(googlePayload);
  };
  return (
    <Fade in={activeView === 1} timeout={1500} className={`${classes.onBoarding} ${classes.socialCenter}`}>
      <Grid container className={classes.onBoardingContainer}>
        <div className={classes.viewContainerSocial}>
          <div className={classes.viewHeaderSmall}>
            Set Up Your Free Account In <br /> Less Than 5 Minutes
          </div>
          <div className={classes.socialButtonsContainer}>
            <FacebookLoginButton loading={user?.isFetching} size="large" clicked={() => facebookButtonClicked()} response={(res) => responseFacebook(res)} />
            <GoogleLoginButton loading={user?.isFetching} size="large" clicked={() => googleButtonClicked()} response={(res) => responseGoogle(res)} />
            {user?.errorMessage === "Email is taken by another account" && (
              <h1 className={classes.SocialemailAlreadyInUseError}>The email associated with your social media account is already being used. Please login with your email.</h1>
            )}
          </div>
          <p className={classes.continueWithEmail} onClick={() => setActiveView(2)}>
            Continue With Email
          </p>
          <p className={classes.alreadyHasAccount} onClick={() => setActiveView(3)}>
            Already Have An Account?
          </p>
        </div>
      </Grid>
    </Fade>
  );
};

SignUpSocial.propTypes = {
  activeView: PropTypes.number,
  setActiveView: PropTypes.func,
};

export default withRouter(SignUpSocial);
