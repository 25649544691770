import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Button, Grid, LinearProgress, withStyles } from "@material-ui/core";
import { GET_STATS } from "../../../Services/Graph/DashBoard/DashboardQueries";
import { GET_USER_BILLING_PLANS } from "../../../Services/Graph/Billing/Queries";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { Skeleton } from "@material-ui/lab";
import BillingUpgrade from "../../Common/Billing/BillingUpgrade";
import { AppStateContext, AppDispatchContext } from "../../../Context/AppReducer";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles((theme) =>
  createStyles({
    gridDivs: {
      borderRadius: "20px",
      backgroundColor: "#F5F5F5",
    },
    gridDivs1: {
      paddingRight: "40px",
    },
    gridDivs2: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    gridDivs3: {
      paddingLeft: "40px",
    },
    middleGridDiv: {
      marginLeft: "20px",
      marginRight: "20px",
    },
    singleContainers: {
      display: "flex",
      flexDirection: "column",
      padding: "21px 40px 45px 40px",
      borderRadius: "20px",
      backgroundColor: "#F5F5F5",
    },
    titleNumberReportsSent: {
      fontSize: "64px",
      color: "#13ce66",
      fontFamily: "nunito",
      fontWeight: "700",
    },
    titleNumberLeads: {
      fontSize: "64px",
      color: "#FFC82C",
      fontFamily: "nunito",
      fontWeight: "700",
    },
    titleNumberOpenRate: {
      fontSize: "64px",
      color: "#FF5216",
      fontFamily: "nunito",
      fontWeight: "700",
    },
    titleContent: {
      fontSize: "18px",
      color: "#000000",
      fontFamily: "nunito",
      fontWeight: "600",
    },
    titleContentOpenRate: {
      marginBottom: "10px",
      marginTop: "39px",
      fontSize: "18px",
      fontFamily: "nunito",
      fontWeight: "400",
    },
    titleContentReportSent: {
      marginBottom: "10px",
      marginTop: "39px",
      fontSize: "18px",
      fontFamily: "nunito",
      fontWeight: "400",
    },
    titleContentLeads: {
      marginBottom: "10px",
      marginTop: "39px",
      fontSize: "18px",
      fontFamily: "nunito",
      fontWeight: "400",
    },
    upgradeButton: {
      backgroundColor: "#BDBDBD",
      padding: " 13px 34px",
      fontSize: "18px",
      fontWeight: "700",
      fontFamily: "nunito",
      color: "#FFFFFF",
      height: "50px",
      marginLeft: "15px",
      borderRadius: "9px",
    },
    blueUpgradeButton: {
      backgroundColor: "#0079B4",
      padding: " 13px 34px",
      fontSize: "18px",
      fontWeight: "700",
      fontFamily: "nunito",
      color: "#FFFFFF",
      height: "50px",
      marginLeft: "15px",
      borderRadius: "9px",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
        transition: "0.2s",
        opacity: "0.8",
      },
    },
    buttonGrid: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    boldWords: {
      fontWeight: "700",
    },
    "@media (max-width: 1280px)": {
      gridDivs1: {
        marginBottom: "15px",
        paddingRight: "0",
      },
      gridDivs2: {
        marginTop: "15px",
        marginBottom: "15px",
        paddingLeft: "0",
        paddingRight: "0",
      },
      gridDivs3: {
        marginTop: "15px",
        paddingLeft: "0",
      },
    },
  })
);

const BorderLinearProgressLead = withStyles((theme) => ({
  root: {
    height: "20px",
    borderRadius: "10px",
  },
  colorPrimary: {
    backgroundColor: "#E0E0E0",
  },
  bar: {
    backgroundColor: "#FFC82C",
    borderRadius: "50px",
    transition: "transform 1.5s linear !important",
  },
}))(LinearProgress);

const BorderLinearProgressReportsSent = withStyles((theme) => ({
  root: {
    height: "20px",
    borderRadius: "10px",
  },
  colorPrimary: {
    backgroundColor: "#E0E0E0",
  },
  bar: {
    backgroundColor: "#13CE66",
    borderRadius: "50px",
    transition: "transform 1.5s linear !important",
  },
}))(LinearProgress);

const BorderLinearProgressOpenRate = withStyles((theme) => ({
  root: {
    height: "20px",
    borderRadius: "10px",
  },
  colorPrimary: {
    backgroundColor: "#E0E0E0",
  },
  bar: {
    backgroundColor: "#FF5216",
    borderRadius: "50px",
    transition: "transform 1.5s linear !important",
  },
}))(LinearProgress);

const Stats = ({ upgradeOpen, setUpgradeOpen, setUpdateStats, updateStats, userData, userLoading }) => {
  const { user } = useContext(AppStateContext);
  const [sentProgress, setSentProgress] = useState(0);
  const [openProgress, setOpenProgress] = useState(0);
  const [leadProgress, setLeadProgress] = useState(0);
  const [newDate, setNewDate] = useState("");
  const [oldDate, setOldDate] = useState("");
  const [getNewDate, setGetNewDate] = useState(true);
  const [paidAccount, setPaidAccount] = useState(false);
  const [gotAccount, setGotAccount] = useState(false);
  const [userPlanToUpdate, setUserPlanToUpdate] = useState(0);
  const [userPlansMap, setUserPlansMap] = useState({});
  const globalDispatch = useContext(AppDispatchContext);

  const classes = useStyles();

  const [fetchUserPlans, { loading: userPlansLoading, error: userPlansError }] = useLazyQuery(GET_USER_BILLING_PLANS, {
    variables: {
      id: user?.userId,
    },
    onCompleted({ user }) {
      if (user && user.subscriptions) {
        let tempMap = {};
        let firstSubscriptionAdded = false;
        user.subscriptions.forEach((subscription) => {
          if (!firstSubscriptionAdded && subscription.name.toLowerCase().indexOf("free") !== -1) {
            setUserPlanToUpdate(subscription.id);
          }
          tempMap[subscription.itemId] = subscription.id;
        });
        setUserPlansMap(tempMap);
      }
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (userData) {
      let array = [];
      array = userData?.user?.subscriptions;
      array.forEach((element) => {
        if (element.itemId !== 29) {
          setPaidAccount(true);
        }
      });
      setGotAccount(true);
    }
  }, [userData]);
  useEffect(() => {
    if (updateStats) {
      setGetNewDate(true);
      setUpdateStats(false);
    }
  }, [updateStats]);

  useEffect(() => {
    fetchUserPlans();
  }, [fetchUserPlans]);

  useEffect(() => {
    const errorMessage = userPlansError?.networkError?.result?.error ?? "";
    if (userPlansError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [userPlansError, globalDispatch]);

  useEffect(() => {
    if (getNewDate) {
      setGetNewDate(false);

      const oldDate = new Date();
      oldDate.setMonth(oldDate.getMonth() - 1);
      const isodate = oldDate.toISOString();
      setOldDate(isodate);

      const date = new Date();
      const newIsoDate = date.toISOString();
      setNewDate(newIsoDate);
    }
  }, [getNewDate]);

  const [fetchStats, { data: statsData, loading: statsLoading, error: statsError }] = useLazyQuery(GET_STATS, {
    variables: {
      startDate: oldDate,
      endDate: newDate,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (newDate) {
      fetchStats();
    }
  }, [newDate]);

  useEffect(() => {
    const errorMessage = statsError?.networkError?.result?.error ?? "";
    if (statsError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [statsError, globalDispatch]);

  const openRate = (x, y) => {
    let opens = "";
    let newOpenRate = Math.round((x / y) * 100);
    if (x === 0 && y === 0) {
      opens = 0;
    } else if (x > y) {
      opens = 100;
    } else {
      opens = newOpenRate;
    }
    return opens;
  };

  const newLeads = (x, y) => {
    let opens = "";
    let newLeadRate = Math.round((x / y) * 100);
    if (x === 0 && y === 0) {
      opens = 0;
    } else {
      opens = newLeadRate;
    }
    return opens;
  };
  useEffect(() => {
    if (statsData !== undefined && !statsLoading && userData !== undefined && !userLoading && gotAccount) {
      const loaders = setTimeout(function () {
        paidAccount
          ? setSentProgress(openRate(statsData?.userActivityStats.sent, statsData?.userActivityStats.totalReports))
          : setSentProgress(openRate(userData?.user?.totalSubscriptionUsage?.reportsUsed, statsData?.userActivityStats.totalReports));

        setOpenProgress(openRate(statsData?.userActivityStats.opened, statsData?.userActivityStats.sent));
        setLeadProgress(newLeads(statsData?.userActivityStats.leads, statsData?.userActivityStats.totalLeads));
      }, 50);
      return () => clearTimeout(loaders);
    }
  }, [statsData, gotAccount]);

  return (
    <Grid container>
      <Grid className={classes.gridDivs1} item lg={4} sm={12} xs={12}>
        {statsData === undefined || userData === undefined ? (
          <Grid className={classes.singleContainers} container>
            <Grid container>
              <Grid item xs={6}>
                <Skeleton width="110px" height="91px"></Skeleton>
              </Grid>
              <Grid className={classes.buttonGrid} item xs={6}></Grid>
            </Grid>
            <Skeleton style={{ marginTop: "18px" }} height="25px"></Skeleton>
            <Skeleton height="25px"></Skeleton>
            <Skeleton style={{ marginTop: "25px" }} height="25px"></Skeleton>
            <Skeleton height="25px"></Skeleton>
          </Grid>
        ) : (
          <Grid className={classes.singleContainers} container>
            <Grid container>
              <Grid item xs={6}>
                <div className={classes.titleNumberReportsSent}>{paidAccount ? statsData?.userActivityStats.sent : userData?.user?.totalSubscriptionUsage?.reportsUsed}</div>
              </Grid>
              <Grid className={classes.buttonGrid} item xs={6}>
                <Button
                  disabled={userPlansLoading}
                  onClick={() => {
                    setUpgradeOpen(true);
                  }}
                  className={
                    statsData?.userActivityStats?.totalReports === -1 || openRate(statsData?.userActivityStats.sent, statsData?.userActivityStats.totalReports) > 79
                      ? classes.blueUpgradeButton
                      : classes.upgradeButton
                  }
                >
                  Upgrade
                </Button>
              </Grid>
            </Grid>
            <div className={classes.titleContent}>{!paidAccount ? "Reports Created " : "Reports Sent"}</div>
            <div className={classes.titleContent}>(Last 30 Days)</div>
            <div className={classes.titleContentReportSent}>
              {statsData?.userActivityStats.totalReports === -1 ? (
                <div>Unlimited Reports</div>
              ) : (
                <div>
                  <span className={classes.boldWords}>{paidAccount ? statsData?.userActivityStats.sent : userData?.user?.totalSubscriptionUsage?.reportsUsed}</span> out of{" "}
                  {statsData?.userActivityStats.totalReports}
                </div>
              )}
            </div>
            <BorderLinearProgressReportsSent value={sentProgress} variant="determinate" />
          </Grid>
        )}
      </Grid>
      <Grid className={`${classes.gridDivs2} `} item lg={4} sm={12} xs={12}>
        {statsData === undefined || userData === undefined ? (
          <Grid className={classes.singleContainers} container>
            <Grid container>
              <Grid item xs={6}>
                <Skeleton width="110px" height="91px"></Skeleton>
              </Grid>
              <Grid className={classes.buttonGrid} item xs={6}></Grid>
            </Grid>
            <Skeleton style={{ marginTop: "18px" }} height="25px"></Skeleton>
            <Skeleton height="25px"></Skeleton>
            <Skeleton style={{ marginTop: "25px" }} height="25px"></Skeleton>
            <Skeleton height="25px"></Skeleton>
          </Grid>
        ) : (
          <Grid className={classes.singleContainers} container>
            <div className={classes.titleNumberOpenRate}>{openRate(statsData?.userActivityStats.opened, statsData?.userActivityStats.sent)}%</div>
            <div className={classes.titleContent}>Open Rate</div>
            <div className={classes.titleContent}>(Last 30 Days)</div>
            <div className={classes.titleContentOpenRate}>
              <span className={classes.boldWords}>{openRate(statsData?.userActivityStats.opened, statsData?.userActivityStats.sent)}%</span> Reports Opened
            </div>
            <BorderLinearProgressOpenRate value={openProgress} variant="determinate" />
          </Grid>
        )}
      </Grid>
      <Grid className={classes.gridDivs3} item lg={4} sm={12} xs={12}>
        {statsData === undefined || userData === undefined ? (
          <Grid className={classes.singleContainers} container>
            <Grid container>
              <Grid item xs={6}>
                <Skeleton width="110px" height="91px"></Skeleton>
              </Grid>
              <Grid className={classes.buttonGrid} item xs={6}></Grid>
            </Grid>
            <Skeleton style={{ marginTop: "18px" }} height="25px"></Skeleton>
            <Skeleton height="25px"></Skeleton>
            <Skeleton style={{ marginTop: "25px" }} height="25px"></Skeleton>
            <Skeleton height="25px"></Skeleton>
          </Grid>
        ) : (
          <Grid className={classes.singleContainers} container>
            <div className={classes.titleNumberLeads}>{statsData?.userActivityStats.leads}</div>
            <div className={classes.titleContent}>New Leads</div>
            <div className={classes.titleContent}>(Last 30 Days)</div>
            <div className={classes.titleContentLeads}>
              <span className={classes.boldWords}>{statsData?.userActivityStats.leads}</span> of {statsData?.userActivityStats.totalLeads} Leads
            </div>
            <BorderLinearProgressLead value={leadProgress} variant="determinate" />
          </Grid>
        )}
      </Grid>
      <BillingUpgrade
        isOpen={upgradeOpen}
        subscribedPlans={userPlansMap ?? {}}
        subscribedPlanToUpdate={userPlanToUpdate}
        handleClose={(reloadStats) => {
          setUpgradeOpen(false);
          if (reloadStats) {
            fetchStats();
          }
        }}
      />
    </Grid>
  );
};

Stats.propTypes = {
  setUpdateStats: PropTypes.func,
  updateStats: PropTypes.bool,
  setUpgradeOpen: PropTypes.func,
  upgradeOpen: PropTypes.bool,
  userLoading: PropTypes.bool,
  statsData: PropTypes.object,
};

export default Stats;
