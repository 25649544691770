import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { GET_MLS } from "../../../../../Services/Graph/OnBoard/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { openApolloClient, authApolloClient } from "../../../../../Services/Graph/apolloConfig";
import { makeStyles, Fade, createStyles, Grid, withStyles, FormControl, MenuItem, Select, InputLabel } from "@material-ui/core";
import { CREATE_MLS_LINK } from "../../../../../Services/Graph/UserPreferences/Mutations";
import { AppStateContext } from "../../../../../Context/AppReducer";

const useStyles = makeStyles((theme) =>
  createStyles({
    onBoarding: {
      position: "absolute",
      top: "0",
      padding: "235px 0px 0px 120px",
    },
    formControl: {
      backgroundColor: "#FFFFFF",
      width: "100%",
      height: "43px",
      maxWidth: "552px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
    },
    formControlTop: {
      marginTop: "40px",
      marginBottom: "19px",
    },
    formControlBottom: {},
    selectLabel: {
      width: "100%",
    },
    select: {
      width: "100%",
    },
    viewHeaders: {
      marginTop: "90px",
      fontFamily: "nunito",
      fontSize: "24px",
      fontWeight: "700",
      color: "#000000",
    },
    MLSErrorDiv: {
      color: "Red",
      paddingLeft: "50px",
      fontFamily: "nunito",
      fontSize: "20px",
      fontWeight: "700",
      marginTop: "15px",
    },
    viewContainer: {
      width: "670px",
      paddingBottom: "100px",
    },

    "@media (max-width: 960px)": {
      onBoarding: {
        padding: "235px 120px 0px 120px",
      },
    },
    "@media (max-width: 960px)": {
      onBoarding: {
        padding: "235px 50px 0px 50px",
      },
    },
  })
);

const RoundSelect = withStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    fontFamily: "nunito",
    fontSize: "21px",
    fontWeight: "700",
    color: "#000000",
    paddingLeft: "37px",
    paddingTop: "0px",
    backgroundColor: "transparent !important",
    "&.MuiFilledInput-underline:before": {
      display: "none",
    },
  },
  icon: {
    height: "45px",
    width: "45px",
    marginTop: "-20px",
    marginRight: "30px",
  },
}))(Select);

const RoundInputLabel = withStyles((theme) => ({
  root: {
    fontSize: "16px",
    fontWeight: "700",
    fontFamily: "nunito",
    color: "#BDBDBD",
  },
  shrink: {
    paddingTop: "10px !important",
    paddingBottom: "0px",
    transform: "translate(0, 1.5px) scale(0) !important",
    marginTop: "0px !important",
    display: "none",
  },
  focused: {
    color: `${theme.palette.areaPulseBlue} !important`,
  },
  formControl: {
    paddingLeft: "50px",
    transform: "translate(0, 22px) scale(1)",
    marginTop: "-5px",
  },
}))(InputLabel);

const CaptureMls = ({ activeView, setActiveView }) => {
  const classes = useStyles();

  const [state, setState] = useState("");
  const [sendState, setSendState] = useState("");
  const [sendAbbrState, setSendAbbrState] = useState("");
  const [MLS, setMLS] = useState("");
  const [noState, setNoState] = useState(true);
  const [MLSClicked, setMLSClicked] = useState(false);
  const [transitionDone, setTransitionDone] = useState(false);

  const globalState = useContext(AppStateContext);

  const handleStateChange = (e) => {
    var split = e?.split(" ");
    setSendState(split[1]);
    setSendAbbrState(split[0]);
    setState(e);
    getMLS();
    setMLS("");
  };

  const handleMLSChange = (e) => {
    if (state === "") {
    } else {
      setMLS(e);
      getMLS();
    }
  };

  const [getMLS, { data: MLSData, loading: MLSLoading }] = useLazyQuery(GET_MLS, {
    variables: {
      state: sendAbbrState,
    },
    client: openApolloClient,
  });

  const [createMLS, { data: createData }] = useMutation(CREATE_MLS_LINK, {
    variables: {
      userId: globalState?.user?.userId,
      mlsId: MLS,
      stateId: sendState,
    },
    client: authApolloClient,
  });
  useEffect(() => {
    if (MLSData !== undefined && !MLSLoading) {
      setNoState(false);
    }
    if (MLS !== "" && !createData) {
      setActiveView(5);
      createMLS();
    }
  }, [MLSData, MLS, createData]);
  return (
    <Fade
      in={activeView === 4}
      onTransitionEnd={() => {
        setTransitionDone(true);
      }}
      timeout={600}
      className={classes.onBoarding}
    >
      <Grid container className={classes.onBoardingContainer}>
        <div className={classes.viewContainer}>
          <div className={classes.viewHeaders}>Let's Make Sure We Have The Right Data</div>

          <FormControl className={`${classes.formControl} ${classes.formControlTop}`}>
            <RoundInputLabel className={classes.selectLabel}>STATE</RoundInputLabel>
            <RoundSelect
              value={state}
              onChange={(e) => {
                handleStateChange(e.target.value);
              }}
              disableUnderline
              className={classes.select}
              labelId="demo-simple-select-label"
            >
              <MenuItem value={"AL 33"}>Alabama</MenuItem>
              <MenuItem value={"AK 1"}>Alaska</MenuItem>
              <MenuItem value={"AZ 13"}>Arizona</MenuItem>
              <MenuItem value={"AR 24"}>Arkansas</MenuItem>
              <MenuItem value={"CA 5"}>California</MenuItem>
              <MenuItem value={"CO 11"}>Colorado</MenuItem>
              <MenuItem value={"CT 39"}>Connecticut</MenuItem>
              <MenuItem value={"DE 42"}>Delaware</MenuItem>
              <MenuItem value={"FL 50"}>Florida</MenuItem>
              <MenuItem value={"GA 49"}>Georgia</MenuItem>
              <MenuItem value={"HI 2"}>Hawaii</MenuItem>
              <MenuItem value={"ID 6"}>Idaho</MenuItem>
              <MenuItem value={"IL 27"}>Illinois</MenuItem>
              <MenuItem value={"IN 28"}>Indiana</MenuItem>
              <MenuItem value={"IA 22"}>Iowa</MenuItem>
              <MenuItem value={"KS 17"}>Kansas</MenuItem>
              <MenuItem value={"KY 30"}>Kentucky</MenuItem>
              <MenuItem value={"LA 25"}>Louisiana</MenuItem>
              <MenuItem value={"ME 34"}>Maine</MenuItem>
              <MenuItem value={"MD 44"}>Maryland</MenuItem>
              <MenuItem value={"MA 38"}>Massachusetts</MenuItem>
              <MenuItem value={"MI 26"}>Michigan</MenuItem>
              <MenuItem value={"MN 20"}>Minnesota</MenuItem>
              <MenuItem value={"MS 32"}>Mississippi</MenuItem>
              <MenuItem value={"MO 23"}>Missouri</MenuItem>
              <MenuItem value={"MT 9"}>Montana</MenuItem>
              <MenuItem value={"NE 16"}>Nebraska</MenuItem>
              <MenuItem value={"NV 7"}>Nevada</MenuItem>
              <MenuItem value={"NH 35"}>New Hampshire</MenuItem>
              <MenuItem value={"NJ 40"}>New Jersey</MenuItem>
              <MenuItem value={"NM 12"}>New Mexico</MenuItem>
              <MenuItem value={"NY 37"}>New York</MenuItem>
              <MenuItem value={"NC 46"}>North Carolina</MenuItem>
              <MenuItem value={"ND 14"}>North Dakota</MenuItem>
              <MenuItem value={"OH 29"}>Ohio</MenuItem>
              <MenuItem value={"OK 18"}>Oklahoma</MenuItem>
              <MenuItem value={"OR 4"}>Oregon</MenuItem>
              <MenuItem value={"PA 41"}>Pennsylvania</MenuItem>
              <MenuItem value={"RI 43"}>Rhode Island</MenuItem>
              <MenuItem value={"SC 47"}>South Carolina</MenuItem>
              <MenuItem value={"SD 15"}>South Dakota</MenuItem>
              <MenuItem value={"TN 31"}>Tennessee</MenuItem>
              <MenuItem value={"TX 19"}>Texas</MenuItem>
              <MenuItem value={"UT 8"}>Utah</MenuItem>
              <MenuItem value={"VT 36"}>Vermont</MenuItem>
              <MenuItem value={"VA 45"}>Virginia</MenuItem>
              <MenuItem value={"WA 3"}>Washington</MenuItem>
              <MenuItem value={"WV 48"}>West Virginia</MenuItem>
              <MenuItem value={"WI 21"}>Wisconson</MenuItem>
              <MenuItem value={"WY 10"}>Wyoming</MenuItem>
            </RoundSelect>
          </FormControl>

          <FormControl
            onClick={() => {
              setMLSClicked(true);
            }}
            className={`${classes.formControl} ${classes.formControlBottom}`}
          >
            <RoundInputLabel className={classes.selectLabel}>MLS</RoundInputLabel>
            <RoundSelect
              disabled={noState}
              onChange={(e) => {
                handleMLSChange(e.target.value);
              }}
              value={MLS}
              disableUnderline
              className={classes.select}
              labelId="demo-simple-select-label"
            >
              {MLSData?.mlses.map((MLS, index) => (
                <MenuItem key={index} value={MLS.mlsID}>
                  {MLS.name}
                </MenuItem>
              ))}
            </RoundSelect>
          </FormControl>
          {noState && MLSClicked ? <div className={classes.MLSErrorDiv}>Please Select a State</div> : null}
        </div>
      </Grid>
    </Fade>
  );
};

CaptureMls.propTypes = {
  activeView: PropTypes.number,
  setActiveView: PropTypes.func,
};

export default CaptureMls;
