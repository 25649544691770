import React, { useState, useEffect, Fragment, useContext } from "react";
import { AppStateContext } from "../../Context/AppReducer";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Backdrop } from "@material-ui/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_MARKET_REPORT, CREATE_MARKET_REPORT_OPEN, EDIT_MARKET_REPORT, SUBSCRIBE_CONTACT_TO_REPORT_FROM_LANDING_PAGE } from "../../Services/Graph/CreateReport/Mutations";
import { GET_SINGLE_REPORT } from "../../Services/Graph/CreateReport/Queries";
import { authApolloClient, openApolloClient } from "../../Services/Graph/apolloConfig";
import CreateReportTools from "./CreateReportTools";
import CreateReportShare from "./CreateReportShare";
import CreateReportLoading from "./CreateReportLoading";
import CreateReportError from "./CreateReportError";
import AreYouSure from "./AreYouSure";

const useStyles = makeStyles((theme) =>
  createStyles({
    createReportBackdrop: {
      height: "100vh",
      width: "100%",
      zIndex: "8",
      padding: (props) => props.padding,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    createReportAnimationContainer: {
      borderRadius: (props) => props.borderRadius,
    },
    createReportContentContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
      maxWidth: "1600px",
      maxHeight: "930px",
      zIndex: "10",
      background: "#fff",
      borderRadius: (props) => props.borderRadius,
      overflow: "hidden",
    },
    headerBar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: theme.palette.areaPulseBlue,
      padding: "25px 35px",
    },
    headerBarTitle: {
      color: "#fff",
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      margin: "0px",
    },
    createReportClose: {
      margin: "0px",
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "22px",
      color: "#fff",
      cursor: "pointer",
      transition: "0.2s",
      "&:hover": {
        opacity: "0.9",
        transition: "0.2s",
      },
    },
    createReportCloseDisabled: {
      cursor: "not-allowed",
      opacity: "0.5",
    },
    createReportToolsContainer: {
      height: "100%",
      width: "100%",
    },
    hidden: {
      height: "0px",
      width: "0px",
      overflow: "hidden",
    },
    "@media (max-width: 600px)": {
      createReportBackdrop: {
        padding: "5px",
      },
    },
  })
);

const CreateReport = ({
  setGetUserData,
  user,
  googleScriptLoaded,
  createReportOpen,
  setCreateReportOpen,
  setSavedReport,
  contactData,
  setSubscribe,
  landingPageView,
  createContactOpen,
  setCreateContactOpen,
  setContactReportWasMade,
}) => {
  const classes = useStyles({
    padding: createReportOpen.iframe ? 0 : 36,
    borderRadius: createReportOpen.iframe ? 0 : 20,
  });
  const globalState = useContext(AppStateContext);
  const [collectedData, setCollectedData] = useState(false);
  const [reportSubmitted, setReportSubmitted] = useState(false);
  const [reportSaved, setReportSaved] = useState(false);
  const [tryingToCloseWithoutSaving, setTryingToCloseWithoutSaving] = useState(false);
  const [marketReportSubmiting, setMarketReportSubmiting] = useState(false);
  const [editPayload, setEditPayload] = useState(false);
  const [iFrameLoading, setiFrameLoading] = useState(false);
  const [contactFrequency, setContactFrequency] = useState("biweekly");
  const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
  const landingPageContact = globalState?.landingPageContact;

  const backdropAnimations = {
    appear: 500,
    enter: 500,
    exit: 500,
  };

  const wholeName = `${contactData?.contact?.firstName} ${contactData?.contact?.lastName}`;
  const [createTheMarketReport, { data: reportDataClosed, loading: reportLoading, error: reportError }] = useMutation(createReportOpen.editing ? EDIT_MARKET_REPORT : CREATE_MARKET_REPORT, {
    variables: createReportOpen.editing
      ? {
          id: createReportOpen.editPayload,
          ...collectedData,
        }
      : contactData
      ? {
          forContactID: contactData?.contact?.id,
          ...collectedData,
        }
      : {
          ...collectedData,
        },
    client: authApolloClient,
  });

  const [createTheMarketReportOpen, { data: reportDataOpen, loading: reportLoadingOpen, error: reportErrorOpen }] = useMutation(CREATE_MARKET_REPORT_OPEN, {
    variables: {
      ...collectedData,
    },
    client: openApolloClient,
    onCompleted() {
      if (Object.keys(landingPageContact).length !== 0) {
        subscribeContactToReport();
      }
    },
  });

  const [subscribeContactToReport, { data: subscribeContactData, loading: subscribeContactDataLoading, error: subscribeContactDataError }] = useMutation(
    SUBSCRIBE_CONTACT_TO_REPORT_FROM_LANDING_PAGE,
    {
      variables: {
        frequency: "biweekly",
        title: reportDataOpen?.createReportRequest?.parameters?.address,
        headline: "Market Report For",
        contactID: landingPageContact?.id,
        reportRequestID: reportDataOpen?.createReportRequest?.id,
        thumbnail: collectedData?.thumbnail,
      },
      client: openApolloClient,
    }
  );

  const reportData = landingPageView ? reportDataOpen : reportDataClosed;

  const [getReportForEdit, { data: reportEditData, loading: reportEditLoading, error: reportEditError }] = useLazyQuery(GET_SINGLE_REPORT, {
    variables: {
      id: createReportOpen.editPayload,
    },
    client: authApolloClient,
    fetchPolicy: "no-cache",
  });
  const collectMarketReportInfo = (values) => {
    setCollectedData(values);
  };
  const closeCreateMarketReport = () => {
    if (!closeButtonDisabled) {
      if (reportSaved && reportSubmitted) {
        setReportSaved(false);
        if (setSavedReport !== undefined) {
          setSavedReport(true);
        }
        setCreateReportOpen({ open: false, editing: false, editPayload: {} });
        document.body.style.overflowY = "visible";
      } else if (!reportSaved && reportSubmitted && !createReportOpen.editing) {
        setTryingToCloseWithoutSaving(true);
      } else if (!reportSaved && reportSubmitted && createReportOpen.editing) {
        setReportSaved(false);

        if (setSavedReport !== undefined) {
          setSavedReport(true);
        }
        setCreateReportOpen({ open: false, editing: false, editPayload: {} });
        document.body.style.overflowY = "visible";
      } else {
        setCreateReportOpen({ open: false, editing: false, editPayload: {} });
        document.body.style.overflowY = "visible";
        if (setSavedReport !== undefined) {
          setSavedReport(false);
        }
      }
    }
  };

  useEffect(() => {
    if (collectedData && landingPageView) {
      createTheMarketReportOpen();
    }
    if (collectedData && !landingPageView) {
      createTheMarketReport();
    }
  }, [collectedData, landingPageView, createContactOpen]);

  useEffect(() => {
    if (iFrameLoading) {
      setCloseButtonDisabled(true);
    } else if (!iFrameLoading) {
      setCloseButtonDisabled(false);
    }
  }, [iFrameLoading]);

  useEffect(() => {
    const hasDateCreated = createReportOpen.editing ? reportData?.data?.updateMarketReport?.dateCreated !== "" : reportData?.data?.createMarketReport?.dateCreated !== "";
    if (hasDateCreated && !reportLoading && !reportError && collectedData) {
      setReportSubmitted(true);
    }
  }, [reportData, reportLoading, reportError]);

  useEffect(() => {
    if (createReportOpen.open) {
      document.body.style.overflowY = "hidden";
    }
  }, [createReportOpen]);

  useEffect(() => {
    if (!reportEditData && !reportEditLoading && !reportEditError) {
      if (createReportOpen.editing) {
        getReportForEdit();
      }
    } else {
      setEditPayload(reportEditData?.report);
    }
  }, [createReportOpen, reportEditData, reportEditLoading, reportEditError]);
  return (
    <Fragment>
      <Backdrop open={createReportOpen.open ?? false} onClick={(e) => e.stopPropagation()} transitionDuration={backdropAnimations} className={classes.createReportBackdrop}>
        <div onClick={(e) => e.stopPropagation()} className={classes.createReportContentContainer}>
          {!createReportOpen.iframe && (
            <div className={classes.headerBar}>
              <h1 className={classes.headerBarTitle}>{createReportOpen.editing ? `Editing ${editPayload?.title ?? "your report"}` : `Create a Report${contactData ? ` for ${wholeName}` : ""}`}</h1>
              <h1 onClick={() => closeCreateMarketReport()} className={`${classes.createReportClose} ${closeButtonDisabled && classes.createReportCloseDisabled}`}>
                CLOSE
              </h1>
            </div>
          )}
          <div className={`${classes.createReportToolsContainer} ${googleScriptLoaded && !reportSubmitted && !reportLoading && !reportError ? "" : classes.hidden}`}>
            <CreateReportTools
              user={user}
              collectedData={collectedData}
              collectMarketReportInfo={(values) => collectMarketReportInfo(values)}
              marketReportSubmiting={marketReportSubmiting}
              setMarketReportSubmiting={(status) => setMarketReportSubmiting(status)}
              createReportOpen={createReportOpen}
              editPayload={editPayload}
              landingPageView={landingPageView}
              createContactOpen={createContactOpen}
              setCreateContactOpen={setCreateContactOpen}
              setContactFrequency={setContactFrequency}
              contactFrequency={contactFrequency}
            />
          </div>
          {reportData && reportSubmitted && !reportLoading && !reportError && (
            <CreateReportShare
              setGetUserData={setGetUserData}
              setSubscribe={setSubscribe}
              contactData={contactData}
              subscribeContactData={subscribeContactData}
              user={user}
              reportData={reportData}
              thumbnail={collectedData?.thumbnail}
              createReportOpen={createReportOpen}
              reportSaved={reportSaved}
              setReportSaved={(status) => setReportSaved(status)}
              setReportSubmitted={(status) => setReportSubmitted(status)}
              setMarketReportSubmiting={(status) => setMarketReportSubmiting(status)}
              iFrameLoading={iFrameLoading}
              setiFrameLoading={(status) => setiFrameLoading(status)}
              landingPageView={landingPageView}
              setContactReportWasMade={setContactReportWasMade}
              contactFrequency={contactFrequency}
            />
          )}
          {reportLoading && !reportError && <CreateReportLoading />}
          {!reportLoading && reportError && <CreateReportError error={reportError} />}
        </div>
      </Backdrop>
      <AreYouSure
        tryingToCloseWithoutSaving={tryingToCloseWithoutSaving}
        setTryingToCloseWithoutSaving={(bool) => setTryingToCloseWithoutSaving(bool)}
        setCreateReportOpen={(status) => setCreateReportOpen(status)}
        setSavedReport={setSavedReport !== undefined ? (status) => setSavedReport(status) : false}
        id={createReportOpen.editing ? reportData?.updateMarketReport?.id : reportData?.createMarketReport?.id}
        editing={createReportOpen.editing}
        landingPageView={landingPageView}
      />
    </Fragment>
  );
};

CreateReport.propTypes = {
  setSubscribe: PropTypes.func,
  contactData: PropTypes.object,
  user: PropTypes.string,
  googleScriptLoaded: PropTypes.bool,
  createReportOpen: PropTypes.object,
  setCreateReportOpen: PropTypes.func,
  setSavedReport: PropTypes.func,
  landingPageView: PropTypes.bool,
  createContactOpen: PropTypes.object,
  setCreateContactOpen: PropTypes.func,
  setGetUserData: PropTypes.func,
  setContactReportWasMade: PropTypes.func,
};

export default CreateReport;
