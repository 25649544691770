import React, { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { AppDispatchContext } from "../../../../../Context/AppReducer";
import PropTypes from "prop-types";
import { Grid, Fade, CircularProgress, withStyles, TextField, makeStyles, createStyles, Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import GoogleLoginButton from "../../../../Common/GoogleLogin/GoogleLogin";
import FacebookLoginButton from "../../../../Common/FacebookLogin/FacebookLogin";
import useSignup from "../../../../../Services/Rest/signupHook";
import useValidateEmail from "../../../../../Services/Rest/validateEmailHook";
import { saveState } from "../../../../../Helpers/localStorage";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    emailParent: {
      top: "0",
      position: "absolute",
      padding: "128px 0px 0px 152px",
    },
    emailAlreadyInUseError: {
      maxWidth: "471px",
      color: "red",
      marginTop: "8px",
      padding: "0px 10px",
    },
    emailTitle: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "24px",
      lineHeight: "32px",
      marginTop: "22px",
      marginBottom: "34px",
    },
    backButton: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#D33141",
      cursor: "pointer",
    },
    goBack: {
      display: "flex",
      marginTop: "35px",
      alignItems: "center",
      justifyContent: "space-between",
      maxWidth: "471px",
    },
    loginParentLeft: {
      display: "flex",
    },
    alreadyHasAccount: {
      textDecoration: "underline",
      cursor: "pointer",
      color: "#D33141",
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "16px",
      marginRight: "16px",
    },
    forgetPassword: {
      textDecoration: "underline",
      cursor: "pointer",
      color: "##777D7Få",
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "16px",
    },
    topText: {
      margin: "23px 0px 0px",
    },
    bottomText: {
      marginTop: "26px",
    },
    viewContainerSmall: {
      width: "100%",
    },
    loginError: {
      color: "red",
      marginLeft: "30px",
    },
    progress: {
      height: "19px !important",
      color: "#FFF",
      width: "19px !important",
    },
    orDiv: {
      color: "#7E7E7E",
      fontFamily: "nunito",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "19px",
      margin: "0px 15px",
    },
    borderDiv: {
      width: "100%",
      border: "0.5px solid #7E7E7E",
      height: "0px",
    },
    dividerDiv: {
      display: "flex",
      maxWidth: "471px",
      alignItems: "center",
      marginBottom: "27px",
    },
    googleButton: {
      marginBottom: "19px",
    },
    facebookButton: {
      marginBottom: "23px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    twoTexts: {
      maxWidth: "471px",
      display: "flex",
      flexDirection: "row",
    },
    leftText: {
      marginRight: "5.5px",
    },
    rightText: {
      marginLeft: "5.5px",
    },
    texts: {
      marginBottom: "25px",
    },
  })
);

const ReportContactInput = withStyles({
  root: {
    width: "100%",
    maxWidth: "471px",
    height: "43px",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    "& label": {
      marginTop: "-3px",
      color: "#CDCDCD",
      paddingLeft: "14px",
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "14px",
    },
    "& label.Mui-focused": {
      color: "#777777",
      fontSize: "14px",
      paddingTop: "0px",
    },
    "& .MuiInputLabel-shrink": {
      paddingTop: "0px",
      fontSize: "14px",
    },
    "& .MuiFilledInput-root": {
      width: "100%",
      padding: "0px 0px 0px 10px",
      fontSize: "17px",
      fontWeight: "600",
      fontFamily: "nunito",
      height: "45px",
      color: "#000",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
      "&.MuiFilledInput-underline:after": {
        display: "none",
      },
    },
  },
})(TextField);

const SampleButton = withStyles((theme) => ({
  root: {
    color: "#FFF",
    minWidth: "107px",
    top: "0",
    right: "0",
    padding: "15px 29px",
    background: "linear-gradient(180deg, #FF507A 0%, #E14864 100%)",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Nunito",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "700",
    "&:hover": {
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const SignUpEmail = ({ emailValue, setEmailValue, activeView, setActiveView, history }) => {
  const classes = useStyles();
  const dispatch = useContext(AppDispatchContext);
  const { handleSubmit, register, errors, watch } = useForm({
    defaultValues: {
      email: emailValue ?? "",
    },
  });

  const [user, signupUser] = useSignup();
  const [email, validateEmail] = useValidateEmail();

  useEffect(() => {
    if (user.isSuccessful) {
      saveState("areaPulseUserToken", user.result.token);
      dispatch({ type: "login", payload: { ...user.result, mlsLinks: user.result.mlsLinks } });
      if (!user?.result?.isNewAccount) {
        history.push(`/${user.result.username}/dashboard`);
      } else if (user?.result?.isNewAccount) {
        setActiveView(4);
      }
    }
  }, [user, dispatch]);

  const facebookButtonClicked = () => {
    console.log("Facebook Button Clicked");
  };
  const responseFacebook = (res) => {
    const firstName = res.name.split(" ")[0];
    const lastName = res.name.split(" ")[1];
    const username = `${firstName.toLowerCase()}${lastName.toLowerCase()}`;
    const facebookPayload = {
      username,
      email: res.email,
      firstName,
      lastName,
      password: "facebook",
      rePassword: "facebook",
      type: "facebook",
      socialToken: res.accessToken,
      socialUserID: res.userID,
    };
    signupUser(facebookPayload);
  };

  const googleButtonClicked = () => {
    console.log("Google Button Clicked");
  };
  const responseGoogle = (res) => {
    const firstName = res.profileObj.givenName;
    const lastName = res.profileObj.familyName;
    const username = `${firstName.toLowerCase()}${lastName.toLowerCase()}`;
    const googlePayload = {
      username,
      email: res.profileObj.email,
      firstName,
      lastName,
      password: "google",
      rePassword: "google",
      type: "google",
      socialToken: res.accessToken,
      socialUserID: res.profileObj.googleId,
    };
    signupUser(googlePayload);
  };

  const onSubmit = (values) => {
    const emailPayload = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      rePassword: values.confirmPassword,
      type: "email",
    };
    signupUser(emailPayload);
  };

  const validatePassword = (value) => {
    if (value === "") return "Please enter a password";
    if (value.length < 8) return "Password must have at least 8 characters";
    if (!value.match(/[A-Z]/)) return "Please include an uppercase letter";
    if (!value.match(/[0-9]/)) return "Please include a number";
    if (!value.match(/[#?!@$%^&*-]/)) return "Please include a special character";
    return true;
  };
  return (
    <Fade in={activeView === 10} timeout={600} className={classes.emailParent}>
      <Grid container className={classes.onBoardingContainer}>
        <div className={classes.viewContainerTop}>
          <div
            onClick={() => {
              setActiveView(2);
            }}
            className={classes.backButton}
          >
            <ArrowBack /> Back
          </div>
          <div className={`${classes.emailTitle}`}>Create Your Account</div>
          <div className={classes.googleButton}>
            {/* <GoogleLoginButton loading={user?.isFetching} size="small" clicked={() => googleButtonClicked()} response={(res) => responseGoogle(res)} /> */}
          </div>
          <div className={classes.facebookButton}>
            <FacebookLoginButton loading={user?.isFetching} size="small" clicked={() => facebookButtonClicked()} response={(res) => responseFacebook(res)} />
          </div>

          <div className={classes.dividerDiv}>
            <div className={classes.borderDiv}></div>
            <div className={classes.orDiv}>OR</div>
            <div className={classes.borderDiv}></div>
          </div>
          <form onSubmit={handleSubmit((data) => onSubmit(data))} id="sign-up-form">
            <div className={classes.twoTexts}>
              <ReportContactInput
                type="name"
                name="firstName"
                variant="filled"
                label="Enter First Name"
                className={`${classes.leftText} ${classes.texts}`}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName?.message}
                InputProps={{
                  name: "firstName",
                }}
                inputRef={register({
                  validate: (value) => {
                    if (value === "") {
                      return "Please enter a first name";
                    }
                  },
                  pattern: {
                    value: /^[a-zA-Z ]+$/,
                    message: "Please enter a valid first name",
                  },
                })}
              />
              <ReportContactInput
                type="name"
                name="lastName"
                variant="filled"
                label="Enter Last Name"
                className={`${classes.rightText} ${classes.texts}`}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName?.message}
                InputProps={{
                  name: "lastName",
                }}
                inputRef={register({
                  validate: (value) => {
                    if (value === "") {
                      return "Please enter a last name";
                    }
                  },
                  pattern: {
                    value: /^[a-zA-Z ]+$/,
                    message: "Please enter a valid last name",
                  },
                })}
              />
            </div>
            <ReportContactInput
              type="email"
              variant="filled"
              label="Enter Email Address"
              className={classes.texts}
              // value={emailValue}
              // onChange={(e) => {
              //   setEmailValue(e.target.value);
              // }}
              error={Boolean(errors.email) || email?.errorMessage !== ""}
              helperText={errors.email?.message !== "" ? errors.email?.message : email?.errorMessage}
              InputProps={{
                name: "email",
              }}
              inputRef={register({
                validate: (value) => {
                  if (value === "") {
                    return "Please enter an email address";
                  } else {
                    validateEmail(value);
                  }
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Please enter a valid email address",
                },
              })}
            />
            <ReportContactInput
              type="password"
              name="password"
              variant="filled"
              label="Create Password"
              className={classes.texts}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              InputProps={{
                name: "password",
              }}
              inputRef={register({
                validate: (value) => validatePassword(value),
              })}
            />
            <ReportContactInput
              type="password"
              name="confirmPassword"
              variant="filled"
              label="Confirm Password"
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword?.message}
              InputProps={{
                name: "confirmPassword",
              }}
              inputRef={register({
                validate: (value) => value === watch("password") || "Passwords do not match",
              })}
            />
          </form>

          {user.errorMessage === "Email is taken by another account" && (
            <div className={classes.emailAlreadyInUseError}>The email associated with this account is already being used. Please login with your email.</div>
          )}

          {email.errorMessage === "Email is taken by another account" && !user.errorMessage && !user.isFetching && (
            <div className={classes.emailAlreadyInUseError}>This email is already being used. Please login with your email.</div>
          )}

          <div className={classes.goBack}>
            <div className={classes.alreadyHasAccount} onClick={() => setActiveView(3)}>
              Already Have An Account? Log In
            </div>
            <div>
              <SampleButton className={`${""}`} type="submit" form="sign-up-form">
                {user?.isFetching ? <CircularProgress className={classes.progress} /> : "create account"}
              </SampleButton>
            </div>
          </div>
        </div>
        <div className={classes.nextSlideContainer}></div>
      </Grid>
    </Fade>
  );
};

SignUpEmail.propTypes = {
  activeView: PropTypes.number,
  setActiveView: PropTypes.func,
};

export default withRouter(SignUpEmail);
