const getFormattedPropertyType = type => {
  switch (type) {
    case "Residential":
      return {
        name: "Residential",
        icon: "house",
        value: type
      };
    case "Residential Lease":
      return {
        name: "Residential Lease",
        icon: "house",
        value: type
      };
    case "Condo/Townhome":
      return {
        name: "Condo Or Townhome",
        icon: "apartment",
        value: type
      };
    case "Commercial":
      return {
        name: "Commercial",
        icon: "store",
        value: type
      };
    case "Commercial Lease":
      return {
        name: "Commercial Lease",
        icon: "store",
        value: type
      };
    case "Commercial Sale":
      return {
        name: "Commercial Sale",
        icon: "store",
        value: type
      };
    case "Farm/Ranch":
      return {
        name: "Farm Or Ranch",
        icon: "agriculture",
        value: type
      };
    case "Vacant Land":
      return {
        name: "Vacant Land",
        icon: "landscape",
        value: type
      };
    case "Business Opportunity":
      return {
        name: "Business Opportunity",
        icon: "add_business",
        value: type
      };
    case "Manufactured Home":
      return {
        name: "Manufactured Home",
        icon: "home",
        value: type
      };
    case "Mixed Use":
      return {
        name: "Mixed Use",
        icon: "home_work",
        value: type
      };
    case "Multi-Family":
      return {
        name: "Multi Family",
        icon: "family_restroom",
        value: type
      };
    default:
      return {
        name: type,
        icon: "home",
        value: type
      }
  };
};

export default getFormattedPropertyType;