import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core";
import AdminHeader from "./AdminHeader";
import AdminBody from "./AdminBody";

const useStyles = makeStyles((theme) =>
  createStyles({
    dashboardContainer: {
      width: "100%",
      height: "100%",
      padding: "60px 80px 60px 180px",
    },
    statsMargin: {
      margin: "60px 0px 55px 0px",
    },
  })
);

const AdminComponent = ({ user, setCreateReportOpen, setSavedReport, savedReport }) => {
  const classes = useStyles();
  return (
    <div className={classes.dashboardContainer}>
      <AdminHeader />
      <AdminBody />
    </div>
  );
};

AdminComponent.propTypes = {
  user: PropTypes.string,
  setCreateReportOpen: PropTypes.func,
  setSavedReport: PropTypes.func,
  savedReport: PropTypes.bool,
};

export default AdminComponent;
