import React from "react";
import { Element } from "react-scroll";
import { makeStyles, createStyles, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    headerSection: {
      position: "relative",
      padding: "120px 0px 60px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#ffffff",
    },
    sectionContentContainer: {
      position: "relative",
      zIndex: "7",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    textCenter: {
      textAlign: "center",
    },
    fadeInUp: {
      animationName: "$fadeInUp",
    },
    "@keyframes fadeInUp": {
      from: {
        opacity: "0",
        transform: "translate3d(0, 100%, 0)",
      },
      to: {
        opacity: "1",
        transform: "translate3d(0, 0, 0)",
      },
    },
    mainHeader: {
      fontSize: "64px",
      fontFamily: "Nunito",
      margin: "20px 0px 60px 0px",
      lineHeight: "1",
      fontWeight: "700",
    },
    secondaryHeader: {
      fontSize: "48px",
      fontFamily: "Nunito",
      margin: "20px 0px 10px 0px",
      lineHeight: "1",
      fontWeight: "700",
    },
    "@media (max-width: 1055px)": {
      headerHeader: {
        fontSize: "34px",
      },
      mainHeader: {
        fontSize: "34px",
      },
      secondaryHeader: {
        fontSize: "24px",
      },
    },
    "@media (max-width: 960px)": {
      headerSection: {
        padding: "45px 25px",
      },
    },
    "@media (max-width: 768px)": {
      headerSection: {
        padding: "45px 25px",
      },
    },
  })
);

const Header = ({ userData }) => {
  const classes = useStyles();

  return (
    <Element name="header" className={classes.headerSection}>
      <Grid container className={classes.sectionContentContainer}>
        <Grid item xs={12} sm={12} md={8}>
          {userData?.user?.landingPageSettings?.headline !== "" ? (
            <h2 className={`wow ${classes.fadeInUp} ${classes.secondaryHeader} ${classes.textCenter}`}>{userData?.user?.landingPageSettings?.headline}</h2>
          ) : null}
          {userData?.user?.landingPageSettings?.subheadline !== "" ? (
            <h2 className={`wow ${classes.fadeInUp} ${classes.mainHeader} ${classes.textCenter}`}>{userData?.user?.landingPageSettings?.subheadline}</h2>
          ) : null}
        </Grid>
      </Grid>
    </Element>
  );
};

Header.propTypes = {
  userData: PropTypes.object,
};

export default Header;
