import React from "react";
import { makeStyles, createStyles, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    dashboardHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "0.5px solid #DEDEDE",
      padding: "0px 0px 25px 0px",
    },
    dashboardHeaderName: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "36px",
      color: "#4E4E4E",
      margin: "0px",
    },
    lessWeight: {
      fontWeight: "600",
    },
    createReportButtonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "9px 20px",
      background: "#F5F5F5",
      borderRadius: "10px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      cursor: "pointer",
    },
    createReportButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    createReportButtonLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "16px",
      color: "#333",
      margin: "0px 16px 0px 0px",
    },
    createReportButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
  })
);

const MarketReportHeader = ({ setCreateReportOpen, setSavedReport }) => {
  const classes = useStyles();

  const handleCreateReport = (e) => {
    e.stopPropagation();
    setCreateReportOpen({ open: true, editing: false, editPayload: {} });
    setSavedReport(false);
  };

  return (
    <div className={classes.dashboardHeaderContainer}>
      <h1 className={classes.dashboardHeaderName}>
        Market Reports
        <br />
      </h1>
      <div className={classes.createReportButtonContainer} onClick={(e) => handleCreateReport(e)}>
        <h1 className={classes.createReportButtonLabel}>
          Create New
          <br />
          Report
        </h1>
        <Button className={classes.createReportButton} onClick={(e) => handleCreateReport(e)}>
          <Add color="inherit" fontSize="inherit" className={classes.createReportButtonIcon} />
        </Button>
      </div>
    </div>
  );
};

MarketReportHeader.propTypes = {
  setCreateReportOpen: PropTypes.func,
  setSavedReport: PropTypes.func,
};

export default MarketReportHeader;
