import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Button, CircularProgress } from "@material-ui/core";
import { Done } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    saveButtonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "9px 20px",
      background: "#F5F5F5",
      borderRadius: "10px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      cursor: "pointer",
      maxWidth: "235px",
      marginTop: "30px",
    },
    saveButtonLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "16px",
      color: "#333",
      margin: "0px 25px 0px 0px",
    },
    saveButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    saveButtonDisabled: {
      cursor: "not-allowed",
      opacity: "0.5",
    },
    saveButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    circle: {
      strokeLinecap: "round",
    },
    progressOutter: {
      color: "#fff",
      animationDuration: "1700ms",
    },
  })
);

const SaveLandingPageSettings = ({ userData, usernameError, savingLandingPageSettings, iFrameLoading, setSavingLandingPageSettings }) => {
  const classes = useStyles();
  const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);

  useEffect(() => {
    if ((iFrameLoading && !userData) || usernameError.status) {
      setCloseButtonDisabled(true);
    } else if (!iFrameLoading) {
      setCloseButtonDisabled(false);
    }
  }, [iFrameLoading, usernameError]);

  const handleSave = (e) => {
    if (!closeButtonDisabled) {
      e.stopPropagation();
      setSavingLandingPageSettings(true);
    }
  };

  return (
    <div className={classes.saveButtonContainer} onClick={(e) => handleSave(e)}>
      {!savingLandingPageSettings && <h1 className={classes.saveButtonLabel}>Save Preferences</h1>}
      {savingLandingPageSettings && <h1 className={classes.saveButtonLabel}>Saving Preferences</h1>}
      <Button className={`${classes.saveButton} ${closeButtonDisabled && classes.saveButtonDisabled}`} onClick={(e) => handleSave(e)}>
        {!savingLandingPageSettings && <Done color="inherit" fontSize="inherit" className={classes.saveButtonIcon} />}
        {savingLandingPageSettings && (
          <CircularProgress
            size={24}
            thickness={4}
            color="inherit"
            classes={{
              circle: classes.circle,
            }}
            className={classes.progressOutter}
          />
        )}
      </Button>
    </div>
  );
};

SaveLandingPageSettings.propTypes = {
  userData: PropTypes.object,
  setSavingLandingPageSettings: PropTypes.func,
  iFrameLoading: PropTypes.bool,
  savingLandingPageSettings: PropTypes.bool,
};

export default SaveLandingPageSettings;
