import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookLoginStylesheet from "./FacebookLoginStylesheet";
import { Button, CircularProgress, withStyles } from "@material-ui/core";
import { Facebook } from "@material-ui/icons";
import { getFacebookClientId } from "../../../Helpers/getEnvVar";

const SocialButton = withStyles((theme) => ({
  root: {
    maxWidth: "471px",
    color: "#D33141",
    height: "43px",
    width: "100%",
    padding: "15px 29px",
    background: "#FFF",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Nunito",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "700",
    border: "1px solid #E4E4E4",

    "&:hover": {
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const FacebookLoginButton = ({ size, clicked, response, loading }) => {
  const classes = FacebookLoginStylesheet();
  const facebookClientId = getFacebookClientId();
  return (
    <FacebookLogin
      appId={facebookClientId}
      fields="name,email,picture"
      callback={(res) => response(res)}
      render={(renderProps) => {
        return (
          <SocialButton
            disabled={loading}
            onClick={() => {
              renderProps.onClick();
              clicked();
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z"
                fill="#D33141"
              />
            </svg>
            <span className={classes.socialText}>sign up With Facebook</span>
            {loading ? <CircularProgress className={classes.progress} /> : null}
          </SocialButton>
        );
      }}
    />
  );
};

export default FacebookLoginButton;
