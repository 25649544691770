import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    parentDiv: {
      width: "100%",
      maxWidth: "1560px",
      fontFamily: "nunito",
    },
    reportParent: {
      padding: "15px 49px 20px 20px",
      height: "132px",
      width: "100%",
      borderRadius: "10px",
      background: "#FEFEFE",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
      marginBottom: "24px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    reportParentChosen: {
      padding: "11px 45px 16px 16px",
      border: "4px solid #0079B4",
    },
    listingPhoto: {
      width: "154px",
      height: "92px",
      borderRadius: "10px",
    },
    leftParentDiv: {
      display: "flex",
    },
    reportTitle: {
      fontWeight: "700",
      fontSize: "20px",
      margin: "8px 0px 4px",
    },
    frequency: {
      fontWeight: "700",
      fontSize: "14px",
    },
    textParent: {
      marginLeft: "34px",
    },
    blueBox: {
      height: "42px",
      width: "42px",
      border: "3px solid #CFCFCF",
      borderRadius: "6px",
    },
    blueBoxChecked: {
      height: "42px",
      width: "42px",
      borderRadius: "6px",
      backgroundColor: "#0079B4",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    overMap: {
      overflow: "scroll",
      padding: "5px",
      maxHeight: "480px",
    },
    "@media (max-width: 900px)": {
      reportParent: {
        height: "80px",
        padding: "15px 10px 20px 20px",
      },
      listingPhoto: {
        display: "none",
      },
      textParent: {
        marginLeft: "0px",
      },
      overMap: {
        maxHeight: "340px",
      },
      reportParentChosen: {
        padding: "11px 10px 16px 16px",
      },
      reportTitle: {
        maxHeight: "50px",
        overflow: "scroll",
      },
    },
  })
);

const UnsubscribeTable = ({ data, setData, onChange }) => {
  const classes = useStyles();
  const toggle = (index) => {
    const newData = [...data];
    newData.splice(index, 1, {
      checked: !data[index].checked,
      id: data[index].id,
      title: data[index].title,
    });
    setData(newData);
    onChange(newData.filter((x) => x.checked));
  };

  return (
    <div className={classes.overMap}>
      {data
        ? data.map((report, index) => {
            return (
              <div key={index} onClick={() => toggle(index)} className={`${classes.reportParent} ${report.checked ? classes.reportParentChosen : null}`}>
                <div className={classes.leftParentDiv}>
                  <img src="/assets/no-image.png" alt="outlined" className={`${classes.listingPhoto}`} />
                  <div className={classes.textParent}>
                    <div className={classes.reportTitle}>{report.title}</div>
                    {/* <div className={classes.frequency}>Frequency: {report.frequency}</div> */}
                    {/* <div className={classes.frequency}>Last Sent: {report.sent}</div> */}
                  </div>
                </div>
                <div>
                  {report?.checked ? (
                    <div className={classes.blueBoxChecked}>
                      <img alt="check" className={`${classes.svgImage}`} src="/assets/check.png" />
                    </div>
                  ) : (
                    <div className={classes.blueBox}></div>
                  )}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};
UnsubscribeTable.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.array,
  onChange: PropTypes.func,
};

export default UnsubscribeTable;
