import React from "react";
import { createStyles, makeStyles, Paper } from "@material-ui/core";
import ContactSubscribedTable from "./ContactSubscribedTable";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: "20px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
    },
    contactHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "30px",
    },
    addContactButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
  })
);

const ContactSubscribed = ({
  reportsRemaining,
  reportSent,
  setReportSent,
  contactDetails,
  setSubscribe,
  subscribe,
  contactId,
  subscriptionWasMade,
  setSubscriptionWasDeleted,
  setCreateReportOpen,
  setNoReports,
  setRegrabReportsSent,
  paidAccount,
  contactReportWasMade,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <div className={classes.contactHeaderContainer}>
        <h1 className={classes.paperLabel}>Reports</h1>
      </div>
      <div>
        <ContactSubscribedTable
          paidAccount={paidAccount}
          setRegrabReportsSent={setRegrabReportsSent}
          setNoReports={setNoReports}
          reportsRemaining={reportsRemaining}
          reportSent={reportSent}
          setReportSent={setReportSent}
          contactDetails={contactDetails}
          setSubscribe={setSubscribe}
          subscribe={subscribe}
          contactId={contactId}
          subscriptionWasMade={subscriptionWasMade}
          setSubscriptionWasDeleted={setSubscriptionWasDeleted}
          setCreateReportOpen={(status) => setCreateReportOpen(status)}
          contactReportWasMade={contactReportWasMade}
        />
      </div>
    </Paper>
  );
};

ContactSubscribed.propTypes = {
  contactDetails: PropTypes.object,
  reportsRemaining: PropTypes.number,
  setSubscribe: PropTypes.func,
  setRegrabReportsSent: PropTypes.func,
  setNoReports: PropTypes.func,
  reportSent: PropTypes.bool,
  paidAccount: PropTypes.bool,
  setReportSent: PropTypes.func,
  subscribe: PropTypes.bool,
  contactId: PropTypes.string,
  subscriptionWasMade: PropTypes.bool,
  setSubscriptionWasDeleted: PropTypes.func,
  setCreateReportOpen: PropTypes.func,
  contactReportWasMade: PropTypes.bool,
};

export default ContactSubscribed;
