import React, { useState, useContext, useCallback } from "react";
import { AppDispatchContext } from "../../Context/AppReducer";
import { makeStyles, createStyles, Button, IconButton } from "@material-ui/core";
import {
  Add,
  DashboardOutlined,
  TrendingUpRounded,
  CallOutlined,
  SettingsOutlined,
  ChevronRightRounded,
  ChevronLeftRounded,
  InputOutlined,
  SupervisorAccount,
  BusinessCenter,
} from "@material-ui/icons";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { saveState } from "../../Helpers/localStorage";

const useStyles = makeStyles((theme) =>
  createStyles({
    navigationContainer: {
      position: "fixed",
      top: "0px",
      left: "0px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "column",
      background: "#fff",
      boxShadow: "1px 0px 2px rgba(0, 0, 0, 0.08)",
      height: "100vh",
      width: "100px",
      zIndex: "5",
      transition: "0.25s",
    },
    navParent: {
      transition: "0.25s",
      overflow: "hidden",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "column",
      height: "100vh",
      width: "100px",
    },
    expanded: {
      width: "230px",
    },
    logoContainer: {
      height: "40px",
      width: "100%",
      margin: "20px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      height: "100%",
      transition: " opacity 2s ease-in-out",
    },
    hidden: {
      opacity: "0",
      visibility: "hidden",
      width: "0px",
    },
    imageLink: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    createReportContainer: {
      position: "absolute",
      transition: "0.25s",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "110px 0px 30px 0px",
    },

    createReportButtonContainer: {
      maxHeight: "204px",
      transition: "0.25s",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "9px 20px",
      background: "#F5F5F5",
      borderRadius: "10px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      cursor: "pointer",
    },
    smallContainer: {
      padding: "0px",
    },
    createReportButtonLabel: {
      position: "absolute",
      height: "45px",
      width: "108px",
      overflow: "hidden",
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "16px",
      color: "#333",
      margin: "0px 16px 0px 0px",
    },
    smallText: {
      height: "0px",
      width: "0px",
      margin: "0",
    },
    createReportButton: {
      transition: "0.25s",
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    createBig: {
      marginLeft: "124px",
    },
    createReportButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    navigationOptionContainer: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderLeft: "4px solid #fff",
      width: "100%",
    },
    navigationOptionContainerExtra: {
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderLeft: "4px solid #fff",
      width: "100%",
      padding: "10px 24px 10px 20px",
    },
    optionContainerExpanded: {
      justifyContent: "flex-start",
    },
    navigationLink: {
      display: "flex",
      marginLeft: "0px",
      alignItems: "center",
      textDecoration: "none",
      height: "100%",
      width: "100%",
      padding: "12px 24px 12px 20px",
      transition: "0.35s",
    },
    navigationLinkCenter: {
      padding: "12px 24px 12px 33px",
    },
    navigationItemIcon: {
      fontSize: "26px",
      color: "#818181",
      transition: "0.5s",
    },
    navOptionText: {
      fontFamily: theme.typography.secondary,
      fontSize: "18px",
      fontWeight: "600",
      color: "#818181",
      transition: "0.35s",
      width: "0px",
      overflow: "hidden",
      margin: "0px 0px 0px 0px",
    },
    navOptionTextMarketReports: {
      fontFamily: theme.typography.secondary,
      fontSize: "18px",
      fontWeight: "600",
      color: "#818181",
      transition: "0.35s",
      width: "1000px",
      margin: "0px 0px 0px 0px",
      height: "25px",
    },
    navOptionTextSmall: {
      margin: "0px 0px 0px 20px",
      width: "100% !important",
    },
    navOptionTextMarketReportsParent: {
      position: "relative",
      transition: "0.35s",
      width: "0px",
      overflow: "hidden",
    },
    navOptionTextReport: {
      position: "absolute",
      width: "200px",
      height: "25px",
      fontFamily: theme.typography.secondary,
      fontSize: "18px",
      fontWeight: "600",
      color: "#818181",
      margin: "0px 0px 0px 46px",
    },
    navOptionTextExtra: {
      width: "137px",
      overflow: "hidden",
      fontFamily: theme.typography.secondary,
      fontSize: "14px",
      fontWeight: "600",
      color: "#818181",
      margin: "0px 0px 0px 45px",
    },
    navOptionTextActive: {
      color: theme.palette.areaPulseBlue,
    },
    dividerHiddenBig: {
      width: "100%",
      height: "50px",
    },
    bottomNavContainer: {
      position: "absolute",
      bottom: "50px",
      width: "100%",
    },
    navContainerActive: {
      borderLeftColor: theme.palette.areaPulseBlue,
    },
    navOptionActive: {
      color: theme.palette.areaPulseBlue,
    },
    cursor: {
      cursor: "pointer",
    },
    expandButton: {
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0008), 0px 4px 8px rgba(0, 0, 0, 0.12)",
      padding: "5px !important",
      position: "absolute",
      bottom: "150px",
      right: "-26px",
      background: "#fff",
      "&:hover": {
        background: "#fff",
      },
    },
    expandIcon: {
      color: "#818181",
      fontSize: "40px",
    },
    userPreferencesExtraOption: {
      background: "#F4F4F4",
      width: "100%",
      height: "0px",
      minHeight: "0px",
      transition: "0.25s",
      overflow: "hidden",
    },
    userPreferencesExtraOptionActive: {
      height: "140px",
      minHeight: "140px",
    },
    extraOptionsLink: {
      textDecoration: "none",
      height: "100%",
      width: "100%",
    },
    logoutUser: {
      position: "absolute",
      height: "20px",
    },
    topLink: {
      marginTop: "110px",
    },
    "@media (max-height: 720px)": {
      navParent: {
        overflow: "scroll",
        height: "720px",
      },
      createReportContainer: {
        position: "relative",
        margin: "30px 0px 30px 0px",
      },
      topLink: {
        marginTop: "30px",
      },
      bottomNavContainer: {
        position: "relative",
        bottom: "0px",
      },
      navigationOptionContainer: {
        minHeight: "50px",
      },
    },
  })
);

const NavigationComponent = ({ state, userRole, user, history, match, setCreateReportOpen, setSavedReport }) => {
  const classes = useStyles();
  const globalDispatch = useContext(AppDispatchContext);

  const isDashboardActive = match.url.includes("dashboard");
  const isMarketReportsActive = match.url.includes("market-reports");
  const isContactsActive = match.url.includes("contacts");
  const isAdminActive = match.url.includes("admin");

  const isUserSettingsActive = match.url.includes("user-preferences") || match.url.includes("user-settings");
  const [clickedUserPrefs, setClickedUserPrefs] = useState(isUserSettingsActive);
  const [expanded, setExpanded] = useState(false);
  const [permExpanded, setPermExpanded] = useState(false);
  const handleCreateReport = useCallback(
    (e) => {
      e.stopPropagation();
      setCreateReportOpen({ open: true, editing: false, editPayload: {} });
      if (setSavedReport !== undefined) {
        setSavedReport(false);
      }
    },
    [setCreateReportOpen, setSavedReport]
  );

  const checkIfAdmin = useCallback(() => {
    let admin = false;
    if (userRole) {
      userRole.forEach((element) => {
        if (element.role.name === "Administrator") {
          admin = true;
        }
      });
    }
    return admin;
  }, [userRole]);

  const handleLogout = () => {
    globalDispatch({ type: "logout", history });
  };

  const handleLogoutOfUser = () => {
    saveState("areaPulseUserToken", state.logInAsUser.token);
    history.push(`/${state.logInAsUser.username}/admin`);
    globalDispatch({ type: "login", payload: state.logInAsUser });
    globalDispatch({ type: "loginAsUser", payload: {} });
  };

  return (
    <div onMouseEnter={() => setExpanded(!expanded)} onMouseLeave={() => setExpanded(false)} className={`${classes.navigationContainer} ${expanded || permExpanded ? classes.expanded : ""}`}>
      <div className={` ${classes.navParent} ${expanded || permExpanded ? classes.expanded : ""}`}>
        <div className={classes.logoContainer}>
          <Link to={`/${user}/dashboard`} className={classes.imageLink}>
            <img src="/assets/ap-color.png" alt="areapulse logo" className={`${classes.logo} ${!expanded && !permExpanded ? classes.hidden : null}`} />
            <img src="/assets/ap-logo-new.png" alt="areapulse logo" className={`${classes.logo} ${expanded || permExpanded ? classes.hidden : null}`} />
          </Link>
        </div>
        <div className={`${classes.createReportContainer}`}>
          <div
            className={` ${classes.createReportButtonContainer} ${expanded || permExpanded ? null : classes.smallContainer}`}
            onClick={(e) => handleCreateReport(e)}
            style={{ overflow: "hidden", position: "relative" }}
          >
            <h1 className={`${classes.createReportButtonLabel} ${expanded || permExpanded ? null : classes.smallText}`}>
              Create Market
              <br />
              Report
            </h1>
            <Button className={`${classes.createReportButton} ${expanded || permExpanded ? classes.createBig : null}`} onClick={(e) => handleCreateReport(e)}>
              <Add color="inherit" fontSize="inherit" className={classes.createReportButtonIcon} />
            </Button>
          </div>
        </div>

        <div className={`${classes.topLink} ${classes.navigationOptionContainer}  ${isDashboardActive ? classes.navContainerActive : ""}`}>
          <Link to={`/${user}/dashboard`} className={`${classes.navigationLink} ${!expanded && !permExpanded && classes.navigationLinkCenter}`} onClick={(e) => e.stopPropagation()}>
            <DashboardOutlined fontSize="inherit" color="inherit" className={`${classes.navigationItemIcon} ${isDashboardActive ? classes.navOptionActive : ""}`} />
            <h1 className={`${classes.navOptionText} ${isDashboardActive ? classes.navOptionTextActive : ""} ${expanded || permExpanded ? classes.navOptionTextSmall : ""}`}>Dashboard</h1>
          </Link>
        </div>

        <div
          className={`${classes.navigationOptionContainer} ${expanded && permExpanded ? classes.optionContainerExpanded : ""} ${isMarketReportsActive ? classes.navContainerActive : ""}`}
          style={{ overflow: "hidden", position: "relative" }}
        >
          <Link to={`/${user}/market-reports`} className={`${classes.navigationLink} ${!expanded && !permExpanded && classes.navigationLinkCenter}`} onClick={(e) => e.stopPropagation()}>
            <TrendingUpRounded fontSize="inherit" color="inherit" className={`${classes.navigationItemIcon} ${isMarketReportsActive ? classes.navOptionActive : ""}`} />
            <div className={`${classes.navOptionTextMarketReportsParent}  ${expanded || permExpanded ? classes.navOptionTextSmall : ""}`}>
              <h1 className={`${classes.navOptionTextMarketReports} ${isMarketReportsActive ? classes.navOptionTextActive : ""} `}>Market Reports</h1>
            </div>
          </Link>
        </div>

        <div className={`${classes.navigationOptionContainer} ${expanded || permExpanded ? classes.optionContainerExpanded : ""} ${isContactsActive ? classes.navContainerActive : ""}`}>
          <Link to={`/${user}/contacts`} className={`${classes.navigationLink} ${!expanded && !permExpanded && classes.navigationLinkCenter}`} onClick={(e) => e.stopPropagation()}>
            <CallOutlined fontSize="inherit" color="inherit" className={`${classes.navigationItemIcon} ${isContactsActive ? classes.navOptionActive : ""}`} />
            <h1 className={`${classes.navOptionText} ${isContactsActive ? classes.navOptionTextActive : ""} ${expanded || permExpanded ? classes.navOptionTextSmall : ""}`}>Contacts</h1>
          </Link>
        </div>
        {checkIfAdmin() ? (
          <div className={`${classes.navigationOptionContainer} ${expanded || permExpanded ? classes.optionContainerExpanded : ""} ${isAdminActive ? classes.navContainerActive : ""}`}>
            <Link to={`/${user}/admin`} className={`${classes.navigationLink} ${!expanded && !permExpanded && classes.navigationLinkCenter}`} onClick={(e) => e.stopPropagation()}>
              <SupervisorAccount fontSize="inherit" color="inherit" className={`${classes.navigationItemIcon} ${isAdminActive ? classes.navOptionActive : ""}`} />
              <h1 className={`${classes.navOptionText} ${isAdminActive ? classes.navOptionTextActive : ""} ${expanded || permExpanded ? classes.navOptionTextSmall : ""}`}>Admin</h1>
            </Link>
          </div>
        ) : null}

        <div
          className={`${classes.navigationOptionContainer} ${expanded || permExpanded ? classes.optionContainerExpanded : ""} ${
            isUserSettingsActive && clickedUserPrefs ? classes.navContainerActive : ""
          }`}
        >
          <div
            className={`${classes.navigationLink} ${classes.cursor} ${!expanded && !permExpanded && classes.navigationLinkCenter}`}
            onClick={(e) => {
              setExpanded(true);
              setClickedUserPrefs(true);
              e.stopPropagation();
            }}
          >
            <SettingsOutlined fontSize="inherit" color="inherit" className={`${classes.navigationItemIcon} ${isUserSettingsActive ? classes.navOptionActive : ""}`} />
            <h1 className={`${classes.navOptionText} ${isUserSettingsActive ? classes.navOptionTextActive : ""} ${expanded || permExpanded ? classes.navOptionTextSmall : ""}`}>User Preferences</h1>
          </div>
        </div>
        <div className={`${classes.userPreferencesExtraOption} ${clickedUserPrefs && (expanded || permExpanded) && classes.userPreferencesExtraOptionActive}`}>
          <div className={`${classes.navigationOptionContainerExtra} ${classes.optionContainerExpanded}`}>
            <Link to={`/${user}/user-preferences`} onClick={(e) => e.stopPropagation()} className={classes.extraOptionsLink}>
              <h1 className={classes.navOptionTextExtra}>Report Preferences</h1>
            </Link>
          </div>
          <div className={`${classes.navigationOptionContainerExtra} ${classes.optionContainerExpanded}`}>
            <Link to={`/${user}/user-settings`} onClick={(e) => e.stopPropagation()} className={classes.extraOptionsLink}>
              <h1 className={classes.navOptionTextExtra}>User Settings</h1>
            </Link>
          </div>
          <div className={`${classes.navigationOptionContainerExtra} ${classes.optionContainerExpanded}`}>
            <Link to={`/${user}/landing-page-preferences`} onClick={(e) => e.stopPropagation()} className={classes.extraOptionsLink}>
              <h1 className={classes.navOptionTextExtra}>Landing Page Preferences</h1>
            </Link>
          </div>
        </div>

        <div className={classes.bottomNavContainer}>
          {/* <div
          onClick={() => {
            setNotificationActive(!notificationActive);
            setHelpActive(false);
          }}
          className={`${classes.navigationOptionContainerExtra} ${expanded ? classes.optionContainerExpanded : ""} ${classes.cursor} ${notificationActive ? classes.navContainerActive : ""}`}
        >
          <NotificationsOutlined fontSize="inherit" color="inherit" className={`${classes.navigationItemIcon} ${notificationActive ? classes.navOptionActive : ""}`} />
          {expanded && <h1 className={`${classes.navOptionText} ${notificationActive ? classes.navOptionTextActive : ""}`}>Notifications</h1>}
        </div>
        <div
          className={`${classes.navigationOptionContainerExtra} ${expanded ? classes.optionContainerExpanded : ""} ${classes.cursor} ${helpActive ? classes.navContainerActive : ""}`}
          onClick={() => {
            setHelpActive(!helpActive);
            setNotificationActive(false);
          }}
        >
          <HelpOutline fontSize="inherit" color="inherit" className={`${classes.navigationItemIcon} ${helpActive ? classes.navOptionActive : ""}`} />
          {expanded && <h1 className={`${classes.navOptionText} ${helpActive ? classes.navOptionTextActive : ""}`}>Help</h1>}
        </div> */}
          <div
            onClick={() => {
              handleLogout();
            }}
            className={`${classes.navigationOptionContainer} ${expanded && permExpanded ? classes.optionContainerExpanded : ""}`}
            style={{ overflow: "hidden", position: "relative" }}
          >
            <div className={`${classes.navigationLink} ${!expanded && !permExpanded && classes.navigationLinkCenter}`} onClick={(e) => {}}>
              <InputOutlined fontSize="inherit" color="inherit" className={`${classes.navigationItemIcon} `} />
              <div className={`${classes.navOptionTextMarketReportsParent}  ${expanded || permExpanded ? classes.navOptionTextSmall : ""}`}>
                <h1 className={`${classes.navOptionTextMarketReports} `}>Logout</h1>
              </div>
            </div>
          </div>
          {Object.keys(state?.logInAsUser ?? {}).length !== 0 ? (
            <div
              onClick={() => {
                handleLogoutOfUser();
              }}
              className={`${classes.navigationOptionContainer} ${expanded && permExpanded ? classes.optionContainerExpanded : ""}`}
              style={{ overflow: "hidden", position: "relative" }}
            >
              <div className={`${classes.navigationLink} ${!expanded && !permExpanded && classes.navigationLinkCenter}`} onClick={(e) => {}}>
                <BusinessCenter fontSize="inherit" color="inherit" className={`${classes.navigationItemIcon} `} />
                <div className={`${classes.navOptionTextMarketReportsParent}  ${expanded || permExpanded ? classes.navOptionTextSmall : ""}`}>
                  <h1 className={`${classes.navOptionTextMarketReports} `}>Logout of User</h1>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setPermExpanded(!permExpanded);
        }}
        className={classes.expandButton}
      >
        {expanded || permExpanded ? (
          <ChevronLeftRounded color="inherit" fontSize="inherit" className={classes.expandIcon} />
        ) : (
          <ChevronRightRounded color="inherit" fontSize="inherit" className={classes.expandIcon} />
        )}
      </IconButton>
    </div>
  );
};

NavigationComponent.propTypes = {
  user: PropTypes.string,
  userRole: PropTypes.array,
  setCreateReportOpen: PropTypes.func,
  setSavedReport: PropTypes.func,
  state: PropTypes.object,
};

export default withRouter(NavigationComponent);
