import React, { useState, useEffect, useContext } from "react";
import { makeStyles, createStyles, Grid, Button, TextField, withStyles, FormControl, MenuItem, Select, InputLabel, Fade } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useLazyQuery, useMutation } from "@apollo/client";
import { authApolloClient } from "../../../../Services/Graph/apolloConfig";
import { AppStateContext } from "../../../../Context/AppReducer";
import { GET_MlS, GET_MLS_LINKS } from "../../../../Services/Graph/UserPreferences/Queries";
import { CREATE_MLS_LINK, DELETE_MLS_LINK } from "../../../../Services/Graph/UserPreferences/Mutations";
import { Skeleton } from "@material-ui/lab";
import DeletePopUp from "./UserSettingMlsTabDelete";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(3),
    },
    demo1: {
      backgroundColor: theme.palette.background.paper,
    },
    parentDiv: {},
    topGridRight: {
      paddingLeft: "11px",
    },
    officeId: {
      margin: "2px 5px 0px",
      padding: "4px 10px",
      fontWeight: "400",
      fontSize: "18px",
      fontFamily: "nunito",
      backgroundColor: "#E0E0E0",
      borderRadius: "20px",
    },
    firstOfficeId: {
      marginLeft: "0px !important",
    },
    overOfficeId: {
      marginBottom: "24px",
      display: "flex",
      flexFlow: "wrap",
    },
    mlsMap: {
      overflowY: "scroll",
      height: "370px",
    },
    topGridLeft: {
      paddingRight: "11px",
    },
    deleteButton: {
      borderRadius: "50px",
      minWidth: "30px",
    },
    delete: {
      display: "flex",
      justifyContent: " flex-end",
    },
    formControl: {
      backgroundColor: "#FFFFFF",
      width: "100%",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
    },
    mlsInfo: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    Skeleton: {
      height: "40px",
      margin: "12px 0px",
    },
    formControlError: {
      margin: "15px 0px",
    },
    formControlNoError: {
      margin: "15px 0px 3px",
    },
    showMLS: {
      fontSize: "18px",
      fontFamily: "nunito",
      fontWeight: "700",
      padding: "20px 0px",
    },
    showMLsNoid: {
      fontSize: "18px",
      fontFamily: "nunito",
      fontWeight: "700",
      padding: "20px 0px 10px",
    },
    overGrid: {},
    gridPadding: {
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      padding: "30px",
    },
    topTitle: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "24px",
      color: "#000000",
    },
    topTitleMLS: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "24px",
      color: "#000000",
      paddingBottom: "22px",
    },
    selectLabel: {
      width: "100%",
    },
    select: {
      width: "100%",
    },
    btnDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    comma: {
      borderRadius: "10px",
      clipPath: "polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)",
      backgroundColor: "#FAFAFA",
      width: "163px",
      height: "35px",
      fontSize: "14px",
      fontFamily: "nunito",
      fontWeight: "bold",
      position: "absolute",
      marginLeft: "-170px",
      marginTop: "245px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    commaBottom: {
      borderRadius: "10px",
      clipPath: "polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)",
      backgroundColor: "#FAFAFA",
      width: "163px",
      height: "35px",
      fontSize: "14px",
      fontFamily: "nunito",
      fontWeight: "bold",
      position: "absolute",
      marginLeft: "-170px",
      marginTop: "323px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    commaParent: {
      filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
    },
    createError: {
      color: "Red",
    },
    MLSErrorDiv: {
      color: "Red",
      paddingLeft: " 34px",
    },
    hr: {
      border: "1px solid #E9E9E9",
      margin: "0px",
    },
  })
);
const ReportContactInput = withStyles({
  root: {
    margin: "15px 0px",
    width: "100%",
    height: "50px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    "& label": {
      color: "#777777",
      paddingLeft: "19px",
    },
    "& label.Mui-focused": {
      color: "#777777",
      paddingLeft: "19px",
    },
    "& .MuiFilledInput-root": {
      color: "#000",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
      "&.MuiFilledInput-underline:after": {
        display: "none",
      },
    },
  },
})(TextField);

const CloseButton = withStyles((theme) => ({
  root: {
    textTransform: "none",
    margin: "30px 0px 6px",
    color: "#fff",
    background: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: "nunito",
    fontWeight: "700",
    padding: "6px 10px",
    borderRadius: "7px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const RoundSelect = withStyles((theme) => ({
  root: {
    paddingLeft: "26px",
    backgroundColor: "transparent !important",
    "&.MuiFilledInput-underline:before": {
      display: "none",
    },
  },
  icon: {
    marginTop: "-7px",
    marginRight: "22px",
  },
}))(Select);

const RoundInputLabel = withStyles((theme) => ({
  shrink: {
    paddingTop: "10px",
    paddingBottom: "0px",
    transform: "translate(0, 1.5px) scale(0.75) !important",
    marginTop: "0px !important",
  },
  focused: {
    color: `${theme.palette.areaPulseBlue} !important`,
  },
  formControl: {
    paddingLeft: "35px",
    transform: "translate(0, 22px) scale(1)",
    marginTop: "-3px",
  },
}))(InputLabel);

const UserSettingsMlsTab = ({ selectedTab }) => {
  const classes = useStyles();
  const [state, setState] = useState("");
  const [sendState, setSendState] = useState("");
  const [sendAbbrState, setSendAbbrState] = useState("");
  const [MLS, setMLS] = useState("");
  const [noState, setNoState] = useState(true);
  const [MLSCLicked, setMLSCLicked] = useState(false);
  const [deleteThisMLS, setDeleteThisMLS] = useState(1);
  const [creationError, setCreationError] = useState(false);
  const [agentIDValue, setAgentIDValue] = useState("");
  const [officeIDValue, setOfficeIDValue] = useState("");
  const [splitAgentId, setSplitAgentId] = useState([]);
  const [splitOfficeId, setSplitOfficeId] = useState([]);
  const [reportUp, setReportUp] = useState(false);
  const [pullDelete, setPullDelete] = useState(false);
  const [mlsCreateButton, setMlsCreateButton] = useState(false);
  const [topComma, setTopComma] = useState(false);
  const [bottomComma, setBottomComma] = useState(false);

  const globalState = useContext(AppStateContext);

  const [getMLS, { data: MLSData }] = useLazyQuery(GET_MlS, {
    variables: {
      state: sendAbbrState,
    },
    client: authApolloClient,
  });

  const [deleteMLS, { data: deleteData }] = useMutation(DELETE_MLS_LINK, {
    variables: {
      userId: globalState?.user.userId,
      id: deleteThisMLS,
    },
    client: authApolloClient,
  });

  const [createMLS, { data: createData, loading: createLoading }] = useMutation(CREATE_MLS_LINK, {
    variables: {
      userId: globalState?.user.userId,
      mlsId: MLS,
      stateId: sendState,
      mlsAgentId: splitAgentId,
      mlsOfficeId: splitOfficeId,
    },
    client: authApolloClient,
  });

  const [getMLSLinks, { data: MLSLinkData, loading: MLSLinkLoading }] = useLazyQuery(GET_MLS_LINKS, {
    variables: {
      id: globalState?.user.userId,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });
  const handleStateChange = (e) => {
    var split = e.split(" ");
    setSendState(split[1]);
    setSendAbbrState(split[0]);
    setState(e);
    getMLS();
    setMLS("");
    setNoState(false);
  };

  const handleMLSChange = (e) => {
    if (state === "") {
    } else {
      setMLS(e);
      getMLS();
    }
  };

  const handleMLSClick = (e) => {
    setMLSCLicked(true);
  };

  const handleAgentIdChange = (e) => {
    setAgentIDValue(e);
  };

  const handleOfficeIdChange = (e) => {
    setOfficeIDValue(e);
  };

  const handleCreateMls = () => {
    if (state !== "" && MLS !== "") {
      var goodAgentIds = agentIDValue.split(",");
      var newAgentIds = [];
      for (let i = 0; i < goodAgentIds.length; i++) {
        if (goodAgentIds[i] !== "") {
          var newestAgentIds = goodAgentIds[i].replace(/\s/g, "");
          // if(newestAgentIds.match(/\s/))
          newAgentIds.push(newestAgentIds);
        }
      }
      var goodOfficeIds = officeIDValue.split(",");
      var newOfficeIds = [];
      for (let i = 0; i < goodOfficeIds.length; i++) {
        if (goodOfficeIds[i] !== "") {
          var newestOfficeIds = goodOfficeIds[i].replace(/\s/g, "");
          newOfficeIds.push(newestOfficeIds);
        }
      }

      setSplitAgentId(newAgentIds);
      setSplitOfficeId(newOfficeIds);
      setMlsCreateButton(true);
    } else {
      setCreationError(true);
    }
  };
  useEffect(() => {
    if (mlsCreateButton === true) {
      createMLS().then(() => {
        getMLSLinks();
      });
      setMlsCreateButton(false);
    }
    if (MLSLinkData === undefined) {
      getMLSLinks();
    }
    if (pullDelete === true) {
      deleteMLS().then(() => {
        getMLSLinks();
      });
      setPullDelete(false);
    }
  }, [pullDelete, deleteData, createData, mlsCreateButton]);
  return (
    <Fade in={selectedTab === 2} timeout={500}>
      <div className={classes.parentDiv}>
        {topComma ? (
          <div className={classes.commaParent}>
            <div className={classes.comma}>Separate by Comma</div>
          </div>
        ) : null}
        {bottomComma ? (
          <div className={classes.commaParent}>
            <div className={classes.commaBottom}>Separate by Comma</div>
          </div>
        ) : null}

        <Grid className={classes.overGrid} container>
          <Grid className={classes.topGridLeft} item xs={6} sm={12} md={6}>
            <div className={classes.gridPadding}>
              <div className={classes.topTitle}>ADD MLS</div>
              <FormControl className={`${classes.formControl} ${classes.formControlError}`}>
                <RoundInputLabel className={classes.selectLabel}>State</RoundInputLabel>
                <RoundSelect
                  value={state}
                  onChange={(e) => {
                    handleStateChange(e.target.value);
                  }}
                  disableUnderline
                  className={classes.select}
                  labelId="demo-simple-select-label"
                >
                  <MenuItem value={"AL 33"}>Alabama</MenuItem>
                  <MenuItem value={"AK 1"}>Alaska</MenuItem>
                  <MenuItem value={"AZ 13"}>Arizona</MenuItem>
                  <MenuItem value={"AR 24"}>Arkansas</MenuItem>
                  <MenuItem value={"CA 5"}>California</MenuItem>
                  <MenuItem value={"CO 11"}>Colorado</MenuItem>
                  <MenuItem value={"CT 39"}>Connecticut</MenuItem>
                  <MenuItem value={"DE 42"}>Delaware</MenuItem>
                  <MenuItem value={"FL 50"}>Florida</MenuItem>
                  <MenuItem value={"GA 49"}>Georgia</MenuItem>
                  <MenuItem value={"HI 2"}>Hawaii</MenuItem>
                  <MenuItem value={"ID 6"}>Idaho</MenuItem>
                  <MenuItem value={"IL 27"}>Illinois</MenuItem>
                  <MenuItem value={"IN 28"}>Indiana</MenuItem>
                  <MenuItem value={"IA 22"}>Iowa</MenuItem>
                  <MenuItem value={"KS 17"}>Kansas</MenuItem>
                  <MenuItem value={"KY 30"}>Kentucky</MenuItem>
                  <MenuItem value={"LA 25"}>Louisiana</MenuItem>
                  <MenuItem value={"ME 34"}>Maine</MenuItem>
                  <MenuItem value={"MD 44"}>Maryland</MenuItem>
                  <MenuItem value={"MA 38"}>Massachusetts</MenuItem>
                  <MenuItem value={"MI 26"}>Michigan</MenuItem>
                  <MenuItem value={"MN 20"}>Minnesota</MenuItem>
                  <MenuItem value={"MS 32"}>Mississippi</MenuItem>
                  <MenuItem value={"MO 23"}>Missouri</MenuItem>
                  <MenuItem value={"MT 9"}>Montana</MenuItem>
                  <MenuItem value={"NE 16"}>Nebraska</MenuItem>
                  <MenuItem value={"NV 7"}>Nevada</MenuItem>
                  <MenuItem value={"NH 35"}>New Hampshire</MenuItem>
                  <MenuItem value={"NJ 40"}>New Jersey</MenuItem>
                  <MenuItem value={"NM 12"}>New Mexico</MenuItem>
                  <MenuItem value={"NY 37"}>New York</MenuItem>
                  <MenuItem value={"NC 46"}>North Carolina</MenuItem>
                  <MenuItem value={"ND 14"}>North Dakota</MenuItem>
                  <MenuItem value={"OH 29"}>Ohio</MenuItem>
                  <MenuItem value={"OK 18"}>Oklahoma</MenuItem>
                  <MenuItem value={"OR 4"}>Oregon</MenuItem>
                  <MenuItem value={"PA 41"}>Pennsylvania</MenuItem>
                  <MenuItem value={"RI 43"}>Rhode Island</MenuItem>
                  <MenuItem value={"SC 47"}>South Carolina</MenuItem>
                  <MenuItem value={"SD 15"}>South Dakota</MenuItem>
                  <MenuItem value={"TN 31"}>Tennessee</MenuItem>
                  <MenuItem value={"TX 19"}>Texas</MenuItem>
                  <MenuItem value={"UT 8"}>Utah</MenuItem>
                  <MenuItem value={"VT 36"}>Vermont</MenuItem>
                  <MenuItem value={"VA 45"}>Virginia</MenuItem>
                  <MenuItem value={"WA 3"}>Washington</MenuItem>
                  <MenuItem value={"WV 48"}>West Virginia</MenuItem>
                  <MenuItem value={"WI 21"}>Wisconson</MenuItem>
                  <MenuItem value={"WY 10"}>Wyoming</MenuItem>
                </RoundSelect>
              </FormControl>
              <FormControl error={noState && MLSCLicked} onClick={handleMLSClick} className={`${classes.formControl} ${noState && MLSCLicked ? classes.formControlNoError : classes.formControlError}`}>
                <RoundInputLabel className={classes.selectLabel}>MLS</RoundInputLabel>
                <RoundSelect
                  disabled={noState}
                  onChange={(e) => {
                    handleMLSChange(e.target.value);
                  }}
                  value={MLS}
                  disableUnderline
                  className={classes.select}
                  labelId="demo-simple-select-label"
                >
                  {MLSData?.mlses.map((MLS, index) => (
                    <MenuItem key={index} value={MLS.mlsID}>
                      {MLS.name}
                    </MenuItem>
                  ))}
                </RoundSelect>
              </FormControl>
              {noState && MLSCLicked ? <div className={classes.MLSErrorDiv}>Please Select a State</div> : null}

              <ReportContactInput
                inputProps={{
                  style: {
                    padding: "22px 26px 9px",
                  },
                }}
                value={agentIDValue}
                variant="filled"
                label="AGENT ID(s)"
                onFocus={() => {
                  setTopComma(true);
                }}
                onBlur={() => {
                  setTopComma(false);
                }}
                onChange={(e) => {
                  handleAgentIdChange(e.target.value);
                }}
              />
              <ReportContactInput
                inputProps={{
                  style: {
                    padding: "22px 26px 9px",
                  },
                }}
                variant="filled"
                label="OFFICE ID(s)"
                value={officeIDValue}
                onFocus={() => {
                  setBottomComma(true);
                }}
                onBlur={() => {
                  setBottomComma(false);
                }}
                onChange={(e) => {
                  handleOfficeIdChange(e.target.value);
                }}
              />
              <div className={classes.btnDiv}>
                <CloseButton
                  disabled={createLoading}
                  onClick={() => {
                    handleCreateMls();
                  }}
                >
                  <AddIcon /> Add MLS
                </CloseButton>
                {creationError && (MLS === "" || state === "") ? <div className={classes.createError}>Please Select a State and MLS</div> : null}
              </div>
            </div>
          </Grid>
          <Grid className={classes.topGridRight} item xs={12} sm={12} md={6}>
            <div className={classes.gridPadding}>
              <div className={classes.topTitleMLS}>MLS INFORMATION</div>
              <div className={classes.mlsMap}>
                {!MLSLinkLoading ? (
                  MLSLinkData?.user.mlsLinks.map((MLS, index) => (
                    <div key={index}>
                      <div className={classes.mlsInfo}>
                        <div className={MLS.mlsOfficeId[0] === "" ? classes.showMLS : classes.showMLsNoid}>
                          {MLS.state.abbr} / {MLS.mlsName}
                        </div>
                        <div className={classes.delete}>
                          <Button
                            disabled={MLSLinkLoading}
                            onClick={() => {
                              setDeleteThisMLS(MLS.id);
                              setReportUp(true);
                            }}
                            className={classes.deleteButton}
                          >
                            <DeleteIcon />
                          </Button>
                        </div>
                      </div>
                      {MLS.mlsOfficeId[0] === "" ? null : (
                        <div className={classes.overOfficeId}>
                          {MLS.mlsOfficeId.map((id, index) => {
                            return (
                              <div key={index} className={index === 0 ? `${classes.officeId} ${classes.firstOfficeId}` : classes.officeId}>
                                OFFICE#: {id}
                              </div>
                            );
                          })}
                          {MLS.mlsAgentId.map((id, index) => {
                            return (
                              <div key={index} className={index === 0 ? `${classes.officeId} ${classes.firstOfficeId}` : classes.officeId}>
                                AGENT#: {id}
                              </div>
                            );
                          })}
                        </div>
                      )}

                      <hr className={classes.hr} />
                    </div>
                  ))
                ) : (
                  <div>
                    <Skeleton className={classes.Skeleton}></Skeleton>
                    <hr className={classes.hr} />
                    <Skeleton className={classes.Skeleton}></Skeleton>
                    <hr className={classes.hr} />
                    <Skeleton className={classes.Skeleton}></Skeleton>
                    <hr className={classes.hr} />
                    <Skeleton className={classes.Skeleton}></Skeleton>
                    <hr className={classes.hr} />
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <DeletePopUp reportUp={reportUp} setReportUp={setReportUp} userId={globalState?.user.userId} deleteThisMLS={deleteThisMLS} setPullDelete={setPullDelete} />
      </div>
    </Fade>
  );
};

UserSettingsMlsTab.propTypes = {
  selectedTab: PropTypes.number,
};

export default UserSettingsMlsTab;
