const isDev = process.env.NODE_ENV === "development";
const isTest = process.env.NODE_ENV === "test";
const isProd = process.env.NODE_ENV === "production";

export const getBasePath = () => {
  return process.env.REACT_APP_API_URL;
};

export const getGoogleClientId = () => {
  return process.env.REACT_APP_GOOGLE_CLIENT_ID;
};

export const getFacebookClientId = () => {
  return process.env.REACT_APP_FACEBOOK_CLIENT_ID;
};

export const getGoogleApiKey = () => {
  return process.env.REACT_APP_GOOGLE_API_KEY;
};

export const getStripeSecretKey = () => {
  return process.env.STRIPE_SECRET_KEY;
};

export const getStripePublishableKey = () => {
  return process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
};

export const getElasticActive = () => {
  return process.env.REACT_APP_ELASTIC_APM_ENABLED;
};

export const getElasticServerUrl = () => {
  return process.env.REACT_APP_ELASTIC_RUM_SERVER_URL;
};

export const getElasticTransactionSampleRate = () => {
  return process.env.REACT_APP_ELASTIC_APM_TRANSACTION_SAMPLE_RATE;
};

export const getCrmUrl = () => {
  return process.env.REACT_APP_CRM_URL;
}

export const getDummyReportId = () => {
  if (isDev) {
    return 41041;
  } else if (isTest) {
    return 41041;
  } else if (isProd) {
    return 66250;
  }
};
