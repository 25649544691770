import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core";
import {
  HomeOutlined,
  LocationOnOutlined,
  LocationCityOutlined,
  ApartmentOutlined,
  TerrainOutlined,
  NearMeOutlined,
  ExploreOutlined,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    autoCompletContainer: {
      position: "absolute",
      top: "60px",
      left: "0px",
      background: "#fff",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px",
      padding: "5px 15px",
      zIndex: "2",
      minWidth: "300px",
    },
    categoryContainer: {
      width: "100%",
      marginBottom: "10px",
    },
    categoryLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "16px",
      color: "#333",
      margin: "0px",
    },
    suggestionContainer: {
      cursor: "pointer",
      padding: "5px 0px 5px 35px",
      background: "#fff",
      transition: "0.2s",
      display: "flex",
      flexWrap: "nowrap",
      "&:hover": {
        "& $suggestionText": {
          color: theme.palette.areaPulseBlue,
          transition: "0.2s",
        },
      },
    },
    suggestionText: {
      fontFamily: theme.typography.secondary,
      fontSize: "14px",
      color: "#333",
      fontWeight: "normal",
      margin: "0px",
    },
    categoryHeaderContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    categoryIcon: {
      fontSize: "24px",
      color: "#333",
      marginRight: "10px",
    },
  })
);

const AutoCompletOptions = ({ elasticData, placesData, handleSelect, inputValue }) => {
  const classes = useStyles();

  const formatResults = () => {
    let formatedArray = [];
    const elasticAddresses = elasticData.filter((item) => item.category === "Address");
    const elasticCities = elasticData.filter((item) => item.category === "City");
    const elasticCounties = elasticData.filter((item) => item.category === "County");
    const elasticNeighborhoods = elasticData.filter((item) => item.category === "Neighborhood");
    const elasticZipCodes = elasticData.filter((item) => item.category === "Zip Code");

    if (elasticCities.length > 0) formatedArray.push({ cat: "City", items: [...elasticCities] });
    if (elasticNeighborhoods.length > 0) formatedArray.push({ cat: "Neighborhood", items: [...elasticNeighborhoods] });
    if (elasticCounties.length > 0) formatedArray.push({ cat: "County", items: [...elasticCounties] });
    if (elasticZipCodes.length > 0) formatedArray.push({ cat: "Zip Code", items: [...elasticZipCodes] });
    if (elasticAddresses.length > 0) formatedArray.push({ cat: "Address", items: [...elasticAddresses] });
    if (placesData.length > 0) formatedArray.push({ cat: "Google Address", items: [...placesData] });

    return formatedArray;
  };

  const getIcon = (category) => {
    switch (category) {
      case "Google Address":
        return <LocationOnOutlined fontSize="inherit" color="inherit" className={classes.categoryIcon} />;
      case "Address":
        return <HomeOutlined fontSize="inherit" color="inherit" className={classes.categoryIcon} />;
      case "City":
        return <LocationCityOutlined fontSize="inherit" color="inherit" className={classes.categoryIcon} />;
      case "Neighborhood":
        return <ApartmentOutlined fontSize="inherit" color="inherit" className={classes.categoryIcon} />;
      case "County":
        return <TerrainOutlined fontSize="inherit" color="inherit" className={classes.categoryIcon} />;
      case "Zip Code":
        return <NearMeOutlined fontSize="inherit" color="inherit" className={classes.categoryIcon} />;
      default:
        return <ExploreOutlined fontSize="inherit" color="inherit" className={classes.categoryIcon} />;
    }
  };
  if (inputValue.length === 0 || placesData.length === 0) return null;

  return (
    <div className={classes.autoCompletContainer}>
      {formatResults().map((category, i) => {
        return (
          <div key={i} className={classes.categoryContainer}>
            <div className={classes.categoryHeaderContainer}>
              {getIcon(category.cat)}
              <h1 className={classes.categoryLabel}>{category.cat}</h1>
            </div>
            {category.items.map((suggestion, i) => {
              if (category.cat !== "Google Address") {
                const labelIsAddress = suggestion.category === "Address";
                const isArea = suggestion.category === "Area";
                const isSchoolDistrict = suggestion.category === "School District";
                const isHighSchool = suggestion.category === "High School";
                const isMiddleSchool = suggestion.category === "Middle School";
                const isElementarySchool = suggestion.category === "Elementary School";
                if (i > 20 || isArea || isSchoolDistrict || isHighSchool || isMiddleSchool || isElementarySchool)
                  return null;
                return (
                  <div
                    key={i}
                    onClick={handleSelect(
                      { cat: suggestion.category, value: suggestion.value },
                      labelIsAddress ? true : false
                    )}
                    className={classes.suggestionContainer}
                  >
                    <h1 className={classes.suggestionText}>{suggestion.value}</h1>
                  </div>
                );
              } else {
                const { description } = suggestion;

                if (i > 20) return null;
                return (
                  <div
                    key={i}
                    onClick={handleSelect({ cat: "Google Address", value: description }, true)}
                    className={classes.suggestionContainer}
                  >
                    <h1 className={classes.suggestionText}>{description}</h1>
                  </div>
                );
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

AutoCompletOptions.propTypes = {
  elasticData: PropTypes.array,
  placesData: PropTypes.array,
  handleSelect: PropTypes.func,
  inputValue: PropTypes.string,
};

export default AutoCompletOptions;
