import React, { Fragment, useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { authApolloClient } from "../../Services/Graph/apolloConfig";
import { GET_USERS } from "../../Services/Graph/Admin/Queries";
import { makeStyles, createStyles, TextField, withStyles, Button, TableCell, TableHead, TableRow, TableSortLabel, Table, TableBody, Fade, TableContainer, CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { AppDispatchContext, AppStateContext } from "../../Context/AppReducer";
import { withRouter } from "react-router-dom";
import { saveState } from "../../Helpers/localStorage";
import DeleteUserConfirm from "./DeleteUserConfirm";

import useLoginAs from "../../Services/Rest/loginAsHook";

const useStyles = makeStyles((theme) =>
  createStyles({
    dashboardContainer: {
      width: "100%",
      height: "100%",
      marginTop: "36px",
    },
    statsMargin: {
      margin: "60px 0px 55px 0px",
    },
    searchParent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: "82px",
      width: "100%",
      backgroundColor: "#FFFFFF",
      marginBottom: "29px",
    },
    resultsBody: {
      backgroundColor: "#FFFFFF",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    nameBox: {
      marginLeft: "37px",
    },
    resultsTitleParent: {
      display: "flex",
      justifyContent: "space-between",
      padding: "52px 70px 20px 126px",
    },
    titles: {
      fontSize: "24px",
      fontWeight: "600",
      fontFamily: "nunito",
    },
    resultBox: {
      justifyContent: "space-between",
      display: "flex",
    },
    line: {
      border: "solid 0.5px #C5C5C5",
      width: "100%",
    },
    tableContainer: {
      backgroundColor: "#ffffff",
      maxHeight: "66vh",
    },
    columnHeader: {
      backgroundColor: "#ffffff",
      fontSize: "24px",
      fontWeight: "600",
      fontFamily: "nunito",
    },
    columnData: {
      border: "0px",
      fontSize: "18px",
      fontWeight: "600",
      fontFamily: "nunito",
      padding: "6px 16px 6px",
    },
    deleteSVG: {
      marginRight: "18px",
      cursor: "pointer",
    },
    firstTitle: {
      padding: "52px 16px 16px 126px !important",
    },
    firstTitleTable: {
      padding: "6px 16px 6px 126px !important",
      position: "relative",
    },
    noBorder: {
      padding: "52px 16px 16px",
      borderBottom: "none",
    },
    border: {
      height: "1px",
      backgroundColor: "#C5C5C5",
      width: "calc(100% - 60px)",
      position: "relative",
      top: "96px",
      margin: "0px 30px",
    },
    circleParent: {
      height: "80px",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
    circle: {
      color: "#0079B4",
    },
    fetchCircle: {
      color: "#0079B4",
      height: "28px !important",
      width: "28px !important",
    },
    naBox: {
      display: "flex",
    },
    checkSVG: {
      marginRight: "7px",
    },
    noResults: {
      display: "flex",
      height: "200px",
      justifyContent: "center",
      alignItems: "center",
    },
    noResultsChild: {
      fontWeight: "500",
      fontSize: "22px",
      fontFamily: "nunito",
    },
    loginSvg: {
      cursor: "pointer",
    },
    coloredSvg: {
      position: "absolute",
      left: "59px",
      top: "13px",
    },
    coloredSvg1: {
      position: "absolute",
      left: "59px",
      top: "39px",
    },
    topRowFirst: {
      padding: "30px 16px 6px 126px !important",
    },
    topRow: {
      padding: "30px 16px 6px",
    },
  })
);
const ReportContactInput = withStyles({
  root: {
    marginRight: "17px",
    width: "310px",
    height: "44px",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    "& label": {
      marginTop: "-6px",
    },
    "& label.Mui-focused": {
      color: "#777777",
      marginTop: "0px",
    },
    "& .MuiOutlinedInput-root": {
      height: "44px",
      "&.MuiOutlinedInput-underline:before": {
        display: "none",
      },
      "&.MuiOutlinedInput-underline:after": {
        display: "none",
      },
      "@media (max-width: 1500px)": {},
    },
  },
})(TextField);
const GoButton = withStyles((theme) => ({
  root: {
    fontFamily: "Nunito",
    textTransform: "none",
    fontSize: "22px",
    marginRight: "37px",
    minWidth: "131px",
    display: "flex",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0079B4",
    height: "44px",
    width: "131px",
    borderRadius: "5px",
    "&:hover": {
      background: "#0079B4",
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const AdminBody = ({ history, user, setCreateReportOpen, setSavedReport, savedReport }) => {
  const tableBodyRef = useRef(null);
  const [order, setOrder] = useState(undefined);
  const [deleteUp, setDeleteUp] = useState(false);
  const [deleteUser, setDeleteUser] = useState({});
  const [userDeleted, setUserDeleted] = useState("");
  const [orderBy, setOrderBy] = useState(undefined);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [offset, setOffset] = useState(0);
  const [users, setUsers] = useState([]);
  const [userAs, loginUser] = useLoginAs();

  const globalDispatch = useContext(AppDispatchContext);
  const globalState = useContext(AppStateContext);

  const tableRef = useRef(null);
  const first = 15;

  const [getUsers, { data: usersData, loading: usersLoading, error: usersError }] = useLazyQuery(GET_USERS, {
    variables: {
      first: 15,
      offset: offset,
      name: name,
      email: email,
      username: username,
      sortField: orderBy,
      sortDirection: order,
    },
    onCompleted(data) {
      setUsers([...users, ...(data?.users?.users ?? [])]);
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  const classes = useStyles();
  useEffect(() => {
    if (userAs.isSuccessful) {
      saveState("areaPulseUserToken", userAs.result.user.token);
      globalDispatch({ type: "loginAsUser", payload: globalState?.user });

      globalDispatch({ type: "login", payload: { ...userAs.result.user, mlsLinks: userAs.result.mlsLinks } });
      history.push(`/${userAs.result.user.username}/dashboard`);
    }
  }, [userAs]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (userDeleted) {
      setUserDeleted(false);
      setOffset(0);
      setUsers([]);
      getUsers();
    }
  }, [userDeleted]);
  const headCells = [
    { id: "NAME", label: "NAME" },
    { id: "username", label: "USERNAME" },
    { id: "email", label: "EMAIL" },
    { id: "ROLES", label: "ROLES" },
    { id: "SUBSCRIPTIONS", label: "SUBSCRIPTIONS" },
    { id: "ACTIONS", label: "ACTIONS" },
  ];
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleScroll = (e) => {
    const tableScrollHeight = tableRef.current.scrollHeight;
    const tableScrollTop = tableRef.current.scrollTop;
    const tableClientHeight = tableRef.current.clientHeight;

    if (tableScrollHeight === tableScrollTop + tableClientHeight && users.length >= (offset === 0 ? first : offset)) {
      setOffset(offset + 10);
    }
  };

  const loginAs = (userId) => {
    loginUser(userId);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setOffset(0);
    setUsers([]);
  };
  const { handleSubmit, register } = useForm();

  const submit = (values) => {
    setUsers([]);
    setOffset(0);
    setName(values.name);
    setUsername(values.username);
    setEmail(values.email);
    getUsers();
  };
  const logInColor = (lastLogin) => {
    var arr = lastLogin.split(/\D/);
    var todaysDate = new Date();
    var date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    let color = "";

    if (date.setHours(0, 0, 0, 0) >= todaysDate.setHours(0, 0, 0, 0) - 2592000000) {
      color = "#94D62B";
    } else if (date.setHours(0, 0, 0, 0) >= todaysDate.setHours(0, 0, 0, 0) - 5184000000) {
      color = "#6BCDD8";
    } else if (date.setHours(0, 0, 0, 0) >= todaysDate.setHours(0, 0, 0, 0) - 7776000000) {
      color = "#F9C952";
    } else {
      color = "#FF865C";
    }

    return color;
  };
  return (
    <div className={classes.dashboardContainer}>
      <form id="search-user-form" onSubmit={handleSubmit((data) => submit(data))} className={classes.searchParent}>
        <ReportContactInput
          inputProps={{
            name: "name",
          }}
          className={classes.nameBox}
          label="Name"
          variant="outlined"
          inputRef={register()}
        />
        <ReportContactInput
          inputProps={{
            name: "username",
          }}
          label="Username"
          variant="outlined"
          inputRef={register()}
        />
        <ReportContactInput
          inputProps={{
            name: "email",
          }}
          label="Email"
          variant="outlined"
          inputRef={register()}
        />
        <GoButton type="submit" form="search-user-form">
          Search
        </GoButton>
        {userAs.isFetching ? <CircularProgress className={classes.fetchCircle} /> : null}
      </form>
      <div className={classes.resultsBody}>
        <div className={classes.border}></div>
        <TableContainer className={classes.tableContainer} ref={tableRef} onScroll={handleScroll}>
          <Table stickyHeader className={classes.table} ref={tableBodyRef}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell, i) => {
                  const labelIsLastReportSent = headCell.label === "ROLES";
                  const labelIsReportsSent = headCell.label === "SUBSCRIPTIONS";
                  const labelIsReportsName = headCell.label === "NAME";
                  const labelIsReportsOpened = headCell.label === "ACTIONS";
                  return (
                    <TableCell
                      key={i}
                      className={` ${classes.noBorder} ${i === 0 ? classes.firstTitle : null}  ${classes.columnHeader} ${(labelIsReportsSent || labelIsReportsOpened) && classes.columnHeaderCenter}`}
                    >
                      {!labelIsLastReportSent && !labelIsReportsSent && !labelIsReportsOpened && !labelIsReportsName ? (
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        <Fragment>{headCell.label}</Fragment>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                ? users.map((row, i) => {
                    return (
                      <Fade key={i} in={true} timeout={500}>
                        <TableRow key={i} className={classes.hoverRow} hover>
                          <TableCell className={`${classes.firstTitleTable} ${classes.nameCell} ${classes.columnData} ${i === 0 ? classes.topRowFirst : null}`}>
                            <svg className={i === 0 ? classes.coloredSvg1 : classes.coloredSvg} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="16" height="16" rx="8" fill={logInColor(row.lastLogin)} />
                            </svg>
                            <div>
                              {row.firstName} {row.lastName}
                            </div>
                          </TableCell>
                          <TableCell className={`${classes.columnData} ${i === 0 ? classes.topRow : null}`}>{row.username}</TableCell>
                          <TableCell className={`${classes.columnData} ${i === 0 ? classes.topRow : null}`}>{row.email}</TableCell>
                          <TableCell className={`${classes.columnData} ${i === 0 ? classes.topRow : null} ${classes.columnDataCenter}`}>{row?.roles[0]?.role?.name}</TableCell>
                          <TableCell className={`${classes.columnData} ${i === 0 ? classes.topRow : null} ${classes.columnDataCenter}`}>
                            {row?.subscriptions[0]?.name ? (
                              row?.subscriptions[0]?.name
                            ) : (
                              <div className={classes.naBox}>
                                <svg className={classes.checkSVG} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M20.7 7.81038L9.7 18.9681C9.5 19.1709 9.3 19.2724 9 19.2724C8.7 19.2724 8.5 19.1709 8.3 18.9681L3.3 13.8964C2.9 13.4907 2.9 12.8821 3.3 12.4763C3.7 12.0706 4.3 12.0706 4.7 12.4763L9 16.838L19.3 6.39031C19.7 5.98458 20.3 5.98458 20.7 6.39031C21.1 6.79605 21.1 7.40465 20.7 7.81038Z"
                                    fill="#3B8A02"
                                  />
                                  <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="6" width="18" height="14">
                                    <path
                                      d="M20.7 7.81038L9.7 18.9681C9.5 19.1709 9.3 19.2724 9 19.2724C8.7 19.2724 8.5 19.1709 8.3 18.9681L3.3 13.8964C2.9 13.4907 2.9 12.8821 3.3 12.4763C3.7 12.0706 4.3 12.0706 4.7 12.4763L9 16.838L19.3 6.39031C19.7 5.98458 20.3 5.98458 20.7 6.39031C21.1 6.79605 21.1 7.40465 20.7 7.81038Z"
                                      fill="white"
                                    />
                                  </mask>
                                </svg>
                                "n/a"
                              </div>
                            )}
                          </TableCell>
                          <TableCell className={`${classes.columnData} ${i === 0 ? classes.topRow : null} ${classes.columnDataCenter}`}>
                            <svg
                              onClick={() => {
                                setDeleteUser(row);
                                setDeleteUp(true);
                              }}
                              className={classes.deleteSVG}
                              width="24"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M21 5.07169H17V4.05735C17 2.33298 15.7 1.01434 14 1.01434H10C8.3 1.01434 7 2.33298 7 4.05735V5.07169H3C2.4 5.07169 2 5.47743 2 6.08603C2 6.69463 2.4 7.10036 3 7.10036H4V20.2867C4 22.0111 5.3 23.3298 7 23.3298H17C18.7 23.3298 20 22.0111 20 20.2867V7.10036H21C21.6 7.10036 22 6.69463 22 6.08603C22 5.47743 21.6 5.07169 21 5.07169ZM9 4.05735C9 3.44875 9.4 3.04302 10 3.04302H14C14.6 3.04302 15 3.44875 15 4.05735V5.07169H9V4.05735ZM17 21.3011C17.6 21.3011 18 20.8953 18 20.2867V7.10036H6V20.2867C6 20.8953 6.4 21.3011 7 21.3011H17ZM11 11.1577V17.2437C11 17.8523 10.6 18.2581 10 18.2581C9.4 18.2581 9 17.8523 9 17.2437V11.1577C9 10.5491 9.4 10.1434 10 10.1434C10.6 10.1434 11 10.5491 11 11.1577ZM15 17.2437V11.1577C15 10.5491 14.6 10.1434 14 10.1434C13.4 10.1434 13 10.5491 13 11.1577V17.2437C13 17.8523 13.4 18.2581 14 18.2581C14.6 18.2581 15 17.8523 15 17.2437Z"
                                fill="black"
                              />
                              <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="1" width="20" height="23">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M21 5.07169H17V4.05735C17 2.33298 15.7 1.01434 14 1.01434H10C8.3 1.01434 7 2.33298 7 4.05735V5.07169H3C2.4 5.07169 2 5.47743 2 6.08603C2 6.69463 2.4 7.10036 3 7.10036H4V20.2867C4 22.0111 5.3 23.3298 7 23.3298H17C18.7 23.3298 20 22.0111 20 20.2867V7.10036H21C21.6 7.10036 22 6.69463 22 6.08603C22 5.47743 21.6 5.07169 21 5.07169ZM9 4.05735C9 3.44875 9.4 3.04302 10 3.04302H14C14.6 3.04302 15 3.44875 15 4.05735V5.07169H9V4.05735ZM17 21.3011C17.6 21.3011 18 20.8953 18 20.2867V7.10036H6V20.2867C6 20.8953 6.4 21.3011 7 21.3011H17ZM11 11.1577V17.2437C11 17.8523 10.6 18.2581 10 18.2581C9.4 18.2581 9 17.8523 9 17.2437V11.1577C9 10.5491 9.4 10.1434 10 10.1434C10.6 10.1434 11 10.5491 11 11.1577ZM15 17.2437V11.1577C15 10.5491 14.6 10.1434 14 10.1434C13.4 10.1434 13 10.5491 13 11.1577V17.2437C13 17.8523 13.4 18.2581 14 18.2581C14.6 18.2581 15 17.8523 15 17.2437Z"
                                  fill="white"
                                />
                              </mask>
                            </svg>

                            <svg
                              className={classes.loginSvg}
                              onClick={() => {
                                if (!userAs.isFetching) {
                                  let object = {
                                    userId: row.id,
                                  };
                                  loginAs(object);
                                }
                              }}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M19 23C20.7 23 22 21.7 22 20V4C22 2.3 20.7 1 19 1H14C13.4 1 13 1.4 13 2C13 2.6 13.4 3 14 3H19C19.6 3 20 3.4 20 4V20C20 20.6 19.6 21 19 21H14C13.4 21 13 21.4 13 22C13 22.6 13.4 23 14 23H19ZM15.9 11.6C16 11.9 16 12.2 15.9 12.4C15.9 12.5 15.8 12.6 15.7 12.7L11.7 16.7C11.5 16.9 11.2 17 11 17C10.8 17 10.5 16.9 10.3 16.7C9.89999 16.3 9.89999 15.7 10.3 15.3L12.6 13H3C2.39999 13 2 12.6 2 12C2 11.4 2.39999 11 3 11H12.6L10.3 8.7C9.89999 8.3 9.89999 7.7 10.3 7.3C10.7 6.9 11.3 6.9 11.7 7.3L15.7 11.3C15.75 11.35 15.775 11.4 15.8 11.45C15.825 11.5 15.85 11.55 15.9 11.6Z"
                                fill="black"
                              />
                              <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="1" width="20" height="22">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M19 23C20.7 23 22 21.7 22 20V4C22 2.3 20.7 1 19 1H14C13.4 1 13 1.4 13 2C13 2.6 13.4 3 14 3H19C19.6 3 20 3.4 20 4V20C20 20.6 19.6 21 19 21H14C13.4 21 13 21.4 13 22C13 22.6 13.4 23 14 23H19ZM15.9 11.6C16 11.9 16 12.2 15.9 12.4C15.9 12.5 15.8 12.6 15.7 12.7L11.7 16.7C11.5 16.9 11.2 17 11 17C10.8 17 10.5 16.9 10.3 16.7C9.89999 16.3 9.89999 15.7 10.3 15.3L12.6 13H3C2.39999 13 2 12.6 2 12C2 11.4 2.39999 11 3 11H12.6L10.3 8.7C9.89999 8.3 9.89999 7.7 10.3 7.3C10.7 6.9 11.3 6.9 11.7 7.3L15.7 11.3C15.75 11.35 15.775 11.4 15.8 11.45C15.825 11.5 15.85 11.55 15.9 11.6Z"
                                  fill="white"
                                />
                              </mask>
                              <g mask="url(#mask0)"></g>
                            </svg>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    );
                  })
                : null}
            </TableBody>
          </Table>
          {usersData && users.length === 0 && !usersLoading ? (
            <div className={classes.noResults}>
              <div className={classes.noResultsChild}>There were no results.</div>
            </div>
          ) : null}
          {users && usersLoading ? (
            <div className={classes.circleParent}>
              <CircularProgress></CircularProgress>
            </div>
          ) : null}
        </TableContainer>
      </div>
      <DeleteUserConfirm setDeleteUp={setDeleteUp} deleteThisUser={deleteUser} deleteUp={deleteUp} setUserDeleted={setUserDeleted} />
    </div>
  );
};

AdminBody.propTypes = {
  user: PropTypes.string,
  setCreateReportOpen: PropTypes.func,
  setSavedReport: PropTypes.func,
  savedReport: PropTypes.bool,
};

export default withRouter(AdminBody);
