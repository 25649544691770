import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { createStyles, makeStyles, Backdrop, withStyles, Button } from "@material-ui/core";
import { AppDispatchContext, AppStateContext } from "../../Context/AppReducer";

const useStyles = makeStyles((theme) =>
  createStyles({
    backdropContainer: {
      height: "100vh",
      width: "100%",
      zIndex: "15",
      padding: "35px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    backdropContentContainer: {
      background: "#fff",
      borderRadius: "20px",
      padding: "30px",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    expiredText: {
      fontFamily: "Nunito",
      fontWeight: "700",
      fontSize: "24px",
      color: "#000",
      textTransform: "uppercase",
      letterSpacing: "0.195em",
    },
    expiredSubText: {
      fontFamily: "Nunito",
      fontWeight: "600",
      fontSize: "18px",
      color: "#000",
      margin: "0px 0px 20px 0px",
      textAlign: "center",
    },
  })
);

const CloseButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    background: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 30px",
    borderRadius: "7px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const SessionExpiredModal = () => {
  const classes = useStyles();
  const globalState = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);
  const history = useHistory();

  const handleClose = () => {
    globalDispatch({ type: "logout", history });
  };

  const backdropAnimations = {
    appear: 500,
    enter: 500,
    exit: 500,
  };

  return (
    <Backdrop open={globalState.sessionExpiredModal} transitionDuration={backdropAnimations} className={classes.backdropContainer}>
      <div className={classes.backdropContentContainer}>
        <h1 className={classes.expiredText}>Your Session Has Expired!</h1>
        <h1 className={classes.expiredSubText}>Please login again to continue viewing and sending market reports</h1>
        <CloseButton onClick={() => handleClose()}>Login</CloseButton>
      </div>
    </Backdrop>
  );
};

export default SessionExpiredModal;
