import React from "react";
import { makeStyles, createStyles, Button, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    parentDiv: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    textField: {
      marginTop: "7px",
    },
    copy: {
      fontSize: "16px",
      fontFamily: "nunito",
      fontWeight: "600",
    },
    textParent: {
      display: "flex",
      justifyContent: "flex-start",
    },
    buttonDiv: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
    },
    copied: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "black",
      height: "45px",
      borderRadius: "10px",
      width: "90px",
      color: "white",
      marginTop: "10px",
      fontFamily: "nunito",
      fontWeight: "700",
    },
    notCopied: {
      display: "none",
    },
    copyTitle: {
      textAlign: "center",
      maxWidth: "720px",
      padding: "30px 120px 23px",
      display: "flex",
      fontFamily: "nunito",
      fontWeight: " 700",
      fontSize: "24px",
    },
    buttonPad: {
      marginBottom: "55px !important",
    },
  })
);

const CannotShare = ({ setShareOpen }) => {
  const classes = useStyles();

  const CloseButton = withStyles((theme) => ({
    root: {
      color: "#fff",
      background: theme.palette.areaPulseBlue,
      fontSize: "16px",
      fontFamily: "nunito",
      fontWeight: "700",
      padding: "11px 72px",
      borderRadius: "7px",
      transition: "0.2s",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
        transition: "0.2s",
        opacity: "0.8",
      },
    },
  }))(Button);

  return (
    <div className={classes.parentDiv}>
      <div className={classes.copyTitle}>You have reached your plans monthly max of sent reports.</div>
      <div className={classes.textParent}>
        <div className={classes.copy}>Please upgrade your plan to send more reports.</div>
      </div>
      <div className={classes.buttonDiv}>
        <CloseButton
          onClick={() => {
            setShareOpen(false);
          }}
        >
          Close
        </CloseButton>
      </div>
    </div>
  );
};

CannotShare.propTypes = {
  setShareOpen: PropTypes.func,
};

export default CannotShare;
