import { gql } from "@apollo/client";

export const GET_ELASTIC_SEARCH_DATA = gql`
  query Suggestions($term: String!, $mlsIDs: [Int]) {
    suggestions(term: $term, mlsIDs: $mlsIDs) {
      category
      label
      value
    }
  }
`;

export const GET_ELASTIC_SEARCH_DATA_OPEN = gql`
  query Suggestions($term: String!, $mlsIDs: [Int]) {
    suggestions(term: $term, mlsIDs: $mlsIDs) {
      category
      label
      value
    }
  }
`;

export const GET_PROPERTY_TYPES = gql`
  query GetPropertyTypes($fields: [String], $mlsIDs: [Int]) {
    mlsValues(fields: $fields, mlsIDs: $mlsIDs) {
      values
      fieldName
    }
  }
`;

export const GET_PROPERTY_TYPES_OPEN = gql`
  query mlsValues($fields: [String], $mlsIDs: [Int]) {
    mlsValues(fields: $fields, mlsIDs: $mlsIDs) {
      values
      fieldName
    }
  }
`;

export const GET_MLS_LINKS = gql`
  query GetUser($id: Int!) {
    user(id: $id) {
      mlsLinks {
        mlsId
        state {
          name
          abbr
          id
        }
      }
    }
  }
`;

export const GET_MLS_LINKS_OPEN = gql`
  query getUser($username: String!) {
    user(username: $username) {
      mlsLinks {
        mlsId
        state {
          name
          abbr
          id
        }
      }
    }
  }
`;

export const GET_USER_AMOUNT = gql`
  query users($id: Int!) {
    user(id: $id) {
      subscriptions {
        name
        itemId
      }
      totalSubscriptionUsage {
        reportsRemaining
        reportsUsed
      }
    }
  }
`;

export const GET_POLYGON = gql`
  query GetPolygon($state: String!, $zip: String, $city: String, $county: String) {
    polygon(state: $state, zip: $zip, city: $city, county: $county) {
      coords
    }
  }
`;

export const GET_POLYGON_OPEN = gql`
  query GetPolygon($state: String!, $zip: String, $city: String, $county: String) {
    polygon(state: $state, zip: $zip, city: $city, county: $county) {
      coords
    }
  }
`;

export const PRE_MARKET_REPORT = gql`
  query listingCount(
    $geoJSON: geoJSONType
    $minBaths: Int
    $maxPrice: Int
    $lat: Float
    $state: String
    $propertyType: [String]
    $minBeds: Int
    $minPrice: Int
    $radius: Float
    $address: String
    $city: String
    $neighborhood: String
    $polygon: [[Float]]
    $lng: Float
    $mlsId: [Int]
    $zip: String
    $maxBeds: Int
    $maxBaths: Int
  ) {
    listingCount(
      geoJSON: $geoJSON
      minBaths: $minBaths
      maxPrice: $maxPrice
      lat: $lat
      state: $state
      propertyType: $propertyType
      minBeds: $minBeds
      minPrice: $minPrice
      radius: $radius
      address: $address
      city: $city
      neighborhood: $neighborhood
      polygon: $polygon
      lng: $lng
      mlsId: $mlsId
      zip: $zip
      maxBeds: $maxBeds
      maxBaths: $maxBaths
    )
  }
`;

export const PRE_MARKET_REPORT_OPEN = gql`
  query listingCount(
    $geoJSON: geoJSONType
    $minBaths: Int
    $maxPrice: Int
    $lat: Float
    $state: String
    $propertyType: [String]
    $minBeds: Int
    $minPrice: Int
    $radius: Float
    $address: String
    $city: String
    $neighborhood: String
    $polygon: [[Float]]
    $lng: Float
    $mlsId: [Int]
    $zip: String
    $maxBeds: Int
    $maxBaths: Int
  ) {
    listingCount(
      geoJSON: $geoJSON
      minBaths: $minBaths
      maxPrice: $maxPrice
      lat: $lat
      state: $state
      propertyType: $propertyType
      minBeds: $minBeds
      minPrice: $minPrice
      radius: $radius
      address: $address
      city: $city
      neighborhood: $neighborhood
      polygon: $polygon
      lng: $lng
      mlsId: $mlsId
      zip: $zip
      maxBeds: $maxBeds
      maxBaths: $maxBaths
    )
  }
`;

export const GET_SINGLE_REPORT = gql`
  query GetSingleReport($id: Int!) {
    report(id: $id) {
      id
      title
      headline
      parameters {
        geoJSON {
          coordinates
          type
        }
        bathsMax
        bathsMin
        bedsMax
        bedsMin
        city
        lat
        lng
        address
        mlsIDs
        polygon
        priceMax
        priceMin
        propertyType
        radius
        state
        zipCode
      }
    }
  }
`;
