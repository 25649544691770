import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, withStyles, Button, CircularProgress, Select, InputLabel, FormControl, MenuItem } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { useMutation } from "@apollo/client";
import { authApolloClient, openApolloClient } from "../../Services/Graph/apolloConfig";
import { EDIT_MARKET_REPORT, DELETE_MARKET_REPORT, SEND_REPORT, SEND_REPORT_OPEN, UPDATE_CONTACT_SUBSCRIPTION } from "../../Services/Graph/CreateReport/Mutations";
import SaveTitleInput from "../Common/Inputs/SaveTitleInput";
import { CREATE_NOTE, CREATE_NOTE_OPEN } from "../../Services/Graph/Contact/Mutations";
import { AppStateContext, AppDispatchContext } from "../../Context/AppReducer";

const useStyles = makeStyles((theme) =>
  createStyles({
    saveTitleContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
    saveTitleHeaderText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      color: "#000",
      margin: "0px 0px 20px 0px",
    },
    saveButtonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "20px",
    },
    errorContainer: {
      background: `${theme.palette.areaPulseError}30`,
      border: `1px solid ${theme.palette.areaPulseError}`,
      borderRadius: "10px",
      padding: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "20px",
    },
    errorText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "14px",
      color: "#333",
      margin: "0px",
    },
    divider: {
      borderBottom: "0.5px solid #DEDEDE",
      width: "100%",
      opacity: "0.8",
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: "#fff",
      marginLeft: "3px",
    },
    done: {
      marginLeft: "3px",
      fontSize: "15px",
      color: "#fff",
    },
    roundSelect: {
      width: "350px",
    },
    selectControl: {
      height: "55px",
      backgroundColor: "#FAFAFA",
      borderRadius: "10px",
      boxShadow: "0px 1px 4px rgb(0 0 0 / 20%)",
      marginBottom: "20px",
    },
    selectLabel: {
      color: "#BDBDBD",
      fontFamily: "nunito",
      fontWeight: "700",
    },
    buttonDisabled: {
      background: "#BDBDBD",
      opacity: "0.5",
      cursor: "not-allowed",
    },
    cancelButtonDisabled: {
      opacity: "0.5",
      cursor: "not-allowed",
    },
  })
);

const SaveButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    background: theme.palette.areaPulseBlue,
    fontSize: "14px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "0px 10px",
    borderRadius: "7px",
    transition: "0.2s",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const RoundSelect = withStyles((theme) => ({
  root: {
    paddingTop: "12px",
    paddingLeft: "12px",
    backgroundColor: "transparent !important",
    "&.MuiFilledInput-underline:before": {
      display: "none",
    },
  },
  icon: {
    marginTop: "-7px",
    marginRight: "22px",
  },
}))(Select);

const RoundInputLabel = withStyles((theme) => ({
  shrink: {
    paddingTop: "10px",
    paddingBottom: "0px",
    transform: "translate(0, 1.5px) scale(0.75) !important",
    marginTop: "0px !important",
  },
  focused: {
    color: `${theme.palette.areaPulseBlue} !important`,
  },
  formControl: {
    paddingLeft: "17px",
    transform: "translate(0, 22px) scale(1)",
    marginTop: "-3px",
  },
}))(InputLabel);

const CancelButton = withStyles((theme) => ({
  root: {
    color: "#C6C6C6",
    fontSize: "14px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "0px 10px",
    borderRadius: "7px",
    transition: "0.2s",
    textTransform: "lowercase",
    marginRight: "5px",
  },
}))(Button);

const SaveReport = ({
  contactFrequency,
  changedTitles,
  setChangedTitles,
  reportSaved,
  setSubscribe,
  contactData,
  title,
  headline,
  id,
  thumbnail,
  user,
  subscribeContactData,
  iFrameLoading,
  editing,
  setReportSaved,
  setReportSubmitted,
  setMarketReportSubmiting,
  setEditedReport,
  landingPageView,
  setGetUserData,
  setContactReportWasMade,
}) => {
  const classes = useStyles();
  const globalState = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);
  const [newTitle, setNewTitle] = useState("");
  const [newHeadline, setNewHeadline] = useState("");
  const [frequency, setFrequency] = useState("biweekly");
  const landingPageContact = globalState?.landingPageContact;

  const [updateTitle, { data, loading, error }] = useMutation(landingPageView ? UPDATE_CONTACT_SUBSCRIPTION : EDIT_MARKET_REPORT, {
    variables: landingPageView
      ? {
          contactID: landingPageContact?.id,
          subscriptionID: subscribeContactData?.subscribeContactToReport?.id,
          frequency: frequency,
          title: newTitle,
          headline: newHeadline,
        }
      : {
          title: newTitle,
          headline: newHeadline,
          id: id,
        },
    client: landingPageView ? openApolloClient : authApolloClient,
  });
  const [deleteReport] = useMutation(DELETE_MARKET_REPORT, {
    variables: {
      id: id,
    },
    client: authApolloClient,
  });

  const [sendReport, { data: sendData, loading: sendLoading, error: sendError }] = useMutation(landingPageView ? SEND_REPORT_OPEN : SEND_REPORT, {
    variables: landingPageView
      ? {
          contactEmail: landingPageContact?.email?.email,
          reportID: id,
          contactFirstName: landingPageContact?.firstName,
          contactLastName: landingPageContact?.lastName,
          contactPhone: landingPageContact?.phone?.number,
          frequency: contactFrequency,
          username: user,
        }
      : {
          contactEmail: contactData?.contact?.email?.email,
          reportID: id,
          contactFirstName: contactData?.contact?.firstName,
          contactLastName: contactData?.contact?.lastName,
          contactPhone: contactData?.contact?.phone?.number,
          frequency: frequency,
        },
    client: landingPageView ? openApolloClient : authApolloClient,
    onCompleted() {
      if (contactData) {
        setContactReportWasMade(true);
      }
    },
  });

  const [addNote, { data: noteData, loading: noteLoading, error: noteError }] = useMutation(landingPageView ? CREATE_NOTE_OPEN : CREATE_NOTE, {
    variables: landingPageView
      ? {
          username: user,
          contactID: landingPageContact?.id,
          note: `Subscribed to ${title}`,
        }
      : {
          id: contactData?.contact?.id,
          note: `Subscribed to ${title}`,
        },
    client: landingPageView ? openApolloClient : authApolloClient,
  });

  useEffect(() => {
    if (sendData && !sendLoading && !sendError && noteData && !noteLoading && !noteError) {
      setSubscribe(true);
    }
  }, [sendData, sendLoading, sendError]);

  useEffect(() => {
    if (data && !loading && !error && changedTitles) {
      setEditedReport(true);
    }
  }, [data, loading, error]);

  useEffect(() => {
    setNewTitle(title);
    setNewHeadline(headline);
  }, [title, headline]);

  useEffect(() => {
    if (landingPageView && id) {
      addNote();
      setReportSaved(true);
      sendReport();
    }
  }, [landingPageView, id]);

  const handleSave = () => {
    if (!iFrameLoading) {
      setReportSaved(true);
      if (setGetUserData) {
        setGetUserData(true);
      }

      if (contactData && !reportSaved && !editing) {
        addNote();
        sendReport();
      }

      updateTitle();
      globalDispatch({ type: "setLandingPageContact", payload: {} });
    }
  };

  const handleCancel = () => {
    if (!iFrameLoading) {
      setReportSubmitted(false);
      setMarketReportSubmiting(false);
      if (!editing || !landingPageView) {
        deleteReport();
      }
    }
  };
  if (landingPageView) {
    return null;
  }
  return (
    <div className={classes.saveTitleContainer}>
      <h1 className={classes.saveTitleHeaderText}>Save This Report</h1>
      <SaveTitleInput
        variant="filled"
        type="text"
        name="title"
        autoComplete="off"
        label="Report Headline"
        value={newHeadline || ""}
        onChange={(e) => {
          setNewHeadline(e.target.value);
          if (!changedTitles) {
            setChangedTitles(true);
          }
        }}
        InputProps={{
          name: "headline",
        }}
      />
      <SaveTitleInput
        variant="filled"
        type="text"
        name="title"
        autoComplete="off"
        label="Report Title"
        value={newTitle || ""}
        onChange={(e) => {
          setNewTitle(e.target.value);
          if (!changedTitles) {
            setChangedTitles(true);
          }
        }}
        InputProps={{
          name: "title",
        }}
      />
      {contactData ? (
        <FormControl className={classes.selectControl}>
          <RoundInputLabel className={classes.selectLabel}>Frequency</RoundInputLabel>
          <RoundSelect
            value={frequency}
            onChange={(e) => {
              setFrequency(e.target.value);
            }}
            label="Frequency"
            className={classes.roundSelect}
            disableUnderline
          >
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="biweekly">Bi-Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </RoundSelect>
        </FormControl>
      ) : null}
      {landingPageView && landingPageContact ? (
        <FormControl className={classes.selectControl}>
          <RoundInputLabel className={classes.selectLabel}>Frequency</RoundInputLabel>
          <RoundSelect
            value={frequency}
            onChange={(e) => {
              setFrequency(e.target.value);
            }}
            label="Frequency"
            className={classes.roundSelect}
            disableUnderline
          >
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="biweekly">Bi-Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </RoundSelect>
        </FormControl>
      ) : null}

      <div className={classes.saveButtonContainer}>
        <CancelButton onClick={() => handleCancel()} className={iFrameLoading ? classes.cancelButtonDisabled : ""}>
          cancel
        </CancelButton>
        <SaveButton disabled={loading} onClick={() => handleSave()} className={iFrameLoading ? classes.buttonDisabled : ""}>
          Save
          {loading && (
            <CircularProgress
              size={15}
              thickness={6}
              color="inherit"
              className={classes.progress}
              classes={{
                circle: classes.circle,
              }}
            />
          )}
          {data && <Done fontSize="inherit" color="inherit" className={classes.done} />}
        </SaveButton>
      </div>
      {error && (
        <div className={classes.errorContainer}>
          <h1 className={classes.errorText}>There was an issue saving this market report</h1>
        </div>
      )}
      <div className={classes.divider} />
    </div>
  );
};

SaveReport.propTypes = {
  reportSaved: PropTypes.bool,
  setSubscribe: PropTypes.func,
  changedTitles: PropTypes.bool,
  setChangedTitles: PropTypes.func,
  contactData: PropTypes.object,
  newTitle: PropTypes.string,
  thumbnail: PropTypes.string,
  user: PropTypes.string,
  subscribeContactData: PropTypes.object,
  headline: PropTypes.string,
  setNewTitle: PropTypes.func,
  editing: PropTypes.bool,
  setReportSaved: PropTypes.func,
  setReportSubmitted: PropTypes.func,
  setMarketReportSubmiting: PropTypes.func,
  setEditedReport: PropTypes.func,
  setGetUserData: PropTypes.func,
  landingPageView: PropTypes.bool,
  setContactReportWasMade: PropTypes.func,
};

export default SaveReport;
