import React, { useContext, useEffect, useState } from "react";
import PropTypes, { object } from "prop-types";
import { GET_USER_DATA } from "../../../../Services/Graph/UserSettings/Queries";
import { AppStateContext, AppDispatchContext } from "../../../../Context/AppReducer";
import { authApolloClient } from "../../../../Services/Graph/apolloConfig";
import { useLazyQuery, useMutation } from "@apollo/client";
import { createStyles, makeStyles, Paper, CircularProgress, Button, Fade } from "@material-ui/core";
import { ErrorOutlineOutlined } from "@material-ui/icons";
import UserSettingsAccountDetailsInputs from "./UserSettingsAccountDetailsInputs";
import { Check, EditOutlined } from "@material-ui/icons";
import { UPDATE_USER } from "../../../../Services/Graph/UserSettings/Mutations";
import getFormattedPhoneNumber from "../../../../Helpers/getFormattedPhoneNumber";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: "20px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    accountSettingsHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "30px",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    accountSettingsInfoText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    editContactButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    editContactButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    cancelButton: {
      color: theme.palette.grey[500],
      marginRight: "10px",
    },
    infoContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    infoText: {
      width: "100%",
    },
    inputActionButtons: {
      width: "130px",
    },
  })
);

const UserSettingsAccountDetails = ({ openUpdateUserSuccess, setOpenUpdateUserSuccess }) => {
  const classes = useStyles();
  const globalState = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);
  const [openUpdatingInputs, setOpenUpdatingInputs] = useState(false);
  const [updateName, setUpdateName] = useState(false);
  const [updatedUserAccountInfo, setUpdatedUserAccountInfo] = useState({});

  // QUERIES
  const [fetchUserInfo, { data: userInfoData, loading: userInfoLoading, error: userInfoError }] = useLazyQuery(
    GET_USER_DATA,
    {
      variables: {
        id: globalState?.user?.userId ?? 0,
      },
      client: authApolloClient,
      fetchPolicy: "network-only",
    }
  );
  const userInfo = userInfoData?.user;
  const [updateUserAccountInfo, { data: updateData }] = useMutation(UPDATE_USER, {
    variables: {
      title: updatedUserAccountInfo.title,
      licenseId: updatedUserAccountInfo.licenseId,
      email: updatedUserAccountInfo.email,
      phone: updatedUserAccountInfo.phone,
      firstName: updatedUserAccountInfo.firstname,
      lastName: updatedUserAccountInfo.lastname,
      userId: globalState?.user?.userId ?? 0,
      username: globalState?.user?.username ?? "",
      streetAddress: updatedUserAccountInfo.street,
      city: updatedUserAccountInfo.city,
      state: updatedUserAccountInfo.state,
      zip: updatedUserAccountInfo.zip,
    },
    client: authApolloClient,
  });

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  useEffect(() => {
    if (userInfoData && updateName) {
      setUpdateName(false);
      globalDispatch({ type: "setFullName", payload: `${userInfoData.user.firstName} ${userInfoData.user.lastName}` });
    }
    if (Object.keys(updatedUserAccountInfo ?? {}).length > 0 && openUpdateUserSuccess === true) {
      setUpdatedUserAccountInfo({});
      updateUserAccountInfo();
    }
  }, [updatedUserAccountInfo, openUpdateUserSuccess, userInfoData]);

  useEffect(() => {
    if (updateData) {
      fetchUserInfo();
      setUpdateName(true);
    }
  }, [updateData]);
  const checkForFade = () => {
    if (userInfoData) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.accountSettingsHeaderContainer}>
        <h1 className={classes.paperLabel}>Account Details</h1>
        <div>
          {openUpdatingInputs ? (
            <Fade in={openUpdatingInputs} timeout={500}>
              <div className={classes.inputActionButtons}>
                <Button
                  aria-label="cancel"
                  className={classes.cancelButton}
                  onClick={() => {
                    setOpenUpdatingInputs(false);
                  }}
                >
                  Cancel
                </Button>
                <Button className={classes.editContactButton} type="submit" form="user-account-details-form">
                  <Check color="inherit" fontSize="inherit" className={classes.editContactButtonIcon} />
                </Button>
              </div>
            </Fade>
          ) : (
            <Button
              className={classes.editContactButton}
              onClick={(e) => {
                e.preventDefault();
                setOpenUpdatingInputs(true);
              }}
            >
              <EditOutlined color="inherit" fontSize="inherit" className={classes.editContactButtonIcon} />
            </Button>
          )}
        </div>
      </div>
      <div className={classes.infoContainer}>
        {userInfoLoading && (
          <CircularProgress
            size={60}
            thickness={3}
            color="inherit"
            className={classes.progress}
            classes={{
              circle: classes.circle,
            }}
          />
        )}
        {userInfoError && <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.error} />}
        {openUpdatingInputs ? (
          <UserSettingsAccountDetailsInputs
            userInfo={userInfo}
            openUpdateUserSuccess={openUpdateUserSuccess}
            setOpenUpdateUserSuccess={setOpenUpdateUserSuccess}
            setOpenUpdatingInputs={setOpenUpdatingInputs}
            setUpdatedUserAccountInfo={setUpdatedUserAccountInfo}
            openUpdatingInputs={openUpdatingInputs}
          />
        ) : null}
        {Object.keys(userInfo ?? {}).length !== 0 && userInfoData !== undefined && openUpdatingInputs === false ? (
          <Fade in={checkForFade()} timeout={500}>
            <div className={classes.infoText}>
              <h2 className={classes.accountSettingsInfoText}>
                {userInfo?.firstName} {userInfo?.lastName}
              </h2>
              <h2 className={classes.accountSettingsInfoText}>{userInfo?.email}</h2>
              <br></br>
              {userInfo?.address?.street ? (
                <div>
                  <h2 className={classes.accountSettingsInfoText}>{userInfo?.address?.street}</h2>
                  <h2 className={classes.accountSettingsInfoText}>
                    {userInfo?.address?.city} {userInfo?.address?.state} {userInfo?.address?.zip}{" "}
                    {userInfo?.address?.country}
                  </h2>
                </div>
              ) : (
                <h2 className={classes.accountSettingsInfoText}>Please add an address</h2>
              )}
              <br></br>
              <h2 className={classes.accountSettingsInfoText}>{getFormattedPhoneNumber(userInfo?.phone)}</h2>
              <br></br>
              <h2 className={classes.accountSettingsInfoText}>{userInfo?.title}</h2>
              <h2 className={classes.accountSettingsInfoText}>{userInfo?.licenseId}</h2>
            </div>
          </Fade>
        ) : null}
      </div>
    </Paper>
  );
};

UserSettingsAccountDetails.propTypes = {
  openUpdateUserSuccess: PropTypes.bool,
  setOpenUpdateUserSuccess: PropTypes.func,
};

export default UserSettingsAccountDetails;
