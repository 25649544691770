import React, { useEffect, useState, useContext } from "react";
import { makeStyles, createStyles, Button, Grid, FormControl, InputLabel, Select, MenuItem, withStyles, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { GET_REPORTS } from "../../../Services/Graph/DashBoard/DashboardQueries";
import { GET_ALL_REPORTS } from "../../../Services/Graph/MarketReportPage/MarketReportPageQueries";
import { Skeleton, Pagination } from "@material-ui/lab";
import RecentReportsPhoto from "../../Dashboard/DashboardRecentReport/DashBoardRecentReportsPhoto";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { AppDispatchContext } from "../../../Context/AppReducer";
import DeletePopUp from "../MarketReportCardButtons/MarketReportsDeleteButton";
import RecentReportsCardButtonsPopUp from "../MarketReportCardButtons/MarketReportCardButtonsPopUp";
import Moment from "react-moment";
import moment from "moment-timezone";

import MarketReportCardSentConfirm from "../MarketReportCardButtons/MarketReportCardSentConfirm";

const useStyles = makeStyles((theme) =>
  createStyles({
    gridBackgrounds: {
      display: "flex",
      flexDirection: "column",
      borderRadius: "10px",
      backgroundColor: "#FEFEFE",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0008), 0px 2px 8px rgba(0, 0, 0, 0.12)",
      top: "0",
      position: "relative",
      "&:hover": {
        top: "-10px",
      },
    },
    trans: {
      transition: "0.25s",
    },
    gridBackgroundsNoReport: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      borderRadius: "10px",
      backgroundColor: "#F5F5F5",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0008), 0px 2px 8px rgba(0, 0, 0, 0.12)",
    },
    noButton: {
      paddingTop: "0px",
      paddingBottom: "15px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
    },
    noReports: {
      fontSize: "32px",
      padding: "95px 30px",
      fontFamily: "nunito",
    },
    overNoReports: {
      width: "calc(100% - 40px)",
      marginLeft: "20px",
    },
    grid1: {
      paddingRight: "20px",
      paddingLeft: "20px",
    },
    marketLinks: {
      textDecoration: "none",
      color: "#000000",
    },
    SkeletonDivTop: {
      padding: "10px 10px 0px 10px",
    },
    infoDiv: {
      padding: "20px 29px 0px 29px ",
    },
    mapOverDiv: {
      padding: "10px 10px 0px 10px",
    },
    overFlowDiv: {
      width: "calc(100% + 40px)",
      marginLeft: "-20px",
      alignItems: "flex-start",
    },
    maxMarginDiv: {
      justifyContent: "center",
      alignItems: "center",
    },
    titleDiv: {
      fontFamily: "roboto",
      fontWeight: "700",
      fontSize: "20px",
      marginBottom: "16px",
      minHeight: "58px",
    },
    createdDiv: {
      fontSize: "14px",
      fontWeight: "700",
      marginBottom: "8px",
    },
    subscribeDiv: {
      fontSize: "14px",
      fontWeight: "700",
      marginBottom: "8px",
    },
    infoContent: {
      fontWeight: "400",
    },
    titles: {
      marginTop: "15px",
      fontFamily: "nunito",
      fontWeight: "600",
      fontSize: "24px",
    },
    howRecentDiv: {
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "18px",
      fontColor: "#3A3A3A",
    },
    titlesContainer: {
      color: "#3A3A3A",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    recentTitleDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    titleHolder: {
      marginBottom: "23px",
    },
    gridMargin: {
      position: "relative",
      paddingBottom: "30px",
    },
    paginDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: " center",
      paddingTop: "30px",
    },
    iconsDiv: {
      padding: "15px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
    },
    smallIconButton: {
      background: "#818181",
      minWidth: "0px",
      padding: "9.5px",
      borderRadius: "100px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: "#818181",
      },
    },
    circle: {
      height: "21px !important",
      width: "21px !important",
      color: "#FFFFFF",
    },
    paddedButtons: {
      padding: "0px 5px",
    },
    transitionDiv: {
      transition: "0.25s",
      overflow: "hidden",
    },
    iconButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "11.5px",
      borderRadius: "100px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    createReportButtonIcon: {
      color: "#fff",
      fontSize: "21px",
    },
    createReportButtonIconBig: {
      color: "#fff",
      fontSize: "27px",
    },
    select: {
      "&:before": {
        borderColor: "transparent",
      },
      "&:after": {
        borderColor: "transparent",
      },
    },
  })
);

const RoundSelect = withStyles((theme) => ({
  select: {
    backgroundColor: "transparent !important",
  },
}))(Select);

const RecentReportsCardComps = ({ userId, user, setSavedReport, savedReport, setCreateReportOpen }) => {
  const classes = useStyles();
  const [howRecent, setHowRecent] = useState(4);
  const [pagePosition, setPagePosition] = useState(1);
  const [displayedReport, setDisplayedReport] = useState([]);
  const [displayedPaginateReport, setPaginateDisplayedReport] = useState([]);
  const globalDispatch = useContext(AppDispatchContext);
  const [queryCache, setQueryCache] = useState("cache-first");
  const [reportUp, setReportUp] = useState(false);
  const [pullDelete, setPullDelete] = useState(false);
  const [deleteReportID, setDeleteReportID] = useState(1);
  const [sendReportOpen, setSendReportOpen] = useState(false);
  const [reportHasSubscription, setReportHasSubscription] = useState(0);
  const [whichReportURL, setWhichReportURL] = useState("");
  const [reportId, setReportId] = useState(Number);
  const [currentKey, setCurrentKey] = useState(Number);
  const [deleteKey, setDeleteKey] = useState(13);
  const [reportWasDeleted, setReportWasDeleted] = useState(false);
  const [doTransition, setDoTransition] = useState(false);
  const [confirmUp, setConfirmUp] = useState(false);
  const [canHover, setCanHover] = useState(false);

  const eightArray = [1, 2, 3, 4, 5, 6, 7, 8];

  const [getAllReports, { data: allReportsData, loading: allReportsLoading, error: allReportsError }] = useLazyQuery(GET_ALL_REPORTS, {
    variables: {
      pageSize: 100000,
      page: 0,
      sortField: "dateCreated",
      sortDirection: "desc",
    },
    client: authApolloClient,
    fetchPolicy: queryCache,
  });

  useEffect(() => {
    const errorMessage = allReportsError?.networkError?.result?.error ?? "";
    if (allReportsError && (errorMessage === "Token is expired" || errorMessage === "Required authorization token not found")) {
      globalDispatch({ type: "setExpiredSessionModal", payload: true });
    }
  }, [allReportsError, globalDispatch]);

  const [getReports, { data: reportsData, loading: reportsLoading }] = useLazyQuery(GET_REPORTS, {
    variables: {
      pageSize: 12,
      page: pagePosition - 1,
      sortField: "dateCreated",
      sortDirection: "desc",
    },
    client: authApolloClient,
    fetchPolicy: queryCache,
  });

  const skeletonStyle = {
    borderRadius: "10px",
  };
  const skeletonStyleButtonSmall = {
    borderRadius: "50px",
    width: "40px",
    margin: "0px 5px",
  };
  const skeletonStyleButtonBig = {
    borderRadius: "50px",
    width: "50px",
    margin: "0px 5px",
  };
  const pageCount = (allReports) => {
    var x = allReports / 12;
    if (Number.isInteger(x)) {
      return x;
    } else {
      var y = Math.floor(x) + 1;
      return y;
    }
  };
  useEffect(() => {
    if (savedReport || pullDelete) {
      setQueryCache("network-only");
      setTimeout(() => {
        getReports();
        getAllReports();
      }, 250);

      setPullDelete(false);
      setSavedReport(false);
    }
    if (pullDelete) {
      if (displayedReport.length === 1) {
        setDisplayedReport([]);
      }
    }
  }, [savedReport, pullDelete]);

  useEffect(() => {
    if (reportsData === undefined && allReportsData === undefined) {
      getReports();
      getAllReports();
    }
    if (!reportsLoading && !allReportsLoading) {
      var tempPaginateDisplayed = [];
      var tempDisplayed = [];
      var todaysDate = new Date();
      var zeroDate = todaysDate.setHours(0, 0, 0, 0);

      if (allReportsData !== undefined) {
        allReportsData.reports.map((report, index) => {
          var arr = report.dateCreated.split(/\D/);
          var date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
          var zeroReportDate = date.setHours(0, 0, 0, 0);

          if (howRecent === 1 && zeroReportDate >= zeroDate - 2592000000) {
            tempPaginateDisplayed.push(report);
          } else if (howRecent === 2 && zeroReportDate >= zeroDate - 5184000000) {
            tempPaginateDisplayed.push(report);
          } else if (howRecent === 3 && zeroReportDate >= zeroDate - 7776000000) {
            tempPaginateDisplayed.push(report);
          } else if (howRecent === 4) {
            tempPaginateDisplayed.push(report);
          }
          if (index + 1 === allReportsData.reports.length) {
            setPaginateDisplayedReport(tempPaginateDisplayed);
          }
        });
      }

      if (reportsData !== undefined) {
        setReportWasDeleted(false);
        setDeleteKey(13);
        reportsData.reports.map((report, index) => {
          var arr = report.dateCreated.split(/\D/);
          var date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
          var zeroReportDate = date.setHours(0, 0, 0, 0);
          if (howRecent === 1 && zeroReportDate >= zeroDate - 2592000000) {
            tempDisplayed.push(report);
          } else if (howRecent === 2 && zeroReportDate >= zeroDate - 5184000000) {
            tempDisplayed.push(report);
          } else if (howRecent === 3 && zeroReportDate >= zeroDate - 7776000000) {
            tempDisplayed.push(report);
          } else if (howRecent === 4) {
            tempDisplayed.push(report);
          }
          if (index + 1 === reportsData.reports.length) {
            setDisplayedReport(tempDisplayed);
          }
        });
      }
    }

    if (displayedReport.length === 0) {
      setPagePosition(1);
    }
  }, [reportsData, allReportsData, howRecent]);

  const handlePaginate = (event, value) => {
    setQueryCache("cache-first");
    setPagePosition(value);
  };

  const sendToContacts = (reportURL, reportId, reportSubscribed) => {
    setSendReportOpen(true);
    setWhichReportURL(reportURL);
    setReportId(reportId);
    setReportHasSubscription(reportSubscribed);
  };

  const openDelete = (id) => {
    setReportUp(true);
    setDeleteReportID(id);
  };

  const handleEditReport = (e, id) => {
    e.preventDefault();
    setCreateReportOpen({ open: true, editing: true, editPayload: id });
  };
  return (
    <div>
      <Grid className={classes.titleHolder} container>
        <Grid className={classes.recentTitleDiv} item xs={6}></Grid>
        <Grid className={classes.titlesContainer} item xs={6}>
          <FormControl>
            <InputLabel id="demo-simple-select-label"></InputLabel>
            <RoundSelect
              disableUnderline
              className={classes.select}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={howRecent}
              onChange={(e) => {
                setPagePosition(1);
                setHowRecent(e.target.value);
              }}
            >
              <MenuItem value={4}>All Reports</MenuItem>
              <MenuItem value={1}>Last Month</MenuItem>
              <MenuItem value={2}>Last 2 Months</MenuItem>
              <MenuItem value={3}>Last 3 Months</MenuItem>
            </RoundSelect>
          </FormControl>
        </Grid>
      </Grid>
      <div className={classes.maxMarginDiv}>
        <Grid className={classes.overFlowDiv} container alignItems="center">
          {reportsData !== undefined || reportWasDeleted ? (
            displayedReport.length !== 0 ? (
              displayedReport.map((report, index) => {
                const splitDate = report.dateCreated.split(" ");
                const reportsDate = new Date(splitDate[0] + "T" + splitDate[1] + "Z");
                const reportDate = moment(reportsDate, "YYYY-MMM-DD HH:mm");
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                return (
                  <Grid
                    onMouseEnter={() => {
                      setCanHover(true);
                    }}
                    onMouseLeave={() => setCanHover(false)}
                    onTransitionEnd={() => {
                      setDoTransition(false);
                    }}
                    style={index === deleteKey ? { maxWidth: "0px", margin: "0px,", padding: "0px", visibility: "hidden", maxHeight: "0px" } : null}
                    key={index}
                    className={`${classes.gridMargin}  ${doTransition ? classes.transitionDiv : null}  ${classes.grid1}`}
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <Link target="_blank" className={classes.marketLinks} to={`/${user}/market-reports/${report.id}`}>
                      <Grid className={`${classes.gridBackgrounds} ${canHover ? classes.trans : null}`} container>
                        <div className={classes.mapOverDiv}>
                          <RecentReportsPhoto photo={report.thumbnailURL} />
                        </div>
                        <div className={classes.infoDiv}>
                          <div className={classes.titleDiv}>{report.title}</div>
                          <div className={classes.createdDiv}>
                            Created:{" "}
                            <span className={classes.infoContent}>
                              <Moment tz={timezone} format="MM/DD/YYYY hh:mm A">
                                {reportDate}
                              </Moment>
                            </span>
                          </div>
                          <div className={classes.subscribeDiv}>
                            Subscribers: <span className={classes.infoContent}>{report.subscriberCount}</span>
                          </div>
                        </div>
                        <div className={classes.iconsDiv}>
                          <div className={classes.paddedButtons}>
                            <Button onClick={(e) => handleEditReport(e, report.id)} className={classes.smallIconButton}>
                              <EditIcon color="inherit" fontSize="inherit" className={classes.createReportButtonIcon} />
                            </Button>
                          </div>

                          <div className={classes.paddedButtons}>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                sendToContacts(report.socialShareURL, report.id);
                              }}
                              className={classes.iconButton}
                            >
                              <SendIcon color="inherit" fontSize="inherit" className={classes.createReportButtonIconBig} />
                            </Button>
                          </div>
                          <div className={classes.paddedButtons}>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                if (!reportsLoading) {
                                  openDelete(report.id);
                                  setCurrentKey(index);
                                  setReportHasSubscription(report.subscriberCount);
                                }
                              }}
                              className={classes.smallIconButton}
                            >
                              {reportsLoading ? <CircularProgress className={classes.circle} /> : <DeleteIcon color="inherit" fontSize="inherit" className={classes.createReportButtonIcon} />}
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Link>
                  </Grid>
                );
              })
            ) : (
              <div className={classes.overNoReports}>
                <Grid className={classes.gridBackgroundsNoReport} item xs={12}>
                  <div className={classes.noReports}>You Have No Market Reports</div>
                </Grid>
              </div>
            )
          ) : (
            eightArray.map((report, index) => (
              <Grid key={index} className={`${classes.gridMargin}  ${classes.grid1}`} item lg={3} md={4} sm={6} xs={12}>
                <Grid className={classes.gridBackgrounds} container>
                  <div className={classes.SkeletonDivTop}>
                    <Skeleton style={skeletonStyle} variant="rect" animation="wave" height={205} />
                  </div>
                  <div className={classes.infoDiv}>
                    <div className={classes.titleDiv}>
                      <Skeleton style={skeletonStyle} variant="rect" animation="wave" height={58} />
                    </div>
                    <div className={classes.createdDiv}>
                      <Skeleton style={skeletonStyle} variant="rect" animation="wave" height={28} />
                    </div>
                    <div className={classes.subscribeDiv}>
                      <Skeleton style={skeletonStyle} variant="rect" animation="wave" height={28} />
                    </div>
                  </div>
                  <div className={classes.noButton}>
                    <Skeleton style={skeletonStyleButtonSmall} variant="rect" animation="wave" height={40} />
                    <Skeleton style={skeletonStyleButtonBig} variant="rect" animation="wave" height={50} />
                    <Skeleton style={skeletonStyleButtonSmall} variant="rect" animation="wave" height={40} />
                  </div>
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
      </div>
      <div className={classes.paginDiv}>
        <Pagination onChange={handlePaginate} page={pagePosition} count={displayedPaginateReport !== undefined ? pageCount(displayedPaginateReport.length) : 1} />
      </div>
      <DeletePopUp
        setDoTransition={setDoTransition}
        setReportWasDeleted={setReportWasDeleted}
        deleteKey={deleteKey}
        setDeleteKey={setDeleteKey}
        currentKey={currentKey}
        setCurrentKey={setCurrentKey}
        reportUp={reportUp}
        setReportUp={setReportUp}
        setPullDelete={setPullDelete}
        deleteReportID={deleteReportID}
        reportHasSubscription={reportHasSubscription}
      />
      <RecentReportsCardButtonsPopUp
        userId={userId}
        user={user}
        reportId={reportId}
        sendReportOpen={sendReportOpen}
        setSendReportOpen={setSendReportOpen}
        whichReportURL={whichReportURL}
        setConfirmUp={setConfirmUp}
      />
      <MarketReportCardSentConfirm setConfirmUp={setConfirmUp} confirmUp={confirmUp} />
    </div>
  );
};

RecentReportsCardComps.propTypes = {
  user: PropTypes.string,
  userId: PropTypes.number,
  setSavedReport: PropTypes.func,
  savedReport: PropTypes.bool,
  setCreateReportOpen: PropTypes.func,
};

export default RecentReportsCardComps;
