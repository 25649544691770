import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, Button, withStyles, CircularProgress } from "@material-ui/core";
import UnSubscribeHeader from "./UnSubscribeHeader";
import { Element } from "react-scroll";
import { GET_SUBSCRIBED_REPORTS } from "../../Services/Graph/UnsubscribePage/Queries";
import { openApolloClient } from "../../Services/Graph/apolloConfig";
import { useLazyQuery } from "@apollo/client";
import UnsubscribeConfirm from "./unsubscribeConfirm";
import UnsubscribeTable from "./UnsubscribeTable";

const useStyles = makeStyles((theme) =>
  createStyles({
    overParent: {
      position: "absolute",
      backgroundColor: "#fff",
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    buttonParent: {
      justifyContent: "flex-end",
      display: "flex",
    },
    overDiv: {
      padding: "0px 180px",
      width: "100%",
      height: "100%",
    },
    parentDiv: {
      width: "100%",
      maxWidth: "1560px",
      fontFamily: "nunito",
    },
    titleDiv: {
      marginTop: "109px",
      fontWeight: "600",
      fontSize: "36px",
      marginBottom: "31px",
    },
    subTitle: {
      fontWeight: "700",
      fontSize: "24px",
      marginBottom: "33px",
    },
    line: {
      height: "1px",
      width: "100%",
      backgroundColor: "#DEDEDE",
      marginBottom: "34px",
    },
    loaderParent: {
      height: "210px",
      alignContent: "center",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    loader: {
      color: "#0079B4",
      marginTop: "100px",
      height: "80px !important",
      width: "80px !important",
    },
    noReportsTitle: {
      fontWeight: "700",
      fontSize: "36px",
      textAlign: "center",
    },
    noReportsParent: {
      backgroundColor: "#F5F5F5",
      borderRadius: "7px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "200px",
    },
    "@media (max-width: 900px)": {
      overDiv: {
        padding: "0px 25px",
      },
      titleDiv: {
        fontSize: "25px",
      },
    },
  })
);

const UnSubscribe = ({ match }) => {
  const [reportUp, setReportUp] = useState(false);
  const [sendIDs, setSendIDs] = useState([]);
  const [regrabReports, setRegrabReports] = useState(false);
  const [selectedReport, setSelectedReport] = useState(false);
  const [data, setData] = useState([]);
  const classes = useStyles();

  const [fetchUserReports, { data: userInfoData, loading: userInfoLoading, error: userInfoError }] = useLazyQuery(GET_SUBSCRIBED_REPORTS, {
    variables: {
      unsubscribeToken: match?.params?.token,
    },
    client: openApolloClient,
    fetchPolicy: "network-only",
  });

  const changeData = () => {
    data.map((element, index) => {
      if (element.id.toString() === match.params.reportID) {
        const newData = [...data];
        newData.splice(index, 1, {
          checked: true,
          id: data[index].id,
          title: data[index].title,
        });
        setData(newData);
      }
    });
  };

  useEffect(() => {
    fetchUserReports();
  }, []);

  useEffect(() => {
    if (regrabReports) {
      setRegrabReports(false);
      fetchUserReports();
    }
  }, [regrabReports]);

  useEffect(() => {
    if (userInfoData) {
      setData(userInfoData?.unsubscribeContact?.reports);
    }
  }, [userInfoData]);

  useEffect(() => {
    if (data && userInfoData?.unsubscribeContact?.reports && !selectedReport) {
      setSelectedReport(true);
      changeData();
    }
  }, [data]);

  const CloseButton = withStyles((theme) => ({
    root: {
      marginTop: "32px",
      width: "190px",
      height: "50px",
      color: "#fff",
      background: theme.palette.areaPulseBlue,
      fontSize: "16px",
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      padding: "5px 30px",
      borderRadius: "10px",
      transition: "0.2s",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
        transition: "0.2s",
        opacity: "0.8",
      },
    },
  }))(Button);

  const checkIDs = () => {
    setSendIDs([]);
    let setArray = [];
    data.forEach((element) => {
      if (element.checked) {
        setArray.push(element.id);
      }
    });
    setSendIDs(setArray);
  };
  return (
    <Element className={classes.overParent} name="unsubscribe">
      <UnSubscribeHeader />
      <div className={classes.overDiv}>
        <div className={classes.parentDiv}>
          <div
            onClick={() => {
              changeData();
            }}
            className={classes.titleDiv}
          >
            Unsubscribe From Report
          </div>
          <div className={classes.line}></div>
          <div className={classes.subTitle}>All Reports</div>
          {userInfoData?.unsubscribeContact?.reports ? (
            userInfoData?.unsubscribeContact?.reports.length !== 0 ? (
              <UnsubscribeTable setData={setData} data={data} options={userInfoData?.unsubscribeContact?.reports} onChange={(data) => {}} />
            ) : (
              <div className={classes.noReportsParent}>
                <div className={classes.noReportsTitle}>You Have No Report Subscriptions.</div>
              </div>
            )
          ) : (
            <div className={classes.loaderParent}>
              <CircularProgress className={classes.loader} />
            </div>
          )}
          <div className={classes.buttonParent}>
            {userInfoData?.unsubscribeContact?.reports.length !== 0 && !userInfoLoading ? (
              <CloseButton
                onClick={() => {
                  setReportUp(true);
                  checkIDs();
                }}
              >
                UNSUBSCRIBE
              </CloseButton>
            ) : null}
          </div>
        </div>
      </div>
      <UnsubscribeConfirm token={match?.params?.token} setRegrabReports={setRegrabReports} sendIDs={sendIDs} setReportUp={setReportUp} reportUp={reportUp} />
    </Element>
  );
};

UnSubscribe.propTypes = {
  googleScriptLoaded: PropTypes.bool,
};

export default UnSubscribe;
