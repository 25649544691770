import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import MarketReportsComponent from "../Components/MarketReports/MarketReportsComponent";
import CreateReport from "../Components/CreateReport/CreateReport";

const useStyles = makeStyles((theme) =>
  createStyles({
    marketReportsViewContainer: {
      position: "absolute",
      left: "0",
      right: "0",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  })
);

const MarketReportsView = ({ userId, createReportOpen, setCreateReportOpen, savedReport, setSavedReport, match, googleScriptLoaded }) => {
  const classes = useStyles();
  const user = match.params.user;

  return (
    <div className={classes.marketReportsViewContainer}>
      {createReportOpen.open && (
        <CreateReport
          user={user}
          googleScriptLoaded={googleScriptLoaded}
          createReportOpen={createReportOpen}
          setSavedReport={(status) => setSavedReport(status)}
          setCreateReportOpen={(status) => setCreateReportOpen(status)}
        />
      )}
      <MarketReportsComponent userId={userId} user={user} setSavedReport={(status) => setSavedReport(status)} setCreateReportOpen={(status) => setCreateReportOpen(status)} savedReport={savedReport} />
    </div>
  );
};

MarketReportsView.propTypes = {
  match: PropTypes.object,
  googleScriptLoaded: PropTypes.bool,
  userId: PropTypes.number,
};

export default MarketReportsView;
