const loadGoogleApiScript = (url, callback) => {
  const isScriptAlreadylaoded = window.google !== undefined;
  if (!isScriptAlreadylaoded) {
    let script = document.createElement("script");
    script.type = "text/javascript";
  
    if (script.readyState) {
      script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }
  
    script.setAttribute("async", "");
    script.setAttribute("defer", "");
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  } else {
    return null;
  }
};

export default loadGoogleApiScript;