import React from "react";
import GoogleLogin from "react-google-login";
import GoogleLoginStylesheet from "./GoogleLoginStylesheet";
import { Button, CircularProgress, withStyles } from "@material-ui/core";
import { getGoogleClientId } from "../../../Helpers/getEnvVar";

const SocialButton = withStyles((theme) => ({
  root: {
    maxWidth: "471px",
    color: "#D33141",
    height: "43px",
    width: "100%",
    padding: "15px 29px",
    background: "#FFF",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Nunito",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "700",
    border: "1px solid #E4E4E4",

    "&:hover": {
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const GoogleLoginButton = ({ size, clicked, response, loading }) => {
  const classes = GoogleLoginStylesheet();
  const googleClientId = getGoogleClientId();

  const GoogleIcon = () => <img src="/assets/google-login-icon.png" alt="google icon" className={size === "large" ? classes.socialIcons : classes.socialIconsSmall} />;

  return (
    <GoogleLogin
      clientId={googleClientId}
      onSuccess={(res) => response(res)}
      onFailure={(res) => response(res)}
      cookiePolicy="single_host_origin"
      render={(renderProps) => {
        return (
          <SocialButton
            disabled={loading}
            onClick={() => {
              renderProps.onClick();
              clicked();
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.5006 13.9999C17.2078 15.0184 16.6276 15.9308 15.8295 16.6278C15.0314 17.3248 14.0492 17.777 13.0006 17.9299C12.1806 18.0489 11.3451 18.001 10.5441 17.789C9.74312 17.577 8.99324 17.2053 8.33946 16.6963C7.68569 16.1873 7.14152 15.5514 6.7396 14.8269C6.33769 14.1024 6.08632 13.3041 6.0006 12.4799C5.9342 11.6526 6.04017 10.8205 6.31182 10.0362C6.58347 9.2519 7.01489 8.5325 7.5788 7.92347C8.14272 7.31445 8.82685 6.82904 9.58797 6.49795C10.3491 6.16686 11.1706 5.99729 12.0006 5.99995C12.7784 6.00104 13.5488 6.15038 14.2706 6.43995C14.3843 6.49098 14.5129 6.49773 14.6313 6.45889C14.7497 6.42004 14.8493 6.3384 14.9106 6.22995L16.3506 3.57995C16.3816 3.51891 16.4002 3.45235 16.4054 3.3841C16.4107 3.31584 16.4023 3.24722 16.381 3.18218C16.3596 3.11715 16.3256 3.05697 16.2809 3.00511C16.2362 2.95324 16.1818 2.91071 16.1206 2.87995C14.5743 2.17986 12.8753 1.88489 11.1835 2.02282C9.49174 2.16075 7.86292 2.72703 6.45045 3.66834C5.03799 4.60965 3.88837 5.895 3.10988 7.40333C2.33139 8.91166 1.94965 10.5933 2.0006 12.2899C2.09915 14.8098 3.13498 17.2022 4.90518 18.9983C6.67538 20.7944 9.0524 21.8648 11.5706 22C14.2085 22.1169 16.7857 21.1858 18.7396 19.4098C20.6936 17.6338 21.8659 15.157 22.0006 12.5199V10.5199C21.998 10.3881 21.9445 10.2624 21.8513 10.1692C21.7581 10.076 21.6324 10.0225 21.5006 10.0199H12.5006C12.368 10.0199 12.2408 10.0726 12.147 10.1664C12.0533 10.2602 12.0006 10.3873 12.0006 10.5199V13.5199C12.0006 13.6526 12.0533 13.7797 12.147 13.8735C12.2408 13.9673 12.368 14.0199 12.5006 14.0199H17.5006"
                fill="#D33141"
              />
            </svg>

            <span className={`${classes.socialTextGoogle}`}>Sign Up With Google</span>
            {/* {loading ? <CircularProgress className={classes.progress} /> : null} */}
          </SocialButton>
        );
      }}
    />
  );
};

export default GoogleLoginButton;
