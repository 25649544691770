import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import { makeStyles, createStyles, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    section: {
      position: "relative",
      padding: "120px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "2",
      backgroundColor: "#ffffff",
    },
    sectionContentContainer: {
      position: "relative",
      width: "80%",
      maxWidth: "1600px",
      zIndex: "9",
    },
    fadeInUp: {
      animationName: "$fadeInUp",
    },
    "@keyframes fadeInUp": {
      from: {
        opacity: "0",
        transform: "translate3d(0, 100%, 0)",
      },
      to: {
        opacity: "1",
        transform: "translate3d(0, 0, 0)",
      },
    },
    delay0: {
      animationDelay: "0.3s",
    },
    delay1: {
      animationDelay: "0.5s",
    },
    delay2: {
      animationDelay: "0.7s",
    },
    textCenter: {
      textAlign: "center",
    },
    homeValueHeader: {
      fontSize: "36px",
      fontFamily: "Nunito",
      margin: "20px 0px 120px 0px",
      lineHeight: "1",
      fontWeight: "700",
    },
    stepContainer: {
      textAlign: "center",
      padding: "0px 30px",
    },
    homeValueStepHeaders: {
      fontSize: "36px",
      fontWeight: "700",
    },
    homeValueDescriptions: {
      color: "#000",
      fontSize: "24px",
      fontWeight: "400",
    },
    "@media (max-width: 1280px)": {
      sectionContentContainer: {
        width: "95%",
      },
    },
    "@media (max-width: 1055px)": {
      homeValueHeader: {
        fontSize: "30px",
      },
      homeValueStepHeaders: {
        fontSize: "24px",
      },
      homeValueDescriptions: {
        fontSize: "18px",
      },
    },
    "@media (max-width: 960px)": {
      sectionContentContainer: {
        width: "85%",
      },
      homeValueHeader: {
        fontSize: "36px",
        margin: "20px 0px 20px 0px",
      },
      stepContainer: {
        textAlign: "center",
        padding: "20px 0px",
      },
    },
    "@media (max-width: 768px)": {
      section: {
        padding: "45px 25px",
      },
    },
    "@media (max-width: 468px)": {
      sectionContentContainer: {
        width: "95%",
      },
    },
    primaryColor: {
      fill: (props) => props.primaryColor,
    },
  })
);

const HomeValueSteps = ({ userData }) => {
  const [primaryColor, setPrimaryColor] = useState(userData?.user?.landingPageSettings?.themeColorPrimary);
  const classes = useStyles({ primaryColor });

  useEffect(() => {
    if (userData?.user?.landingPageSettings?.themeColorPrimary === "") {
      setPrimaryColor("#008DD1");
    }
  }, [userData]);

  return (
    <Element name="homevaluesteps" className={classes.section}>
      <Grid container className={classes.sectionContentContainer}>
        <Grid item xs={12}>
          <h2 className={`wow ${classes.fadeInUp} ${classes.homeValueHeader} ${classes.textCenter}`}>Get Re-Occuring Area Market Report in 3 Easy Steps</h2>
          <Grid container>
            <Grid item xs={12} sm={12} md={4} className={classes.stepContainer}>
              <div className={`wow fadeIn ${classes.delay0}`}>
                <svg className={classes.primaryColor} width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 5.24609H50C54.25 5.24609 57.5 8.50015 57.5 12.7554V37.7866C57.5 42.0419 54.25 45.296 50 45.296H32.5V50.3022H40C41.5 50.3022 42.5 51.3035 42.5 52.8053C42.5 54.3072 41.5 55.3085 40 55.3085H20C18.5 55.3085 17.5 54.3072 17.5 52.8053C17.5 51.3035 18.5 50.3022 20 50.3022H27.5V45.296H10C5.75 45.296 2.5 42.0419 2.5 37.7866V12.7554C2.5 8.50015 5.75 5.24609 10 5.24609ZM50 40.2897C51.5 40.2897 52.5 39.2885 52.5 37.7866V12.7554C52.5 11.2536 51.5 10.2523 50 10.2523H10C8.5 10.2523 7.5 11.2536 7.5 12.7554V37.7866C7.5 39.2885 8.5 40.2897 10 40.2897H50Z"
                    fill="black"
                  />
                  <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="5" width="56" height="51">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 5.24609H50C54.25 5.24609 57.5 8.50015 57.5 12.7554V37.7866C57.5 42.0419 54.25 45.296 50 45.296H32.5V50.3022H40C41.5 50.3022 42.5 51.3035 42.5 52.8053C42.5 54.3072 41.5 55.3085 40 55.3085H20C18.5 55.3085 17.5 54.3072 17.5 52.8053C17.5 51.3035 18.5 50.3022 20 50.3022H27.5V45.296H10C5.75 45.296 2.5 42.0419 2.5 37.7866V12.7554C2.5 8.50015 5.75 5.24609 10 5.24609ZM50 40.2897C51.5 40.2897 52.5 39.2885 52.5 37.7866V12.7554C52.5 11.2536 51.5 10.2523 50 10.2523H10C8.5 10.2523 7.5 11.2536 7.5 12.7554V37.7866C7.5 39.2885 8.5 40.2897 10 40.2897H50Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 0.238281H60V60.3131H0V0.238281Z" />
                  </g>
                </svg>
              </div>
              <h2 className={`wow fadeIn ${classes.delay0} ${classes.homeValueStepHeaders}`}>Step 1</h2>
              <p className={`wow fadeIn ${classes.delay0} ${classes.homeValueDescriptions}`}>Answer a few simple questions about your area</p>
            </Grid>
            <Grid item xs={12} sm={12} md={4} className={classes.stepContainer}>
              <div className={`wow fadeIn ${classes.delay1}`}>
                <svg className={classes.primaryColor} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M57.5 30C57.5 31.5 56.4997 32.5 54.9993 32.5H29.9923C28.4919 32.5 27.4916 31.5 27.4916 30V5C27.4916 3.5 28.4919 2.5 29.9923 2.5C45.2466 2.5 57.5 14.75 57.5 30ZM55.2494 40.75C50.9982 51.25 40.7453 57.5 29.9923 57.5C26.4913 57.5 22.7403 56.75 19.2393 55.25C5.2354 49.25 -1.26642 33.25 4.73526 19.25C7.48603 12.75 12.4874 7.5 18.9892 4.75C20.2396 4.25 21.74 4.75 22.2402 6C22.7403 7.25 22.2402 8.75 20.9898 9.25C15.7383 11.75 11.4871 16 9.23652 21.25C4.48519 32.75 9.73666 46 21.2399 50.75C32.7431 55.5 45.9968 50.25 50.7481 38.75C51.2483 37.5 52.7487 37 53.999 37.5C55.2494 38 55.7495 39.5 55.2494 40.75ZM32.493 7.75C42.7459 8.75 50.9982 17 52.2485 27.5H32.493V7.75Z"
                  />
                  <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="56" height="56">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M57.5 30C57.5 31.5 56.4997 32.5 54.9993 32.5H29.9923C28.4919 32.5 27.4916 31.5 27.4916 30V5C27.4916 3.5 28.4919 2.5 29.9923 2.5C45.2466 2.5 57.5 14.75 57.5 30ZM55.2494 40.75C50.9982 51.25 40.7453 57.5 29.9923 57.5C26.4913 57.5 22.7403 56.75 19.2393 55.25C5.2354 49.25 -1.26642 33.25 4.73526 19.25C7.48603 12.75 12.4874 7.5 18.9892 4.75C20.2396 4.25 21.74 4.75 22.2402 6C22.7403 7.25 22.2402 8.75 20.9898 9.25C15.7383 11.75 11.4871 16 9.23652 21.25C4.48519 32.75 9.73666 46 21.2399 50.75C32.7431 55.5 45.9968 50.25 50.7481 38.75C51.2483 37.5 52.7487 37 53.999 37.5C55.2494 38 55.7495 39.5 55.2494 40.75ZM32.493 7.75C42.7459 8.75 50.9982 17 52.2485 27.5H32.493V7.75Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask1)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H60V60H0V0Z" />
                  </g>
                </svg>
              </div>
              <h2 className={`wow fadeIn ${classes.delay1} ${classes.homeValueStepHeaders}`}>Step 2</h2>
              <p className={`wow fadeIn ${classes.delay1} ${classes.homeValueDescriptions}`}>You’ll receive a detailed area market report</p>
            </Grid>
            <Grid item xs={12} sm={12} md={4} className={classes.stepContainer}>
              <div className={`wow fadeIn ${classes.delay2}`}>
                <svg className={classes.primaryColor} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0399 17.5C10.0399 10.5 15.5618 5 22.5897 5C29.6176 5 35.1395 10.5 35.1395 17.5C35.1395 24.5 29.6176 30 22.5897 30C15.5618 30 10.0399 24.5 10.0399 17.5ZM45.1794 47.5V52.5C45.1794 54 44.1754 55 42.6694 55C41.1634 55 40.1594 54 40.1594 52.5V47.5C40.1594 43.25 36.8965 40 32.6296 40H12.5498C8.28289 40 5.01993 43.25 5.01993 47.5V52.5C5.01993 54 4.01594 55 2.50997 55C1.00399 55 0 54 0 52.5V47.5C0 40.5 5.52192 35 12.5498 35H32.6296C39.6575 35 45.1794 40.5 45.1794 47.5ZM22.5897 25C18.3227 25 15.0598 21.75 15.0598 17.5C15.0598 13.25 18.3227 10 22.5897 10C26.8566 10 30.1196 13.25 30.1196 17.5C30.1196 21.75 26.8566 25 22.5897 25ZM50.7013 35.5C49.4463 35.25 47.9403 36 47.6893 37.25C47.4383 38.5 48.1913 40 49.4463 40.25C52.7093 41 54.9682 44 54.9682 47.5V52.5C54.9682 54 55.9722 55 57.4782 55C58.9842 55 59.9882 54 59.9882 52.5V47.5C60.2392 41.75 56.4742 36.75 50.7013 35.5ZM37.6495 7.25C38.1515 5.75 39.4065 5 40.6614 5.5C47.4383 7 51.4543 14 49.6973 21C48.6933 25.5 45.1794 28.75 40.6614 30H40.1594C38.9045 30 37.9005 29.25 37.6495 28C37.1475 26.75 38.1515 25.25 39.4065 25C42.1674 24.25 44.1754 22.25 44.9284 19.5C45.9324 15.25 43.4224 11.25 39.4065 10.25C38.1515 10 37.3985 8.5 37.6495 7.25Z"
                  />
                  <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="5" width="60" height="50">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.0399 17.5C10.0399 10.5 15.5618 5 22.5897 5C29.6176 5 35.1395 10.5 35.1395 17.5C35.1395 24.5 29.6176 30 22.5897 30C15.5618 30 10.0399 24.5 10.0399 17.5ZM45.1794 47.5V52.5C45.1794 54 44.1754 55 42.6694 55C41.1634 55 40.1594 54 40.1594 52.5V47.5C40.1594 43.25 36.8965 40 32.6296 40H12.5498C8.28289 40 5.01993 43.25 5.01993 47.5V52.5C5.01993 54 4.01594 55 2.50997 55C1.00399 55 0 54 0 52.5V47.5C0 40.5 5.52192 35 12.5498 35H32.6296C39.6575 35 45.1794 40.5 45.1794 47.5ZM22.5897 25C18.3227 25 15.0598 21.75 15.0598 17.5C15.0598 13.25 18.3227 10 22.5897 10C26.8566 10 30.1196 13.25 30.1196 17.5C30.1196 21.75 26.8566 25 22.5897 25ZM50.7013 35.5C49.4463 35.25 47.9403 36 47.6893 37.25C47.4383 38.5 48.1913 40 49.4463 40.25C52.7093 41 54.9682 44 54.9682 47.5V52.5C54.9682 54 55.9722 55 57.4782 55C58.9842 55 59.9882 54 59.9882 52.5V47.5C60.2392 41.75 56.4742 36.75 50.7013 35.5ZM37.6495 7.25C38.1515 5.75 39.4065 5 40.6614 5.5C47.4383 7 51.4543 14 49.6973 21C48.6933 25.5 45.1794 28.75 40.6614 30H40.1594C38.9045 30 37.9005 29.25 37.6495 28C37.1475 26.75 38.1515 25.25 39.4065 25C42.1674 24.25 44.1754 22.25 44.9284 19.5C45.9324 15.25 43.4224 11.25 39.4065 10.25C38.1515 10 37.3985 8.5 37.6495 7.25Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask2)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H60V60H0V0Z" />
                  </g>
                </svg>
              </div>
              <h2 className={`wow fadeIn ${classes.delay2} ${classes.homeValueStepHeaders}`}>Step 3</h2>
              <p className={`wow fadeIn ${classes.delay2} ${classes.homeValueDescriptions}`}>Connect with one of our agents to answer any questions and provide any additional guidance</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Element>
  );
};

HomeValueSteps.propTypes = {
  userData: PropTypes.object,
};

export default HomeValueSteps;
