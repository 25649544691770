import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import NavigationComponent from "./NavigationComponent";
import { Route, Switch } from "react-router-dom";

const NavigationParent = ({ userRole, setCreateReportOpen, setSavedReport, user, state }) => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/:user/dashboard"
          render={(props) => (
            <NavigationComponent
              state={state}
              userRole={userRole}
              user={user}
              setSavedReport={(status) => setSavedReport(status)}
              setCreateReportOpen={(status) => setCreateReportOpen(status)}
              {...props}
            />
          )}
        ></Route>
        <Route
          exact
          path="/:user/market-reports"
          render={(props) => (
            <NavigationComponent
              state={state}
              userRole={userRole}
              user={user}
              setSavedReport={(status) => setSavedReport(status)}
              setCreateReportOpen={(status) => setCreateReportOpen(status)}
              {...props}
            />
          )}
        ></Route>
        <Route
          exact
          path="/:user/admin"
          render={(props) => (
            <NavigationComponent
              state={state}
              userRole={userRole}
              user={user}
              setSavedReport={(status) => setSavedReport(status)}
              setCreateReportOpen={(status) => setCreateReportOpen(status)}
              {...props}
            />
          )}
        ></Route>
        <Route
          exact
          path="/:user/contacts"
          render={(props) => <NavigationComponent state={state} userRole={userRole} user={user} setCreateReportOpen={(status) => setCreateReportOpen(status)} {...props} />}
        ></Route>
        <Route
          exact
          path="/:user/contacts/:contactId"
          render={(props) => <NavigationComponent state={state} userRole={userRole} user={user} setCreateReportOpen={(status) => setCreateReportOpen(status)} {...props} />}
        ></Route>
        <Route
          exact
          path="/:user/user-settings"
          render={(props) => <NavigationComponent state={state} userRole={userRole} user={user} setCreateReportOpen={(status) => setCreateReportOpen(status)} {...props} />}
        ></Route>
        <Route
          exact
          path="/:user/user-preferences"
          render={(props) => <NavigationComponent state={state} userRole={userRole} user={user} setCreateReportOpen={(status) => setCreateReportOpen(status)} {...props} />}
        ></Route>
        <Route exact path="/:user/contacts" render={(props) => <NavigationComponent state={state} user={user} setCreateReportOpen={(status) => setCreateReportOpen(status)} {...props} />}></Route>
        <Route
          exact
          path="/:user/contacts/:contactId"
          render={(props) => <NavigationComponent state={state} user={user} setCreateReportOpen={(status) => setCreateReportOpen(status)} {...props} />}
        ></Route>
        <Route exact path="/:user/user-settings" render={(props) => <NavigationComponent state={state} user={user} setCreateReportOpen={(status) => setCreateReportOpen(status)} {...props} />}></Route>
        <Route
          exact
          path="/:user/user-preferences"
          render={(props) => <NavigationComponent state={state} user={user} setCreateReportOpen={(status) => setCreateReportOpen(status)} {...props} />}
        ></Route>
        <Route
          exact
          path="/:user/landing-page-preferences"
          render={(props) => <NavigationComponent userRole={userRole} state={state} user={user} setCreateReportOpen={(status) => setCreateReportOpen(status)} {...props} />}
        ></Route>
      </Switch>
    </div>
  );
};

NavigationParent.propTypes = {
  user: PropTypes.string,
  userRole: PropTypes.array,
  setCreateReportOpen: PropTypes.func,
  setSavedReport: PropTypes.func,
  state: PropTypes.object,
};

export default withRouter(NavigationParent);
