import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { AppStateContext, AppDispatchContext } from "../../../Context/AppReducer";
import HomeStylesheet from "../HomeStylesheet";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { Dehaze } from "@material-ui/icons";

const MobileNav = ({ anchor, handleMenu, navItemActive, setNavItemActive, needsToBeLighter }) => {
  const classes = HomeStylesheet();
  const dispatch = useContext(AppDispatchContext);
  const state = useContext(AppStateContext);

  return (
    <div className={classes.mobileNavContainer}>
      <IconButton aria-label="toggle the navigation" aria-controls="mobile-menu" aria-haspopup="true" onClick={(e) => handleMenu("open", e)}>
        <Dehaze fontSize="large" className={needsToBeLighter ? classes.mobileIconStylesLighter : classes.mobileIconStyles} />
      </IconButton>
      <Menu id="mobile-menu" anchorEl={anchor} keepMounted open={Boolean(anchor)} onClose={() => handleMenu("close")}>
        <MenuItem className={classes.mobileItemPadding} onClick={() => handleMenu("close")}>
          <ScrollLink
            to="home"
            smooth={true}
            duration={1000}
            title="Go to the home section"
            onClick={() => setNavItemActive("home")}
            className={`${classes.navLinksStyles} ${classes.mobileNavColor} ${classes.navLinkOverrides} ${navItemActive === "home" ? classes.navLinkActive : ""}`}
          >
            Home
          </ScrollLink>
        </MenuItem>
        <MenuItem className={classes.mobileItemPadding} onClick={() => handleMenu("close")}>
          <ScrollLink
            to="about"
            smooth={true}
            offset={-80}
            duration={1000}
            title="Go to the about section"
            onClick={() => setNavItemActive("about")}
            className={`${classes.navLinksStyles} ${classes.mobileNavColor} ${classes.navLinkOverrides} ${navItemActive === "about" ? classes.navLinkActive : ""}`}
          >
            About
          </ScrollLink>
        </MenuItem>
        <MenuItem className={classes.mobileItemPadding} onClick={() => handleMenu("close")}>
          <ScrollLink
            to="included"
            smooth={true}
            offset={-110}
            duration={1000}
            title="Go to the included section"
            onClick={() => setNavItemActive("included")}
            className={`${classes.navLinksStyles} ${classes.mobileNavColor} ${classes.navLinkOverrides} ${navItemActive === "included" ? classes.navLinkActive : ""}`}
          >
            Included
          </ScrollLink>
        </MenuItem>

        <MenuItem className={classes.mobileItemPadding} onClick={() => handleMenu("close")}>
          <ScrollLink
            to="contact"
            smooth={true}
            offset={-90}
            duration={1000}
            title="Go to the contact section"
            onClick={() => setNavItemActive("contact")}
            className={`${classes.navLinksStyles} ${classes.mobileNavColor} ${classes.navLinkOverrides} ${navItemActive === "contact" ? classes.navLinkActive : ""}`}
          >
            Contact
          </ScrollLink>
        </MenuItem>
        <MenuItem className={classes.mobileItemPadding} onClick={() => handleMenu("close")}>
          {state.loggedIn ? (
            <Link to="/dashboard" className={`${classes.navLinksStyles} ${classes.mobileNavColor}`}>
              <span onClick={() => setNavItemActive("dashboard")} className={navItemActive === "dashboard" ? classes.navLinkActive : ""}>
                Dashboard
              </span>
            </Link>
          ) : (
            <ScrollLink
              to="home"
              smooth={true}
              duration={1000}
              title="Go to the home section"
              className={`${classes.navLinksStyles} ${classes.mobileLoginButton} ${navItemActive === "login" ? classes.navLinkActive : ""}`}
              onClick={() => {
                setNavItemActive("login");
                dispatch({ type: "setActiveView", payload: "Login" });
              }}
            >
              <span className={`${classes.navLinksStyles} ${classes.mobileNavColor} ${navItemActive === "login" ? classes.navLinkActive : ""}`}>Login</span>
            </ScrollLink>
          )}
        </MenuItem>
      </Menu>
    </div>
  );
};

MobileNav.propTypes = {
  anchor: PropTypes.object,
  handleMenu: PropTypes.func,
  navItemActive: PropTypes.string,
  setNavItemActive: PropTypes.func,
  state: PropTypes.object,
  needsToBeLighter: PropTypes.bool,
  handleLoginModal: PropTypes.func,
};

export default MobileNav;
