import React, { useContext, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import LandingPageComponent from "../Components/LandingPage/LandingPageComponent";
import CreateReport from "../Components/CreateReport/CreateReport";
import AddContactModal from "../Components/Contacts/ContactsHeader/AddContactModal";
import { AppStateContext, AppDispatchContext } from "../Context/AppReducer";

const useStyles = makeStyles((theme) =>
  createStyles({
    landingPageViewContainer: {
      backgroundColor: "#fff",
      height: "100%",
    },
  })
);

const LandingPageView = ({ match, createReportOpen, googleScriptLoaded, setCreateReportOpen, setSavedReport, createContactOpen, setCreateContactOpen, setSubscribe }) => {
  const classes = useStyles();
  const user = match.params.user;
  const [getUserData, setGetUserData] = useState(false);

  const globalState = useContext(AppStateContext);
  const contactWasMade = globalState?.contactWasMade;
  return (
    <div className={classes.landingPageViewContainer}>
      {createReportOpen.open && (
        <CreateReport
          setGetUserData={setGetUserData}
          user={user}
          landingPageView={true}
          googleScriptLoaded={googleScriptLoaded}
          createReportOpen={createReportOpen}
          setCreateReportOpen={(status) => setCreateReportOpen(status)}
          createContactOpen={createContactOpen}
          setCreateContactOpen={(status) => setCreateContactOpen(status)}
          setSavedReport={(status) => setSavedReport(status)}
          setSubscribe={setSubscribe}
        />
      )}
      {createContactOpen.open && <AddContactModal landingPageView={true} createContactOpen={createContactOpen} setCreateContactOpen={(status) => setCreateContactOpen(status)} />}
      <LandingPageComponent
        setGetUserData={setGetUserData}
        getUserData={getUserData}
        setGetUserData={setGetUserData}
        user={user}
        createContactOpen={createContactOpen}
        setCreateReportOpen={(status) => setCreateReportOpen(status)}
      />
    </div>
  );
};

LandingPageView.propTypes = {
  match: PropTypes.object,
  setSubscribe: PropTypes.func,
  setCreateReportOpen: PropTypes.func,
  createReportOpen: PropTypes.object,
  googleScriptLoaded: PropTypes.bool,
  setSavedReport: PropTypes.func,
  createContactOpen: PropTypes.object,
  setCreateContactOpen: PropTypes.func,
};

export default LandingPageView;
