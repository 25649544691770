import { gql } from "@apollo/client";

export const GET_USER_DATA = gql`
  query User($id: Int!) {
    user(id: $id) {
      email
      username
      firstName
      lastName
      bio
      photoURL
      title
      licenseId
      phone
      id
      logoURL
      mlsLinks {
        mlsOfficeId
        mlsAgentId
        mlsId
      }
      links {
        icon
        isDefault
        isHidden
        title
        url
      }
      reportSettings {
        id
        themeColorSolid
        themeColorGradient
        categoryColorNew
        categoryColorActive
        categoryColorPending
        categoryColorSold
      }
      address {
        city
        country
        state
        street
        zip
      }
      subscriptions {
        id
        name
      }
      landingPageSettings {
        backgroundImage
        logoURL
        faviconUrl
        headline
        id
        subheadline
        templateChosen
        themeColorPrimary
        themeColorSecondary
      }
    }
  }
`;

export const GET_USER_BILLING_HISTORY = gql`
  query GetBillingHistory($userId: Int!, $limit: Int) {
    billingHistory(userId: $userId, limit: $limit) {
      amount
      createdAt
      id
    }
  }
`;

export const GET_USER_BILLING_INFO = gql`
  query GetBillingInfo($userId: Int!) {
    paymentMethods(userId: $userId) {
      paymentMethods {
        brand
        expYear
        expMonth
        last4
        id
        name
        email
        phone
      }
    }
  }
`;

export const GET_USER_PERSONAL_DETAILS = gql`
  query GetUserPersonalDetails($userId: Int!) {
    personalDetails(userId: $userId) {
      address {
        city
        country
        state
        street
        zip
      }
      email {
        email
      }
      firstName
      id
      lastName
      licenseId
      phone {
        number
      }
      title
    }
  }
`;
