import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, withStyles, Button, CircularProgress } from "@material-ui/core";
import { DELETE_USER } from "../../Services/Graph/Admin/Mutations";
import { useMutation } from "@apollo/client";
import { authApolloClient } from "../../Services/Graph/apolloConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    areYouSureModalContainer: {
      position: "fixed",
      height: "0%",
      width: "100%",
      top: "0px",
      left: "0px",
      background: "rgba(0, 0, 0, 0.7)",
      zIndex: "10",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      transition: "0.3s",
      height: "100%",
      visibility: "hidden",
      opacity: "0",
    },
    modalOpen: {
      visibility: "visible",
      opacity: "1",
    },
    areYouSureModalContentContainer: {
      borderRadius: "20px",
      background: "#FFFFFF",
      padding: "30px",
      maxWidth: "767px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    areYouSureText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      color: "#000",
      textTransform: "uppercase",
      letterSpacing: "0.195em",
    },
    areYouSureSubText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "18px",
      color: "#000",
      margin: "20px 0px",
      textAlign: "center",
    },
    subText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "18px",
      color: "#000",
      margin: "10px 0px 0px",
      textAlign: "center",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "40px",
    },
    loader: {
      width: "22px !important",
      height: "22px !important",
      color: " #FFFFFF",
      marginLeft: "10px",
    },
    userInfo: {
      fontFamily: "roboto",
      fontWeight: "400",
      fontSize: "20px",
    },
  })
);

const CloseButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    background: theme.palette.areaPulseErrorLight,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 30px",
    borderRadius: "7px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseErrorLight,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const CancelButton = withStyles((theme) => ({
  root: {
    color: "#C6C6C6",
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 10px",
    borderRadius: "7px",
    transition: "0.2s",
    textTransform: "lowercase",
    marginRight: "5px",
  },
}))(Button);

const DeleteUserConfirm = ({ setDeleteUp, deleteUp, deleteThisUser, setUserDeleted }) => {
  const classes = useStyles();
  const [userDeletedHere, setUserDeletedHere] = useState(false);
  const [deleteUser, { data: deleteData, loading: deleteLoading }] = useMutation(DELETE_USER, {
    variables: {
      userId: deleteThisUser.id,
    },
    client: authApolloClient,
  });
  const handleClose = () => {
    setUserDeletedHere(true);
    deleteUser();
  };
  const handleCancel = () => {
    setDeleteUp(false);
  };

  useEffect(() => {
    if (deleteData && !deleteLoading && userDeletedHere) {
      setUserDeletedHere(false);
      setDeleteUp(false);
      setUserDeleted(true);
    }
  }, [deleteData]);

  return (
    <div className={`${classes.areYouSureModalContainer} ${deleteUp && classes.modalOpen}`} onClick={() => handleCancel()}>
      <div className={classes.areYouSureModalContentContainer} onClick={(e) => e.stopPropagation()}>
        <h1 className={classes.areYouSureText}>Are You Sure?</h1>
        <h1 className={classes.areYouSureSubText}>Deleting this User will delete all their data, and cannot be returned.</h1>
        <div>
          <div className={classes.userInfo}>Name: {deleteThisUser.firstName + " " + deleteThisUser.lastName}</div>
          <div className={classes.userInfo}>Email: {deleteThisUser.email}</div>
        </div>
        <div className={classes.buttonContainer}>
          <CancelButton onClick={() => handleCancel()}>cancel</CancelButton>
          <CloseButton disabled={deleteLoading} onClick={() => handleClose()}>
            Delete
            {deleteLoading ? <CircularProgress className={classes.loader} /> : null}
          </CloseButton>
        </div>
      </div>
    </div>
  );
};

DeleteUserConfirm.propTypes = {
  setDeleteUp: PropTypes.func,
  setUserDeleted: PropTypes.func,
  deleteUp: PropTypes.bool,
  deleteThisUser: PropTypes.object,
};

export default DeleteUserConfirm;
