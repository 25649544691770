import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles, Grid, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) =>
  createStyles({
    usernameInput: {
      backgroundColor: "#FAFAFA",
      fontFamily: theme.typography.secondary,
      color: "#676767",
      fontSize: "18px",
      fontWeight: "600",
      borderRadius: "10px",
      border: "none",
      boxShadow: "inset 0px 0px 7px rgba(0, 0, 0, 0.08);",
    },
    paddingRight: {
      paddingRight: "10px",
    },
    designPreferencesContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
      width: "100%",
      position: "relative",
    },
    labelText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "bold",
      fontSize: "16px",
      color: "#000",
      margin: "15px 0px 15px 0px",
    },
    formContainer: {
      width: "100%",
    },
    url: {
      marginBottom: "0",
      fontFamily: theme.typography.secondary,
    },
    generatedLink: {
      overflowX: "scroll",
      margin: "0",
    },
    error: {
      color: "#f44336",
      margin: "3px 14px 0",
    },
  })
);

const UsernameEdit = ({ userData, updatedUsername, setUpdatedUsername, usernameError, savingLandingPageSettings, setSavingLandingPageSettings }) => {
  const classes = useStyles();
  const baseURL = window.location.host;

  useEffect(() => {
    if (userData?.user?.username) {
      setUpdatedUsername(userData?.user?.username);
    }
  }, [userData]);

  useEffect(() => {
    if (savingLandingPageSettings) {
      setSavingLandingPageSettings(false);
    }
  }, [savingLandingPageSettings, userData]);

  return (
    <div className={classes.designPreferencesContainer}>
      <h1 className={classes.labelText}>Username</h1>
      <form id="landing-page-username-form" className={classes.formContainer}>
        <Grid container>
          <Grid item xs={12} className={classes.paddingRight}>
            <TextField
              variant="outlined"
              className={classes.usernameInput}
              id="landing-page-username"
              placeholder="Username"
              fullWidth
              type="text"
              error={usernameError.status}
              value={updatedUsername}
              onChange={(e) => {
                setUpdatedUsername(e.target.value);
              }}
            />
            {usernameError.status ? (
              <div>
                <p className={classes.error}>Invalid Username</p>
              </div>
            ) : null}
            <h5 className={classes.url}>Generated URL</h5>
            <a href={`https://${baseURL}/${updatedUsername}`} target="_blank">
              <p className={classes.generatedLink}>
                https://{baseURL}/{updatedUsername}
              </p>
            </a>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

UsernameEdit.propTypes = {
  userData: PropTypes.object,
  updatedUsername: PropTypes.string,
  setUpdatedUsername: PropTypes.func,
  usernameError: PropTypes.object,
  savingLandingPageSettings: PropTypes.bool,
  setSavingLandingPageSettings: PropTypes.func,
};

export default UsernameEdit;
