import { gql } from "@apollo/client";

export const DELETE_CONTACT = gql`
  mutation deleteContact($id: Int!) {
    deleteContact(id: $id) {
      id
    }
  }
`;

export const ADD_CONTACT = gql`
  mutation AddContacts($parentPersonID: Int, $firstName: String!, $lastName: String!, $email: String!, $phone: String, $streetAddress: String, $city: String, $state: String, $zip: String) {
    createContact(parentPersonID: $parentPersonID, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, streetAddress: $streetAddress, city: $city, state: $state, zip: $zip) {
      firstName
      lastName
      email {
        email
      }
      address {
        city
        state
        street
        zip
      }
      phone {
        number
      }
      id
    }
  }
`;

export const ADD_CONTACT_OPEN = gql`
  mutation AddContactsOpen($username: String!, $firstName: String!, $lastName: String!, $email: String!, $phone: String, $streetAddress: String, $city: String, $state: String, $zip: String) {
    createContact(username: $username, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, streetAddress: $streetAddress, city: $city, state: $state, zip: $zip) {
      firstName
      lastName
      email {
        email
      }
      address {
        city
        state
        street
        zip
      }
      phone {
        number
      }
      id
    }
  }
`;
