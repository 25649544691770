import React, { useContext, useState, useEffect, Fragment } from "react";
import { AppStateContext, AppDispatchContext } from "../../../Context/AppReducer";
import { createStyles, makeStyles, withStyles, Button, Grid, Container, MenuItem, TextField, Backdrop } from "@material-ui/core";
import { ADD_CONTACT } from "../../../Services/Graph/Contacts/Mutations";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Clear } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    createContactBackdrop: {
      height: "100vh",
      width: "100%",
      zIndex: "8",
      padding: "35px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    createContactContentContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
      maxWidth: "800px",
      maxHeight: "600px",
      zIndex: "10",
      background: "#fff",
      borderRadius: "20px",
      overflow: "scroll",
    },
    createContactContainer: {
      height: "100%",
      width: "100%",
    },
    headerBar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "25px 35px",
    },
    headerBarTitle: {
      color: "black",
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      margin: "0px",
      width: "100%",
      textAlign: "center",
      marginLeft: "32px",
    },
    headerBarIcon: {
      color: "#282828",
      fontSize: "32px",
      cursor: "pointer",
    },
    createContactClose: {
      margin: "0px",
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "22px",
      color: "#fff",
      cursor: "pointer",
      transition: "0.2s",
      "&:hover": {
        opacity: "0.9",
        transition: "0.2s",
      },
    },
    createContactCloseDisabled: {
      cursor: "not-allowed",
      opacity: "0.5",
    },
    inputContainer: {
      padding: "40px 0px",
    },
    paper: {
      margin: "15px",
      padding: "30px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
    },
    contactHeaderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "30px",
    },
    editContactButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    editContactButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    closeModalButton: {
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      "&:hover": {},
    },
    closeModalButtonIcon: {
      color: "#282828",
      fontSize: "30px",
    },
    contactInput: {
      fontFamily: theme.typography.secondary,
      color: "#676767",
      fontSize: "18px",
      fontWeight: "600",
      borderRadius: "10px",
      border: "none",
    },
    saveButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "16px 80px",
      borderRadius: "10px",
      color: "#fff",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    saveButtonLabel: {
      fontFamily: theme.typography.secondary,
      color: "#fff",
      fontSize: "16px",
      fontWeight: "700",
      margin: 0,
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    contactInfoText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    modalClose: {
      position: "absolute",
      right: "30px",
    },
    modalContainer: {
      borderRadius: "10px",
    },
    modalActions: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "35px",
    },
    menuItem: {
      padding: "14px 20px",
    },
    menuItemIcon: {
      marginRight: "12px",
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 10,
        fontSize: 18,
      },
      borderRadius: "10px",
    },
    stateInput: {
      borderRadius: "10px",
    },
    input: {
      backgroundColor: "#F5F5F5",
      borderRadius: "10px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    "@media (max-width: 600px)": {
      inputContainer: {
        padding: "30px 0px",
      },
    },
  })
);

const ContactInfoSelect = withStyles((theme) => ({
  root: {
    width: "100%",
    "& label": {
      color: "#BDBDBD",
      fontWeight: "500",
      fontFamily: "nunito",
    },
    "& label.Mui-focused": {
      color: theme.palette.areaPulseBlue,
      fontWeight: "500",
      fontFamily: "nunito",
      display: "none",
      transform: "translate(14px, 5px) scale(0.75)",
    },
    "& .MuiFilledInput-root": {
      color: "#000",
      borderRadius: "10px",
      backgroundColor: "#F5F5F5",
      fontWeight: "400",
      fontSize: "1rem",
      border: "#bcbcbc 1px solid",
      "&.MuiFilledInput-underline:before": {
        display: "none",
      },
      "&.MuiFilledInput-underline:after": {
        display: "none",
      },
      "& .MuiSelect-filled": {
        padding: "17.5px 14px",
      },
    },
  },
}))(TextField);

const AddContactModal = ({ landingPageView, createContactOpen, setCreateContactOpen }) => {
  const classes = useStyles();
  const { user } = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);
  const [stateSelect, setStateSelect] = useState("State");
  const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
  const [stateSelectError, setStateSelectError] = useState({
    status: false,
    message: "",
  });
  const [formState, setFormState] = useState({});
  const backdropAnimations = {
    appear: 500,
    enter: 500,
    exit: 500,
  };
  const [addContact, { data: contactData, loading: contactLoading }] = useMutation(ADD_CONTACT, {
    variables: {
      parentPersonID: user?.personId ?? 0,
      firstName: formState.firstname,
      lastName: formState.lastname,
      email: formState.email,
      phone: formState.phone,
      streetAddress: formState.streetAddress,
      city: formState.city,
      state: formState.state,
      zip: formState.zip,
    },
    client: authApolloClient,
    update: (updateCache, mutationResult) => {
      globalDispatch({ type: "setLandingPageContact", payload: { ...mutationResult?.data?.createContact } });
    },
    onCompleted() {
      globalDispatch({ type: "setContactWasMade", payload: true });
    },
  });

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      streetAddress: "",
      zip: "",
      city: "",
    },
  });

  const submit = (data) => {
    if (stateSelect === "") {
      setStateSelectError({
        status: true,
        message: "Please enter your state",
      });
    } else {
      setStateSelectError({ status: false, message: "" });
    }
    if (stateSelect !== "") {
      setStateSelectError({
        status: false,
        message: "",
      });
      setFormState({ ...data, state: stateSelect });
      addContact();
      closeCreateMarketReport();
    }
  };

  const closeCreateMarketReport = () => {
    if (!closeButtonDisabled) {
      setCreateContactOpen({ open: false, editing: false, editPayload: {} });
      document.body.style.overflowY = "visible";
    }
  };

  useEffect(() => {
    if (createContactOpen.open) {
      document.body.style.overflowY = "hidden";
    }
  }, [createContactOpen]);

  return (
    <div>
      <Fragment>
        <Backdrop open={createContactOpen.open ?? false} onClick={(e) => e.stopPropagation()} transitionDuration={backdropAnimations} className={classes.createContactBackdrop}>
          <div onClick={(e) => e.stopPropagation()} className={classes.createContactContentContainer}>
            <div className={classes.headerBar}>
              <h1 className={classes.headerBarTitle}>{landingPageView ? "Register To See Your Report" : "Create New Contact"}</h1>
              {!landingPageView ? <Clear onClick={() => closeCreateMarketReport()} className={`${classes.headerBarIcon} ${closeButtonDisabled && classes.createContactCloseDisabled}`} /> : null}
            </div>
            <div className={`${classes.createContactContainer}`}>
              <div>
                <form id="add-contact-form" onSubmit={handleSubmit(submit)}>
                  <Container>
                    <div className={classes.inputContainer}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            variant="outlined"
                            className={classes.contactInput}
                            id="contact-first-name"
                            placeholder="First Name"
                            fullWidth
                            error={Boolean(errors.firstname)}
                            helperText={errors.firstname?.message}
                            InputProps={{
                              name: "firstname",
                              className: classes.input,
                            }}
                            inputRef={register({
                              validate: (value) => value !== "" || "Please enter your first name",
                            })}
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            variant="outlined"
                            className={classes.contactInput}
                            id="contact-last-name"
                            placeholder="Last Name"
                            fullWidth
                            error={Boolean(errors.lastname)}
                            helperText={errors.lastname?.message}
                            InputProps={{
                              name: "lastname",
                              className: classes.input,
                            }}
                            inputRef={register({
                              validate: (value) => value !== "" || "Please enter your last name",
                            })}
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            variant="outlined"
                            className={classes.contactInput}
                            id="contact-phone-number"
                            placeholder="Phone Number"
                            fullWidth
                            error={Boolean(errors.phone)}
                            helperText={errors.phone?.message}
                            InputProps={{
                              name: "phone",
                              className: classes.input,
                            }}
                            inputRef={register({
                              pattern: {
                                value: /^\d{10}$/i,
                                message: "Please Enter A Valid 10 digit phone number",
                              },
                            })}
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            variant="outlined"
                            className={classes.contactInput}
                            id="contact-email"
                            placeholder="Email"
                            fullWidth
                            error={Boolean(errors.email)}
                            helperText={errors.email?.message}
                            InputProps={{
                              name: "email",
                              className: classes.input,
                            }}
                            inputRef={register({
                              validate: (value) => value !== "" || "Please enter your email address",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Please Enter A Valid Email Address",
                              },
                            })}
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            variant="outlined"
                            className={classes.contactInput}
                            id="contact-street-address"
                            placeholder="Home Address"
                            fullWidth
                            error={Boolean(errors.streetAddress)}
                            helperText={errors.streetAddress?.message}
                            InputProps={{
                              name: "streetAddress",
                              className: classes.input,
                            }}
                            inputRef={register()}
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            variant="outlined"
                            className={classes.contactInput}
                            id="contact-city"
                            placeholder="City"
                            fullWidth
                            error={Boolean(errors.city)}
                            helperText={errors.city?.message}
                            InputProps={{
                              name: "city",
                              className: classes.input,
                            }}
                            inputRef={register()}
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={6} sm={3} md={3}>
                          <ContactInfoSelect
                            select
                            variant="filled"
                            className={classes.stateSelect}
                            error={stateSelectError.status}
                            helperText={stateSelectError.message}
                            value={stateSelect}
                            onChange={(e) => setStateSelect(e.target.value)}
                          >
                            <MenuItem value={"State"}>State</MenuItem>
                            <MenuItem value={"AL"}>Alabama</MenuItem>
                            <MenuItem value={"AK"}>Alaska</MenuItem>
                            <MenuItem value={"AZ"}>Arizona</MenuItem>
                            <MenuItem value={"AR"}>Arkansas</MenuItem>
                            <MenuItem value={"CA"}>California</MenuItem>
                            <MenuItem value={"CO"}>Colorado</MenuItem>
                            <MenuItem value={"CT"}>Connecticut</MenuItem>
                            <MenuItem value={"DE"}>Delaware</MenuItem>
                            <MenuItem value={"FL"}>Florida</MenuItem>
                            <MenuItem value={"GA"}>Georgia</MenuItem>
                            <MenuItem value={"HI"}>Hawaii</MenuItem>
                            <MenuItem value={"ID"}>Idaho</MenuItem>
                            <MenuItem value={"IL"}>Illinois</MenuItem>
                            <MenuItem value={"IN"}>Indiana</MenuItem>
                            <MenuItem value={"IA"}>Iowa</MenuItem>
                            <MenuItem value={"KS"}>Kansas</MenuItem>
                            <MenuItem value={"KY"}>Kentucky</MenuItem>
                            <MenuItem value={"LA"}>Louisiana</MenuItem>
                            <MenuItem value={"ME"}>Maine</MenuItem>
                            <MenuItem value={"MD"}>Maryland</MenuItem>
                            <MenuItem value={"MA"}>Massachusetts</MenuItem>
                            <MenuItem value={"MI"}>Michigan</MenuItem>
                            <MenuItem value={"MN"}>Minnesota</MenuItem>
                            <MenuItem value={"MS"}>Mississippi</MenuItem>
                            <MenuItem value={"MO"}>Missouri</MenuItem>
                            <MenuItem value={"MT"}>Montana</MenuItem>
                            <MenuItem value={"NE"}>Nebraska</MenuItem>
                            <MenuItem value={"NV"}>Nevada</MenuItem>
                            <MenuItem value={"NH"}>New Hampshire</MenuItem>
                            <MenuItem value={"NJ"}>New Jersey</MenuItem>
                            <MenuItem value={"NM"}>New Mexico</MenuItem>
                            <MenuItem value={"NY"}>New York</MenuItem>
                            <MenuItem value={"NC"}>North Carolina</MenuItem>
                            <MenuItem value={"ND"}>North Dakota</MenuItem>
                            <MenuItem value={"OH"}>Ohio</MenuItem>
                            <MenuItem value={"OK"}>Oklahoma</MenuItem>
                            <MenuItem value={"OR"}>Oregon</MenuItem>
                            <MenuItem value={"PA"}>Pennsylvania</MenuItem>
                            <MenuItem value={"RI"}>Rhode Island</MenuItem>
                            <MenuItem value={"SC"}>South Carolina</MenuItem>
                            <MenuItem value={"SD"}>South Dakota</MenuItem>
                            <MenuItem value={"TN"}>Tennessee</MenuItem>
                            <MenuItem value={"TX"}>Texas</MenuItem>
                            <MenuItem value={"UT"}>Utah</MenuItem>
                            <MenuItem value={"VT"}>Vermont</MenuItem>
                            <MenuItem value={"VA"}>Virginia</MenuItem>
                            <MenuItem value={"WA"}>Washington</MenuItem>
                            <MenuItem value={"WV"}>West Virginia</MenuItem>
                            <MenuItem value={"WI"}>Wisconson</MenuItem>
                            <MenuItem value={"WY"}>Wyoming</MenuItem>
                          </ContactInfoSelect>
                        </Grid>
                        <Grid item xs={6} sm={3} md={3}>
                          <TextField
                            variant="outlined"
                            className={classes.contactInput}
                            id="contact-zip"
                            placeholder="Zip"
                            fullWidth
                            error={Boolean(errors.zip)}
                            helperText={errors.zip?.message}
                            InputProps={{
                              name: "zip",
                              className: classes.input,
                            }}
                            inputRef={register()}
                            type="text"
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.modalActions}>
                      <Button type="submit" form="add-contact-form" className={classes.saveButton}>
                        <h1 className={classes.saveButtonLabel}>Save</h1>
                      </Button>
                    </div>
                  </Container>
                </form>
              </div>
            </div>
          </div>
        </Backdrop>
      </Fragment>
    </div>
  );
};

AddContactModal.propTypes = {
  landingPageView: PropTypes.bool,
  createContactOpen: PropTypes.object,
  setCreateContactOpen: PropTypes.func,
};

export default AddContactModal;
