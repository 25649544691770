import React from "react";
import { createStyles, makeStyles, Grid, TextField, Fade } from "@material-ui/core";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    contactInput: {
      backgroundColor: "#fff",
      fontFamily: theme.typography.secondary,
      color: "#676767",
      fontSize: "18px",
      fontWeight: "600",
      borderRadius: "10px",
      border: "none",
    },
    accountSettingsInfoText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    usernameLabel: {
      marginRight: "30px",
    },
    usernameInputContainer: {
      width: "300px",
    },
    infoText: {
      width: "100%",
    },
    error: {
      color: "red",
      marginLeft: "10px",
      marginTop: "10px",
      fontFamily: "nunito",
    },
  })
);

const UserSettingsAccountLoginDetailsInputs = ({ userError, userLoginInfo, setUpdatedUserLoginInfo, setUpdateWasMade, openUpdatingLoginInputs, setTryingToUpdateUsername }) => {
  const classes = useStyles();

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      username: userLoginInfo?.username ?? "",
    },
  });

  const submit = (data) => {
    setTryingToUpdateUsername(true);
    setUpdatedUserLoginInfo({ ...data });
    setUpdateWasMade(true);
  };

  return (
    <div className={classes.infoText}>
      <Fade in={openUpdatingLoginInputs} timeout={500}>
        <form id="user-account-login-form" onSubmit={handleSubmit(submit)}>
          <Grid container alignItems="center">
            <Grid item className={classes.usernameLabel}>
              <h2 className={classes.accountSettingsInfoText}>username:</h2>
            </Grid>
            <Grid item className={classes.usernameInputContainer}>
              <TextField
                variant="outlined"
                className={classes.contactInput}
                id="user-username"
                placeholder="Username"
                fullWidth
                required
                type="text"
                error={Boolean(errors.username)}
                helperText={errors.username?.message}
                InputProps={{
                  name: "username",
                }}
                inputRef={register({
                  validate: (value) =>
                    (value !== "" && value !== "home" && value !== "404" && value !== "reset-password" && value !== "privacy-policy" && value !== "reset-password") || "invalid username",
                })}
              />
              {userError ? <div className={classes.error}>Invalid Username</div> : null}
            </Grid>
          </Grid>
        </form>
      </Fade>
    </div>
  );
};

UserSettingsAccountLoginDetailsInputs.propTypes = {
  userLoginInfo: PropTypes.object,
  setUpdatedUserLoginInfo: PropTypes.func,
  setTryingToUpdateUsername: PropTypes.func,
  setUpdateWasMade: PropTypes.func,
  openUpdatingLoginInputs: PropTypes.bool,
  userError: PropTypes.object,
};

export default UserSettingsAccountLoginDetailsInputs;
