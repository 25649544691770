import React, { useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, withStyles, Backdrop, Button } from "@material-ui/core";
import { Close, Publish } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    imageUploadBackdrop: {
      zIndex: "10",
    },
    imageUploadContainer: {
      maxWidth: "767px",
      width: "100%",
      background: "#fff",
      borderRadius: "20px",
      padding: "30px",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    exitButton: {
      position: "absolute",
      right: "30px",
      top: "30px",
      fontSize: "30px",
      color: "#333",
      transition: "0.2s",
      cursor: "pointer",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    imageUploadHeader: {
      fontFamily: theme.typography.secondary,
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000",
      margin: "0px 0px 10px 0px",
    },
    imageUploadTip: {
      fontFamily: theme.typography.secondary,
      fontSize: "16px",
      fontWeight: "bold",
      color: "#E23A00",
      margin: "0px 0px 40px 0px",
    },
    imagesContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    imagesLabelContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: "40px",
    },
    labeltext: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "16px",
      color: "#000",
      margin: "15px 0px 0px 0px",
    },
    imageContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "195px",
      width: "195px",
      borderRadius: "50%",
      overflow: "hidden",
      cursor: "pointer",
      margin: "0px 40px 0px 40px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0008), 0px 4px 8px rgba(0, 0, 0, 0.12)",
      border: "1px solid #CCCCCC",
      background: "#BDBDBD",
    },
    img: {
      width: "100%",
    },
    fallbackContainer: {
      height: "100%",
      width: "100%",
      background: "#BDBDBD",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    fallbackText: {
      fontFamily: theme.typography.default,
      margin: "0px",
      fontSize: "40px",
      color: "#fff",
      fontWeight: "600",
    },
    hoverIconContainer: {
      height: "0%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#00000050",
      opacity: "0",
      transition: "opacity 0.2s",
      position: "absolute",
    },
    active: {
      height: "100%",
      opacity: "1",
      transition: "opacity 0.2s",
    },
    hoverIcon: {
      fontSize: "75px",
      color: "#fff",
    },
  })
);

const SaveButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    fontFamily: theme.typography.secondary,
    fontWeight: "400",
    fontSize: "16px",
    background: theme.palette.areaPulseBlue,
    padding: "15px 75px",
    borderRadius: "10px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    transition: "0.2s",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      opacity: "0.8",
      transition: "0.2s",
    },
  },
}))(Button);

const ImageUpload = ({ firstInitial, imageUpload, setImageUpload, profileImage, setProfileImage, logoImage, setLogoImage, origionalProfileImage, origionalLogoImage }) => {
  const classes = useStyles();
  const profileImageInput = useRef(null);
  const logoImageInput = useRef(null);
  const [profImgHov, onProfImgHov] = useState(false);
  const [logoImgHov, setLogoImgHov] = useState(false);

  const handleProfileUpload = () => {
    profileImageInput.current.click();
  };
  const handleProfileSubmit = (e) => {
    const file = e.target.files[0];
    if (file !== undefined) {
      setProfileImage({
        preview: URL.createObjectURL(file),
        file: file,
      });
    }
  };

  const handleLogoUpload = () => {
    logoImageInput.current.click();
  };
  const handleLogoSubmit = (e) => {
    const file = e.target.files[0];
    if (file !== undefined) {
      setLogoImage({
        preview: URL.createObjectURL(file),
        file: file,
      });
    }
  };

  const handleUploadClose = () => {
    setImageUpload(false);
    setProfileImage({
      preview: origionalProfileImage,
      file: "",
    });
    setLogoImage({
      preview: origionalLogoImage,
      file: "",
    });
  };

  return (
    <Backdrop open={imageUpload} className={classes.imageUploadBackdrop} onClick={() => handleUploadClose()}>
      <div className={classes.imageUploadContainer} onClick={(e) => e.stopPropagation()}>
        <Close fontSize="inherit" color="inherit" className={classes.exitButton} onClick={() => handleUploadClose()} />
        <h1 className={classes.imageUploadHeader}>Upload Profile and Logo Image</h1>
        <h1 className={classes.imageUploadTip}>Please use square transparent images.</h1>
        <div className={classes.imagesContainer}>
          <div className={classes.imagesLabelContainer}>
            <div className={classes.imageContainer} onClick={() => handleProfileUpload()} onMouseEnter={() => onProfImgHov(true)} onMouseLeave={() => onProfImgHov(false)}>
              {profileImage.preview !== "" ? (
                <Fragment>
                  <img alt="publish" src={profileImage.preview} className={classes.img} />
                  <div className={`${classes.hoverIconContainer} ${profImgHov && classes.active}`}>
                    <Publish fontSize="inherit" color="inherit" className={classes.hoverIcon} />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className={classes.fallbackContainer}>
                    <h1 className={classes.fallbackText}>{firstInitial}</h1>
                  </div>
                  <div className={`${classes.hoverIconContainer} ${profImgHov && classes.active}`}>
                    <Publish fontSize="inherit" color="inherit" className={classes.hoverIcon} />
                  </div>
                </Fragment>
              )}
              <input
                type="file"
                ref={profileImageInput}
                onChange={(e) => {
                  handleProfileSubmit(e);
                  e.target.value = null;
                }}
                style={{ display: "none" }}
                accept="image/png, image/jpeg"
              />
            </div>
            <h1 className={classes.labeltext}>Profile</h1>
          </div>
          <div className={classes.imagesLabelContainer}>
            <div className={classes.imageContainer} onClick={() => handleLogoUpload()} onMouseEnter={() => setLogoImgHov(true)} onMouseLeave={() => setLogoImgHov(false)}>
              {logoImage.preview !== "" ? (
                <Fragment>
                  <img alt="publish" src={logoImage.preview} className={classes.img} />
                  <div className={`${classes.hoverIconContainer} ${logoImgHov && classes.active}`}>
                    <Publish fontSize="inherit" color="inherit" className={classes.hoverIcon} />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className={classes.fallbackContainer}>
                    <h1 className={classes.fallbackText}>{firstInitial}</h1>
                  </div>
                  <div className={`${classes.hoverIconContainer} ${logoImgHov && classes.active}`}>
                    <Publish fontSize="inherit" color="inherit" className={classes.hoverIcon} />
                  </div>
                </Fragment>
              )}
              <input
                type="file"
                ref={logoImageInput}
                onChange={(e) => {
                  handleLogoSubmit(e);
                  e.target.value = null;
                }}
                style={{ display: "none" }}
                accept="image/png, image/jpeg"
              />
            </div>
            <h1 className={classes.labeltext}>Logo</h1>
          </div>
        </div>
        <SaveButton onClick={() => setImageUpload(false)}>SAVE</SaveButton>
      </div>
    </Backdrop>
  );
};

ImageUpload.propTypes = {
  imageUpload: PropTypes.bool,
  setImageUpload: PropTypes.func,
  profileImage: PropTypes.any,
  setProfileImage: PropTypes.func,
  logoImage: PropTypes.any,
  setLogoImage: PropTypes.func,
  origionalProfileImage: PropTypes.string,
  origionalLogoImage: PropTypes.string,
};

export default ImageUpload;
