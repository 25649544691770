import React, { useState, useContext } from "react";
import { makeStyles, createStyles, Grid } from "@material-ui/core";
import { AppStateContext } from "../../Context/AppReducer";
import { useQuery } from "@apollo/client";
import { authApolloClient } from "../../Services/Graph/apolloConfig";
import { GET_REPORTS, GET_USER_STEP } from "../../Services/Graph/UserPreferences/Queries";
import PreferencesHeader from "./PreferencesHeader/PreferencesHeader";
import PreferencesAccordion from "./PreferencesAccordion/PreferencesAccordion";
import SaveUserPrefs from "./SaveUserPrefs";
import ReportPreview from "./ReportPreview/ReportPreview";
import NewUserPopUp from "./NewUserPopUp";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    userPreferencesContainer: {
      width: "100%",
      minHeight: "100vh",
      padding: "60px 80px 60px 180px",
      background: "#fff",
    },
    disableDiv: {
      zIndex: "10",
      position: "absolute",
      left: "0",
      top: "0",
      backgroundColor: "transparent",
      width: "200px",
      height: "100%",
    },
    widerDisable: {
      width: "200px",
    },
  })
);

const UserPreferencesComponent = (user) => {
  const classes = useStyles();
  const globalState = useContext(AppStateContext);
  const [savingUserPrefs, setSavingUserPrefs] = useState(false);
  const [iFrameLoading, setiFrameLoading] = useState(false);
  const [wideDisable, setWideDisable] = useState(false);

  const { data: reportData } = useQuery(GET_REPORTS, {
    variables: {
      pageSize: 1,
      page: 0,
      sortField: "dateCreated",
      sortDirection: "desc",
    },
    client: authApolloClient,
  });

  const { data: userData } = useQuery(GET_USER_STEP, {
    variables: {
      userId: globalState?.user?.userId,
    },
    client: authApolloClient,
    fetchPolicy: "network-only",
  });

  return (
    <div className={classes.userPreferencesContainer}>
      <PreferencesHeader savingUserPrefs={savingUserPrefs} iFrameLoading={iFrameLoading} setSavingUserPrefs={(status) => setSavingUserPrefs(status)} />
      <Grid container>
        <Grid item xs={5}>
          <PreferencesAccordion savingUserPrefs={savingUserPrefs} setSavingUserPrefs={(status) => setSavingUserPrefs(status)} />
          <SaveUserPrefs savingUserPrefs={savingUserPrefs} iFrameLoading={iFrameLoading} setSavingUserPrefs={(status) => setSavingUserPrefs(status)} />
        </Grid>
        <Grid item xs={7}>
          <ReportPreview
            userName={globalState.user.username}
            reportId={reportData?.reports[0]?.id}
            savingUserPrefs={savingUserPrefs}
            iFrameLoading={iFrameLoading}
            setiFrameLoading={(status) => setiFrameLoading(status)}
          />
        </Grid>
      </Grid>
      <NewUserPopUp userName={user} user={globalState?.user?.userId} firstOpen={userData?.userOnboardingStep?.step} />
      {iFrameLoading ? (
        <div
          onMouseLeave={() => {
            setWideDisable(false);
          }}
          onMouseEnter={() => {
            setWideDisable(true);
          }}
          className={`${classes.disableDiv} ${wideDisable ? classes.widerDisable : null}`}
        ></div>
      ) : null}
    </div>
  );
};
UserPreferencesComponent.propTypes = {
  user: PropTypes.string,
};
export default UserPreferencesComponent;
