import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { AppStateContext, AppDispatchContext } from "../../../Context/AppReducer";
import { GET_CONTACT } from "../../../Services/Graph/Contact/Queries";
import { DELETE_CONTACT } from "../../../Services/Graph/Contact/Mutations";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { useQuery } from "@apollo/client";
import { createStyles, makeStyles, Grid, Button, withStyles } from "@material-ui/core";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { CloseRounded } from "@material-ui/icons";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    deleteContactContainer: {
      paddingTop: "40px",
    },
    deleteContactButtonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      marginBottom: "25px",
    },
    deleteContactButton: {
      minWidth: "0px",
      padding: "9px 20px",
      margin: "20px",
      borderRadius: "10px",
      "&:hover": {},
    },
    deleteContactButtonLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "18px",
      color: theme.palette.areaPulseRed,
      margin: "0",
      textTransform: "capitalize",
    },
    deleteContactButtonIcon: {
      color: theme.palette.areaPulseRed,
      fontSize: "24px",
      marginRight: "12px",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "5px",
    },
    error: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginBottom: "8px",
      marginLeft: "10px",
    },
    paper: {
      margin: "15px",
      padding: "30px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
    },
    contactHeaderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "30px",
    },
    editContactButton: {
      background: theme.palette.areaPulseBlue,
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    editContactButtonIcon: {
      color: "#fff",
      fontSize: "24px",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    areYouSureSubText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    deleteContactModalTextContainer: {
      display: "flex",
      justifyContent: "center",
    },
    modalActions: {
      display: "flex",
      justifyContent: "center",
      marginTop: "40px",
      marginBottom: "35px",
    },
    personalDetailsForm: {
      width: "100%",
    },
    menuItem: {
      padding: "14px 20px",
    },
    menuItemIcon: {
      marginRight: "12px",
    },
    modalClose: {
      position: "absolute",
      right: "30px",
    },
    closeModalButton: {
      minWidth: "0px",
      padding: "8px",
      borderRadius: "10px",
      "&:hover": {},
    },
    closeModalButtonIcon: {
      color: "#282828",
      fontSize: "30px",
    },
    cancelButton: {
      background: "transparent",
      minWidth: "0px",
      padding: "16px 80px",
      borderRadius: "10px",
      color: "#8D8989",
      "&:hover": {
        background: "transparent",
      },
      marginRight: "10px",
    },
    cancelButtonLabel: {
      fontFamily: theme.typography.secondary,
      color: "#8D8989",
      fontSize: "16px",
      fontWeight: "700",
      margin: 0,
    },
    deleteButton: {
      background: theme.palette.areaPulseError,
      minWidth: "0px",
      padding: "16px 80px",
      borderRadius: "10px",
      color: "#fff",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      "&:hover": {
        background: theme.palette.areaPulseError,
      },
      marginLeft: "10px",
    },
    deleteButtonLabel: {
      fontFamily: theme.typography.secondary,
      color: "#fff",
      fontSize: "16px",
      fontWeight: "700",
      margin: 0,
    },
  })
);

const Dialog = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialog);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "0 30px 30px 30px",
  },
}))(MuiDialogContent);

const DeleteContact = ({ contactId, history }) => {
  const classes = useStyles();
  const { user } = useContext(AppStateContext);
  const globalDispatch = useContext(AppDispatchContext);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const handleLinktoContacts = (event) => {
    event.stopPropagation();
    deleteContact();
    handleClose();
    history.push(`/${user?.username}/contacts/`);
  };

  // QUERIES
  const { data: contactData } = useQuery(GET_CONTACT, {
    variables: {
      id: contactId ?? 0,
    },
    client: authApolloClient,
  });

  const [deleteContact] = useMutation(DELETE_CONTACT, {
    variables: {
      id: contactId ?? 0,
    },
    client: authApolloClient,
    onCompleted() {
      globalDispatch({ type: "setContactWasDeleted", payload: true });
    },
  });

  const contactInfo = contactData?.contact ?? {};

  return (
    <Grid container justify="center">
      <Button className={classes.deleteContactButton} onClick={handleClickOpen}>
        <DeleteOutlineOutlinedIcon color="inherit" fontSize="inherit" className={classes.deleteContactButtonIcon} />
        <h1 className={classes.deleteContactButtonLabel}>
          Delete {contactInfo.firstName} {contactInfo.lastName}
        </h1>
      </Button>
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: "800px",
          },
        }}
        onClose={handleClose}
        aria-labelledby="add-contact-modal"
        open={open}
      >
        <div className={classes.contactHeaderContainer}>
          <h1 className={classes.paperLabel}>Delete Contact</h1>
          <div className={classes.modalClose}>
            <Button className={classes.closeModalButton} onClick={handleClose}>
              <CloseRounded color="inherit" fontSize="inherit" className={classes.closeModalButtonIcon} />
            </Button>
          </div>
        </div>

        <DialogContent>
          <div className={classes.deleteContactModalTextContainer}>
            <h1 className={classes.areYouSureSubText}>
              Are you sure you want to delete {contactInfo.firstName} {contactInfo.lastName}?
            </h1>
          </div>
        </DialogContent>
        <div className={classes.modalActions}>
          <Button onClick={handleClose} className={classes.cancelButton}>
            <h1 className={classes.cancelButtonLabel}>Cancel</h1>
          </Button>
          <Button onClick={handleLinktoContacts} className={classes.deleteButton}>
            <h1 className={classes.deleteButtonLabel}>Delete</h1>
          </Button>
        </div>
      </Dialog>
    </Grid>
  );
};

DeleteContact.propTypes = {
  history: PropTypes.object,
  contactId: PropTypes.string,
};

export default withRouter(DeleteContact);
