import React, { useState } from "react";
import HomeStylesheet from "../HomeStylesheet";
import { Element } from "react-scroll";
import { Grid, Button, withStyles } from "@material-ui/core";

const GoButton = withStyles((theme) => ({
  root: {
    textTransform: "none",
    width: "619px",
    height: "92px",
    backgroundColor: "#FFFFFF",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontFamily: "Nunito",
    fontSize: "24px",
    fontWeight: "400",
    marginBottom: "32px",
    "&:hover": {
      color: "#FFFFFF",
      background: "#0079B4",
      transition: "0.2s",
      opacity: "0.8",
    },
    "@media (max-width: 905px)": {
      width: "339px",
      fontSize: "18px",
    },
  },
}))(Button);

const SampleButton = withStyles((theme) => ({
  root: {
    width: "269px",
    height: "47px",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Nunito",
    fontSize: "14px",
    fontWeight: "700",
    marginTop: "34px",
    marginBottom: "47px",
    "&:hover": {
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const About = () => {
  const classes = HomeStylesheet();
  const [currentLook, setCurrentLook] = useState(0);
  const returnCircleClass = (x) => {
    let currentClass = classes.circlePulsePosition1;
    if (x === 1) {
      currentClass = classes.circlePulsePosition1;
    } else if (x === 2) {
      currentClass = classes.circlePulsePosition2;
    } else if (x === 3) {
      currentClass = classes.circlePulsePosition3;
    } else if (x === 4) {
      currentClass = classes.circlePulsePosition4;
    } else if (x === 5) {
      currentClass = classes.circlePulsePosition5;
    } else if (x === 6) {
      currentClass = classes.circlePulsePosition6;
    }

    return currentClass;
  };
  const checkTop = (whichPoint) => {
    let dotPlace = "0";
    if (whichPoint === 1) {
      dotPlace = "0";
    } else if (whichPoint === 2) {
      dotPlace = "-500";
    } else if (whichPoint === 3) {
      dotPlace = "-155";
    } else if (whichPoint === 4) {
      dotPlace = "-355";
    } else if (whichPoint === 5) {
      dotPlace = "-795";
    } else if (whichPoint === 6) {
      dotPlace = "-1285";
    }
    return dotPlace;
  };
  return (
    <Element name="about" className={classes.aboutSection}>
      <div className={classes.aboutBackground}></div>
      <div className={classes.aboutBackgroundCurve}></div>

      <Grid container className={classes.aboutSectionContentContainer}>
        <Grid item xs={12}>
          <div className={`wow fadeIn `}>
            <h2 className={`wow ${classes.fadeInLeft} ${classes.aboutHeader}`}>Making Your Data Look Good</h2>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <div className={classes.aboutTextPadding}>
            <div className={classes.phoneContainer}>
              <img className={classes.outerPhone} alt="phone" src="/assets/new-areapulse-lp-transparentphone.png" />
              <div className={classes.reportParent}>
                <div className={`${classes.circlePulse} ${returnCircleClass(currentLook)}`}>
                  <div className={classes.whiteOuter}>
                    <div className={"blueInnerBlob"}></div>
                  </div>
                </div>
                <div className={classes.changeParent} style={{ top: checkTop(currentLook) + "px" }}>
                  <div className={classes.redTop}></div>
                  <img className={classes.innerPhone} alt="mobile" src="/assets/new-mobilemock.png" />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <ul className={`${classes.aboutList} ${classes.aboutPhotoContainer}`}>
            <li className={`wow ${classes.fadeInUp} ${classes.aboutListItem}`}>
              <GoButton
                onClick={() => {
                  setCurrentLook(1);
                }}
              >
                <img className={classes.listIcons} alt="personal" src="/assets/Group-4.png" />
                Personal Branding
              </GoButton>
            </li>
            <li className={`wow ${classes.fadeInUp} ${classes.aboutListItem}`}>
              <GoButton
                onClick={() => {
                  setCurrentLook(2);
                }}
              >
                <img className={classes.listIcons} alt="market status" src="/assets/Group-5.png" />
                Current Market Status
              </GoButton>
            </li>
            <li className={`wow ${classes.fadeInUp} ${classes.aboutListItem}`}>
              <GoButton
                onClick={() => {
                  setCurrentLook(3);
                }}
              >
                <img className={classes.listIcons} alt="month trends" src="/assets/Group-6.png" />6 Month Price Trends
              </GoButton>
            </li>
            <li className={`wow ${classes.fadeInUp} ${classes.aboutListItem}`}>
              <GoButton
                onClick={() => {
                  setCurrentLook(4);
                }}
              >
                <img className={classes.listIcons} alt="inventory" src="/assets/Group-3.png" />
                Home Inventory
              </GoButton>
            </li>
            <li className={`wow ${classes.fadeInUp} ${classes.aboutListItem}`}>
              <GoButton
                onClick={() => {
                  setCurrentLook(5);
                }}
              >
                <img className={classes.listIcons} alt="averages" src="/assets/Group-8.png" />
                Prive and DOM Averages
              </GoButton>
            </li>
            <li className={`wow ${classes.fadeInUp} ${classes.aboutListItem}`}>
              <GoButton
                onClick={() => {
                  setCurrentLook(6);
                }}
              >
                <img className={classes.listIcons} alt="active and sold" src="/assets/Group-7.png" />
                Active, Sold, and Pending Properties
              </GoButton>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.aboutButtonContainer}>
            <a className={`wow ${classes.fadeInUp} ${classes.aboutButtonLink}`} href="http://areapulse.com/jarad/market-report/YBVGZY" target="_blank" rel="noopener noreferrer">
              <SampleButton className={classes.aboutButton}>Check Out A Sample Report</SampleButton>
            </a>
          </div>
          <p className={`wow ${classes.fadeInLeft} ${classes.aboutDescriptions}`}>
            Pulse reports are designed to make sure your clients are <strong>ONLY</strong> getting the data they
            <br /> care about the most.
          </p>
          <p className={`wow ${classes.fadeInLeft} ${classes.aboutDescriptions}`}>
            With easy-to-understand charts and graphs that help your clients understand the <br /> real estate market where they live work and play.
          </p>
        </Grid>
      </Grid>
    </Element>
  );
};

About.propTypes = {};

export default About;
