import { makeStyles, createStyles } from '@material-ui/core';

const FooterStylesheet = makeStyles(theme => createStyles({
  footerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "60px 0px"
  },
  footerImage: {
    height: "35px",
    marginBottom: "10px"
  },
  rightsReserved: {
    fontSize: "14px",
    color: "#aaa",
    margin: "0px",
    fontFamily: "Nunito"
  }
}));

export default FooterStylesheet;