import { gql } from "@apollo/client";

export const UPDATE_USER_PERSONAL_INFO = gql`
  mutation UpdateUserPersonalDetails($title: String!, $licenseId: String!, $email: String!, $phone: String!, $state: String!, $userId: Int!, $firstName: String!, $lastName: String!, $street: String!, $city: String!, $zip: String!) {
    updatePersonalDetails(title: $title, licenseId: $licenseId, email: $email, phone: $phone, state: $state, userId: $userId, firstName: $firstName, lastName: $lastName, street: $street, city: $city, zip: $zip) {
      title
      licenseId
      email {
        email
      }
      phone {
        number
      }
      address {
        city
        state
        street
        zip
      }
      firstName
      lastName
      id
    }
  }
`;
export const UPDATE_USER = gql`
  mutation UpdateUserAccountDetails($email: String!, $username: String!, $firstName: String!, $lastName: String!, $title: String!, $phone: String!, $licenseId: String!, $userId: Int!, $state: String, $streetAddress: String, $city: String, $zip: String) {
    updateUser(email: $email, username: $username, firstName: $firstName, lastName: $lastName, title: $title, phone: $phone, licenseId: $licenseId, userId: $userId, state: $state, streetAddress: $streetAddress, city: $city, zip: $zip) {
      username
      title
      licenseId
      email
      phone
      firstName
      lastName
      id
      address {
        street
        city
        state
        zip
        country
      }
    }
  }
`;

export const DELETE_PAYMENT_METHOD = gql`
  mutation detachPaymentMethod($userId: Int!, $paymentMethodId: String!) {
    detachPaymentMethod(userId: $userId, paymentMethodId: $paymentMethodId) {
      id
    }
  }
`;

export const ATTACH_PAYMENT_METHOD = gql`
  mutation attachPaymentMethod($userId: Int!, $paymentMethodId: String!) {
    attachPaymentMethod(userId: $userId, paymentMethodId: $paymentMethodId) {
      id
    }
  }
`;
