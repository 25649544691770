import React, { useState, useContext, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { AppStateContext } from "../../../Context/AppReducer";
import { makeStyles, createStyles, Button } from "@material-ui/core";
import ColorPicker from "./ColorPicker";

const useStyles = makeStyles((theme) =>
  createStyles({
    designPreferencesContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
      width: "100%",
      position: "relative",
    },
    resetButton: {
      marginTop: "10px",
      marginBottom: "20px",
      background: theme.palette.areaPulseBlue,
      color: "#fff",
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.45)",
      padding: "10px 14px",
      "&:hover": {
        background: theme.palette.areaPulseBlue,
      },
    },
    labelText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "bold",
      fontSize: "16px",
      color: "#000",
      margin: "15px 0px 15px 0px",
    },
    colorSetContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      marginBottom: "30px",
    },
    colorTypeContainer: {
      position: "relative",
      background: "transparent",
      padding: "8px 0px",
      border: "1px solid #E9E9E9",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      maxWidth: "82px",
      width: "100%",
      marginRight: "20px",
      cursor: "pointer",
    },
    color: {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      background: "#BDBDBD",
      transition: "0.2s",
      "&:hover": {
        opacity: "0.8",
        transition: "0.2s",
      },
    },
    colorLabel: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "13px",
      color: "#000",
      margin: "6px 0px 0px 0px",
    },
    noMargin: {
      marginTop: "0px",
    },
    primaryColor: {
      background: (props) => props.primaryColor,
    },
    secondaryColor: {
      background: (props) => props.secondaryColor,
    },
  })
);

const DesignPreferences = ({ primaryColor, secondaryColor, setPrimaryColor, setSecondaryColor, savingLandingPageSettings, landingPagePreferences }) => {
  const [colorPickerState, setColorPickerState] = useState("none");
  const [closePicker, setClosePicker] = useState(false);
  const classes = useStyles({ primaryColor, secondaryColor });

  const resetDefaults = () => {
    setPrimaryColor("#008DD1");
    setSecondaryColor("#797A7A");
  };

  useEffect(() => {
    if (landingPagePreferences) {
      if (landingPagePreferences?.themeColorPrimary !== "") {
        setPrimaryColor(landingPagePreferences?.themeColorPrimary);
      }
      if (landingPagePreferences?.themeColorSecondary !== "") {
        setSecondaryColor(landingPagePreferences?.themeColorSecondary);
      }
    }
  }, [landingPagePreferences]);

  useEffect(() => {
    if (savingLandingPageSettings) {
      setPrimaryColor(landingPagePreferences?.themeColorPrimary);
      setSecondaryColor(landingPagePreferences?.themeColorSecondary);
    }
  }, [savingLandingPageSettings]);

  useEffect(() => {
    setColorPickerState("none");
  }, [primaryColor, secondaryColor]);

  useEffect(() => {
    if (closePicker) {
      setColorPickerState("none");
      setClosePicker(false);
    }
  }, [closePicker]);

  return (
    <div className={classes.designPreferencesContainer}>
      <h1 className={classes.labelText}>Theme Colors</h1>
      <div className={classes.colorSetContainer}>
        <div className={classes.colorTypeContainer} onClick={() => setColorPickerState("primary")}>
          <div className={`${classes.color} ${classes.primaryColor}`} />
          <h1 className={classes.colorLabel}>Primary</h1>
          {colorPickerState === "primary" && (
            <ColorPicker setColor={(color) => setPrimaryColor(color)} origionalColor={landingPagePreferences?.themeColorPrimary} setClosePicker={() => setClosePicker(true)} color={primaryColor} />
          )}
        </div>
        <div className={classes.colorTypeContainer} onClick={() => setColorPickerState("secondary")}>
          <div className={`${classes.color} ${classes.secondaryColor}`} />
          <h1 className={classes.colorLabel}>Secondary</h1>
          {colorPickerState === "secondary" && (
            <ColorPicker
              setColor={(color) => setSecondaryColor(color)}
              origionalColor={landingPagePreferences?.themeColorSecondary}
              setClosePicker={() => setClosePicker(true)}
              color={secondaryColor}
            />
          )}
        </div>
      </div>
      <Button className={classes.resetButton} onClick={() => resetDefaults()}>
        Reset defaults
      </Button>
    </div>
  );
};

DesignPreferences.propTypes = {
  primaryColor: PropTypes.string,
  setPrimaryColor: PropTypes.func,
  secondaryColor: PropTypes.string,
  setSecondaryColor: PropTypes.func,
  savingLandingPageSettings: PropTypes.bool,
  landingPagePreferences: PropTypes.object,
};

export default DesignPreferences;
