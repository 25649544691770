import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Button, withStyles, Backdrop } from "@material-ui/core";
import { useMutation, useLazyQuery } from "@apollo/client";
import { authApolloClient } from "../../Services/Graph/apolloConfig";
import { Link } from "react-router-dom";
import { UPDATE_USER_STEP } from "../../Services/Graph/UserPreferences/Mutations";
import { GET_ONE_REPORT } from "../../Services/Graph/UserPreferences/Queries";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    createReportBackdrop: {
      height: "100%",
      width: "100%",
      zIndex: "8",
      padding: "117px 100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    createReportContentContainer: {
      position: "relative",
      width: "808px",
      height: "518px",
      maxWidth: "1600px",
      maxHeight: "607px",
      zIndex: "10",
      background: "#fff",
      borderRadius: "20px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    title: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "24px",
      marginTop: "37px",
      marginBottom: "15px",
    },
    title1: {
      fontFamily: "nunito",
      fontWeight: "600",
      fontSize: "18px",
      marginTop: "47px",
      marginBottom: "22px",
    },
    title2: {
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "16px",
      marginBottom: "37px",
      maxWidth: "675px",
    },
    link: {
      color: "#0079B4",
      fontFamily: "nunito",
      fontWeight: "400",
      fontSize: "16px",
      marginTop: "20px",
    },
    extraOptionsLink: {
      textDecoration: "none",
    },
    spans: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "16px",
    },
  })
);
const CloseButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    background: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "11px 23px",
    borderRadius: "10px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const NewUserPopUp = ({ firstOpen, user, userName }) => {
  const classes = useStyles();
  const [popup, setPopup] = useState(true);

  const [updateUserStep] = useMutation(UPDATE_USER_STEP, {
    variables: {
      userId: user,
      step: 1,
    },
    client: authApolloClient,
  });

  const [getOneReport, { data: reportData, loading: reportLoading }] = useLazyQuery(GET_ONE_REPORT, {
    variables: {
      pageSize: 1,
      page: 0,
      sortField: "dateCreated",
      sortDirection: "desc",
    },
    client: authApolloClient,
  });

  useEffect(() => {
    if (firstOpen === 0 && !reportData && !reportLoading) {
      getOneReport();
      updateUserStep();
    }
  }, [firstOpen, getOneReport, reportData, reportLoading]);

  return (
    <Backdrop className={classes.createReportBackdrop} open={firstOpen === 0 && popup}>
      <div className={classes.createReportContentContainer}>
        <div className={classes.title}>ALMOST THERE!</div>
        <img alt="light bulb" className={classes.image} src="/assets/LightBulb.png" />
        <div className={classes.title1}>For success with AreaPulse, we highly suggest you customize your report!</div>
        <div className={classes.title2}>
          Reports are customized through <span className={classes.spans}> Market Report Preferences.</span> Don’t forget to upload a cute photo, company logo, and change your branding colors! Then{" "}
          <span className={classes.spans}>save your report</span>! Beautiful!
        </div>
        <CloseButton
          onClick={() => {
            setPopup(false);
          }}
        >
          CUSTOMIZE NOW
        </CloseButton>
        {reportData?.reports.length > 0 ? (
          <Link
            target="_blank"
            to={`/${userName.user}/market-reports/${reportData?.reports[0]?.id}`}
            onClick={(e) => {
              e.stopPropagation();
              setPopup(false);
            }}
            className={classes.extraOptionsLink}
          >
            <div className={classes.link}>JUST BE A REBEL AND VIEW MY REPORT</div>
          </Link>
        ) : null}
      </div>
    </Backdrop>
  );
};
NewUserPopUp.propTypes = {
  userName: PropTypes.object,
  user: PropTypes.number,
  firstOpen: PropTypes.number,
};
export default NewUserPopUp;
