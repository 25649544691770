import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Button, withStyles, Backdrop, CircularProgress } from "@material-ui/core";

import PropTypes from "prop-types";
import { Clear } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    createReportBackdrop: {
      height: "100%",
      width: "100%",
      zIndex: "8",
      padding: "117px 100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    createReportContentContainer: {
      position: "relative",
      width: "676px",
      height: "100%",
      maxWidth: "1600px",
      maxHeight: "287px",
      zIndex: "10",
      background: "#fff",
      borderRadius: "20px",
      overflow: "hidden",
    },
    headerBar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "25px 35px 14px",
    },
    headerBarTitle: {
      color: "#fff",
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      margin: "0px",
    },
    headerBarIcon: {
      color: "#282828",
      fontSize: "32px",
      cursor: "pointer",
    },
    upgradeTitle: {
      fontFamily: "nunito",
      fontWeight: "700",
      fontSize: "24px",
      width: "100%",
      textAlign: "center",
      marginBottom: "36px",
    },
    overContent: {
      alignItems: "center",
      flexDirection: "column",
      display: "flex",
      padding: "0px 20px",
    },
    title: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "nunito",
      fontSize: "27px",
      lineHeight: "36px",
      fontWeight: "700",
      marginBottom: "7px",
      textTransform: "uppercase",
    },
    subtitle: {
      fontFamily: "nunito",
      fontSize: "17px",
      lineHeight: "23px",
      fontWeight: "700",
      marginBottom: "35px",
    },
    svg: {
      marginRight: "8px",
    },
    overTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    circle: {
      marginTop: "20px",
      width: "80px !important",
      height: "80px !important",
      color: theme.palette.areaPulseBlue,
    },
  })
);
const CloseButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    background: theme.palette.areaPulseBlue,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "11px 47px",
    borderRadius: "7px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseBlue,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const CancelButton = withStyles((theme) => ({
  root: {
    color: "#8D8989",
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 10px",
    borderRadius: "7px",
    transition: "0.2s",
    textTransform: "uppercase",
    marginRight: "18px",
  },
}))(Button);

const DashboardReportPopUp = ({ userLoading, setUpgradeOpen, totalSubscriptionUsage, reportPopup, setReportPopup }) => {
  const classes = useStyles();
  const [reportPercent, setReportPercent] = useState("");
  const remainingReports = totalSubscriptionUsage?.reportsRemaining;
  const usedReports = totalSubscriptionUsage?.reportsUsed;
  useEffect(() => {
    if (totalSubscriptionUsage) {
      let totalReports = remainingReports + usedReports;
      let percent = Math.round((remainingReports / totalReports) * 100);

      setReportPercent(percent);
    }
  }, [totalSubscriptionUsage]);

  return (
    <Backdrop className={classes.createReportBackdrop} open={reportPopup}>
      <div className={classes.createReportContentContainer}>
        <div className={classes.headerBar}>
          <h1 className={classes.headerBarTitle}>Share Report</h1>
          <Clear
            fontSize="inherit"
            onClick={() => {
              setReportPopup(false);
            }}
            className={classes.headerBarIcon}
          />
        </div>
        <div className={classes.overContent}>
          {userLoading ? (
            <CircularProgress className={classes.circle} />
          ) : (
            <div className={classes.overTitle}>
              <div className={classes.title}>
                <svg className={classes.svg} width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.25038 44.9987C5.31288 45.6237 6.37538 45.832 7.43788 45.832H43.5629C45.2629 45.832 46.7504 45.207 47.8129 43.957C49.0879 42.9154 49.7254 41.2487 49.7254 39.582C49.7254 38.5404 49.5129 37.4987 48.8754 36.457L31.0254 7.08203C29.9629 5.6237 28.6879 4.58203 26.9879 4.16536C25.2879 3.7487 23.5879 3.95703 22.1004 4.79036C21.2504 5.20703 20.4004 6.04036 19.9754 6.8737L1.91288 36.457C0.212885 39.3737 1.27538 43.332 4.25038 44.9987ZM5.73656 38.5416L23.5866 9.16663C23.7991 8.74997 24.0116 8.54163 24.4366 8.3333C25.4991 7.91663 26.7741 8.12497 27.4116 9.16663L45.2616 38.5416C45.4741 38.9583 45.4741 39.1666 45.4741 39.5833C45.4741 40.2083 45.2616 40.625 44.8366 41.0416C44.4116 41.4583 43.9866 41.6666 43.3491 41.6666H7.43656C7.22406 41.6666 6.79906 41.6666 6.58656 41.4583C5.52406 40.8333 5.09906 39.5833 5.73656 38.5416ZM27.6245 27.0834V18.7501C27.6245 17.5001 26.7745 16.6668 25.4995 16.6668C24.2245 16.6668 23.3745 17.5001 23.3745 18.7501V27.0834C23.3745 28.3334 24.2245 29.1668 25.4995 29.1668C26.7745 29.1668 27.6245 28.3334 27.6245 27.0834ZM27.6253 35.4169C27.6253 36.0419 27.4128 36.4585 26.9878 36.8752C26.5628 37.2919 26.1378 37.5002 25.2878 37.5002C24.6503 37.5002 24.2253 37.2919 23.8003 36.8752C23.3753 36.4585 23.1628 36.0419 23.1628 35.4169C23.1628 35.1728 23.2357 35.0002 23.2961 34.8572C23.3388 34.7561 23.3753 34.6698 23.3753 34.5835C23.3753 34.3752 23.5878 34.1669 23.8003 33.9585C24.0128 33.7502 24.2253 33.5419 24.4378 33.5419C24.8628 33.3335 25.2878 33.3335 25.7128 33.3335C25.8346 33.453 25.8865 33.5039 25.9487 33.5257C25.9951 33.5419 26.0471 33.5419 26.1378 33.5419C26.244 33.5419 26.2971 33.594 26.3503 33.646C26.4034 33.6981 26.4565 33.7502 26.5628 33.7502C26.7753 33.7502 26.9878 33.9585 26.9878 33.9585C27.2003 34.1669 27.4128 34.3752 27.4128 34.5835C27.6253 34.7919 27.6253 35.2085 27.6253 35.4169Z"
                    fill="white"
                  />
                  <mask id="mask0" maskUnits="userSpaceOnUse" x="1" y="3" width="49" height="43">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.25038 44.9987C5.31288 45.6237 6.37538 45.832 7.43788 45.832H43.5629C45.2629 45.832 46.7504 45.207 47.8129 43.957C49.0879 42.9154 49.7254 41.2487 49.7254 39.582C49.7254 38.5404 49.5129 37.4987 48.8754 36.457L31.0254 7.08203C29.9629 5.6237 28.6879 4.58203 26.9879 4.16536C25.2879 3.7487 23.5879 3.95703 22.1004 4.79036C21.2504 5.20703 20.4004 6.04036 19.9754 6.8737L1.91288 36.457C0.212885 39.3737 1.27538 43.332 4.25038 44.9987ZM5.73656 38.5416L23.5866 9.16663C23.7991 8.74997 24.0116 8.54163 24.4366 8.3333C25.4991 7.91663 26.7741 8.12497 27.4116 9.16663L45.2616 38.5416C45.4741 38.9583 45.4741 39.1666 45.4741 39.5833C45.4741 40.2083 45.2616 40.625 44.8366 41.0416C44.4116 41.4583 43.9866 41.6666 43.3491 41.6666H7.43656C7.22406 41.6666 6.79906 41.6666 6.58656 41.4583C5.52406 40.8333 5.09906 39.5833 5.73656 38.5416ZM27.6245 27.0834V18.7501C27.6245 17.5001 26.7745 16.6668 25.4995 16.6668C24.2245 16.6668 23.3745 17.5001 23.3745 18.7501V27.0834C23.3745 28.3334 24.2245 29.1668 25.4995 29.1668C26.7745 29.1668 27.6245 28.3334 27.6245 27.0834ZM27.6253 35.4169C27.6253 36.0419 27.4128 36.4585 26.9878 36.8752C26.5628 37.2919 26.1378 37.5002 25.2878 37.5002C24.6503 37.5002 24.2253 37.2919 23.8003 36.8752C23.3753 36.4585 23.1628 36.0419 23.1628 35.4169C23.1628 35.1728 23.2357 35.0002 23.2961 34.8572C23.3388 34.7561 23.3753 34.6698 23.3753 34.5835C23.3753 34.3752 23.5878 34.1669 23.8003 33.9585C24.0128 33.7502 24.2253 33.5419 24.4378 33.5419C24.8628 33.3335 25.2878 33.3335 25.7128 33.3335C25.8346 33.453 25.8865 33.5039 25.9487 33.5257C25.9951 33.5419 26.0471 33.5419 26.1378 33.5419C26.244 33.5419 26.2971 33.594 26.3503 33.646C26.4034 33.6981 26.4565 33.7502 26.5628 33.7502C26.7753 33.7502 26.9878 33.9585 26.9878 33.9585C27.2003 34.1669 27.4128 34.3752 27.4128 34.5835C27.6253 34.7919 27.6253 35.2085 27.6253 35.4169Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0)">
                    <rect width="51" height="50" fill="#FF0000" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H51V50H0V0Z" fill="#0079B4" />
                  </g>
                </svg>
                {reportPercent > 10 && remainingReports !== 1 ? "You only have " + reportPercent + "% of reports left!" : "you only have " + remainingReports + " Report(s) left!"}
              </div>
              <div className={classes.subtitle}>Upgrade and extend your reports.</div>
              <div>
                <CancelButton
                  onClick={() => {
                    setReportPopup(false);
                  }}
                >
                  EDIT REPORTS
                </CancelButton>
                <CloseButton
                  onClick={() => {
                    setUpgradeOpen(true);
                    setReportPopup(false);
                  }}
                >
                  UPGRADE
                </CloseButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </Backdrop>
  );
};
DashboardReportPopUp.propTypes = {
  setReportPopup: PropTypes.func,
  setUpgradeOpen: PropTypes.func,
  reportPopup: PropTypes.bool,
  userLoading: PropTypes.bool,
  totalSubscriptionUsage: PropTypes.object,
};
export default DashboardReportPopUp;
