import React, { useEffect, useState } from "react";
import { GET_CONTACT } from "../../../Services/Graph/Contact/Queries";
import { UPDATE_CONTACT_INFO } from "../../../Services/Graph/Contact/Mutations";
import { authApolloClient } from "../../../Services/Graph/apolloConfig";
import { useLazyQuery, useMutation } from "@apollo/client";
import { createStyles, makeStyles, Paper, CircularProgress, Button, Fade } from "@material-ui/core";
import { ErrorOutlineOutlined } from "@material-ui/icons";
import { Check, EditOutlined } from "@material-ui/icons";
import ContactPersonalDetailsInput from "./ContactPersonalDetailsInput";
import PropTypes from "prop-types";
import DeleteContactModal from "./DeleteContactModal";
import getFormattedPhoneNumber from "../../../Helpers/getFormattedPhoneNumber";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: "20px",
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: "none",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contactHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "30px",
    },
    paperLabel: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "24px",
      fontWeight: "700",
      color: "#000",
      textTransform: "uppercase",
    },
    infoText: {
      width: "100%",
    },
    contactInfoText: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#000",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    circle: {
      strokeLinecap: "round",
    },
    progress: {
      color: theme.palette.areaPulseBlue,
      margin: "50px",
    },
    errorIcon: {
      fontSize: "18px",
      color: theme.palette.areaPulseError,
      marginRight: "10px",
    },
    error: {
      justifyContent: "center",
      width: "100%",
      textAlign: "center",
      fontSize: "16px",
      display: "flex",
      alignItems: "center",
      color: theme.palette.areaPulseError,
    },
    cancelButton: {
      color: theme.palette.grey[500],
      marginRight: "10px",
    },
    infoContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    inputActionButtons: {
      width: "130px",
      display: "flex",
    },
    editContactButton: {
      height: "40px",
      minWidth: "40px",
      backgroundColor: theme.palette.areaPulseBlue,
      boxShadow: "0px 1px 3px rgb(0 0 0 / 45%)",
      borderRadius: "9px",
      "&:hover": {
        backgroundColor: theme.palette.areaPulseBlue,
        opacity: "0.8",
      },
    },
    editContactButtonIcon: {
      height: "24px",
      width: "24px",
      color: "#fff",
    },
  })
);

const ContactPersonalDetails = ({
  setContactDetails,
  contactId,
  openUpdateContactSuccess,
  setOpenUpdateContactSuccess,
}) => {
  const classes = useStyles();
  const [openUpdatingInputs, setOpenUpdatingInputs] = useState(false);
  const [updatedContactInfo, setUpdatedContactInfo] = useState({});

  // QUERIES
  const [fetchContactInfo, { data: contactData, loading: contactLoading, error: contactError }] = useLazyQuery(
    GET_CONTACT,
    {
      variables: {
        id: contactId ?? 0,
      },
      client: authApolloClient,
      fetchPolicy: "network-only",
    }
  );
  const [updateContact, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_CONTACT_INFO, {
    variables: {
      id: contactId ?? 0,
      firstName: updatedContactInfo?.firstname,
      lastName: updatedContactInfo.lastname,
      email: updatedContactInfo.email,
      phone: updatedContactInfo.phone,
      streetAddress: updatedContactInfo.street,
      city: updatedContactInfo.city,
      state: updatedContactInfo.state,
      zip: updatedContactInfo.zip,
    },
    onCompleted() {
      fetchContactInfo();
    },
    client: authApolloClient,
  });

  const contactInfo = contactData?.contact ?? {};

  useEffect(() => {
    if (contactData !== undefined && !contactLoading) {
      setContactDetails(contactData);
    }
  }, [contactData]);

  useEffect(() => {
    fetchContactInfo();
  }, []);

  useEffect(() => {
    if (updatedContactInfo !== "" && openUpdateContactSuccess === true) {
      setOpenUpdateContactSuccess(false);
      updateContact();
    }
  }, [updatedContactInfo, openUpdateContactSuccess]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.contactHeaderContainer}>
        <h1 className={classes.paperLabel}>Personal Details</h1>
        <div>
          {openUpdatingInputs ? (
            <Fade in={openUpdatingInputs} timeout={500}>
              <div className={classes.inputActionButtons}>
                <Button
                  aria-label="cancel"
                  className={classes.cancelButton}
                  onClick={() => {
                    setOpenUpdatingInputs(false);
                  }}
                >
                  Cancel
                </Button>
                <Button className={classes.editContactButton} type="submit" form="contact-person-details-form">
                  <Check color="inherit" fontSize="inherit" className={classes.editContactButtonIcon} />
                </Button>
              </div>
            </Fade>
          ) : (
            <Button
              className={classes.editContactButton}
              onClick={(e) => {
                e.preventDefault();
                setOpenUpdatingInputs(true);
              }}
            >
              <EditOutlined color="inherit" fontSize="inherit" className={classes.editContactButtonIcon} />
            </Button>
          )}
        </div>
      </div>
      <div className={classes.infoContainer}>
        {contactLoading || updateLoading ? (
          <CircularProgress
            size={60}
            thickness={3}
            color="inherit"
            className={classes.progress}
            classes={{
              circle: classes.circle,
            }}
          />
        ) : null}
        {openUpdatingInputs ? (
          <ContactPersonalDetailsInput
            contactInfo={contactInfo}
            setUpdatedContactInfo={setUpdatedContactInfo}
            setOpenUpdateContactSuccess={setOpenUpdateContactSuccess}
            setOpenUpdatingInputs={setOpenUpdatingInputs}
            openUpdatingInputs={openUpdatingInputs}
          />
        ) : null}
        {Object.keys(contactInfo).length !== 0 &&
        contactData !== undefined &&
        openUpdatingInputs === false &&
        !contactLoading &&
        !updateLoading ? (
          <Fade in={true} timeout={500}>
            <div className={classes.infoText}>
              <h2 className={classes.contactInfoText}>
                {contactInfo?.firstName} {contactInfo?.lastName}
              </h2>
              <h2 className={classes.contactInfoText}>{contactInfo?.email?.email}</h2>
              <br></br>
              <h2 className={classes.contactInfoText}>{contactInfo?.address?.street}</h2>
              <h2 className={classes.contactInfoText}>
                {contactInfo?.address?.city} {contactInfo?.address?.state} {contactInfo?.address?.zip}{" "}
                {contactInfo?.address?.country}
              </h2>
              <br></br>
              <h2 className={classes.contactInfoText}>{getFormattedPhoneNumber(contactInfo?.phone?.number)}</h2>
            </div>
          </Fade>
        ) : null}
      </div>
      <DeleteContactModal contactId={contactId} />
      {updateError && (
        <div className={classes.error}>
          <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.errorIcon} />
          There was a problem updating contact
        </div>
      )}
      {contactError && (
        <div className={classes.error}>
          <ErrorOutlineOutlined fontSize="inherit" color="inherit" className={classes.errorIcon} />
          There was a problem getting contact
        </div>
      )}
    </Paper>
  );
};

ContactPersonalDetails.propTypes = {
  setContactDetails: PropTypes.func,
  contactId: PropTypes.string,
  openUpdateContactSuccess: PropTypes.bool,
  setOpenUpdateContactSuccess: PropTypes.func,
};

export default ContactPersonalDetails;
