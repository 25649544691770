import React from "react";
import { makeStyles, createStyles, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import Header from "./OptionTwoSections/Header";
import HomeValueSteps from "./OptionTwoSections/HomeValueSteps";
import LandingFooter from "./LandingFooter";

const useStyles = makeStyles((theme) => createStyles({}));

const OptionTwo = ({ canCreate, setNoReport, userData, setCreateReportOpen }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.landingPageContainer}>
      <Grid item xs={12}>
        <Header canCreate={canCreate} setNoReport={setNoReport} userData={userData} setCreateReportOpen={setCreateReportOpen} />
      </Grid>
      <Grid item xs={12}>
        <HomeValueSteps userData={userData} />
      </Grid>
      <Grid item xs={12}>
        <LandingFooter userData={userData} />
      </Grid>
    </Grid>
  );
};
OptionTwo.propTypes = {
  userData: PropTypes.object,
  setCreateReportOpen: PropTypes.func,
  setNoReport: PropTypes.func,
  canCreate: PropTypes.bool,
};

export default OptionTwo;
