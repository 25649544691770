import React, { useLayoutEffect } from "react";
import HomeStylesheet from "../HomeStylesheet";
import { Element } from "react-scroll";
import { Grid } from "@material-ui/core";
import { Parallax } from "react-scroll-parallax";
import { ParallaxProvider, useController } from "react-scroll-parallax";

const ParallaxCache = () => {
  const { parallaxController } = useController();

  useLayoutEffect(() => {
    const handler = () => parallaxController.update();
    window.addEventListener("load", handler);
    return () => window.removeEventListener("load", handler);
  }, [parallaxController]);

  return null;
};
const Pricing = () => {
  const classes = HomeStylesheet();
  return (
    <Element name="pricing" className={`${classes.pricingSection} ${classes.stitch}`}>
      <Grid container justify="center" alignItems="center" className={classes.pricingContainer}>
        <Grid item xs={12} className={classes.pricingHeaderContainer}>
          <div className={`wow fadeIn ${classes.delay}`}></div>
          <div className={`wow ${classes.fadeInUp} ${classes.pricingHeader}`}>Capture More Leads</div>
          <div className={`wow ${classes.fadeInUp}  ${classes.pricesubHeader}`}>Choose and customize your own Areapulse landing page to generate quality leads. </div>
          <div style={{ position: "absolute", width: "100%" }} className={`wow fadeIn `}>
            <div className={classes.includeImageParent}>
              <ParallaxProvider>
                <ParallaxCache />
                <Parallax className={classes.para1} x={[-13, 13]}>
                  <div style={{ display: "flex" }}>
                    <img className={classes.slideImages} src="/assets/areapulse-landing-4.png" alt="areapulse content 4" />
                    <img className={classes.slideImages} src="/assets/areapulse-landing-2.png" alt="areapulse content 4" />
                    <img className={classes.slideImages} src="/assets/areapulse-landing-6.png" alt="areapulse content 4" />
                    <img className={classes.slideImages} src="/assets/areapulse-landing-5.png" alt="areapulse content 4" />
                    <img className={classes.slideImages} src="/assets/areapulse-landing-4.png" alt="areapulse content 4" />
                  </div>
                </Parallax>
                <Parallax className={classes.para2} x={[13, -13]}>
                  <div style={{ display: "flex" }}>
                    <img className={classes.slideImages} src="/assets/areapulse-landing-6.png" alt="areapulse content 4" />
                    <img className={classes.slideImages} src="/assets/areapulse-landing-5.png" alt="areapulse content 4" />
                    <img className={classes.slideImages} src="/assets/areapulse-landing-4.png" alt="areapulse content 4" />
                    <img className={classes.slideImages} src="/assets/areapulse-landing-2.png" alt="areapulse content 4" />
                    <img className={classes.slideImages} src="/assets/areapulse-landing-6.png" alt="areapulse content 4" />
                  </div>
                </Parallax>
              </ParallaxProvider>
            </div>
          </div>
          <div className={`wow ${classes.fadeInUp}  ${classes.pricesubSubHeader}`}>Awesome looking market reports that are always updated in real time, looking great and fully responsive.</div>
        </Grid>
      </Grid>
    </Element>
  );
};

Pricing.propTypes = {};

export default Pricing;
