import React from "react";
import PropTypes from "prop-types";
import {
  createStyles,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    columnHeader: {
      fontFamily: theme.typography.secondary,
      margin: 0,
      fontSize: "18px",
      fontWeight: "600",
      color: "#818181",
      backgroundColor: "#F5F5F5",
    },
  })
);

const UserSettingsBillingHistoryTableHeaders = ({ order, orderBy, handleRequestSort, rows }) => {
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property)
  }

  const headCells = [
    { 
      id: "date",
      label: "Date" 
    },
    {
      id: "amount",
      label: "Amount",
    }
  ];

  return (
    <TableHead>
      <TableRow>
        {
          headCells.map((headCell) => (
          <TableCell key={headCell.id} className={classes.columnHeader}>
            <TableSortLabel
              direction={orderBy === headCell.id ? order: "asc"}
              onClick={createSortHandler(`${headCell.id}`)}
            >
              { headCell.label }
            </TableSortLabel>
          </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
};

UserSettingsBillingHistoryTableHeaders.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  handleRequestSort: PropTypes.func,
  rows: PropTypes.array,
};

export default UserSettingsBillingHistoryTableHeaders;
