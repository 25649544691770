import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles, withStyles, Button, CircularProgress } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { authApolloClient } from "../../../../Services/Graph/apolloConfig";
import { DELETE_MLS_LINK } from "../../../../Services/Graph/UserPreferences/Mutations";

const useStyles = makeStyles((theme) =>
  createStyles({
    areYouSureModalContainer: {
      position: "fixed",
      height: "0%",
      width: "100%",
      top: "0px",
      left: "0px",
      background: "rgba(0, 0, 0, 0.7)",
      zIndex: "10",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    },
    modalOpen: {
      height: "100%",
    },
    areYouSureModalContentContainer: {
      borderRadius: "20px",
      background: "#FFFFFF",
      padding: "30px",
      maxWidth: "767px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    areYouSureText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "700",
      fontSize: "24px",
      color: "#000",
      textTransform: "uppercase",
      letterSpacing: "0.195em",
    },
    areYouSureSubText: {
      fontFamily: theme.typography.secondary,
      fontWeight: "600",
      fontSize: "18px",
      color: "#000",
      margin: "20px 0px",
      textAlign: "center",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "40px",
    },
    deleteDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

const CloseButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    background: theme.palette.areaPulseErrorLight,
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 0px",
    width: "122.5px",
    borderRadius: "7px",
    transition: "0.2s",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.areaPulseErrorLight,
      transition: "0.2s",
      opacity: "0.8",
    },
  },
}))(Button);

const CancelButton = withStyles((theme) => ({
  root: {
    color: "#C6C6C6",
    fontSize: "16px",
    fontFamily: theme.typography.secondary,
    fontWeight: "700",
    padding: "5px 10px",
    borderRadius: "7px",
    transition: "0.2s",
    textTransform: "lowercase",
    marginRight: "5px",
  },
}))(Button);

const DeletePopUp = ({ reportUp, setReportUp, setPullDelete, deleteThisMLS, userId }) => {
  const classes = useStyles();
  const [deleteMLS, { loading: deleteLoading }] = useMutation(DELETE_MLS_LINK, {
    variables: {
      userId: userId,
      id: deleteThisMLS,
    },
    client: authApolloClient,
  });
  const handleClose = () => {
    deleteMLS().then(() => {
      setReportUp(false);
      setPullDelete(true);
    });
  };

  const handleCancel = () => {
    setReportUp(false);
  };

  return (
    <div className={`${classes.areYouSureModalContainer} ${reportUp && classes.modalOpen}`} onClick={() => handleCancel()}>
      <div className={classes.areYouSureModalContentContainer} onClick={(e) => e.stopPropagation()}>
        <h1 className={classes.areYouSureText}>Are You Sure?</h1>
        <div className={classes.buttonContainer}>
          <CancelButton onClick={() => handleCancel()}>cancel</CancelButton>
          <CloseButton disabled={deleteLoading} onClick={() => handleClose()}>
            <div className={classes.deleteDiv}>Delete</div>
            {deleteLoading ? <CircularProgress style={{ marginLeft: "10px", height: "28px", width: "28px", color: "white" }} /> : null}
          </CloseButton>
        </div>
      </div>
    </div>
  );
};

DeletePopUp.propTypes = {
  reportUp: PropTypes.bool,
  setReportUp: PropTypes.func,
  setPullDelete: PropTypes.func,
  deleteThisMLS: PropTypes.number,
  userId: PropTypes.number,
};

export default DeletePopUp;
